import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    LineChart,
    Line,
} from "recharts";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import classNames from "classnames";
import { buildLbDisconnectsQuery } from "./query";
import {
    DataPoint,
    generateCumulativeDiff,
} from "@cycleplatform/ui/components/recharts/util";
import { useEffect, useMemo, useState } from "react";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { formatNumber } from "@cycleplatform/core/util/number";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerConnectionsChartProps = {
    className?: string;
    environmentId?: string;
    port: string | undefined;
};

export function LoadBalancerConnectionsChart({
    className,
    environmentId,
    port,
}: LoadBalancerConnectionsChartProps) {
    const { colors } = useGetThemedChartColors();

    const {
        data: telemetry,
        isLoading,
        isFetching,
    } = useGenerateAggregatedMetricsQuery(
        buildLbDisconnectsQuery(environmentId, port),
        { skip: !environmentId, pollingInterval: AGGREGATE_POLLING_MS }
    );
    const [prevPort, setPrevPort] = useState<string | undefined>();
    const [isFetchingNewPort, setFetchingNewPort] = useState(false);

    useEffect(() => {
        if (prevPort === port && !isFetchingNewPort) {
            return;
        }
        setFetchingNewPort(isFetching);
        setPrevPort(port);
    }, [isFetching, port, prevPort]);

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    const diffs = useMemo(
        () =>
            generateCumulativeDiff(telemetry?.data as DataPoint[] | undefined),
        [telemetry?.data]
    );

    return (
        <div
            className={classNames(
                className,
                "h-full",
                (isLoading || isFetchingNewPort) && skeletonStyles
            )}
        >
            {telemetry?.data && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        {...CycleChartProps}
                        data={diffs}
                        syncId="lb-telem"
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            tick={(props) => <FormattedYTick {...props} />}
                        />
                        <Tooltip
                            content={(props) => (
                                <CycleLineTooltip
                                    {...props}
                                    formatValue={(v: number) =>
                                        `${formatNumber(v, { decimals: 0 })}`
                                    }
                                />
                            )}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="connections"
                            stroke={colors["blue"].DEFAULT}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="requests"
                            stroke={colors["green"].DEFAULT}
                        />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
