import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faFileDownload,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    Image,
    ImageSource,
} from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";
import { generateDialogLink } from "~/components/dialogs/helpers";
export function formatImageMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name =
        extractComponent<Image | ImageSource>(item.component, components)
            ?.name || item.component?.id;
    const image = (
        <Link
            to={
                item.component?.type === "image.source"
                    ? `/images/sources/${item.component.id}`
                    : generateDialogLink("image", {
                          "dialog-image-id": item.component?.id,
                      })
            }
        >
            {name}
        </Link>
    );
    switch (item.event) {
        case "image.import":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Imported image {image}</span>
                ) : (
                    <span>Error importing image {image} </span>
                ),
                icon: faFileDownload,
            };
        case "image.task.import":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested import of image {image}</span>
                ) : (
                    <span>Error requesting import of image {image}</span>
                ),
                icon: faFileDownload,
            };
        case "image.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated image {image}</span>
                ) : (
                    <span>Error updating image {image}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "image.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created image {image}</span>
                ) : (
                    <span>Error creating image</span>
                ),
                icon: faPlus,
            };
        case "image.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted image {image}</span>
                ) : (
                    <span>Error deleting image {image}</span>
                ),
                icon: faTrash,
            };
        case "image.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested image {image} be deleted</span>
                ) : (
                    <span>Error requesting image {image} be deleted</span>
                ),
                icon: faTrash,
            };
        case "image.source.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created new image source {image}</span>
                ) : (
                    <span>Error creating new image source {image}</span>
                ),
                icon: faPlus,
            };
        case "image.source.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated image source {image}</span>
                ) : (
                    <span>Failed to update image source {image}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "image.source.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Request image source {image} be deleted</span>
                ) : (
                    <span>
                        Error requesting image source {image} be deleted
                    </span>
                ),
                icon: faTrash,
            };
        case "image.source.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted image source {image}</span>
                ) : (
                    <span>Error deleting image source {image}</span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractImageOrSourceName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Image).name;
}
