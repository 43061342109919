import { components } from "@cycleplatform/core/modules/api/__generated";
import { formatDateString } from "@cycleplatform/core/util";

type FormatImageSourceMessage = {
    image: components["schemas"]["Image"];
};
export function FormatImageSourceMessage({ image }: FormatImageSourceMessage) {
    switch (image?.source?.details?.origin.type) {
        case "docker-file": {
            const details = image.source.details.origin.details;
            return (
                <div>
                    Imported from a <strong>Docker File</strong>{" "}
                    {details?.repo?.branch && (
                        <>
                            via branch <strong>{details.repo.branch}</strong>{" "}
                        </>
                    )}
                    on {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }
        case "docker-hub": {
            return (
                <div>
                    Imported from <strong>Docker Hub</strong> on{" "}
                    {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }
        case "oci-registry": {
            const details = image.source.details.origin.details;
            return (
                <div>
                    Imported from{" "}
                    <strong>OCI Registry ({details ? details.url : ""})</strong>{" "}
                    {/* {formatSource(option)} */}
                    on {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }
        case "docker-registry": {
            const details = image.source.details.origin.details;
            return (
                <div>
                    Imported from{" "}
                    <strong>
                        Docker Registry ({details ? details.url : ""})
                    </strong>{" "}
                    {/* {formatSource(option)} */}
                    on {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }
        case "cycle-upload": {
            return (
                <div>
                    Imported from <strong>an uploaded local image</strong>{" "}
                    {/* {formatSource(option)} */}
                    on {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }

        default: {
            return (
                <div>
                    Imported from <strong>an unknown location</strong> on{" "}
                    {formatDateString(image.events.created, "PP HH:mm")}
                </div>
            );
        }
    }
}
