import { components } from "../api/__generated";

export const nonCommercialTiers = ["starter", "free"];

export const tierCategories = {
    NON_COMMERCIAL: "non-commercial",
    TEAMS: "teams",
    ENTERPRISE: "enterprise",
    CUSTOM: "custom",
};

export type TierCategory = typeof tierCategories[keyof typeof tierCategories];

/**
 *
 * @param tiers - list of all tiers returned from the api
 * @param currentTierId - users current tier (will force it to be visible)
 * @returns A map where tiers are nested under respective tier category
 */
export function getTiersByCategory(
    tiers: components["schemas"]["TierPlan"][] | undefined,
    currentTierId?: string | null
) {
    if (!tiers) {
        return { nonCommercial: [], teams: [], custom: [] };
    }
    return tiers.reduce(
        (acc, tier) => {
            if (tier?.hidden || !tier.usable) {
                if (!currentTierId) {
                    return { ...acc };
                } else if (tier?.id !== currentTierId) {
                    return { ...acc };
                }
            }

            if (nonCommercialTiers?.includes(tier.code)) {
                return {
                    ...acc,
                    nonCommercial: [...acc.nonCommercial, tier],
                };
            } else if (tier.hubs !== null) {
                return { ...acc, custom: [...acc.custom, tier] };
            } else {
                return { ...acc, teams: [...acc.teams, tier] };
            }
        },
        { nonCommercial: [], teams: [], custom: [] } as {
            nonCommercial: components["schemas"]["TierPlan"][];
            teams: components["schemas"]["TierPlan"][];
            custom: components["schemas"]["TierPlan"][];
        }
    );
}
