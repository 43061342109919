import { Link } from "react-router-dom";
import { Image, ImageSource, useGetContainersQuery } from "~/services/cycle";
import {
    StyledTableRow,
    StyledDataTable,
    StyledTableHead,
    StyledHeaderCell,
    StyledCell,
} from "@cycleplatform/ui/components/tables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faContainerStorage } from "@fortawesome/pro-solid-svg-icons";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { generateDialogLink } from "~/components/dialogs/helpers";

type ImageSourceSummaryProps = {
    source?: ImageSource;
    image?: Image;
};
export function ContainerUsage({ source, image }: ImageSourceSummaryProps) {
    const { data: containers, error } = useGetContainersQuery(
        {
            page: {
                size: 100,
                number: 1,
            },
            meta: ["instances_count", "domains"],
            include: ["environments", "images", "stacks"],
            filter: {
                image: image?.id || "",
            },
        },
        { skip: !image?.id }
    );
    if (error) {
        throw error;
    }
    return (
        <Panel>
            <PanelTitle title="Containers" />
            <PanelContent stretch>
                {containers?.data && containers.data.length ? (
                    <>
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledHeaderCell>Name</StyledHeaderCell>
                                <StyledHeaderCell>Environment</StyledHeaderCell>
                                <StyledHeaderCell>State</StyledHeaderCell>
                            </StyledTableHead>
                            <tbody>
                                {containers.data.map((c) => (
                                    <StyledTableRow key={c.id}>
                                        <StyledCell>
                                            <Link
                                                to={generateDialogLink(
                                                    "container",
                                                    {
                                                        "dialog-container-id":
                                                            c.id,
                                                    }
                                                )}
                                            >
                                                {c.name}
                                            </Link>
                                        </StyledCell>
                                        <StyledCell>
                                            {c.environment?.id ? (
                                                <Link
                                                    to={`/environments/${c.environment.id}`}
                                                >
                                                    {containers?.includes
                                                        ?.environments
                                                        ? containers?.includes
                                                              ?.environments[
                                                              c.environment.id
                                                          ].name
                                                        : ""}
                                                </Link>
                                            ) : (
                                                <>No Environment</>
                                            )}
                                        </StyledCell>
                                        <StyledCell>
                                            <ResourceStateBadge
                                                state={c.state}
                                                className="capitalize"
                                            />
                                        </StyledCell>
                                    </StyledTableRow>
                                ))}
                            </tbody>
                        </StyledDataTable>
                    </>
                ) : (
                    <div className="w-full py-4 text-center">
                        <FontAwesomeIcon
                            icon={faContainerStorage}
                            className="text-cycle-blue h-10"
                        />
                        <div>No Containers</div>
                        <div>This image is not currrently in use</div>
                    </div>
                )}
            </PanelContent>
        </Panel>
    );
}
