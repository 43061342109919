import { ScopedVariable } from "~/services/cycle";

export type VariableInfo = {
    key: string;
    value: string;
    scopedVariable?: ScopedVariable | null;
};

export type LabeledVariableInfo = VariableInfo & { label: string };
/**

 * @param sources Provide an array of sources in order of descending heirarchy
 * @returns A nested object where the top level key is the source, and the value a Key/Value pair record
 * The order of the array indicates the heirarchy (first is higher priority, last is lower)
 */
export function processVariableHeirarchy(
    sources: { label: string; data: VariableInfo[] }[]
): LabeledVariableInfo[] {
    const result: LabeledVariableInfo[] = [];

    const accumulatedKeys = new Set<string>();

    sources.forEach(({ label, data }) => {
        data.forEach((variable) => {
            const { key, value, scopedVariable } = variable;
            // Only add variable to the final result if it hasn't been set by a higher-priority source
            if (!accumulatedKeys.has(key)) {
                result.push({ key, value, scopedVariable, label });
                accumulatedKeys.add(key);
            }
        });
    });

    return result;
}

/**
 *
 * @param scopedVariables Takes an array of scoped variables directly from the api
 * @returns A processed version of the variables into simple key/value pair ->
 * This ensures that it matches the format of the other env variables
 */
export function normalizeScopedVariableInfo(
    scopedVariables?: ScopedVariable[]
): VariableInfo[] {
    return (
        scopedVariables?.reduce((acc, cur) => {
            const source = cur.source;

            if (source?.type === "raw") {
                return [
                    ...acc,
                    {
                        key: cur.identifier,
                        value: source.details.value,
                        scopedVariable: cur,
                    },
                ];
            }

            return [
                ...acc,
                {
                    key: cur.identifier,
                    value: source?.details?.url || "",
                    scopedVariable: cur,
                },
            ];
        }, [] as VariableInfo[]) || []
    );
}

export function normalizeVariableInfo(
    variables: Record<string, string>
): VariableInfo[] {
    return Object.entries(variables).map(([key, value]) => ({
        key,
        value,
        scopedVariable: null,
    }));
}
