import { FeatureLineItem } from "@cycleplatform/ui/components/resources";
import infrastructureIcon from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";

export function EnterpriseContent() {
    return (
        <div>
            <h2 className="pb-2 text-2xl font-semibold">
                Enterprise Grade DevOps
            </h2>
            <div>
                Cycle scales up to even the most ambitious enterprise teams. Our
                unrivaled platform, combined with world-class customer support,
                will give your team peace of mind through every deploy.
            </div>
            <h4 className="text-cycle-black mt-6 flex items-center gap-2 pb-2 text-xl font-semibold">
                Do More with a Dedicated Core
            </h4>
            <div className="pb-2">
                Enterprise customers have the option to deploy a{" "}
                <strong>Dedicated Core</strong> - a specialized deployment of
                Cycle's core services.
            </div>
            <div className="flex h-full flex-col justify-start gap-1">
                <ul className="flex flex-1 flex-col gap-1">
                    <FeatureLineItem
                        value={`Customized hardware for any scale`}
                        label=""
                    />
                    <FeatureLineItem
                        value={`Managed for you by Cycle`}
                        label=""
                    />
                    <FeatureLineItem
                        value={`Dedicated to your organization - no shared
                                services`}
                        label=""
                    />
                </ul>
            </div>

            <div className="flex justify-center ">
                <img className="w-2/3" src={infrastructureIcon} />
            </div>
        </div>
    );
}
