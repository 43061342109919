import { components } from "@cycleplatform/core/modules/api/__generated";
import { Tooltip } from "../../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Badge } from "../../badges";

type ServerTitleProps = {
    providerServer: components["schemas"]["ProviderServerModel"];
    className?: string;
};

export function ServerTitle({ providerServer, className }: ServerTitleProps) {
    return (
        <div className={classNames("w-full", className)}>
            <div className="flex w-full items-center gap-2 text-center">
                {providerServer.low_resource && (
                    <Tooltip message="This server has low resources and should be reserved for small workloads">
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-cycle-gray dark:text-cycle-white"
                        />
                    </Tooltip>
                )}
                <h3 className="w-full text-center text-xl font-semibold ">
                    {providerServer.name}
                </h3>
            </div>

            <div className="flex w-full justify-center gap-1">
                {providerServer.specs.gpu && (
                    <Badge
                        className={classNames("bg-cycle-gray text-cycle-black")}
                    >
                        gpu
                    </Badge>
                )}
                {providerServer.specs.features.hypervisor && (
                    <Badge
                        className={classNames("bg-cycle-gray text-cycle-black")}
                    >
                        hypervisor
                    </Badge>
                )}
            </div>
        </div>
    );
}
