import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { faCloudUpload, faFileText } from "@fortawesome/pro-duotone-svg-icons";
import { useEffect, useState } from "react";
import { ChooseFromImageSource } from "./ChooseFromImageSource";
import { ChooseFromRecentImages } from "./ChooseFromRecentImages";
import { VolumesManager } from "./VolumesManager";
import { useFormContext } from "react-hook-form";
import { CreateContainerSubmitType } from "../CreateContainerForm";
import { useGetImageQuery } from "~/services/cycle";
import { OptionButton } from "@cycleplatform/ui/components/options";

export function ImageSection() {
    const { watch, setValue } = useFormContext<CreateContainerSubmitType>();
    const [source, setSource] = useState<"imageSource" | "recentImages">();

    const imageId = watch("image_id");

    const { data: image } = useGetImageQuery({
        imageId: imageId,
    });

    // Once image is set, set the ports according to the image ports described in the image config
    useEffect(() => {
        setValue(
            "config.network.ports",
            image?.data?.config.ports.map((p) => `${p.host}:${p.container}`, {
                shouldDirty: true,
            })
        );
    }, [image?.data]);

    return (
        <div>
            <FormSectionHeader header="Image" />
            <FormSection>
                <div className="flex gap-2 py-4">
                    <OptionButton
                        onClick={() => setSource("imageSource")}
                        title="Choose from image source"
                        description="Choose an image from an existing image
                        source."
                        isActive={source === "imageSource"}
                        icon={faCloudUpload}
                    />

                    <OptionButton
                        onClick={() => setSource("recentImages")}
                        title="Recent Images"
                        description="Choose a recently imported image."
                        isActive={source === "recentImages"}
                        icon={faFileText}
                    />
                </div>

                {source ? (
                    <>
                        <div>
                            {source === "imageSource" ? (
                                <ChooseFromImageSource />
                            ) : (
                                <ChooseFromRecentImages />
                            )}
                        </div>

                        <VolumesManager />
                    </>
                ) : null}
            </FormSection>
        </div>
    );
}
