import { Checkbox, FormFieldLabel } from "@cycleplatform/ui/components/forms";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { StackSpecContainer } from "~/services/cycle";

type NewContainersListProps = {
    newContainers: Record<string, StackSpecContainer> | undefined;
};

export function NewContainersList({ newContainers }: NewContainersListProps) {
    return (
        <ul className="flex flex-col gap-2 ">
            {Object.entries(newContainers || {})?.map(([identifier, c]) => (
                <div
                    key={identifier}
                    className="flex w-full items-center justify-between rounded-md border p-4"
                >
                    <div>
                        <div>
                            <span className="font-semibold">Name:</span>{" "}
                            {c.name}
                        </div>
                        <div>
                            <span className="font-semibold">Identifier: </span>
                            {identifier}
                        </div>
                    </div>

                    <div className="flex items-center">
                        <FormFieldLabel label="Deploy" labelClassName="pr-4" />

                        <Tooltip message="New Containers on the spec sheet are required to deploy">
                            <Checkbox
                                className="!m-0"
                                checked={true}
                                disabled
                            />
                        </Tooltip>
                    </div>
                </div>
            ))}
        </ul>
    );
}
