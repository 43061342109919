import {
    FormSectionHeader,
    FormSection,
    RhfFormField,
    TextInput,
    required,
    isJsonOrYaml,
    RhfJsonYamlInput,
    RhfJsonYamlToggle,
} from "@cycleplatform/ui/components/forms";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { extractCycleVariables } from "@cycleplatform/core/modules/variables/util";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { StackCreateFormType } from "~/components/dialogs/stacks/form/CreateStackForm";
import { useDebounce } from "@cycleplatform/ui/hooks";

export function RawForm() {
    const { control, register } = useFormContext<StackCreateFormType>();
    const [variables, setVariables] = useState<string[]>([]);
    const rawSource = useWatch({ control, name: "rawSource" });
    const debouncedDetails = useDebounce(rawSource, 100);

    useEffect(() => {
        // Keep variables state up to date as Stack Spec is updated
        setVariables(extractCycleVariables(rawSource || ""));
    }, [debouncedDetails]);

    return (
        <>
            <FormSectionHeader header="Cycle Stack File">
                <RhfJsonYamlToggle name="rawSource" />
            </FormSectionHeader>
            <FormSection>
                <RhfFormField label="file" name="rawSource">
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <RhfJsonYamlInput
                                {...field}
                                className="!h-[40rem]"
                            />
                        )}
                        control={control}
                        name={"rawSource"}
                        rules={{ validate: isJsonOrYaml() }}
                    />
                </RhfFormField>
            </FormSection>

            {variables?.length > 0 ? (
                <>
                    <FormSectionHeader header="Default Variables" />
                    <FormSection>
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledHeaderCell className="!pl-0">
                                    Stack Variable{" "}
                                    <FontAwesomeIcon
                                        icon={faAsterisk}
                                        className="text-error  ml-2 text-[10px]"
                                    />
                                </StyledHeaderCell>
                                <StyledHeaderCell>
                                    Value{" "}
                                    <FontAwesomeIcon
                                        icon={faAsterisk}
                                        className="text-error  ml-2 text-[10px]"
                                    />
                                </StyledHeaderCell>
                            </StyledTableHead>
                            <tbody>
                                {/* Take current variables from the Stack and register fields for each*/}
                                {variables.map((v, idx) => {
                                    return (
                                        <StyledTableRow key={v}>
                                            <StyledCell className="pr-2">
                                                {v}
                                            </StyledCell>
                                            <StyledCell className="pr-4">
                                                <RhfFormField
                                                    name={`variables.${v}`}
                                                >
                                                    <TextInput
                                                        // register each variable (v) as the variable object key
                                                        // - the input will register as the value
                                                        {...register(
                                                            `variables.${v}`,
                                                            // Critical that this unregisters -
                                                            // ensures that if a user updates the variable key name,
                                                            // the "old" variable it will drop from the variable list
                                                            {
                                                                shouldUnregister:
                                                                    true,
                                                                ...required(),
                                                            }
                                                        )}
                                                    />
                                                </RhfFormField>
                                            </StyledCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </tbody>
                        </StyledDataTable>
                    </FormSection>
                </>
            ) : null}
        </>
    );
}
