import classNames from "classnames";
import Editor from "react-simple-code-editor";
import "prismjs/themes/prism-twilight.css";
import prism, { Grammar } from "prismjs";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";

export function RhfJsonYamlInput({
    className,
    id,
    name,
    value,
    onChange,
    onBlur,
}: {
    id?: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    name: string;
    className?: string;
    value: string;
}) {
    const { highlight, languages } = prism;

    const [highlightedCode, setHighlightedCode] = useState<string>(value);

    useEffect(() => {
        setHighlightedCode(value);

        const highlightHandler = setTimeout(() => {
            setHighlightedCode(
                highlight(value, languages["js"] as Grammar, "javascript")
            );
        }, 500);

        return () => {
            clearTimeout(highlightHandler);
        };
    }, [value]);

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <div
            className={classNames(
                "overflow-auto rounded-md bg-black",
                Object.keys(errors).length && "ring-error ring-2",
                className
            )}
        >
            <Editor
                name={name}
                id={id}
                onBlur={onBlur}
                value={value}
                onValueChange={(code: string) => {
                    onChange(code);
                }}
                highlight={() => highlightedCode}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    color: "white",
                }}
                className="language-javascript"
            />
        </div>
    );
}
