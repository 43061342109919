import classNames from "classnames";

export function FieldContent({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div
            className={classNames(
                "max-w-[80%] gap-2 overflow-hidden text-ellipsis whitespace-nowrap",
                className
            )}
        >
            {children}
        </div>
    );
}
