import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm, useWatch } from "react-hook-form";
import { useKeepFormCurrent } from "~/components/common/forms";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    Environment,
    EnvironmentFeatures,
    MonitoringTierDetails,
    UpdateEnvironmentApiArg,
    useGetEnvironmentMonitoringTiersQuery,
    useGetEnvironmentQuery,
    useUpdateEnvironmentMutation,
} from "~/services/cycle";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { MonitoringTierButton } from "./MonitoringTierButton";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

function getDefaultValues(environment?: Environment) {
    return {
        name: environment?.name || "",
        identifier: environment?.identifier || "",
        about: {
            description: environment?.about.description || "",
            favorite: environment?.about.favorite || false,
        },
        monitoring: environment?.features?.monitoring?.tier
            ? {
                  tier: environment?.features?.monitoring?.tier,
              }
            : undefined,

        isMonitoringEnabled: !!environment?.features?.monitoring,
    };
}

export type Tier = MonitoringTierDetails & {
    identifier: NonNullable<EnvironmentFeatures["monitoring"]>["tier"];
};

export function UpdateMonitoringTierForm({ envId }: { envId: string }) {
    const nav = useNavigate();

    const { data: environment, error: environmentError } =
        useGetEnvironmentQuery({
            environmentId: envId,
        });

    const { data: monitoringTiers, error: tiersError } =
        useGetEnvironmentMonitoringTiersQuery();

    const sortedTiers = useMemo(
        () =>
            monitoringTiers?.data
                ? Object.entries(monitoringTiers?.data)
                      .reduce((acc, [tier, details]) => {
                          return [
                              ...acc,
                              {
                                  identifier: tier as NonNullable<
                                      EnvironmentFeatures["monitoring"]
                                  >["tier"],
                                  ...details,
                              },
                          ];
                      }, [] as Tier[])
                      .sort((a, b) => a.cost_mills - b.cost_mills)
                : [],
        [monitoringTiers?.data]
    );

    const form = useForm<UpdateEnvironmentApiArg["body"]>({
        defaultValues: getDefaultValues(environment?.data),
        ...rhfConfig,
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    useKeepFormCurrent(form, environment?.data, (e) => getDefaultValues(e));

    const selectedTier = useWatch({ name: "monitoring.tier", control });
    const [updateEnvironment] = useUpdateEnvironmentMutation();

    const onSubmit = async (data: UpdateEnvironmentApiArg["body"]) => {
        return updateEnvironment({
            environmentId: envId || "",
            body: data,
        })
            .unwrap()
            .then(() => {
                nav(clearDialogParams());
                form.reset((formValues) => ({ ...formValues }));
            }, handleSubmitError(form.setError));
    };

    if (tiersError) {
        throw tiersError;
    }
    if (environmentError) {
        throw environmentError;
    }

    return (
        <RhfFormProvider {...form} className="!h-full">
            <div className="flex flex-row gap-4 pt-4">
                {sortedTiers?.map((tier) => (
                    <MonitoringTierButton
                        selectedTier={selectedTier}
                        tier={tier}
                    />
                ))}
            </div>
            <DialogFooter className="items-center ">
                <RhfGlobalFormError />
                <PushAndHoldButton
                    onClick={handleSubmit(onSubmit)}
                    icon={faEdit}
                    flavor="primary"
                    disabled={!isDirty}
                    isLoading={isSubmitting}
                >
                    Update
                </PushAndHoldButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
