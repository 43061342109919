import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import { ProtectedRoute } from "../components/layout/ProtectedRoute";
import React from "react";
import VerifyHubRoute from "../components/layout/VerifyHubsRoute";
import { SplashScreen } from "../components/layout/SplashScreen";
import { FeatureLimited, Upsell } from "~/components/sales";
import infraIcon from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";
import deploymentsIcon from "@cycleplatform/ui/images/icons/gridless/deployments.svg";
import { CycleErrorBoundary } from "~/components/common/errors";
import ClusterSettings from "./infrastructure/clusters/$clusterId/settings/route";

const MenuWrapper = React.lazy(
    () => import("../components/layout/MenuWrapper")
);

const Index = React.lazy(() => import("./dashboard/index/route"));
const NoHub = React.lazy(() => import("./noHub/route"));
const Jobs = React.lazy(() => import("./jobs/index/route"));

const LoginWrapper = React.lazy(() => import("./login/route"));
const Login = React.lazy(() => import("./login/index/route"));
const Signup = React.lazy(() => import("./login/signup/route"));
const Verify = React.lazy(() => import("./login/verify/route"));

const Logout = React.lazy(() => import("./logout/route"));
const ResetTwoFactor = React.lazy(() => import("./login/resetTwoFa/route"));
const ForgotPassword = React.lazy(() => import("./login/forgotPassword/route"));

// Environment Routes
const Environments = React.lazy(() => import("./environments/route"));
const EmptyEnvironments = React.lazy(() => import("./environments/root/route"));
const $environmentIdLayout = React.lazy(
    () => import("./environments/$environmentId/route")
);
const $environmentId = React.lazy(
    () => import("./environments/$environmentId/index/route")
);
const Containers = React.lazy(
    () => import("./environments/$environmentId/containers/route")
);
const VirtualMachinesTab = React.lazy(
    () => import("./environments/$environmentId/vms/route")
);
const ScopedVariables = React.lazy(
    () => import("./environments/$environmentId/scopedVariables/route")
);
const EnvironmentMonitoring = React.lazy(
    () => import("./environments/$environmentId/monitoring/route")
);
const EnvironmentSettings = React.lazy(
    () => import("./environments/$environmentId/settings/route")
);

const Deployments = React.lazy(
    () => import("./environments/$environmentId/deployments/route")
);

// Stacks
const Stacks = React.lazy(() => import("./stacks/route"));
const EmptyStacks = React.lazy(() => import("./stacks/root/route"));
const StackLayout = React.lazy(() => import("./stacks/$stackId/route"));
const StackVariables = React.lazy(
    () => import("./stacks/$stackId/variables/route")
);
const StackDashboard = React.lazy(
    () => import("./stacks/$stackId/index/route")
);
const StackBuilds = React.lazy(() => import("./stacks/$stackId/builds/route"));
const StackSettings = React.lazy(
    () => import("./stacks/$stackId/settings/route")
);

// Images
const ImagesDashboard = React.lazy(() => import("./images/index/route"));
const EmptyImages = React.lazy(() => import("./images/sources/root/route"));
const ImageSourceList = React.lazy(() => import("./images/sources/route"));
const $sourceIdLayout = React.lazy(
    () => import("./images/sources/$sourceId/route")
);
const ImageSource = React.lazy(
    () => import("./images/sources/$sourceId/index/route")
);
const ImageSourceSettings = React.lazy(
    () => import("./images/sources/$sourceId/settings/route")
);
// Pipelines
const Pipelines = React.lazy(() => import("./pipelines/route"));
const EmptyPipelines = React.lazy(() => import("./pipelines/root/route"));
const PipelineWrapper = React.lazy(
    () => import("./pipelines/$pipelineId/route")
);
const PipelineDashboard = React.lazy(
    () => import("./pipelines/$pipelineId/dashboard/route")
);
const PipelineEditor = React.lazy(
    () => import("./pipelines/$pipelineId/editor/route")
);
const PipelineTriggerKeys = React.lazy(
    () => import("./pipelines/$pipelineId/triggerKeys/route")
);
const PipelineSettings = React.lazy(
    () => import("./pipelines/$pipelineId/settings/route")
);

const Servers = React.lazy(() => import("./infrastructure/servers/route"));
const EmptyServers = React.lazy(
    () => import("./infrastructure/servers/root/route")
);
const ServerWrapper = React.lazy(
    () => import("./infrastructure/servers/$serverId/route")
);
const ServerDashboard = React.lazy(
    () => import("./infrastructure/servers/$serverId/index/route")
);
const ServerInstances = React.lazy(
    () => import("./infrastructure/servers/$serverId/instances/route")
);
const ServerConsole = React.lazy(
    () => import("./infrastructure/servers/$serverId/console/route")
);
const ServerConfigure = React.lazy(
    () => import("./infrastructure/servers/$serverId/configure/route")
);
const ServerMonitoring = React.lazy(
    () => import("./infrastructure/servers/$serverId/monitoring/route")
);
const ServerSettings = React.lazy(
    () => import("./infrastructure/servers/$serverId/settings/route")
);

const Scaling = React.lazy(() => import("./infrastructure/scaling/route"));
const NoScalingGroups = React.lazy(
    () => import("./infrastructure/scaling/root/route")
);
const ScalingGroup = React.lazy(
    () => import("./infrastructure/scaling/$groupId/route")
);

const ScalingSettings = React.lazy(
    () => import("./infrastructure/scaling/$groupId/settings/route")
);
const AutoScaleDeployableInfra = React.lazy(
    () => import("./infrastructure/scaling/$groupId/index/route")
);

const Clusters = React.lazy(() => import("./infrastructure/clusters/route"));
const ClusterWrapper = React.lazy(
    () => import("./infrastructure/clusters/$clusterId/route")
);

const ClusterDashboard = React.lazy(
    () => import("./infrastructure/clusters/$clusterId/index/route")
);

const ClusterMonitoring = React.lazy(
    () => import("./infrastructure/clusters/$clusterId/monitoring/route")
);

const EmptyClusters = React.lazy(
    () => import("./infrastructure/clusters/root/route")
);

const Ips = React.lazy(() => import("./infrastructure/ips/route"));
const EmptyIps = React.lazy(() => import("./infrastructure/ips/root/route"));
const IpPool = React.lazy(() => import("./infrastructure/ips/index/route"));

// DNS

const ZonesIndex = React.lazy(() => import("./dns/index/route"));
const Zones = React.lazy(() => import("./dns/zones/route"));
const EmptyDnsZones = React.lazy(() => import("./dns/zones/root/route"));

const DnsZoneLayout = React.lazy(() => import("./dns/zones/$zoneId/route"));
const DnsZoneIndex = React.lazy(
    () => import("./dns/zones/$zoneId/index/route")
);
const DnsZoneRecords = React.lazy(
    () => import("./dns/zones/$zoneId/records/route")
);
const DnsZoneActivity = React.lazy(
    () => import("./dns/zones/$zoneId/activity/route")
);
const DnsZoneSettings = React.lazy(
    () => import("./dns/zones/$zoneId/settings/route")
);

// TLS
const TlsLayout = React.lazy(() => import("./dns/tls/route"));
const TlsDashboard = React.lazy(() => import("./dns/tls/dashboard/route"));
const TlsUserCertificates = React.lazy(
    () => import("./dns/tls/userCertificates/route")
);

// Networks
const Networks = React.lazy(() => import("./networks/route"));
const EmptyNetworks = React.lazy(() => import("./networks/root/route"));
const $NetworkId = React.lazy(() => import("./networks/$networkId/route"));

// Billing
const BillingLayout = React.lazy(() => import("./billing/index/route"));
const Billing = React.lazy(() => import("./billing/index/route"));
const Invoices = React.lazy(() => import("./billing/invoices/route"));
const Invoice = React.lazy(() => import("./billing/invoices/$invoiceId/route"));
const BillingMethods = React.lazy(() => import("./billing/methods/route"));
const EmptyMethods = React.lazy(() => import("./billing/methods/root/route"));
const BillingMethod = React.lazy(
    () => import("./billing/methods/$methodId/route")
);
const ActiveServices = React.lazy(
    () => import("./billing/active-services/route")
);
const Credits = React.lazy(() => import("./billing/credits/route"));

// Settings

const Manage = React.lazy(() => import("./hub/index/route"));
const Members = React.lazy(() => import("./hub/members/route"));
const $memberId = React.lazy(() => import("./hub/members/$memberId/route"));

const Roles = React.lazy(() => import("./hub/roles/route"));
const $roleId = React.lazy(() => import("./hub/roles/$roleId/route"));
const Invites = React.lazy(() => import("./hub/invites/route"));
const EmptyInvites = React.lazy(() => import("./hub/invites/root/route"));
const $inviteId = React.lazy(() => import("./hub/invites/$inviteId/route"));
const Integrations = React.lazy(() => import("./hub/integrations/route"));
const $integrationVendor = React.lazy(
    () => import("./hub/integrations/$integrationVendor/route")
);
const Plans = React.lazy(() => import("./hub/plans/route"));
const ApiKeys = React.lazy(() => import("./hub/api-keys/route"));
const EmptyApiKeys = React.lazy(() => import("./hub/api-keys/root/route"));
const ApiKey = React.lazy(() => import("./hub/api-keys/$keyId/route"));
const AuditLog = React.lazy(() => import("./hub/audit-log/route"));
const AuditEvents = React.lazy(() => import("./hub/audit-log/events/route"));
const AuditActivity = React.lazy(
    () => import("./hub/audit-log/activity/route")
);

export const router: ReturnType<typeof createBrowserRouter> =
    createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="login"
                    element={
                        <React.Suspense fallback={""}>
                            <LoginWrapper />
                        </React.Suspense>
                    }
                >
                    <Route
                        path=""
                        element={
                            <React.Suspense fallback={""}>
                                <Login />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="reset-twofactor"
                        element={
                            <React.Suspense fallback={""}>
                                <ResetTwoFactor />
                            </React.Suspense>
                        }
                    />

                    <Route
                        path="forgot-password"
                        element={
                            <React.Suspense fallback={""}>
                                <ForgotPassword />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="signup"
                        element={
                            <React.Suspense fallback={""}>
                                <Signup />
                            </React.Suspense>
                        }
                    />

                    <Route
                        path="verify"
                        element={
                            <React.Suspense fallback={""}>
                                <Verify />
                            </React.Suspense>
                        }
                    />
                </Route>

                <Route
                    path="logout"
                    element={
                        <React.Suspense fallback={<SplashScreen />}>
                            <Logout />
                        </React.Suspense>
                    }
                />
                <Route element={<ProtectedRoute />}>
                    <Route
                        element={
                            <React.Suspense
                                fallback={
                                    <SplashScreen message="Loading Route..." />
                                }
                            >
                                <MenuWrapper />
                            </React.Suspense>
                        }
                    >
                        <Route
                            element={<VerifyHubRoute requiresHubs={false} />}
                        >
                            <Route
                                path="/create-hub"
                                element={
                                    <React.Suspense fallback={""}>
                                        <NoHub />
                                    </React.Suspense>
                                }
                            />
                        </Route>
                        <Route element={<VerifyHubRoute requiresHubs={true} />}>
                            <Route
                                path="/"
                                element={
                                    <React.Suspense fallback={""}>
                                        <Index />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="jobs"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary>
                                            <Jobs />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="environments"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"environments-view"}
                                        >
                                            <Environments />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"environments-view"}
                                            >
                                                <EmptyEnvironments />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":environmentId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={
                                                        "environments-view"
                                                    }
                                                    resourceName="Environment"
                                                >
                                                    <$environmentIdLayout />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "environments-view"
                                                        }
                                                    >
                                                        <$environmentId />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="containers"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "environments-view"
                                                        }
                                                    >
                                                        <Containers />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        {import.meta.env
                                            .VITE_FEAT_VIRTUAL_MACHINES ===
                                        "true" ? (
                                            <Route
                                                path="vms"
                                                element={
                                                    <React.Suspense
                                                        fallback={""}
                                                    >
                                                        <CycleErrorBoundary
                                                            capability={
                                                                "environments-view"
                                                            }
                                                        >
                                                            <VirtualMachinesTab />
                                                        </CycleErrorBoundary>
                                                    </React.Suspense>
                                                }
                                            />
                                        ) : null}

                                        <Route
                                            path="deployments"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <FeatureLimited
                                                        feature="deployments"
                                                        render={
                                                            <CycleErrorBoundary>
                                                                <Upsell
                                                                    title="Upgrade Your Hub to Access Deployments"
                                                                    description="Upgrade your hub to the Lite tier or higher to take advantage of Deployments. Deployments bring application versioning and zero-downtime updates to your containers with the click of a button."
                                                                    icon={
                                                                        deploymentsIcon
                                                                    }
                                                                />
                                                            </CycleErrorBoundary>
                                                        }
                                                    >
                                                        <CycleErrorBoundary>
                                                            <Deployments />
                                                        </CycleErrorBoundary>
                                                    </FeatureLimited>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="scoped-variables"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "environments-view"
                                                        }
                                                    >
                                                        <ScopedVariables />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="monitoring"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "environments-view"
                                                        }
                                                    >
                                                        <EnvironmentMonitoring />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "environments-view"
                                                        }
                                                    >
                                                        <EnvironmentSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            {/* stacks */}

                            <Route
                                path="stacks"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"stacks-view"}
                                        >
                                            <Stacks />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"stacks-view"}
                                            >
                                                <EmptyStacks />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":stackId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={"stacks-view"}
                                                    resourceName="Stack"
                                                >
                                                    <StackLayout />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "stacks-view"
                                                        }
                                                    >
                                                        <StackDashboard />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="builds"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "stacks-view"
                                                        }
                                                    >
                                                        <StackBuilds />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="variables"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "stacks-view"
                                                        }
                                                    >
                                                        <StackVariables />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "stacks-view"
                                                        }
                                                    >
                                                        <StackSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            {/* images */}

                            <Route
                                path="images"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"images-view"}
                                        >
                                            <ImagesDashboard />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path="images/sources"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"images-sources-view"}
                                        >
                                            <ImageSourceList />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={
                                                    "images-sources-view"
                                                }
                                            >
                                                <EmptyImages />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":sourceId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={
                                                        "images-sources-view"
                                                    }
                                                    resourceName="Image Source"
                                                >
                                                    <$sourceIdLayout />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "images-sources-view"
                                                        }
                                                    >
                                                        <ImageSource />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "images-sources-view"
                                                        }
                                                    >
                                                        <ImageSource />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "images-sources-view"
                                                        }
                                                    >
                                                        <ImageSourceSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            {/* pipelines */}

                            <Route
                                path="pipelines"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"pipelines-view"}
                                        >
                                            <Pipelines />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"pipelines-view"}
                                            >
                                                <EmptyPipelines />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":pipelineId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={
                                                        "pipelines-view"
                                                    }
                                                    resourceName="Pipeline"
                                                >
                                                    <PipelineWrapper />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "pipelines-view"
                                                        }
                                                    >
                                                        <PipelineDashboard />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="editor"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "pipelines-view"
                                                        }
                                                    >
                                                        <PipelineEditor />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="trigger-keys"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "pipelines-view"
                                                        }
                                                    >
                                                        <PipelineTriggerKeys />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "pipelines-view"
                                                        }
                                                    >
                                                        <PipelineSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            {/* infrastructure */}

                            <Route
                                path="infrastructure/clusters"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary>
                                            <Clusters />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"clusters-view"}
                                            >
                                                <EmptyClusters />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":clusterId"
                                        element={
                                            // <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"clusters-view"}
                                                resourceName="Cluster"
                                            >
                                                <ClusterWrapper />
                                            </CycleErrorBoundary>
                                            // </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "clusters-view"
                                                        }
                                                    >
                                                        <ClusterDashboard />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="monitoring"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "clusters-view"
                                                        }
                                                    >
                                                        <ClusterMonitoring />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />

                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "clusters-view"
                                                        }
                                                    >
                                                        <ClusterSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="infrastructure/servers"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={["servers-view"]}
                                        >
                                            <Servers />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"servers-view"}
                                            >
                                                <EmptyServers />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":serverId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={"servers-view"}
                                                    resourceName="Server"
                                                >
                                                    <ServerWrapper />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerDashboard />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="instances"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerInstances />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="configure"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerConfigure />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="monitoring"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerMonitoring />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="console"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerConsole />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "servers-view"
                                                        }
                                                    >
                                                        <ServerSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="infrastructure/autoscaling"
                                element={
                                    <React.Suspense fallback={""}>
                                        <FeatureLimited
                                            feature="autoscale"
                                            render={
                                                <Upsell
                                                    title="Upgrade your hub to access Auto-Scaling"
                                                    description="Auto-Scaling is available in the Startup tier and higher."
                                                    icon={infraIcon}
                                                />
                                            }
                                        >
                                            <CycleErrorBoundary
                                                capability={
                                                    "autoscale-groups-view"
                                                }
                                                resourceName="Auto-Scale Group"
                                            >
                                                <Scaling />
                                            </CycleErrorBoundary>
                                        </FeatureLimited>
                                        {/* <Scaling /> */}
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={
                                                    "autoscale-groups-view"
                                                }
                                                resourceName="Auto-Scale Group"
                                            >
                                                <NoScalingGroups />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":groupId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={
                                                        "autoscale-groups-view"
                                                    }
                                                    resourceName="Auto-Scale Group"
                                                >
                                                    <ScalingGroup />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "autoscale-groups-view"
                                                        }
                                                        resourceName="Auto-Scale Group"
                                                    >
                                                        <AutoScaleDeployableInfra />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={
                                                            "autoscale-groups-view"
                                                        }
                                                        resourceName="Auto-Scale Group"
                                                    >
                                                        <ScalingSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="infrastructure/ips"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"ips-manage"}
                                            resourceName="IPs"
                                        >
                                            <Ips />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"ips-manage"}
                                                resourceName="IPs"
                                            >
                                                <EmptyIps />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":ipId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={"ips-manage"}
                                                    resourceName="IP"
                                                >
                                                    <IpPool />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>

                            {/* dns */}

                            <Route
                                path="dns"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"dns-view"}
                                            resourceName="DNS"
                                        >
                                            <ZonesIndex />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path="dns/zones"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"dns-view"}
                                            resourceName="DNS"
                                        >
                                            <Zones />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"dns-view"}
                                                resourceName="DNS"
                                            >
                                                <EmptyDnsZones />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":zoneId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={"dns-view"}
                                                    resourceName="DNS Zone"
                                                >
                                                    <DnsZoneLayout />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={"dns-view"}
                                                        resourceName="DNS"
                                                    >
                                                        <DnsZoneIndex />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="records"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={"dns-view"}
                                                        resourceName="DNS"
                                                    >
                                                        <DnsZoneRecords />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="activity"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={"dns-view"}
                                                        resourceName="DNS"
                                                    >
                                                        <DnsZoneActivity />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path="settings"
                                            element={
                                                <React.Suspense fallback={""}>
                                                    <CycleErrorBoundary
                                                        capability={"dns-view"}
                                                        resourceName="DNS"
                                                    >
                                                        <DnsZoneSettings />
                                                    </CycleErrorBoundary>
                                                </React.Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="dns/tls"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary resourceName="TLS certificates">
                                            <TlsLayout />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"dns-certs-view"}
                                                resourceName="TLS certificates"
                                            >
                                                <TlsDashboard />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                                <Route
                                    path="user-certificates"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"dns-certs-view"}
                                                resourceName="TLS certificates"
                                            >
                                                <TlsUserCertificates />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>

                            {/* networks */}

                            <Route
                                path="networks"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"sdn-networks-view"}
                                            resourceName="Networks"
                                        >
                                            <Networks />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"sdn-networks-view"}
                                                resourceName="Networks"
                                            >
                                                <EmptyNetworks />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":networkId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    capability={
                                                        "sdn-networks-view"
                                                    }
                                                    resourceName="Network"
                                                >
                                                    <$NetworkId />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>

                            {/* billing */}

                            <Route
                                path="billing"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary resourceName="Billing">
                                            <Billing />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path="billing/invoices"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            resourceName="Billing Invoice"
                                            capability={"billing-invoices-view"}
                                        >
                                            <Invoices />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=":invoiceId"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                resourceName="Billing Invoice"
                                                capability={
                                                    "billing-invoices-view"
                                                }
                                            >
                                                <Invoice />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>

                            <Route
                                path="billing/methods"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary resourceName="Billing Methods">
                                            <BillingMethods />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary resourceName="Billing Methods">
                                                <EmptyMethods />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":methodId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary resourceName="Billing Method">
                                                    <BillingMethod />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>

                            <Route
                                path="billing/active-services"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"billing-services-view"}
                                            resourceName="Billing Services"
                                        >
                                            <ActiveServices />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="billing/credits"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"billing-credits-view"}
                                            resourceName="Billing Credits"
                                        >
                                            <Credits />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />

                            {/* settings */}

                            <Route
                                path="hub"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary>
                                            <Manage />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path="hub/members"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"hubs-members-view"}
                                            resourceName="Hub Members"
                                        >
                                            <Members />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=":memberId"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"hubs-members-view"}
                                                resourceName="Hub Member"
                                            >
                                                <$memberId />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>

                            <Route
                                path="hub/roles"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            capability={"hubs-roles-view"}
                                            resourceName="Hub Roles"
                                        >
                                            <Roles />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=":roleId"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                capability={"hubs-roles-view"}
                                                resourceName="Hub Role"
                                            >
                                                <$roleId />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>

                            <Route
                                path="hub/invites"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            resourceName="Hub Invites"
                                            capability={"hubs-invites-manage"}
                                        >
                                            <Invites />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                resourceName="Hub Invites"
                                                capability={
                                                    "hubs-invites-manage"
                                                }
                                            >
                                                <EmptyInvites />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":inviteId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary
                                                    resourceName="Hub Invite"
                                                    capability={
                                                        "hubs-invites-manage"
                                                    }
                                                >
                                                    <$inviteId />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>

                            <Route
                                path="hub/integrations"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            resourceName="Hub Integrations"
                                            capability={
                                                "hubs-integrations-view"
                                            }
                                        >
                                            <Integrations />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=":integrationVendor"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary
                                                resourceName="Hub Integration"
                                                capability={
                                                    "hubs-integrations-view"
                                                }
                                            >
                                                <$integrationVendor />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>
                            <Route
                                path="hub/plans"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary
                                            resourceName="Hub Plans"
                                            capability={"billing-services-view"}
                                        >
                                            <Plans />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="hub/api-keys"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary resourceName="API Keys">
                                            <ApiKeys />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path=""
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary resourceName="API Keys">
                                                <EmptyApiKeys />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                >
                                    <Route
                                        path=":keyId"
                                        element={
                                            <React.Suspense fallback={""}>
                                                <CycleErrorBoundary resourceName="API Key">
                                                    <ApiKey />
                                                </CycleErrorBoundary>
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="hub/audit-log"
                                element={
                                    <React.Suspense fallback={""}>
                                        <CycleErrorBoundary>
                                            <AuditLog />
                                        </CycleErrorBoundary>
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path="activity"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary>
                                                <AuditActivity />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                                <Route
                                    path="events"
                                    element={
                                        <React.Suspense fallback={""}>
                                            <CycleErrorBoundary>
                                                <AuditEvents />
                                            </CycleErrorBoundary>
                                        </React.Suspense>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="*" element={<div>Not Found</div>} />
                </Route>
            </>
        )
    );
