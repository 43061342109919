import { components } from "../api/__generated";

/** This is the number of nanoseconds in 1 second */
export const MAX_CPU_PER_CORE_PER_SECOND = 1_000_000_000;

type CpuUsageParams = {
    currentTotal: number;
    prevTotal: number;
    secondsDiff: number;
    cores: number;
};

/**
 * Calculates the correct percentage of a CPU usage metric over two snapshots.
 * @returns percentage of CPU metric used over the period
 */
export function getCpuUsageAsPercent({
    currentTotal,
    prevTotal,
    secondsDiff,
    cores,
}: CpuUsageParams) {
    if (currentTotal - prevTotal < 0) {
        return null;
    }
    const cpuDiff = currentTotal - prevTotal;
    const maxCpuDiff = cores * MAX_CPU_PER_CORE_PER_SECOND * secondsDiff;
    return (cpuDiff / maxCpuDiff) * 100;
}

export function getEquivalentCpuCoresForInstance(
    cpuConfig:
        | NonNullable<
              components["schemas"]["Container"]["config"]["resources"]
          >["cpu"]
        | undefined,
    hostCores: number,
    deploymentStrategy: components["schemas"]["Container"]["config"]["deploy"]["strategy"]
): number {
    // If there are shares set, we must take them into account
    const limit = cpuConfig?.shares?.limit;
    const pinnedCores = calculateAppliedPinnedCores(
        cpuConfig?.cpus || "",
        hostCores
    );

    // limit === 0 means continue with the rest of the logic here
    if (limit) {
        const limitedCpus = limit / 10;
        // if there are fewer pinned cores than the limit suggests,
        // return the pinned cores length.
        if (pinnedCores.length && limitedCpus > pinnedCores.length) {
            return pinnedCores.length;
        }

        // otherwis return the limit equivalent
        return limitedCpus;
    }

    // Use pinned cores number if those are set
    if (pinnedCores.length > 0) {
        return pinnedCores.length;
    }

    // if we didnt have pinned cores and limit was zero, we're using all the host cores
    if (limit === 0) {
        return hostCores;
    }

    // implicit limits based on deployment strategy are set by the platform
    // https://linear.app/cycleplatform/issue/ENG-2397/fix-incorrect-instance-cpu-usage-logic
    switch (deploymentStrategy) {
        case "high-availability":
        case "manual":
            if (hostCores > 4) {
                return Math.floor(hostCores / 2);
            }
            return Math.min(hostCores, 2);
        default:
            if (hostCores > 2) {
                return Math.floor(hostCores / 2);
            }
            return Math.min(hostCores, 1);
    }
}

/**
 * # Description
 * A function that takes in a pinned cores string as set in a container config, and returns an array of all cores available to this process.
 * # Example
 * ```
 *  const cores = "1-3,1-5, 2-x";    
 *  const c = calculateAppliedPinnedCores(cores, 6);
    expect(c).toEqual([1, 2, 3, 4, 5, 6]);
    ```
 * 
 * @param pinnedCores A string representing the cores to pin a process to. Formatted as `1`, `1-4`, or `1-x`, or a comma separated combination of any of them.
 * @param hostCores The total number of cores available on the host.
 * @returns An array of all cores available to this process
 */
export function calculateAppliedPinnedCores(
    pinnedCores: string,
    hostCores: number
): number[] {
    const pinned: number[] = [];
    const coreEntries = pinnedCores.split(",");

    for (const c of coreEntries) {
        const parts = c.split("-");
        const start = parseInt(parts[0]);
        const end = parts[1] || null;
        if (!isNaN(start) && start <= hostCores) {
            pinned.push(start);
        }

        if (!end) {
            continue;
        }

        let max = end === "x" ? hostCores : parseInt(end);

        if (isNaN(max)) {
            continue;
        }

        if (max > hostCores) {
            max = hostCores;
        }

        for (let idx = start + 1; idx <= max; idx++) {
            pinned.push(idx);
        }
    }
    return [...new Set(pinned)];
}

if (import.meta.vitest) {
    const { it, expect, describe } = import.meta.vitest;

    describe("getEquivalentCpuCoresForInstance", () => {
        it("gets the equivalent instance cores for no CPU config", () => {
            const c = getEquivalentCpuCoresForInstance(undefined, 2, undefined);
            expect(c).toEqual(1);
        });
        it("gets the equivalent instance cores for a manual deployment strategy", () => {
            const c = getEquivalentCpuCoresForInstance(undefined, 16, "manual");
            expect(c).toEqual(8);
        });
        it("gets the equivalent instance cores for an HA deployment strategy", () => {
            const c = getEquivalentCpuCoresForInstance(
                undefined,
                16,
                "high-availability"
            );
            expect(c).toEqual(8);
        });
        it("gets the equivalent instance cores for an HA deployment strategy with less than 4 cpu", () => {
            const c = getEquivalentCpuCoresForInstance(
                undefined,
                3,
                "high-availability"
            );
            expect(c).toEqual(2);
        });
        it("chooses the total host cpu count if max would otherwise be greater", () => {
            const c = getEquivalentCpuCoresForInstance(
                undefined,
                1,
                "high-availability"
            );
            expect(c).toEqual(1);
        });
        it("uses the total cpu of the host if limit == 0", () => {
            const c = getEquivalentCpuCoresForInstance(
                { shares: { limit: 0, reserve: 0 } },
                8,
                "high-availability"
            );
            expect(c).toEqual(8);
        });
        it("gets the equivalent instance cores when shares are set", () => {
            expect(
                getEquivalentCpuCoresForInstance(
                    { shares: { limit: 10, reserve: 0 } },
                    2,
                    undefined
                )
            ).toEqual(1);
            expect(
                getEquivalentCpuCoresForInstance(
                    { shares: { limit: 15, reserve: 0 } },
                    2,
                    undefined
                )
            ).toEqual(1.5);
        });
        it("gets the equivalent when pinned cores are set", () => {
            const c = getEquivalentCpuCoresForInstance(
                { cpus: "1-3,10-x" },
                16,
                undefined
            );
            expect(c).toEqual(10);
        });
        it("gets the equivalent when both limit and pinned cores are set", () => {
            const c = getEquivalentCpuCoresForInstance(
                { cpus: "1-3,10-x", shares: { limit: 32, reserve: 0 } },
                16,
                undefined
            );
            expect(c).toEqual(3.2);
        });
    });

    describe("calculateAppliedPinnedCores", () => {
        it("calculates the correct CPU cores for a single core", () => {
            const cores = "2";
            const c = calculateAppliedPinnedCores(cores, 16);
            expect(c).toEqual([2]);
        });
        it("calculates the correct CPU cores for a range of cores", () => {
            const cores = "1-3";
            const c = calculateAppliedPinnedCores(cores, 16);
            expect(c).toEqual([1, 2, 3]);
        });
        it("calculates the correct CPU cores for a single core and a range of cores", () => {
            const cores = "1,2-4";
            const c = calculateAppliedPinnedCores(cores, 16);
            expect(c).toEqual([1, 2, 3, 4]);
        });
        it("calculates the correct CPU cores for a start to x (total host) cores", () => {
            const cores = "1-x";
            const c = calculateAppliedPinnedCores(cores, 4);
            expect(c).toEqual([1, 2, 3, 4]);
        });
        it("removes duplicates from cores", () => {
            const cores = "1,2,2,4";
            const c = calculateAppliedPinnedCores(cores, 4);
            expect(c).toEqual([1, 2, 4]);
        });
        it("removes duplicates with overlapping ranges of cores", () => {
            const cores = "1-3,1-5";
            const c = calculateAppliedPinnedCores(cores, 5);
            expect(c).toEqual([1, 2, 3, 4, 5]);
        });
        it("removes duplicates with overlapping ranges of cores, when there is an (x) involved", () => {
            const cores = "1-3,1-5, 2-x";
            const c = calculateAppliedPinnedCores(cores, 6);
            expect(c).toEqual([1, 2, 3, 4, 5, 6]);
        });
        it("gracefully handles an invalid range", () => {
            const cores = "3-1";
            const c = calculateAppliedPinnedCores(cores, 6);
            expect(c).toEqual([3]);
        });
        it("gracefully handles a non-valid core string", () => {
            const cores = "aaa";
            const c = calculateAppliedPinnedCores(cores, 6);
            expect(c).toEqual([]);
        });
        it("never counts cores higher than available on the host", () => {
            const cores = "1-18";
            const c = calculateAppliedPinnedCores(cores, 6);
            expect(c).toEqual([1, 2, 3, 4, 5, 6]);
        });
    });
}
