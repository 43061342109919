import {
    ComponentIncludes,
    Event,
    EventType,
    useGenerateAggregatedEventsQuery,
    useLookupComponentsQuery,
} from "~/services/cycle";
import { buildEventsQuery } from "./query";
import { useContext, useMemo } from "react";
import { EventsContext } from "../context";
import {
    ComponentInputArg,
    GenericEvent,
    eventsToComponentInput,
} from "~/components/logs/events/util";
import { EventAlertIcon } from "~/components/logs/events/list/EventAlertIcon";
import classNames from "classnames";
import { EventTitle } from "~/components/logs/events/message/EventTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { EventMessage } from "~/components/logs/events/message/EventMessage";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";
import { Popover } from "@headlessui/react";
import { HumanizedTime } from "@cycleplatform/ui/components/time";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";

export type SecurityEvent = {
    time: string;
    /**
     * TruncTime is used to to match the focusDate if used alongside
     * the SecurityEventsChart.  Ensure that the bin size is the same for
     * both queries
     */
    truncTime?: string;
    event: EventType;
    component: {
        id: string;
        type: ComponentInputArg["type"];
    };
    priority: Event["metadata"]["priority"];
    type: Event["metadata"]["type"];
    text: string;
    security_event: string;
    security_surface: string;
    security_attack: string;
    user_id?: string;
    user_type?: string;
    labels?: {
        [key: string]: string;
    };
};

export function EventsList({
    criteria,
    className,
    skip,
}: {
    criteria: { [key: string]: unknown };
    className?: string;
    skip?: boolean;
}) {
    const {
        data: events,
        error: eventsError,
        isLoading: eventsIsLoading,
    } = useGenerateAggregatedEventsQuery(buildEventsQuery(criteria), {
        pollingInterval: 60_000,
        skip,
    });
    const evs = events?.data as GenericEvent[];

    const compInput = eventsToComponentInput(evs);

    const { data: components, error: componentsError } =
        useLookupComponentsQuery(
            {
                body: {
                    components: compInput,
                },
            },
            { skip: !compInput || compInput.length === 0 }
        );

    /**
     * focusDate & setFocusDate is provided through context when
     * the EventsList is synced with a EventsChart
     */
    const { focusDate, setFocusDate } = useContext(EventsContext);

    const filteredEvs = useMemo(
        () =>
            !setFocusDate ? evs : evs?.filter((e) => e.truncTime === focusDate),
        [evs, focusDate]
    );

    if (componentsError) {
        throw componentsError;
    }

    if (eventsError) {
        throw eventsError;
    }

    if (filteredEvs && filteredEvs.length === 0) {
        return null;
    }

    if (skip || eventsIsLoading) {
        return (
            <div className="flex h-80 flex-col gap-2">
                <div className={classNames(skeletonStyles, "h-[6rem]")} />
                <div className={classNames(skeletonStyles, "h-[6rem]")} />
            </div>
        );
    }
    if (!evs?.length) {
        return null;
    }

    return (
        <ul className="flex h-80 flex-col overflow-y-auto">
            {filteredEvs?.map((ev, idx) => (
                <EventTile
                    key={`${ev.time}-${idx}`}
                    ev={ev}
                    components={components?.data}
                />
            ))}

            {eventsIsLoading ||
                (skip && (
                    <>
                        <div
                            className={classNames(
                                "h-[4rem] w-full",
                                skeletonStyles
                            )}
                        />
                    </>
                ))}
        </ul>
    );
}

function EventTile({
    ev,
    components,
}: {
    ev: GenericEvent;
    components?: ComponentIncludes;
}) {
    return (
        <li className="dark:border-cycle-gray flex items-center gap-4 border-b !border-opacity-40  py-4 last:border-none">
            <div
                className={classNames(
                    "flex w-1/12 min-w-[3rem] justify-center"
                )}
            >
                <EventAlertIcon event={ev} size="2x" />
            </div>

            <div
                className={classNames(
                    "flex w-11/12 items-center  justify-between gap-4"
                )}
            >
                <div className="w-full">
                    <div className="flex gap-2 font-bold">
                        <EventTitle event={ev} />
                    </div>
                    <EventMessage event={ev} components={components} />
                    <HumanizedTime value={ev.time} className="text-xs" />
                </div>

                <div>
                    <EventDetailsPopup event={ev} />{" "}
                </div>
            </div>
        </li>
    );
}

export function EventDetailsPopup({ event }: { event: GenericEvent }) {
    const { refs, floatingStyles } = useFloating({
        placement: "bottom-end",
        strategy: "fixed",
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    if (
        !event.security_event ||
        !event.priority ||
        !event.security_surface ||
        !event.security_attack
    ) {
        return null;
    }

    const ev = event as SecurityEvent;

    return (
        <div
            className={classNames("rounded-lg px-2 text-sm ", {
                "border-cycle-blue-accent [&_svg]:text-cycle-blue-accent hover:bg-cycle-blue-accent hover:text-cycle-white [&_svg]:hover:text-cycle-white border":
                    ev.priority === "medium",
                "border-warning [&_svg]:text-warning  hover:bg-warning hover:text-cycle-white [&_svg]:hover:text-cycle-white border":
                    ev.priority === "high",
                "border-error [&_svg]:text-error hover:bg-error hover:text-cycle-white [&_svg]:hover:text-cycle-white border":
                    ev.priority === "critical",
                "border-cycle-blue [&_svg]:text-cycle-blue hover:bg-cycle-blue hover:text-cycle-white border":
                    ev.priority === "normal" || ev.priority === "low",
            })}
        >
            <Popover className="relative">
                <Popover.Button className="flex w-full items-center justify-between gap-2 text-xs">
                    <div className="w-2/3 text-end" ref={refs.setReference}>
                        Details
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Popover.Button>
                <Popover.Panel
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="dark:bg-cycle-gray dark:text-cycle-white animate-fade-in-fast a bg-cycle-white z-10 min-w-[25rem] rounded-lg stroke-1 p-4 shadow-lg"
                >
                    <p>
                        Cycle has detected a potential security threat{" "}
                        {ev.security_event === "prevention" &&
                            "and has taken action to protect you"}
                        .
                    </p>
                    <div className="mt-2 grid grid-cols-2 gap-3 capitalize">
                        <div>
                            <span className="font-bold">Action: </span>{" "}
                            {ev.security_event.replace("-", " ")}
                        </div>
                        <div className="flex items-center gap-1">
                            <span className="font-bold">Risk Level: </span>{" "}
                            <EventAlertIcon event={event} />
                            {ev.priority.replace("-", " ")}
                        </div>
                        <div>
                            <span className="font-bold">Attack Surface: </span>{" "}
                            {ev.security_surface.replace("-", " ")}
                        </div>
                        <div>
                            <span className="font-bold">Attack Type: </span>{" "}
                            {ev.security_attack.replace("-", " ")}
                        </div>
                    </div>
                </Popover.Panel>
            </Popover>
        </div>
    );
}
