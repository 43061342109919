import { TextInput } from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { DynamicObjectField } from "@cycleplatform/react-hook-form-components/components";

export function RLimitSection() {
    const { control } = useFormContext<ContainerConfigFormData>();
    return (
        <DynamicObjectField control={control} field={`config.runtime.rlimits`}>
            {({ fields, append, fieldKey, register, remove }) => (
                <>
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledHeaderCell className="w-80">
                                RLIMIT VALUE
                            </StyledHeaderCell>
                            <StyledHeaderCell>Soft Limit</StyledHeaderCell>
                            <StyledHeaderCell>Hard Limit</StyledHeaderCell>
                            <StyledHeaderCell className="w-20"></StyledHeaderCell>
                        </StyledTableHead>
                        <tbody>
                            {fields.map((f, idx) => (
                                <StyledTableRow key={f.id}>
                                    <StyledCell className="pr-2">
                                        <TextInput
                                            placeholder="RLIMIT VALUE"
                                            {...register(
                                                `${fieldKey}.${idx}.key`
                                            )}
                                        />
                                    </StyledCell>
                                    <StyledCell className="pr-2">
                                        <TextInput
                                            type="number"
                                            {...register(
                                                `${fieldKey}.${idx}.value.soft`,
                                                {
                                                    valueAsNumber: true,
                                                }
                                            )}
                                        />
                                    </StyledCell>
                                    <StyledCell className="pr-4">
                                        <TextInput
                                            type="number"
                                            {...register(
                                                `${fieldKey}.${idx}.value.hard`,
                                                {
                                                    valueAsNumber: true,
                                                }
                                            )}
                                        />
                                    </StyledCell>
                                    <StyledCell className="text-end">
                                        <Button
                                            icon={faTrash}
                                            type="button"
                                            flavor="discard"
                                            onClick={() => {
                                                remove(idx);
                                            }}
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            ))}
                        </tbody>
                    </StyledDataTable>
                    <Button
                        icon={faPlus}
                        type="button"
                        className="mt-4 w-full whitespace-nowrap"
                        onClick={() => {
                            append({
                                key: "",
                                value: {
                                    soft: 1024,
                                    hard: 1024,
                                },
                            });
                        }}
                    >
                        Add
                    </Button>
                </>
            )}
        </DynamicObjectField>
    );
}
