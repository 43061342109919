import { Button } from "@cycleplatform/ui/components/buttons";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
    RhfFormField,
    TextInput,
    hasNoSpaces,
    isUrl,
    required,
    startsWithSlash,
    startsWithHttpHttps,
} from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { ContainerConfigFormData } from "../types";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";

export function FilesIntegration() {
    const { control, register } = useFormContext<ContainerConfigFormData>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "config.integrations.files",
    });

    return (
        <>
            <StyledDataTable>
                <StyledTableHead>
                    <StyledHeaderCell className="w-80 !pl-0">
                        Source
                        <FontAwesomeIcon
                            icon={faAsterisk}
                            className="text-error  ml-2 text-[10px]"
                        />
                    </StyledHeaderCell>
                    <StyledHeaderCell>
                        Destination
                        <FontAwesomeIcon
                            icon={faAsterisk}
                            className="text-error  ml-2 text-[10px]"
                        />
                    </StyledHeaderCell>
                    <StyledHeaderCell className="w-20"></StyledHeaderCell>
                </StyledTableHead>

                <tbody>
                    {fields.map((field, idx) => (
                        <StyledTableRow key={field.id}>
                            <StyledCell className="pr-2 !pl-0">
                                <RhfFormField
                                    name={`config.integrations.files.${idx}.source`}
                                >
                                    <TextInput
                                        required
                                        {...register(
                                            `config.integrations.files.${idx}.source`,
                                            {
                                                ...required(
                                                    "Source is required"
                                                ),
                                                validate: {
                                                    ...isUrl(
                                                        "Source must be a URL"
                                                    ),
                                                    ...startsWithHttpHttps(),
                                                    ...hasNoSpaces(),
                                                },
                                            }
                                        )}
                                    />
                                </RhfFormField>
                            </StyledCell>
                            <StyledCell className="pr-4">
                                <RhfFormField
                                    name={`config.integrations.files.${idx}.destination`}
                                >
                                    <TextInput
                                        {...register(
                                            `config.integrations.files.${idx}.destination`,
                                            {
                                                ...required(
                                                    "Destination is required"
                                                ),
                                                validate: {
                                                    ...startsWithSlash(),
                                                },
                                            }
                                        )}
                                    />
                                </RhfFormField>
                            </StyledCell>
                            <StyledCell className="text-end">
                                <Button
                                    onClick={() => remove(idx)}
                                    flavor="discard"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </StyledCell>
                        </StyledTableRow>
                    ))}

                    <NewFileRow
                        onAdd={(v) => {
                            append(v);
                        }}
                    />
                </tbody>
            </StyledDataTable>
        </>
    );
}

function NewFileRow({
    onAdd,
}: {
    onAdd: (v: { source: string; destination: string }) => void;
}) {
    return (
        <StyledTableRow className="border-t">
            <StyledCell colSpan={4} className="first:pl-0">
                <Button
                    type="button"
                    className="w-full"
                    icon={faPlus}
                    onClick={() => {
                        onAdd({
                            destination: "",
                            source: "",
                        });
                    }}
                />
            </StyledCell>
        </StyledTableRow>
    );
}
