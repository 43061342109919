import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faSync,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, Network } from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatSDNMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractNetworkName(item.component?.id || "", components);
    const link = <Link to={`/networks/${item.component?.id}`}>{name}</Link>;
    switch (item.event) {
        case "sdn.network.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of network {link}</span>
                ) : (
                    <span>Error requesting deletion of network {link}</span>
                ),
                icon: faTrash,
            };
        case "sdn.network.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated network {link}</span>
                ) : (
                    <span>Error updating network {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "sdn.network.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created network {link}</span>
                ) : (
                    <span>Error creating network</span>
                ),
                icon: faPlus,
            };
        case "sdn.network.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested reconfiguration of network {link}</span>
                ) : (
                    <span>
                        Error requesting reconfiguration of network {link}
                    </span>
                ),
                icon: faSync,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractNetworkName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Network).name;
}
