import {
    CycleButtonProps,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    faHammer,
    faPlay,
    faSquare,
    faTrash,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { IdSelectType } from "./hooks";
import { isCycleApiError } from "~/services/helpers";

type RhfMultipleActionButtonProps<T> = {
    mutation: (id: string) => Promise<T>;
    resourceName: string;
    children?: React.ReactNode;
    className?: string;
    actionType?: string;
    disabled?: boolean;
    icon?: IconDefinition;
    flavor?: CycleButtonProps["flavor"];
};

const icons: Record<string, IconDefinition> = {
    delete: faTrash,
    start: faPlay,
    stop: faSquare,
    default: faHammer,
};

export function RhfMultipleActionButton<T>({
    mutation: desiredMutation,
    resourceName,
    children,
    icon,
    flavor,
    className,
    actionType = "delete",
    disabled,
}: RhfMultipleActionButtonProps<T>) {
    const { setValue, handleSubmit, control } = useFormContext<IdSelectType>();
    const ids = useWatch({ name: "ids", control });
    const dispatch = useAppDispatch();

    const onSubmit = (data: IdSelectType) => {
        setValue("ids", []);
        const promises = data.ids.map(desiredMutation);
        Promise.allSettled(promises).then((result) => {
            const failedArr = result.filter((r) => r.status === "rejected");
            if (failedArr.length) {
                dispatch(
                    pushNotification({
                        title: `Error attempting to ${actionType} ${failedArr.length} ${resourceName}`,
                        type: "error",
                        message: formatRejectedMessageDetails(failedArr),
                        icon: icon || icons[actionType] || icons["default"],
                    })
                );
            }
        });
    };

    return (
        <PushAndHoldButton
            className={className}
            type="button"
            isLoading={false}
            flavor={flavor || actionType === "delete" ? "discard" : "default"}
            onClick={handleSubmit(onSubmit)}
            icon={icon || icons[actionType] || icons["default"]}
            disabled={!ids.length || disabled}
        >
            {children ? <>{children}</> : <div>{actionType}</div>}
        </PushAndHoldButton>
    );
}

function formatRejectedMessageDetails(ps: PromiseRejectedResult[]) {
    const errCountMap = ps.reduce((acc, cur) => {
        const msg =
            formatRejectedCycleApiPromiseMessage(cur) || "Unknown Error";

        if (!acc[msg]) {
            acc[msg] = 0;
        }
        acc[msg]++;
        return acc;
    }, {} as Record<string, number>);

    return Object.entries(errCountMap)
        .map(([err, count]) => {
            return `(${count}x) ${err}`;
        })
        .join("\n");
}

function formatRejectedCycleApiPromiseMessage(p: PromiseRejectedResult) {
    if (!isCycleApiError(p.reason)) {
        if (typeof p.reason === "string") {
            return p.reason;
        }
        return null;
    }

    return `${p.reason.data.error.title} ${
        p.reason.data.error.detail ? `- ${p.reason.data.error.detail}` : ""
    }`;
}
