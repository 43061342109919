import {
    FormSectionHeader,
    PillButtons,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { RegistryAuth } from "~/services/cycle";
import { ImageSourceCreateEditSubmitType } from "../../util";
import { ProviderAuthFields } from "./auth/ProviderAuthFields";
import { UserAuthFields } from "./auth/UserAuthFields";
import { WebhookAuthFields } from "./auth/WebhookAuthFields";

type AuthType = "none" | NonNullable<RegistryAuth>["type"];

export function OciAuthSection() {
    const { unregister, watch, setValue } =
        useFormContext<ImageSourceCreateEditSubmitType>();

    const at = watch("origin.details.auth.type");

    const [authType, setAuthType] = useState<AuthType>(at || "none");

    const handleAuthTypeChange = (t: AuthType) => {
        setAuthType(t);
        switch (t) {
            case "none":
                setValue("origin.details.auth", null, { shouldDirty: true });
                unregister("origin.details.auth", { keepDirty: true });
                break;
            case "provider":
                setValue(
                    "origin.details.auth",
                    {
                        type: "provider",
                        details: {
                            flavor: "ecr",
                            credentials: {
                                api_key: "",
                                secret: "",
                                region: "",
                            },
                        },
                    },
                    { shouldDirty: true }
                );
                break;
            case "user":
                setValue(
                    "origin.details.auth",
                    {
                        type: "user",
                        details: {
                            username: "",
                            token: "",
                        },
                    },
                    { shouldDirty: true }
                );
                break;
            case "webhook":
                setValue(
                    "origin.details.auth",
                    {
                        type: "webhook",
                        details: {
                            url: "",
                        },
                    },
                    { shouldDirty: true }
                );
        }
    };

    return (
        <fieldset className="group">
            <FormSectionHeader header="Auth">
                <PillButtons
                    value={authType}
                    onChange={(v) => handleAuthTypeChange(v as AuthType)}
                    options={[
                        {
                            value: "none",
                            label: "None",
                        },
                        {
                            value: "user",
                            label: "User",
                        },
                        {
                            value: "provider",
                            label: "Provider",
                        },
                        {
                            value: "webhook",
                            label: "Webhook",
                        },
                    ]}
                />
            </FormSectionHeader>
            <AuthFields authType={authType} />
        </fieldset>
    );
}

function AuthFields({ authType }: { authType: AuthType }) {
    switch (authType) {
        case "provider":
            return <ProviderAuthFields />;
        case "user":
            return <UserAuthFields />;
        case "webhook":
            return <WebhookAuthFields />;
        case "none":
        default:
            return null;
    }
}
