import { AlertBar } from "../layout/alert";

type ServiceContainerBannerProps = {
    isServiceCont?: boolean;
};

export function ServiceContainerBanner({
    isServiceCont,
}: ServiceContainerBannerProps) {
    return (
        <>
            {isServiceCont ? (
                <AlertBar type="warning">
                    This is a service container and cannot be modified or
                    deleted.
                </AlertBar>
            ) : null}
        </>
    );
}
