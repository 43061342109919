import { Button } from "@cycleplatform/ui/components/buttons";
import { TextInput } from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { LoadBalancerDialogContext } from "../../../context";
import { LoadBalancerFormValues } from "../../form";

export function V1AddController() {
    const { environment } = useContext(LoadBalancerDialogContext);
    const [controller, setController] = useState<string>("");
    const { control, watch } = useFormContext<LoadBalancerFormValues>();

    const nav = useNavigate();

    const { fields: controllers, append } = useFieldArray({
        control,
        name: `config.details.controllers`,
    });

    const onAdd = (newController: string) => {
        setController("");

        append({
            port: Number(newController),
            identifier: `port-${newController.toString()}`,
            transport: undefined,
        });

        nav(
            generateDialogLink("environment-lb-manage", {
                "dialog-tab": "controllers",
                "dialog-controllers-port-idx": controllers.length.toString(),
                "dialog-controllers-subsection": "config",
            })
        );
    };

    const existingPorts = useMemo(
        () => controllers.map((c) => c.port.toString()),
        [controllers]
    );

    const isValidPort =
        Number(controller) < 65535 && !existingPorts.includes(controller);

    return (
        <div className="flex items-center gap-4">
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={modifyAccessFn("environments-services-manage")}
            >
                <TextInput
                    className="dark:disabled:bg-cycle-gray/50"
                    placeholder="Port #"
                    type="number"
                    value={controller}
                    onChange={(e) => setController(e.target.value)}
                />
            </AccessControlOverlay>
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={modifyAccessFn("environments-services-manage")}
            >
                <Button
                    disabled={
                        controller === undefined ||
                        controller === "" ||
                        !isValidPort
                    }
                    type="button"
                    icon={faPlus}
                    onClick={() => {
                        if (!controller) return;
                        return onAdd(controller);
                    }}
                >
                    Add
                </Button>
            </AccessControlOverlay>
        </div>
    );
}
