import React from "react";
import {
    faTrash,
    faQuestionCircle,
    faPlus,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, HubMembership } from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatMembershipMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractMembershipName(item.component?.id || "", components);
    const link = <a href={`mailto:${name}`}>{name}</a>;
    switch (item.event) {
        case "hub.membership.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Removed {link} from hub</span>
                ) : (
                    <span>Error removing {link} from hub</span>
                ),
                icon: faTrash,
            };
        case "hub.membership.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Added {link} to hub</span>
                ) : (
                    <span>Error adding {link} to hub</span>
                ),
                icon: faPlus,
            };

        case "hub.membership.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated hub membership of {link}</span>
                ) : (
                    <span>Error updating hub membership of {link}</span>
                ),
                icon: faEdit,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractMembershipName(id: string, components: ComponentIncludes) {
    if (!components) {
        return id;
    }

    const c = components[id] as HubMembership;
    return c.invitation.recipient;
}
