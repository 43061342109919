import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { useEffect, useState } from "react";
import { DeploymentStrategySelect } from "~/components/containers/inputs/DeploymentStrategySelect";
import { $error } from "@cycleplatform/core/util/log";

export function DeployGeneral() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <div className="flex gap-4">
                    <div className="w-1/2">
                        <RhfFormField
                            label="Initial Instances"
                            name="config.deploy.instances"
                        >
                            <TextInput
                                className="w-full"
                                disabled
                                {...register("config.deploy.instances")}
                            />
                        </RhfFormField>
                    </div>
                    <div className="w-1/2">
                        <StrategySelect />
                    </div>
                </div>
            </FormSection>
        </>
    );
}

function StrategySelect() {
    const {
        control,
        getValues,
        formState: { isSubmitted },
    } = useFormContext<ContainerConfigFormData>();

    const [initStrategy, setInitStrategy] = useState(
        getValues("config.deploy.strategy")
    );
    useEffect(() => {
        setInitStrategy(getValues("config.deploy.strategy"));
    }, [isSubmitted]);
    return (
        <RhfFormField
            label="Deployment Strategy"
            name="config.deploy.strategy"
            help="Deployment strategy can only be updated to manual. This action is irreversible."
        >
            <Controller
                render={({ field: { ref: _ref, ...field } }) => (
                    <DeploymentStrategySelect
                        {...field}
                        disabled={initStrategy === "manual"}
                        onFetchError={(e) => $error(JSON.stringify(e))}
                        isOptionValid={(o) =>
                            o.id === "manual" || o.id === initStrategy
                        }
                        isNullable={false}
                    />
                )}
                control={control}
                name="config.deploy.strategy"
            />
        </RhfFormField>
    );
}
