import {
    formatRFC3339,
    NearestMinutes,
    roundToNearestHours,
    roundToNearestMinutes,
    subHours,
} from "date-fns";
import { InstanceGranularity } from "../../types";

export type RamUsageByInstanceDataPoint = {
    time: string;
} & Record<string, number>;

export function buildRamUsageByInstanceQuery({
    criteria,
    granularity,
    instanceIds,
    range,
}: {
    criteria: Record<string, string>;
    granularity: InstanceGranularity;
    instanceIds: string[] | undefined;
    range: number;
}) {
    const bounds = { start: "", end: "" };

    switch (granularity.unit) {
        case "minute":
            bounds.start = formatRFC3339(
                roundToNearestMinutes(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestMinutes(new Date()));
            break;
        case "hour":
            bounds.start = formatRFC3339(
                roundToNearestHours(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestHours(new Date()));
            break;
    }

    const idFilter = instanceIds
        ? { "metadata.component._id": { $in: instanceIds } }
        : {};

    return {
        filter: {
            "range-start": bounds.start,
            "range-end": bounds.end,
        },
        body: {
            criteria: {
                "metadata.metric": "container.instance.resources.ram.used_kb",

                ...criteria,
                ...idFilter,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },

                {
                    $project: {
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: granularity.unit,
                                binSize: granularity.size,
                            },
                        },
                        metadata: "$metadata",
                        instance: "$metadata.component._id",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                            instance: "$instance",
                        },
                        ram_usage: {
                            $max: "$point_value",
                        },
                        last_time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $group: {
                        _id: "$last_time",
                        instances: {
                            $push: {
                                k: { $toString: "$_id.instance" },
                                v: { $multiply: ["$ram_usage", 1024] },
                            },
                        },
                    },
                },
                {
                    $project: {
                        time: "$_id",
                        _id: 0,
                        newRoot: {
                            $mergeObjects: [
                                { $arrayToObject: "$instances" },
                                { time: "$_id" },
                            ],
                        },
                    },
                },
                {
                    $replaceRoot: { newRoot: "$newRoot" },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: granularity.size,
                            unit: granularity.unit,
                            bounds: [bounds.start, bounds.end],
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
            ],
        },
    };
}

export function buildFindTopRamUsageInstancesQuery({
    criteria,
    granularity,
    limit,
    range,
}: {
    criteria: Record<string, string>;
    granularity: InstanceGranularity;
    limit: number;
    range: number;
}) {
    const bounds = { start: "", end: "" };

    switch (granularity.unit) {
        case "minute":
            bounds.start = formatRFC3339(
                roundToNearestMinutes(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestMinutes(new Date()));
            break;
        case "hour":
            bounds.start = formatRFC3339(
                roundToNearestHours(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestHours(new Date()));
            break;
    }

    return {
        filter: {
            "range-start": bounds.start,
            "range-end": bounds.end,
        },
        body: {
            criteria: {
                "metadata.metric": "container.instance.resources.ram.used_kb",
                ...criteria,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: granularity.unit,
                                binSize: granularity.size,
                            },
                        },
                        instance: "$metadata.component._id",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: granularity.size,
                            unit: granularity.unit,
                            bounds: [bounds.start, bounds.end],
                        },
                        partitionByFields: ["instance"], // Densify separately for each instance
                    },
                },
                {
                    $fill: {
                        output: {
                            point_value: { value: 0 }, // Fill missing values with zero
                        },
                    },
                },
                {
                    $group: {
                        _id: "$instance",
                        avgUsage: { $avg: "$point_value" },
                    },
                },
                {
                    $sort: {
                        avgUsage: -1,
                    },
                },
                {
                    $limit: limit,
                },
                {
                    $group: {
                        _id: null,
                        instanceIds: { $push: "$_id" },
                    },
                },
                {
                    $project: {
                        _id: 0,
                        instanceIds: 1,
                    },
                },
            ],
        },
    };
}
