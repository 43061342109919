import {
    faChevronDown,
    faFolderGear,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { useGetHubsQuery } from "~/services/cycle";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "../NavIcons";

export function HubSwitcher() {
    const { error } = useGetHubsQuery({});
    const hub = useAppSelector(selectActiveHub);
    if (error) {
        return <div className="text-error">Error loading hubs...</div>;
    }

    return (
        <Link
            to={generateDialogLink("hub-select", {})}
            replace
            className={classNames(
                "flex flex-grow items-center transition",
                "hover:text-cycle-white"
            )}
        >
            <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon
                        className="text-cycle-blue"
                        icon={NavIcons["hubs"]}
                    />
                    <span className="text-md">{hub?.name}</span>
                </div>
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        </Link>
    );
}
