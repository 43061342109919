import {
    Environment,
    useCreateVpnUserMutation,
    useGetVpnUsersQuery,
    useDeleteVpnUserMutation,
} from "~/services/cycle";
import {
    StyledTableRow,
    StyledDataTable,
    StyledTableHead,
    StyledHeaderCell,
    StyledCell,
    StyledIconHeaderCell,
} from "@cycleplatform/ui/components/tables";
import { getHumanizedTime, isZeroTime } from "@cycleplatform/core/util/time";
import {
    RHF_GLOBAL_ERROR,
    RhfFormError,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { RhfMultipleActionButton } from "~/components/common/forms/IdSelect/RhfMultipleActionButton";
import { RhfIdCheckbox } from "~/components/common/forms/IdSelect/RhfIdCheckbox";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faUserPlus } from "@fortawesome/pro-duotone-svg-icons";
import { useForm } from "react-hook-form";
import { handleSubmitError } from "~/components/forms/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
type VpnManageUsersFormProps = {
    environment: Environment | undefined;
};

type FormValues = {
    ids: string[];
    username: string;
    password: string;
};

export function VpnManageUsersForm({ environment }: VpnManageUsersFormProps) {
    const { data: users, error } = useGetVpnUsersQuery({
        environmentId: environment?.id || "",
    });
    const form = useForm({
        defaultValues: {
            ids: [],
            username: "",
            password: "",
        },
    });
    const [deleteVpnUser] = useDeleteVpnUserMutation();
    const [createVpnUser] = useCreateVpnUserMutation();
    const onCreate = (data: FormValues) => {
        return createVpnUser({
            environmentId: environment?.id || "",
            body: data,
        })
            .unwrap()
            .then(() => {
                form.reset();
            }, handleSubmitError(form.setError, { sourceOverride: { username: RHF_GLOBAL_ERROR, password: RHF_GLOBAL_ERROR } }));
    };

    const { isSubmitting, errors } = form.formState;

    if (error) {
        throw error;
    }

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Custom Authorized Accounts" />
                <PanelContent>
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledIconHeaderCell />
                            <StyledHeaderCell className="w-80">
                                Username
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-80">
                                Last Login
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-40" />
                        </StyledTableHead>
                        <tbody>
                            {users?.data?.map((u) => (
                                <StyledTableRow key={u.id}>
                                    <StyledCell>
                                        <RhfIdCheckbox id={u.id} />
                                    </StyledCell>
                                    <StyledCell>
                                        <div className="my-2">
                                            <span>{u.username}</span>
                                        </div>
                                    </StyledCell>
                                    <StyledCell>
                                        {isZeroTime(u.last_login)
                                            ? "Never"
                                            : getHumanizedTime(u.last_login)}
                                    </StyledCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledCell></StyledCell>
                                <StyledCell>
                                    <TextInput
                                        {...form.register("username")}
                                        autoComplete="off"
                                        placeholder="username"
                                    />
                                </StyledCell>
                                <StyledCell>
                                    <TextInput
                                        {...form.register("password")}
                                        autoComplete="off"
                                        placeholder="password"
                                    />
                                </StyledCell>
                                <StyledCell>
                                    <div className="flex justify-center">
                                        <LoaderButton
                                            isLoading={isSubmitting}
                                            icon={faUserPlus}
                                            onClick={form.handleSubmit(
                                                onCreate
                                            )}
                                        />
                                    </div>
                                </StyledCell>
                            </StyledTableRow>
                        </tbody>
                    </StyledDataTable>
                    <PanelFooter>
                        <RhfGlobalFormError />
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <RhfMultipleActionButton
                                resourceName="vpn members"
                                mutation={(id: string) =>
                                    deleteVpnUser({
                                        environmentId: environment?.id || "",
                                        userId: id,
                                    }).unwrap()
                                }
                            >
                                Delete Selected Accounts
                            </RhfMultipleActionButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
