import {
    RhfFormField,
    TextInput,
    startsWithSlash,
    required,
    FormSection,
    FormSectionHeader,
    hasNoSpaces,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { AddCredentialForm } from "./AddCredentialForm";
import { DockerFileAuthSection } from "./DockerFileAuthSection";
import { ImageSourceCreateEditSubmitType } from "../../util";

type RefType = "hash" | "tag" | "none";

export function GitRepoForm() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();

    return (
        <>
            <FormSectionHeader header="Git" />
            <FormSection>
                <InputRow>
                    <RhfFormField
                        label="git repo url"
                        name="origin.details.repo.url"
                        required
                    >
                        <TextInput
                            placeholder="https://github.com/repo.git"
                            {...register("origin.details.repo.url", {
                                ...required(),
                                shouldUnregister: true,
                                validate: {
                                    ...hasNoSpaces(),
                                },
                            })}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="git repo branch"
                        name="origin.details.repo.branch"
                    >
                        <TextInput
                            {...register("origin.details.repo.branch", {
                                shouldUnregister: true,
                            })}
                        />
                    </RhfFormField>
                </InputRow>

                <RhfFormField
                    label="context directory"
                    name="origin.details.context_dir"
                >
                    <TextInput
                        {...register("origin.details.context_dir", {
                            shouldUnregister: true,
                        })}
                    />
                </RhfFormField>

                <RhfFormField
                    label="build file"
                    name="origin.details.build_file"
                >
                    <TextInput
                        placeholder="/Dockerfile"
                        {...register("origin.details.build_file", {
                            shouldUnregister: true,
                            validate: {
                                ...startsWithSlash(
                                    `Destination path must begin with a "/"`
                                ),
                                ...hasNoSpaces(),
                            },
                        })}
                    />
                </RhfFormField>

                <RefFields />

                <DockerFileAuthSection />
            </FormSection>

            <AddCredentialForm />
        </>
    );
}

function RefFields() {
    const { register, unregister, watch, setValue } =
        useFormContext<ImageSourceCreateEditSubmitType>();

    const currentRefType = watch("origin.details.repo.ref.type");
    const [refType, setRefType] = useState<string>(
        !currentRefType ? "none" : currentRefType
    );

    const handleRefTypeChange = (rt: RefType) => {
        setRefType(rt);

        if (rt === "none") {
            setValue("origin.details.repo.ref.value", "", {
                shouldDirty: true,
            });
            unregister("origin.details.repo.ref", { keepDirty: true });
        } else {
            setValue(
                "origin.details.repo.ref",
                {
                    type: rt,
                    value: "",
                },
                {
                    shouldDirty: true,
                }
            );
        }
    };

    return (
        <div className="flex gap-4">
            <RhfFormField
                label="ref type"
                className="w-3/5"
                name="origin.details.repo.ref.type"
            >
                <BasicSelect
                    placeholder="Select a Ref Type..."
                    value={refType}
                    onChange={(v) => handleRefTypeChange(v as RefType)}
                    options={[
                        {
                            value: "none",
                            label: "None",
                        },
                        {
                            value: "branch",
                            label: "Branch",
                        },
                        {
                            value: "hash",
                            label: "Hash",
                        },
                        {
                            value: "tag",
                            label: "Tag",
                        },
                    ]}
                />
            </RhfFormField>

            <RhfFormField
                label="ref value"
                className="w-3/5"
                name="origin.details.repo.ref.value"
                required={refType !== "none"}
            >
                {refType !== "none" ? (
                    <TextInput
                        placeholder="Ref Value"
                        {...register("origin.details.repo.ref.value", {
                            ...required(),
                        })}
                    />
                ) : (
                    <TextInput placeholder="Ref Value" disabled />
                )}
            </RhfFormField>
        </div>
    );
}
