import { processCapabilities } from "@cycleplatform/core/modules/hub/capabilities";
import {
    FormSectionHeader,
    FormToggle,
} from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import {
    Capability,
    useGetHubCapabilitiesQuery,
    useGetHubMembershipQuery,
} from "~/services/cycle";
import { CapabilitiesSection } from "./CapabilitiesSection";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import classNames from "classnames";

export function CapabilitiesFormSection() {
    const { data: capabilities } = useGetHubCapabilitiesQuery();
    const formStructure = processCapabilities(
        capabilities?.meta.captions || {}
    );
    const form = useFormContext<{
        capabilities?: {
            all: boolean;
            specific: Capability[];
        } | null;
    }>();
    const { control, setValue } = form;
    const allCaps = useWatch({ name: "capabilities.all", control });

    const { data: self, error } = useGetHubMembershipQuery({
        include: ["roles"],
    });
    const viewerRole = self?.includes?.roles?.[self?.data?.role_id || ""];

    if (error) {
        throw error;
    }

    const isPrivilegedViewer = viewerRole?.capabilities.all || viewerRole?.root;

    return (
        <>
            <FormSectionHeader header="Allow All">
                <Tooltip
                    message={"Allow All is not available for your role"}
                    disabled={isPrivilegedViewer}
                >
                    <FormToggle
                        value={allCaps}
                        onChange={(v) =>
                            setValue("capabilities.all", v, {
                                shouldDirty: true,
                            })
                        }
                        disabled={!isPrivilegedViewer}
                    />
                </Tooltip>
            </FormSectionHeader>

            {!allCaps ? (
                <div
                    className={classNames(
                        "border-t pt-4",
                        "3xl:grid-cols-3 grid grid-cols-2 gap-4"
                    )}
                >
                    {Object.entries(formStructure).map(
                        ([section, capabilities]) => (
                            <CapabilitiesSection
                                section={section}
                                capabilities={capabilities}
                                viewerRole={viewerRole}
                            />
                        )
                    )}
                </div>
            ) : null}
        </>
    );
}
