import { Image } from "~/services/cycle";
import { FormField, TextAreaInput } from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFiles } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useEffect, useState } from "react";
import { parseEnvFile } from "@cycleplatform/core/util/env";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import classNames from "classnames";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { useFormContext, useWatch } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

type BulkUpdateVariablesProps = {
    onSave: (vars: Record<string, string>) => void;
    image?: Image;
    className?: string;
};

function varsToString(vars: Record<string, string>) {
    return Object.entries(vars).reduce((acc, [key, value]) => {
        return `${acc !== "" ? `${acc} \n` : ""}${key}=${value}`;
    }, "");
}

export function BulkUpdateVariables({
    onSave,
    className,
    image,
}: BulkUpdateVariablesProps) {
    return (
        <PositionedMenu
            className={classNames("w-[60rem]", className)}
            placement="left-start"
            render={(isOpen, setIsOpen) => (
                <BulkAddForm
                    onSave={onSave}
                    image={image}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        >
            <Tooltip message="Bulk Update Environment Variables">
                <Button className=" h-full !py-1 !px-2 text-xs">
                    <FontAwesomeIcon icon={faFiles} className="text-xs" />
                </Button>
            </Tooltip>
        </PositionedMenu>
    );
}

function BulkAddForm({
    onSave,
    image,
    isOpen,
    setIsOpen,
}: {
    image?: Image;
    onSave: (vars: Record<string, string>) => void;
    isOpen?: boolean;
    setIsOpen?: (open: boolean) => void;
}) {
    const { control } = useFormContext<ContainerConfigFormData>();

    const formEnvVars = useWatch({
        name: "config.runtime.environment_vars",
        control,
    });

    const [value, setValue] = useState<string>("");
    const [error, setError] = useState<string>();

    const updateVariables = () => {
        try {
            const parsed = parseEnvFile(value);
            if (image) {
                // ensure image variables are always present and cannot be removed.
                Object.entries(image.config.env).forEach(([name, value]) => {
                    if (!parsed[name]) {
                        parsed[name] = value;
                    }
                });
            }

            onSave(parsed);
            setIsOpen?.(false);
        } catch (e) {
            setError("Error parsing environment variables.");
        }
    };

    useEffect(() => {
        if (!isOpen || value === "") {
            setValue(varsToString(formEnvVars || {}));
        }
    }, [isOpen, formEnvVars]);

    return (
        <div>
            <div className="pb-4 text-lg">
                <FontAwesomeIcon icon={faFiles} className="pr-2" /> Bulk Update
                Environment Variables
            </div>
            <p>
                Enter your environment variables here, or paste in a .env file.
            </p>
            <FormField label="">
                <TextAreaInput
                    autoFocus
                    value={value}
                    error={!!error}
                    rows={15}
                    className="my-2 w-full"
                    placeholder={`VERSION=123\nPATH=/usr/local/sbin`}
                    onChange={(ev) => setValue(ev.target.value)}
                />
            </FormField>
            {error && <p className="text-error pb-2 text-sm">{error}</p>}
            <PanelFooter>
                <Button onClick={updateVariables} flavor="primary">
                    <FontAwesomeIcon icon={faPlus} /> Update Variables
                </Button>
            </PanelFooter>
        </div>
    );
}
