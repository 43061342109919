import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import {
    Environment,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    BarChart,
    Bar,
} from "recharts";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import { getLbDestinationsQuery } from "./query";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { useEffect, useState } from "react";
import { formatNumber } from "@cycleplatform/core/util/number";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerDestinationsAvailabilityChartProps = {
    className?: string;
    environment?: Environment;
    port: string | undefined;
};

export function LoadBalancerDestinationsAvailabilityChart({
    className,
    environment,
    port,
}: LoadBalancerDestinationsAvailabilityChartProps) {
    const { colors } = useGetThemedChartColors();

    const {
        data: telemetry,
        isLoading,
        isFetching,
    } = useGenerateAggregatedMetricsQuery(
        getLbDestinationsQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const [prevPort, setPrevPort] = useState<string | undefined>();
    const [isFetchingNewPort, setFetchingNewPort] = useState(false);

    useEffect(() => {
        if (prevPort === port && !isFetchingNewPort) {
            return;
        }
        setFetchingNewPort(isFetching);
        setPrevPort(port);
    }, [isFetching, port, prevPort]);

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    const processedData =
        telemetry?.data?.map((d) => {
            const { time, ...data } = d;

            if (Object.keys(data).length === 0) {
                return d;
            }

            const sum = Object.values(data).reduce(
                (sum: number, value: number) => {
                    return sum + value;
                },
                0
            );

            const test = Object.entries(data).reduce((acc, [key, value]) => {
                return { ...acc, [key]: value / sum };
            }, {});

            return { time, ...test };
        }) || [];

    return (
        <div
            className={classNames(
                className,
                "h-full",
                (isLoading || isFetchingNewPort) && skeletonStyles
            )}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    {...CycleChartProps}
                    // Slice the first point so that the chart times align with all the others
                    // The other charts use generateCumulativeDiff which does not have a diff until there are 2 points
                    data={processedData.slice(1)}
                    syncId={"lb-telem"}
                >
                    <CartesianGrid {...CycleGridProps} />
                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        tick={(props) => (
                            <FormattedYTick
                                {...props}
                                formatValue={(v: number) => `${v * 100}%`}
                            />
                        )}
                    />
                    <Tooltip
                        content={(props) => (
                            <CycleBarTooltip
                                {...props}
                                formatValue={(v: number) =>
                                    `${formatNumber(v * 100, { decimals: 2 })}%`
                                }
                            />
                        )}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="dest"
                        dataKey="success"
                        fill={colors["green"].DEFAULT}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="dest"
                        dataKey="invalid"
                        fill={colors["red"].DEFAULT}
                    />

                    <Bar
                        {...CycleBarProps}
                        stackId="dest"
                        dataKey="unavailable"
                        fill={colors["gray"].DEFAULT}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
