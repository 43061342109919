import { components } from "../api/__generated";

export function isBuildLogEnabled(image: components["schemas"]["Image"]) {
    return image.state?.current === "live" || !!image.state?.error;
}

export function getImageSourceTarget(
    source: components["schemas"]["ImageSource"]
) {
    switch (source.origin.type) {
        case "docker-registry":
        case "docker-hub":
            return source.origin.details?.target;
        case "docker-file":
            return source.origin.details?.repo?.url;
        case "oci-registry":
            return source.origin.details?.url;
        default:
            "Unknown Source";
    }
}

export function getImageBackendName(image: components["schemas"]["Image"]) {
    return image.backend ? image.backend?.provider : "Cached on Cycle";
}

export const getSourceDetails = (
    origin: components["schemas"]["ImageOrigin"],
    override?: {
        target?: string;
        targz_url?: string;
    }
) => {
    switch (origin.type) {
        case "docker-file":
            return (
                override?.targz_url ||
                origin?.details?.repo?.url ||
                "A git repo"
            );
        case "docker-registry":
        case "docker-hub":
        case "oci-registry":
            return override?.target || origin?.details?.target;
        default:
            return "Unknown";
    }
};
