import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { Children, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { Breadcrumb } from "../breadcrumb";
import { SkeletonText } from "../loaders/skeleton";
import { BetaBadge } from "../badges";
type ResourceDialogTabProps = {
    /**
     * to === null is used for the default tab
     * typically the dashboard tab
     */
    to: string | null;
    name: string;
    icon: IconDefinition;
    children: React.ReactNode;
    sidebar?: boolean;
    beta?: boolean;
};

type StyledResourceDialogProps = {
    open: boolean;
    onClose: () => void;
    breadcrumb?: Breadcrumb[];
    className?: string;
    header: React.ReactNode;
    hideSidebar?: boolean;
    children:
        | React.ReactElement<ResourceDialogTabProps>[]
        | React.ReactElement<ResourceDialogTabProps>;
};

const StyledResourceDialogSearchParams = {
    tab: "dialog-tab",
};
export function StyledResourceDialog({
    open,
    onClose,
    breadcrumb,
    className,
    header,
    children,
    hideSidebar,
}: StyledResourceDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classNames("z-50", className)}
        >
            <div
                className="pointer-events-none fixed inset-0  bg-black/50 backdrop-blur-[2px]"
                aria-hidden="true"
            />

            <div className="fixed inset-0 overflow-y-auto">
                <div
                    className={classNames(
                        "flex flex-col items-center justify-center px-16 pt-8 pb-24 text-center",
                        "h-full w-full"
                    )}
                >
                    <Dialog.Panel
                        className={classNames(
                            "!z-[60] h-full w-[75%] min-w-[95rem]"
                        )}
                    >
                        <div className="mb-2 flex w-full items-center justify-between">
                            <div className="bg-cycle-black-accent rounded-lg px-2 py-3">
                                {breadcrumb ? (
                                    <Breadcrumb trail={breadcrumb} />
                                ) : (
                                    <SkeletonText size="lg" className="my-2" />
                                )}
                            </div>
                            <div
                                className="flex flex-1 items-center justify-end"
                                onClick={onClose}
                            >
                                <button
                                    onClick={onClose}
                                    className={classNames(
                                        "bg-cycle-gray text-cycle-white flex h-10 w-10 items-center justify-center rounded-full opacity-90 transition-colors",
                                        "hover:text-cycle-blue hover:bg-cycle-black"
                                    )}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                        <section
                            className={classNames(
                                "dark:text-white",
                                "relative m-auto flex flex-col  bg-transparent text-left align-middle opacity-100 transition-all",
                                "h-full w-full"
                            )}
                        >
                            <header
                                className={classNames(
                                    "bg-cycle-black-accent dark:bg-cycle-gray-accent  z-20  h-16 w-full gap-4 rounded-t-md py-2 px-6"
                                )}
                            >
                                <div className="flex h-full gap-4 text-2xl">
                                    {header}
                                </div>
                            </header>
                            <div className="flex h-full overflow-hidden rounded-b-md ">
                                <aside
                                    className={classNames(
                                        "bg-cycle-black-accent dark:bg-cycle-gray-accent  w-[8rem] min-w-[8rem]",
                                        hideSidebar && "hidden"
                                    )}
                                >
                                    <nav className="flex w-full flex-col pl-3">
                                        {Children.map(children, (child) => (
                                            <SidebarLink
                                                to={child.props.to}
                                                name={child.props.name}
                                                icon={child.props.icon}
                                                beta={child.props.beta}
                                            />
                                        ))}
                                    </nav>
                                </aside>
                                <div
                                    className={classNames(
                                        "bg-cycle-white-accent dark:bg-cycle-black w-full overflow-hidden"
                                    )}
                                >
                                    {children}
                                </div>
                            </div>
                        </section>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}

export function ResourceDialogTab({ to, children }: ResourceDialogTabProps) {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("dialog-tab") as string;

    if (to === tab) {
        return <>{children}</>;
    } else {
        return null;
    }
}

type SidebarLinkProps = {
    to: string | null;
    name: string;
    icon: IconProp;
    beta?: boolean;
};

function SidebarLink({ to, name, icon, beta }: SidebarLinkProps) {
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState(
        searchParams.get(StyledResourceDialogSearchParams.tab)
    );
    const getSearchUrl = () => {
        const nsp = new URLSearchParams(searchParams);
        if (to) {
            nsp.set(StyledResourceDialogSearchParams.tab, to);
        } else {
            nsp.delete(StyledResourceDialogSearchParams.tab);
        }
        return nsp.toString();
    };

    const isMatch = tab === to || (to === null && !tab);

    useEffect(() => {
        if (searchParams.has(StyledResourceDialogSearchParams.tab)) {
            const newTab = searchParams.get(
                StyledResourceDialogSearchParams.tab
            );
            if (tab !== newTab) {
                setTab(searchParams.get(StyledResourceDialogSearchParams.tab));
            }
        } else {
            setTab(null);
        }
    }, [searchParams]);

    return (
        <div
            className={classNames(
                {
                    "bg-white font-bold shadow-[-3px_2px_3px_rgba(0,0,0,0.9)]  dark:bg-black dark:shadow-[-3px_2px_3px_rgba(0,0,0,0.1)]":
                        isMatch,
                },
                "rounded-tl-lg rounded-bl-lg font-semibold transition-all ",
                "[&>a]:block [&>a]:p-3"
            )}
        >
            <NavLink
                to={{ search: getSearchUrl() }}
                end={true}
                className={({ isActive }) =>
                    classNames(
                        { "font-bold": isActive },
                        {
                            "pointer-events-none": isMatch,
                        },
                        "!flex flex-col items-center justify-center"
                    )
                }
            >
                <div>
                    {icon && (
                        <FontAwesomeIcon
                            className="text-cycle-blue py-2 text-2xl"
                            icon={icon}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        " text-cycle-white hover:text-cycle-blue inline-block",
                        {
                            "!text-cycle-blue": isMatch,
                        }
                    )}
                >
                    <div className="flex flex-col items-center">
                        {name} {beta && <BetaBadge className="!ml-0 text-sm" />}
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
