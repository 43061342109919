import { useFormContext } from "react-hook-form";
import { CreateOrderApiArg, TierPlan } from "~/services/cycle";
import classNames from "classnames";
import {
    PLAN_TILE_CLASSNAME,
    PlanTier,
} from "@cycleplatform/ui/components/resources/plans/PlanTier";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";

export function PlanTierButton({
    tier,
    selectedTierId,
    currentTierId,
    activeTierPrice,
}: {
    tier: TierPlan;
    selectedTierId: string | undefined;
    currentTierId: string | null;
    activeTierPrice: number;
}) {
    const form = useFormContext<CreateOrderApiArg["body"]>();

    const { setValue } = form;

    return (
        <button
            type="button"
            className={classNames(
                getOptionButtonClassName(selectedTierId === tier.id),
                "p-4",
                PLAN_TILE_CLASSNAME
            )}
            onClick={(v) =>
                setValue("tier_plan_id", tier.id, {
                    shouldDirty: true,
                })
            }
        >
            <PlanTier
                tier={tier}
                isCurrentTier={currentTierId === tier.id}
                priceOverride={
                    currentTierId === tier.id
                        ? { price: activeTierPrice }
                        : undefined
                }
                key={tier.id}
            />
        </button>
    );
}
