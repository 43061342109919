import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../../util";
import {
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";

export function ProviderAuthFields() {
    const { control, watch } =
        useFormContext<ImageSourceCreateEditSubmitType>();
    const flavor = watch("origin.details.auth.details.flavor");
    const f = watch("origin.details.auth");

    let fields = null;
    switch (flavor) {
        case "ecr":
            fields = <EcrProviderFields />;
            break;
        // Can add more native provider types here as we expand
        default:
    }

    return (
        <>
            <RhfFormField
                label="Provider"
                name="origin.details.auth.details.flavor"
                required
            >
                <Controller
                    control={control}
                    name="origin.details.auth.details.flavor"
                    shouldUnregister
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <BasicSelect
                                {...field}
                                disabled
                                options={[{ label: "AWS ECR", value: "ecr" }]}
                            />
                        );
                    }}
                />
            </RhfFormField>

            {fields}
        </>
    );
}

function EcrProviderFields() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();

    return (
        <>
            <RhfFormField
                label="API Key"
                name="origin.details.auth.details.credentials.api_key"
                required
            >
                <TextInput
                    {...register(
                        "origin.details.auth.details.credentials.api_key",
                        {
                            ...required(),
                            shouldUnregister: true,
                        }
                    )}
                />
            </RhfFormField>
            <RhfFormField
                label="Secret"
                name="origin.details.auth.details.credentials.secret"
                required
            >
                <TextInput
                    {...register(
                        "origin.details.auth.details.credentials.secret",
                        {
                            ...required(),
                            shouldUnregister: true,
                        }
                    )}
                />
            </RhfFormField>
            <RhfFormField
                label="Region"
                name="origin.details.auth.details.credentials.region"
                required
            >
                <TextInput
                    placeholder="us-west-2"
                    {...register(
                        "origin.details.auth.details.credentials.region",
                        {
                            ...required(),
                            shouldUnregister: true,
                        }
                    )}
                />
            </RhfFormField>
        </>
    );
}
