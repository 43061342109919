import { useEffect, useState } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { InstanceChartDataPoint } from "../../helpers";

export function CpuUsageByInstanceChart({
    data,
    instanceColorMap,
    instanceLabelMap,
    instanceIds,
}: {
    data: InstanceChartDataPoint[];
    instanceColorMap: Record<string, string>;
    instanceLabelMap: Record<string, string>;
    instanceIds: string[];
}) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                syncId="container-telem"
                {...CycleChartProps}
                data={data}
            >
                <CartesianGrid {...CycleGridProps} />
                <XAxis
                    {...CycleAxisProps}
                    dataKey="time"
                    tick={(props) => <FormattedTimeTick {...props} />}
                />
                <YAxis
                    {...CycleAxisProps}
                    domain={([_, dataMax]) =>
                        dataMax < 100 ? [0, 100] : [0, Math.ceil(dataMax)]
                    }
                    tick={(props) => (
                        <FormattedYTick
                            {...props}
                            formatValue={(v: number) => `${v}%`}
                        />
                    )}
                />
                <Tooltip
                    content={(props) => (
                        <CycleLineTooltip
                            {...props}
                            labelMap={instanceLabelMap}
                            formatValue={(v: number) => `${v}%`}
                        />
                    )}
                />

                {[...instanceIds].map((id) => (
                    <Line
                        {...CycleLineProps}
                        key={id}
                        dataKey={id}
                        stroke={instanceColorMap[id]}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}
