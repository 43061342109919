import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import classNames from "classnames";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useGenerateAggregatedEventsQuery } from "~/services/cycle";
import { EventPoint, buildEventsQuery } from "./query";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { EventsContext } from "../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { eventTypeColorMap } from "../color";
import { NavIcons } from "~/components/layout/NavIcons";

export function EventsChart({
    criteria,
    className,
    skip,
}: {
    criteria: { [key: string]: unknown };
    className?: string;
    skip?: boolean;
}) {
    const { colors } = useGetThemedChartColors();

    const eventColors = eventTypeColorMap(colors);

    const {
        data: eventsReport,
        isLoading: eventsIsLoading,
        error,
    } = useGenerateAggregatedEventsQuery(buildEventsQuery(criteria), {
        pollingInterval: 60_000,
        skip,
    });
    const { focusDate, setFocusDate } = useContext(EventsContext);
    const [bars, setBars] = useState<string[]>([]);

    const points = eventsReport?.data as EventPoint[] | undefined;

    const chartRef = useRef<HTMLDivElement>(null);
    const [highlightWidth, setHighlightWidth] = useState(20);

    // Layout effect calculates the ideal width for the "active" bar highlight
    // The output is used as the input for the <ReferenceLine/>
    useLayoutEffect(() => {
        const chartWidth = chartRef?.current?.offsetWidth;
        if (chartWidth) {
            setHighlightWidth(
                points?.length
                    ? chartWidth / (points.length * 0.75)
                    : chartWidth / 30
            );
        }
    }, [chartRef?.current?.offsetWidth, points]);

    useEffect(() => {
        if (!points) {
            return;
        }

        const bs: string[] = [];

        points.forEach((p) => {
            const { time, ...priorities } = p;
            bs.push(...Object.keys(priorities));
        });

        setBars([...new Set(bs)]);
    }, [points]);

    // on load, select most recent activity event
    useEffect(() => {
        if (!setFocusDate) {
            return;
        }
        const p = points
            ?.filter((p) => Object.keys(p).length > 1)
            .slice(-1)?.[0];
        if (!p?.time) {
            return;
        }
        setFocusDate(p.time);
    }, [points]);

    // ---
    if (error) {
        throw error;
    }

    if (skip || eventsIsLoading) {
        return (
            <>
                <div className={classNames(skeletonStyles, "mb-4 h-60")} />
            </>
        );
    }

    if (!bars.length) {
        return (
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={NavIcons["telemetry"]}
                title="No Events"
            >
                <p className="text-center">
                    There have been no events logged in the last 24 hours.
                </p>
            </EmptyResource>
        );
    }

    return (
        <div
            className={classNames(
                "flex h-60 flex-col justify-between",
                (skip || eventsIsLoading) && skeletonStyles,
                className
            )}
            ref={chartRef}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    {...CycleChartProps}
                    data={points}
                    onMouseMove={
                        setFocusDate
                            ? (ev) => {
                                  if (
                                      ev.activeLabel &&
                                      ev.activePayload?.some(
                                          (p) => p.value !== undefined
                                      )
                                  ) {
                                      setFocusDate(ev.activeLabel);
                                  }
                              }
                            : undefined
                    }
                >
                    <CartesianGrid {...CycleGridProps} />
                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        allowDecimals={false}
                        tick={(props) => <FormattedYTick {...props} />}
                    />
                    <Tooltip
                        content={(props) =>
                            setFocusDate ? null : <CycleBarTooltip {...props} />
                        }
                    />
                    <ReferenceLine
                        x={focusDate}
                        stroke={colors["gray"].extraLight}
                        strokeWidth={highlightWidth}
                    />

                    {[...bars].map((b) => (
                        <Bar
                            {...CycleBarProps}
                            key={b}
                            stackId="priority"
                            dataKey={b}
                            fill={eventColors[b as "success"] || "black"}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
