import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";
import { ConfigureStepType, DialogFormStepTypes } from "../../../../types";
import { LearnMore } from "../LearnMore";
import { OptionButton } from "@cycleplatform/ui/components/options";
import {
    faDiagramProject,
    faDiagramSubtask,
} from "@fortawesome/pro-duotone-svg-icons";
import { ManageHostedDomain } from "./manageHostedDomain";
import { ManageHostedSubdomains } from "./manageHostedSubdomains";
import { useFormContext, useWatch } from "react-hook-form";

export function HostedConfig() {
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();
    const form = useFormContext<ConfigureStepType>();

    const { control, setValue } = form;
    const isAuthoritative = useWatch({ name: "isAuthoritative", control });

    return (
        <div className="flex gap-16">
            <div className="w-2/3 text-lg">
                <FormSectionHeader header="Domain" />
                <FormSection className="flex gap-4 pb-6">
                    <OptionButton
                        onClick={() =>
                            setValue("isAuthoritative", true, {
                                shouldDirty: true,
                            })
                        }
                        title="Manage Domain"
                        icon={faDiagramProject}
                        iconClassName="text-cycle-blue"
                        description={
                            <div>
                                Provides full authoritative control over{" "}
                                <p className="font-semibold">
                                    {values[0].origin}
                                </p>
                            </div>
                        }
                        isActive={isAuthoritative === true}
                    />

                    <OptionButton
                        onClick={() =>
                            setValue("isAuthoritative", false, {
                                shouldDirty: true,
                            })
                        }
                        title="Manage Subdomains "
                        icon={faDiagramSubtask}
                        iconClassName="text-cycle-blue"
                        description={
                            <div>
                                Provides authoritative control over specific
                                subdomains managed at{" "}
                                <p className="font-semibold">
                                    {values[0].origin}
                                </p>
                            </div>
                        }
                        isActive={isAuthoritative === false}
                    />
                </FormSection>

                {isAuthoritative === true ? (
                    <ManageHostedDomain />
                ) : (
                    <ManageHostedSubdomains />
                )}
            </div>
            <LearnMore />
        </div>
    );
}
