import { Tooltip } from "../../tooltip";
import { FormSection } from "../FormSection";
import { FormSectionHeader } from "../FormSectionHeader";
import { FormToggle } from "../FormToggle";
import { Controller, useFormContext, useWatch } from "react-hook-form";

type RhfEnableDisableFormSectionProps = {
    header: React.ReactNode;
    help?: string;
    isExpanded?: boolean;
    onToggle?: (v: boolean) => void;
    toggleDisabled?: boolean;
    name?: string;
    /**
     * Render child as a function to ensure that the content does not render
     * unless the isExpanded criteria is met
     *
     * This is important because the form elements will register to the form if they
     * render initially, which will cause the form to error out
     *
     * The children must conditionally render based on isExpanded value
     *
     * isExpanded  => <>(isExpanded && ()}</>
     */
    children: (isExpanded: boolean) => React.ReactNode;
    /**
     * For adding a branded logo to expandable section.  Should in wide aspect ratio
     */
    logo?: string;
    /**
     * Tooltip message to display on hover for toggle button
     */
    tooltip?: string;
    /**
     * Boolean value to display tooltip on toggle button
     */
    showTooltip?: boolean;
};

export function RhfEnableDisableFormSection({
    header,
    help,
    isExpanded: isExpandedOverride,
    onToggle,
    name,
    children,
    logo,
    tooltip,
    showTooltip,
    toggleDisabled,
}: RhfEnableDisableFormSectionProps) {
    const { control } = useFormContext();
    const watchName = useWatch({ name: name || "", control });
    const isExpanded =
        isExpandedOverride !== undefined ? isExpandedOverride : !!watchName;

    return (
        <>
            <FormSectionHeader header={header} help={help}>
                <div className="flex w-full items-center justify-end pl-4">
                    <div className="flex items-center gap-16">
                        {logo && <img src={logo} className="mx-2 w-40 py-2" />}

                        <Tooltip message={tooltip} disabled={!showTooltip}>
                            {name ? (
                                <Controller
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <FormToggle
                                            disabled={toggleDisabled}
                                            {...field}
                                            onChange={(v) => {
                                                field.onChange(v);
                                                onToggle?.(v);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={name}
                                />
                            ) : (
                                <FormToggle
                                    disabled={toggleDisabled}
                                    value={isExpanded}
                                    onChange={(v) => {
                                        onToggle?.(v);
                                    }}
                                />
                            )}
                        </Tooltip>
                    </div>
                </div>
            </FormSectionHeader>

            {isExpanded && (
                <ExpandedContentWrapper>
                    {children(isExpanded)}
                </ExpandedContentWrapper>
            )}
        </>
    );
}

function ExpandedContentWrapper({ children }: { children: React.ReactNode }) {
    if (children === <></>) {
        return null;
    }
    return <FormSection className="p-4">{children}</FormSection>;
}
