import { faInfinity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

type MeterDetailProps = {
    current: number;
    total: number;
    unlimited?: boolean;
    className?: string;
    formatValue?: (v: number) => string;
    /**
     * The resource being measured.  Example "servers"
     */
    resourceLabel?: string;
    /**
     * The label for the line indicating max total
     */
    totalLabel?: string;
    /**
     * The label for the value of data exceeding total example: "overcommit"
     */
    overageLabel?: string;
};

export function MeterDetail({
    current,
    total,
    unlimited = false,
    className,
    formatValue = (v) => `${v}`,
    resourceLabel = "",
    totalLabel = "Total",
    overageLabel = "Additional",
}: MeterDetailProps) {
    const overage = current - total;
    const { leftWidth, rightWidth } = getWidth(current, total, unlimited);
    const leftPercent = getLeftSidePercent(current, total);

    return (
        <div className={classNames("flex text-sm", className)}>
            <div
                className={classNames(
                    "border-r-2 border-black",
                    "relative pb-8"
                )}
                style={{
                    width: `${leftWidth}%`,
                }}
            >
                <div
                    className={classNames(
                        "w-full",
                        "bg-cycle-white dark:bg-cycle-gray-accent dark:text-cycle-white relative h-8 rounded-l-md "
                    )}
                >
                    <div
                        style={{ right: `${100 - leftPercent}%` }}
                        className={
                            "absolute inset-0 rounded-l-md  bg-green-500 transition-all"
                        }
                    />
                    <div
                        className={
                            "relative flex h-full w-full items-center justify-center font-bold leading-4 "
                        }
                    >
                        {formatValue(current < total ? current : total)}{" "}
                        {resourceLabel}
                    </div>
                </div>
                <div className="absolute bottom-0 right-2 font-bold">
                    {formatValue(total)} {totalLabel}
                </div>
            </div>

            {/* //Overage */}
            <div
                className={classNames("relative pb-8")}
                style={{
                    width: `${rightWidth}%`,
                }}
            >
                <div
                    className={classNames(
                        "bg-cycle-white dark:bg-cycle-gray-accent dark:text-cycle-white relative h-8 rounded-r-md "
                    )}
                >
                    <div
                        style={{
                            right: `${
                                100 - getOveragePercent(overage, total)
                            }%`,
                        }}
                        className={classNames(
                            "to-cycle-white dark:to-cycle-gray-accent from-40% bg-gradient-to-r",
                            unlimited ? " from-yellow-500" : "from-error",
                            "absolute inset-0 rounded-r-lg  transition-all"
                        )}
                    />
                    {overage > 0 ? (
                        <div
                            className={
                                "relative flex h-full w-full items-center justify-center font-semibold leading-4 "
                            }
                        >
                            {overage} {overageLabel}
                        </div>
                    ) : null}
                </div>

                {unlimited ? (
                    <div className="absolute bottom-0 right-2 font-bold">
                        <FontAwesomeIcon icon={faInfinity} />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
function getLeftSidePercent(current: number, total: number) {
    const leftPercent = (current / total) * 100;

    if (leftPercent < 100) {
        return leftPercent;
    } else {
        return 100;
    }
}

function getOveragePercent(overage: number, total: number) {
    if (overage <= 0) return 0;
    const percent = (overage / total) * 100;
    if (percent < 40) {
        return 40;
    } else if (percent > 80) {
        return 80;
    } else {
        return percent;
    }
}

function getWidth(current: number, total: number, unlimited: boolean) {
    const overage = current - total;
    const overagePercent = getOveragePercent(overage, total);

    // Hard Cap and No Overage
    if (!unlimited && overage <= 0) {
        return {
            leftWidth: 100,
            rightWidth: 0,
        };
    }

    // Has Overage
    if (overage > 0) {
        return {
            leftWidth: 100 - overagePercent / 1.5,
            rightWidth: overagePercent / 1.5,
        };
    }

    return {
        leftWidth: 80,
        rightWidth: 20,
    };
}
