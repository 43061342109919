import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faCheck,
} from "@fortawesome/pro-solid-svg-icons";

import { Activity, ComponentIncludes, Integration } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { getProviderName } from "@cycleplatform/core/modules/infrastructure/providers";

export function formatProviderMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractProviderName(item.component?.id || "", components);

    const providerName = <strong>{name}</strong>;
    switch (item.event) {
        case "infrastructure.provider.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested provider {providerName} be deleted</span>
                ) : (
                    <span>
                        Error requesting provider {providerName} be deleted
                    </span>
                ),
                icon: faTrash,
            };
        case "infrastructure.provider.task.verify":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested verification of provider {providerName}
                    </span>
                ) : (
                    <span>
                        Error requesting verification of provider {providerName}
                    </span>
                ),
                icon: faCheck,
            };
        case "infrastructure.provider.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated provider {providerName}</span>
                ) : (
                    <span>Error updating provider {providerName}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "infrastructure.provider.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created provider {providerName}</span>
                ) : (
                    <span>Error creating provider</span>
                ),
                icon: faPlus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractProviderName(
    id: string,
    components: ComponentIncludes
): string {
    const c = components?.[id] as Integration;

    if (!c) {
        return id;
    }
    return getProviderName(c);
}
