import { Checkbox } from "@cycleplatform/ui/components/forms";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ResourceWithId } from "~/modules/resource";
import { IdSelectType } from "./hooks";

type RhfIdCheckboxSelectAllProps<T extends ResourceWithId> = {
    resources?: T[];
    className?: string;
};

export function RhfIdCheckboxSelectAll<T extends ResourceWithId>({
    resources,
    className,
}: RhfIdCheckboxSelectAllProps<T>) {
    const { setValue } = useFormContext<IdSelectType>();
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        if (isChecked && resources) {
            setValue(
                "ids",
                resources?.map((r) => r.id)
            );
        } else {
            setValue("ids", []);
        }
    }, [isChecked]);

    return (
        <Tooltip message="Select All">
            <Checkbox
                onChange={() => setIsChecked(!isChecked)}
                className={classNames(className)}
            />
        </Tooltip>
    );
}
