import {
    PanelContent,
    PanelContentProps,
} from "@cycleplatform/ui/components/panels";
import { CycleErrorBoundary } from "~/components/common/errors";
import { Capability } from "~/services/cycle";

type PanelContentBoundaryProps = PanelContentProps & {
    capability?: Capability | Capability[];
    resourceName?: string;
};

export function PanelContentBoundary({
    capability,
    children,
    resourceName,
    ...panelContentProps
}: PanelContentBoundaryProps) {
    return (
        <PanelContent {...panelContentProps}>
            <CycleErrorBoundary
                capability={capability}
                resourceName={resourceName}
            >
                {children}
            </CycleErrorBoundary>
        </PanelContent>
    );
}
