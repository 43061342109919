import {
    FormSectionHeader,
    FormSection,
    RhfFormField,
    Checkbox,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";

export function HostSection() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <FormSectionHeader header="Host" />
            <FormSection>
                <RhfFormField
                    label="Expose Host's /Proc"
                    name="config.runtime.host.expose_proc"
                >
                    <Checkbox
                        {...register("config.runtime.host.expose_proc")}
                    />
                </RhfFormField>
            </FormSection>
        </>
    );
}
