import { createContext } from "react";
import { Container, Environment, VirtualMachine } from "~/services/cycle";

type VirtualMachineDialogContex = {
    environment: Environment | undefined;
    vm: VirtualMachine | undefined;
    container: Container | undefined;
};
export const VirtualMachineDialogContex =
    createContext<VirtualMachineDialogContex>({
        environment: undefined,
        vm: undefined,
        container: undefined,
    });
