import { components } from "../api/__generated";

export function processCapabilities(captions: { [key: string]: string }) {
    return Object.entries(captions || {}).reduce(
        (acc, [identifier, caption]) => {
            const capIdentifier =
                identifier as components["schemas"]["Capability"];
            const [sectionString, action] = caption.split(" -> ");
            const [section, subsection] = sectionString?.split(" / ");
            if (acc[section]) {
                return {
                    ...acc,
                    [section]: [
                        ...acc[section],
                        {
                            identifier: capIdentifier,
                            label: ` ${action} ${subsection || ""}`,
                        },
                    ],
                };
            }

            return {
                ...acc,
                [section]: [
                    {
                        identifier: capIdentifier,
                        label: `${action} ${subsection || ""} `,
                    },
                ],
            };
        },
        {} as Record<
            string,
            { identifier: components["schemas"]["Capability"]; label: string }[]
        >
    );
}
