type Colors = "green" | "blue" | "red" | "gray" | "darkgray";

// https://shiroyasha.svbtle.com/escape-sequences-a-quick-guide-1

export function colorTerminalLine(
    value: string,
    color: Colors,
    params?: { bold?: boolean; background?: boolean }
) {
    const escChar = `\x1B[`;

    let c;
    switch (color) {
        case "blue":
            c = params && params.background ? "48;5;39" : "38;5;39";
            break;
        case "green":
            c = params && params.background ? "48;5;34" : "38;5;34";
            break;
        case "red":
            c = params && params.background ? "48;5;124" : "38;5;124";
            break;
        case "gray":
            c = params && params.background ? "48;5;249" : "38;5;249";
            break;
        default:
        case "darkgray":
            c = params && params.background ? "48;5;243" : "38;5;243";
            break;
    }

    const modifier = params && params.bold ? ";1" : "";

    return `${escChar}${c}${modifier}m${value}${escChar}0m`;
}
