import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { TriggerKeyEditForm } from "~/components/pipelines/forms";
import { Pipeline, TriggerKey } from "~/services/cycle";

type TriggerKeySettingsProps = {
    triggerKey?: TriggerKey;
    pipeline?: Pipeline;
    pipelineId: string;
    fullTriggerKey?: string;
};

export function TriggerKeySettings({
    triggerKey,
    pipeline,
    pipelineId,
    fullTriggerKey,
}: TriggerKeySettingsProps) {
    return (
        <Panel>
            <PanelTitle title="Settings" />
            <PanelContent>
                <TriggerKeyEditForm
                    pipelineId={pipelineId}
                    triggerKey={triggerKey}
                    pipeline={pipeline}
                    fullTriggerKey={fullTriggerKey}
                />
            </PanelContent>
        </Panel>
    );
}
