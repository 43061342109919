import {
    BasicSelect,
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { useMemo } from "react";
import { useGetEnvironmentDeploymentsQuery } from "~/services/cycle";

type DeploymentTagSelectProps = {
    name?: string;
    environmentId?: string | null;
    isCreatable?: boolean;
    value?: string;
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value?: string | null) => void;
};

export function DeploymentTagSelect({
    value,
    onChange,
    isCreatable,
    environmentId,
    ...props
}: DeploymentTagSelectProps) {
    const { data: deployments, error } = useGetEnvironmentDeploymentsQuery(
        {
            environmentId: environmentId || "",
        },
        { skip: !environmentId }
    );
    if (error) {
        throw error;
    }

    const tags = useMemo(
        () =>
            Object.entries(deployments?.data.versions || {})
                .map(([v, d]) =>
                    d.tags.map((t) => ({
                        name: t,
                        version: v,
                        containers: d.containers,
                    }))
                )
                .flat(),
        [deployments?.data]
    );

    if (isCreatable) {
        return (
            <SelectInput
                {...props}
                isCreatable
                value={{
                    name: value || "",
                    version: "",
                    containers: 0,
                }}
                placeholder="Choose a version tag or create a new one"
                options={!!environmentId ? tags : []}
                formatDisplayValue={(t) => {
                    if (t.version === "") {
                        return t.name;
                    }
                    return `${t.name} [${t.version} - ${t.containers} containers]`;
                }}
                formatCreatableValue={(v) => ({
                    name: v,
                    version: "",
                    containers: 0,
                })}
                creatablePlaceholder={(v) => `Use deployment tag "${v}"`}
                onChange={(v) => onChange?.(v?.name)}
                formatOption={(t) => (
                    <FormattedOption
                        label={`${t.name} [${t.version} - ${t.containers} containers]`}
                    />
                )}
            />
        );
    }

    return (
        <BasicSelect
            {...props}
            value={value}
            onChange={onChange}
            options={
                !!environmentId
                    ? tags.map((t) => ({
                          value: t.name,
                          label: `${t.name} (current: ${t.version} / ${
                              t.containers
                          } container${t.containers !== 1 ? "s" : ""})`,
                      }))
                    : []
            }
        />
    );
}
