type ResourceNavHeaderProps = {
    children: React.ReactNode;
};

/**
 * Creates the proper padding for use inside a ResourceNavLayout component for the header.
 *
 * Should contain search, filter, and action button functionality.
 */
export function ResourceNavHeader({ children }: ResourceNavHeaderProps) {
    return <div className="pb-4 pr-4">{children}</div>;
}
