import classNames from "classnames";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    BarChart,
    Bar,
} from "recharts";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { buildInstanceHistoryChartQuery } from "./query";
import { useGetStateColorMap } from "~/util/charts/hooks";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type instanceHistoryBarChartProps = {
    className?: string;
    containerId?: string;
};

export function InstanceHistoryBarChart({
    containerId,
    className,
}: instanceHistoryBarChartProps) {
    const { data: telemetry, isLoading } = useGenerateAggregatedMetricsQuery(
        buildInstanceHistoryChartQuery(containerId),
        { skip: !containerId, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const { colors } = useGetStateColorMap();

    return (
        <div
            className={classNames(
                className,
                "h-full",
                isLoading && skeletonStyles
            )}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart {...CycleChartProps} data={telemetry?.data}>
                    <CartesianGrid />
                    <Tooltip
                        content={(props) => <CycleBarTooltip {...props} />}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="deleting"
                        fill={colors["deleting"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="deleted"
                        fill={colors["deleted"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="failed"
                        fill={colors["failed"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="migrating"
                        fill={colors["migrating"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="new"
                        fill={colors["new"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="reimaging"
                        fill={colors["reimaging"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="running"
                        fill={colors["running"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="starting"
                        fill={colors["starting"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="stopped"
                        fill={colors["stopped"]}
                    />
                    <Bar
                        {...CycleBarProps}
                        stackId="state"
                        dataKey="stopping"
                        fill={colors["stopping"]}
                    />

                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        allowDecimals={false}
                        domain={[0, "dataMax"]}
                        tick={(props) => <FormattedYTick {...props} />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
