import { faTrash, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    AutoScaleGroup,
    InfrastructureIpPool,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { faGroupArrowsRotate } from "@fortawesome/pro-duotone-svg-icons";

export function formatInfrastructureAutoscaleMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractAutoscaleGroupName(
        item.component?.id || "",
        components
    );
    const autoscaleGroupLink = (
        <Link to={`/infrastructure/autoscaling/${item.component?.id}`}>
            {name}
        </Link>
    );
    switch (item.event) {
        case "infrastructure.autoscale.group.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created new auto-scale group {autoscaleGroupLink}
                    </span>
                ) : (
                    <span>
                        Error creating new auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faGroupArrowsRotate,
            };
        case "infrastructure.autoscale.group.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated auto-scale group {autoscaleGroupLink}</span>
                ) : (
                    <span>
                        Error updating auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faGroupArrowsRotate,
            };
        case "infrastructure.autoscale.group.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted auto-scale group {autoscaleGroupLink}</span>
                ) : (
                    <span>
                        Error deleting auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "infrastructure.autoscale.group.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of auto-scale group{" "}
                        {autoscaleGroupLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of auto-scale group{" "}
                        {autoscaleGroupLink}
                    </span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
            };
    }
}

function extractAutoscaleGroupName(id: string, components: ComponentIncludes) {
    const ag = components?.[id];
    if (!ag) {
        return id;
    }

    return (ag as AutoScaleGroup).name;
}
