import {
    DiscoveryConfig,
    Environment,
    useCreateDiscoveryServiceJobMutation,
} from "~/services/cycle";
import {
    StyledTableRow,
    StyledDataTable,
    StyledTableHead,
    StyledHeaderCell,
    StyledCell,
    StyledIconHeaderCell,
} from "@cycleplatform/ui/components/tables";
import {
    RHF_GLOBAL_ERROR,
    RhfFormError,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { useJobTracker } from "~/modules/jobs/hooks";

type HostnameEntry = {
    hostname: string;
    ipv4?: string[];
    ipv6?: string[];
};

function getDefaultValues(environment: Environment | undefined) {
    return {
        hosts: Object.entries(
            environment?.services.discovery?.config?.hosts || []
        ).map(([key, value]) => ({
            hostname: key,
            ipv4: value.ipv4 || undefined,
            ipv6: value.ipv6 || undefined,
        })),
    };
}

export function HostsManageForm() {
    const { environment } = useContext(ContainerDialogContext);

    const form = useForm<{ hosts: HostnameEntry[] }>({
        defaultValues: getDefaultValues(environment),
        ...rhfConfig,
    });

    const [updateDiscovery] = useCreateDiscoveryServiceJobMutation();
    const [trackJob] = useJobTracker();

    useKeepFormCurrent(form, environment, (e) => getDefaultValues(e));

    const submit = (data: { hosts: HostnameEntry[] }) => {
        return trackJob(
            updateDiscovery({
                environmentId: environment?.id || "",
                body: {
                    action: "reconfigure",
                    contents: {
                        config: {
                            hosts: data.hosts.reduce((arr, cur) => {
                                arr[cur.hostname] = {};
                                if (cur.ipv4 && !!cur.ipv4.length) {
                                    arr[cur.hostname].ipv4 = cur.ipv4;
                                }

                                if (cur.ipv6 && !!cur.ipv6.length) {
                                    arr[cur.hostname].ipv6 = cur.ipv6;
                                }

                                return arr;
                            }, {} as NonNullable<NonNullable<DiscoveryConfig>["hosts"]>),
                        },
                    },
                },
            }).unwrap()
        )
            .then(() => form.reset((formValues) => ({ ...formValues })))
            .catch(
                handleSubmitError(form.setError, {
                    sourceOverride: { hosts: RHF_GLOBAL_ERROR },
                })
            );
    };

    const { isSubmitting, isDirty } = form.formState;

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: `hosts`,
    });

    return (
        <Panel>
            <RhfFormProvider {...form} onSubmit={form.handleSubmit(submit)}>
                <PanelTitle title="Custom Host Resolver" />
                <PanelContent>
                    <StyledDataTable className=" border-spacing-4">
                        <StyledTableHead>
                            <StyledHeaderCell className="w-96">
                                Domain
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-80">
                                IPv4
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-80">
                                IPv6
                            </StyledHeaderCell>
                            <StyledIconHeaderCell />
                        </StyledTableHead>
                        <tbody>
                            {fields.map((entry, index) => (
                                <StyledTableRow key={entry.id}>
                                    <StyledCell className="pr-4">
                                        <TextInput
                                            {...form.register(
                                                `hosts.${index}.hostname`,
                                                { ...required() }
                                            )}
                                        />
                                    </StyledCell>
                                    <StyledCell className="pr-4">
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => {
                                                return (
                                                    <MultiSelectInput
                                                        {...field}
                                                        value={
                                                            field.value || []
                                                        }
                                                        isCreatable
                                                        formatOption={(o) => (
                                                            <FormattedOption
                                                                label={o}
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                            control={form.control}
                                            name={`hosts.${index}.ipv4`}
                                        />
                                    </StyledCell>
                                    <StyledCell className="pr-4">
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => {
                                                return (
                                                    <MultiSelectInput
                                                        {...field}
                                                        value={
                                                            field.value || []
                                                        }
                                                        isCreatable
                                                        formatOption={(o) => (
                                                            <FormattedOption
                                                                label={o}
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                            control={form.control}
                                            name={`hosts.${index}.ipv6`}
                                        />
                                    </StyledCell>
                                    <StyledCell>
                                        <Button
                                            flavor="discard"
                                            icon={faTrash}
                                            onClick={() => remove(index)}
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledCell colSpan={4}>
                                    <Tooltip message="add new resolver">
                                        <AccessControlOverlay
                                            aclResource={environment}
                                            verifyFn={modifyAccessFn(
                                                "environments-services-manage"
                                            )}
                                        >
                                            <Button
                                                className="w-full"
                                                icon={faPlus}
                                                onClick={() =>
                                                    append({
                                                        hostname: "",
                                                        ipv4: [],
                                                        ipv6: [],
                                                    })
                                                }
                                            >
                                                Add
                                            </Button>
                                        </AccessControlOverlay>
                                    </Tooltip>
                                </StyledCell>
                            </StyledTableRow>
                        </tbody>
                    </StyledDataTable>

                    <PanelFooter>
                        <RhfGlobalFormError />
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <LoaderButton
                                isLoading={isSubmitting}
                                icon={faEdit}
                                disabled={!isDirty}
                                flavor="primary"
                                type="submit"
                            >
                                Update Host Resolutions
                            </LoaderButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
