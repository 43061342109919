import { useMemo } from "react";

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { getAbbreviatedNumberString } from "@cycleplatform/core/util/number";
import {
    pieCenterTextProps,
    pieProps,
} from "@cycleplatform/ui/components/recharts/pie/props";
import { LbUrlQueryTelem } from "./query";
import { CyclePieTooltip } from "@cycleplatform/ui/components/recharts/pie/CyclePieTooltip";
import { brandColors } from "tailwindcss-config/colors";
import { useGetThemedChartColors } from "~/util/charts/hooks";

export function HandlersPieChart({
    destinationMetric,
}: {
    destinationMetric: LbUrlQueryTelem[number];
}) {
    const { cache_hits, forward_hits, proxy_hits, redirect_hits } =
        destinationMetric;

    const { colors } = useGetThemedChartColors();

    const chartData = [
        {
            label: "cache hits",
            value: cache_hits,
            color: colors["blue"].dark,
        },
        {
            label: "forward hits",
            value: forward_hits,
            color: colors["purple"].DEFAULT,
        },

        {
            label: "proxy hits",
            value: proxy_hits,
            color: colors["blue"].DEFAULT,
        },
        {
            label: "redirect hits",
            value: redirect_hits,
            color: colors["gray"].DEFAULT,
        },
    ];

    const total = useMemo(
        () => cache_hits + forward_hits + proxy_hits + redirect_hits,
        [destinationMetric]
    );

    return (
        <div className="flex w-full">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart margin={{ top: 1, bottom: 1 }}>
                    <Pie {...pieProps} data={chartData} dataKey="value">
                        {chartData.map((entry, index) => {
                            return (
                                <>
                                    <Cell
                                        className="cursor-pointer"
                                        key={`cell-${entry.label}`}
                                        fill={entry.color}
                                        stroke="rgba(0,0,0,0)"
                                    />
                                </>
                            );
                        })}
                    </Pie>
                    <text {...pieCenterTextProps}>
                        {total ? (
                            <>{getAbbreviatedNumberString(total)}</>
                        ) : null}
                    </text>

                    <Tooltip
                        reverseDirection={{ x: true, y: true }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        offset={-5}
                        content={(props) => <CyclePieTooltip {...props} />}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}
