import { formatDateString } from "@cycleplatform/core/util";

export function FormattedTimeTick({
    x,
    y,
    stroke,
    payload,
    index,
    visibleTicksCount,
}: {
    [key: string]: unknown;
}) {
    const val =
        payload && typeof payload === "object" && "value" in payload
            ? payload.value
            : undefined;
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                dx={index === (visibleTicksCount as number) - 1 ? 0 : -25}
                className="fill-cycle-gray dark:fill-cycle-white text-xs font-semibold"
            >
                {formatDateString(new Date(val as string), "MMM d ha")}
            </text>
        </g>
    );
}
