import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";

export function DialogHeader({
    icon,
    title,
    children = <></>,
}: {
    icon?: IconDefinition;
    title: React.ReactNode;
    children?: React.ReactNode;
}) {
    return (
        <div
            className={classNames(
                "rounded-t-lg px-8 pt-6 pb-4",
                "dark:bg-cycle-black-accent bg-white"
            )}
        >
            <Dialog.Title
                className={classNames(
                    "text-2xl",
                    "flex  items-center justify-between"
                )}
            >
                <div className="flex grow items-center gap-4 ">
                    {icon ? (
                        <FontAwesomeIcon
                            icon={icon}
                            className="text-cycle-blue"
                        />
                    ) : null}
                    <div className="w-full whitespace-nowrap">{title}</div>
                </div>

                {children}
            </Dialog.Title>
        </div>
    );
}
