import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { Controller, useFormContext } from "react-hook-form";
import { RhfFormField, required } from "@cycleplatform/ui/components/forms";
import { LoadBalancerFormValues } from "../../../../../../form";

export function V1RouterMode({
    baseRoute,
    idx,
}: {
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
}) {
    const { control } = useFormContext<LoadBalancerFormValues>();
    return (
        <div className="flex gap-4">
            <RhfFormField
                label="Mode"
                name={`${baseRoute}.${idx}.mode`}
                className="w-full"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <BasicSelect
                            {...field}
                            options={[
                                {
                                    label: "Random",
                                    value: "random",
                                },
                                {
                                    label: "Round Robin",
                                    value: "round-robin",
                                },
                            ]}
                        />
                    )}
                    rules={{ ...required() }}
                    control={control}
                    name={`${baseRoute}.${idx}.mode`}
                />
            </RhfFormField>
        </div>
    );
}
