export const ContainerDialogSearchParams = {
    tab: "dialog-tab",
    containerId: "container-id",
    instanceId: "instance-id",
    volumeId: "volume-id",
    config: "config",
    integration: "integration",
    volume: "volume-id",
    sftp: "sftp",
    backup: "backup",
    focus: "focus",
    activityPage: "activity-page",
    advanced: "advanced",
} as const;
