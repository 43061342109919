import { useForm } from "react-hook-form";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { RhfFormProvider, TextInput } from "@cycleplatform/ui/components/forms";
import { LoadBalancerFormValues, getDefaultLbFormVals } from "../form";
import { rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { useContext, useState } from "react";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside } from "@cycleplatform/ui/components/page";
import { DialogResourceList } from "~/components/layout/resources";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadBalancerDialogContext } from "../../context";
import { V1AddController } from "./form/V1AddController";
import { PortList } from "./PortList";
import { PortContent } from "./PortContent";
import { useCapability } from "~/modules/hubs/permissions/useCapability";

export function V1Controllers() {
    const { service } = useContext(LoadBalancerDialogContext);
    const form = useForm<LoadBalancerFormValues>({
        defaultValues: getDefaultLbFormVals(service),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, service, (lb) => {
        return getDefaultLbFormVals(lb);
    });

    const [search, setSearch] = useState("");

    const [idxToPortMap, setIdxToPortMap] = useState<Record<string, number>>(
        {}
    );

    if (!service) {
        return null;
    }

    return (
        <RhfFormProvider {...form} className="h-full">
            <div className="h-full">
                <DialogPageBody className="!p-0">
                    <PageAside className="relative h-full !w-auto border-r bg-white dark:bg-black">
                        <DialogResourceList>
                            <div className="pb-2 pl-1 pr-4">
                                <TextInput
                                    placeholder="Search Ports"
                                    onChange={(v) => setSearch(v.target.value)}
                                    value={search}
                                    prefix={<FontAwesomeIcon icon={faSearch} />}
                                    className="!mb-0"
                                />
                            </div>

                            <PortList
                                search={search}
                                setIdxToPortMap={setIdxToPortMap}
                            />
                            <div
                                className={classNames(
                                    "bg-cycle-white absolute bottom-0 left-0 right-0 flex w-full justify-between gap-2 p-4 shadow-lg",
                                    "dark:bg-cycle-black"
                                )}
                            >
                                <V1AddController />
                            </div>
                        </DialogResourceList>
                    </PageAside>

                    <PortContent idxToPortMap={idxToPortMap} />
                </DialogPageBody>
            </div>
        </RhfFormProvider>
    );
}
