import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterContext, FilterValueType } from "./FilterContext";
import classNames from "classnames";
import { Children, cloneElement, isValidElement, useContext } from "react";
import { PositionedMenu } from "../../menus";
import { FilterGroupProperties } from "./FilterGroup";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const sharedClassNames = classNames(
    "text-cycle-black bg-white dark:bg-cycle-black dark:text-white ",
    "h-[24px] items-center  p-1 px-2 text-xs",
    "border-cycle-gray/30 border-l border-t border-b dark:border-cycle-white/10 "
);
export function ActiveFilter({
    groupName,
    groupIdentifier,
    filterValues,
    children,
}: {
    groupName: string;
    groupIdentifier: string;
    filterValues: FilterValueType[];
    children: React.ReactElement<FilterGroupProperties>[];
}) {
    const { deleteFilter, groupDetails } = useContext(FilterContext);

    if (!filterValues || filterValues.length === 0) {
        return null;
    }

    const filterIcon = groupDetails?.[groupIdentifier]?.icon;
    const isRequired = groupDetails?.[groupIdentifier]?.isRequired;

    return (
        <div className="flex items-center">
            <PositionedMenu
                className={"w-[20rem]"}
                placement="bottom-end"
                render={(_, onClose) => (
                    <RenderChildren
                        groupIdentifier={groupIdentifier}
                        onClose={onClose}
                    >
                        {children}
                    </RenderChildren>
                )}
                stretch
                hideCloseButton
            >
                <button
                    key={groupName}
                    className={classNames(
                        "flex h-[32px] items-center text-base ",
                        "[&>div:first-child]:rounded-l-md", // Round only the first child
                        "[&>div:last-child]:rounded-r-md  [&>div:last-child]:border-r"
                    )}
                >
                    <div className={classNames(sharedClassNames)}>
                        {!!filterIcon && (
                            <FontAwesomeIcon
                                icon={filterIcon}
                                className="pr-2"
                            />
                        )}
                        {groupName}{" "}
                    </div>
                    <div className={classNames(sharedClassNames)}>
                        {filterValues.length === 1 ? "is" : "is any of"}
                    </div>

                    {filterValues.map((v) => (
                        <div
                            key={`active-filter-name-${v.name}`}
                            className={classNames(
                                sharedClassNames,
                                "!bg-cycle-blue/20",
                                !isRequired &&
                                    "!rounded-r-none !border-r-transparent"
                            )}
                        >
                            {v.name}
                        </div>
                    ))}
                </button>
            </PositionedMenu>
            {isRequired ? null : (
                <button
                    key={`active-filter-required-${groupName}`}
                    className={classNames(
                        sharedClassNames,
                        "border-l border-r",
                        "rounded-r",
                        "!bg-cycle-gray/20"
                    )}
                    onClick={() => deleteFilter(groupIdentifier)}
                >
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                </button>
            )}
        </div>
    );
}

function RenderChildren({
    onClose,
    groupIdentifier,
    children,
}: {
    onClose: (open: boolean) => void;
    groupIdentifier: string;
    children: React.ReactElement<FilterGroupProperties>[];
}) {
    const enhancedChildren = Children.map(children, (child) =>
        isValidElement(child)
            ? cloneElement(child, { filter: groupIdentifier, onClose })
            : child
    );

    return <div className="p-4 pt-4">{enhancedChildren}</div>;
}
