import { HoverSecret } from "@cycleplatform/ui/components/forms";
import { LabeledVariableInfo } from "./helpers";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { Link } from "react-router-dom";
import { ScopedVariableRow } from "./ScopedVariableRow";

export function ContainerScopedVariablesTable({
    labeledVariableInfo,
}: {
    labeledVariableInfo: LabeledVariableInfo[];
}) {
    return (
        <>
            <StyledDataTable>
                <StyledTableHead>
                    <StyledHeaderCell>Source</StyledHeaderCell>
                    <StyledHeaderCell>Key</StyledHeaderCell>
                    <StyledHeaderCell>Value</StyledHeaderCell>
                </StyledTableHead>
                <tbody>
                    {labeledVariableInfo.map((lv) => {
                        const scopedVariable = lv.scopedVariable;

                        if (!scopedVariable) {
                            return (
                                <StyledTableRow>
                                    <StyledCell>{lv.label}</StyledCell>
                                    <StyledCell>{lv.key}</StyledCell>
                                    <StyledCell className="!w-[20rem]">
                                        <HoverSecret
                                            className="!w-[20rem]"
                                            value={lv.value}
                                            isObscured={false}
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            );
                        }

                        return (
                            <ScopedVariableRow
                                labeledVariable={lv}
                                scopedVariable={scopedVariable}
                            />
                        );
                    })}
                </tbody>
            </StyledDataTable>
            <div className="p-4">
                To view variables applied by Cycle, see the{" "}
                <Link to="https://cycle.io/docs/platform/container-environment-variables#platform-environment-variables">
                    Cycle Platform Environment Variables
                </Link>{" "}
                documentation.
            </div>
        </>
    );
}
