export function parseEnvFile(content: string): Record<string, string> {
    return content
        .trim()
        .split(/\r?\n/u)
        .reduce((result, elem) => {
            const line = elem.trim();
            if (!line || line.startsWith("#")) {
                return result;
            }
            const splitIndex = line.indexOf("=");
            const key = line.substring(0, splitIndex);
            const val = line.substring(splitIndex + 1);
            if (!key) {
                throw new Error(`Missing key for environment variable.`);
            }
            result[key] =
                val.startsWith("'") && val.endsWith("'")
                    ? val.slice(1, -1)
                    : val;
            return result;
        }, {} as Record<string, string>);
}
