import {
    faTrash,
    faTimesCircle,
    faPersonDolly,
    faBoxUp,
    faMedkit,
    faTerminal,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    Activity,
    ComponentIncludes,
    Container,
    Environment,
    Instance,
    Server,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { faArrowUpRightDots } from "@fortawesome/pro-duotone-svg-icons";
import { NavIcons } from "~/components/layout/NavIcons";

export function formatInstanceMessage(
    item: Activity,
    components: ComponentIncludes
) {
    // create instance link
    const instanceName = extractInstanceName(
        item.component?.id || "",
        components
    );
    const instanceLink = (
        <Link
            to={generateDialogLink("container", {
                "dialog-container-id": item.context.container_id || "",
                "dialog-tab": "instances",
                "dialog-instance-id": item.component?.id || "",
            })}
        >
            {instanceName}
        </Link>
    );

    // create container link
    const containerName = extractContainerName(
        item.context.container_id || "",
        components
    );
    const containerLink = (
        <Link
            to={generateDialogLink("container", {
                "dialog-container-id": item.context.container_id || "",
            })}
        >
            {containerName}
        </Link>
    );

    // create environment link
    const envName = extractEnvironmentName(
        item.context.environment_id || "",
        components
    );
    const envLink = (
        <Link to={`/environments/${item.context.environment_id}`}>
            {envName}
        </Link>
    );

    switch (item.event) {
        // multiple instances
        case "container.instances.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created instances for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating instances for container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faBoxUp,
            };
        case "container.instances.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted instances for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting instances for container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "container.instance.volume.extend":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Extended volume for instance {instanceLink} in container{" "}
                        {containerLink}.
                    </span>
                ) : (
                    <span>
                        Error extending volume for instance {instanceLink} in
                        container {containerLink} in environment {envLink}
                    </span>
                ),
                icon: NavIcons["volumes"],
            };

        case "container.instance.task.volume.extend":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested to extend volume for {instanceLink} in
                        container {containerLink}.
                    </span>
                ) : (
                    <span>
                        Error requesting to extend volume for instance{" "}
                        {instanceLink} in container {containerLink}.
                    </span>
                ),
                icon: NavIcons["volumes"],
            };

        // single instance
        case "container.instance.healthcheck.restarted": {
            return {
                message: (
                    <span>
                        Restarted instance {instanceLink} in environment{" "}
                        {envLink} due to failed health check
                    </span>
                ),
                icon: faMedkit,
            };
        }

        case "container.instance.healthcheck.failed": {
            return {
                message: (
                    <span>
                        Health check failed for instance {instanceLink} in
                        container {containerLink}
                    </span>
                ),
                icon: faMedkit,
                iconClassName: "text-error",
            };
        }

        case "container.instance.error":
            return {
                message: (
                    <span>Instance {instanceLink} encountered an error</span>
                ),
                icon: faTimesCircle,
            };

        case "container.instance.ssh.login": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Connected via SSH to instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error connecting via SSH to instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faTerminal,
            };
        }

        case "container.instance.task.migration.start": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error requesting migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.migration.start": {
            const prevServer = extractServerName(
                item.changes[0]?.before?.id || "",
                components
            );
            const newServer = extractServerName(
                item.changes[0]?.after?.id || "",
                components
            );
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Started migration of instance {instanceLink} of
                        container {containerLink} from{" "}
                        <Link
                            to={`/infrastructure/servers/${
                                item.changes[0]?.before?.id || ""
                            }`}
                        >
                            {prevServer}
                        </Link>{" "}
                        to{" "}
                        <Link
                            to={`/infrastructure/servers/${
                                item.changes[0]?.after?.id || ""
                            }`}
                        >
                            {newServer}
                        </Link>
                    </span>
                ) : (
                    <span>
                        Error migrating instance {instanceLink} of container{" "}
                        {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.task.migration.revert": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reversal of migration of instance{" "}
                        {instanceLink} of container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reversal of migration of instance{" "}
                        {instanceLink} of container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.migration.revert": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reverted migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error reverting migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }
        case "container.instance.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted instance {instanceLink}</span>
                ) : (
                    <span>Error deleting instance {instanceLink}</span>
                ),
                icon: faTrash,
            };

        case "container.instances.autoscale.up": {
            const ni = item.annotations["new-instances"];
            const si = item.annotations["sibling-instance-id"];
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Automatically created {ni || ""} new instance
                        {ni !== 1 ? "s" : ""} for container {containerLink} due
                        to instance{" "}
                        <Link
                            to={generateDialogLink("container", {
                                "dialog-container-id":
                                    item.context.container_id || "",
                                "dialog-tab": "instances",
                                "dialog-instance-id": si || "",
                            })}
                        >
                            ...
                            {si.slice(-6)}
                        </Link>{" "}
                        hitting auto-scale criteria.
                    </span>
                ) : (
                    <span>Error up-scaling container {containerLink}.</span>
                ),
                icon: faArrowUpRightDots,
            };
        }

        case "container.instances.autoscale.down": {
            const pi = item.annotations["purged-instances"];
            const si = item.annotations["sibling-instance-id"];
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Automatically deleted {pi || ""} instance
                        {pi !== 1 ? "s" : ""} for container {containerLink} that
                        were caused by an auto-scale on instance
                        <Link
                            to={generateDialogLink("container", {
                                "dialog-container-id":
                                    item.context.container_id || "",
                                "dialog-tab": "instances",
                                "dialog-instance-id": si || "",
                            })}
                        >
                            ...
                            {si?.slice(-6)}
                        </Link>
                        .
                    </span>
                ) : (
                    <span>Error down-scaling container {containerLink}.</span>
                ),
                icon: faArrowUpRightDots,
                iconClassName: "rotate-90",
            };
        }

        default:
            return {
                // This will most likely be a custom activity.
                message: item.event,
                // icon: faQuestionCircle,
            };
    }
}

function extractInstanceName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }
    const i = c as Instance;

    return `${i.hostname} (...${i.id.slice(-6)})`;
}

function extractServerName(id: string, components: ComponentIncludes) {
    const c = components?.[id || ""];
    if (!c) {
        return id;
    }

    return (c as Server).hostname;
}

function extractEnvironmentName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Environment).name;
}

function extractContainerName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Container).name;
}
