import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import { LoadBalancerFormValues } from "../../../../../form";
import { V1RouterForm } from "./V1RouterForm";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useDraggableFieldArray } from "~/components/layout/draggable/useDraggableFieldArray";
import { DraggableWrapper } from "~/components/layout/draggable/DraggableWrapper";

export function V1RouterSection({
    baseRoute,
    idx,
}: {
    baseRoute: `config.details.controllers.${number}`;
    idx: number;
}) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const { control } = useFormContext<LoadBalancerFormValues>();

    const mode = useWatch({ control, name: `${baseRoute}.transport.mode` });

    const {
        fields: routers,
        append,
        remove,
        move,
    } = useFieldArray({
        control,
        name: `${baseRoute}.transport.routers`,
    });

    const draggableProps = useDraggableFieldArray({
        fieldArr: routers,
        onMove: (prevIdx, newIdx) => move(prevIdx, newIdx),
        fieldArrayId: baseRoute,
    });

    return (
        <Panel>
            <PanelTitle
                title="Routers"
                help="The router configurations below do not define traffic routes. Instead, these configurations are used to match auto-created routes and modify their behavior."
            />
            <PanelContent>
                <div className="mb-4 flex w-full flex-col gap-4">
                    {routers?.map((r, routerIdx) => (
                        <DraggableWrapper id={r.id} {...draggableProps}>
                            <V1RouterForm
                                key={r.id}
                                router={r}
                                idx={routerIdx}
                                portIdx={idx}
                                mode={mode}
                                remove={(idx) => remove(idx)}
                                baseRoute={`${baseRoute}.transport.routers`}
                            />
                        </DraggableWrapper>
                    ))}
                </div>
                <div className="flex justify-end">
                    <AccessControlOverlay
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                    >
                        <Button
                            icon={faPlus}
                            onClick={() =>
                                append({
                                    mode: "round-robin",
                                    config: {
                                        sticky_sessions: false,
                                        destination_retries: 3,
                                        timeouts: {
                                            destination_connection: "3s",
                                        },
                                    },
                                    match: {
                                        domains: null,
                                        internal_ports: null,
                                        path: null,
                                        containers: {
                                            include: null,
                                            exclude: null,
                                        },
                                    },
                                })
                            }
                        >
                            Add Router
                        </Button>
                    </AccessControlOverlay>
                </div>
            </PanelContent>
        </Panel>
    );
}
