import {
    Checkbox,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { faEdit, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CreateServerSchema, FormServerSchema } from "../../../form";
import { ProviderServerModel } from "~/services/cycle";
import classNames from "classnames";

type ScheduledServerTileProps = {
    server: FormServerSchema;
    serverModels?: ProviderServerModel[];
    advFeatures: string[];
    extendedConfig: {
        title?: string | undefined;
        key?: string | undefined;
        type?: string | undefined;
    }[];
    location: string | undefined;
};
export function ScheduledServerTile({
    server,
    advFeatures,
    extendedConfig,
    serverModels,
    location,
}: ScheduledServerTileProps) {
    const isAdvanced =
        advFeatures.includes("Availability Zones") || extendedConfig.length;

    const { watch, setValue } = useFormContext<CreateServerSchema>();
    const [isEdit, setIsEdit] = useState(false);

    const removeServers = (modelId: string) => {
        if (!servers[modelId]) {
            return;
        }
        const { [modelId]: _, ...rest } = servers;
        setValue("servers", {
            ...rest,
        });
        return;
    };

    const servers = watch("servers");

    return (
        <div className="rounded-md border p-4 py-2">
            <div className="flex justify-between ">
                <div className="flex gap-2">
                    <strong>{server.quantity} x </strong>{" "}
                    <p>
                        {server.hostnames
                            ? server.hostnames[0]
                            : server.model_id}
                    </p>
                </div>
                <div className="flex gap-4">
                    {isAdvanced ? (
                        <button
                            type="button"
                            onClick={() => setIsEdit(!isEdit)}
                            className="group/button"
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                className={classNames("text-cycle-blue")}
                            />
                        </button>
                    ) : null}

                    <button
                        type="button"
                        onClick={() => removeServers(server.model_id)}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="text-error"
                        />
                    </button>
                </div>
            </div>

            {isEdit && (
                <div className="mt-2 flex flex-col gap-2 border-t pt-2">
                    {isAdvanced ? (
                        <>
                            {Array.from({
                                length: server.quantity,
                            }).map((_, idx) => (
                                <AdvancedServerOptions
                                    key={idx}
                                    idx={idx}
                                    providerServers={serverModels}
                                    server={server}
                                    advFeatures={advFeatures}
                                    extendedConfig={extendedConfig}
                                    location={location}
                                />
                            ))}
                        </>
                    ) : null}
                </div>
            )}
        </div>
    );
}

type AdvancedServerOptionsProps = {
    idx: number;
    server: FormServerSchema;
    providerServers?: ProviderServerModel[];
    advFeatures: string[];
    extendedConfig: {
        title?: string | undefined;
        key?: string | undefined;
        type?: string | undefined;
    }[];
    location: string | undefined;
};

function AdvancedServerOptions({
    idx,
    advFeatures,
    providerServers,
    extendedConfig,
    location,
    server,
}: AdvancedServerOptionsProps) {
    const allZones = providerServers?.find((ps) => ps.id === server.model_id)
        ?.provider?.availability_zones;

    const locationZones = allZones ? allZones[location || ""] : undefined;
    const { control } = useFormContext<CreateServerSchema>();

    return (
        <>
            <div className="bg-cycle-white/70 dark:bg-cycle-gray rounded-md p-4 pb-0">
                <div className="text-cycle-gray dark:text-cycle-white flex items-center pb-2 font-bold uppercase">{`Server ${
                    idx + 1
                }`}</div>

                {advFeatures?.includes("Availability Zones") ? (
                    <RhfFormField label={`Zone`} name="zone">
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <SelectInput
                                    {...field}
                                    options={locationZones}
                                    placeholder="Auto"
                                    formatOption={(o) => (
                                        <FormattedOption label={o} />
                                    )}
                                />
                            )}
                            control={control}
                            name={`servers.${server.model_id}.advanced.${idx}.zone`}
                        />
                    </RhfFormField>
                ) : null}

                {extendedConfig?.map((c) => (
                    <AdvancedInput
                        c={c}
                        providerServers={providerServers}
                        server={server}
                        idx={idx}
                    />
                ))}
            </div>
        </>
    );
}

function AdvancedInput({
    c,
    server,
    providerServers,
    idx,
}: {
    c: {
        title?: string | undefined;
        key?: string | undefined;
        type?: string | undefined;
    };
    server: FormServerSchema;
    providerServers?: ProviderServerModel[];
    idx: number;
}) {
    const { register } = useFormContext<CreateServerSchema>();
    const ramGb = useMemo(
        () =>
            providerServers?.find((s) => s.id === server.model_id)?.specs
                ?.memory?.size_gb,
        [server, providerServers]
    );

    let input: React.ReactNode;

    if (c.type === "bool") {
        input = (
            <Checkbox
                {...register(
                    `servers.${server.model_id}.advanced.${idx}.provision_options.${c.key}`
                )}
            />
        );
    } else if (c.type === "int") {
        // generate placeholder for attached storage size
        const placeholder =
            c.key === "attached_storage_size" && ramGb
                ? getStorageDefault(ramGb)
                : undefined;
        input = (
            <TextInput
                type="number"
                placeholder={placeholder?.toString()}
                {...register(
                    `servers.${server.model_id}.advanced.${idx}.provision_options.${c.key}`,
                    { setValueAs: (v) => (v === "" ? null : v) }
                )}
            />
        );
    } else {
        input = (
            <TextInput
                {...register(
                    `servers.${server.model_id}.advanced.${idx}.provision_options.${c.key}`,
                    { setValueAs: (v) => (v === "" ? null : v) }
                )}
            />
        );
    }

    return (
        <div key={`${c.key}-${server.model_id}`}>
            <RhfFormField
                name={`servers.${server.model_id}.advanced.${idx}.provision_options.${c.key}`}
                label={c.title}
            >
                {input}
            </RhfFormField>
        </div>
    );
}

// Determine the storage default size
// Default is 15 x ramGb
// must be > 30 and < 120
const getStorageDefault = (ramGb: number | undefined) => {
    if (!ramGb) {
        return 45;
    }
    const std = ramGb * 15;

    if (std < 30) {
        return 30;
    } else if (std > 120) {
        return 120;
    } else {
        return std;
    }
};
