import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import { Avatar } from "@cycleplatform/ui/components/avatar";
import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    FormField,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError } from "~/components/forms/util";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    faEdit,
    faEnvelope,
    faUser,
    faUserClock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useForm } from "react-hook-form";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    Account,
    UpdateAccountApiArg,
    useUpdateAccountMutation,
} from "~/services/cycle";

type ProfileProps = {
    account?: Account;
};

export function Profile({ account }: ProfileProps) {
    const form = useForm<UpdateAccountApiArg["body"]>({
        defaultValues: {
            name: {
                first: account?.name?.first,
                last: account?.name?.last,
            },
        },
    });

    useKeepFormCurrent(form, account, (a) => ({
        name: {
            first: a.name.first,
            last: a.name.last,
        },
    }));

    const {
        register,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updateAccount] = useUpdateAccountMutation();

    const onSubmit = (data: UpdateAccountApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return updateAccount({
            body: data,
        })
            .unwrap()
            .then(
                () => form.reset((formValues) => ({ ...formValues })),
                handleSubmitError(form.setError)
            );
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Profile" />
                <PanelContent>
                    <div className="flex gap-8 ">
                        <div className="w-1/2">
                            <div className="flex flex-col items-center pb-4">
                                <div className="mb-4 h-40 w-40">
                                    <Avatar
                                        email={account?.email?.address}
                                        className="h-full w-full"
                                    />
                                </div>
                                <div className="text-xs">
                                    Update image on{" "}
                                    <a
                                        href="https://gravatar.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Gravatar
                                    </a>
                                </div>
                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="text-cycle-blue pr-2"
                                />
                                {`${account?.name?.first} ${account?.name?.last}`}
                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="text-cycle-blue pr-2"
                                />
                                {account?.email?.address}
                            </div>

                            <div>
                                <FontAwesomeIcon
                                    icon={faUserClock}
                                    className="text-cycle-blue pr-2"
                                />
                                {`Member Since: ${formatDateString(
                                    account?.events?.created || new Date(),
                                    DateFormats["standardShort"]
                                )}`}
                            </div>
                        </div>
                        <div className="flex h-full w-full flex-col">
                            <RhfFormField label="first name" name="name.first">
                                <TextInput
                                    {...register("name.first", {
                                        ...required(),
                                    })}
                                />
                            </RhfFormField>
                            <RhfFormField label="last name" name="name.last">
                                <TextInput
                                    {...register("name.last", {
                                        ...required(),
                                    })}
                                />
                            </RhfFormField>
                        </div>
                    </div>

                    <PanelFooter>
                        {/* <DeleteAccountMenu account={account} /> */}

                        <RhfGlobalFormError />

                        <PushAndHoldButton
                            flavor="primary"
                            onClick={form.handleSubmit(onSubmit)}
                            icon={faEdit}
                            isLoading={isSubmitting}
                            disabled={!isDirty}
                        >
                            Save Profile
                        </PushAndHoldButton>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
