import {
    Checkbox,
    EnableSectionInline,
    FormField,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { useState } from "react";
import { CoresSelect } from "~/components/containers/CoresSelect";

export function CpuCoresPinningInput() {
    const { control, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.resources.cpu.cpus") !== undefined
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField label="Enable Pinning">
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (ev.target.checked) {
                                setValue("config.resources.cpu.cpus", "", {
                                    shouldDirty: true,
                                });
                            } else {
                                setValue(
                                    "config.resources.cpu.cpus",
                                    undefined,
                                    { shouldDirty: true }
                                );
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <RhfFormField
                    label="Cores"
                    name="config.resources.cpu.cpus"
                    className="w-full"
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <CoresSelect {...field} />
                        )}
                        control={control}
                        name="config.resources.cpu.cpus"
                    />
                </RhfFormField>
            ) : (
                <FormField label="Cores" className="w-full">
                    <MultiSelectInput disabled />
                </FormField>
            )}
        </EnableSectionInline>
    );
}
