import { components, operations } from "../../api/__generated";

/**
 * Extracts the actual LBv1 controller transport configuration,
 * whether that be user-provided, defaults for a given port,
 * or using the default template.
 *
 * The order is:
 * 1. A custom transport defined for that specific port
 * 2. A transport defined in the base config specifically for that port
 * 3. The custom controller template, if defined
 * 4. The base config controller template
 */
export function getActualTransportConfig(
    port: number,
    baseConfig: components["schemas"]["V1LbConfig"],
    currentConfig: components["schemas"]["V1LbConfig"]
): NonNullable<components["schemas"]["V1LbController"]["transport"]> {
    // 1. A custom transport defined for that specific port
    if (currentConfig) {
        const controller = currentConfig.controllers.find(
            (c) => c.port === port
        );
        if (controller && controller.transport) {
            // controller exists in the currently utilized config
            return controller.transport;
        }
    }

    // 2. A transport defined in the base config specifically for that port
    const baseController = baseConfig.controllers.find((c) => c.port === port);
    if (baseController && baseController.transport) {
        return baseController.transport;
    }

    // 3. The custom controller template, if defined
    if (
        currentConfig &&
        currentConfig.controller_template &&
        currentConfig.controller_template.transport
    ) {
        return currentConfig.controller_template.transport;
    }

    // 4. The base config controller template
    // We're asserting these exist here, because this is the lowest level
    // of config possible, and is the fallback for the platform. The typing
    // reuses an option type but we know for a fact this is true.
    return baseConfig.controller_template!.transport!;
}

/**
 * Extracts the actual WAF config used by the platform by this controller,
 * whether user-provided, default for the port, or using the base template.
 */
export function getActualControllerWafConfig(
    port: number,
    baseConfig: components["schemas"]["V1LbConfig"],
    currentConfig?: components["schemas"]["V1LbConfig"]
): components["schemas"]["V1LbController"]["waf"] {
    // If the controller has a custom waf designated, we're good!
    if (currentConfig) {
        const controller = currentConfig.controllers.find(
            (c) => c.port === port
        );
        if (controller) {
            return controller.waf;
        }
    }

    // Otherwise, look through the base config for v1 to find out
    // if this port has a default waf set by the platform
    const baseMatchController = baseConfig.controllers.find(
        (c) => c.port === port
    );
    if (baseMatchController && baseMatchController.waf) {
        return baseMatchController.waf;
    }

    // If there's no specific configuration for that port, use our customized
    // controller template, if we have one set with a waf.
    if (
        currentConfig &&
        currentConfig.controller_template &&
        currentConfig.controller_template.waf
    ) {
        return currentConfig.controller_template.waf;
    }

    // Otherwise, use the base config waf/undefined if none set.
    return baseConfig.controller_template?.waf;
}
