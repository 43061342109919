import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { useNavigate } from "react-router-dom";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "../../helpers";
import { useGetAutoScaleGroupQuery } from "~/services/cycle";
import { AddDeployableInfraForm } from "~/components/infrastructure/form/autoScaling/deployableInfra/AddDeployableInfraForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function AddDeployableInfraDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"autoscaling-group-add-infra">();

    const { data: group } = useGetAutoScaleGroupQuery({
        groupId: params["dialog-group-id"],
    });

    return (
        <StyledFormDialog
            title={`Add Models - ${group?.data?.name}`}
            isOpen={isDialogOpen("autoscaling-group-add-infra")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <AddDeployableInfraForm group={group?.data} />
            </DialogColumn>
        </StyledFormDialog>
    );
}
