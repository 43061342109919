import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { Environment, useGetContainerQuery } from "~/services/cycle";
import { ServiceContainerPanel } from "../../ServiceContainerPanel";
import { DiscoveryConfigPanel } from "./DiscoveryConfigPanel";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import classNames from "classnames";
import { DiscoveryLookupsChart } from "./components/charts/lookups/DiscoveryLookupsChart";
import { RecentLookupsTable } from "./components/lookups/RecentLookupsTable";

export function DashboardTab() {
    const { environment } = useContext(ContainerDialogContext);
    const { data: discoveryContainer, error } = useGetContainerQuery(
        {
            containerId: environment?.services?.discovery?.container_id || "",
            meta: ["ips", "instances_count"],
        },
        {
            skip: !environment?.services?.discovery?.container_id,
        }
    );

    if (error) {
        throw error;
    }

    return (
        <DialogPageBody>
            <PageContent>
                <Panel>
                    <PanelTitle title="DNS Telemetry" />
                    <PanelContent className={classNames("w-full")}>
                        <PanelTitle title="Lookups" />
                        <div className="h-60 p-4">
                            <DiscoveryLookupsChart environment={environment} />
                        </div>
                    </PanelContent>
                </Panel>

                <Panel>
                    <PanelTitle title="Recent Lookups (Last 24 Hours)" />
                    <PanelContent stretch>
                        <RecentLookupsTable environment={environment} />
                    </PanelContent>
                </Panel>
            </PageContent>
            <PageAside>
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                >
                    <DiscoveryConfigPanel
                        info={environment?.services?.discovery}
                        environment={environment}
                    />
                </AccessControlledSection>
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                >
                    <ServiceContainerPanel
                        container={discoveryContainer?.data}
                        environment={environment}
                    />
                </AccessControlledSection>
            </PageAside>
        </DialogPageBody>
    );
}
