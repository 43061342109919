import {
    ButtonLink,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { faPlay, faStop, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { useJobTracker } from "~/modules/jobs/hooks";
import {
    Container,
    Environment,
    useCreateContainerJobMutation,
} from "~/services/cycle";
import { AccessControlOverlay } from "~/components/common/buttons";
import {
    containerStartAccessFn,
    containerModifyAccessFn,
    containerStopAccessFn,
} from "@cycleplatform/core/modules/containers/acls";

type ContainerStateControlsProps = {
    container: Container | undefined;
    environment: Environment | undefined;
    disabled?: boolean;
    className?: string;
    hide?: Array<"start" | "stop" | "delete">;
};

/**
 * Start, stop, and delete buttons for a container, lined up in a nice row. Iconic.
 */
export function ContainerStateControls({
    container,
    environment,
    disabled,
    className,
    hide,
}: ContainerStateControlsProps) {
    const [trackStartJob, { isTrackingJob: isTrackingStartJob }] =
        useJobTracker();
    const [trackStopJob, { isTrackingJob: isTrackingStopJob }] =
        useJobTracker();
    const [createContainerJob] = useCreateContainerJobMutation();

    return (
        <div
            className={classNames(
                "flex h-[32px] items-center text-base ",
                "[&>:not(:first-child):not(:last-child)_button]:rounded-none [&>:last-child_button]:rounded-l-none  [&>:first-child_button]:rounded-r-none [&>:last-child_a]:rounded-l-none",
                "[&>:not(:first-child):not(:last-child)_div]:rounded-none [&>:last-child_div]:rounded-l-none  [&>:first-child_div]:rounded-r-none ",
                className
            )}
        >
            {container?.id ? (
                <>
                    {!hide?.includes("start") ? (
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={containerStartAccessFn(container)}
                        >
                            <PushAndHoldButton
                                icon={faPlay}
                                disabled={disabled}
                                tooltip={"Hold to start"}
                                isLoading={isTrackingStartJob}
                                aria-label="Start Container"
                                flavor="primary"
                                className={classNames(
                                    "border-cycle-blue-accent border-r",
                                    disabled &&
                                        "!text-cycle-white !bg-cycle-gray"
                                )}
                                onClick={() =>
                                    trackStartJob(
                                        createContainerJob({
                                            containerId: container.id,
                                            body: {
                                                action: "start",
                                            },
                                        }).unwrap()
                                    )
                                }
                            />
                        </AccessControlOverlay>
                    ) : null}

                    {!hide?.includes("stop") ? (
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={containerStopAccessFn(container)}
                        >
                            <PushAndHoldButton
                                icon={faStop}
                                disabled={disabled}
                                tooltip={"Hold to stop"}
                                isLoading={isTrackingStopJob}
                                aria-label="Stop Container"
                                flavor="primary"
                                className={classNames(
                                    "border-cycle-blue-accent border-r",
                                    disabled &&
                                        "!text-cycle-white !bg-cycle-gray"
                                )}
                                onClick={() =>
                                    trackStopJob(
                                        createContainerJob({
                                            containerId: container.id,
                                            body: {
                                                action: "stop",
                                            },
                                        }).unwrap()
                                    )
                                }
                            />
                        </AccessControlOverlay>
                    ) : null}
                    {!hide?.includes("delete") ? (
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={containerModifyAccessFn(container)}
                        >
                            <ButtonLink
                                tooltip="Go to delete form"
                                disabled={disabled}
                                aria-label="Delete Container"
                                icon={faTrashAlt}
                                flavor="primary"
                                to={generateDialogLink("container", {
                                    "dialog-container-id": container.id,
                                    "dialog-focus": "delete",
                                    "dialog-tab": "settings",
                                })}
                                className={classNames(
                                    "border-cycle-blue-accent flex items-center border-r",
                                    disabled &&
                                        "!text-cycle-white !bg-cycle-gray"
                                )}
                            />
                        </AccessControlOverlay>
                    ) : null}
                </>
            ) : (
                <SkeletonButton size="md" />
            )}
        </div>
    );
}
