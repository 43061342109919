import classNames from "classnames";

export function FieldName({
    fieldName,
    className,
}: {
    fieldName: string;
    className?: string;
}) {
    return (
        <div
            className={classNames(
                "flex !w-28 gap-2 text-sm font-semibold uppercase",
                className
            )}
        >
            <strong>{`${fieldName}:`}</strong>
        </div>
    );
}
