import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { getOptionButtonClassName } from "./styles";

type OptionTabProps = {
    title: string;
    description: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    icon?: IconDefinition;
    iconClassName?: string;
};

/**
 * A large styled tab for use with headlessui tabs.
 *
 * Used in forms for binary options where you select a 'path' (such as environment create from scratch/from stack)
 */
export function OptionTab({
    title,
    description,
    onClick,
    disabled,
    icon,
    iconClassName,
}: OptionTabProps) {
    return (
        <Tab
            disabled={disabled}
            onClick={onClick}
            className={({ selected }) =>
                getOptionButtonClassName(selected, disabled)
            }
        >
            <div className="flex ">
                {icon && (
                    <div
                        className={classNames(
                            "flex w-14 items-center justify-center py-2",
                            iconClassName
                        )}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}

                <div className="pl-2">
                    <h4 className="font-semibold uppercase">{title}</h4>
                    <p className="text-sm">{description}</p>
                </div>
            </div>
        </Tab>
    );
}
