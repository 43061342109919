import { useState } from "react";
import { PillButtons } from "../../PillButtons";
import { useFormContext, useWatch } from "react-hook-form";
import {
    convertToJsonString,
    convertToYamlString,
} from "@cycleplatform/core/util";

export function RhfJsonYamlToggle({ name }: { name: string }) {
    const { setValue, control } = useFormContext();

    const [mode, setMode] = useState<"json" | "yaml">("json");
    const value = useWatch({ name, control });

    const convert = (m: "json" | "yaml") => {
        if (m === "json") {
            const formattedJson = convertToJsonString(value);
            if (typeof formattedJson === "string") {
                setValue(name, formattedJson);
                setMode("json");
            }
        } else {
            const formattedYaml = convertToYamlString(value);
            if (typeof formattedYaml === "string") {
                setValue(name, formattedYaml);
                setMode("yaml");
            }
        }
    };

    return (
        <PillButtons
            value={mode}
            onChange={(v) => {
                convert(v as "json" | "yaml");
            }}
            options={[
                {
                    value: "json",
                    label: "JSON",
                },
                {
                    value: "yaml",
                    label: "YAML",
                },
            ]}
        />
    );
}
