import classNames from "classnames";
import { Link, To } from "react-router-dom";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    ResourceStateIcon,
    ResourceStateFull,
    getStateTooltip,
} from "@cycleplatform/ui/components/resources/state";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Checkbox } from "@cycleplatform/ui/components/forms";
import {
    SkeletonCircle,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { RhfIdCheckbox } from "~/components/common/forms/IdSelect/RhfIdCheckbox";

type ResourceListItemProps = {
    isLoading?: boolean;
    isActive?: boolean;
    children?: React.ReactNode;
    to: To;
    checkboxId?: string;
    checkboxDisabled?: boolean;
    checkboxDisabledTooltip?: string;
    state?: ResourceStateFull;
    stateTooltip?: React.ReactNode;
};

/**
 * An individual resource item that goes in the 'ResourceList' component.
 */
export function DialogResourceListItem({
    isActive,
    to,
    children,
    checkboxId,
    checkboxDisabled,
    checkboxDisabledTooltip,
    isLoading,
    state,
    stateTooltip,
}: ResourceListItemProps) {
    return (
        <li
            className={classNames(
                "dark:text-cycle-white  overflow-hidden text-ellipsis whitespace-nowrap rounded-lg rounded-r-none p-1",
                "hover:bg-cycle-gray-light hover:dark:bg-cycle-black-accent/80 flex min-h-[3.5rem] w-full rounded-lg rounded-r-none",
                isActive && "bg-cycle-white-accent dark:bg-cycle-black-accent"
            )}
        >
            <div className={classNames("inherit  flex flex-1 items-center")}>
                {checkboxId ? (
                    <div
                        className={classNames(
                            "relative mr-2 w-8 px-4 text-center",
                            isLoading && skeletonStyles
                        )}
                    >
                        <Tooltip
                            message={checkboxDisabledTooltip}
                            disabled={
                                !checkboxDisabled || !checkboxDisabledTooltip
                            }
                        >
                            <RhfIdCheckbox
                                id={checkboxId}
                                disabled={checkboxDisabled || false}
                            />
                        </Tooltip>
                    </div>
                ) : null}

                <Link to={to} className="inherit flex-1 p-4">
                    <div className={classNames("flex flex-1 items-center")}>
                        {state || isLoading ? (
                            <div className="mr-4 w-[12px] text-center">
                                <Tooltip
                                    message={
                                        stateTooltip ||
                                        getStateTooltip(state, state?.current)
                                    }
                                >
                                    {state ? (
                                        <ResourceStateIcon state={state} />
                                    ) : (
                                        <SkeletonCircle />
                                    )}
                                </Tooltip>
                            </div>
                        ) : null}

                        {isLoading ? <SkeletonText /> : <>{children}</>}
                    </div>
                </Link>
            </div>
        </li>
    );
}
