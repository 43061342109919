import { AccessError, VerifyFn, doesRoleHaveCapabilities } from "../acls/util";
import { components } from "../api/__generated";
import { isServiceContainer, isHypervisorContainer } from "./util";

export const containerTriggerFunctionAccessFn =
    (
        container: components["schemas"]["Container"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (!doesRoleHaveCapabilities(role, ["containers-functions-trigger"])) {
            return new AccessError().setCapability(
                "containers-functions-trigger"
            );
        }

        if (container.config.deploy.strategy !== "function") {
            return new Error("container is not a function");
        }

        if (!acl.modify) {
            return new AccessError(
                "cannot trigger function container"
            ).setAclResource("environment");
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        return undefined;
    };

export const containerModifyAccessFn =
    (
        container: components["schemas"]["Container"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (
            isServiceContainer(container) &&
            !doesRoleHaveCapabilities(role, ["environments-services-manage"])
        ) {
            return new AccessError().setCapability(
                "environments-services-manage"
            );
        } else if (!doesRoleHaveCapabilities(role, ["containers-manage"])) {
            return new AccessError().setCapability("containers-manage");
        }

        if (!acl.modify) {
            return new AccessError().setAclResource("environment");
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        return undefined;
    };

export const containerScaleAccessFn =
    (
        container: components["schemas"]["Container"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (
            isServiceContainer(container) &&
            !doesRoleHaveCapabilities(role, ["environments-services-manage"])
        ) {
            return new AccessError("cannot scale container").setCapability(
                "environments-services-manage"
            );
        } else if (!doesRoleHaveCapabilities(role, ["containers-manage"])) {
            return new AccessError().setCapability("containers-manage");
        }

        if (!acl.modify) {
            return new AccessError("cannot scale container").setAclResource(
                "environment"
            );
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        if (container.config.deploy.strategy === "node") {
            return new Error(
                "container has a deployment strategy of node, which cannot be scaled"
            );
        }

        if (container.stateful) {
            return new Error("container is stateful and cannot be scaled");
        }

        return undefined;
    };

export const containerStartAccessFn =
    (
        container: components["schemas"]["Container"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (
            isServiceContainer(container) &&
            !doesRoleHaveCapabilities(role, ["environments-services-manage"])
        ) {
            return new AccessError("cannot start container").setCapability(
                "environments-services-manage"
            );
        } else if (!doesRoleHaveCapabilities(role, ["containers-manage"])) {
            return new AccessError().setCapability("containers-manage");
        }

        if (!acl.modify) {
            return new AccessError("cannot start container").setAclResource(
                "environment"
            );
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        if (container.config.deploy.strategy === "function") {
            return new Error(
                "container is a function, which cannot be started"
            );
        }

        return undefined;
    };

export const containerStopAccessFn =
    (
        container: components["schemas"]["Container"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (
            isServiceContainer(container) &&
            !doesRoleHaveCapabilities(role, ["environments-services-manage"])
        ) {
            return new AccessError("cannot stop container").setCapability(
                "environments-services-manage"
            );
        } else if (!doesRoleHaveCapabilities(role, ["containers-manage"])) {
            return new AccessError().setCapability("containers-manage");
        }

        if (!acl.modify) {
            return new AccessError("cannot stop container").setAclResource(
                "environment"
            );
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        return undefined;
    };

export const containerInstanceSshAccessFn =
    (
        container: components["schemas"]["Container"],
        instance: components["schemas"]["Instance"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (!doesRoleHaveCapabilities(role, ["containers-ssh"])) {
            return new AccessError(
                "cannot ssh into container instance"
            ).setCapability("containers-ssh");
        }

        if (!acl.modify) {
            return new AccessError(
                "cannot ssh into container instance"
            ).setAclResource("environment");
        }

        if (container.config.deploy.strategy === "function") {
            if (instance.state.current !== "running") {
                return new Error("container instance is not running");
            }
        } else {
            if (container.state.current !== "running") {
                return new Error("container is not running");
            }
        }

        return undefined;
    };

export const containerMigrateAccessFn =
    (
        container: components["schemas"]["Container"],
        instance: components["schemas"]["Instance"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        const isService = !!instance.service;

        if (!doesRoleHaveCapabilities(role, ["containers-instances-migrate"])) {
            return new AccessError(
                "cannot migrate container instances"
            ).setCapability("containers-instances-migrate");
        }

        if (
            isService &&
            !doesRoleHaveCapabilities(role, ["environments-services-manage"])
        ) {
            return new AccessError(
                "cannot migrate environment service instances"
            ).setCapability("environments-services-manage");
        }

        if (!acl.modify) {
            return new AccessError(
                "cannot migrate container instances"
            ).setAclResource("environment");
        }

        if (container.state.current === "deleted") {
            return new Error("container is deleted");
        }

        return undefined;
    };
