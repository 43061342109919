import { Checkbox, RhfFormField } from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

export function DeployStatefulInstances() {
    const { register } = useFormContext<ContainerConfigFormData>();
    return (
        <>
            <RhfFormField
                label="use base hostname"
                name="config.deploy.stateful.options.use_base_hostname"
                help="When enabled, instances will
                                        utilize stateless base hostnames
                                        instead of being prefixed with a
                                        unique ID."
            >
                <Checkbox
                    {...register(
                        "config.deploy.stateful.options.use_base_hostname"
                    )}
                />
            </RhfFormField>
        </>
    );
}
