import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageContent } from "@cycleplatform/ui/components/page";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { useContext, useState } from "react";
import {
    Controller,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import {
    SchedulerEnvironmentService,
    Environment,
    CreateSchedulerServiceJobApiArg,
    useCreateSchedulerServiceJobMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    faAsterisk,
    faEdit,
    faPlus,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";

type ConfigTabProps = {};

function getDefaultValues(info?: SchedulerEnvironmentService | null) {
    return {
        auto_update: info?.auto_update,
        config: info?.config || null,
    };
}

export function ConfigTab({}: ConfigTabProps) {
    return (
        <DialogPageBody>
            <PageContent>
                <ConfigForm />
            </PageContent>
        </DialogPageBody>
    );
}

function ConfigForm() {
    const { environment } = useContext(ContainerDialogContext);
    const info = environment?.services?.scheduler;

    const form = useForm<CreateSchedulerServiceJobApiArg["body"]["contents"]>({
        defaultValues: getDefaultValues(info),
        ...rhfConfig,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty },
    } = form;
    const [formError, setFormError] = useState<string>();
    const [updateConfig] = useCreateSchedulerServiceJobMutation();

    useKeepFormCurrent(form, info, (i) => getDefaultValues(i));

    const onSubmit = async (
        data: CreateSchedulerServiceJobApiArg["body"]["contents"]
    ) => {
        setFormError(undefined);

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => reset(data))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Update Access Keys" />
                <PanelContent>
                    <AccessKeyFormSection />
                    <PanelFooter>
                        <div>
                            <div>
                                {formError && (
                                    <p className="text-error pb-2">
                                        {formError}
                                    </p>
                                )}
                                <RhfGlobalFormError className="pb-2" />
                            </div>
                            <div className="flex w-full justify-end">
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={modifyAccessFn(
                                        "environments-services-manage"
                                    )}
                                >
                                    <PushAndHoldButton
                                        flavor="primary"
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        icon={faEdit}
                                        isLoading={isSubmitting}
                                        disabled={!isDirty}
                                    >
                                        Update Access Keys
                                    </PushAndHoldButton>
                                </AccessControlOverlay>
                            </div>
                        </div>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}

function AccessKeyFormSection() {
    const { environment } = useContext(ContainerDialogContext);

    const { register, control } =
        useFormContext<CreateSchedulerServiceJobApiArg["body"]["contents"]>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "config.access_keys",
    });

    return (
        <StyledDataTable>
            <StyledTableHead>
                <StyledHeaderCell className="w-1/3">
                    <div className="flex items-center">
                        Name{" "}
                        <FontAwesomeIcon
                            icon={faAsterisk}
                            className="text-error  ml-2 text-[10px]"
                        />
                    </div>
                </StyledHeaderCell>
                <StyledHeaderCell className="w-1/3">
                    <div className="flex items-center">
                        Secret{" "}
                        <FontAwesomeIcon
                            icon={faAsterisk}
                            className="text-error  ml-2 text-[10px]"
                        />
                    </div>
                </StyledHeaderCell>
                <StyledHeaderCell className="w-1/3">IPs</StyledHeaderCell>
                <StyledHeaderCell className="w-20"></StyledHeaderCell>
            </StyledTableHead>

            <tbody>
                {fields.map((ev, idx) => {
                    return (
                        <StyledTableRow key={ev.id}>
                            <StyledCell className="pr-4">
                                <RhfFormField
                                    name={`config.access_keys.${idx}.name`}
                                >
                                    <TextInput
                                        {...register(
                                            `config.access_keys.${idx}.name`,
                                            { ...required() }
                                        )}
                                    />
                                </RhfFormField>
                            </StyledCell>
                            <StyledCell className="flex-1 pr-4">
                                <RhfFormField
                                    name={`config.access_keys.${idx}.secret`}
                                >
                                    <TextInput
                                        {...register(
                                            `config.access_keys.${idx}.secret`,
                                            { ...required() }
                                        )}
                                    />
                                </RhfFormField>
                            </StyledCell>
                            <StyledCell className="pr-4">
                                <RhfFormField
                                    name={`config.access_keys.${idx}.ips`}
                                >
                                    <Controller
                                        render={({
                                            field: { ref: _ref, ...field },
                                        }) => (
                                            <MultiSelectInput
                                                isCreatable
                                                placeholder="No IPs selected"
                                                {...field}
                                                value={field.value || []}
                                            />
                                        )}
                                        control={control}
                                        name={`config.access_keys.${idx}.ips`}
                                    />
                                </RhfFormField>
                            </StyledCell>
                            <StyledCell className="text-end">
                                <Button
                                    flavor="discard"
                                    onClick={() => remove(idx)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </StyledCell>
                        </StyledTableRow>
                    );
                })}

                <StyledTableRow>
                    <StyledCell colSpan={4}>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <Button
                                type="button"
                                icon={faPlus}
                                className="w-full"
                                onClick={() =>
                                    append({
                                        name: "",
                                        secret: "",
                                        ips: [],
                                    })
                                }
                            >
                                Add
                            </Button>
                        </AccessControlOverlay>
                    </StyledCell>
                </StyledTableRow>
            </tbody>
        </StyledDataTable>
    );
}
