import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CreateContainerSubmitType } from "../CreateContainerForm";
import {
    RhfEnableDisableFormSection,
    RhfFormField,
    hasNoSpaces,
} from "@cycleplatform/ui/components/forms";
import { useGetEnvironmentDeploymentsQuery } from "~/services/cycle";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";

export function Deployments({
    environmentId,
}: {
    environmentId: string | undefined;
}) {
    const {
        control,
        setValue,
        unregister,
        formState: { errors },
    } = useFormContext<CreateContainerSubmitType>();

    // Not handling error since for the creatable select, it is not imperative that we have options here
    const { data: deployments } = useGetEnvironmentDeploymentsQuery(
        {
            environmentId: environmentId || "",
        },
        { skip: !environmentId }
    );

    const deploymentSection = useWatch({
        name: `deployment`,
        control,
    });
    return (
        <RhfEnableDisableFormSection
            header={<div className="flex items-center gap-4">Deployments</div>}
            onToggle={(v) => {
                if (v) {
                    setValue(`deployment`, { version: "" });
                } else {
                    unregister(`deployment`);
                }
            }}
            isExpanded={!!deploymentSection}
        >
            {(isExpanded) => (
                <>
                    {isExpanded && (
                        <div className="flex gap-4">
                            <RhfFormField
                                required
                                name="deployment.version"
                                label="Version"
                            >
                                <Controller
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <SelectInput
                                            {...field}
                                            isCreatable
                                            options={Object.keys(
                                                deployments?.data?.versions ||
                                                    {}
                                            )}
                                            formatOption={(o) => (
                                                <FormattedOption label={o} />
                                            )}
                                        />
                                    )}
                                    control={control}
                                    rules={{
                                        validate: {
                                            ...hasNoSpaces(),
                                        },
                                    }}
                                    name="deployment.version"
                                />
                            </RhfFormField>
                        </div>
                    )}
                </>
            )}
        </RhfEnableDisableFormSection>
    );
}
