import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Badge } from "@cycleplatform/ui/components/badges";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    faLockKeyhole,
    faUnlockKeyhole,
} from "@fortawesome/pro-duotone-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

type ResourceLockedBadgeProps<T extends { lock: boolean }> = {
    resource: T;
    resourceName: string;
    className?: string;
};

export function ResourceLockedBadge<T extends { lock: boolean }>({
    resource,
    resourceName,
    className,
}: ResourceLockedBadgeProps<T>) {
    if (!resource.lock) {
        return null;
    }

    return (
        <div>
            <Tooltip
                message={`This ${resourceName} is locked and cannot be deleted`}
            >
                <Badge
                    className={classNames(
                        className,
                        "!bg-cycle-gray !h-8",
                        !resource && skeletonStyles,
                        "dark:bg-cycle-gray items-center gap-2 whitespace-nowrap px-2 !text-xs leading-4 text-white"
                    )}
                >
                    <FontAwesomeIcon
                        icon={resource.lock ? faLockKeyhole : faUnlockKeyhole}
                        className="text-warning text-xs"
                    />
                    <div className="pt-0.5 !text-xs">locked</div>
                </Badge>
            </Tooltip>
        </div>
    );
}
