import {
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { RestartCondition } from "~/modules/containers/schemas";

export function DeployRestartPolicy() {
    const { register, control } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <RhfFormField
                className="flex-grow"
                label="restart condition"
                name="config.deploy.restart.condition"
                required
            >
                <Controller
                    defaultValue="always"
                    render={({ field: { ref: _ref, ...field } }) => (
                        <BasicSelect
                            {...field}
                            options={RestartCondition.options.map((o) => ({
                                label: o.toLocaleUpperCase(),
                                value: o,
                            }))}
                        />
                    )}
                    control={control}
                    name="config.deploy.restart.condition"
                />
            </RhfFormField>
            <RhfFormField
                label="delay"
                name="config.deploy.restart.delay"
                required
            >
                <TextInput
                    defaultValue={"5s"}
                    {...register("config.deploy.restart.delay", {
                        ...required(),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="max restart attempts"
                name="config.deploy.restart.max_attempts"
                required
            >
                <TextInput
                    type="number"
                    defaultValue={3}
                    {...register("config.deploy.restart.max_attempts", {
                        valueAsNumber: true,
                        ...required(),
                    })}
                />
            </RhfFormField>
        </>
    );
}
