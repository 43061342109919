import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { portType } from "~/modules/containers/schemas";

type PortSelectProps = {
    name: string;
    value?: string[] | null;
    disabled?: boolean;
    onBlur?: () => void;
    onChange: (value: string[] | undefined) => void;
    resourceName?: string;
};

export function PortSelect({ resourceName, ...props }: PortSelectProps) {
    return (
        <MultiSelectInput
            {...props}
            onChange={(e) => {
                return props.onChange(e && e.length ? e : []);
            }}
            value={props.value || []}
            placeholder="Add ports in the format 80:80"
            isCreatable={true}
            options={[]}
            creatablePlaceholder={(v) => getCreatePrompt(v, resourceName)}
            isCreateValueValid={testIsValidPort}
            formatDisplayValue={(v) => v}
            formatOption={(v) => v}
        />
    );
}

function getCreatePrompt(v: string, resourceName: string = "container") {
    const t = portType.safeParse(v);
    if (!t.success) {
        return "Please enter a valid port.";
    }

    const [ingress, internalPort] = v.split(":");

    if (internalPort) {
        return `Accept connections on port ${ingress} and map to ${resourceName} port ${internalPort}`;
    } else {
        return `Accept connections on port ${ingress}`;
    }
}

function testIsValidPort(v: string): boolean {
    const t = portType.safeParse(v);
    return t.success;
}
