import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    DropFileInput,
    FieldErrorMessage,
} from "@cycleplatform/ui/components/forms";
import { parseZoneFileToRecords } from "~/modules/dns/zones";
import { ChangeEvent, DragEvent, FormEvent, useEffect, useState } from "react";
import {
    CreateDnsZoneRecordApiArg,
    useCreateDnsZoneRecordMutation,
    useGetDnsZoneQuery,
} from "~/services/cycle";
import { UploadableRecordTable } from "./UploadableRecordTable";
import { ExternalLink } from "~/components/common/links";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faFileImport } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError } from "~/components/forms/util";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

export function ZoneFileWizardDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"dns-zone-import">();
    const zoneId = params["dialog-zone-id"];

    useEffect(() => {
        if (!zoneId) {
            nav(clearDialogParams());
        }
    }, [zoneId]);

    const { data: zone, error: _ } = useGetDnsZoneQuery({ zoneId });
    const [createRecords, { isLoading: isSubmitting }] =
        useCreateDnsZoneRecordMutation();

    const [zoneFile, setZoneFile] = useState<string>();
    const [error, setError] = useState<string>();
    const [records, setRecords] =
        useState<CreateDnsZoneRecordApiArg["body"][]>();

    useEffect(() => {
        if (!zoneFile) {
            return;
        }
        const rs = parseZoneFileToRecords(zoneFile);
        if (rs.length === 0) {
            setError("no records found in uploaded file");
            return;
        }
        setRecords(rs.sort((r1, r2) => r1.name.localeCompare(r2.name)));
    }, [zoneFile]);

    function processFile(files: FileList | null) {
        if (!files || !files[0]) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (ev) => {
            setZoneFile(undefined);
            setError(undefined);
            // uploading the exact same file after clearing
            // every record out makes the zone file string identical,
            // causing react to not rerender. This forces a rerender
            // for any file.
            setTimeout(() => {
                setZoneFile(ev.target?.result?.toString());
            });
        };

        reader.readAsText(files[0]);
    }

    function handleFileChange(ev: ChangeEvent<HTMLInputElement>) {
        processFile(ev.target.files);
    }

    function handleDrop(ev: DragEvent<HTMLInputElement>) {
        ev.preventDefault();
        processFile(ev.dataTransfer.files);
    }

    function handleSubmit(ev?: FormEvent<HTMLFormElement>) {
        ev?.preventDefault();
        if (!records || !records.length) {
            return;
        }
        Promise.all(records?.map((r) => createRecords({ zoneId, body: r })))
            .then(() => nav(clearDialogParams()))
            .catch(handleSubmitError((__, { message }) => setError(message)));
    }

    return (
        <StyledFormDialog
            title="Import Bind Zone File"
            isOpen={isDialogOpen("dns-zone-import")}
            onClose={() => nav(clearDialogParams())}
        >
            <form onSubmit={handleSubmit} className="w-full">
                <DialogColumn className="w-full">
                    {zone ? (
                        <p>
                            <strong>Zone: </strong> {zone.data.origin}
                        </p>
                    ) : null}
                    <p>
                        Upload a zone file to automatically import the records
                        into this DNS zone. For more information on BIND DNS
                        zone files,{" "}
                        <ExternalLink
                            to={
                                "https://bind9.readthedocs.io/en/latest/chapter3.html#example-com-base-zone-file"
                            }
                        >
                            see here.
                        </ExternalLink>
                    </p>
                    <p className="text-cycle-gray dark:text-cycle-gray-light my-2 text-sm">
                        Note: SOA records and root-level (@) NS records are not
                        imported.
                    </p>
                    {records?.length ? (
                        <UploadableRecordTable
                            records={records}
                            onRemove={(idx) => {
                                setRecords((rs) =>
                                    rs?.filter((_, ridx) => idx !== ridx)
                                );
                            }}
                        />
                    ) : (
                        <DropFileInput
                            onChange={handleFileChange}
                            onDragOver={(ev) => ev.preventDefault()}
                            onDrop={handleDrop}
                        />
                    )}
                </DialogColumn>

                <FieldErrorMessage message={error} />
                <DialogFooter>
                    <div>
                        <FieldErrorMessage message="" />
                    </div>

                    <PushAndHoldButton
                        isLoading={isSubmitting}
                        onClick={() => handleSubmit()}
                        type="button"
                        flavor="primary"
                        icon={faFileImport}
                        disabled={!records || !records.length}
                    >
                        Create Records
                    </PushAndHoldButton>
                </DialogFooter>
            </form>
        </StyledFormDialog>
    );
}
