import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export function buildEventsQuery(criteria: { [key: string]: unknown }) {
    const start = formatRFC3339(
        roundToNearestMinutes(subDays(new Date(), 1), {
            roundingMethod: "floor",
            nearestTo: 30,
        })
    );
    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria,
            pipeline: [
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $addFields: {
                        truncatedTime: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "minute",
                                binSize: 30,
                            },
                        },
                    },
                },
                {
                    $project: {
                        truncTime: "$truncatedTime",
                        time: "$time",
                        event: "$metadata.event",
                        component: {
                            id: "$metadata.component._id",
                            type: "$metadata.component.type",
                        },
                        priority: "$metadata.priority",
                        type: "$metadata.type",
                        text: "$text",
                        security_event: "$labels.security:event",
                        security_surface: "$labels.security:surface",
                        security_attack: "$labels.security:attack",
                        user_id: "$labels.user:id",
                        user_type: "$labels.user:type",
                        labels: "$labels",
                    },
                },
            ],
        },
    };
}
