import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    Container,
    GetContainersApiArg,
    useGetContainersQuery,
} from "~/services/cycle";
import { ContainerPublicNetworkBadge } from "@cycleplatform/ui/components/resources";
import { ContainerDeploymentBadge } from "./ContainerDeploymentBadge";
import { Badge } from "@cycleplatform/ui/components/badges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { HybridMultiSelect } from "../common/forms/HybridMultiSelect";

export type ContainerHybridMultiSelectProps = {
    name: string;
    value: string[] | undefined | null;
    disabled?: boolean;
    onChange: (id: string[] | undefined | null) => void;
    skip?: boolean;
    filter?: GetContainersApiArg["filter"];
    placeholder?: string;
    additionalContainers?: Container[];
    creatablePlaceholder?: (v: string) => string;
};

export function ContainerHybridMultiSelect({
    name,
    value,
    disabled,
    onChange,
    filter,
    skip,
    placeholder = "Select a container or enter a container identifier",
    additionalContainers = [],
    creatablePlaceholder = (v) => `Use container identifier "${v}"`,
}: ContainerHybridMultiSelectProps) {
    const {
        currentData: containers,
        error,
        isLoading,
    } = useGetContainersQuery(
        {
            sort: ["-id"],
            filter,
            page: {
                number: 1,
                size: 100,
            },
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <HybridMultiSelect
            name={name}
            isLoading={isLoading}
            value={value}
            onChange={onChange}
            disabled={disabled}
            resources={[...additionalContainers, ...(containers?.data || [])]}
            formatDisplayValue={(c) => {
                const container = c as Container;

                return container.name;
            }}
            formatOption={(c) => {
                const container = c as Container;
                return (
                    <div className="flex justify-between p-2">
                        <div>
                            <strong>{container.name}</strong>
                        </div>
                        <div className="flex gap-2">
                            {!!container.image.service ? (
                                <Badge>
                                    <FontAwesomeIcon
                                        icon={faGear}
                                        className="pr-2"
                                    />{" "}
                                    service
                                </Badge>
                            ) : null}
                            <ContainerDeploymentBadge container={container} />
                            <ContainerPublicNetworkBadge c={container} />
                            <ResourceStateBadge state={container.state} />
                        </div>
                    </div>
                );
            }}
            creatablePlaceholder={creatablePlaceholder}
            placeholder={placeholder}
        />
    );
}
