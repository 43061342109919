import { useSearchParams } from "react-router-dom";
import {
    addExpandedIdentifier,
    removeExpandedIdentifier,
    routerExpandedParam,
} from "../../../../expandedParamHelpers";
import { Disclosure } from "@headlessui/react";
import { generateButtonClasses } from "@cycleplatform/ui/components/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownLeftAndUpRightToCenter,
    faPencil,
} from "@fortawesome/pro-solid-svg-icons";

export function ExpandButton({
    open,
    expandedIdentifier,
    routerExpandedArr,
}: {
    open: boolean;
    expandedIdentifier: string;
    routerExpandedArr: string[];
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = () => {
        const sp = new URLSearchParams(searchParams);

        if (open) {
            // remove identifier from array if closing action
            const newArray = removeExpandedIdentifier(
                routerExpandedArr,
                expandedIdentifier
            );

            if (newArray.length) {
                sp.set(routerExpandedParam, newArray);
            } else {
                sp.delete(routerExpandedParam);
            }
        } else {
            // add identifier to array if opening action
            sp.set(
                routerExpandedParam,
                addExpandedIdentifier(routerExpandedArr, expandedIdentifier)
            );
        }
        setSearchParams(sp);
    };
    return (
        <legend>
            <Disclosure.Button
                as={"div"}
                role="button"
                tabIndex={0}
                onClick={onClick}
            >
                <div
                    className={generateButtonClasses({
                        flavor: "default",
                    })}
                >
                    <FontAwesomeIcon
                        icon={open ? faDownLeftAndUpRightToCenter : faPencil}
                    />
                </div>
            </Disclosure.Button>
        </legend>
    );
}
