import { useEffect, useState } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { CycleAxisProps } from "@cycleplatform/ui/components/recharts/props";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { CycleChartProps } from "@cycleplatform/ui/components/recharts/props";
import { formatBytesString } from "@cycleplatform/core/util";
import { RamUsageByInstanceDataPoint } from "./query";

export function RamUsageByInstanceChart({
    data,
    instanceColorMap,
    instanceLabelMap,
    instanceIds,
}: {
    data: RamUsageByInstanceDataPoint[];
    instanceColorMap: Record<string, string>;
    instanceLabelMap: Record<string, string>;
    instanceIds: string[];
}) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                {...CycleChartProps}
                data={data.slice(1)}
                syncId="container-telem"
            >
                <CartesianGrid {...CycleGridProps} />
                <XAxis
                    {...CycleAxisProps}
                    dataKey="time"
                    tick={(props) => <FormattedTimeTick {...props} />}
                />
                <YAxis
                    {...CycleAxisProps}
                    tickCount={6}
                    domain={[
                        () => 0,
                        (dataMax: number) => {
                            const yBytes = 6 * 1024 * 1024 * 1024;
                            if (dataMax > yBytes) {
                                return dataMax * 1.2;
                            }
                            return yBytes;
                        },
                    ]}
                    tick={(props) => (
                        <FormattedYTick
                            {...props}
                            formatValue={(v: number) =>
                                `${formatBytesString(v)}`
                            }
                        />
                    )}
                />
                <Tooltip
                    content={(props) => (
                        <CycleLineTooltip
                            {...props}
                            labelMap={instanceLabelMap}
                            formatValue={(v: number) =>
                                `${formatBytesString(v)}`
                            }
                        />
                    )}
                />

                {[...instanceIds].map((id) => (
                    <Line
                        {...CycleLineProps}
                        key={id}
                        dataKey={id}
                        stroke={instanceColorMap[id]}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}
