import { Image } from "~/services/cycle";
import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cycleplatform/ui/components/buttons";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { ContainerConfigFormData } from "../../types";
import { BulkUpdateVariables } from "./BulkUpdateVariables";
import { DynamicObjectField } from "@cycleplatform/react-hook-form-components/components";

type EnvironmentVariablesProps = {
    image?: Image;
};

export function EnvironmentVariables({ image }: EnvironmentVariablesProps) {
    const { setValue, control } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <FormSectionHeader header="Environment Variables">
                <BulkUpdateVariables
                    image={image}
                    className="absolute top-0 right-0"
                    onSave={(env) => {
                        setValue("config.runtime.environment_vars", env);
                    }}
                />
            </FormSectionHeader>
            <FormSection>
                <DynamicObjectField
                    control={control}
                    field={`config.runtime.environment_vars`}
                >
                    {({ fields, append, fieldKey, register, remove }) => (
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledHeaderCell className="w-80">
                                    Variable
                                </StyledHeaderCell>
                                <StyledHeaderCell>Value</StyledHeaderCell>
                                <StyledHeaderCell className="w-20"></StyledHeaderCell>
                            </StyledTableHead>

                            <tbody>
                                {fields.map((f, idx) => {
                                    const isImageVar =
                                        image?.config.env &&
                                        Object.keys(image.config.env).includes(
                                            f?.key
                                        );

                                    return (
                                        <StyledTableRow key={f.id}>
                                            <StyledCell>
                                                <div className="flex gap-2 pr-4">
                                                    <RhfFormField
                                                        name={`${fieldKey}.${idx}.key`}
                                                    >
                                                        <TextInput
                                                            placeholder="VARIABLE"
                                                            readOnly={
                                                                isImageVar
                                                            }
                                                            {...register(
                                                                `${fieldKey}.${idx}.key`,
                                                                {
                                                                    ...required(),
                                                                    validate: {
                                                                        unique: (
                                                                            v
                                                                        ) => {
                                                                            if (
                                                                                isImageVar
                                                                            ) {
                                                                                return true;
                                                                            }
                                                                            const res =
                                                                                fields.find(
                                                                                    (
                                                                                        f,
                                                                                        sidx
                                                                                    ) => {
                                                                                        return (
                                                                                            f.key.toLocaleLowerCase() ===
                                                                                                v.toLocaleLowerCase() &&
                                                                                            idx !==
                                                                                                sidx // ensure it's not comparing against the same exact entry.
                                                                                        );
                                                                                    }
                                                                                );
                                                                            return res
                                                                                ? "already in use"
                                                                                : true;
                                                                        },
                                                                    },
                                                                }
                                                            )}
                                                        />
                                                    </RhfFormField>
                                                </div>
                                            </StyledCell>
                                            <StyledCell className="flex-1">
                                                <div className="w-full pr-4">
                                                    <RhfFormField
                                                        name={`${fieldKey}.${idx}.value`}
                                                    >
                                                        <TextInput
                                                            placeholder={
                                                                image?.config
                                                                    .env?.[
                                                                    f.key
                                                                ] || ""
                                                            }
                                                            defaultValue={
                                                                image?.config
                                                                    .env?.[
                                                                    f.key
                                                                ] || ""
                                                            }
                                                            {...register(
                                                                `${fieldKey}.${idx}.value`
                                                            )}
                                                        />
                                                    </RhfFormField>
                                                </div>
                                            </StyledCell>
                                            <StyledCell className="text-end">
                                                <Tooltip
                                                    message="environment variables defined in the image cannot be removed"
                                                    disabled={!isImageVar}
                                                >
                                                    <Button
                                                        flavor="discard"
                                                        disabled={isImageVar}
                                                        onClick={() =>
                                                            remove(idx)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            </StyledCell>
                                        </StyledTableRow>
                                    );
                                })}

                                <StyledTableRow>
                                    <StyledCell colSpan={4}>
                                        <Button
                                            type="button"
                                            icon={faPlus}
                                            className="w-full"
                                            onClick={() =>
                                                append({
                                                    key: "",
                                                    value: "",
                                                })
                                            }
                                        >
                                            Add
                                        </Button>
                                    </StyledCell>
                                </StyledTableRow>
                            </tbody>
                        </StyledDataTable>
                    )}
                </DynamicObjectField>
            </FormSection>
        </>
    );
}
