import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    ContainerBackup,
    CreateContainerBackupJobApiArg,
    useGetInstancesQuery,
    useCreateContainerBackupJobMutation,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesPacking, faSync } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    required,
} from "@cycleplatform/ui/components/forms";
import { $info } from "@cycleplatform/core/util/log";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import classNames from "classnames";

type BackupRestoreDialogProps = {
    backup: ContainerBackup;
};

export function BackupRestoreDialog({ backup }: BackupRestoreDialogProps) {
    // TODO detect backup.new/backup.running and correlate to see if in progress
    return (
        <PositionedMenu
            className={classNames("w-[25rem] font-normal normal-case")}
            render={(_, setIsOpen) => (
                <RestoreBackupForm setIsOpen={setIsOpen} backup={backup} />
            )}
        >
            <Tooltip message="Restore Backup" placement="left">
                <Button
                    className="relative w-10 !px-2 text-xs"
                    icon={faBoxesPacking}
                />
            </Tooltip>
        </PositionedMenu>
    );
}

type RestoreBackupForm = {
    setIsOpen?: (open: boolean) => void;
    backup: ContainerBackup;
};

function RestoreBackupForm({ setIsOpen, backup }: RestoreBackupForm) {
    const [restoreBackup] = useCreateContainerBackupJobMutation();

    const { data: instances, error } = useGetInstancesQuery({
        containerId: backup.container_id,
    });

    const form = useForm<CreateContainerBackupJobApiArg["body"]["contents"]>({
        ...rhfConfig,
    });
    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = form;

    const submit = (
        values: CreateContainerBackupJobApiArg["body"]["contents"]
    ) => {
        if (!isDirty) {
            return;
        }
        return restoreBackup({
            backupId: backup.id,
            containerId: backup.container_id,
            body: {
                action: "restore",
                contents: values,
            },
        }).then(() => {
            $info("Instance restore job created");
            setIsOpen?.(false);
        }, handleSubmitError(form.setError));
    };
    if (error) {
        throw error;
    }
    return (
        <>
            <h5 className="pb-4 text-xl">
                <FontAwesomeIcon
                    className="text-cycle-blue pr-2"
                    icon={faBoxesPacking}
                />{" "}
                Restore Backup
            </h5>
            <p className="mb-4">
                Select an instance below to begin restoring this backup. The
                instance must be running, and it will not be restarted during
                the backup process.
            </p>
            <RhfFormProvider {...form}>
                <RhfFormField label="Instance" name="instance_id" required>
                    <Controller
                        rules={{
                            ...required("you must select a target instance"),
                        }}
                        render={({ field: { ref: _ref, ...field } }) => (
                            <BasicSelect
                                placeholder="Select an Instance..."
                                {...field}
                                options={
                                    instances?.data?.map((i) => ({
                                        label: i.hostname,
                                        value: i.id,
                                    })) || []
                                }
                            />
                        )}
                        control={control}
                        name="instance_id"
                    />
                </RhfFormField>
                <div className="-mx-4 mt-16 -mb-4 flex justify-end gap-2 border-t px-4 py-2">
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <PushAndHoldButton
                        onClick={handleSubmit(submit)}
                        type="button"
                        flavor="primary"
                        isLoading={isSubmitting}
                        icon={faSync}
                        disabled={!isDirty}
                    >
                        Restore Backup
                    </PushAndHoldButton>
                </div>
            </RhfFormProvider>
        </>
    );
}
