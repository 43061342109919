import { IntegrationLogo } from "@cycleplatform/ui/components/resources";
import {
    GetIntegrationsApiArg,
    Integration,
    useGetAvailableIntegrationsQuery,
    useGetIntegrationsQuery,
} from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { selectAppliedTheme } from "~/modules/settings";
import { useAppSelector } from "~/hooks";
import { FieldErrorMessage } from "@cycleplatform/ui/components/forms";
import { isCycleApiError } from "~/services/helpers";
import { generateVendorExtendsMap } from "@cycleplatform/core/modules/integrations";

type IntegrationSelectProps = {
    value?: string | null;
    disabled?: boolean;
    onChange: (
        integrationId: string | undefined,
        integration: Integration | undefined
    ) => void;
    filter?: GetIntegrationsApiArg["filter"];
    placeholder?: string;
    featureExtends?: string;
    isNullable?: boolean;
};

export function IntegrationSelect({
    value,
    disabled,
    onChange,
    filter,
    placeholder = "No Integration selected",
    featureExtends,
    isNullable = true,
}: IntegrationSelectProps) {
    // TODO - there is a risk that the image set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific image
    // if not initially returned and then merge them.
    const {
        data: integrations,
        error,
        isLoading,
    } = useGetIntegrationsQuery({
        filter,
        page: {
            size: 100,
            number: 1,
        },
    });

    const {
        data: available,
        error: availableError,
        isLoading: availableIsLoading,
    } = useGetAvailableIntegrationsQuery();

    const vendorExtendsMap = generateVendorExtendsMap(available?.data);

    const theme = useAppSelector(selectAppliedTheme);

    const resources = !featureExtends
        ? integrations?.data
        : integrations?.data?.filter((i) =>
              vendorExtendsMap[i.vendor]?.includes(
                  featureExtends.toLocaleLowerCase()
              )
          );

    return (
        <>
            <ResourceComboBox
                isLoading={isLoading || availableIsLoading}
                value={value}
                isNullable={isNullable}
                onChange={(id) =>
                    onChange(
                        id,
                        integrations?.data.find((i) => i.id === id)
                    )
                }
                disabled={disabled}
                resources={resources || []}
                formatDisplayValue={(i) => {
                    if (!i) return "";

                    return i.name || i.identifier;
                }}
                filterFields={["identifier", "vendor", "id", "name"]}
                placeholder={placeholder}
                emptyMessage={`No ${
                    filter?.category
                        ? filter.category.split(",").join(" / ")
                        : ""
                } Integrations configured`}
                formatOption={(i) => {
                    return (
                        <FormattedOption
                            label={i.name || i.identifier}
                            detail={`vendor: ${i.vendor}`}
                        >
                            <IntegrationLogo
                                className="w-10"
                                vendor={i.vendor}
                                theme={theme}
                            />
                        </FormattedOption>
                    );
                }}
            />
            <FieldErrorMessage
                className="absolute right-0"
                message={
                    error || availableError
                        ? isCycleApiError(error)
                            ? error.data.error.title
                            : isCycleApiError(availableError)
                            ? availableError.data.error.title
                            : "failed to fetch integrations"
                        : ""
                }
            />
        </>
    );
}
