import {
    faCheckCircle,
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "../../tooltip";

type FeatureLineItem = {
    value: string | number | null;
    label: string;
    tooltip?: React.ReactNode;
    tooltipIcon?: IconProp;
    tooltipClassName?: string;
    notSupported?: boolean;
    className?: string;
    icon?: IconDefinition;
};

export function FeatureLineItem({
    value,
    label,
    tooltip,
    notSupported,
    tooltipIcon,
    tooltipClassName,
    className,
    icon: iconOverride,
}: FeatureLineItem) {
    return (
        <li className={classNames(className, "max-w-full ")}>
            <div className="flex w-full items-center gap-1">
                <div className={classNames("@[14rem]:w-[1.5rem] pr-1")}>
                    <FontAwesomeIcon
                        icon={
                            iconOverride ||
                            (!notSupported ? faCheckCircle : faTimesCircle)
                        }
                        className={classNames(
                            notSupported
                                ? "text-cycle-gray"
                                : "text-cycle-blue",
                            "@[14rem]:text-base text-sm"
                        )}
                    />
                </div>
                <div className="@[22rem]:text-lg @[18rem]:text-base  text-wrap  max-w-full  gap-2 overflow-hidden break-words text-sm">
                    {value} {label}
                </div>

                {tooltip ? (
                    <Tooltip
                        as="span"
                        message={tooltip}
                        className={classNames(
                            tooltipClassName,
                            "text-cycle-blue flex items-center"
                        )}
                    >
                        <FontAwesomeIcon
                            className={"@[14rem]:text-base text-sm"}
                            icon={tooltipIcon || faInfoCircle}
                        />
                    </Tooltip>
                ) : null}
            </div>
        </li>
    );
}
