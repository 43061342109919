import {
    faQuestionCircle,
    faSync,
    faTimesCircle,
    faEnvelopeOpenDollar,
    faUsdCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, BillingInvoice, ComponentIncludes } from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";
import { formatDateString } from "@cycleplatform/core/util";

export function formatInvoiceMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractInvoiceName(item.component, components);
    const invoice = (
        <Link to={`/billing/invoices/${item.component?.id}`}>{name}</Link>
    );
    switch (item.event) {
        case "billing.invoice.task.void":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested void of invoice {invoice}</span>
                ) : (
                    <span>Error requesting void of invoice {invoice}</span>
                ),
                icon: faTimesCircle,
            };
        case "billing.invoice.task.credit":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested credit for invoice {invoice}</span>
                ) : (
                    <span>Error requesting credit for invoice {invoice}</span>
                ),
                icon: faEnvelopeOpenDollar,
            };
        case "billing.invoice.task.refund":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested refund for invoice {invoice}</span>
                ) : (
                    <span>Error requesting refund for invoice {invoice}</span>
                ),
                icon: faUsdCircle,
            };
        case "billing.invoice.task.pay":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested to pay invoice {invoice}</span>
                ) : (
                    <span>Error requesting to pay invoice{invoice}</span>
                ),
                icon: faSync,
            };
        case "billing.invoice.pay":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Paid invoice {invoice}</span>
                ) : (
                    <span>Error paying invoice {invoice}</span>
                ),
                icon: faSync,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractInvoiceName(
    component: Activity["component"],
    components: ComponentIncludes
) {
    const i = extractComponent<BillingInvoice>(component, components);
    if (!i) {
        return component?.id;
    }

    return (
        <span>
            {formatDateString(i.events.created)}
            (...
            {i.id.slice(i.id.length - 6)})
        </span>
    );
}
