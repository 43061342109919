import classNames from "classnames";
import { SkeletonHeader } from "../loaders/skeleton";
import { Tooltip } from "../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

type PanelTitleProps = {
    title: React.ReactNode | undefined;
    fallback?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    help?: string;
};

export function PanelTitle({
    title,
    fallback = <SkeletonHeader size="lg" />,
    children,
    className,
    help,
}: PanelTitleProps) {
    return (
        <div
            className={classNames(
                className,
                "w-full rounded-t-lg p-2 font-semibold uppercase"
            )}
        >
            {!title && fallback ? <>{fallback}</> : null}
            <div className="flex items-center gap-2">
                {title && (
                    <h3 className="whitespace-nowrap text-base">{title}</h3>
                )}
                {help && (
                    <Tooltip message={help} popupClassName="!normal-case">
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-cycle-blue text-xs"
                        />
                    </Tooltip>
                )}
            </div>
            {children}
        </div>
    );
}
