import { useLocation } from "react-router-dom";
import { Activity, ComponentIncludes } from "~/services/cycle";
import {
    formatApiKeyMessage,
    formatBillingOrderMessage,
    formatContainerBackupMessage,
    formatContainerMessage,
    formatDnsRecordMessage,
    formatDnsZoneMessage,
    formatEnvironmentMessage,
    formatHubMessage,
    formatImageMessage,
    formatInfrastructureAutoscaleMessage,
    formatInstanceMessage,
    formatInvoiceMessage,
    formatIPPoolMessage,
    formatMembershipMessage,
    formatMethodMessage,
    formatPipelineMessage,
    formatProviderMessage,
    formatScopedVariableMessage,
    formatSDNMessage as formatSdnMessage,
    formatServerMessage,
    formatStackBuildMessage,
    formatStackMessage,
} from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { formatPipelineRunMessage } from "./messages/pipeline.run";
import { formatRoleMessage } from "./messages/hub.role";
import { formatClusterMessage } from "./messages/infrastructure.cluster";
import { formatVmMessage } from "./messages/vm";
import { formatDnsCertificateMessage } from "./messages/dns.certificate";

type ActivityMessageProps = {
    activity: Activity;
    components: ComponentIncludes;
};

export function ActivityMessage({
    activity,
    components,
}: ActivityMessageProps) {
    let msg: {
        message: string | React.ReactElement;
        icon?: IconDefinition;
        iconClassName?: string;
    };
    switch (true) {
        case activity.event.startsWith("dns.zone.record."):
            msg = formatDnsRecordMessage(activity, components);
            break;
        case activity.event.startsWith("dns.zone."):
            msg = formatDnsZoneMessage(activity, components);
            break;
        case activity.event.startsWith("dns.certificate."):
            msg = formatDnsCertificateMessage(activity, components);
            break;
        case activity.event.startsWith("stack.build."):
            msg = formatStackBuildMessage(activity, components);
            break;
        case activity.event.startsWith("infrastructure.ips."):
            msg = formatIPPoolMessage(activity, components);
            break;
        case activity.event.startsWith("infrastructure.server."):
            msg = formatServerMessage(activity, components);
            break;
        case activity.event.startsWith("infrastructure.cluster."):
            msg = formatClusterMessage(activity, components);
            break;
        case activity.event.startsWith("infrastructure.autoscale."):
            msg = formatInfrastructureAutoscaleMessage(activity, components);
            break;
        case activity.event.startsWith("infrastructure.provider."):
            msg = formatProviderMessage(activity, components);
            break;
        case activity.event.startsWith("billing.order."):
            msg = formatBillingOrderMessage(activity);
            break;
        case activity.event.startsWith("billing.invoice."):
            msg = formatInvoiceMessage(activity, components);
            break;
        case activity.event.startsWith("billing.method."):
            msg = formatMethodMessage(activity, components);
            break;
        case activity.event.startsWith("hub.apikey."):
            msg = formatApiKeyMessage(activity, components);
            break;
        case activity.event.startsWith("hub.role."):
            msg = formatRoleMessage(activity, components);
            break;
        case activity.event.startsWith("hub.membership."):
            msg = formatMembershipMessage(activity, components);
            break;
        case activity.event.startsWith("environment.scoped-variable"):
            msg = formatScopedVariableMessage(activity, components);
            break;
        case activity.event.startsWith("environment."):
            msg = formatEnvironmentMessage(activity, components);
            break;
        case activity.event.startsWith("container.instance."):
        case activity.event.startsWith("container.instances."):
            msg = formatInstanceMessage(activity, components);
            break;
        case activity.event.startsWith("container.backup"):
            msg = formatContainerBackupMessage(activity, components);
            break;
        case activity.event.startsWith("container."):
            msg = formatContainerMessage(activity, components);
            break;
        case activity.event.startsWith("hub."):
            msg = formatHubMessage(activity, components);
            break;
        case activity.event.startsWith("image."):
            msg = formatImageMessage(activity, components);
            break;
        case activity.event.startsWith("stack."):
            msg = formatStackMessage(activity, components);
            break;
        case activity.event.startsWith("sdn."):
            msg = formatSdnMessage(activity, components);
            break;
        case activity.event.startsWith("pipeline.key."):
            msg = formatPipelineMessage(activity, components);
            break;
        case activity.event.startsWith("pipeline.run."):
            msg = formatPipelineRunMessage(activity, components);
            break;
        case activity.event.startsWith("pipeline."):
            msg = formatPipelineMessage(activity, components);
            break;
        case activity.event.startsWith("virtual-machine."):
            msg = formatVmMessage(activity, components);
            break;
        default:
            msg = {
                message: activity.event,
            };
    }

    return (
        <div className="break-after-column overflow-hidden text-ellipsis">
            {msg?.icon && (
                <FontAwesomeIcon
                    className={classNames(
                        "mr-1",
                        msg.iconClassName,
                        activity.error ? "text-error" : "text-cycle-blue"
                    )}
                    icon={msg.icon}
                />
            )}{" "}
            {msg ? msg.message : activity.event}
        </div>
    );
}
