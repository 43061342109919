/* Standardized dialog keys */
import { To } from "react-router-dom";

// SUB PARAMETERS MUST START WITH `dialog-${string}`
export type DialogParametersRecord = {
    // Hubs
    "hub-create": null;
    "hub-select": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    "hub-member-invite": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };

    // API Keys
    "api-key-create": null;

    // Accounts
    account: { "dialog-tab"?: "security" | "hubs" };
    "account-info": { "dialog-account-id": string };

    "change-hub-plan": {
        "dialog-step"?: string;
        "dialog-tier-id"?: string;
        "dialog-tier-tab"?: string;
        "dialog-promo-code"?: string;
    };

    // Environments
    "environment-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };

    "environment-monitoring": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
        "dialog-env-id": string;
    };
    "environment-lb-manage": {
        "dialog-env"?: string;
        "dialog-tab"?: "controllers" | "manage" | "instances" | "firewall";
        "dialog-instance-id"?: string;
        "dialog-port-idx"?: string;

        "dialog-port"?: string;

        "dialog-firewall-subsection"?: string;
        "dialog-controllers-subsection"?: string;
        "dialog-controllers-port-idx"?: string;
    };
    "environment-discovery-manage": {
        "dialog-env": string;
        "dialog-tab"?: "config";
        "dialog-instance-id"?: string;
    };
    "environment-scheduler-manage": {
        "dialog-env": string;
        "dialog-tab"?: "config";
        "dialog-instance-id"?: string;
    };
    "environment-vpn-manage": {
        "dialog-env": string;
        "dialog-tab"?: "auth";
        "dialog-instance-id"?: string;
    };
    "environment-scopedvar-edit": {
        "dialog-var"?: string;
        "dialog-env": string;
    };
    "environment-export": {
        "dialog-env"?: string;
    };

    // Containers
    container: {
        "dialog-tab"?:
            | "instances"
            | "config"
            | "volumes"
            | "backups"
            | "settings";
        "dialog-container-id"?: string;
        "dialog-instance-id"?: string;
        "dialog-volume-idx"?: string;
        "dialog-backup-id"?: string;
        "dialog-config"?: string;
        "dialog-integration"?: string;
        "dialog-sftp"?: string;
        "dialog-focus"?: string;
        "dialog-activity-page"?: string;
    };
    "container-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };

    // Image
    "image-source-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    image: {
        "dialog-tab"?: "build-log";
        "dialog-image-id"?: string;
    };
    // Infrastructure
    "server-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
        "dialog-step"?: string;
        "dialog-integration-id"?: string;
        "dialog-location-id"?: string;
    };

    "cluster-create": null;

    // Settings
    "billing-method-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };

    // DNS
    "sdn-network-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    "dns-zone-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    "dns-record": {
        "dialog-zone-id": string;
        "dialog-record-id"?: string;
    };
    "dns-zone-import": {
        "dialog-zone-id": string;
    };

    "tls-user-cert-upload": {
        "dialog-nav"?: string;
    };

    "autoscaling-group-create": null;

    "autoscaling-group-add-infra": {
        "dialog-group-id": string;
    };

    "autoscaling-group-edit-infra": {
        "dialog-group-id": string;
        "dialog-model-id": string;
    };

    // Stack
    "stack-build": {
        "dialog-tab"?: "images" | "deploy";
        "dialog-stack-id": string;
        "dialog-build-id": string;
    };
    "stack-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };

    // Pipelines
    "pipeline-create": null;
    "pipeline-trigger-key": {
        "dialog-key-id": string;
        "dialog-pipeline-id": string;
    };
    "raw-stack-source": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    "pipeline-run": {
        "dialog-pipeline-id": string;
        "dialog-pipeline-run-id": string;
    };
    "integration-create": {
        "dialog-integration-vendor": string;
    };

    "role-create": null;

    "virtual-machine-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
    "virtual-machine": {
        "dialog-tab"?: "config" | "volumes" | "settings";
        "dialog-id"?: string;
        "dialog-volume-idx"?: string;
        "dialog-config"?: string;
        "dialog-focus"?: string;
        "dialog-activity-page"?: string;
    };
    "virtual-machines-ssh-key-create": {
        /** dialog-nav = "x" will trigger the dialog to close on success  */
        "dialog-nav"?: string;
    };
};

/**
 * Generate a "To" object to open a dialog, along with all sub parameters, that can be used in a <Link /> component or in a navigate().
 * @param dialog the dialog key you wish to generate links for
 * @param values the values for all url search sub-parameters
 * @param searchParams the current search params object
 * @returns An array where the first entry is the open To property, and the second is the close: [open, close]
 */
export const generateDialogLink = <
    DialogKey extends keyof DialogParametersRecord
>(
    dialog: DialogKey,
    values: DialogParametersRecord[DialogKey]
): To => {
    const sp = new URLSearchParams(
        new URL(window.location.toString()).searchParams
    );

    sp.set("dialog", dialog);
    sp.delete("dialog-tab");

    if (values === null) {
        return {
            pathname: window.location.pathname,
            search: sp.toString(),
        };
    }

    Object.entries(values).forEach(([param, value]) => {
        if (sp.get(param)) {
            sp.set(param, value);
            return;
        }
        sp.append(param, value);
    });

    return {
        pathname: window.location.pathname,
        search: sp.toString(),
    };
};

/**
 * Deletes all search params that have the `dialog-${string}` pattern, and the dialog itself.
 * Returns the To object that can be applied to a link or programmatically navigated
 * @param searchParams the current search parameters object from react router
 */
export function clearDialogParams(): To {
    const sp = new URLSearchParams(
        new URL(window.location.toString()).searchParams
    );

    Array.from(sp.keys()).forEach((key) => {
        if (key.startsWith("dialog")) {
            sp.delete(key);
        }
    });

    return {
        search: sp.toString(),
    };
}

/** Returns true if the specified dialog is open */
export function isDialogOpen(dialog: keyof DialogParametersRecord) {
    return (
        new URL(window.location.toString()).searchParams.get("dialog") ===
        dialog
    );
}

/**
 * Returns all url search params that are currently set that begin with `dialog-${string}`
 * @returns a record of dialog search params for the specified dialog
 */
export function getAllDialogSearchParams<
    DialogKey extends keyof DialogParametersRecord
>(): Record<keyof DialogParametersRecord[DialogKey], string> {
    return Array.from(
        new URL(window.location.toString()).searchParams.entries()
    ).reduce((acc, [key, value]) => {
        if (!key.startsWith("dialog-")) {
            return acc;
        }
        acc[key as keyof DialogParametersRecord[DialogKey]] = value;
        return acc;
    }, {} as Record<keyof DialogParametersRecord[DialogKey], string>);
}

export function shouldClose(dialogNav: string) {
    if (dialogNav === "x") {
        return clearDialogParams();
    }
    return undefined;
}
