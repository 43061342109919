import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faSync,
    faCheckCircle,
    faShield,
    faFileLock,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, Cluster, ComponentIncludes, Server } from "~/services/cycle";
import { Link } from "react-router-dom";
import { isErroredActivity } from "./common/util";

export function formatClusterMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const identifier = extractClusterIdentifier(
        item.component?.id || "",
        components
    );
    const link = (
        <Link to={`/infrastructure/clusters/${item.component?.id}`}>
            {identifier}
        </Link>
    );

    switch (item.event) {
        case "infrastructure.cluster.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted cluster {link}</span>
                ) : (
                    <span>Error deleting cluster {link}</span>
                ),
                icon: faTrash,
            };

        case "infrastructure.cluster.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated cluster {link}</span>
                ) : (
                    <span>Error updating cluster {link}</span>
                ),
                icon: faArrowCircleUp,
            };

        case "infrastructure.cluster.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created cluster {link}</span>
                ) : (
                    <span>Error creating cluster {link}</span>
                ),
                icon: faPlus,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractClusterIdentifier(id: string, components: ComponentIncludes) {
    const c = components?.[id];

    if (!c) {
        return id;
    }

    return (c as Cluster).identifier;
}
