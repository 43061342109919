import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { PipelineCreateForm } from "./forms/PipelineCreateForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { Dialog } from "@headlessui/react";

export function PipelineCreateDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title={"Create New Pipeline"}
            isOpen={isDialogOpen("pipeline-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-2/3">
                <PipelineCreateForm />
            </DialogColumn>

            <DialogColumn className="w-1/3">
                <h2 className="pb-2 text-xl">Automate Workflows</h2>
                <div className="pb-2">
                    From simple tasks like importing an image, to complex
                    sequences that affect entire clusters, Deployment Pipelines
                    make it easy to automate nearly any task within Cycle.
                </div>

                <ul>
                    <li>
                        <a
                            href={
                                "https://cycle.io/docs/platform/introduction-to-pipelines"
                            }
                            className="text-cycle-blue text-sm"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Pipelines Overview
                        </a>
                    </li>

                    <li>
                        <a
                            href={
                                "https://cycle.io/docs/platform/pipeline-steps"
                            }
                            className="text-cycle-blue text-sm"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Pipeline Stages and Steps
                        </a>
                    </li>
                </ul>
            </DialogColumn>
        </StyledFormDialog>
    );
}
