import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageContent } from "@cycleplatform/ui/components/page";
import { HostsManageForm } from "./HostsManageForm";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";

export function ConfigTab() {
    const { environment } = useContext(ContainerDialogContext);

    return (
        <DialogPageBody>
            <PageContent>
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                    className="w-full"
                >
                    <HostsManageForm />
                </AccessControlledSection>
            </PageContent>
        </DialogPageBody>
    );
}
