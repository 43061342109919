import { ThemedColors } from "~/util/charts/hooks";

export const eventTypeColorMap = (colors: ThemedColors) => {
    return {
        success: colors["green"].DEFAULT, // green
        info: colors["blue"].dark, // blue accent
        warning: colors["orange"].DEFAULT, // orange - yellow
        error: colors["red"].DEFAULT, // red
        alert: colors["red"].dark, // red accent
        notice: colors["blue"].DEFAULT, // cycle blue
        audit: colors["gray"].light, // light grey
    };
};
