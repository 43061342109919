import { ComponentIncludes, PipelineRunStep } from "~/services/cycle";
import classNames from "classnames";
import { PipelineRunStepMessage } from "./PipelineStepMessage";
import { PipelineStepDuration } from "./PipelineStepDuration";
import { PipelineStepError } from "./PipelineStepError";
import { PipelineStepErrorIcon } from "./PipelineStepErrorIcon";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { StackBuildGenerateDetails } from "./advancedStepDetails/StackBuildGenerateDetails";
import { useState } from "react";
import { ImageImportDetails } from "./advancedStepDetails/ImageImportDetails";

export function Step({
    step,
    isRunning,
    components,
}: {
    step: PipelineRunStep;
    isRunning: boolean;
    components?: ComponentIncludes;
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const advancedComponent = getAdvancedStepComponent(step);

    const status = step.error
        ? "error"
        : isRunning
        ? "running"
        : step.success
        ? "complete"
        : "idle";

    return (
        <li className="flex">
            <div
                className={classNames(
                    "border-l-4",
                    status === "error" && "border-error",
                    status === "running" && "border-success animate-pulse",
                    status === "complete" && "border-success",
                    status === "idle" &&
                        "border-cycle-gray dark:border-cycle-gray-light/50"
                )}
            />
            <div
                className={classNames(
                    "flex w-full items-center justify-between gap-4 p-4"
                )}
            >
                <div>
                    <PipelineRunStepMessage
                        step={step}
                        components={components}
                    />
                    <PipelineStepDuration step={step} />
                    <PipelineStepError step={step} />
                </div>

                <PipelineStepErrorIcon step={step} />

                {advancedComponent !== null ? (
                    <Button
                        onClick={() => setIsExpanded(!isExpanded)}
                        icon={isExpanded ? faChevronUp : faChevronDown}
                    />
                ) : null}
            </div>

            <div
                className={classNames("pl-4", isExpanded ? "block" : "hidden")}
            >
                {advancedComponent}
            </div>
        </li>
    );
}

function getAdvancedStepComponent(
    step: PipelineRunStep
): React.ReactNode | null {
    if (!step.components) {
        return null;
    }

    if (step.action === "stack.build.generate") {
        return <StackBuildGenerateDetails step={step} />;
    }

    if (step.action.startsWith("image") && step.action.endsWith("import")) {
        return <ImageImportDetails step={step} />;
    }

    return null;
}
