import classNames from "classnames";

export function DialogFooter({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <footer
            className={classNames(
                "mt-auto flex justify-end gap-4 border-t pt-4",
                "border-[rgba(0,0,0,0.04)] dark:border-[rgba(255,255,255,0.04)]",
                "dark:bg-cycle-black-accent  absolute left-4 right-4 bottom-0 bg-white pb-4",

                className
            )}
        >
            {children}
        </footer>
    );
}
