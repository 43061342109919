import {
    FormSectionHeader,
    FormSection,
    PillButtons,
    RhfFormField,
    required,
    isTrue,
} from "@cycleplatform/ui/components/forms";
import {
    ConfigureStepType,
    DialogFormStepTypes,
} from "~/components/dialogs/dns/zone-create/types";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";
import { IsRootOrigin } from "./IsRootOrigin";
import { IsInvalidConfig } from "./IsInvalidConfig";
import { IsSubdomain } from "./IsSubdomain";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { getSubdomains } from "@cycleplatform/core/util";
import { ErrorMessage } from "@hookform/error-message";

export function ManageHostedDomain() {
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();

    const originParts = values[0].origin.split(".");

    const isPossibleSubdomain = !!getSubdomains(originParts.join("."))
        .subdomains;

    if (!isPossibleSubdomain) {
        return <IsRootOrigin />;
    }

    return <IsPossibleSubdomain originParts={originParts} />;
}

function IsPossibleSubdomain({ originParts }: { originParts: string[] }) {
    const form = useFormContext<ConfigureStepType>();
    const { control, setValue } = form;

    const isConfirmed = useWatch({ name: "isConfirmed", control });

    return (
        <>
            <FormSectionHeader header="Verify" />
            <FormSection>
                <RhfFormField name="isConfirmed">
                    <div className="flex items-center gap-4 rounded-md ">
                        <div>
                            <p className="text-base">
                                It looks like{" "}
                                <p className="inline font-semibold">
                                    {originParts.join(".")}
                                </p>{" "}
                                is a subdomain of{" "}
                                <p className="inline font-semibold">
                                    {originParts.slice(1).join(".")}
                                </p>
                                . Do you plan to continue managing{" "}
                                <p className="inline font-semibold">
                                    {originParts.slice(1).join(".")}
                                </p>{" "}
                                outside of Cycle?
                            </p>
                        </div>

                        <Controller
                            name={"isConfirmed"}
                            control={control}
                            rules={{
                                validate: {
                                    ...isTrue(
                                        "This field must be set to 'yes' to continue"
                                    ),
                                },
                            }}
                            render={({ field: { ref: _ref, ...field } }) => {
                                return (
                                    <PillButtons
                                        {...field}
                                        className="ring-cycle-blue ring-1"
                                        options={[
                                            {
                                                value: true,
                                                label: "Yes",
                                            },
                                            {
                                                value: false,
                                                label: "No",
                                            },
                                        ]}
                                    />
                                );
                            }}
                        />
                    </div>
                </RhfFormField>
            </FormSection>

            {isConfirmed === true ? <IsSubdomain /> : null}
            {isConfirmed === false ? <IsInvalidConfig /> : null}
        </>
    );
}
