import {
    FormSection,
    FormSectionHeader,
    required,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { faCode, faFile } from "@fortawesome/pro-solid-svg-icons";
import { useFormContext, useWatch } from "react-hook-form";
import { StackCreateFormType } from "./CreateStackForm";
import { OptionButton } from "@cycleplatform/ui/components/options";

export function StackCreateGeneralSection() {
    const form = useFormContext<StackCreateFormType>();
    const { control, setValue, register, trigger } = form;
    const sourceType = useWatch({ name: "sourceType", control });

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <RhfFormField label="stack name" name="name" required>
                    <TextInput {...register("name", { ...required() })} />
                </RhfFormField>
                <RhfFormField name="sourceType">
                    <div className="flex w-full gap-4 ">
                        <TextInput
                            {...register("sourceType", { ...required() })}
                            className="hidden"
                        />
                        <OptionButton
                            onClick={() => {
                                setValue("sourceType", "git-repo", {
                                    shouldDirty: true,
                                });
                                trigger("sourceType");
                            }}
                            title="Import from Git Repo"
                            description="Import multiple images using a cycle.json file in
                            the root of a git repo"
                            isActive={sourceType === "git-repo"}
                            icon={faCode}
                        />

                        <OptionButton
                            onClick={() => {
                                setValue("sourceType", "raw", {
                                    shouldDirty: true,
                                });
                                trigger("sourceType");
                            }}
                            title="Import from file"
                            description="Paste a cycle.json file and automatically build a stack from it"
                            isActive={sourceType === "raw"}
                            icon={faFile}
                        />
                    </div>
                </RhfFormField>
            </FormSection>
        </>
    );
}
