import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Badge } from "@cycleplatform/ui/components/badges";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { faChevronDoubleDown } from "@fortawesome/pro-solid-svg-icons";

type ResourceDeprecatedBadgeProps<T extends { deprecate: boolean }> = {
    resource: T;
    resourceName?: string;
    className?: string;
};

export function ResourceDeprecatedBadge<T extends { deprecate: boolean }>({
    resource,
    resourceName = "container",
    className,
}: ResourceDeprecatedBadgeProps<T>) {
    if (!resource.deprecate) {
        return null;
    }

    return (
        <div>
            <Tooltip
                message={`This ${resourceName} is deprecated and can only be started manually.`}
            >
                <Badge
                    className={classNames(
                        className,
                        "!bg-cycle-gray !h-8",
                        !resource && skeletonStyles,
                        "dark:bg-cycle-gray items-center gap-2 whitespace-nowrap px-2 !text-xs leading-4 text-white"
                    )}
                >
                    <FontAwesomeIcon
                        icon={faChevronDoubleDown}
                        className="text-error pt-0.5 text-xs"
                    />
                    <div className=" !text-xs">deprecated</div>
                </Badge>
            </Tooltip>
        </div>
    );
}
