import { components } from "../api/__generated";

export function generateVendorExtendsMap(
    available?: Record<
        string,
        components["schemas"]["IntegrationDefinition"][] | null | undefined
    >
) {
    return Object.values(available || {})?.reduce((acc, cur) => {
        const extendsMapByCategory = cur?.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.vendor]:
                    cur.extends?.map((f) => f.toLocaleLowerCase()) || [],
            };
        }, {} as Record<string, string[]>);
        return { ...acc, ...extendsMapByCategory };
    }, {} as Record<string, string[]>);
}
