import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { selectNotificationConnected } from "~/modules/notifications/slice";
import { ErrorFallback } from "./ErrorFallback";
import { useLocation } from "react-router-dom";
import { Capability } from "~/services/cycle";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { PermissionDenied } from "@cycleplatform/ui/components/resources/panels";
import { ErrorBoundary } from "react-error-boundary";
import { CycleApiErrorResponse, isCycleApiError } from "~/services/helpers";
import { AccessError } from "@cycleplatform/core/modules/acls/util";

type CycleErrorBoundaryProps = {
    /** the name of the resource, i.e. 'environment' - singular */
    resourceName?: string;
    capability?: Capability | Capability[];
    children: React.ReactNode;
    fallbackWrapper?: (
        children: React.ReactNode,
        error?: CycleApiErrorResponse
    ) => React.ReactNode;
};

export function CycleErrorBoundary({
    resourceName = "resource",
    capability = [],
    children,
    fallbackWrapper = (c) => c,
}: CycleErrorBoundaryProps) {
    const hub = useAppSelector(selectActiveHub);

    const reconnected = useAppSelector(selectNotificationConnected);

    const location = useLocation();

    try {
        const capabilityError = useCapability(capability);
        if (!!capabilityError && capabilityError instanceof AccessError) {
            // we're going to pre-check our capabilities to help make things snappier
            return fallbackWrapper(
                <div className="w-full rounded p-4">
                    <PermissionDenied
                        resourceName={resourceName}
                        capability={capability}
                    />
                </div>
            );
        }
    } catch (e) {
        // hub membership failed to fetch
        // don't blow this up to the root
    }

    return (
        <ErrorBoundary
            resetKeys={[
                window.location.pathname,
                window.location.search,
                location.pathname,
                hub?.id,
                reconnected,
            ]}
            FallbackComponent={(props) =>
                fallbackWrapper(
                    ErrorFallback({
                        capability,
                        resourceName,
                        ...props,
                    }),
                    isCycleApiError(props.error) ? props.error : undefined
                )
            }
        >
            {children}
        </ErrorBoundary>
    );
}
