import {
    CreateImageSourceApiArg,
    UpdateImageSourceApiArg,
} from "~/services/cycle";

export type GitLocationType = "git-repo" | "tarball" | undefined;

export type ImageSourceCreateEditSubmitType =
    | UpdateImageSourceApiArg["body"]
    | CreateImageSourceApiArg["body"];

export const formatTypeLocationOption = (
    t: CreateImageSourceApiArg["body"]["origin"]["type"],
    l: GitLocationType
) => {
    return `${t}${l ? `:${l}` : ""}`;
};

export const sourceTypes: Record<string, { value: string; label: string }> = {
    "docker-hub": {
        value: "docker-hub",
        label: "Docker Hub" as const,
    },
    "docker-registry": {
        label: "Docker Registry",
        value: "docker-registry" as const,
    },
    "docker-file:git-repo": {
        label: "Docker File - Git Repo",
        value: "docker-file:git-repo" as const,
    },
    "docker-file:tarball": {
        label: "Docker File - Tarball",
        value: "docker-file:tarball" as const,
    },
    "oci-registry": {
        label: "OCI Registry",
        value: "oci-registry" as const,
    },
};
