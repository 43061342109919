import { Controller, useForm } from "react-hook-form";
import {
    CreateEnvironmentApiArg,
    useCreateEnvironmentMutation,
} from "~/services/cycle";
import {
    Checkbox,
    RhfGlobalFormError,
    RhfFormField,
    RhfFormProvider,
    TextInput,
    ExpandableFormSection,
    required,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { getAllDialogSearchParams, shouldClose } from "../../helpers";
import { ClusterSelect } from "~/components/infrastructure/clusters/ClusterSelect";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
    getAclDefaultValues,
} from "~/components/common/acl";
import { ExternalLink } from "~/components/common/links";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type CreateEnvironmentFormType = Omit<
    CreateEnvironmentApiArg["body"],
    "acl"
> & {
    acl: AclForm;
};

export function CreateEnvironmentForm() {
    const params = getAllDialogSearchParams<"environment-create">();

    const form = useForm<CreateEnvironmentFormType>({
        defaultValues: { cluster: "", acl: getAclDefaultValues(undefined) },
        ...rhfConfig,
    });

    const {
        register,
        handleSubmit,
        setError,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const nav = useNavigate();
    const [createEnvironment] = useCreateEnvironmentMutation();

    const onSubmit = async (data: CreateEnvironmentFormType) => {
        const submitBody = {
            ...data,
            ...createAclSubmitBody(data.acl, "create"),
        };

        return createEnvironment({
            body: submitBody,
        })
            .unwrap()
            .then(
                (r) =>
                    nav(
                        shouldClose(params["dialog-nav"]) ||
                            `/environments/${r?.data?.id}`
                    ),
                handleSubmitError(setError)
            );
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <FormSectionHeader header="General" />
                <FormSection>
                    <div className="mb-4 flex flex-col">
                        <RhfFormField label="name" name="name" required>
                            <TextInput
                                {...register("name", { ...required() })}
                            />
                        </RhfFormField>

                        <RhfFormField label="cluster" name="cluster" required>
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => <ClusterSelect {...field} />}
                                rules={{ ...required() }}
                                control={control}
                                name="cluster"
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="description"
                            name="about.description"
                        >
                            <TextInput {...register("about.description")} />
                        </RhfFormField>
                    </div>
                </FormSection>
                <ResourceAclCreate resourceType="environment" />
                <ExpandableFormSection>
                    {() => (
                        <RhfFormField
                            label="Legacy Networking"
                            name="features.legacy_networking"
                        >
                            <div className="flex items-center gap-4">
                                <Checkbox
                                    defaultChecked={false}
                                    {...register("features.legacy_networking")}
                                />
                                <span className="text-sm">
                                    Enables IPv4 support for this environment.{" "}
                                    <ExternalLink to="https://cycle.io/docs/platform/introduction-to-environments">
                                        Check out the documentation for more
                                        information.
                                    </ExternalLink>
                                </span>
                            </div>
                        </RhfFormField>
                    )}
                </ExpandableFormSection>
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <div>
                    <LoaderButton
                        icon={faPlus}
                        isLoading={isSubmitting}
                        type="submit"
                        flavor="primary"
                        disabled={!isDirty}
                    >
                        Create Environment
                    </LoaderButton>
                </div>
            </DialogFooter>
        </RhfFormProvider>
    );
}
