import {
    DockerHubSearchResponseResult,
    useDockerhubSearchQuery,
} from "~/services/portal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import {
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";

type DockerImageSelectProps = {
    name: string;
    value?: string;
    disabled?: boolean;
    onChange: (imageName: string | undefined) => void;
};

export function DockerImageSelect({
    name,
    value,
    disabled,
    onChange,
}: DockerImageSelectProps) {
    const [query, setQuery] = useState(value?.split(":")[0]);
    const [options, setOptions] = useState<DockerHubSearchResponseResult[]>();

    const { data: dockerSearch, error } = useDockerhubSearchQuery(
        {
            query: query || "",
            count: 100,
        },
        { skip: !query }
    );

    if (error) {
        throw error;
    }

    useEffect(() => {
        if (dockerSearch?.results) {
            setOptions(dockerSearch?.results);
        }
    }, [dockerSearch?.results]);

    return (
        <div className="flex w-full gap-4">
            <RhfFormField label="image name" name={name} required>
                <DockerSearchComboBox
                    name={name}
                    onSearchQueryChanged={(q) => setQuery(q)}
                    value={value}
                    onChange={(id) => onChange(`${id}:latest`)}
                    disabled={disabled}
                    resources={options || []}
                />
            </RhfFormField>

            <FormField label="tag" required className="w-1/4">
                <TextInput
                    disabled={!value}
                    value={value?.split(":")[1]}
                    onChange={(v) => {
                        if (value) {
                            onChange(
                                value?.split(":")[0] + ":" + v.target.value
                            );
                        }
                    }}
                />
            </FormField>
        </div>
    );
}

type DockerSearchComboBoxProps = {
    name?: string;
    onSearchQueryChanged?: (v: string) => void;
    value: DockerHubSearchResponseResult["name"] | undefined;
    resources: DockerHubSearchResponseResult[];
    onChange: (
        id: string | undefined,
        resource: DockerHubSearchResponseResult | undefined
    ) => void;
    disabled?: boolean;
    placeholder?: string;
};

function valueToResource(val?: string) {
    return {
        is_automated: false,
        name: val,
        is_trusted: false,
        is_official: false,
        star_count: 0,
        description: "",
    } as DockerHubSearchResponseResult;
}

/**
 * Custom Combobox to handle the dynamic Docker Image Select
 * Needed since the value is updated from both the name field and the tag field,
 *
 * Once the tag is appended to the value, we will need to remove it in order to index
 * the resourceByName object
 */
export function DockerSearchComboBox({
    value,
    onChange,
    resources,
    placeholder = "Start typing to search",
    ...props
}: DockerSearchComboBoxProps) {
    const [resourceByName, setResourceByName] = useState<
        Record<string, DockerHubSearchResponseResult>
    >({ ...resourcesToResourcesByName(resources, value?.split(":")[0]) });

    useEffect(() => {
        setResourceByName(
            resourcesToResourcesByName(resources, value?.split(":")[0])
        );
    }, [resources]);

    return (
        <SelectInput
            {...props}
            formatCreatableValue={(v) => {
                return {
                    is_automated: false,
                    name: v,
                    is_trusted: false,
                    is_official: false,
                    star_count: 0,
                    description: "",
                };
            }}
            placeholder={placeholder}
            isCreatable
            filterFields={["name", "description"]}
            options={resources}
            defaultValue={
                value
                    ? {
                          is_automated: false,
                          name: value,
                          is_trusted: false,
                          is_official: false,
                          star_count: 0,
                          description: "",
                      }
                    : undefined
            }
            value={value ? resourceByName[value?.split(":")[0]] : undefined}
            onChange={(newValue) => {
                if (!newValue) {
                    onChange(undefined, undefined);
                    return;
                }
                setResourceByName({
                    ...resourceByName,
                    [newValue.name]: newValue,
                });
                onChange(newValue.name, newValue);
            }}
            formatOption={(i) => (
                <FormattedOption
                    label={
                        <div className="flex">
                            <strong className="pr-2">{i?.name}</strong>
                            <div className="w-4">
                                {i?.is_official && (
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                )}
                            </div>
                        </div>
                    }
                    detail={i?.description}
                />
            )}
            formatDisplayValue={(i) => {
                if (!i) return "";
                return i.name;
            }}
        />
    );
}

export function resourcesToResourcesByName(
    resources: DockerHubSearchResponseResult[],
    currentValue?: string
) {
    const transformedResources = resources.reduce((resourceById, resource) => {
        if (!resource?.name) {
            return resourceById;
        }
        resourceById[resource.name] = resource;
        return resourceById;
    }, {} as Record<string, DockerHubSearchResponseResult>);

    if (
        !currentValue ||
        Object.keys(transformedResources).includes(currentValue)
    ) {
        return transformedResources;
    }

    // If the current value is not included in the transformedResources, then add it
    // this is neccessary for the indexing to work properly
    return {
        ...transformedResources,
        [currentValue]: valueToResource(currentValue),
    };
}
