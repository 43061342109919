import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import {
    useGetBillingTiersQuery,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import { FormServerSchema } from "../form";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { formatRFC3339, roundToNearestMinutes, subHours } from "date-fns";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export const useSetServerParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback(
        ({
            locationId,
            integrationId,
            step,
        }: {
            locationId?: string | null;
            integrationId?: string | null;
            step?: string;
        }) => {
            const nsp = new URLSearchParams(searchParams);

            if (locationId === null) {
                nsp.delete("dialog-location-id");
            }

            if (integrationId === null) {
                nsp.delete("dialog-integration-id");
            }

            if (locationId) {
                nsp.set("dialog-location-id", locationId);
            }

            if (integrationId) {
                nsp.set("dialog-integration-id", integrationId);
            }
            if (step) {
                nsp.set("dialog-step", step);
            }
            setSearchParams(nsp);
        },
        [searchParams, setSearchParams]
    );
};

export function useGetServerUsage(
    newServers: Record<string, FormServerSchema>
) {
    const { data: tiers, error: tierError } = useGetBillingTiersQuery();
    const { data: summary, error: summaryError } =
        useGenerateAggregatedMetricsQuery(
            {
                filter: {
                    ["range-start"]: formatRFC3339(
                        roundToNearestMinutes(subHours(new Date(), 1), {
                            roundingMethod: "floor",
                        })
                    ),
                    ["range-end"]: formatRFC3339(
                        roundToNearestMinutes(new Date(), {
                            roundingMethod: "floor",
                        })
                    ),
                },
                body: {
                    criteria: {
                        "metadata.metric": "infrastructure.cluster.servers.num",
                    },
                    pipeline: [
                        {
                            $sort: {
                                time: -1,
                            },
                        },
                        {
                            $project: {
                                count: {
                                    $arrayElemAt: [
                                        {
                                            $arrayElemAt: ["$points", 0],
                                        },
                                        1,
                                    ],
                                },
                                cluster: "$metadata.cluster",
                            },
                        },
                        {
                            $group: {
                                _id: "$cluster",
                                servers: {
                                    $last: "$count",
                                },
                            },
                        },
                        {
                            $group: {
                                _id: null,
                                servers: {
                                    $sum: "$servers",
                                },
                            },
                        },
                        {
                            $project: {
                                _id: 0,
                            },
                        },
                    ],
                },
            },
            { pollingInterval: AGGREGATE_POLLING_MS }
        );

    const hub = useAppSelector(selectActiveHub);

    const currentTierId = hub?.billing?.plans?.tier_id;
    const tier = tiers?.data?.find((t) => t.id === currentTierId);

    const additionalServers = Object.values(newServers)?.reduce((acc, cur) => {
        return acc + cur.quantity;
    }, 0);

    const existing = summary?.data?.[0] as { servers: number } | undefined;

    if (summaryError) {
        throw summaryError;
    }

    if (tierError) {
        throw tierError;
    }

    return {
        tier: tier,
        additional: additionalServers,
        included: tier?.servers?.included || 0,
        current: existing?.servers || 0,
        hardCap: tier?.servers?.hard_cap || false,
        isLoading: !tier || !summary?.data,
    };
}

export function useCalcGen2TierUpgradeInfo(
    servers: Record<string, FormServerSchema>
) {
    const { tier, included, current, additional } = useGetServerUsage(servers);
    const totalServerCount = current + additional;
    let cycleAddedCost = 0;

    if (totalServerCount < included) return cycleAddedCost;

    const overageServers =
        current > included
            ? totalServerCount - current
            : totalServerCount - included;

    const overageCost = tier?.servers?.additional?.mills || 0;

    cycleAddedCost = overageCost * overageServers;

    return cycleAddedCost;
}
