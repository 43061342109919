import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Image, ImageSource, useUpdateImageMutation } from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { AccessControlOverlay } from "~/components/common/buttons";
import { useContext } from "react";
import { ImageDialogContext } from "../context";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type UpdateImageSourceProps = {
    source?: ImageSource;
    image?: Image;
};

export type UpdateImageSubmitType = {
    name: string;
};

function getDefaultValues(i?: Image) {
    return {
        name: i?.name || "",
    };
}

export function UpdateImage({ source, image }: UpdateImageSourceProps) {
    const form = useForm<UpdateImageSubmitType>({
        defaultValues: getDefaultValues(image),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, image, (i) => getDefaultValues(i));

    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updateImage] = useUpdateImageMutation();
    const onSubmit = async (data: UpdateImageSubmitType) => {
        if (!isDirty) {
            return;
        }
        return updateImage({
            imageId: image?.id || "",
            body: data,
        })
            .unwrap()
            .catch(handleSubmitError(form.setError));
    };

    return (
        <AccessControlledSection
            aclResource={source}
            verifyFn={modifyAccessFn("images-sources-manage")}
        >
            <Panel>
                <RhfFormProvider {...form}>
                    <PanelTitle title="Update Image" />
                    <PanelContent>
                        <div className="flex justify-between">
                            <div className="w-full pr-4">
                                <RhfFormField label="name" name="name" required>
                                    <TextInput
                                        className="my-2 w-full"
                                        spellCheck={false}
                                        {...register("name", { ...required })}
                                    />
                                </RhfFormField>
                            </div>
                        </div>

                        <PanelFooter>
                            <div>
                                <RhfGlobalFormError />
                            </div>
                            <AccessControlOverlay
                                aclResource={source}
                                verifyFn={modifyAccessFn(
                                    "images-sources-manage"
                                )}
                            >
                                <PushAndHoldButton
                                    icon={faEdit}
                                    type="button"
                                    flavor="primary"
                                    onClick={handleSubmit(onSubmit)}
                                    isLoading={isSubmitting}
                                    disabled={!isDirty}
                                >
                                    Update
                                </PushAndHoldButton>
                            </AccessControlOverlay>
                        </PanelFooter>
                    </PanelContent>
                </RhfFormProvider>
            </Panel>
        </AccessControlledSection>
    );
}
