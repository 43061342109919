export const ApiResponseColorMap: Record<string, string> = {
    "100": "#7dfffb",
    "101": "#31aba1",
    "102": "#0777d3",

    "200": "#22d368",

    "201": "#C6FF7D",
    "202": "#A2EA45",
    "203": "#7CD010",
    "204": "#55D010",
    "205": "#6ABE3B",
    "206": "#6BA849",
    "207": "#1A9B00",
    "208": "#157A01",
    "226": "#005D15",

    "300": "#d774ff",
    "301": "#d774ff",
    "302": "#d774ff",
    "303": "#74b7ff",
    "304": "#2aa7ff",
    "305": "#391ee9",
    "306": "#1c0b9f",
    "307": "#9774ff",
    "308": "#64048a",

    "400": "#fed778",
    "401": "#fed069",
    "402": "#fec95b",
    "403": "#fec34f",
    "404": "#feba46",
    "405": "#feb23f",
    "406": "#fea937",
    "407": "#fe9f2e",
    "408": "#fd9728",
    "409": "#f98e23",
    "410": "#f5851f",
    "411": "#f27d1b",
    "412": "#ee7416",
    "413": "#e86c12",
    "414": "#e2650f",
    "415": "#db5d0b",
    "416": "#d55607",
    "417": "#ce4f03",
    "418": "#c44802",
    "421": "#bb4403",
    "422": "#b03f03",
    "423": "#a43904",
    "424": "#9b3504",
    "426": "#903104",
    "428": "#842e05",
    "429": "#7b2b05",
    "431": "#702806",
    "451": "#662506",
    "499": "#fda660",

    "500": "#FF4500",
    "501": "#FF5500",
    "502": "#FF6600",
    "503": "#FF7700",
    "504": "#FF8800",
    "505": "#FF9900",
    "506": "#FFAA00",
    "507": "#FFBB00",
    "508": "#FFCC00",
    "510": "#FFDD00",
    "511": "#FFEE00",

    empty: "#BABA",
};

export const DisconnectReasonsColorMap: Record<string, string> = {
    no_error: "#22d368",
    request_invalid: "#E57373",
    connection_invalid: "2aa7ff",
    timeout_idle: "#FF5252",
    timeout_init: "#FF8800",
    router_none: "#EC407A",
    router_nomatch: "#880E4F",
    destination_unavailable: "#F50057",
    unknown_error: "#d774ff",
    empty: "#BABA",
};
