import { formatRFC3339, roundToNearestMinutes, subHours } from "date-fns";

export type LatencyChartMetric = {
    destination_instance_id: string;
    instance_id: string;
    latency: Array<{ time: string; value: number }>;
};

export function buildLatencyQuery(
    environmentId: string | undefined,
    port: number
) {
    const start = formatRFC3339(
        roundToNearestMinutes(subHours(new Date(), 1), {
            nearestTo: 1,
            roundingMethod: "floor",
        })
    );
    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria: {
                "metadata.metric": "lb.controller.router.destinations.latency",
                "labels.environment:id": environmentId,
                "labels.port": `${port}`,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        destination_instance_id:
                            "$labels.destination:instance_id",
                        instance_id: "$labels.instance:id",
                        time: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                0,
                            ],
                        },
                        point: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        partitionByFields: [
                            "destination_instance_id",
                            "instance_id",
                        ],
                        range: {
                            step: 1,
                            unit: "minute",
                            bounds: [
                                start,
                                formatRFC3339(
                                    roundToNearestMinutes(new Date())
                                ),
                            ],
                        },
                    },
                },
                {
                    $fill: {
                        output: {
                            point: {
                                value: null,
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                            destination_instance_id: "$destination_instance_id",
                            instance_id: "$instance_id",
                        },
                        destination_instance_id: {
                            $first: "$destination_instance_id",
                        },
                        instance_id: {
                            $first: "$instance_id",
                        },
                        time: {
                            $last: {
                                $dateTrunc: {
                                    date: "$time",
                                    unit: "minute",
                                    binSize: 1,
                                },
                            },
                        },
                        point: {
                            $last: "$point",
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $group: {
                        _id: {
                            destination_instance_id: "$destination_instance_id",
                            instance_id: "$instance_id",
                        },
                        destination_instance_id: {
                            $first: "$destination_instance_id",
                        },
                        instance_id: {
                            $first: "$instance_id",
                        },
                        latency: {
                            $push: {
                                time: "$time",
                                value: "$point",
                            },
                        },
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
            ],
        },
    };
}
