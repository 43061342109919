import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Panel, PanelContent } from "@cycleplatform/ui/components/panels";
import { useFormContext } from "react-hook-form";
import {
    ServerInfo,
    ServerPrice,
    ServerTitle,
} from "@cycleplatform/ui/components/resources";
import { CreateServerSchema } from "../../../form";
import { ProviderServerModel } from "~/services/cycle";

interface ServerOptionTileProps {
    providerServer: ProviderServerModel;
    providerName: string;
}

export function ServerOptionTile({
    providerServer,
    providerName,
}: ServerOptionTileProps) {
    const { watch } = useFormContext<CreateServerSchema>();
    const servers = watch("servers");

    return (
        <li>
            <Panel className="mb-2 last:mb-0">
                <PanelContent>
                    <div className="flex items-center justify-between">
                        <div className="w-20">
                            <AddRemoveButton
                                servers={servers}
                                providerServer={providerServer}
                            />
                        </div>
                        <div className="flex w-full flex-1 items-center gap-4 ">
                            <ServerTitle
                                providerServer={providerServer}
                                className="w-[40%]"
                            />
                            <ServerInfo
                                providerServer={providerServer}
                                className="w-[40%]"
                            />

                            <ServerPrice
                                className="w-[20%]"
                                providerServer={providerServer}
                                providerName={providerName}
                            />
                        </div>
                    </div>
                </PanelContent>
            </Panel>
        </li>
    );
}

type AddRemoveButtonProps = {
    servers: Record<
        string,
        {
            model_id: string;
            quantity: number;
            hostnames?: string[] | undefined;
        }
    >;
    providerServer: ProviderServerModel;
};

function AddRemoveButton({ servers, providerServer }: AddRemoveButtonProps) {
    const { setValue } = useFormContext<CreateServerSchema>();

    const addServer = (modelId: string) => {
        const exists = !!servers[modelId];
        if (!exists) {
            setValue("servers", {
                ...servers,
                [modelId]: {
                    model_id: modelId,
                    quantity: 1,
                    hostnames: [providerServer.name],
                },
            });
            return;
        }
        setValue("servers", {
            ...servers,
            [modelId]: {
                model_id: modelId,
                quantity: servers[modelId].quantity + 1,
                hostnames: [providerServer.name],
            },
        });
        return;
    };
    const removeServer = (modelId: string) => {
        const exists = !!servers[modelId];
        if (!exists) {
            return;
        }

        if (servers[modelId].quantity === 1) {
            const { [modelId]: _, ...rest } = servers;
            setValue("servers", {
                ...rest,
            });
            return;
        }

        setValue("servers", {
            ...servers,
            [modelId]: {
                model_id: modelId,
                quantity: servers[modelId].quantity - 1,
                hostnames: [providerServer.name],
            },
        });
        return;
    };
    return (
        <div className="px-4">
            <button type="button" onClick={() => addServer(providerServer.id)}>
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
            <h3 className="text-3xl font-semibold">
                {servers[providerServer.id]?.quantity || 0}
            </h3>
            <button
                type="button"
                onClick={() => removeServer(providerServer.id)}
            >
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
        </div>
    );
}
