import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Step } from "./step/Step";
import { useContext, useMemo } from "react";
import { PipelineRunDialogContext } from "../../context";
import {
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { PipelineRun } from "~/services/cycle";

export function PipelineStagesSection() {
    const { run, components } = useContext(PipelineRunDialogContext);

    const { stageIdx: runningStageIdx, stepIdx: runningStepIdx } = useMemo(
        () => findRunningIndex(run),
        [run]
    );

    return (
        <Panel>
            <PanelTitle title="stages" />
            <PanelContent>
                {run?.stages ? (
                    run.stages.map((s, stageIdx) => (
                        <div className="pb-4" key={s.identifier}>
                            <p className="pb-4 text-xl">
                                Stage {stageIdx + 1} - {s.identifier}
                            </p>
                            <ul className="w-full">
                                {s.steps?.map((st, stepIdx) => (
                                    <Step
                                        step={st}
                                        isRunning={
                                            stepIdx === runningStepIdx &&
                                            stageIdx === runningStageIdx
                                        }
                                        components={components}
                                        key={`${st.action}-${stepIdx}-${stageIdx}`}
                                    />
                                ))}
                            </ul>
                        </div>
                    ))
                ) : (
                    <>
                        <SkeletonHeader size="lg" className="mb-4" />
                        <div className="flex flex-col gap-4 border-l-4 pl-4">
                            {Array.from({
                                length: 3,
                            }).map((_, idx) => (
                                <div key={`skeleton-${idx}`}>
                                    <SkeletonHeader size="md" />
                                    <SkeletonText size="lg" />
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </PanelContent>
        </Panel>
    );
}

function findRunningIndex(run?: PipelineRun) {
    if (!run?.stages) {
        return { stageIdx: 0, stepIdx: 0 };
    }

    for (let stageIdx = 0; stageIdx < run.stages.length; stageIdx++) {
        const stage = run.stages[stageIdx];
        if (!stage?.steps) continue;

        for (let stepIdx = 0; stepIdx < stage.steps.length; stepIdx++) {
            const step = stage.steps[stepIdx];
            if (step && !step.success) {
                return { stageIdx, stepIdx };
            }
        }
    }

    return { stageIdx: null, stepIdx: null }; // Return null if no `false` is found
}
