import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { AccessControlOverlay } from "~/components/common/buttons";
import { LoadBalancerFormValues } from "../../form";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../../../context";
import {
    LoadBalancerConfig,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import { handleSubmitError } from "~/components/forms/util";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";

export function V1LbFormFooter() {
    const {
        handleSubmit,
        setError,
        reset,
        formState: { isSubmitting, isDirty },
    } = useFormContext<LoadBalancerFormValues>();

    const { environment } = useContext(LoadBalancerDialogContext);
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();

    const onSubmit = (data: LoadBalancerFormValues) => {
        if (!isDirty) {
            return;
        }

        const formattedData = {
            config: {
                ...data.config,
                details: data.config?.details,
            } as LoadBalancerConfig,
        };

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: formattedData,
            },
        })
            .unwrap()
            .then(
                () => reset((formValues) => ({ ...formValues })),
                handleSubmitError(setError)
            );
    };

    return (
        <div className="dark:border-cycle-black-accent dark:bg-cycle-black absolute bottom-0 left-0 right-0 z-10 mt-auto flex w-full items-center justify-between border-t bg-white px-2 py-4">
            <div>
                <RhfGlobalFormError />
            </div>
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={modifyAccessFn("environments-services-manage")}
            >
                <PushAndHoldButton
                    flavor="primary"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    icon={faEdit}
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                >
                    Update Load Balancer
                </PushAndHoldButton>
            </AccessControlOverlay>
        </div>
    );
}
