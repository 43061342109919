import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { createContext, useCallback, useState } from "react";

export type FilterValueType = {
    name: string;
    value: string;
};

export type FilterOption = {
    name: string;
    value: FilterValueType[];
    negate?: boolean;
    matchAll?: boolean;
};

/**
 * Details are lifted from the FilterGroup component props
 */
export type GroupDetailMap = Record<
    string,
    { icon: IconDefinition; isRequired: boolean; isExclusive: boolean }
>;

export type Filters = Record<string, FilterOption>;

export type FilterContext = {
    filters: Filters;
    getFilter(key: string): FilterOption | null;
    setFilter(key: string, value: FilterOption): void;
    initializeDefaults(key: string, value: FilterOption): void;
    appendFilterValue(key: string, value: FilterValueType): void;
    deleteFilterValue(key: string, value: string): void;
    replaceFilterValue(key: string, value: FilterValueType): void;
    deleteFilter(key: string): void;
    getUrlFilterParams(): Record<string, string>;
    accessExclusiveValue({
        key,
        isNumber,
    }: {
        key: string;
        isNumber?: boolean;
    }): string | number | undefined;
    groupDetails: GroupDetailMap;
    setGroupDetails: (map: GroupDetailMap) => void;
    isReady: boolean;
};

export const FilterContext = createContext<FilterContext>({
    filters: {},
    getFilter: () => null,
    setFilter: () => null,
    initializeDefaults: () => null,
    appendFilterValue: () => null,
    deleteFilterValue: () => null,
    replaceFilterValue: () => null,
    deleteFilter: () => null,
    getUrlFilterParams: () => ({}),
    accessExclusiveValue: () => undefined,
    groupDetails: {},
    setGroupDetails: () => null,
    isReady: true,
});

export const FilterGroupContext = createContext<{
    group: string | null;
    groupName: string;
    exclusive: boolean;
    required: boolean;
    onClose?: (open: boolean) => void;
}>({
    group: null,
    groupName: "",
    exclusive: false,
    required: false,
    onClose: undefined,
});

export function useFiltering(): FilterContext {
    const [filters, setFilters] = useState<Filters>({});
    const [status, setStatus] = useState<"ready" | "buffering">("ready");
    const [groupDetails, setGroupDetails] = useState<GroupDetailMap>({});

    let timer: NodeJS.Timeout | null = null;

    const handleSetFilters = useCallback((filters: Filters) => {
        if (timer) clearTimeout(timer);

        setStatus("buffering");

        timer = setTimeout(() => {
            setStatus("ready");
        }, 2000);

        setFilters(filters);
    }, []);

    const getFilter = useCallback(
        (key: string) => {
            return filters[key];
        },
        [filters]
    );

    /**
     * Same as setFilters but does not trigger the debounced filter buffer
     */
    const initializeDefaults = useCallback(
        (key: string, value: FilterOption) => {
            setFilters({ ...filters, [key]: value });
        },
        [filters, setFilters]
    );

    const setFilter = useCallback(
        (key: string, value: FilterOption) => {
            handleSetFilters({ ...filters, [key]: value });
        },
        [filters, setFilters]
    );

    const appendFilterValue = useCallback(
        (key: string, value: FilterValueType) => {
            if (!filters[key]) {
                return;
            }
            if (filters[key].value.some((v) => v.value === value.value)) {
                return;
            }
            handleSetFilters({
                ...filters,
                [key]: {
                    ...filters[key],
                    value: [...filters[key].value, value],
                },
            });
        },
        [filters, setFilters]
    );

    const replaceFilterValue = useCallback(
        (key: string, value: FilterValueType) => {
            if (!filters[key]) {
                return;
            }

            handleSetFilters({
                ...filters,
                [key]: {
                    ...filters[key],
                    value: [value],
                },
            });
        },
        [filters, setFilters]
    );

    const deleteFilterValue = useCallback(
        (key: string, value: string) => {
            if (!filters[key]) {
                return;
            }

            handleSetFilters({
                ...filters,
                [key]: {
                    ...filters[key],
                    value: filters[key].value.filter((v) => v.value !== value),
                },
            });
        },
        [filters, setFilters]
    );

    const deleteFilter = useCallback(
        (key: string) => {
            const { [key]: _, ...updatedFilters } = filters;
            handleSetFilters(updatedFilters);
        },
        [filters, setFilters]
    );

    const getUrlFilterParams = useCallback(
        () =>
            Object.entries(filters).reduce((acc, [key, option]) => {
                acc[key] = option.value.map((v) => v.value).join(",");
                return acc;
            }, {} as Record<string, string>),
        [filters]
    );

    const accessExclusiveValue = useCallback(
        ({ key, isNumber }: { key: string; isNumber?: boolean }) => {
            const value = filters?.[key]?.value?.[0]?.value;

            if (isNumber) {
                return value ? parseInt(value) : undefined;
            }
            return value;
        },
        [filters]
    );

    return {
        filters,
        getFilter,
        setFilter,
        initializeDefaults,
        appendFilterValue,
        deleteFilterValue,
        replaceFilterValue,
        deleteFilter,
        getUrlFilterParams,
        accessExclusiveValue,
        setGroupDetails,
        groupDetails,
        isReady: status === "ready",
    };
}
