import {
    Checkbox,
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { useSmoothScroll } from "@cycleplatform/core/hooks";
import { useSearchParams } from "react-router-dom";
import { ContainerDialogSearchParams } from "../../../searchparams";
import { useEffect } from "react";

export function DeployTelemetry() {
    const { register, watch } = useFormContext<ContainerConfigFormData>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { ref: telemetryRef, scrollIntoView } = useSmoothScroll();
    const shouldTelemFocus =
        searchParams.get(ContainerDialogSearchParams.focus) === "telemetry";

    useEffect(() => {
        if (shouldTelemFocus) {
            scrollIntoView();
            searchParams.delete(ContainerDialogSearchParams.focus);
            setSearchParams(searchParams);
        }
    }, [shouldTelemFocus]);
    return (
        <>
            <RhfFormField
                className="flex-grow"
                label="retention"
                name="config.deploy.telemetry.retention"
                required
            >
                <TextInput
                    defaultValue={"21600s"}
                    {...register("config.deploy.telemetry.retention", {
                        ...required(),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="interval"
                name="config.deploy.telemetry.interval"
                required
            >
                <TextInput
                    defaultValue={"15s"}
                    {...register("config.deploy.telemetry.interval", {
                        ...required(),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>

            <RhfFormField
                label="disable telemetry"
                name="config.deploy.telemetry.disable"
                help="Will disable telemetry for this
                                    container and its instances"
            >
                <Checkbox
                    defaultChecked={false}
                    {...register("config.deploy.telemetry.disable")}
                />
            </RhfFormField>
            {/* Anchor point for telemetry smooth scroll */}
            <div ref={telemetryRef} />
        </>
    );
}
