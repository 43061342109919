import { required, RhfFormField } from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { ImageSelect } from "~/components/images/ImageSelect";
import { CreateContainerSubmitType } from "../CreateContainerForm";

export const ChooseFromRecentImages = () => {
    const { control } = useFormContext<CreateContainerSubmitType>();
    return (
        <div>
            <RhfFormField
                className="!mb-0 "
                label="Image"
                name="image_id"
                required
            >
                <Controller
                    control={control}
                    name="image_id"
                    rules={{ ...required() }}
                    render={({ field: { ref: _ref, ...field } }) => (
                        <ImageSelect
                            {...field}
                            filter={{
                                state: "live",
                            }}
                            filterFields={["id", "name"]}
                        />
                    )}
                />
            </RhfFormField>
        </div>
    );
};
