import classNames from "classnames";

type SkeletonCircleProps = {
    className?: string;
    size?: "sm" | "md" | "lg";
};

export function SkeletonCircle({
    className,
    size = "sm",
}: SkeletonCircleProps) {
    return (
        <div
            className={classNames(
                className,
                {
                    "h-4 w-4": size === "sm",
                    "h-8 w-8": size === "md",
                    "h-10 w-10": size === "lg",
                },
                "from-cycle-gray-light via-cycle-gray/60 to-cycle-gray-light animate-shimmer self-center rounded-[50%] bg-gradient-to-r bg-[length:1000px_100%] opacity-50"
            )}
        />
    );
}
