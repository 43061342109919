import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    CopyInput,
    FormField,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { InfoPanel, PanelFooter } from "@cycleplatform/ui/components/panels";
import { faEdit, faPlus } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    Pipeline,
    TriggerKey,
    UpdatePipelineTriggerKeyApiArg,
    useCreatePipelineTriggerKeyMutation,
    useUpdatePipelineTriggerKeyMutation,
} from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";

type TriggerKeyEditFormProps = {
    pipelineId: string;
    pipeline?: Pipeline;
    triggerKey?: TriggerKey;
    fullTriggerKey?: string;
};

export function TriggerKeyEditForm({
    pipeline,
    pipelineId,
    triggerKey,
    fullTriggerKey,
}: TriggerKeyEditFormProps) {
    const isLoading = !triggerKey;

    const form = useForm<UpdatePipelineTriggerKeyApiArg["body"]>({
        ...rhfConfig,
    });

    useKeepFormCurrent(form, triggerKey, (tk) => ({
        name: tk.name,
        ips: tk.ips,
    }));

    const {
        register,
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updateTriggerKey] = useUpdatePipelineTriggerKeyMutation();
    const [createTriggerKey] = useCreatePipelineTriggerKeyMutation({
        fixedCacheKey: `trigger-key-${pipelineId}`,
    });

    const nav = useNavigate();

    const onSubmit = (data: UpdatePipelineTriggerKeyApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return updateTriggerKey({
            pipelineId: pipeline?.id || "",
            triggerKeyId: triggerKey?.id || "",
            body: data,
        })
            .unwrap()
            .then(
                () => form.reset((formValues) => ({ ...formValues })),
                handleSubmitError(form.setError)
            );
    };

    return (
        <RhfFormProvider {...form}>
            {fullTriggerKey && (
                <InfoPanel>
                    Store this key somewhere safe. For security, it will not be
                    shown again.
                </InfoPanel>
            )}
            <FormField label="endpoint">
                <CopyInput
                    value={`https://api.cycle.io/v1/pipelines/${pipelineId}/trigger`}
                />
            </FormField>
            <FormField
                label="trigger key"
                help="The full trigger key is only shown immediately after creation"
            >
                {fullTriggerKey ? (
                    <CopyInput value={fullTriggerKey} />
                ) : (
                    <TextInput
                        className={classNames(isLoading && skeletonStyles)}
                        disabled
                        value={triggerKey?.secret}
                    />
                )}
            </FormField>
            <RhfFormField label="name" name="name">
                <TextInput
                    {...register("name")}
                    className={classNames(isLoading && skeletonStyles)}
                />
            </RhfFormField>
            <RhfFormField label="IP Restrictions" name="ips">
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <MultiSelectInput
                            isCreatable
                            className={classNames(isLoading && skeletonStyles)}
                            placeholder="No IP Restrictions"
                            options={
                                triggerKey?.ips
                                    ? triggerKey.ips.map((ip) => ip)
                                    : []
                            }
                            {...field}
                            value={field.value || []}
                        />
                    )}
                    control={control}
                    name="ips"
                />
            </RhfFormField>

            <PanelFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <PushAndHoldButton
                    icon={faEdit}
                    type="button"
                    flavor="primary"
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={isLoading}
                    isLoading={isSubmitting}
                >
                    Update Key
                </PushAndHoldButton>
            </PanelFooter>
        </RhfFormProvider>
    );
}
