import {
    RhfFormField,
    TextInput,
    isDurationString,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { ShutdownSignal } from "~/modules/containers/schemas";

export function DeployShutdownPolicy() {
    const { register, control } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <RhfFormField
                label="graceful timeout"
                name="config.deploy.shutdown.graceful_timeout"
            >
                <TextInput
                    defaultValue={"5s"}
                    {...register("config.deploy.shutdown.graceful_timeout", {
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="shutdown signals"
                name="config.deploy.shutdown.signals"
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <MultiSelectInput
                            {...field}
                            options={ShutdownSignal.options}
                        />
                    )}
                    control={control}
                    name="config.deploy.shutdown.signals"
                />
            </RhfFormField>
        </>
    );
}
