import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { ToolTrayContent } from "./ToolTrayContent";
import { IdSelectType } from "../forms/IdSelect/hooks";

type TableToolTrayProps = {
    children: React.ReactNode;
};

export function TableToolTray({ children }: TableToolTrayProps) {
    const { watch, resetField } = useFormContext<IdSelectType>();

    const ids = watch("ids");

    useEffect(() => {
        const keyHandler = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                resetField("ids");
            }
        };
        window.addEventListener("keydown", keyHandler);
        return () => {
            window.removeEventListener("keydown", keyHandler);
        };
    }, []);

    return (
        <Transition
            show={ids.length > 0}
            className={"w-full"}
            enter="transition duration-300"
            enterTo="translate-y-0 opacity-100"
            enterFrom="-translate-y-full opacity-0"
            leave="transition duration-300"
            leaveTo="-translate-y-full opacity-0"
            leaveFrom="translate-y-0 opacity-100"
            unmount={false}
        >
            <div
                className={classNames(
                    "rounded-b-md bg-white p-4",
                    "border-[rgba(0,0,0,0.04)]",
                    "dark:bg-cycle-black border dark:border-[rgba(255,255,255,0.04)]"
                )}
            >
                <ToolTrayContent ids={ids}>{children}</ToolTrayContent>
            </div>
        </Transition>
    );
}
