import classNames from "classnames";
import { forwardRef } from "react";
import { Tooltip } from "../tooltip";

type CheckboxProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    error?: boolean;
    info?: string;
    tooltip?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    function Checkbox({ error, info, tooltip, ...props }, ref) {
        const inputComponent = (
            <div className="relative h-5 w-5">
                <input
                    {...props}
                    aria-invalid={error ? "true" : "false"}
                    ref={ref}
                    type="checkbox"
                    className={classNames(
                        props.className,
                        "text-cycle-blue border-cycle-blue  absolute inset-0 h-5 w-5 cursor-pointer rounded-sm  bg-gray-100 transition",
                        "dark:text-cycle-blue dark:checked:bg-cycle-blue dark:bg-cycle-gray-accent dark:border-cycle-gray dark:ring-offset-gray-800",
                        "focus:ring-cycle-blue focus:ring-2",
                        "dark:focus:ring-cycle-blue",
                        "disabled:border-cycle-gray disabled:cursor-not-allowed disabled:text-gray-400",
                        "disabled:dark:checked:bg-cycle-gray  disabled:dark:bg-black"
                    )}
                />
            </div>
        );

        return (
            <div
                className={classNames(
                    "flex  items-center ",
                    // CSS selector in Form Field will style the checkbox to align with other inputs if nested
                    "checkbox-container"
                )}
            >
                <Tooltip message={tooltip} disabled={!tooltip} as="span">
                    {inputComponent}
                </Tooltip>
                {info ? (
                    <span className="ml-1 ">
                        {" - "}
                        <span className="text-sm ">{info}</span>
                    </span>
                ) : null}
            </div>
        );
    }
);
