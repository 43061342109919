import {
    BillingMethod,
    CreateBillingMethodApiArg,
    UpdateBillingMethodApiArg,
    useCreateBillingMethodMutation,
    useGetHubQuery,
    useUpdateBillingMethodMutation,
} from "~/services/cycle";
import {
    Controller,
    useForm,
    useFormContext,
    useFormState,
    useWatch,
} from "react-hook-form";
import {
    Checkbox,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    maxLength,
    required,
    FormSectionHeader,
    FormSection,
    PillButtons,
    minLength,
} from "@cycleplatform/ui/components/forms";
import {
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    faCreditCard,
    faEdit,
    faLockAlt,
} from "@fortawesome/pro-solid-svg-icons";
import {
    RhfCountrySelect,
    useKeepFormCurrent,
} from "~/components/common/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    KeyboardEvent,
    KeyboardEventHandler,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
    getAllDialogSearchParams,
    shouldClose,
} from "~/components/dialogs/helpers";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
    faStripe,
} from "@fortawesome/free-brands-svg-icons";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import {
    capitalizeWord,
    formatCreditCard,
    getCreditCardType,
} from "@cycleplatform/core/util";
import { getDate } from "date-fns";
import { getOrdinalNum } from "@cycleplatform/core/util/number";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { PanelFooter } from "@cycleplatform/ui/components/panels";

type BillingMethodFormProps = {
    editMode?: boolean;
    method?: BillingMethod;
};

type BillingMethodFormType =
    | (CreateBillingMethodApiArg["body"] & {
          method_type: "cc" | "us_bank_acct";
      })
    | UpdateBillingMethodApiArg["body"];

function getDefaultValues(method?: BillingMethod) {
    return {
        name: method?.name || "",
        method_type: "cc",
        primary: method?.primary || false,
        address: method?.address || {
            country: "",
            zip: "",
        },
    };
}

export function BillingMethodForm({
    method,
    editMode = false,
}: BillingMethodFormProps) {
    const params = getAllDialogSearchParams<"billing-method-create">();
    const isEdit = editMode;

    const form = useForm<BillingMethodFormType>({
        defaultValues: getDefaultValues(method),
        ...rhfConfig,
    });

    const { data: hub } = useGetHubQuery({});

    const {
        handleSubmit,
        register,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const [updateBillingMethod] = useUpdateBillingMethodMutation();
    const [createBillingMethod] = useCreateBillingMethodMutation();

    useKeepFormCurrent(form, method, (m) => getDefaultValues(m));

    const nav = useNavigate();

    const onSubmit = (data: BillingMethodFormType) => {
        if (!isDirty) {
            return;
        }
        if (isEdit) {
            return updateBillingMethod({
                methodId: method?.id || "",
                body: {
                    name: data.name,
                    primary: data.primary,
                },
            })
                .unwrap()
                .then(
                    () => form.reset((formValues) => ({ ...formValues })),
                    handleSubmitError(form.setError)
                );
        }

        if ("address" in data) {
            return createBillingMethod({
                body: {
                    name: data.name || "",
                    primary: data.primary || false,
                    address: data.address,
                    credit_card:
                        data.credit_card as CreateBillingMethodApiArg["body"]["credit_card"],
                    us_bank_acct: data.us_bank_acct,
                },
            })
                .unwrap()
                .then(
                    (m) =>
                        nav(
                            shouldClose(params["dialog-nav"]) || {
                                pathname: `billing/methods/${m?.data?.id}`,
                                search: "",
                            }
                        ),
                    handleSubmitError(form.setError)
                );
        }
    };

    const methodType = useWatch({
        name: "method_type",
        control,
    });

    if (isEdit) {
        if (!method) {
            return <SkeletonButton />;
        }
        return (
            <RhfFormProvider {...form}>
                <GeneralSection
                    isEdit
                    canBePrimary={
                        !method.primary && method.state.current === "live"
                    }
                />
                <PanelFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>

                    <PushAndHoldButton
                        flavor="primary"
                        icon={faEdit}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                        tooltip="Hold to Update"
                    >
                        Update Payment Method
                    </PushAndHoldButton>
                </PanelFooter>
            </RhfFormProvider>
        );
    }

    const renewalDay = hub?.data?.billing?.term.end
        ? getDate(new Date(hub.data.billing.term.end))
        : null;

    return (
        <RhfFormProvider {...form}>
            <GeneralSection />

            <ToggleMethodType />
            {methodType === "cc" && <CreditCardFormSection />}
            {methodType === "us_bank_acct" && <UsBankFormSection />}

            <FormSectionHeader header="Address" />
            <FormSection>
                <div className="flex gap-2">
                    <RhfFormField
                        label="country"
                        name="address.country"
                        className="!w-3/4"
                        required
                    >
                        <RhfCountrySelect
                            disabled={methodType === "us_bank_acct"}
                            path={"address.country"}
                            isRequired
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="postal code"
                        name="address.zip"
                        className="!w-1/4"
                        required
                    >
                        <TextInput
                            {...register("address.zip", { ...required() })}
                        />
                    </RhfFormField>
                </div>
            </FormSection>

            {renewalDay ? (
                <div className="rounded-sm border p-2 text-xs">
                    By clicking [Create Payment Method], you authorize Petrichor
                    Holdings (Cycle.io) to{" "}
                    {methodType === "us_bank_acct"
                        ? "debit the bank account"
                        : "charge the credit card"}{" "}
                    specified above for any amount owed for charges arising from
                    your use of Petrichor Holdings' (Cycle.io) services and/or
                    purchase of products from Petrichor Holdings (Cycle.io),
                    pursuant to Petrichor Holdings' (Cycle.io) website and
                    terms, until this authorization is revoked. You may amend or
                    cancel this authorization at any time by providing notice to
                    Petrichor Holdings (Cycle.io) with 30 (thirty) days notice.
                    You will be billed for this hub on the{" "}
                    {`${getOrdinalNum(renewalDay)}`} of every month, or the last
                    day of the month on months without that day.
                </div>
            ) : null}

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    icon={faEdit}
                    type="button"
                    onClick={() => {
                        // needs to clear errors manually because the handleSubmit for this form will render
                        // errors to a slightly different route than the fields are registered to.  This will
                        // prevent automatic revalidation
                        form.clearErrors();
                        handleSubmit(onSubmit)();
                    }}
                    isLoading={isSubmitting}
                    disabled={!isDirty || !hub?.data}
                >
                    Create Payment Method
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function GeneralSection({
    isEdit,
    canBePrimary,
}: {
    isEdit?: boolean;
    canBePrimary?: boolean;
}) {
    const { register } = useFormContext<BillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <div className="flex w-full gap-4">
                    <RhfFormField
                        label="Payment Method Name"
                        name="name"
                        className="w-full"
                        required
                    >
                        <TextInput {...register("name", { ...required() })} />
                    </RhfFormField>

                    {isEdit ? (
                        <RhfFormField
                            label="make primary"
                            name="primary"
                            className="!w-40"
                        >
                            <Checkbox
                                {...register("primary")}
                                disabled={!canBePrimary}
                            />
                        </RhfFormField>
                    ) : null}
                </div>
            </FormSection>
        </>
    );
}

function ToggleMethodType() {
    const { control, unregister, setValue } =
        useFormContext<BillingMethodFormType>();

    return (
        <RhfFormField name="method_type">
            <>
                <Controller
                    name={"method_type"}
                    control={control}
                    defaultValue="cc"
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <PillButtons
                                {...field}
                                onChange={(v) => {
                                    field.onChange(v);
                                    if (v === "us_bank_acct") {
                                        unregister("credit_card");
                                        setValue("address.country", "US");
                                    } else {
                                        unregister("us_bank_acct");
                                    }
                                }}
                                options={[
                                    {
                                        value: "cc",
                                        label: "Credit Card",
                                    },
                                    {
                                        value: "us_bank_acct",
                                        label: "US Bank Account",
                                    },
                                ]}
                            />
                        );
                    }}
                />
                <div className="flex items-center gap-1 text-xs">
                    <FontAwesomeIcon icon={faLockAlt} />
                    <span>Powered by</span>
                    <a
                        tabIndex={-1}
                        href="https://stripe.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-2xl text-black dark:text-white"
                    >
                        <FontAwesomeIcon icon={faStripe} className="pt-2" />
                    </a>
                </div>
            </>
        </RhfFormField>
    );
}

function CreditCardFormSection() {
    const { register } = useFormContext<BillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="Credit Card" />
            <FormSection>
                <RhfFormField
                    label="Card Holder Name"
                    name="credit_card.name"
                    className="flex-1"
                    required
                >
                    <TextInput
                        {...register("credit_card.name", {
                            ...required(),
                        })}
                    />
                </RhfFormField>

                <div className="flex gap-2">
                    <RhfFormField
                        label="card number"
                        name="credit_card.number"
                        required
                    >
                        <CreditCardTextInput />
                    </RhfFormField>

                    <RhfFormField
                        label="expiration date"
                        name="credit_card._tmpexpires"
                        className="!w-[15rem]"
                        required
                    >
                        <ExpirationInput />
                    </RhfFormField>
                    {/* Security Code */}
                    <RhfFormField
                        label="security code"
                        name="credit_card.cvv"
                        className="!w-[10rem]"
                        required
                    >
                        <TextInput
                            {...register("credit_card.cvv", {
                                ...required(),
                                ...maxLength(5),
                            })}
                        />
                    </RhfFormField>
                </div>
            </FormSection>
        </>
    );
}

const ccIcons = {
    uatp: faCreditCard,
    amex: faCcAmex,
    diners: faCcDinersClub,
    discover: faCcDiscover,
    dankort: faCreditCard,
    instapayment: faCreditCard,
    mastercard: faCcMastercard,
    jcb15: faCcJcb,
    jcb: faCcJcb,
    maestro: faCreditCard,
    visa: faCcVisa,
    mir: faCreditCard,
    unionpay: faCreditCard,
    general: faCreditCard,
};

function CreditCardTextInput() {
    const { control } = useFormContext<BillingMethodFormType>();
    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => {
                const ccType = getCreditCardType(field.value || "", " ");
                return (
                    <TextInput
                        onChange={(e) =>
                            field.onChange(
                                formatCreditCard(e.target.value).trimEnd()
                            )
                        }
                        value={field.value}
                        onBlur={field.onBlur}
                        name={field.name}
                        suffix={
                            <FontAwesomeIcon
                                icon={ccIcons[ccType] || faCreditCard}
                                className="dark:text-cycle-gray-light/70 text-cycle-gray text-3xl"
                            />
                        }
                    />
                );
            }}
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "This field is required",
                },
            }}
            name="credit_card.number"
        />
    );
}

function ExpirationInput() {
    const { control, setValue } = useFormContext<BillingMethodFormType>();
    const [rawValue, setRawValue] = useState(""); // Store the raw input value

    const handleExpirationChange = (value: string) => {
        // Remove all non-numeric characters except "/"
        let cleanValue = value.replace(/[^\d\/]/g, "");

        // If user enters "1/" or "2/", it automatically becomes "01/" or "02/"
        if (cleanValue.length === 2 && value.includes("/")) {
            cleanValue = `0${cleanValue.slice(0, 1)}/`;
        }

        // If the user types a single digit, pad it with a 0 if necessary
        if (cleanValue.length === 1 && cleanValue > "1") {
            cleanValue = `0${cleanValue}`;
        }

        // Automatically add "/" after the month part if not present
        if (cleanValue.length > 2 && !cleanValue.includes("/")) {
            cleanValue = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
        }

        // Cap months to "12" if the entered value exceeds valid month range
        const month = cleanValue.slice(0, 2);
        if (parseInt(month, 10) > 12) {
            cleanValue = `12${cleanValue.slice(2)}`;
        }

        // Sync month and year in the form state when both are present (MM/YY)
        if (cleanValue.length === 7) {
            const [formattedMonth, formattedYear] = cleanValue.split("/");
            setValue("credit_card.expiration.month", parseInt(formattedMonth)); // Store month as MM
            setValue("credit_card.expiration.year", parseInt(formattedYear)); // Store year as YY
        }

        // Update the raw value for display and return the formatted value
        setRawValue(cleanValue);
    };

    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => (
                <TextInput
                    type="text"
                    onChange={(e) => {
                        handleExpirationChange(e.target.value);
                        field.onChange(rawValue); // Sync the raw value to the form state
                    }}
                    onBlur={field.onBlur}
                    name={field.name}
                    value={rawValue}
                    placeholder="MM/YYYY"
                    maxLength={7}
                />
            )}
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "This field is required",
                },
            }}
            name={"credit_card._tmpexpires" as "credit_card.expiration.month"} // mock a field that doesn't exist in our definition to store temp val
        />
    );
}

function UsBankFormSection() {
    const { register, control } = useFormContext<BillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="Bank Account" />
            <FormSection>
                <div className="flex gap-2">
                    <RhfFormField
                        label="Account Holder"
                        name="us_bank_acct.account_holder_name"
                        required
                    >
                        <TextInput
                            {...register("us_bank_acct.account_holder_name", {
                                ...required(),
                            })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Account Type"
                        name="us_bank_acct.account_holder_type"
                        className="!w-[10rem]"
                        required
                    >
                        <Controller
                            defaultValue="company"
                            render={({ field: { ref: _ref, ...field } }) => (
                                <BasicSelect
                                    {...field}
                                    options={["company", "individual"].map(
                                        (c) => ({
                                            label: capitalizeWord(c),
                                            value: c,
                                        })
                                    )}
                                />
                            )}
                            rules={{ ...required() }}
                            control={control}
                            name="us_bank_acct.account_holder_type"
                        />
                    </RhfFormField>
                </div>

                <RhfFormField
                    label="Routing Number"
                    name="us_bank_acct.routing_number"
                    required
                >
                    <TextInput
                        {...register("us_bank_acct.routing_number", {
                            ...required(),
                            ...maxLength(9),
                            ...minLength(9),
                        })}
                    />
                </RhfFormField>

                <RhfFormField
                    label="Account Number"
                    name="us_bank_acct.account_number"
                    required
                >
                    <TextInput
                        {...register("us_bank_acct.account_number", {
                            ...required(),
                            ...maxLength(17),
                            ...minLength(4),
                        })}
                    />
                </RhfFormField>
            </FormSection>
        </>
    );
}
