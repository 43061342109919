import { components } from "../../api/__generated/index";

export function getAutoscaleGroupUniqueProviders(
    ag: Pick<components["schemas"]["AutoScaleGroup"], "infrastructure">
): string[] {
    return [...new Set(ag.infrastructure.models.map((m) => m.provider))];
}

if (import.meta.vitest) {
    const { it, expect, describe } = import.meta.vitest;

    describe("getAutoscaleGroupUniqueProviders", () => {
        it("gets providers from an autoscale group", () => {
            const ag = {
                infrastructure: {
                    models: [
                        {
                            provider: "aws",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                        {
                            provider: "gcp",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                        {
                            provider: "vultr",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                    ],
                },
            };
            const p = getAutoscaleGroupUniqueProviders(ag);
            expect(p).toEqual(["aws", "gcp", "vultr"]);
        });
        it("removes duplicates from providers list", () => {
            const ag = {
                infrastructure: {
                    models: [
                        {
                            provider: "aws",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                        {
                            provider: "aws",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                        {
                            provider: "vultr",
                            model_id: "1",
                            priority: 1,
                            locations: [],
                        },
                    ],
                },
            };
            const p = getAutoscaleGroupUniqueProviders(ag);
            expect(p).toEqual(["aws", "vultr"]);
        });
    });
}
