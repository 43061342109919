import {
    CopyInput,
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useState } from "react";
import { encrypt, EncryptResult } from "./util";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useFormContext, useWatch } from "react-hook-form";
import { ScopedVariableFormSubmitType } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinaryLock } from "@fortawesome/pro-solid-svg-icons";

type EncryptFormType = {
    secret_key: string;
    hint: string;
};
export function ScopedVariableEncryptForm() {
    const { control, setValue } =
        useFormContext<ScopedVariableFormSubmitType>();

    const source = useWatch({ name: "source", control });
    const value = useWatch({ name: "source.details.value", control });

    const [encryptForm, setEncryptForm] = useState<EncryptFormType>({
        secret_key: "",
        hint: source.type === "raw" ? source.details.secret?.hint || "" : "",
    });
    const [keyDetails, setKeyDetails] = useState<EncryptResult>();

    const handleEncrypt = () => {
        if (encryptForm.secret_key) {
            const encrypted = encrypt({
                secret: value || "",
                key: encryptForm.secret_key,
            });

            setKeyDetails(encrypted);

            setValue("source.details.value", encrypted.secret, {
                shouldDirty: true,
            });
            setValue("source.details.secret.iv", encrypted.ivHex);

            setValue("source.details", {
                ...source.details,
                value: encrypted.secret,
                secret: {
                    hint: encryptForm.hint,
                    iv: encrypted.ivHex,
                },
            });
        }
    };

    return (
        <div>
            <div className="flex items-center gap-4 pb-4 text-lg">
                <FontAwesomeIcon icon={faBinaryLock} /> Encrypt Value
            </div>
            <div className="flex items-center gap-4 ">
                <div className="w-full">
                    <RhfFormField label="key" name="key" required>
                        <TextInput
                            autoComplete="off"
                            spellCheck={false}
                            type="password"
                            className="w-full "
                            value={encryptForm.secret_key}
                            onChange={(e) =>
                                setEncryptForm({
                                    ...encryptForm,
                                    secret_key: e.target.value,
                                })
                            }
                        />
                    </RhfFormField>

                    <FormField label="hint">
                        <TextInput
                            autoComplete="off"
                            spellCheck={false}
                            className="w-full "
                            value={encryptForm.hint}
                            onChange={(e) =>
                                setEncryptForm({
                                    ...encryptForm,
                                    hint: e.target.value,
                                })
                            }
                        />
                    </FormField>
                </div>
            </div>
            {keyDetails ? (
                <div>
                    <h4 className="text-center text-sm font-bold uppercase">
                        Generated Key Details
                    </h4>

                    <FormField label="IV">
                        <CopyInput value={keyDetails.ivHex} />
                    </FormField>
                </div>
            ) : null}
            <div className="w-30 mt-4 flex justify-end">
                <Tooltip
                    message="Source and key is required for encryption"
                    disabled={!!value && !!encryptForm.secret_key}
                >
                    <Button
                        type="button"
                        flavor="primary"
                        className="mb-1"
                        onClick={() => handleEncrypt()}
                        disabled={!value || !encryptForm.secret_key}
                    >
                        Encrypt
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}
