import Editor from "react-simple-code-editor";
import { useEffect, useState } from "react";
import "prismjs/themes/prism-twilight.css";
import prism, { Grammar } from "prismjs";
import classNames from "classnames";
import { useDebounce } from "../../hooks";

type CodeInputProps = {
    id?: string;
    value: Record<string, any>;
    onChange: (value: Record<string, unknown>) => void;
    name: string;
    className?: string;
    fileType?: "yaml" | "json";
};

export function CodeInput({
    id,
    value,
    onChange,
    name,
    className,
    fileType = "json",
}: CodeInputProps) {
    const { highlight, languages } = prism;
    const [code, setCode] = useState<string>();
    const [error, setError] = useState<string>();

    const debouncedCode = useDebounce(code, 500);
    const debouncedFileType = useDebounce(fileType, 500);

    const yaml = async () => import("js-yaml");

    useEffect(() => {
        if (fileType === "json") {
            setCode(JSON.stringify(value, null, 2));
        } else {
            yaml().then((y) => setCode(y.dump(value)));
        }
    }, [fileType]);

    useEffect(() => {
        if (code) {
            if (fileType === "json") {
                try {
                    onChange(JSON.parse(code));
                    setError(undefined);
                } catch (e) {
                    setError("Invalid JSON Config");
                }
            } else {
                yaml()
                    .then((y) => {
                        onChange(y.load(code) as Record<string, unknown>);
                        setError(undefined);
                    })
                    .catch(() => setError("Invalid YAML Config"));
            }
        }
    }, [debouncedCode, debouncedFileType]);

    return (
        <>
            <div
                className={classNames(
                    "overflow-auto rounded-md bg-black",
                    error && "ring-error ring",
                    className
                )}
            >
                <Editor
                    name={name}
                    id={id}
                    onBlur={() => {
                        if (code) {
                            if (fileType === "json") {
                                try {
                                    onChange(JSON.parse(code));
                                    setError(undefined);
                                    setCode(JSON.stringify(value, null, 2));
                                } catch (e) {
                                    setError("Invalid JSON Config");
                                }
                            } else {
                                yaml()
                                    .then((y) => {
                                        onChange(
                                            y.load(code) as Record<
                                                string,
                                                unknown
                                            >
                                        );
                                        setError(undefined);
                                        setCode(y.dump(value));
                                    })
                                    .catch(() =>
                                        setError("Invalid YAML Config")
                                    );
                            }
                        }
                    }}
                    value={code || ""}
                    onValueChange={(code: string) => setCode(code)}
                    highlight={(code: string) =>
                        highlight(
                            code || "",
                            languages["js"] as Grammar,
                            "javascript"
                        )
                    }
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        color: "white",
                    }}
                    className="language-javascript"
                />
            </div>
            {error ? (
                <p className="text-error pt-1 text-end text-sm font-semibold">
                    {error}
                </p>
            ) : null}
        </>
    );
}
