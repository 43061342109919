import { useAppSelector } from "~/hooks";
import { useGetBillingTiersQuery } from "~/services/cycle";
import { selectActiveHub } from "../hubs/slice";

export function useGetCurrentTier() {
    const { data: tiers, error } = useGetBillingTiersQuery();
    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id || undefined;

    return {
        tier: tiers?.data?.find((t) => t.id === currentTierId),
        error: error,
    };
}
