import { PanelContent } from "@cycleplatform/ui/components/panels";
import { InstanceChartTelemetryContext } from "./context";
import { CycleErrorBoundary } from "~/components/common/errors";
import { CpuSection } from "./sections/CpuSection";
import { RamSection } from "./sections/RamSection";
import { TrafficSection } from "./sections/TrafficSection";
import { InstanceGranularity } from "./types";
import { InstanceChartComponents } from "./helpers";

export function InstanceTelemtrySection({
    instanceColorMap,
    instanceLabelMap,
    filtering,
    registerIds,
    components,
    criteria,
    tableInclude,
}: {
    instanceLabelMap: Record<string, string>;
    instanceColorMap: Record<string, string>;
    filtering: {
        granularity: InstanceGranularity;
        instanceLimit: number;
        range: number;
        isBuffering: boolean;
    };

    registerIds: (ids: string[]) => void;
    components: InstanceChartComponents;
    criteria?: Record<string, string>;
    tableInclude: ("servers" | "environments")[];
}) {
    return (
        <InstanceChartTelemetryContext.Provider
            key={`${filtering.range} - ${filtering.instanceLimit}`}
            value={{
                instanceLabelMap,
                instanceColorMap,

                filtering,

                registerIds,
                components,
                criteria,
                tableInclude,
            }}
        >
            <PanelContent>
                <div className="grid grid-cols-2 gap-8">
                    <CycleErrorBoundary>
                        <CpuSection />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary>
                        <RamSection />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary>
                        <TrafficSection packets="tx" scope="public" />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary>
                        <TrafficSection packets="rx" scope="public" />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary>
                        <TrafficSection packets="tx" scope="private" />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary>
                        <TrafficSection packets="rx" scope="private" />
                    </CycleErrorBoundary>
                </div>
            </PanelContent>
        </InstanceChartTelemetryContext.Provider>
    );
}
