import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import {
    removeExpandedIdentifier,
    routerExpandedParam,
} from "../../../../expandedParamHelpers";
import { AccessControlOverlay } from "~/components/common/buttons";
import { Button } from "@cycleplatform/ui/components/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

export function RemoveButton({
    remove,
    idx,
    expandedIdentifier,
    expandedArr,
}: {
    remove: (idx: number) => void;
    idx: number;
    expandedIdentifier: string;
    expandedArr: string[];
}) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const [searchParams, setSearchParams] = useSearchParams();

    // Remove Expanded Identifier on Delete
    const onClick = () => {
        const sp = new URLSearchParams(searchParams);
        const newArray = removeExpandedIdentifier(
            expandedArr,
            expandedIdentifier
        );
        if (newArray.length) {
            sp.set(routerExpandedParam, newArray);
        } else {
            sp.delete(routerExpandedParam);
        }

        setSearchParams(sp);
    };
    return (
        <AccessControlOverlay
            aclResource={environment}
            verifyFn={modifyAccessFn("environments-services-manage")}
        >
            <Button
                flavor="discard"
                icon={faTrash}
                onClick={() => {
                    remove(idx);
                    onClick();
                }}
            />
        </AccessControlOverlay>
    );
}
