import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageContent } from "@cycleplatform/ui/components/page";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { Controller, useForm, useWatch } from "react-hook-form";
import { NavIcons } from "~/components/layout/NavIcons";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    FormToggle,
    RhfCodeInput,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import {
    CreateLoadBalancerServiceJobApiArg,
    GetLoadBalancerServiceApiResponse,
    WafConfig,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import {
    ButtonLink,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { CycleErrorBoundary } from "~/components/common/errors";
import { useContext, useEffect } from "react";
import { LoadBalancerDialogContext } from "../../context";
import { useKeepFormCurrent } from "~/components/common/forms";
import { generateDialogLink } from "~/components/dialogs/helpers";

export function FirewallConfig() {
    const hasCap = useCapability("environments-services-manage");
    const { service } = useContext(LoadBalancerDialogContext);

    if (hasCap instanceof Error && service) {
        return (
            <DialogPageBody className="w-full ">
                <EmptyResource
                    className="flex h-60 items-center justify-center border-none"
                    icon={NavIcons["firewall"]}
                    title={`Load Balancer Firewall Restricted`}
                >
                    <p className="text-center">
                        Load balancer firewall requires the
                        "environment-services-manage" capability.
                    </p>
                </EmptyResource>
            </DialogPageBody>
        );
    }

    return (
        <DialogPageBody className="!p-0">
            <PageContent>
                <CycleErrorBoundary>
                    <ReconfigureFirewall />
                </CycleErrorBoundary>
            </PageContent>
        </DialogPageBody>
    );
}

const defaultWafValue: WafConfig["rules"] = [
    {
        description: "allow all traffic",
        skip: false,
        type: "allow",
        match: "any",
        conditions: [
            {
                type: "ip-match",
                operator: "==",
                value: "0.0.0.0/0",
            },
            {
                type: "ip-match",
                operator: "==",
                value: "::/0",
            },
        ],
    },
];

type ConfigureFirewallForm = {
    content: WafConfig["rules"] | undefined | null;
    enabled: boolean;
};

function getDefaultFirewallValues(
    lbInfo: GetLoadBalancerServiceApiResponse["data"] | undefined
) {
    if (!lbInfo?.service?.config || lbInfo.service.config?.type !== "v1") {
        return {
            content: undefined,
            enabled: false,
        };
    }

    return {
        content: lbInfo?.service?.config?.details?.waf?.rules,
        enabled: !!lbInfo?.service?.config?.details?.waf?.rules,
    };
}

function ReconfigureFirewall() {
    const { environment, service } = useContext(LoadBalancerDialogContext);

    const form = useForm<ConfigureFirewallForm>({
        defaultValues: getDefaultFirewallValues(service),
        ...rhfConfig,
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isDirty },
    } = form;

    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();
    const isDefaultConfig = !service?.service?.config?.details;
    const isEnabled = useWatch({ name: "enabled", control });
    const content = useWatch({ name: "content", control });

    const onSubmit = (data: ConfigureFirewallForm) => {
        if (isDefaultConfig || service?.service?.config?.type !== "v1") {
            return;
        }
        // take the v1 config and inject data.content from the form into waf.rules
        const formattedData: CreateLoadBalancerServiceJobApiArg["body"]["contents"] =
            {
                config: {
                    ...service.service.config,
                    details: {
                        ...service.service.config.details,
                        waf:
                            data.content && data.enabled
                                ? {
                                      rules: data.content,
                                  }
                                : null,
                    },
                },
            };

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: formattedData,
            },
        })
            .unwrap()
            .catch(handleSubmitError(form.setError));
    };

    useKeepFormCurrent(form, service, (lb) => getDefaultFirewallValues(lb));

    useEffect(() => {
        if (!content) {
            setValue("content", defaultWafValue);
        }
    }, [isEnabled, content]);

    if (isDefaultConfig && !!service) {
        return (
            <Panel>
                <PanelTitle
                    title="Firewall Configuration"
                    className="flex justify-between"
                />

                <EmptyResource
                    className="mb-4 flex h-60 items-center justify-center border-none"
                    icon={NavIcons["firewall"]}
                    title={`Load Balancer is Using Default Config.`}
                >
                    <p className="text-center">
                        In order to enable Web Application Firewall, enable
                        custom configuration from the controllers section.
                    </p>

                    <ButtonLink
                        to={generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "controllers",
                        })}
                        icon={NavIcons["lbController"]}
                        flavor="primary"
                    >
                        Update Config
                    </ButtonLink>
                </EmptyResource>
            </Panel>
        );
    }

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <Panel>
                <PanelTitle
                    title="Firewall Configuration"
                    className="flex justify-between"
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <FormToggle {...field} />
                        )}
                        control={control}
                        name={`enabled`}
                    />
                </PanelTitle>

                <PanelContent>
                    {isEnabled ? (
                        <RhfFormField name="content">
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <RhfCodeInput
                                        {...field}
                                        className="!h-[40rem]"
                                        fileType={"json"}
                                    />
                                )}
                                control={control}
                                name={"content"}
                            />
                        </RhfFormField>
                    ) : (
                        <EmptyResource
                            className="mb-4 flex h-60 items-center justify-center border-none"
                            icon={NavIcons["firewall"]}
                            title={`Load Balancer Firewall is Disabled`}
                        >
                            <p className="text-center">
                                Enable the firewall above to specify firewall
                                rules.
                            </p>
                        </EmptyResource>
                    )}

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>

                        <PushAndHoldButton
                            type="button"
                            onClick={form.handleSubmit(onSubmit)}
                            icon={faEdit}
                            flavor="primary"
                            isLoading={isSubmitting}
                            disabled={!isDirty}
                        >
                            Update Firewall
                        </PushAndHoldButton>
                    </PanelFooter>
                </PanelContent>
            </Panel>
        </RhfFormProvider>
    );
}
