import { StackSpec } from "~/services/cycle";
import { isZeroTime } from "@cycleplatform/core/util/time";
import { differenceInMilliseconds } from "date-fns";
import { CreatorInclude, Job, JobTask } from "~/services/cycle";

/**
 * Determines if a job is 'active' - running or preparing to run.
 *
 * ## Active States
 * - running
 * - scheduled
 * - queued
 */
export function isJobActive(job: Job): boolean {
    const activeStates: Job["state"]["current"][] = [
        "running",
        "scheduled",
        "queued",
    ];
    return activeStates.includes(job.state.current);
}

export function getActiveJobsCount(jobs: Job[]): number {
    const activeStates: Job["state"]["current"][] = [
        "running",
        "scheduled",
        "queued",
    ];
    return jobs.filter((j) => activeStates.includes(j.state.current)).length;
}

// TODO: add within x time parameter
export function getErrorJobsCount(jobs: Job[], since?: Date): number {
    const errorStates: Job["state"]["current"][] = ["error"];
    return jobs.filter((j) => errorStates.includes(j.state.current)).length;
}

export type JobCounts = {
    active: number;
    error: number;
    total: number;
};

export function getJobCounts(jobs: Job[]): JobCounts {
    return jobs.reduce(
        (counts, job) => {
            if (isJobActive(job)) {
                counts.active += 1;
            }

            if (job.state.current === "error") {
                counts.error += 1;
            }

            counts.total += 1;
            return counts;
        },
        {
            active: 0,
            error: 0,
            total: 0,
        }
    );
}

export function getSpecAsJSON(source?: StackSpec | null) {
    if (!source) {
        return "build spec not available";
    }
    return JSON.stringify(source, null, 2);
}

export async function getSpecAsYAML(source?: StackSpec | null) {
    if (!source) {
        return "build spec not available";
    }
    const yaml = await import("js-yaml");

    return yaml.dump(source);
}

export function getActiveStep(t: JobTask | null) {
    if (!t?.steps) {
        return null;
    }

    const a = t.steps.find(
        (s) => !isZeroTime(s.started) && isZeroTime(s.completed)
    );
    if (a) {
        return a;
    }

    if (!t.steps.length) {
        return null;
    }

    return t.steps[t.steps.length - 1];
}

export function getJobPercentComplete(j: Job | null) {
    if (!j) {
        return 0;
    } else if (!j.tasks) {
        return 0;
    }

    if (j.state.current === "completed") {
        return 100;
    }

    const taskPercentComplete = j.tasks.reduce(
        (acc, cur) => {
            return {
                i: acc.i + 1,
                sumAverages: acc.sumAverages + getTaskPercentComplete(cur),
            };
        },
        { i: 0, sumAverages: 0 } as {
            i: number;
            sumAverages: number;
        }
    );

    return taskPercentComplete.sumAverages / taskPercentComplete.i;
}

export function getTaskPercentComplete(t: JobTask | null) {
    if (!t) {
        return 0;
    } else if (!t.steps) {
        return 0;
    }

    const steps = t.steps.reduce(
        (acc, cur) => {
            return {
                completedStepLength: !isZeroTime(cur.completed)
                    ? acc.completedStepLength + 1
                    : acc.completedStepLength,
                stepLength: acc.stepLength + 1,
            };
        },
        { completedStepLength: 0, stepLength: 0 }
    );

    if (steps.completedStepLength === 0 && steps.stepLength === 0) {
        return t.state.current === "completed" ? 100 : 0;
    }

    return (steps.completedStepLength / steps.stepLength) * 100;
}

export function getActiveTask(j: Job | null) {
    if (!j || !j.tasks.length) {
        return null;
    }

    const active = j.tasks.find((t) => t.state.current === "pending");
    return active || j.tasks[j.tasks.length - 1];
}

export function getJobError(j: Job | null) {
    if (j?.state.current !== "error" || !j.tasks.length) {
        return null;
    }

    const err = j.tasks[j.tasks.length - 1].error;
    if (err) {
        return err && err.message;
    }

    if (j.state.error && j.state.error.message) {
        return j.state.error.message;
    }

    return "Unknown Error";
}

export function formatJobDurationToSeconds(job: Job): string | null {
    if (job.events.started && job.events.completed) {
        const diff = differenceInMilliseconds(
            new Date(job.events.completed),
            new Date(job.events.started)
        );
        if (diff < 0) {
            return null;
        }

        const diffInSeconds = diff / 1000;

        return `${diffInSeconds.toString()}s`;
    }
    return "In Progress";
}

export function formatTaskDurationToSeconds(
    task: JobTask | null
): string | null {
    if (!task) {
        return null;
    } else if (task.error) {
        return null;
    } else if (task.events.started && task.events.completed) {
        const diff = differenceInMilliseconds(
            new Date(task.events.completed),
            new Date(task.events.started)
        );

        if (diff < 0) {
            return null;
        }
        const diffInSeconds = diff / 1000;

        return `${diffInSeconds.toString()}s`;
    }
    return "In Progress";
}

export function formatJobCreatorUsername(job: Job, creators?: CreatorInclude) {
    if (!creators) {
        return "Unknown";
    }

    switch (job.creator.type) {
        case "account": {
            if (!creators.accounts) {
                return "Unknown";
            }
            const acc = creators.accounts[job.creator.id];
            return `${acc.name.first} ${acc.name.last}`;
        }
        case "environment": {
            if (!creators.environments) {
                return "Unknown";
            }
            const environment = creators.environments[job.creator.id].name;
            return `Environment (${environment})`;
        }
        case "employee": {
            if (!creators.employees) {
                return "Unknown";
            }
            const employee = creators.employees[job.creator.id];
            return `${employee.name.first} ${employee.name.last} (Cycle.io Team)`;
        }
        case "api-key": {
            if (!creators.api_keys) {
                return "Unknown";
            }
            return creators.api_keys[job.creator.id].name;
        }
        case "platform-pipeline":
            return "Platform Pipeline";
        case "platform":
        default:
            return "Platform";
    }
}
