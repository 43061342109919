import { createContext } from "react";

type EventsContext = {
    focusDate: string | undefined;
    setFocusDate: ((date: string) => void) | undefined;
};

export const EventsContext = createContext<EventsContext>({
    focusDate: undefined,
    setFocusDate: undefined,
});
