import { Hub, Image } from "~/services/cycle";

/**
 * Returns true if the hub has a backup provider configured.
 */

export function matchAutofillFromImage(image: Image) {
    if (image.name.includes("mysql")) {
        return {
            type: "mysql",
            commands: autofillOptions["mysql"],
        };
    }

    if (image.name.includes("postgres")) {
        return {
            type: "postgres",
            commands: autofillOptions["postgres"],
        };
    }

    if (image.name.includes("mongo")) {
        return {
            type: "mongo",
            commands: autofillOptions["mongo"],
        };
    }

    return null;
}

export const autofillOptions = {
    mysql: {
        backupCommand: `"mysqldump --all-databases -u root -p $MYSQL_ROOT_PASSWORD"`,
        restoreCommand: `"mysql -u root -p $MYSQL_ROOT_PASSWORD < /dev/stdin"`,
    },
    postgres: {
        backupCommand: `"PGPASSWORD=$POSTGRES_PASSWORD pg_dump  -U $POSTGRES_USER --clean -Ft $POSTGRES_DB"`,
        restoreCommand: `"PASSWORD=$POSTGRES_PASSWORD pg_restore  -U $POSTGRES_USER -Ft  -d $POSTGRES_DB"`,
    },
    mongo: {
        backupCommand: `"mongodump --archive -u $USERNAME -p $PASSWORD"`,
        restoreCommand: `"mongorestore --archive -u $USERNAME -p $PASSWORD"`,
    },
};
