import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faStar } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { FormattedMoney } from "@cycleplatform/ui/components/money";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";
import { PlanFeatures } from "@cycleplatform/ui/components/resources";
import { Badge } from "@cycleplatform/ui/components/badges";
import { components } from "@cycleplatform/core/modules/api/__generated";

type PriceOverride = {
    price: number;
    reason?: "discount" | "price-reduced";
};
type PlanTierProps = {
    tier?: components["schemas"]["TierPlan"];
    isCurrentTier?: boolean;
    priceOverride?: PriceOverride;
};

// Starter tier is free for 2 months with a promo we automatically apply
export const STARTER_TIER_PROMO_CODE = "starter-trial";

// In order to make the Plan Tier Tile consistent across apps, apply this classname to the tile wrapping the tier
// This will ensure that flexbox sizes everything properly
export const PLAN_TILE_CLASSNAME = "max-w-[28rem] min-w-[9.5rem]";

export function PlanTier({
    tier,
    isCurrentTier,
    priceOverride: po,
}: PlanTierProps) {
    if (!tier) {
        return null;
    }

    const priceOverride: PriceOverride | undefined =
        tier.code === "starter" ? { price: 0, reason: "discount" } : po;

    const isPayingLess = priceOverride
        ? priceOverride.price < tier.price.mills
        : false;

    return (
        <div
            className={classNames(
                "@container",
                "w-full",
                // "flex flex-col",
                "flex h-full flex-col"
            )}
        >
            <h2
                className={classNames(
                    "@[22rem]:text-2xl @[16rem]:text-xl text-large",
                    "flex items-center justify-between pb-2 font-semibold"
                )}
            >
                {tier.name}
                {isCurrentTier ? (
                    <div className="flex h-full items-center">
                        <Badge>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-success mr-2"
                                size="xs"
                            />
                            Current Tier
                        </Badge>
                    </div>
                ) : null}
            </h2>
            <div
                className={classNames(
                    "@[16rem]:text-base mb-2 text-sm",
                    "!min-h-[45px]"
                )}
            >
                {tier.description}
            </div>

            <div className="relative py-4">
                <div className="flex items-center">
                    {isPayingLess && priceOverride ? (
                        <div className="flex">
                            <HelpInfoCircle
                                className="pr-2"
                                size="lg"
                                message={getInfoMessage(priceOverride?.reason)}
                            />
                            <FormattedMoney
                                value={priceOverride?.price}
                                mills
                                className={classNames(
                                    "pr-4 font-bold",
                                    "@[22rem]:text-2xl text-base"
                                )}
                            />
                        </div>
                    ) : null}
                    <FormattedMoney
                        value={tier.price.mills}
                        mills
                        className={classNames(
                            "@[22rem]:text-2xl  @[16rem]:text-lg font-bold",
                            "pr-4 text-base",
                            isPayingLess && "line-through"
                        )}
                    />
                    /month
                </div>

                {tier.code === "starter" ? (
                    <div className="absolute left-0 -top-1 text-start text-sm font-semibold italic">
                        <FontAwesomeIcon
                            icon={faStar}
                            className="text-cycle-blue"
                        />{" "}
                        Free for 2 Months
                    </div>
                ) : null}
            </div>

            <PlanFeatures t={tier} />
        </div>
    );
}

function getInfoMessage(reason?: "discount" | "price-reduced") {
    switch (reason) {
        case "price-reduced":
            return "Prices have increased since you signed up. You're paying the lower price!";
        case "discount":
        default:
            return "You are receiving a discounted rate";
    }
}
