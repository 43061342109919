import { $error } from "@cycleplatform/core/util/log";
import { Capability } from "~/services/cycle";
import { useGetMemberRole } from "./useGetMemberRole";
import { AccessError } from "@cycleplatform/core/modules/acls/util";
import { isCycleApiError } from "~/services/helpers";

export function useCapability(
    cap: Capability | Capability[]
): Error | undefined {
    const { role, error } = useGetMemberRole();

    if (error) {
        $error("error fetching hub membership");
        throw error;
    }

    if (!role) {
        return new Error("role missing from member");
    }

    const { capabilities } = role;

    if (capabilities.all) {
        return undefined;
    }

    if (Array.isArray(cap)) {
        if (cap.every((c) => capabilities.specific.includes(c))) {
            return undefined;
        } else {
            return new AccessError().setCapability(cap);
        }
    }

    if (capabilities.specific.includes(cap)) {
        return undefined;
    } else {
        return new AccessError().setCapability(cap);
    }
}
