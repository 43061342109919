import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    FormSection,
    FormSectionHeader,
    RHF_GLOBAL_ERROR,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    faArrowLeft,
    faArrowRight,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    AutoScaleGroup,
    UpdateAutoScaleGroupApiArg,
    useGetIntegrationsQuery,
    useGetProviderLocationsQuery,
    useGetProviderServersQuery,
    useUpdateAutoScaleGroupMutation,
} from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { PageBody } from "@cycleplatform/ui/components/page";
import { useNavigate } from "react-router-dom";
import { LocationSection } from "./components/LocationSection";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { ModelsSection } from "./components/ModelsSection";
import { ProviderSection } from "./components/ProviderSection";
import { getAutoScaleGroupDefaultValues } from "../util";
import { DeployableInfraFormType } from "./form";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export function AddDeployableInfraForm({ group }: { group?: AutoScaleGroup }) {
    const form = useForm<DeployableInfraFormType>({
        defaultValues: {
            step: 0,
        },
        ...rhfConfig,
    });
    const {
        handleSubmit,
        watch,
        setValue,
        formState: { isSubmitting },
    } = form;

    const provider = watch("provider");

    const [updateAutoscaleGroup] = useUpdateAutoScaleGroupMutation();
    const nav = useNavigate();

    const onSubmit = (data: DeployableInfraFormType) => {
        if (!group) {
            return;
        }
        // formats group into a format that can be submited to updateAutoscaleGroup
        const existing = getAutoScaleGroupDefaultValues(group);

        // build new model
        const newModel = {
            provider: data.provider ? data.provider.vendor : "",
            model_id: data.model?.id || "",
            priority: data?.priority || 0,
            locations: data?.locations?.map((l) => ({
                id: l.id,
                availability_zones: data.zones?.[l.id] || undefined,
            })),
        };

        return updateAutoscaleGroup({
            groupId: group.id || "",
            body: {
                ...existing,
                infrastructure: {
                    ...existing.infrastructure,
                    // append new model
                    models: [...existing.infrastructure.models, newModel],
                },
            } as UpdateAutoScaleGroupApiArg["body"],
        })
            .unwrap()
            .then(
                () => nav(clearDialogParams()),
                handleSubmitError(form.setError, {
                    sourceOverride: { infrastructure: RHF_GLOBAL_ERROR },
                })
            );
    };

    const { data: integrations, error: integrationsError } =
        useGetIntegrationsQuery({
            filter: { category: "infrastructure-provider" },
        });

    const { data: providerServers, error: providerServerError } =
        useGetProviderServersQuery(
            {
                providerVendor: provider?.vendor || "",
                page: {
                    number: 1,
                    size: 100,
                },
            },
            { skip: !provider?.vendor }
        );

    const step = watch("step");
    const selectedModel = watch("model");

    const { data: locations, error: locationsError } =
        useGetProviderLocationsQuery(
            {
                providerVendor: provider?.vendor || "",
                page: {
                    size: 100,
                    number: 1,
                },
            },
            {
                skip: !provider?.id,
            }
        );

    if (integrationsError) {
        throw integrationsError;
    }
    if (providerServerError) {
        throw providerServerError;
    }
    if (locationsError) {
        throw locationsError;
    }

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <PageBody>
                <div className="h-full w-full ">
                    {step === 0 ? (
                        <>
                            <FormSectionHeader header="Provider" />
                            <FormSection>
                                <ProviderSection
                                    provider={provider}
                                    providers={integrations?.data}
                                />
                            </FormSection>

                            {provider ? (
                                <>
                                    <FormSectionHeader header="Models" />
                                    <FormSection>
                                        <ModelsSection
                                            provider={provider}
                                            providerServers={
                                                providerServers?.data
                                            }
                                        />
                                    </FormSection>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <LocationSection
                                model={selectedModel}
                                allLocations={locations?.data}
                            />
                        </>
                    )}
                </div>
            </PageBody>

            <DialogFooter className="justify-between">
                {step === 1 ? (
                    <Button
                        type="button"
                        onClick={() => setValue("step", 0)}
                        icon={faArrowLeft}
                        disabled={!selectedModel}
                    >
                        Back
                    </Button>
                ) : null}

                <div>
                    <RhfGlobalFormError />
                </div>

                {step === 0 ? (
                    <Button
                        flavor="primary"
                        type="button"
                        onClick={() => setValue("step", 1)}
                        icon={faArrowRight}
                        disabled={!selectedModel}
                    >
                        Next
                    </Button>
                ) : (
                    <LoaderButton
                        flavor="primary"
                        isLoading={isSubmitting}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        icon={faPlus}
                    >
                        Add Model
                    </LoaderButton>
                )}
            </DialogFooter>
        </RhfFormProvider>
    );
}
