import {
    Checkbox,
    RhfFormProvider,
    RhfFormField,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useForm } from "react-hook-form";
import {
    Environment,
    useGetVpnServiceQuery,
    VpnReconfigureTask,
    VpnEnvironmentService,
    useCreateVpnServiceJobMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useKeepFormCurrent } from "~/components/common/forms";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlOverlay } from "~/components/common/buttons";

type VpnAccessControlSectionProps = {
    environment: Environment | undefined;
};

export function VpnAccessControlSection({
    environment,
}: VpnAccessControlSectionProps) {
    const { data: vpn, error } = useGetVpnServiceQuery(
        {
            environmentId: environment?.id || "",
        },
        { skip: !environment?.id || !environment?.services?.vpn?.enable }
    );
    const vpnConfig = vpn?.data?.service;

    if (error) {
        throw error;
    }

    return <VpnAccessForm vpnConfig={vpnConfig} environment={environment} />;
}

type FormProps = {
    vpnConfig?: VpnEnvironmentService | null;
    environment: Environment | undefined;
};

const getDefaultValues = (vpnConfig?: VpnEnvironmentService | null) => {
    return {
        config: {
            auth: {
                cycle_accounts: vpnConfig?.config?.auth.cycle_accounts || false,
                vpn_accounts: vpnConfig?.config?.auth.vpn_accounts || false,
                webhook: vpnConfig?.config?.auth.webhook || "",
            },
            allow_internet: vpnConfig?.config?.allow_internet || false,
        },
    };
};

function VpnAccessForm({ vpnConfig, environment }: FormProps) {
    const form = useForm<VpnReconfigureTask["contents"]>({
        defaultValues: getDefaultValues(vpnConfig),
        ...rhfConfig,
    });
    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    useKeepFormCurrent(form, vpnConfig, (config) => getDefaultValues(config));

    const [updateVpnAccess] = useCreateVpnServiceJobMutation();

    const onSubmit = (data: VpnReconfigureTask["contents"]) => {
        return updateVpnAccess({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => form.reset(data))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Access Control" />
                <PanelContent>
                    <div className="flex gap-4">
                        <RhfFormField
                            label="Allow cycle logins"
                            name="config.auth.cycle_accounts"
                            help="Users can access VPN with Cycle
                            username/password"
                        >
                            <Checkbox
                                defaultChecked={false}
                                {...register("config.auth.cycle_accounts")}
                            />
                        </RhfFormField>

                        <RhfFormField
                            label="Allow VPN Accounts"
                            name="config.auth.vpn_accounts"
                            help="Allow access to VPN through custom
                            accounts"
                        >
                            <Checkbox
                                defaultChecked={false}
                                {...register("config.auth.vpn_accounts")}
                            />
                        </RhfFormField>
                    </div>

                    <PanelFooter className="flex-col">
                        <RhfGlobalFormError />
                        <div className="flex w-full justify-end">
                            <AccessControlOverlay
                                aclResource={environment}
                                verifyFn={modifyAccessFn(
                                    "environments-services-manage"
                                )}
                            >
                                <PushAndHoldButton
                                    flavor="primary"
                                    disabled={!isDirty}
                                    isLoading={isSubmitting}
                                    icon={faEdit}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Update VPN Access
                                </PushAndHoldButton>
                            </AccessControlOverlay>
                        </div>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
