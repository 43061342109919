/**
 *
 * @param inputString Is any string, typically in the format of JSON.stringify(blob)
 * @returns An array of the variables indicated by {{variable}} in the input string
 *
 * Function will find the values inside all double curly braces and will return an array of those values
 */
export function extractCycleVariables(inputString: string): string[] {
    const regex = new RegExp(/\"?\{\{(\$)?([a-z0-9\-]+)\}\}\"?/g); // Optional $, followed by alphanumeric and dashes
    const matches = Array.from(inputString.matchAll(regex));

    // Use Set to filter duplicates, then convert back to an array
    return Array.from(new Set(matches.map((m) => m[2])));
}
