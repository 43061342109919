import { DateTimeFormats, formatDateString } from "@cycleplatform/core/util";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CycleLineTooltip = ({
    active,
    payload,
    label,
    formatValue = (v: number) => `${v}`,
    labelMap,
}: {
    formatValue?: (v: number) => string;
    labelMap?: Record<string, string>;
    [key: string]: unknown;
}) => {
    if (active && payload && Array.isArray(payload) && payload.length) {
        return (
            <div className="dark:bg-cycle-black-accent rounded-md border bg-white dark:text-white">
                <p className="label bg-cycle-blue rounded-t-md p-2 text-sm text-white">
                    {formatDateString(
                        new Date(label as string),
                        DateTimeFormats["standard"]
                    )}
                </p>
                <div className="flex flex-col px-2 py-1 ">
                    {payload.map((p: Record<string, unknown>) => {
                        const dk = p.dataKey as string;
                        return (
                            <div
                                className="flex items-center gap-2 text-sm"
                                key={`${p.name}`}
                            >
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    color={p.stroke as string}
                                    className="w-3"
                                />
                                <div className="max-w-[12rem] overflow-hidden text-ellipsis whitespace-nowrap">
                                    {labelMap?.[dk] || dk.replace("_", " ")}
                                </div>
                                [{formatValue(p.value as number)}]
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};
