import { faTrash, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    InfrastructureIpPool,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatIPPoolMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractPoolName(item.component?.id || "", components);
    const ipPoolLink = (
        <Link to={`/infrastructure/ips/${item.component?.id}`}>{name}</Link>
    );
    switch (item.event) {
        case "infrastructure.ips.pool.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of IP pool {ipPoolLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of IP pool {ipPoolLink}
                    </span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractPoolName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as InfrastructureIpPool).block.cidr;
}
