import { ImageOrigin } from "~/services/cycle";
import { GitLocationType } from "./util";
import { DockerHubForm } from "./formSections/DockerHubForm";
import { DockerRegistryForm } from "./formSections/DockerRegistryForm";
import { DockerFileForm } from "./formSections/DockerFileForm";
import { OciRegistryForm } from "./formSections/ociRegistryForm/OciRegistryForm";

type OriginDetailsFormProps = {
    originType: ImageOrigin["type"];
    dockerFileLocation: GitLocationType;
};

export function OriginDetailsForm({
    originType,
    dockerFileLocation,
}: OriginDetailsFormProps) {
    switch (originType) {
        case "docker-hub":
            return <DockerHubForm />;
        case "docker-registry":
            return <DockerRegistryForm />;
        case "docker-file":
            return <DockerFileForm location={dockerFileLocation} />;
        case "oci-registry":
            return <OciRegistryForm />;
        default:
            return null;
    }
}
