import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import { LoadBalancerFormValues } from "../../../form";
import { V1LbConfig } from "~/services/cycle";
import classNames from "classnames";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { V1ManagePlatformDefault } from "./sections/managePlatformDefault/V1ManagePlatformDefault";
import { V1GeneralSection } from "./sections/general/V1GeneralSection";
import { V1RouterSection } from "./sections/routers/V1RouterSection";
import { V1ExtensionSection } from "./sections/extension/V1ExtensionSection";

type V1ControllerFormProps = {
    baseRoute: `config.details.controllers.${number}`;
    idx: number;
};

export function V1PortForm({ baseRoute, idx }: V1ControllerFormProps) {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortIdx = params["dialog-controllers-port-idx"];
    const { control } = useFormContext<LoadBalancerFormValues>();

    const details = useWatch({
        control,
        name: "config.details",
    }) as V1LbConfig | null;

    const transport = useWatch({
        control,
        name: `${baseRoute}.transport`,
    });

    return (
        <div
            className={classNames(
                `${idx.toString() === activePortIdx ? "visible" : "hidden"}`,
                "pb-24"
            )}
        >
            <SectionDisabledControl
                disabled={!details}
                className="flex flex-col gap-4"
            >
                <V1ManagePlatformDefault baseRoute={baseRoute} />

                {!!transport && (
                    <>
                        <V1GeneralSection baseRoute={baseRoute} />
                        <V1RouterSection baseRoute={baseRoute} idx={idx} />
                        <V1ExtensionSection baseRoute={baseRoute} />
                    </>
                )}
            </SectionDisabledControl>
        </div>
    );
}
