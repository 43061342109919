import { InfoPanel, PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    ApiKey,
    CreateApiKeyApiArg,
    useCreateApiKeyMutation,
} from "~/services/cycle";
import { useForm } from "react-hook-form";
import {
    CopyInput,
    FormField,
    FormSection,
    FormSectionHeader,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { ButtonLink, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    faEdit,
    faExternalLink,
    faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { Controller } from "react-hook-form";
import { useState } from "react";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { clearDialogParams } from "../../helpers";
import { RolesSelect } from "~/components/roles/RolesSelect";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export function CreateApiKeyForm() {
    const [key, setKey] = useState<ApiKey>();

    const form = useForm<CreateApiKeyApiArg["body"]>({
        ...rhfConfig,
    });

    const {
        register,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createApiKey] = useCreateApiKeyMutation();

    const onSubmit = (data: CreateApiKeyApiArg["body"]) => {
        if (!isDirty) {
            return;
        }

        return createApiKey({
            body: data,
        })
            .unwrap()
            .then((key) => {
                setKey(key.data);
            }, handleSubmitError(form.setError));
    };

    if (key?.secret) {
        return (
            <>
                <div className="rounded-md border p-8">
                    <FormField label="Secret">
                        <CopyInput value={key?.secret} />
                    </FormField>
                    <InfoPanel className="mb-4" type="warning">
                        Store this key somewhere safe. For security, it will not
                        be shown again.
                    </InfoPanel>
                </div>
                <PanelFooter className="justify-between">
                    <ButtonLink
                        to={clearDialogParams()}
                        icon={faTimes}
                        flavor="discard"
                        type="button"
                    >
                        Close
                    </ButtonLink>
                    <ButtonLink
                        to={`/hub/api-keys/${key?.id}`}
                        flavor="primary"
                        icon={faExternalLink}
                        type="button"
                    >
                        Navigate to API Key
                    </ButtonLink>
                </PanelFooter>
            </>
        );
    }

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pb-4">
                <FormSectionHeader header="General" />

                <FormSection>
                    <RhfFormField label="name" name="name" required>
                        <TextInput {...register("name", { ...required() })} />
                    </RhfFormField>

                    <RhfFormField label="ip restrictions" name="ips">
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <MultiSelectInput
                                    {...field}
                                    isCreatable
                                    value={field.value || []}
                                    placeholder="No IPs Selected"
                                    formatOption={(v) => {
                                        return <FormattedOption label={v} />;
                                    }}
                                    formatDisplayValue={(v) => {
                                        return v;
                                    }}
                                />
                            )}
                            control={control}
                            name="ips"
                        />
                    </RhfFormField>

                    <RhfFormField label="role" name="role_id" required>
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <RolesSelect {...field} />
                            )}
                            rules={{ ...required() }}
                            control={control}
                            name="role_id"
                        />
                    </RhfFormField>
                </FormSection>
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    flavor="primary"
                    icon={faEdit}
                    type="button"
                    onClick={form.handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                >
                    Create API Key
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
