import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useGetContainerQuery } from "~/services/cycle";
import { InstanceTab } from "./instances/InstanceTab";
import { ConfigTab } from "./config/ConfigTab";
import { ContainerDashboardTab } from "./dashboard";
import { ContainerSettingsTab } from "./settings";
import { VolumesTab } from "./volumes/VolumesTab";
import { BackupsTab } from "./backups/BackupsTab";
import { ContainerStatefulBadge } from "../../../containers/ContainerStatefulBadge";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { ResourceLockedBadge } from "~/components/common/resources/ResourceLockedBadge";
import { clearDialogParams, getAllDialogSearchParams } from "../../helpers";
import { ContainerStateControls } from "~/components/containers/controls";
import { ResourceDeprecatedBadge } from "~/components/common/resources/ResourceDeprecatedBadge";
import { ContainerDeploymentBadge } from "~/components/containers/ContainerDeploymentBadge";
import { ReadOnlyBadge } from "@cycleplatform/ui/components/badges";
import { CycleErrorBoundary } from "~/components/common/errors";
import { ContainerDialogContext } from "./context";
import { FunctionControls } from "~/components/containers/controls/FunctionControls";
import { isFunctionContainer } from "@cycleplatform/core/modules/containers/config";
import { useVerifyAccess } from "~/modules/access";
import { containerModifyAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { LogsTab } from "./logs";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { TelemetryTab } from "./telemetry/TelemetryTab";

type ContainerDialogProps = {
    className?: string;
};

export function ContainerDialog({ className }: ContainerDialogProps) {
    const params = getAllDialogSearchParams<"container">();
    const containerId = params["dialog-container-id"];
    const navigate = useNavigate();

    const { currentData: container, error } = useGetContainerQuery(
        {
            containerId: containerId || "",
            include: ["environments", "images"],
            meta: ["instances_count", "domains"],
        },
        { skip: !containerId }
    );

    const env =
        container?.includes?.environments?.[
            container?.data?.environment?.id || ""
        ];

    const modifyRestrictedError = useVerifyAccess(
        env,
        container?.data
            ? containerModifyAccessFn(container?.data)
            : () => undefined
    );

    if (error) {
        throw error;
    }
    return (
        <ContainerDialogContext.Provider
            value={{
                environment: env,
                image: container?.includes?.images?.[
                    container?.data?.image?.id || ""
                ],
            }}
        >
            <StyledResourceDialog
                open={!!containerId}
                onClose={() => navigate(clearDialogParams())}
                className={classNames(className)}
                breadcrumb={[
                    {
                        label: "Environments",
                        link: "environments",
                    },
                    {
                        label: env ? env.name : "",
                        link: `environments/${env?.id}`,
                    },
                    {
                        label: "Containers",
                        link: `environments/${env?.id}/containers`,
                    },
                    {
                        label: container?.data?.name || "",
                    },
                ]}
                header={
                    <div className="flex w-full items-center justify-between">
                        <div className="flex h-full items-center gap-4 text-base ">
                            {container?.data?.name ? (
                                <h1 className="text-2xl uppercase">
                                    {container?.data?.name}
                                </h1>
                            ) : (
                                <SkeletonHeader size="lg" />
                            )}

                            {container?.data && (
                                <>
                                    <ResourceStateBadge
                                        className="!bg-cycle-gray !h-8"
                                        state={container.data.state}
                                    />
                                    <ContainerStatefulBadge
                                        container={container.data}
                                    />
                                    <ResourceLockedBadge
                                        resource={container.data}
                                        resourceName="container"
                                    />
                                    <ResourceDeprecatedBadge
                                        resource={container.data}
                                        resourceName="container"
                                    />
                                    <ContainerDeploymentBadge
                                        className="!bg-cycle-gray !h-8"
                                        container={container.data}
                                    />
                                    {modifyRestrictedError && (
                                        <ReadOnlyBadge
                                            className="!bg-cycle-gray !h-8"
                                            state={container.data.state}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <div className="flex gap-4">
                            {container?.data &&
                            isFunctionContainer(container.data) ? (
                                <FunctionControls
                                    container={container?.data}
                                    environment={env}
                                />
                            ) : null}
                            <ContainerStateControls
                                container={container?.data}
                                environment={env}
                                disabled={
                                    isHypervisorContainer(container?.data) ||
                                    isServiceContainer(container?.data)
                                }
                            />
                        </div>
                    </div>
                }
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={NavIcons["altDash"]}
                >
                    <CycleErrorBoundary>
                        <ContainerDashboardTab container={container?.data} />
                    </CycleErrorBoundary>
                </ResourceDialogTab>

                <ResourceDialogTab
                    to="instances"
                    name="Instances"
                    icon={NavIcons["instances"]}
                >
                    <CycleErrorBoundary>
                        <InstanceTab
                            container={container?.data}
                            containerId={containerId}
                            instanceId={params["dialog-instance-id"]}
                            environment={env}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="config"
                    name="Config"
                    icon={NavIcons["hubSettings"]}
                >
                    <CycleErrorBoundary>
                        <ConfigTab
                            container={container?.data}
                            config={params["dialog-config"]}
                            focus={params["dialog-focus"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="volumes"
                    name="Volumes"
                    icon={NavIcons["volumes"]}
                >
                    <CycleErrorBoundary capability="containers-volumes-view">
                        <VolumesTab
                            container={container?.data}
                            volumeIdx={params["dialog-volume-idx"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="backups"
                    name="Backups"
                    icon={NavIcons["backups"]}
                >
                    <CycleErrorBoundary capability="containers-backups-view">
                        <BackupsTab
                            container={container?.data}
                            backupId={params["dialog-backup-id"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="logs"
                    name="Logs"
                    icon={NavIcons["logs"]}
                >
                    <CycleErrorBoundary>
                        <LogsTab container={container?.data} />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="telemetry"
                    name="Telemetry"
                    icon={NavIcons["telemetry"]}
                >
                    <CycleErrorBoundary>
                        <TelemetryTab
                            container={container?.data}
                            environment={env}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="settings"
                    name="Settings"
                    icon={NavIcons["settings"]}
                >
                    <CycleErrorBoundary>
                        <ContainerSettingsTab
                            container={container?.data}
                            focus={params["dialog-focus"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </ContainerDialogContext.Provider>
    );
}
