import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { NetworkForm } from "../../networks/forms/NetworkForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function SdnNetworkCreateDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="New Network"
            isOpen={isDialogOpen("sdn-network-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <NetworkForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
