import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { useGetContainerQuery } from "~/services/cycle";
import { ServiceContainerPanel } from "../../ServiceContainerPanel";
import { SchedulerSettingsPanel } from "./SchedulerSettingsPanel";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import {
    InfoPanel,
    Panel,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { ExternalLink } from "~/components/common/links";

type DashboardTabProps = {};

export function DashboardTab({}: DashboardTabProps) {
    const { environment } = useContext(ContainerDialogContext);
    const { data: schedulerContainer, error } = useGetContainerQuery(
        {
            containerId: environment?.services?.scheduler?.container_id || "",
            meta: ["ips", "instances_count"],
        },
        {
            skip: !environment?.services?.scheduler?.container_id,
        }
    );

    if (error) {
        throw error;
    }

    return (
        <>
            {environment?.services.loadbalancer?.config?.type !== "v1" ? (
                <div className="px-4 pt-4">
                    <InfoPanel
                        className="bg-cycle-white dark:text-cycle-white dark:bg-black"
                        type="warning"
                    >
                        <span>
                            Initiating a function container via HTTP/S requires
                            the Native Load Balancer (V1). This can be
                            configured in the{" "}
                            <Link
                                to={generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-env": environment?.id,
                                    }
                                )}
                            >
                                Load Balancer Settings
                            </Link>
                            . Learn more on the docs{" "}
                            <ExternalLink to="https://cycle.io/docs/platform/function-containers"></ExternalLink>
                        </span>
                    </InfoPanel>
                </div>
            ) : null}

            <DialogPageBody className="mt-4 pt-0">
                <PageContent>
                    <Panel>
                        <PanelTitle title="telemetry" />

                        <EmptyResource
                            className="text-center"
                            icon={NavIcons["environmentScheduler"]}
                            title="No Scheduler Telemetry"
                        >
                            <p>
                                Currently, no telemetry is available for the
                                scheduler service. We expect to add more here
                                soon, so check back later!
                            </p>
                        </EmptyResource>
                    </Panel>
                </PageContent>
                <PageAside>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                    >
                        <SchedulerSettingsPanel
                            info={environment?.services?.scheduler}
                        />
                        <ServiceContainerPanel
                            container={schedulerContainer?.data}
                            environment={environment}
                        />
                    </AccessControlledSection>
                </PageAside>
            </DialogPageBody>
        </>
    );
}
