import { components } from "../modules/api/__generated";

type AccountType = {
    type: "account";
    creator: NonNullable<
        components["schemas"]["CreatorInclude"]["accounts"]
    >[string];
};

type EmployeeType = {
    type: "employee";
    creator: NonNullable<
        components["schemas"]["CreatorInclude"]["employees"]
    >[string];
};

type VisitorType = {
    type: "visitor";
    creator: NonNullable<
        components["schemas"]["CreatorInclude"]["visitors"]
    >[string];
};

type EnvironmentType = {
    type: "environment";
    creator: NonNullable<
        components["schemas"]["CreatorInclude"]["environments"]
    >[string];
};

type ApiKeyType = {
    type: "api-key";
    creator: NonNullable<
        components["schemas"]["CreatorInclude"]["api_keys"]
    >[string];
};

type PlatformPipelineType = {
    type: "platform-pipeline";
};

type PlatformType = {
    type: "platform";
};

export type CreatorType =
    | AccountType
    | EmployeeType
    | VisitorType
    | EnvironmentType
    | ApiKeyType
    | PlatformPipelineType
    | PlatformType;

export function extractCreatorDetails(
    creator: components["schemas"]["CreatorScope"],
    creatorInclude: components["schemas"]["CreatorInclude"]
): CreatorType | null {
    if (!creator.id) {
        return {
            type: "platform",
        };
    }

    if (!creator.type) {
        return {
            type: "platform",
        };
    }

    switch (creator.type) {
        case "account":
            if (!creatorInclude["accounts"]) {
                return null;
            }
            return {
                type: creator.type,
                creator: creatorInclude["accounts"][creator.id],
            };
        case "api-key":
            if (!creatorInclude["api_keys"]) {
                return null;
            }
            return {
                type: creator.type,
                creator: creatorInclude["api_keys"][creator.id],
            };
        case "employee":
            if (!creatorInclude["employees"]) {
                return null;
            }
            return {
                type: creator.type,
                creator: creatorInclude["employees"][creator.id],
            };
        case "environment":
            if (!creatorInclude["environments"]) {
                return null;
            }
            return {
                type: creator.type,
                creator: creatorInclude["environments"][creator.id],
            };
        case "platform-pipeline":
            return {
                type: creator.type,
            };
        case "visitor":
            if (!creatorInclude["visitors"]) {
                return null;
            }
            return {
                type: creator.type,
                creator: creatorInclude["visitors"][creator.id],
            };
    }

    return { type: "platform" };
}

export function getCreatorName(creator: CreatorType) {
    if (!creator) {
        return "";
    }

    switch (creator.type) {
        case "account":
            return creator.creator?.name
                ? `${creator.creator.name.first} ${creator.creator.name.last}`
                : "";
        case "employee":
            return creator.creator?.name
                ? `${creator.creator.name.first} ${creator.creator.name.last} (Cycle Employee)`
                : "Cycle Employee";
        case "visitor":
            return creator.creator?.name
                ? `${creator.creator.name.first} ${creator.creator.name.last} (Visitor)`
                : "Visitor";
        case "api-key":
            return creator.creator?.name
                ? `${creator.creator?.name} (API Key)`
                : "API Key";
        case "environment":
            return creator.creator?.name
                ? `${creator.creator?.name} (Environment)`
                : "Environment";
        case "platform-pipeline":
            return "Platform Pipeline";
        case "platform":
            return "Platform";
    }
}
