import { parseDuration } from "@cycleplatform/core/util";
import validator from "validator";
import clean from "semver/functions/clean.js";
import isValid from "semver/functions/valid.js";
import yaml from "js-yaml";

export const minLength = (value: number, message?: string) => ({
    minLength: {
        value,
        message: message || `must be at least ${value} characters`,
    },
});

export const maxLength = (value: number, message?: string) => ({
    maxLength: {
        value,
        message: message || `must be ${value} characters or fewer`,
    },
});

export const required = (message?: string) => ({
    required: { value: true, message: message || "This field is required" },
});

export const fluidIdentifierRequired = (message?: string) => ({
    fluidIdentifierRequired: (v?: string | null) => {
        return !v
            ? true
            : v.split(":")[1] !== "" || message || `This field is required`;
    },
});

export const isTrue = (message?: string) => ({
    isTrue: (v?: boolean | null) => {
        return v === true || message || `This field must be true`;
    },
});

export const isJsonOrYaml = (message?: string) => ({
    isJsonOrYaml: (v?: string | null) => {
        if (!v) {
            return true;
        }

        try {
            JSON.parse(v);
            return true;
        } catch (e) {
            try {
                yaml.load(v);
                return true;
            } catch (e) {
                return message || `Input value must be valid JSON or YAML`;
            }
        }
    },
});
// validate object validation

export const hasMaximumValue = (value: number, message?: string) => ({
    hasMaximumValue: (v?: number | null) => {
        return !v
            ? true
            : v <= value || message || `Value must be a maximum of ${value}`;
    },
});

export const hasMinimumValue = (value: number, message?: string) => ({
    hasMinimumValue: (v?: number | null) => {
        return !v
            ? true
            : v >= value || message || `Value must be a minimum of ${value}`;
    },
});

export const startsWithSlash = (message?: string) => ({
    startsWithSlash: (v?: string | null) => {
        return !v
            ? true
            : v[0] === "/" || message || `Value must start with a "/"`;
    },
});

export const isDurationString = (message?: string) => ({
    isDurationString: (v?: string | null) => {
        return !v
            ? true
            : typeof parseDuration(v) === "number" ||
                  message ||
                  `Value must be in duration format (i.e. 1m30s)`;
    },
});

export const isEmptyString = (message?: string) => ({
    isEmptyString: (v?: string | null) => {
        return !v ? true : v === "" || message || `value must be empty string`;
    },
});

export const isUrl = (message?: string) => ({
    isUrl: (v?: string | null) => {
        return !v
            ? true
            : validator.isURL(v) || message || "Value must be a URL";
    },
});

export const isEmail = (message?: string) => ({
    isEmail: (v?: string | null) => {
        return !v
            ? true
            : validator.isEmail(v) || message || "Value must be an email";
    },
});

export const isIdentifier = (message?: string) => ({
    isIdentifier: (v?: string | null) => {
        return !v
            ? true
            : /^[a-z0-9\-]+$/.test(v) ||
                  message ||
                  "Value must be an identifier (lowercase alphanumeric)";
    },
});
export const isNotZero = (message?: string) => ({
    isNot0: (v?: number) => {
        return v === undefined
            ? true
            : v !== 0 || message || "This field cannot be 0";
    },
});

export const isTaggedImage = (message?: string) => ({
    isTaggedImage: (v?: string | null) =>
        !v
            ? true
            : (v.split(":").length === 2 && !!v.split(":")[1]!.length) ||
              message ||
              "Image must have a tag",
});

export const startsWithHttpHttps = (message?: string) => ({
    isHttp: (v?: string | null) =>
        !v
            ? true
            : v.startsWith("http://") ||
              v.startsWith("https://") ||
              message ||
              "String must start with http:// or https://",
});

export const isNotHttpHttps = (message?: string) => ({
    isHttp: (v?: string | null) =>
        !v
            ? true
            : (!v.startsWith("https") && !v.startsWith("http")) ||
              message ||
              "String cannot start with https",
});

export const hasNoSpaces = (message?: string) => ({
    hasNoSpaces: (v?: string | null) =>
        !v
            ? true
            : v.indexOf(" ") < 0 || message || "Field cannot contain spaces",
});

export const isNotUndefined = (message?: string) => ({
    isNotUndefined: (v?: unknown) =>
        v === undefined ? message || "This field is required" : true,
});

export const atLeastOneArrayEntry = (message?: string) => ({
    asLeastOneArrayEntry: (v?: unknown[]) =>
        !v ? true : v.length > 0 || message || "Must select at least one entry",
});

export const exactLength = (length: number, message?: string) => ({
    exactLength: (v?: string | null) =>
        !v
            ? true
            : v.length === length ||
              message ||
              `Must be exactly ${length} characters`,
});

/**
 * Checks that deployment version string is valid.  Must start with a v, followed by valid semver string
 * example of valid string: (v1.2.3)
 */
export const isDeploymentVersion = (message?: string) => ({
    isDeploymentVersion: (s?: string) => {
        if (!s) {
            return true;
        }

        const v = s.substring(0, 1);

        if (v !== "v") {
            return (
                message ||
                "Must use semver format 'v1.2.3'. String does not start with 'v'"
            );
        }

        const sem = clean(s);

        return !!isValid(sem) || message || "Must use semver format 'v1.2.3'";
    },
});

export const isRegExMatch = (
    regex: string | null | undefined,
    ignoreIfEllipsis?: boolean,
    message?: string
) => ({
    isRegExMatch: (s?: string | null) => {
        if (!s || !regex || (ignoreIfEllipsis && s.endsWith("..."))) {
            return true;
        }

        try {
            const re = new RegExp(regex);
            const matches = s.match(re);
            return (
                !!matches || message || "Does not match specified regex format"
            );
        } catch {
            return true;
        }
    },
});
