import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { PipelineRunDialogContext } from "../../context";

export function RuntimeVariablesSection() {
    const { run } = useContext(PipelineRunDialogContext);
    return (
        <Panel>
            <PanelTitle title="Runtime Variables" />
            <PanelContent>
                <ul className="flex w-full flex-col ">
                    {Object.entries(run?.variables || {})?.map(
                        ([key, value]) => (
                            <li>
                                <FormField label={key}>
                                    <CopyInput value={value} />
                                </FormField>
                            </li>
                        )
                    )}
                </ul>
            </PanelContent>
        </Panel>
    );
}
