import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type BadgeProps = {
    className?: string;
    role?: React.AriaRole;
};

export const Badge: FC<PropsWithChildren<BadgeProps>> = ({
    children,
    className,
    role,
}) => {
    return (
        <div
            role={role}
            className={classNames(
                "bg-cycle-black-accent inline-flex h-[24px] items-center rounded-md p-1 px-2 text-xs text-white",
                " dark:bg-cycle-gray dark:text-cycle-white flex",
                className
            )}
        >
            {children}
        </div>
    );
};
