import classNames from "classnames";

export function getOptionButtonClassName(
    isActive?: boolean,
    disabled?: boolean
) {
    return classNames(
        "w-full rounded-md border-2 p-2.5  text-left text-lg",
        "ring-cycle-blue focus:outline-none focus:ring-2",
        isActive
            ? "dark:bg-cycle-black  ring-2"
            : " dark:bg-cycle-black-accent ",
        disabled
            ? "bg-cycle-gray/10 dark:bg-cycle-white/10"
            : "hover:ring-2  dark:hover:bg-white/[0.12]"
    );
}
