import { FormattedMoney } from "@cycleplatform/ui/components/money";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { FormattedProviderName } from "@cycleplatform/ui/components/resources";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext, useWatch } from "react-hook-form";
import { useCalcGen2TierUpgradeInfo } from "../../../helpers/hooks";
import { calcTotalServerCost } from "../../../helpers/util";
import { CreateServerSchema } from "../../../form";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { Integration, ProviderServerModel } from "~/services/cycle";

type PriceBreakdownProps = {
    providerServers?: ProviderServerModel[];
    integration: Integration;
};

export function PriceBreakdown({
    providerServers,
    integration,
}: PriceBreakdownProps) {
    const { control } = useFormContext<CreateServerSchema>();

    const servers = useWatch({ name: "servers", control });

    const serverCost = calcTotalServerCost(servers, providerServers);
    const cycleCost = useCalcGen2TierUpgradeInfo(servers);

    return (
        <Panel>
            <PanelTitle title="Price Breakdown" />
            <PanelContent>
                <div className="flex justify-between pb-4">
                    <div>
                        <span className="font-bold">
                            <Tooltip
                                className="inline"
                                message="Cycle is unable to determine total monthly spend at
                                infrastructure providers as we don’t keep track of non-Cycle
                                resources."
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="text-cycle-blue pr-2"
                                />
                            </Tooltip>
                            <span>New Infrastructure Spend at </span>
                            <FormattedProviderName integration={integration} />
                            {": "}
                        </span>
                    </div>

                    <div>
                        <FormattedMoney
                            mills
                            value={serverCost}
                            className="text-cycle-blue font-bold"
                        />
                        {" /mo"}
                    </div>
                </div>

                <div className="flex justify-between pb-4">
                    <span className="font-bold">
                        <Tooltip
                            className="inline"
                            message="Additional server costs will be billed by Cycle. Calculated by
                            additional server cost for your current plan."
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-cycle-blue pr-2"
                            />
                        </Tooltip>
                        <span>New Spend at Cycle</span>
                        {": "}
                    </span>

                    <div>
                        <FormattedMoney
                            mills
                            value={cycleCost}
                            className="text-cycle-blue font-bold"
                        />
                        {" /mo"}
                    </div>
                </div>
            </PanelContent>
        </Panel>
    );
}
