import { useDebounce } from "@cycleplatform/ui/hooks";
import { useMemo, useState, useEffect, useRef } from "react";
import {
    Environment,
    Instance,
    Server,
    useLookupComponentsQuery,
} from "~/services/cycle";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { InstanceGranularity } from "./types";

export type InstanceChartDataPoint = {
    time: string;
} & Record<string, number | null>;

/**
 *
 * @param data - Aggreagate data for instance charts
 * @returns An array of instanceIds present in the data
 */
export function getInstanceIdsFromAggregateData(
    data: InstanceChartDataPoint[] | undefined
) {
    const [instanceIds, setInstanceIds] = useState<string[]>([]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const bs: string[] = [];

        data.forEach((d) => {
            const { time, ...priorities } = d;
            bs.push(...Object.keys(priorities));
        });

        setInstanceIds([...new Set(bs)]);
    }, [data]);

    return { instanceIds };
}

export type InstanceChartComponents = {
    instances: Record<string, Instance>;
    servers: Record<string, Server>;
    environments: Record<string, Environment>;
};

export function useHandleInstanceLookups(
    include: ("servers" | "environments")[]
) {
    // Uses both a ref and a useState in order to guarantee synchronous processing.
    // The ref ensures all the data is processed in order
    // The useState update is needed to trigger the RTK queries
    // The useState value is debounced to ensure it only runs once after all the ids register

    const [instanceIds, setInstanceIds] = useState<string[]>([]);
    const instanceIdsRef = useRef<string[]>([]); // Ref to store the latest state

    const debouncedInstanceIds = useDebounce(instanceIds, 250);

    const components = useBuildComponentLookup(debouncedInstanceIds, include);

    const registerIds = (ids: string[], source?: string) => {
        // Update the ref synchronously
        instanceIdsRef.current = [...instanceIdsRef.current, ...ids];

        // Update the state to trigger a re-render
        setInstanceIds([...new Set([...instanceIdsRef.current])]);
    };

    return {
        registerIds,
        components,
    };
}

/**
 *
 * @param instanceIds An array of instance IDs
 * @param include indicates what additional resources should be included, relative to the provided instances
 * @returns A map of instances, servers, and environments that the section should be aware of
 */
function useBuildComponentLookup(
    instanceIds: string[],
    include: ("servers" | "environments")[]
) {
    const { data: instances } = useLookupComponentsQuery(
        {
            body: {
                components:
                    instanceIds?.map((id) => ({
                        type: "container.instance",
                        id,
                    })) || [],
            },
        },
        { skip: !instanceIds || instanceIds.length === 0 }
    );

    const serverComponentLookup = useMemo(() => {
        if (!include.includes("servers")) {
            return [];
        }
        return Object.values(
            (instances?.data || {}) as Record<string, Instance>
        ).map((instance) => ({
            type: "infrastructure.server" as const,
            id: instance?.server_id,
        }));
    }, [instances?.data]);

    const envComponentLookup = useMemo(() => {
        if (!include.includes("environments")) {
            return [];
        }
        return Object.values(
            (instances?.data || {}) as Record<string, Instance>
        ).map((instance) => ({
            type: "environment" as const,
            id: instance?.environment?.id,
        }));
    }, [instances?.data]);

    const { data: servers } = useLookupComponentsQuery(
        {
            body: {
                components: serverComponentLookup,
            },
        },
        { skip: !serverComponentLookup || serverComponentLookup.length === 0 }
    );

    const { data: environments } = useLookupComponentsQuery(
        {
            body: {
                components: envComponentLookup,
            },
        },
        { skip: !envComponentLookup || envComponentLookup.length === 0 }
    );

    return {
        instances: instances?.data || {},
        servers: servers?.data || {},
        environments: environments?.data || {},
    } as InstanceChartComponents;
}

/**
 *
 * @param instances A keyed map of instances
 * @returns A keyed map of colors associated with each instance ID
 * This function is used to ensure that all instances appear with the same color in a given section
 */
export function useGenerateInstanceColorMap(
    instances: Record<string, Instance>
) {
    const { colors } = useGetThemedChartColors();

    return useMemo(() => {
        const colorsArr = [
            colors["blue"].DEFAULT,
            colors["gray"].DEFAULT,
            colors["green"].DEFAULT,
            colors["blue"].dark,
            colors["gray"].dark,
            colors["green"].dark,
            colors["blue"].light,
            colors["gray"].light,
            colors["green"].light,
            colors["gray"].extraLight,
            colors["black"].DEFAULT,
        ];

        return Object.values(instances).reduce(
            (instanceIdMap, instance, idx) => {
                const colorIdx = idx % colorsArr.length;
                return {
                    ...instanceIdMap,
                    [instance.id]: colorsArr[colorIdx],
                };
            },
            {} as Record<string, string>
        );
    }, [colors, instances]);
}

export function generateInstanceGranularity(
    range: number
): InstanceGranularity {
    if (range <= 24) {
        return { unit: "minute", size: 10 };
    } else if (range <= 72) {
        return { unit: "minute", size: 30 };
    } else {
        return { unit: "hour", size: 1 };
    }
}
