import { useContext, useMemo } from "react";
import { Container, useGetScopedVariablesQuery } from "~/services/cycle";
import { ContainerDialogContext } from "../../context";
import {
    processVariableHeirarchy,
    normalizeScopedVariableInfo,
    normalizeVariableInfo,
    LabeledVariableInfo,
    VariableInfo,
} from "./helpers";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { ContainerScopedVariablesTable } from "./ContainerScopedVariablesTable";

export function ScopedVariablesSummary({
    container,
}: {
    container: Container | undefined;
}) {
    const { environment, image } = useContext(ContainerDialogContext);

    const { data: sv } = useGetScopedVariablesQuery(
        {
            environmentId: environment?.id || "",
            filter: {
                container: container?.id || "",
            },
        },
        { skip: !environment?.id }
    );

    const runtimeVariables: VariableInfo[] = useMemo(() => {
        return normalizeVariableInfo(
            container?.config?.runtime?.environment_vars || {}
        );
    }, [sv]);

    const scopedVariables: VariableInfo[] = useMemo(() => {
        return normalizeScopedVariableInfo(sv?.data);
    }, [sv]);

    const imageVariables: VariableInfo[] = useMemo(() => {
        return normalizeVariableInfo(image?.config?.env || {});
    }, [sv]);

    // Structure in order of higher priority first **
    const labledVariableInfo: LabeledVariableInfo[] = useMemo(
        () =>
            processVariableHeirarchy([
                { label: "Container Config", data: runtimeVariables },
                { label: "Scoped Variables", data: scopedVariables },
                { label: "Image", data: imageVariables },
            ]),
        [runtimeVariables, scopedVariables, imageVariables]
    );
    return (
        <>
            {sv ? (
                <ContainerScopedVariablesTable
                    labeledVariableInfo={labledVariableInfo}
                />
            ) : (
                <>
                    <SkeletonTable />
                </>
            )}
        </>
    );
}
