import classNames from "classnames";

export function ScalingTile({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={classNames("rounded-md border p-4", className)}>
            <div>{children}</div>
        </div>
    );
}
