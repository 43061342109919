import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { LogsLoadingFallback } from "~/components/logs/LogsLoadingFallback";

import { Account, useGetAccountLoginsQuery } from "~/services/cycle";

type ActivityProps = {
    account?: Account;
};

export function Activity({ account }: ActivityProps) {
    const { data: logins, error } = useGetAccountLoginsQuery({
        page: {
            size: 5,
            number: 1,
        },
        sort: ["-id"],
    });
    if (error) {
        throw error;
    }

    return (
        <Panel>
            <PanelTitle title="Activity" />
            <PanelContent stretch>
                <div className={classNames("flex flex-col gap-4")}>
                    <ul>
                        {logins?.data ? (
                            <>
                                {logins?.data?.map((l) => (
                                    <li
                                        key={l.id}
                                        className={
                                            "dark:border-cycle-gray  border-b !border-opacity-40 p-4 last:border-none"
                                        }
                                    >
                                        {`${
                                            l.success ? "Successful" : "Failed"
                                        } login from IP ${l.account.ip}`}
                                        <div className="flex items-center gap-2 text-sm">
                                            <FontAwesomeIcon icon={faClock} />
                                            {formatDateString(
                                                l.time,
                                                DateFormats["standardShort"]
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <LogsLoadingFallback length={4} />
                        )}
                    </ul>
                </div>
            </PanelContent>
        </Panel>
    );
}
