import { useContext } from "react";
import { ScopedVariable } from "~/services/cycle";
import { ContainerDialogContext } from "../../context";
import { HoverSecret } from "@cycleplatform/ui/components/forms";
import { LabeledVariableInfo } from "./helpers";
import {
    StyledCell,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBinaryLock,
    faCloudCheck,
    faFileLines,
    faSubscript,
} from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

export function ScopedVariableRow({
    labeledVariable,
    scopedVariable,
}: {
    labeledVariable: LabeledVariableInfo;
    scopedVariable: ScopedVariable;
}) {
    const { environment } = useContext(ContainerDialogContext);

    return (
        <StyledTableRow>
            <StyledCell>
                <div>{labeledVariable.label}</div>
            </StyledCell>
            <StyledCell>
                <div className="flex items-center gap-4">
                    <Link
                        to={generateDialogLink("environment-scopedvar-edit", {
                            "dialog-var": scopedVariable.id,
                            "dialog-env": environment?.id || "",
                        })}
                    >
                        {labeledVariable.key}
                    </Link>
                    <div className="flex gap-2">
                        <EnvVarIcon sv={scopedVariable} />
                        <ApiIcon sv={scopedVariable} />
                        <FileIcon sv={scopedVariable} />
                    </div>
                </div>
            </StyledCell>
            <StyledCell className="!w-[20rem]">
                <ScopedVariableValue lv={labeledVariable} sv={scopedVariable} />
            </StyledCell>
        </StyledTableRow>
    );
}

function ScopedVariableValue({
    lv,
    sv,
}: {
    lv: LabeledVariableInfo;
    sv: ScopedVariable;
}) {
    const source = sv.source;

    if (source?.type !== "raw") {
        return (
            <HoverSecret
                className="!w-[20rem]"
                value={lv.value}
                isObscured={false}
            />
        );
    }

    const secret = source?.details?.secret;

    return (
        <div className="flex items-center gap-2">
            {secret?.hint ? (
                <Tooltip message={"Encrypted"}>
                    <FontAwesomeIcon icon={faBinaryLock} />
                </Tooltip>
            ) : null}
            <HoverSecret
                className="!w-[20rem]"
                value={lv.value}
                isObscured={!!secret}
            />
        </div>
    );
}

function EnvVarIcon({ sv }: { sv: ScopedVariable }) {
    const key = sv.access.env_variable?.key;

    if (!key) {
        return null;
    }
    return (
        <Tooltip message={`Accessible via environment variable ${key}`}>
            <FontAwesomeIcon icon={faSubscript} />
        </Tooltip>
    );
}

function ApiIcon({ sv }: { sv: ScopedVariable }) {
    const api = sv.access.internal_api;

    if (!api) {
        return null;
    }
    return (
        <Tooltip message={`Accessible over internal API`}>
            <FontAwesomeIcon icon={faCloudCheck} />
        </Tooltip>
    );
}
function FileIcon({ sv }: { sv: ScopedVariable }) {
    const path = sv.access.file?.path;

    if (!path) {
        return null;
    }
    return (
        <Tooltip message={`Accessible via file - Path: ${path}`}>
            <FontAwesomeIcon icon={faFileLines} />
        </Tooltip>
    );
}
