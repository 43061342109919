import { components } from "@cycleplatform/core/modules/api/__generated";
import { getProviderName } from "@cycleplatform/core/modules/infrastructure/providers";
import classNames from "classnames";

type FormattedProviderNameProps = {
    integration: components["schemas"]["Integration"];
    className?: string;
};

export function FormattedProviderName({
    className,
    integration,
}: FormattedProviderNameProps) {
    return (
        <span className={classNames(className)}>
            {getProviderName(integration)}
        </span>
    );
}
