import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

type PillButtonsProps<T> = {
    id?: string;
    value: T;
    onChange: (value: T) => void;
    options: { label: string; value: T }[];
    disabled?: boolean;
    error?: boolean;
    className?: string;
};

export const PillButtons = <T,>({
    id,
    onChange,
    value,
    disabled,
    options,
    error,
    className,
}: PillButtonsProps<T>) => {
    return (
        <RadioGroup
            id={id}
            disabled={disabled}
            aria-invalid={error ? "true" : "false"}
            onChange={onChange}
            value={value}
        >
            <div className={classNames("flex rounded-md ", className)}>
                {options.map((option) => (
                    <RadioGroup.Option
                        key={option.label}
                        value={option.value}
                        className={({ checked }) =>
                            classNames(
                                checked
                                    ? ` ${
                                          disabled
                                              ? "bg-cycle-gray"
                                              : "bg-cycle-blue"
                                      } group-disabled/fieldset:bg-cycle-gray text-white`
                                    : ` ${
                                          disabled
                                              ? "dark:bg-black"
                                              : "dark:bg-cycle-gray"
                                      }  ${
                                          disabled
                                              ? "bg-cycle-gray-light"
                                              : "bg-white"
                                      }`,

                                disabled && "!cursor-not-allowed",

                                " relative flex cursor-pointer border  px-4 py-1 first:rounded-l-md first:border-r-0 last:rounded-r-md last:border-l-0 focus:outline-none",

                                checked
                                    ? "group-disabled/fieldset:dark:bg-cycle-gray-accent group-disabled/fieldset:bg-cycle-gray"
                                    : "group-disabled/fieldset:bg-cycle-gray-light group-disabled/fieldset:dark:bg-black"
                            )
                        }
                    >
                        {({ checked }) => (
                            <>
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="text-sm">
                                            <RadioGroup.Label
                                                as="p"
                                                className={`whitespace-nowrap font-medium dark:text-white ${
                                                    checked
                                                        ? "text-white"
                                                        : "text-gray-900"
                                                }`}
                                            >
                                                {option.label}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as="span"
                                                className={`inline ${
                                                    checked
                                                        ? "text-sky-100"
                                                        : "text-gray-500"
                                                }`}
                                            ></RadioGroup.Description>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
};
