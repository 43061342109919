import { TarballForm } from "./TarballForm";
import { GitRepoForm } from "./GitRepoForm";

type DockerfileFormProps = {
    location: "git-repo" | "tarball" | undefined;
};

export function DockerFileForm({ location }: DockerfileFormProps) {
    return (
        <>
            {location === "git-repo" ? (
                <GitRepoForm />
            ) : location === "tarball" ? (
                <TarballForm />
            ) : null}
        </>
    );
}
