import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { ConfigurationRecords } from "~/components/dialogs/dns/zone-create/ConfigurationRecords";
import { DialogFormStepTypes } from "~/components/dialogs/dns/zone-create/types";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";

export function ManageHostedSubdomains() {
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();
    return (
        <>
            <FormSectionHeader header="Delegating Subdomains" />

            <FormSection>
                <p className="text-base">
                    To delegate one or more subdomains to be managed by Cycle,
                    login to your DNS provider and set up a TXT record at{" "}
                    <strong>{values[0].origin}</strong> and an NS record per
                    subdomain.
                </p>

                <ConfigurationRecords
                    isHosted={true}
                    isAuthoritative={false}
                    origin={values[0].origin}
                />
            </FormSection>
        </>
    );
}
