import { AlertBar } from "../layout/alert";

type VmContainerBannerProps = {
    isHypervisor?: boolean;
};

export function VmContainerBanner({ isHypervisor }: VmContainerBannerProps) {
    return (
        <>
            {isHypervisor ? (
                <AlertBar type="warning">
                    This is a container that underlies a virtual machine and
                    cannot be directly modified or deleted.
                </AlertBar>
            ) : null}
        </>
    );
}
