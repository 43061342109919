import { Margin } from "@nivo/core";

export const CycleAxisProps: {
    tickMargin: number;
    strokeOpacity: number;
} = {
    tickMargin: 8,
    strokeOpacity: 0,
};

export const CycleChartProps: {
    margin: Margin;
} = {
    margin: { top: 5, bottom: 5, right: 0, left: -15 },
};
