import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type HeaderTabListProps = {
    className?: string;
};

export const SideNavTabList: FC<PropsWithChildren<HeaderTabListProps>> = ({
    className,
    children,
}) => {
    return (
        <nav
            className={classNames(
                className,
                "dark:border-cycle-black-accent flex flex-wrap gap-2 p-4 [&>*]:grow"
            )}
        >
            {children}
        </nav>
    );
};
