import { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
    CreateImageSourceApiArg,
    useCreateImageSourceMutation,
} from "~/services/cycle";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextAreaInput,
    TextInput,
    required,
    FormSectionHeader,
    FormSection,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { formatTypeLocationOption, GitLocationType, sourceTypes } from "./util";
import { useNavigate } from "react-router-dom";
import { OriginDetailsForm } from "./OriginDetailsForm";
import { getAllDialogSearchParams, shouldClose } from "../../helpers";
import { IntegrationSelect } from "~/components/integrations";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
    getAclDefaultValues,
} from "~/components/common/acl";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type CreateImageSourceFormType = Omit<
    CreateImageSourceApiArg["body"],
    "acl"
> & {
    acl: AclForm;
};
export function CreateImageSourceForm() {
    const params = getAllDialogSearchParams<"image-source-create">();
    const nav = useNavigate();

    const [dockerFileLocation, setDockerfileLocation] =
        useState<GitLocationType>("git-repo");

    const form = useForm<CreateImageSourceFormType>({
        defaultValues: {
            type: "direct",
            origin: {
                type: undefined,
            },
            acl: getAclDefaultValues(undefined),
        },
        ...rhfConfig,
    });
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const originType = watch("origin.type");

    const [createSource] = useCreateImageSourceMutation();

    const onSubmit = async (data: CreateImageSourceFormType) => {
        if (!isDirty) {
            return;
        }

        const submitBody = {
            name: data.name,
            type: data.type,
            origin: data.origin,
            about: data.about,
            builder: data.builder?.integration_id
                ? { integration_id: data.builder.integration_id }
                : undefined,
            ...createAclSubmitBody(data.acl, "create"),
        };

        return createSource({
            body: submitBody,
        })
            .unwrap()
            .then((v) =>
                nav(
                    shouldClose(params["dialog-nav"]) || {
                        pathname: `images/sources/${v?.data?.id}`,
                        search: "",
                    }
                )
            )
            .catch(
                handleSubmitError(form.setError, {
                    sourceOverride: { origin: RHF_GLOBAL_ERROR },
                })
            );
    };

    useEffect(() => {
        if (originType === "docker-hub") {
            unregister("origin.details", { keepDirty: true });
            setValue("origin.details", {
                target: "",
            });
        } else if (originType === "docker-registry") {
            unregister("origin.details", { keepDirty: true });
            setValue("origin.details", {
                target: "",
            });
        } else if (originType === "docker-file") {
            unregister("origin.details", { keepDirty: true });
        }
    }, [originType]);

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <FormSectionHeader header="General" />
                <FormSection>
                    <RhfFormField label="name" name="name" required>
                        <TextInput
                            {...register("name", {
                                ...required(),
                            })}
                        />
                    </RhfFormField>

                    <RhfFormField label="description" name="about.description">
                        <TextAreaInput
                            rows={2}
                            {...register("about.description")}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="Builder"
                        name="builder.integration_id"
                        help="Use this input to tell Cycle's Factory to use an image builder integration when importing images with this source. "
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <IntegrationSelect
                                    onChange={(v) => {
                                        field.onChange(v || null);
                                    }}
                                    value={field.value}
                                    filter={{ category: "image-builder" }}
                                    placeholder="Use Cycle Factory"
                                />
                            )}
                            control={control}
                            name="builder.integration_id"
                        />
                    </RhfFormField>

                    <OriginTypeLocationHybridSelect
                        dockerFileLocation={dockerFileLocation}
                        setDockerfileLocation={setDockerfileLocation}
                    />
                </FormSection>

                <ResourceAclCreate resourceType="image source" />

                <OriginDetailsForm
                    originType={originType}
                    dockerFileLocation={dockerFileLocation}
                />
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    icon={faPlus}
                    isLoading={isSubmitting}
                    type="submit"
                    flavor="primary"
                    disabled={!isDirty}
                >
                    Create Image Source
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function OriginTypeLocationHybridSelect({
    dockerFileLocation,
    setDockerfileLocation,
}: {
    dockerFileLocation: GitLocationType;
    setDockerfileLocation: (loc: GitLocationType) => void;
}) {
    const { control, setValue, watch } =
        useFormContext<CreateImageSourceFormType>();

    const originType = watch("origin.type");

    return (
        <RhfFormField label="type" name="origin.type" required>
            <Controller
                render={({ field: { ref: _ref, ...field } }) => (
                    <BasicSelect
                        {...field}
                        placeholder="Select a source"
                        options={Object.values(sourceTypes)}
                        value={formatTypeLocationOption(
                            originType,
                            dockerFileLocation
                        )}
                        onChange={(v) => {
                            const split = v?.split(":");
                            field.onChange(v);
                            // Set origin type
                            setValue(
                                "origin.type",
                                split?.[0] as CreateImageSourceFormType["origin"]["type"]
                            );

                            // Set location in local state
                            if (split?.[1]) {
                                setDockerfileLocation(split[1] as "git-repo");
                                return;
                            }
                            setDockerfileLocation(undefined);
                        }}
                    />
                )}
                control={control}
                name="origin.type"
            />
        </RhfFormField>
    );
}
