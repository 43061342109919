import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Button, ButtonProps } from "./Button";

type CopyButtonProps = ButtonProps & {
    textToCopy: string;
    children?: React.ReactNode;
};

export function CopyButton({
    children,
    textToCopy,
    className,
    onClick,
    ...props
}: CopyButtonProps) {
    return (
        <Button
            {...props}
            className={classNames(
                className,
                "border-cycle-gray/10 rounded-none !py-0"
            )}
            onClick={(ev) => {
                navigator.clipboard.writeText(textToCopy);
                onClick?.(ev);
            }}
            icon={faCopy}
        >
            {children}
        </Button>
    );
}
