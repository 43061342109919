import {
    CreatorType,
    getCreatorName,
} from "@cycleplatform/core/modules/creators";
import { generateDialogLink } from "../dialogs/helpers";
import { Link } from "react-router-dom";

export const CreatorLink = ({
    creatorType: details,
}: {
    creatorType: CreatorType;
}) => {
    const name = getCreatorName(details);

    switch (details.type) {
        case "account": {
            return (
                <Link
                    to={generateDialogLink("account-info", {
                        "dialog-account-id": details.creator?.id || "",
                    })}
                >
                    {name}
                </Link>
            );
        }
        default:
            return <>{name}</>;
    }
};
