import classNames from "classnames";
import AuthCode from "react-auth-code-input";

type VerificationCodeInputProps = {
    // Input manages state locally, so onChange function must be passed as a side effect
    onChange: (v: string) => void;
};
export function VerificationCodeInput({
    onChange,
}: VerificationCodeInputProps) {
    return (
        <AuthCode
            length={6}
            containerClassName="flex gap-2"
            inputClassName={classNames(
                "w-1/6 text-center rounded-md h-16 text-[36px] !ring-cycle-blue focus:ring-2 !border-cycle-gray/10",
                "!dark:ring-cycle-blue dark:bg-cycle-gray-accent dark:border-none "
            )}
            onChange={(v) => onChange(v)}
        />
    );
}
