import classNames from "classnames";
import { FC } from "react";
import { Link, To } from "react-router-dom";

type DialogSubNavTabProps = {
    title: React.ReactNode;
    to: To;
    isActive: boolean;
    isError?: boolean;
    className?: string;
};

export const DialogSubNavTab: FC<DialogSubNavTabProps> = ({
    title,
    to,
    isActive,
    isError,
    className,
}) => {
    return (
        <Link
            to={to}
            className={classNames(
                "dark:text-cycle-white overflow-hidden text-ellipsis whitespace-nowrap rounded-lg rounded-r-none p-3 text-inherit",
                "hover:bg-cycle-gray-light dark:hover:bg-cycle-black-accent hover:text-inherit ",
                className,
                isActive &&
                    "bg-cycle-white-accent dark:bg-cycle-black-accent !text-cycle-blue",
                isError && "!text-error"
            )}
        >
            {title}
        </Link>
    );
};
