export const pieCenterTextProps = {
    x: "50%",
    y: "50%",
    fill: "none",
    className: "text-cycle-gray dark:text-cycle-white fill-current",
    textAnchor: "middle",
    dominantBaseline: "central",
    style: {
        fontSize: "15px",
        fontWeight: 600,
    },
};

export const pieProps = {
    innerRadius: "70%",
    outerRadius: "100%",
    paddingAngle: 0,
    isAnimationActive: false,
};
