import { formatIdAsName } from "../../../util";

/**
 * Formats lb instance information into a useful and descriptive string
 * @param instanceId is the id string of the instance
 * @param providerVendor is the vendor string of the provider for the instance
 * @param locationName is the location string of the instance
 * @returns A formatted string that described the lb instance
 */
export function formatLoadBalancerInstanceName(
    instanceId: string,
    providerVendor: string,
    locationName: string
) {
    return `${formatLoadBalancerLocation(
        providerVendor,
        locationName
    )} ${formatIdAsName(instanceId)}`;
}

/**
 * Formats lb location information into a useful and descriptive string
 * @param providerIdentifier is the identifier string of the provider for the instance
 * @param locationName is the location string of the instance
 * @returns A formatted string that described the lb location
 */
export function formatLoadBalancerLocation(
    providerVendor: string,
    locationName: string
) {
    return `${locationName} - (${providerVendor.toUpperCase()})`;
}
/**
 *
 * Designed to return undefined if the Number(t) === NaN
 * If there is no "port-" portion of the string, it will take the whole input and
 * attempt to convert to a number
 * @param controllerString in typical format port-80
 * @returns the number of the port
 */
export function extractPort(controllerString: string) {
    const t = controllerString.split("-").reverse()[0];
    return Number(t) || undefined;
}
