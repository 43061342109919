type ToolTrayContentProps = {
    ids: string[];
    children: React.ReactNode;
};

export function ToolTrayContent({ ids, children }: ToolTrayContentProps) {
    return (
        <div className="flex flex-wrap items-center justify-between text-sm">
            {children}
            <span>{ids.length} Selected</span>
        </div>
    );
}
