/**
 * @param input is a space delimited string, where parenthesis indicate that the words in the paranethesis should
 * be searched as a phrase
 *
 * Example:  the string `docker search` vs `"docker search"` yields ["docker", "search"] and ["docker search"]
 * respectively
 *
 * @returns an array of strings that is split according to the rules above
 */
export function parseSearchString(input: string): string[] {
    // Regex to match words and phrases inside quotes
    const regex = /"([^"]+)"|(\w+)/g;
    const result: string[] = [];

    let match;
    while ((match = regex.exec(input)) !== null) {
        // If the first group (with quotes) matches, add it; otherwise, add the second group
        result.push(match[1] || (match[2] as string));
    }

    return result;
}
