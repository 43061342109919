import { Badge } from "@cycleplatform/ui/components/badges";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetEnvironmentDeploymentsQuery } from "~/services/cycle";
import { generateTagVersionMap } from "@cycleplatform/core/modules/environments/deployments";
import { useMemo } from "react";

type DeploymentVersionSelectProps = {
    name?: string;
    environmentId?: string | null;
    isCreatable?: boolean;
    value?: string | null;
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value?: string | null) => void;
    /**
     * tag is an optional property that, if provided, will provide additional context to the dropdown
     * if "current tag" exists, the select component will check to see what version is currently associated and
     * it will be marked appropriately
     */
    tag?: string | null;
};

export function DeploymentVersionSelect({
    value,
    onChange,
    isCreatable,
    environmentId,
    tag,
    ...props
}: DeploymentVersionSelectProps) {
    const { data: deployments, error } = useGetEnvironmentDeploymentsQuery(
        {
            environmentId: environmentId || "",
        },
        { skip: !environmentId }
    );

    // if tag is provided, check to see what version the tag is currently associated with
    const tagVersionMap = useMemo(
        () => generateTagVersionMap(deployments?.data?.versions),
        [deployments?.data?.versions]
    );
    const currentVersion = tag && tagVersionMap[tag];

    if (error) {
        throw error;
    }

    const options = Object.keys(deployments?.data?.versions || {}).reverse();

    if (isCreatable) {
        return (
            <SelectInput
                {...props}
                value={value || undefined}
                isCreatable
                placeholder="Choose a deployment or create a new one"
                options={!!environmentId ? options : []}
                onChange={onChange}
                creatablePlaceholder={(v) => `Use deployment version "${v}"`}
                formatOption={(i) => {
                    return (
                        <FormattedOption
                            label={
                                <div className="flex w-full justify-between">
                                    <strong>{i}</strong>

                                    {i === currentVersion ? (
                                        <Badge className="flex gap-2 text-xs font-normal">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="text-success"
                                            />
                                            current
                                        </Badge>
                                    ) : null}
                                </div>
                            }
                        />
                    );
                }}
            />
        );
    }

    return (
        <SelectInput
            {...props}
            value={value || undefined}
            placeholder="Choose a deployment"
            options={!!environmentId ? options : []}
            onChange={onChange}
            formatOption={(i) => {
                return (
                    <FormattedOption
                        label={
                            <div className="flex w-full justify-between">
                                <strong>{i}</strong>

                                {i === currentVersion ? (
                                    <Badge className="flex gap-2 text-xs font-normal">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="text-success"
                                        />
                                        current
                                    </Badge>
                                ) : null}
                            </div>
                        }
                    />
                );
            }}
        />
    );
}
