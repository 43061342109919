import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Meter } from "@cycleplatform/ui/components/progress";
import classNames from "classnames";
import { StackBuild } from "~/services/cycle";

type BuildImportProgressMeterProps = {
    build?: StackBuild;
};

export function BuildImportProgressMeter({
    build,
}: BuildImportProgressMeterProps) {
    if (!build) {
        return <div className={classNames("h-8 w-full", skeletonStyles)} />;
    }
    const { text, percentage } = getBuildTextAndPercentage(build);
    const importError = build?.state.error;
    return (
        <Meter
            fillClassName={!!importError ? "bg-error" : undefined}
            percent={percentage}
            text={importError ? "Error Importing Build" : text}
        />
    );
}

interface GetBuildTextAndPercentageReturn {
    text: string;
    percentage: number;
}

const getBuildTextAndPercentage = (
    build: StackBuild
): GetBuildTextAndPercentageReturn => {
    switch (build.state.current) {
        case "new": {
            return {
                text: "Gathering Information...",
                percentage: 10,
            };
        }
        case "importing": {
            return {
                text: "Importing...",
                percentage: 25,
            };
        }
        case "building": {
            return {
                text: "Building...",
                percentage: 50,
            };
        }
        case "saving": {
            return {
                text: "Saving...",
                percentage: 75,
            };
        }
        case "verifying": {
            return {
                text: "Verifying...",
                percentage: 90,
            };
        }
        case "deleting":
        case "deleted":
        case "deploying":
        case "live": {
            return {
                text: "Build Successful",
                percentage: 100,
            };
        }
    }
};
