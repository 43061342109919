export type DataPoint = {
    time: string | number;
    // Should be of type number | null
    [key: string]: unknown;
};

export function generateCumulativeDiff(data?: DataPoint[]): DataPoint[] {
    if (!data || data.length === 0) return [];

    return data.reduce<DataPoint[]>((dataAcc, cur, idx) => {
        if (idx === 0) {
            // Omit first data point since we don't have the context to generate a diff
            return [...dataAcc];
        } else {
            const pastPoints = [data[idx - 2], data[idx - 1]];
            const { time, ...rest } = cur;

            const value = Object.entries(rest).reduce(
                (pointAcc, [key, value]) => {
                    if (
                        typeof cur[key] !== "number" ||
                        typeof pastPoints[1][key] !== "number" ||
                        (pastPoints[1]?.[key] as number) -
                            (pastPoints[0]?.[key] as number) <
                            0 ||
                        value === 0
                    ) {
                        return { ...pointAcc, [key]: null };
                    }

                    const d =
                        (value as number) - (pastPoints[1][key] as number);

                    return {
                        ...pointAcc,
                        [key]: d >= 0 ? d : null,
                    };
                },
                {} as DataPoint
            );

            return [...dataAcc, { ...value, time: new Date(time).getTime() }];
        }
    }, []);
}

export function formatTimeSeriesData(data?: DataPoint[]): DataPoint[] {
    return (
        data?.map((v) => {
            const { time, ...rest } = v;
            return {
                ...rest,
                time: new Date(time).getTime(),
            };
        }) || []
    );
}
