import { Role } from "@cycleplatform/core/modules/accounts";
import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import {
    Account,
    useGetAccountMembershipsQuery,
    useGetHubsQuery,
} from "~/services/cycle";

type MembershipsProps = {
    account?: Account;
};

export function Memberships({ account }: MembershipsProps) {
    const { data: memberships } = useGetAccountMembershipsQuery({
        include: ["roles", "senders", "hubs"],
    });

    const { data: hubs } = useGetHubsQuery({});

    return (
        <Panel>
            <PanelTitle title="Memberships" />
            <PanelContent stretch>
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell>Hub</StyledHeaderCell>
                        <StyledHeaderCell>Joined</StyledHeaderCell>
                        <StyledHeaderCell>Role</StyledHeaderCell>
                    </StyledTableHead>
                    <tbody>
                        {memberships?.data?.map((m) => (
                            <StyledTableRow key={m.id}>
                                <StyledCell>
                                    {
                                        hubs?.data?.find(
                                            (h) => h.id === m.hub_id
                                        )?.name
                                    }
                                </StyledCell>
                                <StyledCell>
                                    {formatDateString(
                                        m.events.joined,
                                        DateFormats["standardShort"]
                                    )}
                                </StyledCell>
                                <StyledCell>
                                    {
                                        memberships?.includes?.roles?.[
                                            m.role_id || ""
                                        ]?.name
                                    }
                                </StyledCell>
                            </StyledTableRow>
                        ))}
                    </tbody>
                </StyledDataTable>
            </PanelContent>
        </Panel>
    );
}
