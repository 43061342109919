import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { Image } from "~/services/cycle";
import { OverridePathInput } from "./OverridePathInput";
import { OverrideWorkdirInput } from "./OverrideWorkdirInput";

type CommandOptionsProps = {
    image?: Image;
};

export function CommandOptions({ image }: CommandOptionsProps) {
    return (
        <>
            <FormSectionHeader header="Command" />
            <FormSection>
                <OverridePathInput image={image} />
                <OverrideWorkdirInput image={image} />
            </FormSection>
        </>
    );
}
