import { getCpuUsageAsPercent } from "@cycleplatform/core/modules/containers/telemetry";
import { AggregateCpuDataPoint } from "./query";
import { InstanceChartDataPoint } from "../../helpers";
import { InstanceGranularity } from "../../types";

export function processAggregateCpuInstanceData(
    data: AggregateCpuDataPoint[] | undefined,
    granularity: InstanceGranularity
): InstanceChartDataPoint[] {
    if (!data || data.length < 2) {
        return [];
    }

    const granMin =
        granularity.unit === "minute"
            ? granularity.size
            : granularity.size * 60;

    return data.slice(1).reduce((results, current, i) => {
        const previous = data[i];
        const diff: Record<string, string | number | null> = {
            time: current.time,
        };

        Object.keys(current).forEach((key) => {
            if (
                key !== "time" &&
                typeof current[key] === "object" &&
                typeof previous[key] === "object"
            ) {
                const currentInstance = current[key];
                const previousInstance = previous[key];

                if (currentInstance && previousInstance) {
                    const percent = getCpuUsageAsPercent({
                        currentTotal: currentInstance.usage,
                        prevTotal: previousInstance.usage,
                        secondsDiff: granMin * 60,
                        cores: currentInstance.cores,
                    });

                    diff[key] = percent ? Math.round(percent * 10) / 10 : 0;
                }
            }
        });

        results.push(diff as InstanceChartDataPoint);
        return results;
    }, [] as InstanceChartDataPoint[]);
}
