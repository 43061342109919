import {
    Container,
    useDeleteContainerMutation,
    useGetVirtualMachineQuery,
} from "~/services/cycle";
import {
    CopyInput,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { ReimageContainer } from "./sections/ReimageContainer";
import { ScaleInstances } from "./sections/ScaleInstances";
import { Annotations } from "./sections/Annotations";
import { BasicSettings } from "./sections/BasicSettings";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { ServiceContainerBanner } from "~/components/containers/ServiceContainerBanner";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { useContext } from "react";
import { ContainerDialogContext } from "../context";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { VmContainerBanner } from "~/components/containers/VmContainerBanner";

type ContainerSettingsTabProps = {
    container?: Container;
    focus: string | undefined;
};

export function ContainerSettingsTab({
    container,
    focus,
}: ContainerSettingsTabProps) {
    const [deleteContainer] = useDeleteContainerMutation();
    const isService = isServiceContainer(container);
    const isHypervisor = isHypervisorContainer(container);
    const { environment } = useContext(ContainerDialogContext);

    const { data: vm } = useGetVirtualMachineQuery(
        {
            virtualMachineId: container?.image?.extension?.id || "",
        },
        { skip: !container?.image?.extension?.id || !isHypervisor }
    );

    const isContainerDeleted = container?.state.current === "deleted";
    const isVmDeleted = vm?.data?.state?.current === "deleted";
    return (
        <DialogPageBody className="relative">
            <ServiceContainerBanner isServiceCont={isService} />
            <VmContainerBanner isHypervisor={isHypervisor} />
            <div
                className={classNames(
                    "flex  flex-1 items-start rounded-lg",
                    (isService || isHypervisor) && "pt-4"
                )}
            >
                <PageContent>
                    <SectionDisabledControl
                        disabled={isContainerDeleted || isHypervisor}
                    >
                        <BasicSettings container={container} />
                    </SectionDisabledControl>
                    <SectionDisabledControl
                        disabled={isContainerDeleted || isHypervisor}
                    >
                        <ReimageContainer container={container} />
                    </SectionDisabledControl>
                    <SectionDisabledControl
                        disabled={isContainerDeleted || isHypervisor}
                    >
                        <ScaleInstances container={container} />
                    </SectionDisabledControl>
                </PageContent>

                <PageAside>
                    <Panel>
                        <PanelTitle title="Container ID" />
                        <PanelContent>
                            {container ? (
                                <CopyInput value={container.id} />
                            ) : (
                                <SkeletonButton />
                            )}
                        </PanelContent>
                    </Panel>

                    <Annotations container={container} />
                    <SectionDisabledControl
                        disabled={
                            isContainerDeleted || (isHypervisor && !isVmDeleted)
                        }
                    >
                        <DeleteResource
                            accessControl={{
                                aclResource: environment,
                                verifyFn: modifyAccessFn("containers-manage"),
                            }}
                            disabled={
                                !container ||
                                isServiceContainer(container) ||
                                container.lock
                            }
                            warningPanel={
                                container?.lock
                                    ? "This container is locked and cannot be deleted"
                                    : undefined
                            }
                            autoFocus={focus === "delete"}
                            resourceType="container"
                            resourceName={container?.name}
                            onSubmit={async () => {
                                if (!container) {
                                    return;
                                }
                                return deleteContainer({
                                    containerId: container.id,
                                }).unwrap();
                            }}
                        />
                    </SectionDisabledControl>
                </PageAside>
            </div>
        </DialogPageBody>
        // </fieldset>
    );
}
