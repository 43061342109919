import { formatBytes, formatBytesString } from "@cycleplatform/core/util/bytes";
import { FormatImageSourceMessage } from "@cycleplatform/ui/components/resources";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { GetImagesApiArg, Image, useGetImagesQuery } from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";

type ImageSelectProps = {
    value?: string;
    disabled?: boolean;
    onChange: (imageId: string | undefined, image: Image | undefined) => void;
    skip?: boolean;
    filter?: GetImagesApiArg["filter"];
    filterFields: Array<keyof Image>;
};

export function ImageSelect({
    value,
    disabled,
    onChange,
    filter,
    skip,
    filterFields,
}: ImageSelectProps) {
    // TODO - there is a risk that the image set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific image
    // if not initially returned and then merge them.
    const {
        data: images,
        error,
        isLoading,
    } = useGetImagesQuery(
        {
            sort: ["-id"],
            filter,
            include: ["sources"],
            page: {
                // TODO - infinite scroll

                size: 100,
                number: 1,
            },
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            isLoading={isLoading}
            value={value}
            onChange={(id) =>
                onChange(
                    id,
                    images?.data?.find((i) => i.id === id)
                )
            }
            disabled={disabled}
            resources={images?.data || []}
            formatDisplayValue={(i) => {
                if (!i) return "";

                return i.name;
            }}
            filterFields={filterFields}
            placeholder="No Image Selected"
            formatOption={(i) => (
                <FormattedOption
                    label={`${i.name} (${formatBytesString(i.size)})`}
                    detail={
                        <>
                            {i.about && <div>{i.about.description}</div>}
                            <FormatImageSourceMessage
                                image={i as components["schemas"]["Image"]}
                            />
                        </>
                    }
                >
                    <ResourceStateBadge state={i.state} />
                </FormattedOption>
            )}
        />
    );
}
