import { useState } from "react";
import { CategoryFilter } from "~/components/dialogs/infrastructure/addServer/ui/CategoryFilter";
import { getServerCategories } from "@cycleplatform/core/modules/infrastructure/servers";
import { ModelTile } from "./ModelTile";
import { Integration, ProviderServerModel } from "~/services/cycle";

export function ModelsSection({
    provider,
    providerServers,
}: {
    provider: Integration;
    providerServers?: ProviderServerModel[];
}) {
    const [category, setCategory] = useState<string | null>("all");

    const categories = providerServers
        ? getServerCategories(providerServers)
        : [];

    const filteredProviderServers = providerServers
        ? providerServers
              .filter((s) =>
                  category === "all" ? true : s.provider.category === category
              )
              .sort((a, b) => a.price.mills - b.price.mills)
        : [];

    return (
        <>
            <CategoryFilter
                active={category}
                setCategory={setCategory}
                categories={categories}
                loading={!providerServers}
            />

            <ul className="flex flex-col gap-4">
                {filteredProviderServers.map((s) => (
                    <ModelTile key={s.id} s={s} provider={provider} />
                ))}
            </ul>
        </>
    );
}
