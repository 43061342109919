import {
    RhfFormField,
    PillButtons,
    TextInput,
    required,
    FormSection,
    FormSectionHeader,
    TextAreaInput,
    SectionDisabledControl,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../util";

type AuthType = "http" | "ssh" | "none";

export function DockerFileAuthSection() {
    const { register, unregister, watch, setValue } =
        useFormContext<ImageSourceCreateEditSubmitType>();

    const currentAuth = watch("origin.details.repo.auth");

    const [authType, setAuthType] = useState<AuthType>(
        !currentAuth ? "none" : currentAuth?.type
    );

    const handleAuthTypeChange = (t: AuthType) => {
        setAuthType(t);
        if (t === "none") {
            setValue("origin.details.repo.auth.credentials.username", "", {
                shouldDirty: true,
            });
            unregister("origin.details.repo.auth", { keepDirty: true });
        } else if (t === "http") {
            unregister("origin.details.repo.auth", { keepDirty: true });

            setValue(
                "origin.details.repo.auth",
                {
                    type: "http",
                    credentials: {
                        username: "",
                        password: "",
                    },
                },
                { shouldDirty: true }
            );
        } else if (t === "ssh") {
            // unregister first is preventing an issue with null behavior in o
            unregister("origin.details.repo.auth", { keepDirty: true });
            setValue(
                "origin.details.repo.auth",
                {
                    type: "ssh",
                    credentials: {
                        username: "",
                        passphrase: "",
                        private_key: "",
                    },
                },
                { shouldDirty: true }
            );
        }
    };

    return (
        <>
            <SectionDisabledControl>
                <FormSectionHeader header="Auth">
                    <PillButtons
                        value={authType}
                        onChange={(v) => handleAuthTypeChange(v as AuthType)}
                        options={[
                            {
                                value: "http",
                                label: "HTTP",
                            },
                            {
                                value: "none",
                                label: "None",
                            },
                            {
                                value: "ssh",
                                label: "SSH",
                            },
                        ]}
                    />
                </FormSectionHeader>

                {authType === "http" ? (
                    <InputRow className="flex gap-4">
                        <RhfFormField
                            label="username"
                            className="w-1/2"
                            name="origin.details.repo.auth.credentials.username"
                            required
                        >
                            <TextInput
                                {...register(
                                    "origin.details.repo.auth.credentials.username",
                                    { ...required(), shouldUnregister: true }
                                )}
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="password"
                            className="w-1/2"
                            name="origin.details.repo.auth.credentials.password"
                            required
                        >
                            <TextInput
                                type="password"
                                {...register(
                                    "origin.details.repo.auth.credentials.password",
                                    { ...required(), shouldUnregister: true }
                                )}
                            />
                        </RhfFormField>
                    </InputRow>
                ) : authType === "ssh" ? (
                    <>
                        <InputRow className="flex gap-4">
                            <RhfFormField
                                label="username"
                                className="w-1/2"
                                name="origin.details.repo.auth.credentials.username"
                                required
                            >
                                <TextInput
                                    {...register(
                                        "origin.details.repo.auth.credentials.username",
                                        {
                                            ...required(),
                                            shouldUnregister: true,
                                        }
                                    )}
                                />
                            </RhfFormField>
                            <RhfFormField
                                label="passphrase"
                                className="w-1/2"
                                name="origin.details.repo.auth.credentials.passphrase"
                            >
                                <TextInput
                                    type="password"
                                    {...register(
                                        "origin.details.repo.auth.credentials.passphrase",
                                        { shouldUnregister: true }
                                    )}
                                />
                            </RhfFormField>
                        </InputRow>
                        <RhfFormField
                            label="private key"
                            name="origin.details.repo.auth.credentials.private_key"
                            required
                        >
                            <TextAreaInput
                                rows={3}
                                {...register(
                                    "origin.details.repo.auth.credentials.private_key",
                                    { ...required(), shouldUnregister: true }
                                )}
                            />
                        </RhfFormField>
                    </>
                ) : null}
            </SectionDisabledControl>
        </>
    );
}
