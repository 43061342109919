import classNames from "classnames";

export function DialogColumn({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={classNames("h-full overflow-auto pb-16", className)}>
            <div className="mx-8">{children}</div>
        </div>
    );
}
