import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export function buildInstanceHistoryChartQuery(
    containerId: string | undefined
) {
    const intervalMinutes = 15;
    const start = formatRFC3339(
        roundToNearestMinutes(subDays(new Date(), 1), {
            nearestTo: intervalMinutes,
            roundingMethod: "floor",
        })
    );
    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria: {
                "metadata.metric": "container.instances.num",
                "metadata.component._id": containerId || "",
            },
            pipeline: [
                {
                    $project: {
                        metadata: "$metadata",
                        state: "$labels.state",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "minute",
                                binSize: intervalMinutes,
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                            state: "$state",
                        },
                        count: {
                            $last: "$point_value",
                        },
                        time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                        },
                        states: {
                            $push: {
                                count: "$count",
                                state: "$_id.state",
                            },
                        },
                        time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        _id: 0,
                        time: 1,
                        states: {
                            $arrayToObject: {
                                $map: {
                                    input: "$states",
                                    as: "state",
                                    in: {
                                        k: "$$state.state",
                                        v: "$$state.count",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    $replaceRoot: {
                        newRoot: {
                            $mergeObjects: [
                                "$states",
                                {
                                    time: "$time",
                                },
                            ],
                        },
                    },
                },
            ],
        },
    };
}
