import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { LearnMore } from "../LearnMore";

export function NonHostedConfig() {
    return (
        <div className="flex gap-16">
            <div className="w-2/3 text-lg">
                <FormSectionHeader header="Non-Hosted Zone Limitations" />

                <FormSection className="flex flex-col gap-4">
                    <p>
                        Non-Hosted Zones are meant for creating associations
                        between domains and containers. These zones do not need
                        to be verified.
                    </p>{" "}
                    <ul className="flex list-disc flex-col gap-2 pl-4">
                        <li className="text-base">
                            Cycle will not have authoritive access to this zone.
                            DNS will be managed outside of Cycle.
                        </li>
                        <li className="text-base">
                            A user <strong>cannot</strong> create TLS
                            certificates through Cycle in a Non-Hosted zone.
                        </li>
                    </ul>
                </FormSection>
            </div>
            <LearnMore />
        </div>
    );
}
