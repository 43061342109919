import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ApiKey, ComponentIncludes } from "~/services/cycle";
import { Link } from "react-router-dom";
import { extractComponent, isErroredActivity } from "./common/util";

export function formatApiKeyMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name =
        extractComponent<ApiKey>(item.component, components)?.name ||
        item.component?.id;

    const link = <Link to={`/hub/api-keys/${item.component?.id}`}>{name}</Link>;
    switch (item.event) {
        case "hub.apikey.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted API key {link}</span>
                ) : (
                    <span>Error deleting API key {link}</span>
                ),
                icon: faTrash,
            };
        case "hub.apikey.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated API key {link}</span>
                ) : (
                    <span>Error updating API key {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "hub.apikey.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created API key {link}</span>
                ) : (
                    <span>Error creating API key</span>
                ),
                icon: faPlus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
