import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faCloudArrowDown, faHammer } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { AccessControlOverlay } from "~/components/common/buttons";
import { getThinClientUrl } from "~/services/cluster";
import { Environment, useGetVpnServiceQuery } from "~/services/cycle";
import {
    GenerateVpnFilesReturn,
    useGenerateVpnFilesMutation,
} from "~/services/portal";

type VpnConnectionInfoSectionProps = {
    environment: Environment | undefined;
    isVpnDisabled?: boolean;
};

export function VpnConnectionInfoSection({
    environment,
    isVpnDisabled,
}: VpnConnectionInfoSectionProps) {
    const { data: vpn } = useGetVpnServiceQuery(
        { environmentId: environment?.id || "" },
        { skip: !environment?.id }
    );

    const [filesReturn, setFilesReturn] = useState<GenerateVpnFilesReturn>({
        data: {
            ready: false,
        },
    });

    const [error, setError] = useState<string>();

    const [warning, setWarning] = useState<string>();

    const [generateVpnFiles, { isLoading }] = useGenerateVpnFilesMutation();

    const onGenerate = async () => {
        setError(undefined);
        setWarning(undefined);
        return generateVpnFiles({
            base64Url: window.btoa(vpn?.data?.url || ""),
        })
            .unwrap()
            .then(
                (v) => {
                    setFilesReturn(v as GenerateVpnFilesReturn);
                },
                (e) => {
                    if (e.status === 503) {
                        if (isVpnDisabled) {
                            return setError(
                                "VPN has not been configured or is not enabled for this environment."
                            );
                        } else {
                            return setWarning(
                                "VPN config files are currently building, but aren't yet ready for download. They should be available within a few minutes."
                            );
                        }
                    }
                    setError("Error Generating VPN Files");
                }
            );
    };

    return (
        <Panel>
            <PanelTitle title="Connection Info" />
            <PanelContent>
                <p className="mb-2">
                    {` You'll need a VPN client on your machine to connect. We recommend`}{" "}
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.sparklabs.com/viscosity/"
                        className="font-semibold"
                    >
                        Viscosity
                    </a>
                    . For more info about using the VPN, see{" "}
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://cycle.io/docs/platform/vpn-service"
                        className="font-semibold"
                    >
                        the docs
                    </a>
                    .
                </p>
                {error && <InfoPanel type="error">{error}</InfoPanel>}
                {warning && <InfoPanel type="warning">{warning}</InfoPanel>}

                <PanelFooter>
                    {!filesReturn?.data?.ready ? (
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <LoaderButton
                                flavor="primary"
                                onClick={() => onGenerate()}
                                disabled={!vpn?.data?.url}
                                isLoading={isLoading}
                                icon={faHammer}
                            >
                                Generate VPN Files
                            </LoaderButton>
                        </AccessControlOverlay>
                    ) : (
                        <Button
                            onClick={() =>
                                handleGenerate(
                                    `vpn/${window.btoa(vpn?.data?.url || "")}`
                                )
                            }
                            disabled={!vpn?.data?.url}
                            icon={faCloudArrowDown}
                            flavor="primary"
                        >
                            Download VPN Files
                        </Button>
                    )}
                </PanelFooter>
            </PanelContent>
        </Panel>
    );
}

const handleGenerate = (pathname: string) => {
    const link = document.createElement("a");
    link.href = `${getThinClientUrl()}/${pathname}`;
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};
