import { LoadingPanel } from "@cycleplatform/ui/components/resources/panels";
import { V1Controllers } from "./v1/V1Controllers";
import { HaProxyConfig } from "./haproxy/HaProxyConfig";
import { isUsingCycleNativeLb } from "@cycleplatform/core/modules/environments/loadbalancer";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../context";

export function ControllersTab() {
    const theme = useAppSelector(selectAppliedTheme);
    const { service } = useContext(LoadBalancerDialogContext);

    if (!service) {
        return <LoadingPanel theme={theme} />;
    }

    return (
        <>
            {isUsingCycleNativeLb(service) ? (
                <V1Controllers />
            ) : (
                <HaProxyConfig />
            )}
        </>
    );
}
