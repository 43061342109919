export const routerExpandedParam = "dialog-routers-expanded";
export const advancedExpandedParam = "dialog-advanced-expanded";

export function getExpandedIdentifier(
    port: string | number,
    idx: string | number
) {
    return `${port}-${idx}`;
}

export function removeExpandedIdentifier(arr: string[], identifier: string) {
    return arr.filter((v) => v !== identifier).join(",");
}

export function addExpandedIdentifier(arr: string[], identifier: string) {
    return [...arr, identifier].join(",");
}

export function replaceExpandedIdentifier(
    arr: string[],
    oldIdentifier: string,
    newIdentifier: string
) {
    return [...arr.filter((v) => v !== oldIdentifier), newIdentifier].join(",");
}
