import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import {
    Environment,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    BarChart,
    Bar,
    LineChart,
    Line,
} from "recharts";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import { getLbDestinationsQuery } from "./query";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { useEffect, useState } from "react";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { formatNumber } from "@cycleplatform/core/util/number";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerDestinationsChartProps = {
    className?: string;
    environment?: Environment;
    port: string | undefined;
};

export function LoadBalancerDestinationsChart({
    className,
    environment,
    port,
}: LoadBalancerDestinationsChartProps) {
    const { colors } = useGetThemedChartColors();

    const {
        data: telemetry,
        isLoading,
        isFetching,
    } = useGenerateAggregatedMetricsQuery(
        getLbDestinationsQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const [prevPort, setPrevPort] = useState<string | undefined>();
    const [isFetchingNewPort, setFetchingNewPort] = useState(false);

    useEffect(() => {
        if (prevPort === port && !isFetchingNewPort) {
            return;
        }
        setFetchingNewPort(isFetching);
        setPrevPort(port);
    }, [isFetching, port, prevPort]);

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    const processedData = telemetry?.data
        ?.map((d) => ({ ...d, time: new Date(d.time).getTime() }))
        .slice(1);

    return (
        <div
            className={classNames(
                className,
                "h-full",
                (isLoading || isFetchingNewPort) && skeletonStyles
            )}
        >
            {telemetry?.data && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        {...CycleChartProps}
                        data={processedData}
                        syncId={"lb-telem"}
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            scale="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            tick={(props) => <FormattedYTick {...props} />}
                        />
                        <Tooltip
                            content={(props) => (
                                <CycleBarTooltip
                                    {...props}
                                    formatValue={(v: number) =>
                                        `${formatNumber(v, { decimals: 0 })}`
                                    }
                                />
                            )}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="invalid"
                            stroke={colors["red"].DEFAULT}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="unavailable"
                            stroke={colors["gray"].DEFAULT}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="success"
                            stroke={colors["green"].DEFAULT}
                        />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
