import { components } from "../../api/__generated";

/**
 * Tries to determine the best name for an integration.
 * First will try to use the custom name, then check the meta for an integration name, then finally default
 * to the integration identifier
 *
 * @param integration
 * @returns
 */
export function getProviderName(
    integration: components["schemas"]["Integration"]
): string {
    if (integration.name) {
        return integration.name;
    }

    if (integration.meta?.definition) {
        return integration.meta.definition.name;
    }

    return integration.vendor;
}
