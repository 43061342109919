import React from "react";
import {
    faQuestionCircle,
    faArrowCircleUp,
    faPlus,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, BillingMethod, ComponentIncludes } from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";

export function formatMethodMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractComponent<BillingMethod>(
        item.component,
        components
    )?.name;

    const method = <Link to={`/methods/${item.component?.id}`}>{name}</Link>;

    switch (item.event) {
        case "billing.method.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated payment method {method}</span>
                ) : (
                    <span>Error updating payment method {method}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "billing.method.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Added payment method {method}</span>
                ) : (
                    <span>Error adding payment method</span>
                ),
                icon: faPlus,
            };
        case "billing.method.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested payment method {method} be deleted</span>
                ) : (
                    <span>
                        Error requesting payment method {method} be deleted
                    </span>
                ),
                icon: faTrash,
            };
        case "billing.method.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted payment method {method}</span>
                ) : (
                    <span>Error deleting payment method {method}</span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
