import classNames from "classnames";
import { skeletonStyles } from "../loaders/skeleton/skeletonStyle";

export type PageTitleProps = {
    children: React.ReactNode;
    className?: string;
    isLoading?: boolean;
};

export function PageTitle({ className, isLoading, children }: PageTitleProps) {
    return (
        <h2
            className={classNames(
                className,
                isLoading && skeletonStyles,
                "h-[45px] text-3xl leading-[3rem]"
            )}
        >
            <span>{children}</span>
        </h2>
    );
}
