import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { CreateIntegrationForm } from "./CreateIntegrationsForm";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function CreateIntegrationDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="Add Integration"
            isOpen={isDialogOpen("integration-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <CreateIntegrationForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
