import { Checkbox, FormFieldLabel } from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { DeployStackBuild, StackSpecContainer } from "~/services/cycle";

type ExistingContainersListProps = {
    existingContainers: Record<string, StackSpecContainer> | undefined;
};

export function ExistingContainersList({
    existingContainers,
}: ExistingContainersListProps) {
    const { register } = useFormContext<DeployStackBuild["contents"]>();
    return (
        <ul className="flex flex-col gap-2">
            {Object.entries(existingContainers || {})?.map(
                ([identifier, c]) => (
                    <div
                        key={identifier}
                        className="flex w-full items-center justify-between rounded-md border p-4"
                    >
                        <div>
                            <div>
                                <span className="font-semibold">Name:</span>{" "}
                                {c.name}
                            </div>
                            <div>
                                <span className="font-semibold">
                                    Identifier:{" "}
                                </span>
                                {identifier}
                            </div>
                        </div>

                        <div className="flex gap-8">
                            <div className="flex items-center">
                                <FormFieldLabel
                                    label="Reimage"
                                    labelClassName="pr-4"
                                />
                                <Checkbox
                                    className="!m-0"
                                    {...register(
                                        `update.containers.${c.name}.reimage`
                                    )}
                                />
                            </div>
                            <div className="flex items-center ">
                                <FormFieldLabel
                                    label="Reconfigure"
                                    labelClassName="pr-4"
                                />
                                <Checkbox
                                    {...register(
                                        `update.containers.${c.name}.reconfigure`
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )
            )}
        </ul>
    );
}
