import React from "react";
import { millsToDollars } from "@cycleplatform/core/util/money";

interface FormattedMoneyProps extends Intl.NumberFormatOptions {
    className?: string;
    value: number;
    mills?: boolean;
}

export const FormattedMoney: React.FC<FormattedMoneyProps> = ({
    value,
    mills,
    className,
    ...formatOpts
}) => {
    const amount = mills ? millsToDollars(value) : value;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        ...formatOpts,
    });

    return <span className={className}>{formatter.format(amount)}</span>;
};
