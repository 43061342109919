import { BetaBadge } from "@cycleplatform/ui/components/badges";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { NavLink, LinkProps, useMatch } from "react-router-dom";
type SidebarNavLinkProps = {
    to: LinkProps["to"];
    title: string;
    end?: boolean;
    onClick?: () => void;
    icon?: IconProp;
    beta?: boolean;
    subLinks?: SubLinkProps[];
    /** override match (most likely for search params links) */
    isMatch?: boolean;
};

type SubLinkProps = Omit<SidebarNavLinkProps, "subLinks" | "children">;

export function SidebarNavLink({
    to,
    title,
    onClick,
    icon,
    beta,
    subLinks,
    isMatch: matchOverride,
}: SidebarNavLinkProps) {
    const match = useMatch(
        `${typeof to === "string" ? to : to.pathname || ""}/*`
    );

    const isMatch =
        typeof matchOverride === "boolean" ? matchOverride : !!match;
    const [isExpanded, setExpanded] = useState(isMatch);

    useEffect(() => {
        if (!isExpanded && isMatch) {
            setExpanded(true);
        }
    }, [isMatch]);

    return (
        <>
            <div
                onClick={() => {
                    if (subLinks) {
                        setExpanded(!isExpanded);
                    }
                }}
                className={classNames(
                    {
                        "bg-white font-bold  shadow-[-3px_2px_3px_rgba(0,0,0,0.9)] dark:bg-black dark:shadow-[-3px_2px_3px_rgba(0,0,0,0.1)]":
                            isMatch && !subLinks,
                    },
                    "group rounded-tl-lg rounded-bl-lg font-semibold transition-all hover:cursor-pointer ",
                    "text-cycle-white [&>a]:block [&>a]:p-3"
                )}
            >
                <NavLink
                    to={to}
                    end={true}
                    onClick={onClick}
                    className={({ isActive }) =>
                        classNames(
                            { "font-bold ": isActive && !subLinks },
                            {
                                "pointer-events-none": subLinks,
                            },
                            "hover:text-cycle-blue  !flex items-center justify-between"
                        )
                    }
                >
                    <div
                        className={classNames(
                            "text-cycle-white  flex gap-4",
                            "group-hover:text-cycle-blue",
                            {
                                "!text-cycle-blue": isMatch && !subLinks,
                            }
                        )}
                    >
                        <div className="w-6">
                            {icon && (
                                <FontAwesomeIcon
                                    className="text-cycle-blue"
                                    icon={icon}
                                />
                            )}
                        </div>
                        <div className="flex">
                            {title} {beta && <BetaBadge />}
                        </div>
                    </div>

                    {subLinks ? (
                        <FontAwesomeIcon
                            className={classNames(
                                "text-cycle-blue mr-4 transition-transform",
                                isMatch || isExpanded ? "rotate-180" : ""
                            )}
                            icon={faChevronDown}
                        />
                    ) : null}
                </NavLink>
            </div>
            {isMatch || isExpanded ? (
                <div className="font-bold [&>a]:block [&>a]:p-3 ">
                    {subLinks?.map((l) => (
                        <SidebarSubLink key={l.title} {...l} />
                    ))}
                </div>
            ) : null}
        </>
    );
}

function SidebarSubLink({ to, title, end, onClick, icon, beta }: SubLinkProps) {
    return (
        <NavLink
            to={to}
            end={end}
            onClick={onClick}
            className={({ isActive }) =>
                classNames(
                    "text-cycle-white hover:text-cycle-blue child rounded-tl-lg rounded-bl-lg",
                    isActive
                        ? "!text-cycle-blue bg-white font-bold shadow-[-3px_2px_3px_rgba(0,0,0,0.9)] dark:bg-black dark:shadow-[-3px_2px_3px_rgba(0,0,0,0.1)]"
                        : ""
                )
            }
        >
            {icon ? (
                <FontAwesomeIcon icon={icon} className="pl-8 pr-2 text-xs" />
            ) : null}
            {title} {beta && <BetaBadge />}
        </NavLink>
    );
}
