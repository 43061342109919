import {
    Button,
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { useEffect } from "react";
import {
    Container,
    Environment,
    Instance,
    useExpireInstanceSshCredentialsMutation,
    useGenerateInstanceSshCredentialsMutation,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faClock,
    faTerminal,
} from "@fortawesome/pro-solid-svg-icons";
import { useCountdown } from "@cycleplatform/ui/hooks";
import { isAfter } from "date-fns";
import { brandColors } from "tailwindcss-config/colors";
import { $info } from "@cycleplatform/core/util/log";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { AccessControlOverlay } from "~/components/common/buttons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { containerInstanceSshAccessFn } from "@cycleplatform/core/modules/containers/acls";

type SshPanelProps = {
    instance: Instance;
    container: Container;
    environment: Environment | undefined;
};

export function SshPanel({ instance, container, environment }: SshPanelProps) {
    const [getSshCreds, { data: sshCreds, isLoading, reset }] =
        useGenerateInstanceSshCredentialsMutation({
            fixedCacheKey: `ssh-creds-${instance.id}`,
        });
    const [expireSshCreds, { isLoading: isExpiring }] =
        useExpireInstanceSshCredentialsMutation();

    // Handle initial fetch
    useEffect(() => {
        if (
            sshCreds?.data &&
            isAfter(new Date(), new Date(sshCreds.data.token.events.expires))
        ) {
            reset();
        }
    }, [instance.id]);

    return (
        <PositionedMenu
            className={"w-[30rem]"}
            render={() => {
                return (
                    <div>
                        <div className="pb-4 text-lg">
                            <FontAwesomeIcon icon={faTerminal} /> SSH Access
                        </div>
                        <p>
                            Gain SSH access to this instance via Cycle&apos;s
                            console proxy service. No SSH processes or ports are
                            installed inside your container.
                        </p>
                        {sshCreds?.data ? (
                            <>
                                <div className="mt-4">
                                    <FormField label="command">
                                        <CopyInput
                                            value={formatSshCmd(
                                                sshCreds.data.address
                                            )}
                                        />
                                    </FormField>
                                    <FormField label="password">
                                        <CopyInput
                                            value={sshCreds.data.secret}
                                        />
                                    </FormField>
                                    <Countdown
                                        onExpired={() => reset()}
                                        expires={
                                            new Date(
                                                sshCreds.data.token.events.expires
                                            )
                                        }
                                    />
                                </div>
                                <PanelFooter>
                                    <PushAndHoldButton
                                        flavor="discard"
                                        icon={faClock}
                                        loaderSvgProps={{
                                            fill: brandColors["error"].DEFAULT,
                                        }}
                                        isLoading={isExpiring}
                                        onClick={() =>
                                            expireSshCreds({
                                                instanceId: instance.id,
                                                containerId: container.id,
                                            }).then(
                                                () => {
                                                    reset();
                                                },
                                                (err) => {
                                                    $info(
                                                        "Error expiring SSH Creds",
                                                        err
                                                    );
                                                }
                                            )
                                        }
                                    >
                                        Expire All Tokens
                                    </PushAndHoldButton>
                                </PanelFooter>
                            </>
                        ) : (
                            <div className="flex min-h-[10rem]  items-center justify-center">
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={
                                        container && instance
                                            ? containerInstanceSshAccessFn(
                                                  container,
                                                  instance
                                              )
                                            : () => undefined
                                    }
                                >
                                    <LoaderButton
                                        icon={faTerminal}
                                        isLoading={isLoading}
                                        flavor="primary"
                                        onClick={() =>
                                            getSshCreds({
                                                instanceId: instance.id,
                                                containerId: container.id,
                                            })
                                        }
                                    >
                                        Get SSH Credentials
                                    </LoaderButton>
                                </AccessControlOverlay>
                            </div>
                        )}
                    </div>
                );
            }}
        >
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={
                    container && instance
                        ? containerInstanceSshAccessFn(container, instance)
                        : () => undefined
                }
            >
                <Tooltip message="Gain SSH Access" className="relative">
                    <Button icon={faTerminal} className="w-10 !px-2 text-xs" />
                    {sshCreds?.data && (
                        <FontAwesomeIcon
                            icon={faCircle}
                            className="text-warning absolute top-0 right-1 w-1 animate-pulse"
                        />
                    )}
                </Tooltip>
            </AccessControlOverlay>
        </PositionedMenu>
    );
}

function formatSshCmd(address: string) {
    return `ssh ${address.split(":")[0]} -p 22`;
}

function Countdown({
    expires,
    onExpired,
}: {
    expires: Date;
    onExpired: () => void;
}) {
    const { minutes: m, seconds: s } = useCountdown(expires);

    const minutes = m || 0;
    const seconds = s || 0;

    useEffect(() => {
        if (minutes <= 0 && seconds <= 0) {
            onExpired();
        }
    }, [minutes, seconds]);

    return (
        <span className="text-error dark:text-cycle-white">
            This token expires in{" "}
            <strong>{`${formatTimeSubstring(minutes)}:${formatTimeSubstring(
                seconds
            )}`}</strong>
        </span>
    );
}

function formatTimeSubstring(number: number) {
    return `${number < 10 ? `0${number.toString()}` : number.toString()}`;
}
