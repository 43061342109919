import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import {
    TextInput,
    RhfFormField,
    Checkbox,
    startsWithSlash,
    hasNoSpaces,
    required,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faAsterisk, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { DynamicObjectField } from "@cycleplatform/react-hook-form-components/components";

export function SharedDirectoriesIntegration() {
    const { control } = useFormContext<ContainerConfigFormData>();

    return (
        <DynamicObjectField
            control={control}
            field={`config.integrations.shared_file_systems`}
        >
            {({ fields, append, fieldKey, register, remove }) => (
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell className="flex-1">
                            Host Identifier
                            <FontAwesomeIcon
                                icon={faAsterisk}
                                className="text-error  ml-2 text-[10px]"
                            />
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-30 text-center">
                            Writable
                        </StyledHeaderCell>
                        <StyledHeaderCell className="flex-1">
                            Container Mount Point
                            <FontAwesomeIcon
                                icon={faAsterisk}
                                className="text-error  ml-2 text-[10px]"
                            />
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-[4rem]">
                            <Button
                                flavor="discard"
                                className="invisible"
                                icon={faTrash}
                            />
                        </StyledHeaderCell>
                    </StyledTableHead>

                    <tbody>
                        {fields.map((v, idx) => (
                            <StyledTableRow key={v.id}>
                                <StyledCell>
                                    <RhfFormField
                                        name={`${fieldKey}.${idx}.key`}
                                    >
                                        <TextInput
                                            {...register(
                                                `${fieldKey}.${idx}.key`,
                                                {
                                                    ...required(),
                                                    validate: {
                                                        ...hasNoSpaces(),
                                                    },
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                </StyledCell>
                                <StyledCell className="[&>*]:justify-center">
                                    <Checkbox
                                        {...register(
                                            `${fieldKey}.${idx}.value.writable`,
                                            {}
                                        )}
                                    />
                                </StyledCell>
                                <StyledCell className="!pr-4">
                                    <RhfFormField
                                        name={`${fieldKey}.${idx}.value.mount_point`}
                                    >
                                        <TextInput
                                            {...register(
                                                `${fieldKey}.${idx}.value.mount_point`,
                                                {
                                                    ...required(),
                                                    validate: {
                                                        ...startsWithSlash(),
                                                        ...hasNoSpaces(),
                                                    },
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                </StyledCell>
                                <StyledCell className="text-end">
                                    <Button
                                        flavor="discard"
                                        icon={faTrash}
                                        onClick={() => remove(idx)}
                                    />
                                </StyledCell>
                            </StyledTableRow>
                        ))}
                        <StyledTableRow>
                            <StyledCell colSpan={4}>
                                <Button
                                    type="button"
                                    icon={faPlus}
                                    className="w-full"
                                    onClick={() => {
                                        append({
                                            key: "",
                                            value: {
                                                writable: false,
                                                mount_point: "",
                                            },
                                        });
                                    }}
                                >
                                    Add
                                </Button>
                            </StyledCell>
                        </StyledTableRow>
                    </tbody>
                </StyledDataTable>
            )}
        </DynamicObjectField>
    );
}
