import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "./Badge";
import { faBook } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { components } from "@cycleplatform/core/modules/api/__generated";

export function ReadOnlyBadge({
    state,
    className,
}: {
    state?: (components["schemas"]["State"] & { current: string }) | null;
    className?: string;
}) {
    return (
        <Tooltip
            message={
                state?.current === "deleted"
                    ? "Resource is deleted"
                    : "You do not have manage permissions for this resource"
            }
        >
            <Badge
                className={classNames(
                    "dark:bg-cycle-gray gap-2 whitespace-nowrap leading-4",
                    className
                )}
            >
                <FontAwesomeIcon
                    icon={faBook}
                    className="text-cycle-gray-light/50 text-xs"
                />
                <span>read-only</span>
            </Badge>
        </Tooltip>
    );
}
