import { useForm, useWatch } from "react-hook-form";
import {
    Container,
    VolumeSummary,
    useCreateContainerJobMutation,
} from "~/services/cycle";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobTracker } from "~/modules/jobs/hooks";
import { useContext, useEffect } from "react";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { PageAside } from "@cycleplatform/ui/components/page";
import { $info } from "@cycleplatform/core/util/log";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    DialogResourceList,
    DialogResourceListItem,
} from "~/components/layout/resources";
import {
    DialogPageBody,
    DialogPageContent,
} from "@cycleplatform/ui/components/dialog";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { ActiveVolumePanel } from "./ActiveVolumePanel";
import { AccessControlOverlay } from "~/components/common/buttons";
import { ContainerDialogContext } from "../context";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { faCube } from "@fortawesome/pro-duotone-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { EmptyActionPanel } from "@cycleplatform/ui/components/panels";
import { isHypervisorContainer } from "@cycleplatform/core/modules/containers/util";
import { VmContainerBanner } from "~/components/containers/VmContainerBanner";
import classNames from "classnames";

type VolumesTabProps = {
    container?: Container;
    volumeIdx: string | undefined;
};

function getDefaultValues(volumes?: VolumeSummary[]) {
    return {
        volumes: volumes || [],
    };
}

export type ReconfigureVolumeSubmit = {
    volumes: VolumeSummary[];
};

export function VolumesTab({ container, volumeIdx }: VolumesTabProps) {
    const { environment } = useContext(ContainerDialogContext);
    const navigate = useNavigate();
    const form = useForm<ReconfigureVolumeSubmit>({
        defaultValues: getDefaultValues(container?.volumes),
        ...rhfConfig,
    });
    useKeepFormCurrent(form, container, (c) => getDefaultValues(c?.volumes));
    const hasVolumes = container?.volumes && container.volumes.length > 0;
    const {
        control,
        formState: { errors, isDirty, isSubmitting },
    } = form;

    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [createContainerJob] = useCreateContainerJobMutation();

    const volumes = useWatch({ name: "volumes", control });

    // Dialog autorouting
    useEffect(() => {
        if (volumeIdx || !container?.volumes?.[0]) {
            return;
        }

        navigate(
            generateDialogLink("container", {
                "dialog-volume-idx": "0",
                "dialog-tab": "volumes",
            }),
            { replace: true }
        );
    }, [container?.volumes, volumeIdx]);

    const params = getAllDialogSearchParams<"container">();
    const activeVolumeIdx = Number(params["dialog-volume-idx"]);

    const onSubmit = (values: ReconfigureVolumeSubmit) => {
        if (!isDirty) {
            return;
        }
        return trackJob(
            createContainerJob({
                containerId: container?.id || "",
                body: {
                    action: "volumes.reconfigure",
                    contents: values.volumes,
                },
            }).unwrap()
        ).then((f) => {
            form.reset((formValues) => ({ ...formValues }));
            $info("complete", f);
        }, handleSubmitError(form.setError, { pathPrefix: "volumes" }));
    };

    if (container && !container.volumes?.length) {
        return (
            <>
                <EmptyActionPanel
                    title={"No volumes exist for this container"}
                    icon={NavIcons["volumes"]}
                />
            </>
        );
    }

    const isHypervisor = isHypervisorContainer(container);

    return (
        <RhfFormProvider {...form} className="h-full">
            {/* Page Body has a mb-16 built in, we don't want that here for the sideNav + action button layout */}
            <DialogPageBody
                className={classNames(
                    "relative !mb-0 h-full px-0 pb-0",
                    isHypervisor && "pt-8"
                )}
            >
                <VmContainerBanner isHypervisor={isHypervisor || false} />
                {hasVolumes ? (
                    <>
                        <PageAside className="z-20 h-full !w-auto border-r bg-white dark:bg-black">
                            <DialogResourceList>
                                {volumes?.map((v, idx) => (
                                    <VolumeItem
                                        key={v.id}
                                        volume={v}
                                        isActive={activeVolumeIdx === idx}
                                        idx={idx}
                                        hasErrors={!!errors.volumes?.[idx]}
                                    />
                                ))}
                            </DialogResourceList>
                        </PageAside>

                        <DialogPageContent className="relative !p-0">
                            <div className="flex w-full flex-1 flex-col gap-4 ">
                                {volumes[activeVolumeIdx] ? (
                                    <AccessControlledSection
                                        aclResource={environment}
                                        verifyFn={modifyAccessFn(
                                            "containers-volumes-manage"
                                        )}
                                    >
                                        <ActiveVolumePanel
                                            volume={volumes[activeVolumeIdx]}
                                            idx={activeVolumeIdx}
                                            container={container}
                                            isActive
                                        />
                                    </AccessControlledSection>
                                ) : null}
                            </div>
                            <div className="dark:border-cycle-black-accent dark:bg-cycle-black absolute bottom-0 left-0 right-0 z-10 mt-auto flex items-center justify-between border-t bg-white px-2 py-4">
                                <div>
                                    <RhfGlobalFormError />
                                </div>
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={modifyAccessFn(
                                        "containers-volumes-manage"
                                    )}
                                >
                                    <PushAndHoldButton
                                        onClick={form.handleSubmit(onSubmit)}
                                        type="button"
                                        isLoading={
                                            isTrackingJob || isSubmitting
                                        }
                                        disabled={!isDirty}
                                        flavor="primary"
                                        icon={faEdit}
                                    >
                                        Save Volumes
                                    </PushAndHoldButton>
                                </AccessControlOverlay>
                            </div>
                        </DialogPageContent>
                    </>
                ) : null}
            </DialogPageBody>
        </RhfFormProvider>
    );
}

type InstanceItemProps = {
    volume: VolumeSummary;
    isActive: boolean;
    hasErrors?: boolean;
    idx: number;
};

function VolumeItem({ volume, isActive, hasErrors, idx }: InstanceItemProps) {
    return (
        <DialogResourceListItem
            to={generateDialogLink("container", {
                "dialog-volume-idx": idx.toString(),
                "dialog-tab": "volumes",
            })}
            isActive={isActive}
        >
            <div className="flex items-center">
                {volume.config.block_device ? (
                    <Tooltip message="Block device">
                        <FontAwesomeIcon
                            className="text-cycle-blue mr-3"
                            icon={faCube}
                        />
                    </Tooltip>
                ) : (
                    <FontAwesomeIcon
                        className="text-cycle-blue mr-3"
                        icon={NavIcons["volumes"]}
                    />
                )}
                <div
                    className="max-w-[13rem] overflow-hidden text-ellipsis whitespace-nowrap"
                    style={{
                        direction: "rtl",
                    }}
                >
                    {volume.config.destination}
                </div>
                {hasErrors && <span className="text-error text-xs">Error</span>}
            </div>
        </DialogResourceListItem>
    );
}
