import { FC, useMemo } from "react";
import classNames from "classnames";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useGetStateColorMap } from "~/util/charts/hooks";
import { pieProps } from "@cycleplatform/ui/components/recharts/pie";

export type ResourceStatePieChartData = {
    id: string;
    label: string;
    value: number;
}[];

type ResourceStatePieChartProps = {
    data: ResourceStatePieChartData;
    centerTextClassName?: string;
    tooltipOffset?: number;
};

export const ResourceStatePieChart: FC<ResourceStatePieChartProps> = ({
    data,
    centerTextClassName,
    tooltipOffset = -5,
}) => {
    const { colors } = useGetStateColorMap();
    const total = data.reduce((acc, cur) => (acc += cur.value), 0);
    const isEmpty = data.length === 0 || total === 0;

    const chartData = useMemo(
        () => data.map((d) => ({ ...d, name: d.label })),
        [data]
    );

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart margin={{ top: 1, bottom: 1 }}>
                <Pie
                    {...pieProps}
                    data={!isEmpty ? chartData : [{ value: 1 }]}
                    innerRadius="81%"
                    outerRadius="100%"
                    animationBegin={0}
                    fill="#000"
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data.map((entry, index) => {
                        return (
                            <Cell
                                className="cursor-pointer"
                                key={`cell-${index}`}
                                stroke="rgba(0,0,0,0)"
                                fill={
                                    !isEmpty ? colors[entry.label] : "#333333"
                                }
                            />
                        );
                    })}
                </Pie>
                <text
                    x="50%"
                    y="50%"
                    fill="none"
                    className={classNames(
                        "text-cycle-gray dark:text-cycle-white fill-current",
                        centerTextClassName
                    )}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: "15px",
                        fontWeight: 600,
                    }}
                >
                    {total < 1000 ? total : "999+"}
                </text>
                {!isEmpty ? (
                    <Tooltip
                        reverseDirection={{ x: true, y: true }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        offset={tooltipOffset}
                        content={(v) => {
                            return (
                                <div className="dark:bg-cycle-black-accent whitespace-nowrap rounded-md border bg-white p-2 text-xs dark:text-white ">
                                    <div className="flex items-center gap-2 text-xs">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="w-3"
                                            color={
                                                v.payload?.[0]?.payload?.fill
                                            }
                                        />
                                        {v.payload?.[0]?.name} [
                                        {v.payload?.[0]?.value}]
                                    </div>
                                </div>
                            );
                        }}
                    />
                ) : null}
            </PieChart>
        </ResponsiveContainer>
    );
};
