/**
 *
 * @param deploymentVersions is an object where the key is the version and the value is an object with an
 * array of tags and numbers of containers associated with that version
 * @returns A record where the key is the tag and the value is the version
 *
 * Generates a dict that makes it easy to lookup what version a tag is currently associated with
 */
export function generateTagVersionMap(
    deploymentVersions?: Record<string, { tags: string[]; containers: number }>
) {
    if (!deploymentVersions) {
        return {};
    }
    return Object.entries(deploymentVersions || {}).reduce(
        (acc, [version, val]) => {
            const tags = val.tags;
            const tagMap = tags.reduce((acc, cur) => {
                return { ...acc, [cur]: version };
            }, {});

            return { ...acc, ...tagMap };
        },
        {} as Record<string, string>
    );
}
