import {
    Checkbox,
    FormSection,
    FormSectionHeader,
    FormToggle,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Role, useGetRolesQuery } from "~/services/cycle";
import { RolesSelect } from "../../roles/RolesSelect";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { AclForm } from "./helpers";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";

export function ResourceAclCreate({ resourceType }: { resourceType: string }) {
    const { register, control } = useFormContext<{ acl: AclForm }>();
    const roleIds = useWatch({ name: "acl.roleIds", control });
    const enabled = useWatch({ name: "acl.enabled", control });

    const rolesAccessError = useCapability("hubs-roles-view");

    const { data: roles } = useGetRolesQuery(
        {
            sort: ["-rank"],
            page: {
                number: 1,
                size: 100,
            },
        },
        { skip: !!rolesAccessError }
    );

    const roleMap = useMemo(() => {
        return roles?.data?.reduce((acc, cur) => {
            return { ...acc, [cur.id]: cur };
        }, {} as Record<string, Role>);
    }, [roles]);

    return (
        <>
            <FormSectionHeader
                header={`Access Controls`}
                className="flex justify-between"
            >
                <Tooltip
                    message="missing hubs-roles-view capability"
                    disabled={!rolesAccessError}
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <FormToggle
                                {...field}
                                disabled={!!rolesAccessError}
                            />
                        )}
                        control={control}
                        name={`acl.enabled`}
                    />
                </Tooltip>
            </FormSectionHeader>

            {enabled ? (
                <FormSection>
                    <RhfFormField label="roles" name="acl.role">
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <RolesSelect {...field} multiple={true} />
                            )}
                            control={control}
                            name="acl.roleIds"
                        />
                    </RhfFormField>

                    <div className="relative max-h-[25rem] overflow-y-auto">
                        {roleIds && roleIds.length ? (
                            <StyledDataTable>
                                <StyledTableHead className="sticky top-0 bg-white">
                                    <StyledHeaderCell>Role</StyledHeaderCell>
                                    <StyledHeaderCell className="w-[8rem]">
                                        <div className="flex items-center gap-2">
                                            View
                                            <HelpInfoCircle
                                                message={`Provides ability to fetch and view this ${resourceType}.`}
                                            />
                                        </div>
                                    </StyledHeaderCell>
                                    <StyledHeaderCell className="w-[8rem]">
                                        <div className="flex items-center gap-2">
                                            Modify
                                            <HelpInfoCircle
                                                message={`Provides the ability to update this ${resourceType} and it's child resources.`}
                                            />
                                        </div>
                                    </StyledHeaderCell>
                                    <StyledHeaderCell className="w-[8rem]">
                                        <div className="flex items-center gap-2">
                                            Manage
                                            <HelpInfoCircle
                                                message={`Provides ability to delete and manage ACLs for this ${resourceType}.`}
                                            />
                                        </div>
                                    </StyledHeaderCell>
                                </StyledTableHead>

                                <tbody>
                                    {roleIds.map((r, idx) => (
                                        <StyledTableRow key={r}>
                                            <StyledCell>
                                                {roleMap?.[r]?.name}
                                            </StyledCell>
                                            <StyledCell>
                                                <Checkbox
                                                    {...register(
                                                        `acl.roles.${idx}.view`
                                                    )}
                                                />
                                            </StyledCell>
                                            <StyledCell>
                                                <Checkbox
                                                    {...register(
                                                        `acl.roles.${idx}.modify`
                                                    )}
                                                />
                                            </StyledCell>
                                            <StyledCell>
                                                <Checkbox
                                                    {...register(
                                                        `acl.roles.${idx}.manage`
                                                    )}
                                                />
                                            </StyledCell>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            </StyledDataTable>
                        ) : null}
                    </div>
                </FormSection>
            ) : null}
        </>
    );
}
