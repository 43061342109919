import {
    DateTimeFormats,
    formatDateString,
} from "@cycleplatform/core/util/time";
import type { components } from "@cycleplatform/core/modules/api/__generated";
import { Avatar } from "@cycleplatform/ui/components/avatar";
import { usePagination } from "@cycleplatform/ui/hooks";
import { ActivityMessage } from "./ActivityMessage";
import {
    Activity,
    ComponentIncludes,
    CreatorInclude,
    useGetHubActivityQuery,
} from "~/services/cycle";
import { useEffect, useRef, useState } from "react";
import { SkeletonFallback } from "@cycleplatform/ui/components/loaders/skeleton";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { extractCreatorDetails } from "@cycleplatform/core/modules/creators";
import { getCreatorName } from "@cycleplatform/core/modules/creators";
import { Security } from "./Security";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { CreatorAvatar } from "@cycleplatform/ui/components/resources/creator/CreatorAvatar";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import classNames from "classnames";
import { LogsLoadingFallback } from "../LogsLoadingFallback";
import { HumanizedTime } from "@cycleplatform/ui/components/time";

type RecentActivityLogProps = {
    filterEvent?: Activity["event"][];
    userId?: string;
    containerId?: string;
    environmentId?: string;
    accountId?: string | null;
    vmId?: string | null;
    verbosity?: number;
    className?: string;
    pagination?: ReturnType<typeof usePagination>;
    skip?: boolean;
};

export function RecentActivityLog({
    filterEvent,
    userId,
    containerId,
    vmId,
    environmentId,
    accountId,
    className,
    pagination,
    verbosity = 1,
    skip,
}: RecentActivityLogProps) {
    // const pagination = usePagination(15, pageParam);

    const [holdWhileLoading, setHoldWhileLoading] = useState(false);

    const scrollSection = useRef<HTMLUListElement>(null);

    const filter: Record<string, string> = {
        verbosity: `${verbosity}`,
    };

    if (userId) {
        filter["user"] = userId;
    }

    if (containerId) {
        filter["container"] = containerId;
    }

    if (environmentId) {
        filter["environment"] = environmentId;
    }

    if (vmId) {
        filter["virtual-machine"] = vmId;
    }

    if (accountId) {
        filter["user"] = `account-${accountId}`;
    }

    if (filterEvent) {
        filter["events"] = filterEvent.join(",");
    }

    useEffect(() => {
        scrollSection.current?.scrollTo(0, 0);
    }, [pagination?.number]);

    const { data: activities, error } = useGetHubActivityQuery(
        {
            sort: ["-id"],
            filter,
            include: ["components", "users"],
            page: pagination,
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <div className={classNames(className, "relative h-full w-full")}>
            <ul ref={scrollSection} className="overflow-y-scroll-auto ">
                <SkeletonFallback
                    shouldRenderFallback={!activities?.data || holdWhileLoading}
                    fallback={<LogsLoadingFallback />}
                >
                    {activities?.data?.map((a) => (
                        <RecentActivityItem
                            key={a.id}
                            activity={a}
                            creators={activities?.includes?.users}
                            components={activities?.includes?.components}
                        />
                    ))}
                    {activities?.data && activities.data.length === 0 ? (
                        <EmptyResource
                            className="border-none pb-0"
                            title="No Logs"
                            icon={NavIcons["environments"]}
                        />
                    ) : null}
                </SkeletonFallback>
            </ul>
        </div>
    );
}

type RecentActivityItemProps = {
    activity?: Activity;
    creators?: CreatorInclude;
    components?: ComponentIncludes;
};

export const RecentActivityItem = ({
    activity,
    creators,
    components,
}: RecentActivityItemProps) => {
    const theme = useAppSelector(selectAppliedTheme);

    return (
        <li
            className={
                "dark:border-cycle-gray  flex min-h-[6rem] items-center gap-4 border-b !border-opacity-40 p-4 last:border-none"
            }
        >
            <div
                className={classNames(
                    "flex w-1/12 min-w-[3rem] justify-center",
                    !activity && skeletonStyles
                )}
            >
                {creators && activity && (
                    <CreatorAvatar
                        theme={theme}
                        creator={activity.user}
                        creatorInclude={
                            creators as components["schemas"]["CreatorInclude"]
                        }
                    />
                )}
            </div>
            <div
                className={classNames(
                    "flex w-11/12  items-center justify-between",
                    !activity && skeletonStyles
                )}
            >
                <div className="w-full">
                    <div className="flex w-full flex-1 flex-wrap items-center justify-between">
                        <div className="pr-4">
                            <div className="font-bold">
                                {activity && creators && (
                                    <ActivityCreator
                                        activity={activity}
                                        creators={creators}
                                    />
                                )}
                            </div>
                            {activity && components ? (
                                <div className="text-wrap overflow-hidden whitespace-normal break-words ">
                                    <ActivityMessage
                                        activity={activity}
                                        components={components}
                                    />
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>

                        <div>
                            {activity?.security && (
                                <div className="mt-2 w-[12rem] ">
                                    <Security security={activity.security} />
                                </div>
                            )}
                        </div>
                    </div>
                    {activity && activity.error !== null && (
                        <div className="text-error text-sm">
                            Error: {activity?.error.message}
                        </div>
                    )}

                    <HumanizedTime
                        value={activity?.time}
                        significantFigures={1}
                        className={"text-sm"}
                    />
                </div>
            </div>
        </li>
    );
};

type ActivityAvatarProps = {
    activity: Activity;
    creators: CreatorInclude;
};

const ActivityAvatar = ({ activity, creators }: ActivityAvatarProps) => {
    switch (activity.user.type) {
        case "account": {
            if (!creators.accounts) {
                return null;
            }
            const accEmail = creators.accounts[activity.user.id].email.address;
            return <Avatar email={accEmail} className="h-10 w-10" />;
        }
        case "employee": {
            if (!creators.employees) {
                return null;
            }
            const accEmail = creators.employees[activity.user.id].email.address;
            return <Avatar email={accEmail} className="w-10" />;
        }
        default:
            return (
                <img
                    src={`https://static.cycle.io/icons/logo/cycle_gear.svg`}
                    className="w-10"
                />
            );
    }
};

const ActivityCreator = ({ activity, creators }: ActivityAvatarProps) => {
    const name = formatUserName(activity, creators);

    switch (activity.user.type) {
        case "account": {
            if (!creators.accounts) {
                return null;
            }

            return (
                <Link
                    to={generateDialogLink("account-info", {
                        "dialog-account-id": activity.user.id,
                    })}
                >
                    {name}
                </Link>
            );
        }
        default:
            return <>{name}</>;
    }
};

const formatUserName = (activity: Activity, creators: CreatorInclude) => {
    const details = extractCreatorDetails(
        activity.user,
        creators as components["schemas"]["CreatorInclude"]
    );
    if (!details) {
        return "Unknown";
    }
    return getCreatorName(details);
};
