import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { PageControl } from "@cycleplatform/ui/components/pagination";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { usePagination } from "@cycleplatform/ui/hooks";
import { CycleErrorBoundary } from "~/components/common/errors";
import { RecentActivityLog } from "~/components/logs/activity";
import {
    Environment,
    useCreateContainerJobMutation,
    useGetContainerQuery,
    useCreateVpnServiceJobMutation,
} from "~/services/cycle";
import { doesLbHaveIps } from "@cycleplatform/core/modules/environments/loadbalancer";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { ServiceContainerPanel } from "../../ServiceContainerPanel";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { faPlay } from "@fortawesome/pro-duotone-svg-icons";
import { useJobTracker } from "~/modules/jobs/hooks";
import { VpnConnectionInfoSection } from "./VpnConnectionInfoSection";
import { VpnDisableSection } from "./VpnDisableSection";
import { isCycleApiError } from "~/services/helpers";
import { useContext, useState } from "react";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { VpnControlsSection } from "./VpnControlsSection";
import { VpnSettingsSection } from "./VpnSettingsSection";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { panelFallbackWrapper } from "~/components/common/errors/FallbackWrappers";

type DashboardTabProps = {};

export function DashboardTab({}: DashboardTabProps) {
    const pagination = usePagination(50, "dialog-page");
    const { environment } = useContext(ContainerDialogContext);

    return (
        <DialogPageBody>
            <PageContent>
                <Panel>
                    <PanelTitle
                        className="bg-cycle-white-accent dark:bg-cycle-black-accent sticky -top-5 z-10 flex justify-between"
                        title="Recent Activity"
                    >
                        <PageControl page={pagination} />
                    </PanelTitle>

                    <PanelContent className="flex max-h-full overflow-y-auto px-0 pt-0">
                        <CycleErrorBoundary>
                            <RecentActivityLog
                                pagination={pagination}
                                environmentId={environment?.id}
                                filterEvent={[
                                    "environment.services.vpn.login",
                                    "environment.services.vpn.reconfigure",
                                    "environment.services.vpn.user.create",
                                ]}
                                skip={!environment}
                            />
                        </CycleErrorBoundary>
                    </PanelContent>
                </Panel>
            </PageContent>
            <PageAside>
                <CycleErrorBoundary>
                    <AsideSection environment={environment} />
                </CycleErrorBoundary>
            </PageAside>
        </DialogPageBody>
    );
}

function AsideSection({
    environment,
}: {
    environment: Environment | undefined;
}) {
    const { data: vpnContainer, error: vpnError } = useGetContainerQuery(
        {
            containerId: environment?.services?.vpn?.container_id || "",
            meta: ["ips", "instances_count"],
        },
        {
            skip: !environment?.services?.vpn?.container_id,
        }
    );
    const { data: loadBalancerContainer, error: lbError } =
        useGetContainerQuery(
            {
                containerId:
                    environment?.services?.loadbalancer?.container_id || "",
                meta: ["ips"],
            },
            {
                skip: !environment?.services?.loadbalancer?.container_id,
            }
        );
    const [reconfigureVpn] = useCreateVpnServiceJobMutation();

    const isLoadBalancerRunning =
        (loadBalancerContainer &&
            loadBalancerContainer?.data?.state?.current === "running") ||
        false;

    const isVpnEnabled = environment?.services.vpn?.enable || false;

    const isLbMisconfigured = loadBalancerContainer?.data
        ? !doesLbHaveIps(loadBalancerContainer.data)
        : false;

    const [trackStartJob, { isTrackingJob: isTrackingStartJob }] =
        useJobTracker();

    const [createContainerJob] = useCreateContainerJobMutation();

    const [trackEnableJob, { isTrackingJob: isTrackingEnableJob }] =
        useJobTracker();
    const [formError, setFormError] = useState<string>();
    const enableVpn = () =>
        reconfigureVpn({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: {
                    enable: true,
                },
            },
        });

    if (lbError) {
        throw lbError;
    }

    if (vpnError) {
        throw vpnError;
    }

    return (
        <AccessControlledSection
            aclResource={environment}
            verifyFn={modifyAccessFn("environments-services-manage")}
        >
            {isLoadBalancerRunning === false ||
            !isVpnEnabled ||
            isLbMisconfigured ? (
                <>
                    {!isLoadBalancerRunning ? (
                        <>
                            <EmptyResource
                                className="text-center"
                                icon={NavIcons["environmentVpn"]}
                                title="No Running Load Balancer"
                                footer={
                                    <div className="flex justify-center">
                                        <PushAndHoldButton
                                            icon={faPlay}
                                            isLoading={isTrackingStartJob}
                                            aria-label="Start Load Balancer"
                                            flavor="primary"
                                            onClick={() =>
                                                trackStartJob(
                                                    createContainerJob({
                                                        containerId:
                                                            loadBalancerContainer
                                                                ?.data?.id ||
                                                            "",
                                                        body: {
                                                            action: "start",
                                                        },
                                                    }).unwrap()
                                                )
                                            }
                                        >
                                            Start Load Balancer
                                        </PushAndHoldButton>
                                    </div>
                                }
                            >
                                <p>
                                    In order to utilize the VPN service, you
                                    must have at least one active load balancer
                                    in this environment.
                                </p>
                            </EmptyResource>
                        </>
                    ) : !isVpnEnabled ? (
                        <EmptyResource
                            className="justify-center "
                            icon={NavIcons["environmentVpn"]}
                            title="VPN Service is not Enabled"
                            footer={
                                <div className="flex justify-center">
                                    <PushAndHoldButton
                                        icon={faPlay}
                                        isLoading={isTrackingEnableJob}
                                        aria-label="Enable VPN Service"
                                        flavor="primary"
                                        onClick={() =>
                                            trackEnableJob(
                                                enableVpn().unwrap()
                                            ).catch((error) => {
                                                if (isCycleApiError(error)) {
                                                    setFormError(
                                                        error.data.error.title
                                                    );
                                                }
                                            })
                                        }
                                    >
                                        Enable
                                    </PushAndHoldButton>
                                </div>
                            }
                        >
                            <p className="text-center">
                                Start the VPN service by clicking the button
                                below.
                            </p>
                            {formError && (
                                <p className="text-error mt-4 text-sm">
                                    {formError}
                                </p>
                            )}
                        </EmptyResource>
                    ) : isLbMisconfigured ? (
                        <EmptyResource
                            className="text-center"
                            icon={NavIcons["environmentVpn"]}
                            title="Load Balancer is Misconfigured"
                        >
                            <p>
                                In order to utilize the VPN service, you must
                                have at least one active load balancer in this
                                environment. The load balancer in this
                                environment is misconfigured and has no
                                instances.
                            </p>
                        </EmptyResource>
                    ) : null}
                </>
            ) : (
                <>
                    <CycleErrorBoundary
                        fallbackWrapper={panelFallbackWrapper(
                            "connection info"
                        )}
                    >
                        <VpnConnectionInfoSection environment={environment} />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary
                        fallbackWrapper={panelFallbackWrapper("settings")}
                    >
                        <VpnSettingsSection
                            environment={environment}
                            info={environment?.services?.vpn}
                        />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary
                        fallbackWrapper={panelFallbackWrapper("container")}
                    >
                        <ServiceContainerPanel
                            container={vpnContainer?.data}
                            environment={environment}
                        />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary
                        fallbackWrapper={panelFallbackWrapper("controls")}
                    >
                        <VpnControlsSection />
                    </CycleErrorBoundary>
                    <CycleErrorBoundary
                        fallbackWrapper={panelFallbackWrapper("vpn disable")}
                    >
                        <VpnDisableSection />
                    </CycleErrorBoundary>
                </>
            )}
        </AccessControlledSection>
    );
}
