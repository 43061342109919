import { createContext } from "react";
import { InstanceChartComponents } from "~/components/instances/telemetry/helpers";
import { InstanceGranularity } from "~/components/instances/telemetry/types";

type InstanceChartTelemetryContext = {
    instanceLabelMap: Record<string, string>;
    instanceColorMap: Record<string, string>;
    filtering: {
        granularity: InstanceGranularity;
        instanceLimit: number;
        range: number;
        isBuffering: boolean;
    };

    registerIds: (ids: string[]) => void;
    components: InstanceChartComponents;
    criteria?: Record<string, string>;
    tableInclude?: ("environments" | "servers")[];
};
export const InstanceChartTelemetryContext =
    createContext<InstanceChartTelemetryContext>({
        instanceLabelMap: {},
        instanceColorMap: {},

        filtering: {
            granularity: { unit: "minute", size: 10 },
            instanceLimit: 10,
            range: 24,
            isBuffering: false,
        },

        registerIds: () => null,
        components: { instances: {}, servers: {}, environments: {} },
        criteria: undefined,
        tableInclude: [],
    });
