export function FormattedYTick({
    x,
    y,
    width,
    height,
    payload,
    formatValue = (v: number) => `${v}`,
}: any & { formatValue: (v: number) => string }) {
    return (
        <text
            width={width}
            height={height}
            x={x + 10}
            y={y}
            textAnchor="end"
            className="fill-cycle-gray dark:fill-cycle-white text-end text-xs font-semibold"
        >
            {formatValue(payload.value)}
        </text>
    );
}
