import { Button } from "@cycleplatform/ui/components/buttons";
import { TextInput } from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useContext, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadBalancerFormValues } from "../form";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { LoadBalancerDialogContext } from "../../context";

export function AddPort() {
    const { environment } = useContext(LoadBalancerDialogContext);
    const [port, setPort] = useState<string>("");
    const { setValue, watch } = useFormContext<LoadBalancerFormValues>();
    const lbDetails = watch("config.details");
    const custom = watch("custom");

    const disabled =
        custom || (typeof port === "string" && port !== "") ? false : true;
    const nav = useNavigate();

    const onSubmit = (newPort: string) => {
        if (lbDetails && "default" in lbDetails) {
            setPort("");
            setValue(
                "config.details.ports",
                {
                    ...lbDetails?.ports,
                    [Number(newPort)]: lbDetails?.default,
                },
                { shouldDirty: true }
            );
        }

        nav(
            generateDialogLink("environment-lb-manage", {
                "dialog-tab": "controllers",
                "dialog-controllers-port-idx": newPort,
            })
        );
    };

    const isValidPort = Number(port) < 65535;

    return (
        <div className="flex gap-2">
            <TextInput
                className={classNames("border-cycle-gray/20 flex-grow")}
                placeholder="Port #"
                type="number"
                value={port}
                onChange={(e) => setPort(e.target.value)}
                disabled={disabled}
            />
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={modifyAccessFn("environments-services-manage")}
            >
                <Button
                    flavor="primary"
                    disabled={
                        !custom ||
                        port === undefined ||
                        port === "" ||
                        !isValidPort
                    }
                    type="button"
                    icon={faPlus}
                    onClick={() => {
                        if (!port) return;
                        return onSubmit(port);
                    }}
                >
                    Add Port
                </Button>
            </AccessControlOverlay>
        </div>
    );
}
