import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    Account,
    UpdateAccountApiArg,
    useUpdateAccountMutation,
} from "~/services/cycle";
import { useForm } from "react-hook-form";
import {
    Checkbox,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faUserLock } from "@fortawesome/pro-solid-svg-icons";
import { useEffect } from "react";
import { handleSubmitError } from "~/components/forms/util";

type SecuritySettingsProps = {
    account?: Account;
};

export function SecuritySettings({ account }: SecuritySettingsProps) {
    const form = useForm<UpdateAccountApiArg["body"]>({
        defaultValues: {
            allow_support_login: account?.allow_support_login,
        },
    });
    const {
        register,
        formState: { isDirty, isSubmitting },
    } = form;
    const [updateAccount] = useUpdateAccountMutation();
    const onSubmit = (data: UpdateAccountApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return updateAccount({
            body: data,
        })
            .unwrap()
            .then(
                () => form.reset((formValues) => ({ ...formValues })),
                handleSubmitError(form.setError)
            );
    };
    useEffect(() => {
        form.setValue("allow_support_login", account?.allow_support_login);
    }, [account]);

    return (
        <Panel>
            <PanelTitle title="Security Settings" />
            <PanelContent>
                <div>
                    Authorize Cycle employees to access your account to assist
                    with troubleshooting or management. Access can be revoked at
                    any time.
                </div>
                <div className="mb-4">
                    <strong>
                        Cycle employees will never ask for your password.
                    </strong>
                </div>

                <RhfFormProvider {...form} className="!block">
                    <RhfFormField
                        label="authorize cycle support"
                        name="support"
                    >
                        <Checkbox {...register("allow_support_login")} />
                    </RhfFormField>
                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <PushAndHoldButton
                            icon={faUserLock}
                            type="button"
                            onClick={form.handleSubmit(onSubmit)}
                            flavor="primary"
                            isLoading={isSubmitting}
                            disabled={!isDirty}
                        >
                            Update Security Settings
                        </PushAndHoldButton>
                    </PanelFooter>
                </RhfFormProvider>
            </PanelContent>
        </Panel>
    );
}
