import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { StackForm } from "../../stacks/form/UpdateStackForm";
import { CreateStackForm } from "./form/CreateStackForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function CreateStackDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="Create Stack"
            isOpen={isDialogOpen("stack-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <CreateStackForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
