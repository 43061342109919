import { useFormContext, useWatch } from "react-hook-form";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { LoadBalancerFormValues } from "../form";
import {
    useGenerateAggregatedMetricsQuery,
    V1LbConfig,
} from "~/services/cycle";
import { useContext, useEffect, useMemo } from "react";
import {
    DialogSubNavTab,
    SideNavTabList,
} from "@cycleplatform/ui/components/page/tabs";
import { getStandardPortName } from "~/util/loadbalancer";
import { getLbControllersQuery } from "../../util/getLbControllersQuery";
import { LoadBalancerDialogContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { generateIdxToPortMap } from "./form/util";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export function PortList({
    search,
    setIdxToPortMap,
}: {
    search: string;
    setIdxToPortMap: (value: Record<string, number>) => void;
}) {
    const hasCap = useCapability("environments-services-manage");

    return (
        <SideNavTabList className="!px-0 pt-0">
            {hasCap instanceof Error ? (
                // Generate list from aggregates if user does not have manage
                <RestrictedPortList
                    search={search}
                    setIdxToPortMap={setIdxToPortMap}
                />
            ) : (
                // Generate list from form if user has manage acces
                <PermissionedPortList
                    search={search}
                    setIdxToPortMap={setIdxToPortMap}
                />
            )}
        </SideNavTabList>
    );
}

function RestrictedPortList({
    search,
    setIdxToPortMap,
}: {
    search: string;
    setIdxToPortMap: (value: Record<string, number>) => void;
}) {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortIdx = params["dialog-controllers-port-idx"];

    const { environment } = useContext(LoadBalancerDialogContext);
    const nav = useNavigate();

    const { data: telemetry } = useGenerateAggregatedMetricsQuery(
        getLbControllersQuery(environment?.id),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const controllers: string[] = useMemo(() => {
        const c = telemetry?.data?.[0]?.controllers;

        if (!c || !Array.isArray(c)) {
            return [];
        }

        return [...c]
            ?.sort((a: string, b: string) => {
                return Number(a) - Number(b);
            })
            .filter((c) => c.includes(search));
    }, [telemetry?.data[0], search]);

    useEffect(() => {
        setIdxToPortMap(
            controllers.reduce((acc, cur, idx) => {
                return { ...acc, [idx]: cur };
            }, {})
        );
    }, [controllers]);

    useEffect(() => {
        if (!controllers || controllers.length === 0) {
            return;
        }
        if (!activePortIdx) {
            nav(
                generateDialogLink("environment-lb-manage", {
                    "dialog-tab": "controllers",
                    "dialog-controllers-port-idx": "0",
                    "dialog-controllers-subsection":
                        params["dialog-controllers-subsection"] || "overview",
                })
            );
        }
    }, [controllers, params]);

    return (
        <>
            {controllers.map((p, idx) => {
                return (
                    <DialogSubNavTab
                        className="w-full"
                        key={p}
                        title={`Port ${p} ${
                            getStandardPortName(Number(p)) || ""
                        }`}
                        to={generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "controllers",
                            "dialog-controllers-port-idx": `${idx}`,
                        })}
                        isActive={
                            params["dialog-controllers-port-idx"] === `${idx}`
                        }
                    />
                );
            })}
        </>
    );
}

function PermissionedPortList({
    search,
    setIdxToPortMap,
}: {
    search: string;
    setIdxToPortMap: (value: Record<string, number>) => void;
}) {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const {
        control,
        formState: { errors },
    } = useFormContext<LoadBalancerFormValues>();
    const nav = useNavigate();
    const activePortIdx = params["dialog-controllers-port-idx"];
    const details = useWatch({ name: "config.details", control }) as V1LbConfig;

    useEffect(() => {
        setIdxToPortMap(generateIdxToPortMap(details?.controllers));
    }, [details?.controllers]);

    const errorDetails = errors?.config?.details || ({} as V1LbConfig);
    const erroredControllers =
        "controllers" in errorDetails
            ? Object.keys(errorDetails.controllers)
            : [];

    const controllers = useMemo(
        () =>
            details?.controllers?.filter((c) =>
                c.port.toString().includes(search)
            ),
        [search, details?.controllers]
    );

    useEffect(() => {
        if (!controllers || controllers.length === 0) {
            return;
        }
        if (!activePortIdx) {
            nav(
                generateDialogLink("environment-lb-manage", {
                    "dialog-tab": "controllers",
                    "dialog-controllers-port-idx": "0",
                    "dialog-controllers-subsection":
                        params["dialog-controllers-subsection"] || "overview",
                })
            );
        }
    }, [controllers, params]);

    return (
        <>
            {controllers?.map((p, idx) => {
                const port = p.port;
                return (
                    <DialogSubNavTab
                        isError={erroredControllers.includes(`${idx}`)}
                        className="w-full"
                        key={`controller-link-${idx}`}
                        title={`Port ${port} ${
                            getStandardPortName(port) || ""
                        }`}
                        to={generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "controllers",
                            "dialog-controllers-port-idx": `${idx}`,
                        })}
                        isActive={
                            params["dialog-controllers-port-idx"] === `${idx}`
                        }
                    />
                );
            })}
        </>
    );
}
