import { useState } from "react";
import { FieldArrayWithId } from "react-hook-form";

export function useDraggableFieldArray({
    fieldArr,
    onMove,
    fieldArrayId,
}: {
    fieldArr: FieldArrayWithId<any>[];
    onMove: (prevIdx: number, newIdx: number) => void;
    fieldArrayId: string;
}) {
    const [dragItem, setDragItem] = useState<string | null>(null);
    const [dragOverItem, setDragOverItem] = useState<string | null>(null);
    const [activeFieldArrayId, setActiveFieldArrayId] = useState<string | null>(
        null
    );

    const dragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if ("id" in e.target && typeof e.target.id === "string") {
            setDragItem(e.target.id);
            setActiveFieldArrayId(fieldArrayId);
        }
    };

    const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setDragOverItem(e.currentTarget.id);
    };

    const drop = () => {
        if (!dragItem || !dragOverItem) {
            setDragItem(null);
            setDragOverItem(null);
            setActiveFieldArrayId(null);
            return;
        }

        // find index of dragItem and dragOverItem
        const dragItemIdx = fieldArr.findIndex((i) => i.id === dragItem);
        const dragOverItemIdx = fieldArr.findIndex(
            (i) => i.id === dragOverItem
        );

        onMove(dragItemIdx, dragOverItemIdx);

        setDragItem(null);
        setDragOverItem(null);
        setActiveFieldArrayId(null);
    };

    return {
        drop,
        dragStart,
        dragEnter,
        dragOverItem,
        dragItem,
        fieldArrayId,
        activeFieldArrayId,
    };
}
