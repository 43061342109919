import { useCallback, useRef } from "react";

export function useSmoothScroll(block?: ScrollLogicalPosition) {
    const ref = useRef<HTMLDivElement>(null);

    const scrollIntoView = useCallback(() => {
        return ref.current?.scrollIntoView({
            behavior: "smooth",
            block: block || "start",
            inline: "start",
        });
    }, [ref]);

    return { ref, scrollIntoView };
}
