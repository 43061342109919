import { useForm, useWatch } from "react-hook-form";
import {
    CreateScopedVariableApiArg,
    ScopedVariable,
    useCreateScopedVariableMutation,
    useDeleteScopedVariableMutation,
    useUpdateScopedVariableMutation,
} from "~/services/cycle";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import {
    PushAndHoldButton,
    LoaderButton,
} from "@cycleplatform/ui/components/buttons";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { ScopedVariablesGeneral } from "./sections/ScopedVariablesGeneral";
import { ScopedVariablesScope } from "./sections/ScopedVariablesScope";
import { ScopedVariablesAccess } from "./sections/ScopedVariablesAccess";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { useKeepFormCurrent } from "~/components/common/forms";
import { ScopedVariablesRaw } from "./sections/ScopedVariablesRaw";
import { ScopedVariablesUrl } from "./sections/ScopedVariablesUrl";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type ScopedVariableFormProps = {
    closeDialog: () => void;
    scopedVariable?: ScopedVariable;
    environmentId?: string;
};

export type ScopedVariableFormSubmitType =
    CreateScopedVariableApiArg["body"] & {
        scopedVariableId?: string;
        dirtyField?: string;
    };

const getDefaultValues = (sv?: ScopedVariable) => {
    return JSON.parse(
        JSON.stringify({
            identifier: sv?.identifier || "",
            scope: sv?.scope || {
                containers: {
                    global: false,
                    ids: [],
                    identifiers: [],
                },
            },
            access: sv?.access || {},
            source: {
                type: sv?.source?.type || "raw",
                details: sv?.source?.details || {
                    value: "",
                    blob: false,
                },
            } as ScopedVariableFormSubmitType["source"],
        })
    );
};

export function ScopedVariableForm({
    closeDialog,
    scopedVariable,
    environmentId,
}: ScopedVariableFormProps) {
    const isEdit = !!scopedVariable;

    const form = useForm<ScopedVariableFormSubmitType>({
        defaultValues: getDefaultValues(scopedVariable),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, scopedVariable, (sv) => getDefaultValues(sv));

    const {
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updateScopedVariable] = useUpdateScopedVariableMutation();
    const [deleteScopedVariableById] = useDeleteScopedVariableMutation();
    const [createScopedVariable] = useCreateScopedVariableMutation();
    const source = useWatch({ name: "source", control });

    const onSubmit = (data: ScopedVariableFormSubmitType) => {
        if (!isDirty) {
            return;
        }

        if (isEdit) {
            return updateScopedVariable({
                environmentId: environmentId || "",
                scopedVariableId: scopedVariable?.id || "",
                body: {
                    identifier: data.identifier,
                    access: data.access,
                    scope: data.scope,
                    source: {
                        ...data.source,
                        type: data.source.type.split("-")[0],
                    } as CreateScopedVariableApiArg["body"]["source"],
                },
            })
                .unwrap()
                .then(() => closeDialog())
                .catch(handleSubmitError(form.setError));
        } else {
            return createScopedVariable({
                environmentId: environmentId || "",
                body: {
                    identifier: data.identifier || "",
                    access: data.access,
                    scope: data.scope,
                    source: {
                        ...data.source,
                        type: data.source.type.split("-")[0],
                    } as CreateScopedVariableApiArg["body"]["source"],
                },
            })
                .unwrap()
                .then(() => closeDialog())
                .catch(handleSubmitError(form.setError));
        }
    };

    const onDelete = () => {
        deleteScopedVariableById({
            environmentId: environmentId || "",
            scopedVariableId: scopedVariable?.id || "",
        })
            .unwrap()
            .then(() => closeDialog())
            .catch(handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form}>
            <div>
                <ScopedVariablesGeneral />

                {source.type === "raw" ? (
                    <ScopedVariablesRaw />
                ) : (
                    <ScopedVariablesUrl />
                )}

                <ScopedVariablesScope environmentId={environmentId} />
                <ScopedVariablesAccess />
            </div>
            <DialogFooter className="justify-between">
                <div>
                    {isEdit && (
                        <PushAndHoldButton
                            flavor="discard"
                            type="button"
                            onClick={() => onDelete()}
                            icon={faTrash}
                        >
                            Delete Variable
                        </PushAndHoldButton>
                    )}
                </div>
                <RhfGlobalFormError />
                <LoaderButton
                    flavor="primary"
                    type="button"
                    onClick={form.handleSubmit(onSubmit)}
                    icon={faPlus}
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                >
                    {`${isEdit ? "Update" : "Create"} Variable`}
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
