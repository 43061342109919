import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { ChangeHubPlanForm } from "./form/ChangeHubPlanForm";

export function ChangeHubPlanDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="Change Hub Plan"
            isOpen={isDialogOpen("change-hub-plan")}
            onClose={() => nav(clearDialogParams())}
        >
            <ChangeHubPlanForm />
        </StyledFormDialog>
    );
}
