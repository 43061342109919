import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import {
    Checkbox,
    RhfDataSizeInput,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";

export function NetworkThroughputThreshold({
    thresholdIdx,
}: {
    thresholdIdx: number;
}) {
    const { register, control } = useFormContext<ContainerConfigFormData>();

    return (
        <div className="flex gap-16">
            <div className="shrink">
                <RhfFormField label="private" name="private">
                    <Checkbox
                        {...register(
                            `config.scale.thresholds.${thresholdIdx}.details.private`
                        )}
                    />
                </RhfFormField>
            </div>

            <RhfFormField label="bandwidth" name="bandwidth">
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <RhfDataSizeInput {...field} />
                    )}
                    control={control}
                    name={`config.scale.thresholds.${thresholdIdx}.details.bandwidth`}
                />
            </RhfFormField>
        </div>
    );
}
