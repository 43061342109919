import classNames from "classnames";
import { PageContent } from "../page";

type DialogPageContentProps = {
    children: React.ReactNode;
    className?: string;
};

export function DialogPageContent({
    children,
    className,
}: DialogPageContentProps) {
    return (
        <PageContent
            className={classNames(
                className,
                "bg-cycle-white-accent dark:bg-cycle-black-accent h-full w-full flex-1 overflow-y-auto !px-4 py-4"
            )}
        >
            {children}
        </PageContent>
    );
}
