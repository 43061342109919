import {
    Checkbox,
    EnableSectionInline,
    FormField,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { Controller, useFormContext } from "react-hook-form";
import {
    PrivilegedKernelCapabilities,
    BaseKernelCapabilities,
} from "~/modules/containers/schemas";
import { ContainerConfigFormData } from "../types";
import { useState } from "react";
import classNames from "classnames";

export function KernelCapsInput() {
    const { control, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.runtime.capabilities") !== undefined
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField
                    help="Enable custom kernel capabilities"
                    className="!w-1/4"
                    label="Customize"
                >
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (ev.target.checked) {
                                setValue(
                                    "config.runtime.capabilities",
                                    BaseKernelCapabilities.options,
                                    { shouldDirty: true }
                                );
                            } else {
                                setValue(
                                    "config.runtime.capabilities",
                                    undefined,
                                    { shouldDirty: true }
                                );
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <RhfFormField
                    label="Kernel Capabilities"
                    name={"config.runtime.capabilities"}
                    className={classNames("w-full")}
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => {
                            const value = field.value
                                ? [...new Set([...field.value])]
                                : BaseKernelCapabilities.options;

                            return (
                                <MultiSelectInput
                                    {...field}
                                    value={value}
                                    disabled={!isCustomized}
                                    placeholder="No extra kernel capabilities enabled."
                                    options={
                                        PrivilegedKernelCapabilities.options
                                    }
                                    formatOption={(o) => (
                                        <FormattedOption label={o} />
                                    )}
                                    isOptionValid={(o) =>
                                        !BaseKernelCapabilities.options.includes(
                                            o as "CAP_CHOWN"
                                        )
                                    }
                                />
                            );
                        }}
                        control={control}
                        shouldUnregister
                        name={"config.runtime.capabilities"}
                    />
                </RhfFormField>
            ) : (
                <FormField
                    label="Kernel Capabilities"
                    className={classNames("w-full")}
                >
                    <MultiSelectInput
                        value={BaseKernelCapabilities.options}
                        disabled={true}
                        isOptionValid={(o) =>
                            !BaseKernelCapabilities.options.includes(
                                o as "CAP_CHOWN"
                            )
                        }
                        formatOption={(o) => <FormattedOption label={o} />}
                    />
                </FormField>
            )}
        </EnableSectionInline>
    );
}
