import classNames from "classnames";

export function DialogContent({ children }: { children: React.ReactNode }) {
    return (
        <section
            className={classNames(
                "h-full !rounded-b-lg bg-white",
                "dark:bg-cycle-black-accent  dark:text-white",
                "relative "
            )}
        >
            <div
                className={classNames("h-full w-full  !rounded-b-lg pt-4 pb-4")}
            >
                {children}
            </div>
        </section>
    );
}
