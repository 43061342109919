import {
    CreatePipelineApiArg,
    useCreatePipelineMutation,
} from "~/services/cycle";
import { useForm, useWatch } from "react-hook-form";
import { faCode, faPlus, faCodeSimple } from "@fortawesome/pro-solid-svg-icons";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { OptionButton } from "@cycleplatform/ui/components/options";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
    getAclDefaultValues,
} from "~/components/common/acl";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type PipelineCreateFormType = Omit<CreatePipelineApiArg["body"], "acl"> & {
    acl: AclForm;
};
export function PipelineCreateForm() {
    const form = useForm<PipelineCreateFormType>({
        defaultValues: {
            name: "",
            dynamic: false,
            acl: getAclDefaultValues(undefined),
        },
        ...rhfConfig,
    });

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createPipeline] = useCreatePipelineMutation();

    const nav = useNavigate();
    const dynamic = useWatch({ name: "dynamic", control });
    const onCreate = (data: PipelineCreateFormType) => {
        if (!isDirty) {
            return;
        }
        const submitBody = {
            ...data,
            ...createAclSubmitBody(data.acl, "create"),
        };

        return createPipeline({
            body: submitBody,
        })
            .unwrap()
            .then((d) => {
                nav({ pathname: `/pipelines/${d?.data?.id}`, search: "" });
            }, handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onCreate)}>
            <RhfFormField label="pipeline name" name="name" required>
                <TextInput {...register("name", { ...required() })} />
            </RhfFormField>
            <div className="flex gap-2 py-4">
                <OptionButton
                    onClick={() => setValue("dynamic", false)}
                    title="Standard"
                    description="Statically defined pipeline specification."
                    isActive={!dynamic}
                    icon={faCodeSimple}
                />

                <OptionButton
                    onClick={() => setValue("dynamic", true)}
                    title="Dynamic"
                    description="Pipeline enhanced with dynamic variable support."
                    isActive={!!dynamic}
                    icon={faCode}
                />
            </div>

            <ResourceAclCreate resourceType="pipeline" />

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onCreate)}
                    icon={faPlus}
                    flavor="primary"
                    disabled={!isDirty}
                >
                    Create
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
