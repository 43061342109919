import CryptoJS from "crypto-js";

export interface DecryptedVariableState {
    key: string;
    error: boolean;
    unlocked: boolean;
    iv?: string;
    generatedKey?: string;
    decrypted?: string;
}

export const decrypt = (encrypted: string, key: string) => {
    const bytes = CryptoJS.AES.decrypt(encrypted, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return decrypted;
};

export type EncryptResult = {
    ivHex: string;
    keyHex: string;
    secret: string;
};

export const encrypt = (details: { secret: string; key: string }) => {
    const encrypted = CryptoJS.AES.encrypt(details.secret, details.key);

    const iv = encrypted.iv;
    const key = encrypted.key;

    const ivHex = CryptoJS.enc.Hex.stringify(iv);
    const keyHex = CryptoJS.enc.Hex.stringify(key);
    const secret = encrypted.toString();

    return { secret, ivHex, keyHex };
};
