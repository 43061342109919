import {
    TextInput,
    RhfFormField,
    FormSectionHeader,
    FormSection,
    Checkbox,
    TextAreaInput,
    RhfEnableDisableFormSection,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import classNames from "classnames";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import {
    addExpandedIdentifier,
    getExpandedIdentifier,
    removeExpandedIdentifier,
    advancedExpandedParam,
} from "../../../../expandedParamHelpers";
import { PanelContent } from "@cycleplatform/ui/components/panels";
import { LoadBalancerFormValues } from "../../../../../../form";

export function V1RouterAdvanced({
    baseRoute,
    idx,
    portIdx,
}: {
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
    portIdx: string;
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { register, control, setValue, getValues } =
        useFormContext<LoadBalancerFormValues>();
    const [tlsExpanded, setTlsExpanded] = useState(
        !!getValues(`${baseRoute}.${idx}.config.tls`)
    );
    const tls = useWatch({ name: `${baseRoute}.${idx}.config.tls`, control });

    const expandedIdentifier = getExpandedIdentifier(portIdx, idx);

    const advancedExpandedArr =
        searchParams.get(advancedExpandedParam)?.split(",") || [];

    const [advancedExpanded, setAdvancedExpanded] = useState(
        advancedExpandedArr.includes(expandedIdentifier)
    );

    const onExpandClick = () => {
        const sp = new URLSearchParams(searchParams);

        if (advancedExpanded) {
            // remove identifier from array if closing action
            const newArray = removeExpandedIdentifier(
                advancedExpandedArr,
                expandedIdentifier
            );

            if (newArray.length) {
                sp.set(advancedExpandedParam, newArray);
            } else {
                sp.delete(advancedExpandedParam);
            }
        } else {
            // add identifier to array if opening action
            sp.set(
                advancedExpandedParam,
                addExpandedIdentifier(advancedExpandedArr, expandedIdentifier)
            );
        }
        setSearchParams(sp);
        setAdvancedExpanded(!advancedExpanded);
    };

    return (
        <>
            <FormSectionHeader header="Advanced">
                <div className="flex grow items-center justify-end pl-4">
                    <div className="flex items-center gap-8">
                        <button type="button" onClick={() => onExpandClick()}>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className={classNames(
                                    !advancedExpanded ? "-rotate-90" : ""
                                )}
                            />
                        </button>
                    </div>
                </div>
            </FormSectionHeader>
            <FormSection
                className={classNames(advancedExpanded ? "block" : "hidden")}
            >
                <RhfEnableDisableFormSection
                    header={"TLS"}
                    isExpanded={!!tlsExpanded}
                    onToggle={(v) => {
                        if (v) {
                            setValue(
                                `${baseRoute}.${idx}.config.tls`,
                                tls || {},
                                { shouldDirty: true }
                            );
                        } else {
                            setValue(
                                `${baseRoute}.${idx}.config.tls`,
                                undefined,
                                {
                                    shouldDirty: true,
                                }
                            );
                        }
                        setTlsExpanded(v);
                    }}
                >
                    {(isExpanded) => (
                        <>
                            {isExpanded && (
                                <div>
                                    <div className="flex w-full gap-4">
                                        <div className="w-1/2">
                                            <PanelContent className="h-full">
                                                <FormSectionHeader header="General" />
                                                <RhfFormField
                                                    label="Server Name"
                                                    name={`${baseRoute}.${idx}.config.tls.server_name`}
                                                    className="w-full"
                                                >
                                                    <TextInput
                                                        {...register(
                                                            `${baseRoute}.${idx}.config.tls.server_name`,
                                                            {
                                                                shouldUnregister:
                                                                    true,
                                                            }
                                                        )}
                                                    />
                                                </RhfFormField>
                                                <RhfFormField
                                                    label="Allow Insecure"
                                                    name={`${baseRoute}.${idx}.config.tls.allow_insecure`}
                                                    help="Accept TLS traffic with invalid cert. Designed for testing - not recommended for production environments."
                                                >
                                                    <Checkbox
                                                        {...register(
                                                            `${baseRoute}.${idx}.config.tls.allow_insecure`,
                                                            {
                                                                shouldUnregister:
                                                                    true,
                                                            }
                                                        )}
                                                    />
                                                </RhfFormField>
                                            </PanelContent>
                                        </div>
                                        <div className="w-1/2">
                                            <PanelContent className="h-full">
                                                <FormSectionHeader header="Mutual TLS (mTLS)" />
                                                <RhfFormField
                                                    label="Client Auth"
                                                    name={`${baseRoute}.${idx}.config.tls.client_auth`}
                                                    className="w-full"
                                                >
                                                    <Controller
                                                        render={({
                                                            field: {
                                                                ref: _ref,
                                                                ...field
                                                            },
                                                        }) => (
                                                            <BasicSelect
                                                                {...field}
                                                                options={[
                                                                    {
                                                                        label: "None",
                                                                        value: "none",
                                                                        detail: "Certificate not required or requested",
                                                                    },
                                                                    {
                                                                        label: "Request",
                                                                        value: "request",
                                                                        detail: "Requested to use a certificate",
                                                                    },
                                                                    {
                                                                        label: "Require",
                                                                        value: "require",
                                                                        detail: "Certificate must be sent",
                                                                    },
                                                                    {
                                                                        label: "Require-Verify",
                                                                        value: "require-verify",
                                                                        detail: "Must have a valid certificate",
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                        shouldUnregister
                                                        control={control}
                                                        name={`${baseRoute}.${idx}.config.tls.client_auth`}
                                                    />
                                                </RhfFormField>
                                                <RhfFormField
                                                    label="Client Cert Auth"
                                                    name={`${baseRoute}.${idx}.config.tls.client_cert_auth`}
                                                    className="w-full"
                                                >
                                                    <TextAreaInput
                                                        rows={3}
                                                        {...register(
                                                            `${baseRoute}.${idx}.config.tls.client_cert_auth`,
                                                            {
                                                                shouldUnregister:
                                                                    true,
                                                                setValueAs: (
                                                                    v
                                                                ) =>
                                                                    v?.trim() ===
                                                                    ""
                                                                        ? null
                                                                        : v,
                                                            }
                                                        )}
                                                    />
                                                </RhfFormField>
                                            </PanelContent>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </RhfEnableDisableFormSection>
            </FormSection>
        </>
    );
}
