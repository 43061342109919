import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    DialogSubNavTab,
    SideNavTabList,
} from "@cycleplatform/ui/components/page/tabs";
import { useForm, useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    type HaProxyConfig,
    LoadBalancerConfig,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import { getStandardPortName } from "~/util/loadbalancer";
import { PortForm } from "./HaProxyPortForm";
import { faEdit, faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    FormFieldLabel,
} from "@cycleplatform/ui/components/forms";
import { useContext, useEffect, useMemo, useState } from "react";
import { LoadBalancerFormValues, getDefaultLbFormVals } from "../form";
import { AddPort } from "./AddPort";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { useKeepFormCurrent } from "~/components/common/forms";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { FormToggle } from "@cycleplatform/ui/components/forms";
import {
    DialogPageBody,
    DialogPageHeader,
} from "@cycleplatform/ui/components/dialog";
import { DialogResourceList } from "~/components/layout/resources";
import { PageAside } from "@cycleplatform/ui/components/page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { immutableKeyDelete } from "@cycleplatform/core/util";
import { NavIcons } from "~/components/layout/NavIcons";
import { AccessControlOverlay } from "~/components/common/buttons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { LoadBalancerDialogContext } from "../../context";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";

export function HaProxyConfig() {
    const { environment, service } = useContext(LoadBalancerDialogContext);

    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const nav = useNavigate();
    const activePort = params["dialog-port"] || "default";
    const form = useForm<LoadBalancerFormValues>({
        defaultValues: getDefaultLbFormVals(service),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, service, (lb) => getDefaultLbFormVals(lb));

    const {
        handleSubmit,
        watch,
        setError,
        reset,
        setValue,
        formState: { isSubmitting, isDirty },
    } = form;

    const config = watch("config");
    const custom = watch("custom");
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();

    const onSubmit = (data: LoadBalancerFormValues) => {
        if (!isDirty) {
            return;
        }

        const formattedData = {
            config: {
                ...data.config,
                // strip out details if not custom.
                details: data.custom ? data.config?.details : null,
            } as LoadBalancerConfig,
        };

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: formattedData,
            },
        })
            .unwrap()
            .then(
                () => reset((formValues) => ({ ...formValues })),
                handleSubmitError(setError)
            );
    };

    useEffect(() => {
        if (
            !params["dialog-port"] ||
            (details?.ports &&
                !Object.keys(details?.ports).includes(params["dialog-port"]))
        ) {
            nav(
                generateDialogLink("environment-lb-manage", {
                    "dialog-tab": "controllers",
                    "dialog-port": "default",
                })
            );
        }
    }, [params["dialog-port"]]);
    const [search, setSearch] = useState("");
    // We can assert the HaProxyConfig type because we conditionally render forms in parent
    const details = config?.details as HaProxyConfig;

    const [searchParams] = useSearchParams();

    const hasCap = useCapability("environments-services-manage");
    if (hasCap instanceof Error) {
        return (
            <DialogPageBody className="w-full p-4">
                <EmptyResource
                    className="flex h-60 items-center justify-center border-none"
                    icon={NavIcons["lbController"]}
                    title={`Load Balancer Controllers Restricted`}
                >
                    <p className="text-center">
                        Managing load balancer controllers requires the
                        "environment-services-manage" capability.
                    </p>
                </EmptyResource>
            </DialogPageBody>
        );
    }

    return (
        <RhfFormProvider {...form} className="h-full">
            <AccessControlledSection
                aclResource={environment}
                verifyFn={modifyAccessFn("environments-services-manage")}
                heightFull
            >
                <DialogPageBody className="!p-0">
                    <PageAside className="relative h-full !w-auto border-r bg-white dark:bg-black">
                        <DialogResourceList>
                            <div className="pb-2 pl-1 pr-4">
                                <TextInput
                                    placeholder="Search Ports"
                                    onChange={(v) => setSearch(v.target.value)}
                                    value={search}
                                    prefix={<FontAwesomeIcon icon={faSearch} />}
                                    className="!mb-0"
                                />
                            </div>
                            <div className="flex items-center justify-between border-t border-b px-2 py-4">
                                <FormFieldLabel
                                    label={"Enable Custom Config"}
                                />
                                <FormToggle
                                    disabled={
                                        service?.service?.config?.type ===
                                            "default" ||
                                        service?.service?.config === null
                                    }
                                    value={custom}
                                    onChange={(on) => {
                                        setValue("custom", on, {
                                            shouldDirty: true,
                                        });

                                        if (!on) {
                                            // show the user the default values even if we're not
                                            // customizing.
                                            setValue(
                                                "config.details",
                                                service?.base_configs
                                                    ?.haproxy || null
                                            );
                                            return;
                                        }

                                        setValue(
                                            "config.details",
                                            service?.service?.config?.details ||
                                                service?.base_configs
                                                    ?.haproxy ||
                                                null
                                        );
                                    }}
                                />
                            </div>
                            <PortList search={search} />
                            <div
                                className={classNames(
                                    "bg-cycle-white absolute bottom-0 left-0 right-0 flex w-full justify-between gap-2 p-4 shadow-lg",
                                    "dark:bg-cycle-black"
                                )}
                            >
                                <AddPort />
                            </div>
                        </DialogResourceList>
                    </PageAside>

                    <div className="relative h-full w-full ">
                        <div className="w-full p-4 ">
                            <DialogPageHeader
                                title={
                                    activePort !== "default"
                                        ? `Port ${activePort} ${
                                              getStandardPortName(
                                                  parseInt(activePort, 10)
                                              ) || ""
                                          }`
                                        : "Default Config"
                                }
                                icon={NavIcons["lbController"]}
                            >
                                <Button
                                    type="button"
                                    flavor="discard"
                                    disabled={
                                        activePort === "default" || !custom
                                    }
                                    icon={faTimes}
                                    onClick={() => {
                                        if (
                                            !details ||
                                            !("default" in details)
                                        ) {
                                            return;
                                        }

                                        setValue(
                                            "config.details.ports",
                                            immutableKeyDelete(
                                                details.ports,
                                                activePort
                                            ),
                                            { shouldDirty: true }
                                        );

                                        const sp = new URLSearchParams(
                                            searchParams
                                        );
                                        sp.delete("dialog-port");
                                        nav({
                                            search: sp.toString(),
                                        });
                                    }}
                                >
                                    Delete Controller
                                </Button>
                            </DialogPageHeader>

                            <PortForm
                                baseRoute="config.details.default"
                                activePort={activePort}
                                port={"default"}
                                disabled={!custom}
                            />

                            {details?.ports &&
                                Object.keys(details.ports).map((p) => {
                                    return (
                                        <PortForm
                                            key={p}
                                            baseRoute={`config.details.ports.${p}`}
                                            activePort={activePort}
                                            port={p}
                                            disabled={!custom}
                                        />
                                    );
                                })}
                        </div>

                        <div className="dark:border-cycle-black-accent dark:bg-cycle-black absolute bottom-0 left-0 right-0 z-10 mt-auto flex w-full items-center justify-between border-t bg-white px-2 py-4">
                            <div>
                                <RhfGlobalFormError />
                            </div>
                            <AccessControlOverlay
                                aclResource={environment}
                                verifyFn={modifyAccessFn(
                                    "environments-services-manage"
                                )}
                            >
                                <PushAndHoldButton
                                    flavor="primary"
                                    type="button"
                                    onClick={handleSubmit(onSubmit)}
                                    icon={faEdit}
                                    isLoading={isSubmitting}
                                    disabled={!isDirty}
                                >
                                    Update Load Balancer
                                </PushAndHoldButton>
                            </AccessControlOverlay>
                        </div>
                    </div>
                </DialogPageBody>
            </AccessControlledSection>
        </RhfFormProvider>
    );
}

function PortList({ search }: { search: string }) {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const {
        watch,
        formState: { errors },
    } = useFormContext<LoadBalancerFormValues>();

    const details = watch("config.details") as HaProxyConfig;
    const errorDetails = (errors?.config?.details || {}) as HaProxyConfig;

    const erroredControllers =
        "controllers" in errorDetails ? Object.keys(errorDetails.ports) : [];

    const filteredPorts = useMemo(() => {
        return Object.keys(details?.ports || {})?.filter((c) =>
            c.toString().includes(search)
        );
    }, [search, details?.ports]);

    return (
        <SideNavTabList className="!px-0 pt-0">
            {search === "" ||
            "default config".includes(search.toLocaleLowerCase()) ? (
                <DialogSubNavTab
                    title="Default Config"
                    to={generateDialogLink("environment-lb-manage", {
                        "dialog-tab": "controllers",
                        "dialog-port": "default",
                    })}
                    isActive={params["dialog-port"] === "default"}
                />
            ) : null}
            {filteredPorts?.map((key) => {
                const port = key;
                return (
                    <DialogSubNavTab
                        isError={erroredControllers.includes(port.toString())}
                        className="w-full"
                        key={key}
                        title={`Port ${key} ${
                            getStandardPortName(parseInt(key, 10)) || ""
                        }`}
                        to={generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "controllers",
                            "dialog-port": key,
                        })}
                        isActive={params["dialog-port"] === key}
                    />
                );
            })}
        </SideNavTabList>
    );
}
