import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { AddServerForm } from "./AddServerForm";

export function AddServerDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"server-create">();
    const step = params["dialog-step"];
    const locationId = params["dialog-location-id"];
    const integrationId = params["dialog-integration-id"];
    const navigateTo = params["dialog-nav"];

    return (
        <StyledFormDialog
            title="Add Server"
            isOpen={isDialogOpen("server-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <AddServerForm
                step={step}
                locationId={locationId}
                integrationId={integrationId}
                navigateTo={navigateTo}
            />
        </StyledFormDialog>
    );
}
