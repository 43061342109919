import { createContext } from "react";
import { ComponentIncludes, PipelineRun } from "~/services/cycle";

type PipelineRunDialogContext = {
    run: PipelineRun | undefined;
    components: ComponentIncludes | undefined;
};
export const PipelineRunDialogContext = createContext<PipelineRunDialogContext>(
    {
        run: undefined,
        components: undefined,
    }
);
