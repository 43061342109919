import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";

type CoresSelectProps = {
    name: string;
    value?: string | null;
    disabled?: boolean;
    onBlur?: () => void;
    onChange: (value: string | undefined) => void;
};

export function CoresSelect({ ...props }: CoresSelectProps) {
    return (
        <MultiSelectInput
            {...props}
            onChange={(e) => {
                return props.onChange(e && e.length ? e.join(",") : "");
            }}
            value={props.value?.split(",").filter((v) => v !== "") || []}
            placeholder={`Add cores in the format "1" or "1-2"`}
            isCreatable={true}
            options={[]}
            creatablePlaceholder={getCreatePrompt}
            isCreateValueValid={testIsValidCore}
            formatDisplayValue={(v) => v}
            formatOption={(v) => v}
        />
    );
}

function getCreatePrompt(v: string) {
    const t = testIsValidCore(v);

    if (!t) {
        return "Please enter a valid core entry";
    }
    const [start, end] = v.split("-");

    if (end) {
        switch (end) {
            case "x":
                return `Pin cores ${start} through the maximum on the server`;
            default:
                return `Pin cores ${start} through ${end}`;
        }
    } else {
        return `Pin core ${start}`;
    }
}

function testIsValidCore(v: string): boolean {
    return /^(\d+(-\d+|-x)?,)*\d+(-\d+|-x)?$/.test(v);
}
