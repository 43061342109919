import { formatLoadBalancerInstanceName } from "@cycleplatform/core/modules/environments/loadbalancer/format";
import { formatBytesString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Meter } from "@cycleplatform/ui/components/progress";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import {
    Instance,
    useGenerateAggregatedMetricsQuery,
    useGetInstanceQuery,
    useGetInstancesQuery,
} from "~/services/cycle";
import { LatencyChart } from "./latency/LatencyChart";
import { LoadBalancerDialogContext } from "../../../../context";
import {
    LoadBalancerDestinationMetric,
    buildRouterMetricsQuery,
} from "./query";
import { CycleErrorBoundary } from "~/components/common/errors";
import { isFunctionContainer } from "@cycleplatform/core/modules/containers/config";
import { LatencyChartMetric, buildLatencyQuery } from "./latency/query";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type RoutersSectionProps = {
    port: number;
};

export function RoutersSection({ port }: RoutersSectionProps) {
    const { environment, lbContainer } = useContext(LoadBalancerDialogContext);
    const {
        data: routerMetricsResp,
        isLoading: isRouterMetricsLoading,
        error: routerMetricsErr,
    } = useGenerateAggregatedMetricsQuery(
        buildRouterMetricsQuery(environment?.id, port),
        {
            skip: !environment?.id || !port,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );
    const {
        data: latencyResp,
        isLoading: isLatencyLoading,
        error: latencyErr,
    } = useGenerateAggregatedMetricsQuery(
        buildLatencyQuery(environment?.id, port),
        { refetchOnFocus: false, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const { data: instances, error: instancesErr } = useGetInstancesQuery(
        {
            containerId: lbContainer?.id || "",
            sort: ["id"],
            include: ["locations"],
        },
        { skip: !lbContainer?.id, pollingInterval: 1000 * 60 * 1 }
    );

    if (instancesErr) {
        throw instancesErr;
    } else if (latencyErr) {
        throw latencyErr;
    } else if (routerMetricsErr) {
        throw routerMetricsErr;
    }

    const routerMetrics = routerMetricsResp?.data as
        | LoadBalancerDestinationMetric[]
        | undefined;

    // Reduce lookups for instance pairing with telemetry
    const instancesByInstanceId = useMemo(
        () =>
            instances?.data.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {} as Record<string, Instance>) || {},
        [instances]
    );

    const metricsByLoadBalancerInstanceId = useMemo(
        () =>
            routerMetrics?.reduce((acc, cur) => {
                if (!acc[cur.instance_id]) {
                    acc[cur.instance_id] = [];
                }
                acc[cur.instance_id]!.push(cur);
                return acc;
            }, {} as Record<string, LoadBalancerDestinationMetric[]>) || {},
        [routerMetrics]
    );

    const latencyMetrics = latencyResp?.data as
        | LatencyChartMetric[]
        | undefined;

    if (isRouterMetricsLoading || isLatencyLoading) {
        return (
            <Panel>
                <PanelContent stretch>
                    <PanelTitle title={undefined} />
                    <SkeletonTable />
                </PanelContent>
            </Panel>
        );
    }

    if (Object.keys(metricsByLoadBalancerInstanceId).length === 0) {
        return (
            <Panel>
                <PanelTitle title="Routers" />
                <PanelContent stretch>
                    <StyledDataTable>
                        <TableHeader />
                        <tbody>
                            <StyledTableRow>
                                <StyledCell colSpan={5}>
                                    <EmptyResource
                                        icon={NavIcons["telemetry"]}
                                        title="No Metrics"
                                        className="w-full border-none"
                                    />
                                </StyledCell>
                            </StyledTableRow>
                        </tbody>
                    </StyledDataTable>
                </PanelContent>
            </Panel>
        );
    }
    return (
        <>
            {port ? (
                <>
                    {Object.entries(metricsByLoadBalancerInstanceId).map(
                        ([lbInstanceId, routerMetrics], idx) => {
                            const lbInstance: Instance | undefined =
                                instancesByInstanceId[lbInstanceId];
                            const location =
                                instances?.includes?.locations?.[
                                    lbInstance?.location_id || ""
                                ];

                            if (!lbInstance) {
                                return null;
                            }

                            return (
                                <Panel
                                    className="w-full pb-4"
                                    key={lbInstance?.id || ""}
                                >
                                    <PanelTitle
                                        className="flex items-center justify-between"
                                        title={formatLoadBalancerInstanceName(
                                            lbInstance?.id || "",
                                            lbInstance?.provider?.vendor || "",
                                            location?.name || ""
                                        )}
                                    >
                                        {idx === 0 && (
                                            <div className="text-sm">
                                                Showing Last 24 Hours
                                            </div>
                                        )}
                                    </PanelTitle>

                                    <PanelContent stretch>
                                        <StyledDataTable>
                                            <TableHeader />
                                            <tbody>
                                                <CycleErrorBoundary>
                                                    {routerMetrics &&
                                                        routerMetrics.map(
                                                            (m) => (
                                                                <RouterRow
                                                                    key={`${m.instance_id}${m.destination_instance_id}`}
                                                                    metric={m}
                                                                    environmentId={
                                                                        environment?.id ||
                                                                        ""
                                                                    }
                                                                    latency={latencyMetrics?.find(
                                                                        (l) =>
                                                                            l.destination_instance_id ===
                                                                                m.destination_instance_id &&
                                                                            l.instance_id ===
                                                                                m.instance_id
                                                                    )}
                                                                />
                                                            )
                                                        )}
                                                </CycleErrorBoundary>
                                            </tbody>
                                        </StyledDataTable>
                                    </PanelContent>
                                </Panel>
                            );
                        }
                    )}
                </>
            ) : (
                <Panel>
                    <PanelTitle title={undefined} />
                    <PanelContent stretch>
                        <StyledDataTable>
                            <TableHeader />
                            <tbody>
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            </tbody>
                        </StyledDataTable>
                    </PanelContent>
                </Panel>
            )}
        </>
    );
}

function TableHeader() {
    return (
        <StyledTableHead>
            <StyledHeaderCell className="w-1/8 min-w-[20rem] ">
                Destination
            </StyledHeaderCell>
            <StyledHeaderCell className="w-1/5 text-center">
                Latency
            </StyledHeaderCell>

            <StyledHeaderCell className="w-1/6 text-center">
                Requests
            </StyledHeaderCell>

            <StyledHeaderCell className="min-w-[8rem]">
                Connections
            </StyledHeaderCell>
            <StyledHeaderCell className="w-1/8 min-w-[8rem] max-w-[12rem]">
                Success Rate
            </StyledHeaderCell>
        </StyledTableHead>
    );
}

function RouterRow({
    metric,
    environmentId,
    latency,
}: {
    environmentId: string;
    metric: LoadBalancerDestinationMetric;
    latency: LatencyChartMetric | undefined;
}) {
    const {
        data: instance,
        isLoading,
        error,
    } = useGetInstanceQuery({
        containerId: metric.destination_container_id,
        instanceId: metric.destination_instance_id,
        include: ["integrations", "locations", "containers"],
    });

    if (error) {
        throw error;
    }

    const location =
        instance?.includes?.locations?.[instance?.data?.location_id || ""];

    const container =
        instance?.includes?.containers?.[metric.destination_container_id];

    const theme = useAppSelector(selectAppliedTheme);

    return (
        <StyledTableRow>
            <StyledCell className="pr-2">
                <Link
                    to={generateDialogLink("container", {
                        "dialog-container-id": metric.destination_container_id,
                        "dialog-instance-id": metric.destination_instance_id,
                        "dialog-tab": "instances",
                    })}
                >
                    {instance?.data?.hostname}
                </Link>
                <div className="text-sm">
                    {formatLoadBalancerInstanceName(
                        metric.destination_instance_id,
                        instance?.data?.provider?.vendor || "",
                        location?.name || ""
                    )}
                </div>
            </StyledCell>
            <StyledCell>
                <div className="h-[4rem] w-full">
                    {container && !isFunctionContainer(container) ? (
                        <>
                            {latency ? (
                                <LatencyChart metric={latency} />
                            ) : (
                                <div className="flex h-full items-center justify-center">
                                    <Tooltip message="no requests in the last hour">
                                        <FontAwesomeIcon
                                            className="text-cycle-blue"
                                            icon={faInfoCircle}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex h-full items-center justify-center">
                            <Tooltip message="latency tracking unsupported on function containers">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </StyledCell>
            <StyledCell className="text-center">{metric.requests}</StyledCell>

            <StyledCell>
                <div className="text-sm">
                    {`${formatBytesString(
                        metric.transmitted_kb * 1024
                    )} Transmitted`}
                </div>
                <div className="text-sm">
                    {`${formatBytesString(
                        metric.received_kb * 1024
                    )}  Received`}{" "}
                </div>
            </StyledCell>

            <StyledCell>
                <Meter
                    text={`${metric.connections_success_percent} %`}
                    percent={metric.connections_success_percent}
                />
            </StyledCell>
        </StyledTableRow>
    );
}
