import { InstanceChartComponents } from "~/components/instances/telemetry/helpers";

/**
 * Build label map for instance charts in container telemetry
 */
export function generateInstanceLabelMap({
    instances,
    servers,
}: {
    instances: InstanceChartComponents["instances"];
    servers: InstanceChartComponents["servers"];
}) {
    return Object.values(instances).reduce((instanceIdMap, instance) => {
        return {
            ...instanceIdMap,
            [instance.id]: `${instance.hostname} (...${instance.id.slice(
                -4
            )}) - ${
                servers?.[instance.server_id || ""]?.hostname.split(".")?.[0]
            }`,
        };
    }, {} as Record<string, string>);
}
