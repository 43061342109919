import { isZeroTime } from "../../util";
import { components } from "../api/__generated";

export function isMigrationComplete(
    instance: components["schemas"]["Instance"]
) {
    return !!(
        instance?.migration &&
        instance.migration.to !== undefined &&
        !isZeroTime(instance.migration.completed || "")
    );
}

export function getServerPurgeTime(
    instance: components["schemas"]["Instance"]
) {
    return instance?.ready_state === "purge" &&
        instance?.stateful &&
        instance?.purge_time
        ? instance?.purge_time
        : null;
}

export function getInstanceState(instance: components["schemas"]["Instance"]) {
    const migrationCompleted = isMigrationComplete(instance);

    if (migrationCompleted && instance?.state.current !== "deleted") {
        return {
            ...instance.state,
            current: "migrated",
        };
    }

    return instance.state;
}

export function getTargetNetworkInterface<T extends { name: string }>(
    target: "public" | "private",
    interfaces: Array<T>
): T | undefined {
    return interfaces.find((i) => {
        if (target === "public") {
            return i.name.startsWith("i-pub");
        }

        if (target === "private") {
            return i.name.startsWith("i-pri");
        }
    });
}
