import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Container } from "~/services/cycle";
import { DeployGeneral } from "./sections/DeployGeneral";
import { DeployContraints } from "./sections/DeployConstraints";
import { DeployStatefulInstances } from "./sections/DeployStatefulInstances";
import { DeployStartupPolicy } from "./sections/DeployStartupPolicy";
import { DeployShutdownPolicy } from "./sections/DeployShutdownPolicy";
import { DeployRestartPolicy } from "./sections/DeployRestartPolicy";
import { DeployHealthCheck } from "./sections/DeployHealthCheck";
import { DeployTelemetry } from "./sections/DeployTelemetry";
import { DeployUpdatePolicy } from "./sections/DeployUpdatePolicy";
import { DeployFunction } from "./sections/DeployFunction";
import { isFunctionContainer } from "@cycleplatform/core/modules/containers/config";
import { ToggleFormSection } from "@cycleplatform/react-hook-form-components/components";
import { ContainerConfigFormData } from "../types";

type DeploymentSectionProps = {
    container?: Container;
};

export function DeploymentSection({ container }: DeploymentSectionProps) {
    const isFunction = container && isFunctionContainer(container);
    return (
        <>
            <Panel>
                <PanelTitle
                    title="Deployment"
                    className="flex justify-start px-4"
                />
                <PanelContent>
                    <DeployGeneral />
                    {isFunction ? (
                        <ToggleFormSection<ContainerConfigFormData>
                            header="Function"
                            field="config.deploy.function"
                        >
                            {() => <DeployFunction />}
                        </ToggleFormSection>
                    ) : null}

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Constraints"
                        field="config.deploy.constraints"
                    >
                        {() => <DeployContraints container={container} />}
                    </ToggleFormSection>

                    {container?.stateful ? (
                        <ToggleFormSection<ContainerConfigFormData>
                            header="Stateful Instances"
                            field="config.deploy.stateful"
                        >
                            {() => <DeployStatefulInstances />}
                        </ToggleFormSection>
                    ) : null}

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Startup Policy"
                        field="config.deploy.startup"
                    >
                        {() => <DeployStartupPolicy />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Shutdown Policy"
                        field="config.deploy.shutdown"
                    >
                        {() => <DeployShutdownPolicy />}
                    </ToggleFormSection>
                    <ToggleFormSection<ContainerConfigFormData>
                        header="Restart Policy"
                        field="config.deploy.restart"
                    >
                        {() => <DeployRestartPolicy />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Update Policy"
                        field="config.deploy.update"
                    >
                        {() => <DeployUpdatePolicy />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Health Check Policy"
                        field="config.deploy.health_check"
                    >
                        {() => <DeployHealthCheck />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Telemetry"
                        field="config.deploy.telemetry"
                    >
                        {() => <DeployTelemetry />}
                    </ToggleFormSection>
                </PanelContent>
            </Panel>
        </>
    );
}
