import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RHF_GLOBAL_ERROR,
    RhfEnableDisableFormSection,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import {
    CreateAutoScaleGroupApiArg,
    useCreateAutoScaleGroupMutation,
} from "~/services/cycle";
import { useNavigate } from "react-router-dom";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    shouldClose,
} from "~/components/dialogs/helpers";
import { CreateGroupFormType, getAutoScaleGroupDefaultValues } from "./util";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { ClusterSelect } from "../../clusters/ClusterSelect";
import {
    ResourceAclCreate,
    createAclSubmitBody,
} from "~/components/common/acl";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export function CreateGroupForm() {
    const nav = useNavigate();
    const form = useForm<CreateGroupFormType>({
        defaultValues: getAutoScaleGroupDefaultValues(),
        ...rhfConfig,
    });
    const {
        handleSubmit,
        register,
        control,
        unregister,
        formState: { isSubmitting },
    } = form;

    const [createAutoscaleGroup] = useCreateAutoScaleGroupMutation();

    const onSubmit = (data: CreateGroupFormType) => {
        const submitBody = {
            ...data,
            ...createAclSubmitBody(data.acl, "create"),
        };
        return createAutoscaleGroup({
            body: submitBody,
        })
            .unwrap()
            .then(
                (g) => nav(`/infrastructure/autoscaling/${g.data.id}`),
                handleSubmitError(form.setError, {
                    sourceOverride: { scale: RHF_GLOBAL_ERROR },
                })
            );
    };
    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <RhfFormField required label="name" name="name">
                    <TextInput
                        className="w-full"
                        {...register("name", { ...required() })}
                    />
                </RhfFormField>

                <RhfIdentifierInput
                    name={`identifier`}
                    label="Identifier"
                    required
                />
                <RhfFormField label="cluster" name="cluster" required>
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <ClusterSelect {...field} />
                        )}
                        rules={{ ...required() }}
                        control={control}
                        name="cluster"
                    />
                </RhfFormField>
                <RhfEnableDisableFormSection
                    header="Scale Up"
                    name={"sections.scaleUp"}
                    onToggle={(v) => {
                        if (!v) {
                            unregister("scale.up", { keepDirty: true });
                        }
                    }}
                >
                    {(isExpanded) => (
                        <>
                            {isExpanded && (
                                <div className="flex gap-4">
                                    <RhfFormField
                                        label="Maximum"
                                        name="scale.up.maximum"
                                        className="!w-1/2"
                                    >
                                        <TextInput
                                            className="w-full"
                                            type="number"
                                            defaultValue={10}
                                            {...register("scale.up.maximum", {
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </RhfFormField>
                                </div>
                            )}
                        </>
                    )}
                </RhfEnableDisableFormSection>

                <RhfEnableDisableFormSection
                    header="Scale Down"
                    name={"sections.scaleDown"}
                    onToggle={(v) => {
                        if (!v) {
                            unregister("scale.down", { keepDirty: true });
                        }
                    }}
                >
                    {(isExpanded) => (
                        <>
                            {isExpanded && (
                                <div className="flex gap-4">
                                    <RhfFormField
                                        label="Min TTL"
                                        name="scale.down.min_ttl"
                                    >
                                        <TextInput
                                            {...register("scale.down.min_ttl", {
                                                validate: {
                                                    ...isDurationString(),
                                                },
                                            })}
                                            defaultValue={"15m"}
                                        />
                                    </RhfFormField>

                                    <RhfFormField
                                        label="Inactivity Period"
                                        name="scale.down.inactivity_period"
                                    >
                                        <TextInput
                                            {...register(
                                                "scale.down.inactivity_period",
                                                {
                                                    validate: {
                                                        ...isDurationString(),
                                                    },
                                                }
                                            )}
                                            defaultValue={"15m"}
                                        />
                                    </RhfFormField>

                                    <RhfFormField
                                        label="Scale Down Method"
                                        name="scale.down.method"
                                    >
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => (
                                                <BasicSelect
                                                    {...field}
                                                    placeholder="Auto"
                                                    options={[
                                                        {
                                                            value: undefined,
                                                            label: "Auto",
                                                        },
                                                        {
                                                            value: "lifo",
                                                            label: "Last In, First Out (LIFO)",
                                                        },
                                                        {
                                                            value: "fifo",
                                                            label: "First In, First Out (FIFO)",
                                                        },
                                                    ]}
                                                />
                                            )}
                                            control={control}
                                            name="scale.down.method"
                                        />
                                    </RhfFormField>
                                </div>
                            )}
                        </>
                    )}
                </RhfEnableDisableFormSection>
                <ResourceAclCreate resourceType="auto-scaling group" />
            </div>

            <DialogFooter className="justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    isLoading={isSubmitting}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    icon={faPlus}
                >
                    Create Group
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
