import { AutoScaleGroup, CreateAutoScaleGroupApiArg } from "~/services/cycle";
import { AclForm, getAclDefaultValues } from "~/components/common/acl";

export type CreateGroupFormType = Omit<
    CreateAutoScaleGroupApiArg["body"],
    "acl"
> & {
    acl: AclForm;
};

/**
 * Function accepts AutoscaleGroup and returns API arg formatted group
 */
export function getAutoScaleGroupDefaultValues(group?: AutoScaleGroup) {
    return {
        name: group?.name || "",
        identifier: group?.identifier || "",
        cluster: group?.cluster || "",
        scale: group?.scale || {
            up: {
                maximum: 10,
            },
            down: {
                min_ttl: "15m",
                inactivity_period: "15m",
            },
        },
        infrastructure: group?.infrastructure || {
            models: [],
        },
        sections: {
            scaleUp: !!group?.scale?.up || true,
            scaleDown: !!group?.scale?.down || true,
        },
        acl: getAclDefaultValues(undefined),
    } as CreateGroupFormType;
}
