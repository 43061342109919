import {
    Checkbox,
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

export function DeployHealthCheck() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <RhfFormField
                label="command"
                name="config.deploy.health_check.command"
                required
                help={`To submit a script, wrap the script in " " to execute in shell`}
            >
                <TextInput
                    {...register("config.deploy.health_check.command", {
                        ...required("Command field is required"),
                    })}
                />
            </RhfFormField>
            <div className="flex gap-4">
                <RhfFormField
                    className="flex-grow"
                    label="retries"
                    name="config.deploy.health_check.retries"
                    required
                >
                    <TextInput
                        defaultValue={3}
                        type="number"
                        {...register("config.deploy.health_check.retries", {
                            valueAsNumber: true,
                            validate: {
                                greaterThan3: (v) =>
                                    (v !== undefined && v >= 3) ||
                                    "At least 3 retries are required",
                                lessThan100: (v) =>
                                    (v !== undefined && v <= 100) ||
                                    "Retries limited to 100 at most",
                            },
                        })}
                    />
                </RhfFormField>
                <RhfFormField
                    className="flex-grow"
                    label="delay"
                    help="This duration tells Cycle how long to wait before performing an initial health check"
                    name="config.deploy.health_check.delay"
                >
                    <TextInput
                        {...register("config.deploy.health_check.delay", {
                            validate: {
                                ...isDurationString(),
                            },
                        })}
                    />
                </RhfFormField>
                <RhfFormField
                    className="flex-grow"
                    label="interval"
                    name="config.deploy.health_check.interval"
                    required
                >
                    <TextInput
                        defaultValue={"10s"}
                        {...register("config.deploy.health_check.interval", {
                            ...required(),
                            validate: {
                                ...isDurationString(),
                            },
                        })}
                    />
                </RhfFormField>
                <RhfFormField
                    className="flex-grow"
                    label="timeout"
                    name="config.deploy.health_check.timeout"
                    required
                >
                    <TextInput
                        defaultValue={"30s"}
                        {...register("config.deploy.health_check.timeout", {
                            ...required(),
                            validate: {
                                ...isDurationString(),
                            },
                        })}
                    />
                </RhfFormField>
            </div>
            <RhfFormField
                label="restart on fail"
                name="config.deploy.health_check.restart"
            >
                <Checkbox {...register("config.deploy.health_check.restart")} />
            </RhfFormField>
        </>
    );
}
