import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { ConfigureStepType } from "../../types";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { NonHostedConfig } from "./components/nonHosted";
import { HostedConfig } from "./components/hosted";
import { useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { RhfFormProvider } from "@cycleplatform/ui/components/forms";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/currentForm/slice";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

export function Configure({ stepIdx = 0 }: { stepIdx?: number }) {
    const dispatch = useAppDispatch();
    const { handleBack, handleNext } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector(getCurrentForm);
    const isHosted = currentForm?.steps?.[1].hosted;

    const form = useForm<ConfigureStepType>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            isAuthoritative: true,
            isConfirmed: null,
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
    } = form;

    const onSubmit = async (data: ConfigureStepType) => {
        dispatch(addStepData({ data, idx: stepIdx }));
        handleNext?.();
    };

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <DialogColumn>
                {isHosted ? <HostedConfig /> : <NonHostedConfig />}
            </DialogColumn>
            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>

                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                    disabled={
                        !!Object.keys(errors).length &&
                        !currentForm?.steps?.[stepIdx]
                    }
                >
                    Review
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
