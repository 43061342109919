import {
    InfrastructureSummary,
    ProviderServerModel,
    TierPlan,
} from "~/services/cycle";
import { FormServerSchema } from "../form";

export const calcTotalServerNodes = (
    servers: Record<string, FormServerSchema>,
    tier?: TierPlan | null,
    summary?: InfrastructureSummary | null
) => {
    let totalNodes = 0;

    for (const c in summary?.clusters) {
        if (summary?.clusters[c].servers.count) {
            totalNodes += summary?.clusters[c].servers.count;
        }
    }

    const tierMaxNodes = tier ? tier.max_nodes : 0;

    if (!Object.keys(servers).length) {
        return {
            numberNodes: totalNodes,
            atLimit: false,
            overLimit: false,
        };
    }

    for (const s of Object.keys(servers)) {
        totalNodes += servers[s].quantity;
    }

    if (!tierMaxNodes) {
        return {
            numberNodes: totalNodes,
            atLimit: false,
            overLimit: false,
        };
    }

    const atNodeLimit = totalNodes === tierMaxNodes;
    const overNodeLimit = totalNodes > tierMaxNodes;

    return {
        numberNodes: totalNodes,
        atLimit: atNodeLimit,
        overLimit: overNodeLimit,
    };
};

export function calcTotalServerCost(
    selectedServers: Record<string, FormServerSchema>,
    providerServers?: ProviderServerModel[]
) {
    if (!providerServers) {
        return 0;
    }

    return Object.values(selectedServers).reduce((acc, cur) => {
        const serverDetails = providerServers?.find(
            (s) => s.id === cur.model_id
        );
        if (!serverDetails) {
            return acc;
        }
        return acc + serverDetails.price.mills * cur.quantity;
    }, 0);
}
