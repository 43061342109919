import { Md5 } from "ts-md5";
import classNames from "classnames";
import { FC } from "react";

type AvatarProps = {
    email: string | undefined;
    className?: string;
};

export const Avatar: FC<AvatarProps> = ({ email, className }) => {
    return (
        <img
            className={classNames(
                "h-7 w-7 rounded-full transition-all",
                className
            )}
            src={getFromGravatar(
                Md5.hashStr(email?.toLocaleLowerCase().trim() || ""),
                500
            )}
            alt="avatar"
        />
    );
};

function getFromGravatar(hash: string, size: number, placeholder?: string) {
    return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size}&d=${
        placeholder || "identicon"
    }`;
}
