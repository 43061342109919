import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { TogglePanelContent } from "@cycleplatform/react-hook-form-components/components";
import { CpuSharesInput } from "./CpuSharesInput";
import { CpuCoresPinningInput } from "./CpuCoresPinningInput";
import { RamLimitInput } from "./RamLimitInput";
import { RamReserveInput } from "./RamReserveInput";

export function ResourcesSection() {
    return (
        <TogglePanelContent
            title="Resources"
            field="config.resources"
            disabledFallback={(toggle) => (
                <div className="flex justify-center ">
                    <div className="ext-center flex max-w-xl flex-col items-center gap-4">
                        <h4>Resource Settings Disabled</h4>
                        <p>
                            Enable resource settings to configure CPU and RAM
                            utilization for this container.
                        </p>
                        <Button flavor="primary" onClick={() => toggle()}>
                            Enable
                        </Button>
                    </div>
                </div>
            )}
        >
            {() => (
                <>
                    <FormSectionHeader header="CPU" />
                    <FormSection>
                        <CpuSharesInput />
                        <CpuCoresPinningInput />
                    </FormSection>
                    <FormSectionHeader header="RAM" />
                    <FormSection>
                        <RamLimitInput />
                        <RamReserveInput />
                    </FormSection>
                </>
            )}
        </TogglePanelContent>
    );
}
