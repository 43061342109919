import { getRecordType } from "@cycleplatform/core/modules/dns/records";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DnsRecordFields } from "~/components/dns/tables/components/DnsRecordsFields";
import { CreateDnsZoneRecordApiArg, DnsRecord } from "~/services/cycle";

type UploadableRecordTableProps = {
    records: CreateDnsZoneRecordApiArg["body"][];
    onRemove: (idx: number) => void;
};

export function UploadableRecordTable({
    records,
    onRemove,
}: UploadableRecordTableProps) {
    return (
        <StyledDataTable>
            <StyledTableHead>
                <StyledHeaderCell className="">Name</StyledHeaderCell>
                <StyledHeaderCell className="w-40 text-center">
                    Type
                </StyledHeaderCell>
                <StyledHeaderCell className="">Fields</StyledHeaderCell>
                <StyledHeaderCell className="w-10 text-center "></StyledHeaderCell>
            </StyledTableHead>
            <tbody>
                {records.map((r, idx) => (
                    <StyledTableRow key={idx}>
                        <StyledCell>{r.name}</StyledCell>
                        {/* Here we force the DNS record type - even though its
                        the 'create params' it has the fields we use in these
                        defined, it's just missing things like 'state' which
                        isn't used here, and LINKED records are not possible to
                        get out of the upload. */}
                        <StyledCell className="text-center uppercase">
                            {getRecordType(r as DnsRecord)}
                        </StyledCell>
                        <StyledCell>
                            <DnsRecordFields record={r as DnsRecord} />
                        </StyledCell>
                        <StyledCell>
                            <button type="button" onClick={() => onRemove(idx)}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-error"
                                />
                            </button>
                        </StyledCell>
                    </StyledTableRow>
                ))}
            </tbody>
        </StyledDataTable>
    );
}
