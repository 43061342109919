export function extractPortalSubdomain(url: string): string | null {
    const subdomainPattern = /^portal\.([^.]+)\.cycle\.io$/;
    const match = url.match(subdomainPattern);
    if (match && match[1] !== "cycle") {
        return match[1];
    }
    return "";
}

export function getSubdomains(domain: string) {
    const parts = domain.split(".");

    // TLD is always the last part
    const tld = parts[parts.length - 1];

    // If the second-to-last part is greater than 3 characters, it's likely part of a compound TLD
    let baseDomain, subdomains;

    if (parts[parts.length - 2].length > 3) {
        // Handle cases like `example.com`, `sub.example.com`
        baseDomain = `${parts[parts.length - 2]}.${tld}`;
        subdomains = parts.slice(0, parts.length - 2).join(".");
    } else {
        // Handle compound TLDs, e.g., `example.co.uk`
        baseDomain = `${parts[parts.length - 3]}.${
            parts[parts.length - 2]
        }.${tld}`;
        subdomains = parts.slice(0, parts.length - 3).join(".");
    }

    return {
        subdomains: subdomains || null, // Return null if no subdomain
        baseDomain: baseDomain,
    };
}
