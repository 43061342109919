import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "~/store";
import { NotificationIcon } from "./icons";
import { DateTime } from "~/services/cycle";
import { Md5 } from "ts-md5";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface Notification {
    id: string;
    title: string;
    // TODO support markdown
    message?: string;
    icon?: NotificationIcon | IconDefinition;
    type: "info" | "warning" | "error" | "success";
    received: DateTime;
    /** Undefined = use the default */
    timeoutMs?: number | undefined;
    /** If true, will remain open until the user manually dismisses the notification */
    hold?: boolean;
    link?: string;
}

type NotificationState = {
    /** Information about the notifications websocket */
    hubSocket: {
        /** Whether or not the socket is connected. If undefined, a connection was never created before.*/
        connected?: boolean;
        error?: string;
    };
    accountSocket: {
        /** Whether or not the socket is connected. If undefined, a connection was never created before.*/
        connected?: boolean;
        error?: string;
    };
    list: Notification[];
};

const initialState: NotificationState = {
    hubSocket: {},
    accountSocket: {},
    list: [],
};

/**
 * Keeps track of our notification states
 */
const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setHubNotificationsConnected: (
            state,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.hubSocket.connected = action.payload;
        },
        setHubNotificationSocketError: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.hubSocket.error = action.payload;
        },
        setAccountNotificationsConnected: (
            state,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.accountSocket.connected = action.payload;
        },
        setAccountNotificationSocketError: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.accountSocket.error = action.payload;
        },
        pushNotification: (
            state,
            action: PayloadAction<Omit<Notification, "id" | "received">>
        ) => {
            state.list.push({
                ...action.payload,
                received: new Date().toISOString(),
                id: Md5.hashStr(JSON.stringify(action.payload) + Math.random()),
            });
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.list = state.list.filter((n) => n.id !== action.payload);
        },
    },
});

export const selectNotificationConnected = (state: RootState) =>
    state.notifications.hubSocket.connected;

export const selectNotifications = (state: RootState) =>
    state.notifications.list;

// Action creators are generated for each case reducer function
export const {
    actions: {
        setHubNotificationsConnected,
        setHubNotificationSocketError,
        setAccountNotificationsConnected,
        setAccountNotificationSocketError,
        pushNotification,
        removeNotification,
    },
    reducer: notificationsReducer,
} = notificationSlice;
