import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PositionedMenu } from "../menus";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { Placement } from "@floating-ui/react";

type GearOptionsProps = {
    children: React.ReactNode;
    disabled?: boolean;
    placement?: Placement;
    className?: string;
};

export function GearOptions({
    children,
    disabled,
    className,
    placement,
}: GearOptionsProps) {
    return (
        <PositionedMenu
            placement={placement}
            hideCloseButton
            className={className}
            render={() => children}
        >
            <button
                disabled={disabled}
                className="hover:text-cycle-blue transition-colors"
            >
                <FontAwesomeIcon icon={faGear} />
            </button>
        </PositionedMenu>
    );
}
