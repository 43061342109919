import { Controller, useFormContext } from "react-hook-form";
import { ModelContent } from "./ModelTile";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";

import {
    FormSectionHeader,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useEffect, useMemo } from "react";
import { PanelContent } from "@cycleplatform/ui/components/panels";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { DeployableInfraFormType } from "../form";
import { ProviderLocation, ProviderServerModel } from "~/services/cycle";

type LocationSectionProps = {
    model?: ProviderServerModel;
    allLocations?: ProviderLocation[];
};

export function LocationSection({ model, allLocations }: LocationSectionProps) {
    const { setValue, control, watch, register } =
        useFormContext<DeployableInfraFormType>();

    const selectedLocations = watch("locations");
    const zones = watch("zones");
    const provider = watch("provider");

    const modelLocations = useMemo(() => {
        return allLocations?.filter((l) => model?.location_ids?.includes(l.id));
    }, [allLocations]);
    useEffect(() => {
        const tmp = selectedLocations?.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.id]: zones?.[cur.id] || [],
            };
        }, {} as any);

        setValue("zones", tmp);
    }, [selectedLocations]);

    return (
        <div>
            <div className="m-1 mb-8 rounded-md border">
                {model ? (
                    <ModelContent
                        model={model}
                        providerName={provider?.name || ""}
                        className={getOptionButtonClassName(true)}
                    />
                ) : null}
            </div>
            <div className="flex gap-4">
                <RhfFormField name="locations" label="locations">
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <MultiSelectInput
                                {...field}
                                filterFields={["id", "name"]}
                                options={modelLocations}
                                formatOption={(v) => {
                                    return <FormattedOption label={v.name} />;
                                }}
                                formatDisplayValue={(v) => {
                                    return v.name;
                                }}
                            />
                        )}
                        control={control}
                        name={`locations`}
                    />
                </RhfFormField>
                <RhfFormField name={`priority`} label="priority">
                    <TextInput
                        type="number"
                        defaultValue={1}
                        {...register(`priority`, {
                            valueAsNumber: true,
                        })}
                    />
                </RhfFormField>
            </div>

            <div className="grid grid-cols-2 gap-4">
                {selectedLocations?.map((l, lIdx) => (
                    <SelectedLocation l={l} key={l.id} />
                ))}
            </div>
        </div>
    );
}

function SelectedLocation({ l }: { l: ProviderLocation }) {
    const { control } = useFormContext<DeployableInfraFormType>();

    if (
        !l.provider?.availability_zones ||
        l.provider?.availability_zones?.length === 0
    ) {
        return null;
    }
    return (
        <PanelContent className="border-2">
            <FormSectionHeader header={l.name} />

            <RhfFormField label={`Zones`} name={`zones.${l.id}`}>
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <MultiSelectInput
                            {...field}
                            placeholder="Platform Default"
                            options={l.provider.availability_zones}
                        />
                    )}
                    control={control}
                    name={`zones.${l.id}`}
                />
            </RhfFormField>
        </PanelContent>
    );
}
