import {
    Checkbox,
    FormField,
    FormSection,
    FormSectionHeader,
    FormToggle,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import { ScopedVariableFormSubmitType } from "../ScopedVariableForm";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

export function ScopedVariablesAccess() {
    const { register, setValue, unregister, control } =
        useFormContext<ScopedVariableFormSubmitType>();

    const access = useWatch({ name: "access", control });
    const blob = useWatch({ name: "source.details.blob", control });
    const identifier = useWatch({ name: "identifier", control });
    return (
        <>
            <FormSectionHeader header="Access" />
            <FormSection>
                <div className="flex gap-4">
                    <div className="w-full rounded-md border p-4">
                        <FormSectionHeader header="Environment Variable">
                            <Tooltip
                                message="Environment Variable access disabled for blob type"
                                disabled={!blob}
                            >
                                <FormToggle
                                    disabled={!!blob}
                                    value={!!access?.env_variable}
                                    onChange={(v) => {
                                        if (v) {
                                            return setValue(
                                                "access.env_variable",
                                                {
                                                    key: identifier
                                                        .toLocaleUpperCase()
                                                        .replaceAll("-", "_"),
                                                },
                                                {
                                                    shouldDirty: true,
                                                }
                                            );
                                        } else {
                                            return setValue(
                                                "access.env_variable",
                                                undefined,
                                                {
                                                    shouldDirty: true,
                                                }
                                            );
                                        }
                                    }}
                                />
                            </Tooltip>
                        </FormSectionHeader>

                        <div className="flex gap-4">
                            {!!access?.env_variable ? (
                                <>
                                    <RhfFormField
                                        name="access.env_variable.key"
                                        label="key"
                                        required
                                        help="Exposes this scoped variable as an environment variable inside the container as this key."
                                    >
                                        <TextInput
                                            {...register(
                                                "access.env_variable.key",
                                                { ...required() }
                                            )}
                                        />
                                    </RhfFormField>
                                </>
                            ) : (
                                <>
                                    <FormField label="Key">
                                        <TextInput disabled />
                                    </FormField>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="w-full rounded-md border p-4">
                        <FormSectionHeader header="Internal API">
                            <div>
                                <FormToggle
                                    value={!!access?.internal_api}
                                    onChange={(v) => {
                                        if (v) {
                                            setValue(
                                                "access.internal_api",
                                                {
                                                    duration: "10s",
                                                },
                                                { shouldDirty: true }
                                            );
                                        } else {
                                            setValue(
                                                "dirtyField",
                                                "access.internal_api",
                                                { shouldDirty: true }
                                            );
                                            unregister("access.internal_api");
                                        }
                                    }}
                                />
                            </div>
                        </FormSectionHeader>

                        <div className="flex gap-4">
                            {!!access?.internal_api ? (
                                <>
                                    <RhfFormField
                                        label="Duration"
                                        name="access.internal_api.duration"
                                    >
                                        <TextInput
                                            {...register(
                                                "access.internal_api.duration"
                                            )}
                                        />
                                    </RhfFormField>
                                </>
                            ) : (
                                <>
                                    <FormField label="Duration">
                                        <TextInput disabled />
                                    </FormField>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="w-full rounded-md border p-4">
                        <FormSectionHeader header="File">
                            <div>
                                <FormToggle
                                    value={!!access?.file}
                                    onChange={(v) => {
                                        if (v) {
                                            setValue(
                                                "access.file",
                                                {
                                                    decode: false,
                                                    path: "",
                                                },
                                                { shouldDirty: true }
                                            );
                                        } else {
                                            setValue(
                                                "dirtyField",
                                                "access.file",
                                                { shouldDirty: true }
                                            );
                                            unregister("access.file");
                                        }
                                    }}
                                />
                            </div>
                        </FormSectionHeader>

                        <div className="flex gap-4">
                            {!!access?.file ? (
                                <>
                                    <div className="flex w-full gap-4">
                                        <RhfFormField
                                            label="Decode"
                                            name="access.file.decode"
                                            className="!w-20"
                                        >
                                            <Checkbox
                                                {...register(
                                                    "access.file.decode"
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="Path"
                                            name="access.file.path"
                                            className="w-full"
                                        >
                                            <TextInput
                                                {...register(
                                                    "access.file.path"
                                                )}
                                            />
                                        </RhfFormField>
                                    </div>
                                </>
                            ) : (
                                <div className="flex w-full gap-4">
                                    <FormField label="Decode" className="!w-20">
                                        <Checkbox disabled />
                                    </FormField>
                                    <FormField label="Path" className="w-full">
                                        <TextInput disabled />
                                    </FormField>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
