import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Account } from "~/services/cycle";

type NotificationSettingsProps = {
    account?: Account;
};

export function NotificationSettings({ account }: NotificationSettingsProps) {
    return null;
    // TODO Build out Notifications Settings

    // return (
    //     <Panel>
    //         <PanelTitle title="Notification Settings" />
    //         <PanelContent>To be completed at a later date</PanelContent>
    //     </Panel>
    // );
}
