import {
    Checkbox,
    FormSection,
    FormSectionHeader,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ContainerSelect } from "~/components/containers";
import { ScopedVariableFormSubmitType } from "../ScopedVariableForm";
import { ContainerIdentifierMultiSelectInput } from "~/components/containers/ContainerIdentifierMultiSelectInput";

export function ScopedVariablesScope({
    environmentId,
}: {
    environmentId?: string;
}) {
    const { control, setValue } =
        useFormContext<ScopedVariableFormSubmitType>();

    const scope = useWatch({ name: "scope", control });
    const access = useWatch({ name: "access", control });

    return (
        <>
            <FormSectionHeader header="Scope" />
            <FormSection>
                <div className="flex gap-4">
                    <div className="w-36">
                        <RhfFormField
                            label="Global"
                            name="scope.containers.global"
                        >
                            <Checkbox
                                checked={scope?.containers?.global || false}
                                onChange={() => {
                                    if (access) {
                                        setValue(
                                            `scope.containers.global`,
                                            !scope.containers.global,
                                            { shouldDirty: true }
                                        );
                                    }
                                }}
                            />
                        </RhfFormField>
                    </div>
                    <div className="flex w-full gap-4">
                        <RhfFormField
                            label="Existing Containers"
                            name="scope.containers.ids"
                        >
                            <Controller
                                name={`scope.containers.ids`}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <ContainerSelect
                                        {...field}
                                        multiple
                                        placeholder={"No containers selected"}
                                        disabled={scope.containers.global}
                                        filter={{
                                            environment: environmentId || "",
                                        }}
                                    />
                                )}
                                control={control}
                            />
                        </RhfFormField>

                        <RhfFormField
                            label="Container Identifiers"
                            name="scope.containers.identifiers"
                        >
                            <Controller
                                name={`scope.containers.identifiers`}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <ContainerIdentifierMultiSelectInput
                                        {...field}
                                        filter={{
                                            environment: environmentId || "",
                                        }}
                                        skip={!environmentId}
                                        isCreatable
                                    />
                                )}
                                control={control}
                            />
                        </RhfFormField>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
