import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { brandColors } from "tailwindcss-config/colors";
import {
    Environment,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    BarChart,
    Bar,
} from "recharts";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import { LookupDataPoint, buildDiscoveryLookupsQuery } from "./query";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerDestinationsChartProps = {
    className?: string;
    environment?: Environment;
};

export function DiscoveryLookupsChart({
    className,
    environment,
}: LoadBalancerDestinationsChartProps) {
    const theme = useAppSelector(selectAppliedTheme);
    const {
        data: telemetry,
        isLoading,
        error,
    } = useGenerateAggregatedMetricsQuery(
        buildDiscoveryLookupsQuery(environment?.id),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    if (error) {
        throw error;
    }

    const data = telemetry?.data as LookupDataPoint[] | undefined;

    return (
        <div
            className={classNames(
                className,
                "h-full",
                isLoading && skeletonStyles
            )}
        >
            {data && (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart {...CycleChartProps} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            tick={(props) => <FormattedYTick {...props} />}
                        />
                        <Tooltip
                            content={(props) => <CycleBarTooltip {...props} />}
                        />
                        <Bar
                            {...CycleBarProps}
                            dataKey="lookups"
                            stackId="lookups"
                            fill={brandColors["success"].DEFAULT}
                        />
                        <Bar
                            {...CycleBarProps}
                            dataKey="cached"
                            stackId="lookups"
                            fill={brandColors["cycle-blue"].DEFAULT}
                        />
                        <Bar
                            {...CycleBarProps}
                            dataKey="not_found"
                            stackId="lookups"
                            fill={brandColors["error"].DEFAULT}
                        />
                        <Bar
                            {...CycleBarProps}
                            dataKey="throttled_hits"
                            stackId="lookups"
                            fill={brandColors["warning"].DEFAULT}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
