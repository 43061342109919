import { faDatabase, faGears } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Container } from "~/services/cycle";
import { Badge } from "@cycleplatform/ui/components/badges";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";

type ContainerStatefulBadgeProps = {
    container: Container;
    className?: string;
};

export function ContainerStatefulBadge({
    container,
    className,
}: ContainerStatefulBadgeProps) {
    return (
        <div>
            <Badge
                className={classNames(
                    className,
                    "!bg-cycle-gray !h-8",
                    !container && skeletonStyles,
                    "dark:bg-cycle-gray gap-2 whitespace-nowrap px-2 !text-xs leading-4 text-white"
                )}
            >
                <FontAwesomeIcon
                    icon={container.stateful ? faDatabase : faGears}
                    className="text-xs"
                />
                <span className="pt-0.5 !text-xs">
                    {container.stateful ? "stateful" : "stateless"}
                </span>
            </Badge>
        </div>
    );
}
