import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    RhfSliderInput,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import slugify from "slugify";
import { useGetEnvironmentQuery } from "~/services/cycle";
import { StatefulSelect } from "~/components/containers/StatefulSelect";
import classNames from "classnames";
import { TagSelectInput } from "~/components/common/forms/TagSelectInput";
import { DeploymentStrategySelect } from "~/components/containers/inputs/DeploymentStrategySelect";
import { $error } from "@cycleplatform/core/util/log";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import { CreateContainerSubmitType } from "../CreateContainerForm";

export function General({
    environmentId: paramsEnv,
}: {
    environmentId?: string;
}) {
    const { register, control, watch, setValue } =
        useFormContext<CreateContainerSubmitType>();

    const envId = watch("environment_id");

    const { data: environment } = useGetEnvironmentQuery(
        {
            environmentId: envId,
        },
        { skip: !envId }
    );

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <div className={classNames("flex flex-col")}>
                    {!paramsEnv && (
                        <RhfFormField label="environment" name="environment_id">
                            <Controller
                                name={`environment_id`}
                                control={control}
                                rules={{ ...required() }}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <EnvironmentSelect
                                        {...field}
                                        onChange={(id) => {
                                            if (!id) {
                                                return;
                                            }
                                            field.onChange(id);
                                        }}
                                    />
                                )}
                            />
                        </RhfFormField>
                    )}

                    <RhfFormField label="container name" name="name" required>
                        <TextInput
                            {...register("name", { ...required() })}
                            onChange={(e) =>
                                setValue(
                                    "config.network.hostname",
                                    slugify(e.target.value, {
                                        lower: true,
                                        strict: true,
                                    })
                                )
                            }
                        />
                    </RhfFormField>

                    <RhfFormField label="stateful" name="stateful" required>
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <StatefulSelect {...field} />
                            )}
                            control={control}
                            name="stateful"
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="deployment strategy"
                        name="config.deploy.strategy"
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <DeploymentStrategySelect
                                    {...field}
                                    onFetchError={(e) =>
                                        $error(JSON.stringify(e))
                                    }
                                />
                            )}
                            control={control}
                            name="config.deploy.strategy"
                        />
                    </RhfFormField>

                    {watch("config.deploy.strategy") !== "node" ? (
                        <RhfFormField
                            label="desired instances"
                            name="config.deploy.instances"
                        >
                            <RhfSliderInput
                                {...register("config.deploy.instances", {
                                    valueAsNumber: true,
                                })}
                            />
                        </RhfFormField>
                    ) : null}

                    <div className="flex w-full gap-4">
                        <RhfFormField
                            label="node tags (match any)"
                            name="config.deploy.constraints.node.tags.any"
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        value={field.value || []}
                                        cluster={environment?.data?.cluster}
                                    />
                                )}
                                control={control}
                                name="config.deploy.constraints.node.tags.any"
                            />
                        </RhfFormField>

                        <RhfFormField
                            label="node tags (match all)"
                            name="config.deploy.constraints.node.tags.all"
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        value={field.value || []}
                                        cluster={environment?.data?.cluster}
                                    />
                                )}
                                control={control}
                                name="config.deploy.constraints.node.tags.all"
                            />
                        </RhfFormField>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
