import { useGetServerTagsQuery } from "~/services/cycle";
import { ResourceComboBox } from "./ResourceComboBox";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";

type TagSelectInputProps = {
    value: string[];
    name?: string;
    onChange: (value: string[]) => void;
    disabled?: boolean;
    cluster?: string;
    additionalTags?: string[];
    skip?: boolean;
};

export function TagSelectInput({
    cluster,
    additionalTags,
    skip = !cluster,
    ...props
}: TagSelectInputProps) {
    const { data: clusterTags, error } = useGetServerTagsQuery(
        cluster
            ? {
                  filter: { cluster: cluster || "" },
              }
            : {},
        { skip }
    );
    if (error) {
        throw error;
    }

    const options = [
        ...new Set([...(clusterTags?.data || []), ...(additionalTags || [])]),
    ].map((tag) => ({ id: tag }));

    return (
        <ResourceComboBox
            {...props}
            emptyMessage=""
            resources={options || []}
            multiple
            placeholder="Select a Node Tag..."
            formatDisplayValue={(v) => {
                return v?.id || "";
            }}
            formatOption={(v) => <FormattedOption label={v.id} />}
            filterFields={["id"]}
        />
    );
}
