import { Link } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    Pipeline,
    PipelineRun,
} from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";
import { NavIcons } from "~/components/layout/NavIcons";

export const formatPipelineRunMessage = (
    item: Activity,
    components: ComponentIncludes
) => {
    const pipeRun = extractComponent<PipelineRun>(item.component, components);

    const pipe =
        pipeRun &&
        extractComponent<Pipeline>(
            { id: pipeRun?.pipeline_id, type: "pipeline" },
            components
        );

    const pipeLink = (
        <Link
            to={{
                pathname: `/pipelines/${pipe?.id}`,
            }}
        >
            {pipe?.name || item.component?.id}
        </Link>
    );

    switch (item.event) {
        case "pipeline.run.completed":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Successfully ran pipeline {pipeLink}</span>
                ) : (
                    <span>Error running pipeline {pipeLink}</span>
                ),
                icon: NavIcons["pipelines"],
            };
        default:
            return {
                message: item.event,
            };
    }
};
