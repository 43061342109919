import {
    Children,
    cloneElement,
    isValidElement,
    useContext,
    useEffect,
} from "react";

import { MultiStepDialogContext } from "../context";
import { useAppSelector } from "~/hooks";
import { getCurrentForm } from "~/modules/currentForm/slice";

export type FormStepProps = {
    stepIdx?: number;
    children: React.ReactElement<{ stepIdx?: number }>;
    /**
     * Name is read by the MultiStepFormSteps component (the parent) to create the progress bar
     */
    name: string;
};

export function MultiStepFormStep({ stepIdx, children }: FormStepProps) {
    const { currentIdx, navigateToStep } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector(getCurrentForm);
    const numCompletedSteps = currentForm?.steps?.length || 0;
    const isInvalidStep = stepIdx && stepIdx > numCompletedSteps;

    useEffect(() => {
        if (isInvalidStep) {
            navigateToStep(numCompletedSteps);
        }
    }, []);

    if (currentIdx !== stepIdx || isInvalidStep) {
        return null;
    }

    const childrenWithStepIdx = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { stepIdx });
        }
        return child;
    });

    return <div className="h-full">{childrenWithStepIdx}</div>;
}
