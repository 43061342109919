import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { useMemo } from "react";
import { useGetEnvironmentDeploymentsQuery } from "~/services/cycle";

type DeploymentTagMultiSelectProps = {
    name?: string;
    environmentId?: string | null;
    isCreatable?: boolean;
    placeholder?: string;
    className?: string;
    value?: string[];
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value?: string[] | null) => void;
};

export function DeploymentTagMultiSelect({
    value,
    onChange,
    isCreatable,
    placeholder = "Select deployment version tags",
    environmentId,
    ...props
}: DeploymentTagMultiSelectProps) {
    const { data: deployments, error } = useGetEnvironmentDeploymentsQuery(
        {
            environmentId: environmentId || "",
        },
        { skip: !environmentId }
    );
    if (error) {
        throw error;
    }

    const tags = useMemo(
        () =>
            Object.entries(deployments?.data.versions || {})
                .map(([v, d]) =>
                    d.tags.map((t) => ({
                        name: t,
                        version: v,
                        containers: d.containers,
                    }))
                )
                .flat()
                .sort((a, b) => a.name.localeCompare(b.name)),
        [deployments?.data]
    );

    return (
        <MultiSelectInput
            {...props}
            isCreatable={isCreatable}
            placeholder={placeholder}
            options={!!environmentId ? tags : []}
            value={value?.map((v) => ({
                name: v || "",
                version: "",
                containers: 0,
            }))}
            formatDisplayValue={(t) => {
                if (t.version === "") {
                    return t.name;
                }
                return `${t.name} [${t.version} - ${t.containers} containers]`;
            }}
            onChange={(v) => {
                onChange?.(Array.from(new Set(v?.map((t) => t.name) || [])));
            }}
            creatablePlaceholder={(v) => `Use deployment tag "${v}"`}
            formatOption={(t) => (
                <FormattedOption
                    label={`${t.name} [${t.version} - ${t.containers} containers]`}
                />
            )}
        />
    );
}
