import {
    faQuestionCircle,
    faPlus,
    faSync,
    faTrash,
    faHammer,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, Stack } from "~/services/cycle";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { isErroredActivity } from "./common/util";

export function formatStackBuildMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const stackName = extractStackName(item.context.stack_id || "", components);
    const build = (
        <Link
            to={generateDialogLink("stack-build", {
                "dialog-build-id": item.component?.id || "",
                "dialog-stack-id": item.context.stack_id || "",
            })}
        >
            build
        </Link>
    );
    const stackLink = (
        <Link to={`/stacks/${item.context.stack_id}`}>{stackName}</Link>
    );
    switch (item.event) {
        case "stack.build.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created a {build} of stack {stackLink}
                    </span>
                ) : (
                    <span>Error creating build for stack {stackLink}</span>
                ),
                icon: faPlus,
            };
        case "stack.build.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Generated this {build} for stack {stackLink}
                    </span>
                ) : (
                    <span>Error generating build for stack {stackLink}</span>
                ),
                icon: faHammer,
            };
        case "stack.build.deploy":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deployed this {build} of stack {stackLink}
                    </span>
                ) : (
                    <span>
                        Error deploying this {build} of stack {stackLink}
                    </span>
                ),
                icon: faSync,
            };
        case "stack.build.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted this {build} of stack {stackLink}
                    </span>
                ) : (
                    <span>
                        Error deleting this {build} of stack {stackLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "stack.build.task.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested generation of this {build} for stack{" "}
                        {stackLink}
                    </span>
                ) : (
                    <span>
                        Error requesting generation of this {build} for stack{" "}
                        {stackLink}
                    </span>
                ),
                icon: faHammer,
            };

        case "stack.build.task.deploy":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deployment of this {build} for stack{" "}
                        {stackLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deployment of this {build} for stack{" "}
                        {stackLink}
                    </span>
                ),
                icon: faHammer,
            };

        case "stack.build.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested to delete {build} of stack {stackLink}
                    </span>
                ) : (
                    <span>
                        Error requesting to delete {build} of stack {stackLink}
                    </span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractStackName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Stack).name;
}
