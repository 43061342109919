import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import {
    PageAside,
    PageBody,
    PageContent,
} from "@cycleplatform/ui/components/page";

import { Account } from "~/services/cycle";
import { EmployeeAccessLog } from "./components/EmployeeAccessLog";
import { SecuritySettings } from "./components/SecuritySettings";
import { TwoFactorAuth } from "./components/TwoFactorAuth";

import { UpdatePassword } from "./components/UpdatePassword";
type SecuritySectionProps = {
    account?: Account;
};

export function SecuritySection({ account }: SecuritySectionProps) {
    return (
        <DialogPageBody>
            <PageContent>
                <TwoFactorAuth account={account} />
                <SecuritySettings account={account} />
            </PageContent>
            <PageAside>
                <EmployeeAccessLog account={account} />
                <UpdatePassword />
            </PageAside>
        </DialogPageBody>
    );
}
