import {
    SectionDisabledControl,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    RhfSliderInput,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    faExclamationCircle,
    faEdit,
    faGearComplexCode,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { Container, useCreateContainerJobMutation } from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useContext, useEffect, useState } from "react";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { useJobTracker } from "~/modules/jobs/hooks";
import { $info } from "@cycleplatform/core/util/log";
import { ContainerDialogContext } from "../../context";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { hasMaximumValue } from "@cycleplatform/ui/components/forms";
import { useKeepFormCurrent } from "~/components/common/forms";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { AccessControlOverlay } from "~/components/common/buttons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { containerScaleAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { isServiceContainer } from "@cycleplatform/core/modules/containers/util";

type ScaleInstancesProps = {
    container?: Container;
};

type ScaleInstancesSubmitType = {
    instances: number;
};

const getDefaultValues = (container?: Container) => {
    return {
        instances: container?.instances ?? 1,
    };
};

export function ScaleInstances({ container }: ScaleInstancesProps) {
    const [advanced, setAdvanced] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (container?.instances === undefined) {
            return;
        }
        setAdvanced(container.instances > 100);
    }, [container?.instances]);

    const form = useForm<ScaleInstancesSubmitType>({
        defaultValues: getDefaultValues(container),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, container, (c) => getDefaultValues(c));

    const { environment } = useContext(ContainerDialogContext);

    const {
        handleSubmit,
        reset,
        getValues,
        control,
        setValue,
        formState: { isSubmitting, isSubmitSuccessful, isDirty },
    } = form;
    const instances = useWatch({ name: "instances", control });
    const [trackScaleJob, { isTrackingJob }] = useJobTracker();
    const [scaleInstances] = useCreateContainerJobMutation();

    const onSubmit = async (data: ScaleInstancesSubmitType) => {
        if (!container) {
            return;
        }
        return trackScaleJob(
            scaleInstances({
                containerId: container.id,
                body: {
                    action: "scale",
                    contents: {
                        instances: Number(data.instances),
                    },
                },
            }).unwrap()
        ).then(
            () => $info("scaled containers successfully"),
            handleSubmitError(form.setError)
        );
    };

    useEffect(() => {
        reset(getValues());
    }, [isSubmitSuccessful]);

    return (
        <Panel>
            <PanelTitle title="Scaling" />
            <PanelContent>
                <RhfFormProvider {...form}>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={
                            container
                                ? containerScaleAccessFn(container)
                                : () => undefined
                        }
                    >
                        <div
                            className={classNames(
                                "flex items-start gap-4",
                                !container ||
                                    (advanced === undefined && skeletonStyles)
                            )}
                        >
                            <InstancesCountSelect
                                advanced={advanced}
                                container={container}
                            />

                            <Tooltip
                                message={`${
                                    advanced ? "Standard" : "Elevated"
                                } Demand`}
                            >
                                <button
                                    type="button"
                                    className="text-cycle-blue hover:text-cycle-blue-accent border-none text-sm"
                                    onClick={() => {
                                        setAdvanced(!advanced);

                                        if (instances > 100) {
                                            setValue("instances", 100, {
                                                shouldDirty: true,
                                            });
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faGearComplexCode} />
                                </button>
                            </Tooltip>
                        </div>

                        <PanelFooter>
                            <RhfGlobalFormError />
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className="text-cycle-blue "
                                />

                                {container?.stateful ? (
                                    <p>
                                        Stateful containers cannot be scaled at
                                        this time
                                    </p>
                                ) : (
                                    <p>
                                        Scaling containers does not cause any
                                        downtime
                                    </p>
                                )}
                            </div>

                            {container ? (
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={
                                        container
                                            ? containerScaleAccessFn(container)
                                            : () => undefined
                                    }
                                >
                                    <PushAndHoldButton
                                        isLoading={
                                            isTrackingJob || isSubmitting
                                        }
                                        flavor="primary"
                                        icon={faEdit}
                                        type="button"
                                        tooltip={
                                            isServiceContainer(container)
                                                ? "cannot scale service container"
                                                : "hold to scale container"
                                        }
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={!isDirty}
                                    >
                                        Scale Instances
                                    </PushAndHoldButton>
                                </AccessControlOverlay>
                            ) : (
                                <SkeletonButton size="lg" />
                            )}
                        </PanelFooter>
                    </AccessControlledSection>
                </RhfFormProvider>
            </PanelContent>
        </Panel>
    );
}

function InstancesCountSelect({
    advanced,
    container,
}: {
    advanced: boolean | undefined;
    container?: Container;
}) {
    const { register } = useFormContext<ScaleInstancesSubmitType>();

    if (!container || advanced === undefined) {
        return <SkeletonButton className="my-8" />;
    }

    switch (advanced) {
        case true:
            return (
                <RhfFormField
                    label="desired instances"
                    name="instances"
                    className="pb-16"
                >
                    <TextInput
                        type="number"
                        {...register("instances", {
                            valueAsNumber: true,
                            validate: {
                                ...hasMaximumValue(16_000_000),
                            },
                        })}
                    />
                </RhfFormField>
            );
        case false:
            return (
                <RhfFormField label="desired instances" name="instances">
                    <RhfSliderInput
                        {...register("instances", {
                            valueAsNumber: true,
                        })}
                    />
                </RhfFormField>
            );
    }
}
