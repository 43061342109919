import { Button } from "@cycleplatform/ui/components/buttons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ConsoleButtonProps = {
    children?: React.ReactNode;
    icon: IconProp;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function ConsoleButton({ children, icon, onClick }: ConsoleButtonProps) {
    return (
        <Button className="text-xs" onClick={onClick}>
            <FontAwesomeIcon icon={icon} /> {children}
        </Button>
    );
}
