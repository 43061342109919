import { parseSearchString } from "@cycleplatform/core/util";
import { formatRFC3339 } from "date-fns";
import { AggregateLogsApiArg, Container } from "~/services/cycle";

export type SearchLogsForm = {
    search: string;
    isRegex: boolean;
    limit?: number | null;
    dateRange?: {
        start: Date | null;
        end: Date | null;
    };
};

export function convertLogsSearchToQuery({
    container,
    form,
}: {
    container?: Container;
    form: SearchLogsForm;
}): AggregateLogsApiArg["body"] {
    return {
        scope: {
            type: "container",
            ids: container?.id ? [container.id] : null,
            date_range: {
                start: form?.dateRange?.start
                    ? formatRFC3339(form.dateRange.start)
                    : undefined,
                end: form?.dateRange?.end
                    ? formatRFC3339(form.dateRange.end)
                    : undefined,
            },
        },
        limit: form.limit,
        search: form.isRegex
            ? [{ match: form.search, type: "regexp" }]
            : parseSearchString(form.search).map((s) => ({
                  type: "raw",
                  match: s,
              })),
    };
}
