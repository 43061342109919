export function pushUnique<T extends Array<string> | ReadonlyArray<string>>(
    arr: T,
    val: string | T
): T {
    if (Array.isArray(val)) {
        const s = new Set([...arr, ...val]);
        return [...s] as T;
    }

    const s = new Set([...arr, val]);
    return [...s] as T;
}

export const filterResource = <T extends Array<string> | ReadonlyArray<string>>(
    arr: T,
    val: string | T
) => {
    if (Array.isArray(val)) {
        return arr.filter((id) => val.indexOf(id) === -1);
    }
    return arr.filter((s) => s !== val);
};

/**
 * Removes duplicate objects in an array
 */
export function filterDuplicates<T>(data: Array<T>) {
    return [...new Set(data)];
}

/**
 * Array.sort() function used to sort alphabetically
 */
export function alphabeticalStringCompare(a: string, b: string) {
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    } else {
        return 0;
    }
}
