import { useEffect, useState } from "react";
import { BasicSelect, Option } from "../select";
import { TextInput } from "../TextInput";
import classNames from "classnames";

type RhfDataSizeInput = {
    name: string;
    value?: string;
    disabled?: boolean;
    onBlur?: () => void;
    onChange: (value: string | undefined) => void;
    filterUnitOptions?: (o: Option[]) => Option[];
    suffix?: React.ReactNode;
    suffixClassName?: string;
};

export function RhfDataSizeInput({
    onChange,
    value,
    disabled,
    suffix,
    suffixClassName,
    filterUnitOptions = (opt) => opt,
    ...props
}: RhfDataSizeInput) {
    const [unit, setUnit] = useState(
        value ? value.charAt(value.length - 1) : "G"
    );

    useEffect(() => {
        setUnit(value ? value.charAt(value.length - 1) : "G");
    }, [value]);

    const unitOptions = filterUnitOptions([
        { value: "M", label: "MB" },
        { value: "G", label: "GB" },
        { value: "T", label: "TB" },
    ]);

    return (
        <div className="group ">
            <div className="group-focus-within:ring-cycle-blue flex rounded-md group-focus-within:border-transparent group-focus-within:ring-2">
                <TextInput
                    {...props}
                    className="rounded-r-none border-r-0"
                    type="number"
                    onChange={(v) => onChange(v.target.value.toString() + unit)}
                    disabled={disabled}
                    value={value ? value.slice(0, -1) : 0}
                />
                <BasicSelect
                    className={classNames(
                        "group-focus-within:ring-cycle-blue rounded-l-none group-focus-within:border-transparent group-focus-within:ring-2 ",
                        suffix && "rounded-r-none"
                    )}
                    name="unit"
                    value={unit}
                    onChange={(s) => {
                        setUnit(s || "G");
                        if (value) {
                            onChange(value?.slice(0, -1) + s);
                        }
                    }}
                    disabled={disabled}
                    options={unitOptions}
                />

                {suffix && (
                    <div
                        className={classNames(
                            suffixClassName,
                            "min-h-[40px] text-sm",
                            "bg-cycle-gray-light dark:bg-cycle-gray dark:text-cycle-gray-light group-focus-within:ring-cycle-blue text-cycle-gray-accent flex h-[42px] min-w-[8rem]  grow  items-center  justify-center rounded-r-md px-2 group-focus-within:ring-2"
                        )}
                    >
                        {suffix}
                    </div>
                )}
            </div>
        </div>
    );
}
