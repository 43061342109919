import {
    StyledCell,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";

import { SkeletonHeader } from "./SkeletonHeader";

type SkeletonTableRowProps = {
    rows?: number;
    columns?: number;
};

export function SkeletonTableRows({
    rows = 2,
    columns = 5,
}: SkeletonTableRowProps) {
    return (
        <>
            {new Array(rows).fill(0).map((_, idx) => (
                <StyledTableRow key={`row-${idx}`}>
                    {new Array(columns).fill(0).map((_, idx) => (
                        <StyledCell key={`column-${idx}`}>
                            <SkeletonHeader size="sm" />
                        </StyledCell>
                    ))}
                </StyledTableRow>
            ))}
        </>
    );
}
