import {
    RhfFormField,
    TextInput,
    isIdentifier,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";

export function RhfIdentifierInput({
    name,
    label,
    className,
    required,
    disabled,
}: {
    name: string;
    label: string;
    className?: string;
    required?: boolean;
    disabled?: boolean;
}) {
    const { register } = useFormContext();

    return (
        <RhfFormField
            label={label}
            name={name}
            className={className}
            required={required}
            help="A valid identifier is lowercase, alphanumeric with dashes.  Ex: abc-def"
        >
            <TextInput
                disabled={disabled}
                {...register(name, {
                    validate: {
                        ...isIdentifier(),
                    },
                })}
            />
        </RhfFormField>
    );
}
