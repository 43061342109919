import { LabelPosition } from "recharts/types/component/Label";
import { CurveType } from "recharts/types/shape/Curve";
import { Margin } from "recharts/types/util/types";
import { brandColors } from "tailwindcss-config/colors";

export const CycleLineChartProps: {
    margin: Margin;
} = {
    margin: { top: 5, bottom: 5, right: 0, left: -20 },
};

export const CycleLineProps: {
    type: CurveType;
    strokeWidth: number;
    dot: boolean;
    isAnimationActive: boolean;
} = {
    strokeWidth: 2,
    dot: false,
    type: "monotone",
    isAnimationActive: false,
};

export const CycleReferenceLineProps: {
    stroke: string;
    strokeDasharray: "6 6";
    strokeWidth: number;
} = {
    stroke: brandColors["cycle-gray"].DEFAULT,
    strokeDasharray: "6 6",
    strokeWidth: 2,
};

export const CycleReferenceLabelProps: {
    position: "insideTopLeft";
    fontSize: number;
    dy: number;
} = {
    position: "insideTopLeft",
    fontSize: 14,
    dy: -20,
};

// use when reference line is a true "max" where the value will never exceed the ref line
// Will align the label left-bottom
export const CycleMaxReferenceLabelProps: {
    position: "insideTopLeft";
    fill: string;
    fontSize: number;
    dy: number;
} = {
    position: "insideTopLeft",
    fill: brandColors["cycle-black"].DEFAULT,
    fontSize: 14,
    dy: 0,
};
