import {
    Checkbox,
    SectionDisabledControl,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    Container,
    UpdateContainerApiArg,
    useUpdateContainerMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { isServiceContainer } from "@cycleplatform/core/modules/containers/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useContext } from "react";
import { ContainerDialogContext } from "../../context";
import { AccessControlOverlay } from "~/components/common/buttons";
import { containerModifyAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";

type BasicSettingsProps = {
    container?: Container;
};

function getDefaultValues(c?: Container) {
    return {
        name: c?.name,
        identifier: c?.identifier,
        deprecate: c?.deprecate,
        lock: c?.lock,
    };
}

export function BasicSettings({ container }: BasicSettingsProps) {
    const { environment } = useContext(ContainerDialogContext);
    const form = useForm<UpdateContainerApiArg["body"]>({
        defaultValues: getDefaultValues(container),
        ...rhfConfig,
    });

    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    useKeepFormCurrent(form, container, (c) => getDefaultValues(c));

    const [updateContainer] = useUpdateContainerMutation();

    const onSubmit = async (data: UpdateContainerApiArg["body"]) => {
        if (!container || !isDirty) {
            return;
        }
        return updateContainer({
            containerId: container.id,
            body: data,
        })
            .unwrap()
            .catch(handleSubmitError(form.setError));
    };

    const containerLockCapabilityError = useCapability("containers-lock");

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Basic Settings" />
                <PanelContent>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={
                            container
                                ? containerModifyAccessFn(container)
                                : () => undefined
                        }
                        disabled={isServiceContainer(container)}
                    >
                        <RhfFormField label="Name" name="name" required>
                            {container ? (
                                <TextInput
                                    className="my-2 w-full"
                                    {...register("name", { ...required() })}
                                />
                            ) : (
                                <SkeletonButton />
                            )}
                        </RhfFormField>

                        <RhfIdentifierInput
                            name={`identifier`}
                            label="Identifier"
                            required
                        />

                        <div className="flex gap-4">
                            <RhfFormField
                                label="Deprecate"
                                name="deprecate"
                                help="Add a deprecated tag to this container"
                            >
                                <div className="flex items-center gap-4">
                                    {container ? (
                                        <Checkbox {...register("deprecate")} />
                                    ) : (
                                        <SkeletonButton className="!h-5 !w-5 !rounded-sm" />
                                    )}
                                </div>
                            </RhfFormField>

                            <RhfFormField
                                label="Lock Container"
                                name="lock"
                                help="Locking the container prevents the container or its instances from being deleted"
                            >
                                <div className="flex items-center gap-4">
                                    {container ? (
                                        <Tooltip
                                            disabled={
                                                !containerLockCapabilityError
                                            }
                                            message="Access Denied - Missing 'containers-lock' capability."
                                        >
                                            <Checkbox
                                                disabled={
                                                    !!containerLockCapabilityError
                                                }
                                                {...register("lock")}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <SkeletonButton className="!h-5 !w-5 !rounded-sm" />
                                    )}
                                </div>
                            </RhfFormField>
                        </div>
                    </AccessControlledSection>

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={
                                container
                                    ? containerModifyAccessFn(container)
                                    : () => undefined
                            }
                        >
                            <PushAndHoldButton
                                isLoading={isSubmitting}
                                icon={faEdit}
                                onClick={handleSubmit(onSubmit)}
                                type="button"
                                tooltip={
                                    isServiceContainer(container)
                                        ? "cannot update service container"
                                        : "hold to update container"
                                }
                                flavor="primary"
                                disabled={!isDirty}
                            >
                                Update
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
