import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type DropFileInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    title?: string;
    subtitle?: string;
};

export function DropFileInput({
    subtitle,
    onDragOver,
    onDrop,
    ...inputProps
}: DropFileInputProps) {
    return (
        <div
            className="my-4 flex w-full items-center justify-center"
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            <label
                htmlFor={inputProps.id}
                className="border-cycle-blue bg-cycle-white hover:bg-cycle-gray-light dark:border-cycle-blue dark:bg-cycle-black dark:hover:border-cycle-blue-accent dark:hover:bg-cycle-gray-accent flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed"
            >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <FontAwesomeIcon
                        className="text-cycle-blue mb-4 h-8 w-8"
                        icon={faUpload}
                    />

                    <p className="mb-2 text-base">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                    </p>
                    <p className="text-xs">{subtitle}</p>
                </div>
                <input
                    {...inputProps}
                    id={inputProps.id}
                    type="file"
                    className="hidden"
                />
            </label>
        </div>
    );
}
