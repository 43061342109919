import {
    Container,
    DeployStackBuild,
    StackSpec,
    StackSpecContainer,
} from "~/services/cycle";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
    FormSectionHeader,
    FormSection,
    RhfFormField,
    FieldErrorMessage,
} from "@cycleplatform/ui/components/forms";
import { useEffect, useMemo } from "react";
import { ScopedVariablesSection } from "./ScopedVariablesSection";
import { sortContainers } from "./helpers";
import { NewContainersList } from "./NewContainersList";
import { ExistingContainersList } from "./ExistingContainersList";
import { DeploymentVersionSelect } from "~/components/environments/deployments";
import { ErrorMessage } from "@hookform/error-message";

export function DeploymentFormSection({
    envContainers,
    stackContainers,
}: {
    envContainers?: Container[];
    stackContainers?: StackSpec["containers"];
}) {
    const sc = typeof stackContainers === "object" ? stackContainers : {};

    const {
        setValue,
        control,
        unregister,
        formState: { errors },
    } = useFormContext<DeployStackBuild["contents"]>();

    const deployment = useWatch({ name: "deployment.version", control });
    const environment = useWatch({ name: "environment_id", control });

    const sortedContainers = useMemo(
        () => sortContainers(envContainers, sc, deployment),
        [envContainers, sc, deployment]
    );

    useEffect(() => {
        unregister("deployment.version");
    }, [environment]);

    useEffect(() => {
        if (sortedContainers?.existing) {
            const defaultValues = Object.entries(
                sortedContainers?.existing
            )?.reduce((acc, cur) => {
                return {
                    ...acc,
                    [cur[0]]: {
                        reimage: false,
                        reconfigure: false,
                    },
                };
            }, {} as Record<string, { reimage: boolean; reconfigure: boolean }>);
            setValue("update.containers", defaultValues);
        }
    }, [sortedContainers?.existing]);

    return (
        <>
            <FormSectionHeader
                header={
                    <div className="flex items-center gap-4">
                        Deployments
                        <ErrorMessage
                            errors={errors}
                            name={"deployment"}
                            render={({ message }) => (
                                <FieldErrorMessage
                                    className="relative text-end "
                                    message={message}
                                />
                            )}
                        />
                    </div>
                }
            />
            <FormSection>
                <div className="w-1/2">
                    <RhfFormField label="deployment" name="deployment.version">
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <DeploymentVersionSelect
                                    {...field}
                                    isCreatable
                                    environmentId={environment}
                                />
                            )}
                            control={control}
                            name="deployment.version"
                        />
                    </RhfFormField>
                </div>
            </FormSection>

            {deployment ? (
                <>
                    {Object.values(sortedContainers?.new || {}).length ? (
                        <>
                            <FormSectionHeader header="New Containers" />
                            <FormSection>
                                <NewContainersList
                                    newContainers={sortedContainers?.new}
                                />
                            </FormSection>
                        </>
                    ) : null}

                    {Object.values(sortedContainers?.existing || {}).length ? (
                        <>
                            <FormSectionHeader
                                header="Existing Containers"
                                help="Existing containers are detected if a container in the deployment has the same identifier as the container in the stack spec."
                            />
                            <FormSection>
                                <ExistingContainersList
                                    existingContainers={
                                        sortedContainers?.existing
                                    }
                                />
                            </FormSection>
                        </>
                    ) : null}
                    <ScopedVariablesSection />
                </>
            ) : null}
        </>
    );
}
