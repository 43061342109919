import { Badge } from "@cycleplatform/ui/components/badges";

export function DeploymentTagBadge({
    tag,
}: {
    tag: string | null | undefined;
}) {
    if (!tag) {
        return null;
    }
    return (
        <Badge
            role="button"
            aria-haspopup="menu"
            className="!bg-cycle-purple cursor-pointer"
        >
            {tag}
        </Badge>
    );
}
