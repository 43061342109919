import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Container } from "~/services/cycle";
import { Badge } from "@cycleplatform/ui/components/badges";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { faRocketLaunch } from "@fortawesome/pro-duotone-svg-icons";

type ContainerDeploymentBadgeProps = {
    container: Container;
    className?: string;
};

export function ContainerDeploymentBadge({
    container,
    className,
}: ContainerDeploymentBadgeProps) {
    if (!container.deployment) {
        return null;
    }

    return (
        <div>
            <Badge
                className={classNames(
                    className,
                    "flex items-center gap-2 text-xs",
                    !container && skeletonStyles,
                    "dark:bg-cycle-gray items-center gap-2 whitespace-nowrap px-2 !text-xs leading-4 text-white"
                )}
            >
                <FontAwesomeIcon
                    icon={faRocketLaunch}
                    className="text-warning"
                />
                {container.deployment.version}
            </Badge>
        </div>
    );
}
