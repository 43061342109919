import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { CodeHighlighter } from "~/components/common/forms/CodeEditor";
import { Container } from "~/services/cycle";

type AnnotationsProps = {
    container?: Container;
};

export function Annotations({ container }: AnnotationsProps) {
    return (
        <Panel>
            <PanelTitle title="Annotations" />
            <PanelContent>
                <p className="mb-4">
                    Annotations are custom data that was added to this container
                    via the API.
                </p>
                {container ? (
                    <CodeHighlighter
                        code={printContainerAnnotations(container)}
                        language="json"
                    />
                ) : (
                    <SkeletonButton />
                )}
            </PanelContent>
        </Panel>
    );
}

function printContainerAnnotations(container: Container) {
    try {
        return JSON.stringify(container.annotations);
    } catch (e) {
        return `Unable to parse container annotations`;
    }
}
