import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
    DialogContent,
    DialogHeader,
    DialogBody,
} from "@cycleplatform/ui/components/dialog/components";

import { useAppDispatch, useAppSelector } from "~/hooks";
import {
    clearError,
    getCurrentForm,
    initializeForm,
    terminateForm,
} from "~/modules/currentForm/slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearDialogParams } from "../helpers";
import { MultiStepDialogContext } from "./context";
import { StepProgress } from "./components/StepProgress";

type MultiStepStyledFormDialogProps = {
    isOpen?: boolean;
    title?: React.ReactNode;
    className?: string;
    icon?: IconDefinition;
    children: React.ReactNode;
    onClose?: () => void;
    isCloseable?: boolean;
};

export function MultiStepFormDialog({
    isOpen,
    className,
    icon,
    children,
    title,
    isCloseable = true,
    onClose: customOnClose,
}: MultiStepStyledFormDialogProps) {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [stepNames, setStepNames] = useState<string[]>([]);

    const currentForm = useAppSelector(getCurrentForm);

    const currentIdx = parseInt(searchParams.get("dialog-step") || "0");

    const handleNext = () => {
        if (currentForm?.error && currentForm?.error.step === currentIdx) {
            dispatch(clearError());
        }

        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", (currentIdx + 1).toString());
        setSearchParams(sp);
    };

    const handleBack = () => {
        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", (currentIdx - 1).toString());
        setSearchParams(sp);
    };

    const navigateToStep = (idx: number) => {
        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", idx.toString());
        setSearchParams(sp);
    };

    const onClose = () => {
        if (customOnClose) {
            customOnClose();
            return;
        }
        dispatch(terminateForm());
        nav(clearDialogParams());
    };

    useEffect(() => {
        if (!currentForm) {
            dispatch(initializeForm());
        }
    }, []);

    return (
        <MultiStepDialogContext.Provider
            value={{
                onClose,
                currentIdx,
                handleNext,
                handleBack,
                navigateToStep,
                stepNames,
                setStepNames,
            }}
        >
            <Dialog
                open={isOpen}
                onClose={onClose}
                className={classNames("relative z-50 ", className)}
            >
                <DialogBody
                    isCloseable={isCloseable}
                    onClose={onClose}
                    className="max-w-[85rem]"
                >
                    <DialogHeader icon={icon} title={title}>
                        <StepProgress />
                    </DialogHeader>
                    <DialogContent>{children}</DialogContent>
                </DialogBody>
            </Dialog>
        </MultiStepDialogContext.Provider>
    );
}
