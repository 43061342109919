import { useAppSelector } from "~/hooks";
import { selectActiveHubId } from "~/modules/hubs/slice";
import {
    extractPortalSubdomain,
    getSubdomains,
} from "./form/step3/components/hosted/manageHostedDomain/helpers";
import { CopyInput, TextInput } from "@cycleplatform/ui/components/forms";

export function ConfigurationRecords({
    isHosted,
    isAuthoritative,
    origin,
}: {
    isHosted: boolean;
    isAuthoritative: boolean;
    origin: string;
}) {
    const activeHubId = useAppSelector(selectActiveHubId);
    const originSubdomain = getSubdomains(origin).subdomains;
    const portalSubdomain = extractPortalSubdomain(window.location.hostname);

    const portalSubdomainString = portalSubdomain ? `-${portalSubdomain}` : "";

    // !hosted => return []
    if (!isHosted) {
        return [];
    }

    if (!isAuthoritative) {
        const key = originSubdomain
            ? `[subdomain].${originSubdomain}`
            : "[subdomain]";
        return (
            <div className="flex w-full flex-col gap-4 pt-4 ">
                <h3 className="mb-0 text-lg">Verify Hub</h3>

                <div className="flex gap-2 ">
                    <CopyInput
                        prefix="TXT"
                        value={`cycle-verify${
                            originSubdomain ? `.${originSubdomain}` : ""
                        }`}
                        wrapperClassName="w-full"
                    />
                    <CopyInput
                        prefixClassName="w-[6rem]"
                        value={activeHubId}
                        wrapperClassName="w-full"
                    />
                </div>

                <h3 className="mb-0 text-lg">Delegate Each Subdomain</h3>

                <div className="flex gap-4">
                    <div className="w-full">
                        <TextInput
                            readOnly
                            value={key}
                            prefix="NS"
                            className="text-base "
                        />
                    </div>
                    <CopyInput
                        value={`ns1${portalSubdomainString}.cycle.io`}
                        wrapperClassName="w-full"
                    />
                </div>
                <div className="flex gap-4">
                    <div className="w-full">
                        <TextInput
                            readOnly
                            value={key}
                            prefix="NS"
                            className="text-base "
                        />
                    </div>

                    <CopyInput
                        value={`ns2${portalSubdomainString}.cycle.io`}
                        wrapperClassName="w-full"
                    />
                </div>
            </div>
        );
    }

    if (originSubdomain) {
        return (
            <div className="flex flex-col gap-4 pt-4">
                <div className="flex gap-4">
                    <CopyInput
                        prefix="NS"
                        value={originSubdomain || ""}
                        wrapperClassName="w-full"
                    />
                    <CopyInput
                        value={`ns1${portalSubdomainString}.cycle.io`}
                        wrapperClassName="w-full"
                    />
                </div>
                <div className="flex gap-4">
                    <CopyInput
                        prefix="NS"
                        value={originSubdomain || ""}
                        wrapperClassName="w-full"
                    />
                    <CopyInput
                        value={`ns2${portalSubdomainString}.cycle.io`}
                        wrapperClassName="w-full"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="flex max-w-[30rem] flex-col gap-4 pt-4">
            <CopyInput value={`ns1${portalSubdomainString}.cycle.io`} />
            <CopyInput value={`ns2${portalSubdomainString}.cycle.io`} />
            <CopyInput value={`ns3${portalSubdomainString}.cycle.io`} />
            <CopyInput value={`ns4${portalSubdomainString}.cycle.io`} />
        </div>
    );
}
