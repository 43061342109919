import { PageContent } from "@cycleplatform/ui/components/page";
import {
    DeployStackBuild,
    Stack,
    StackBuild,
    useCreateStackBuildJobMutation,
    useGetContainersQuery,
} from "~/services/cycle";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import { Controller, useForm } from "react-hook-form";
import {
    RhfFormField,
    RhfFormProvider,
    required,
    FormSectionHeader,
    FormSection,
    TextInput,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { faRocket } from "@fortawesome/pro-solid-svg-icons";
import {
    ButtonLink,
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import {
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { useJobTracker } from "~/modules/jobs/hooks";
import { NavIcons } from "~/components/layout/NavIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPartyHorn } from "@fortawesome/pro-duotone-svg-icons";
import { handleSubmitError } from "~/components/forms/util";
import { Tab } from "@headlessui/react";
import { OptionTab } from "@cycleplatform/ui/components/options";
import { useState } from "react";
import { DefaultFormSection } from "./DefaultFormSection";
import { DeploymentFormSection } from "./DeploymentFormSection";
import { AccessControlOverlay } from "~/components/common/buttons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type StackBuildDeployProps = {
    stack?: Stack;
    build?: StackBuild;
};

export function StackBuildDeploy({ stack, build }: StackBuildDeployProps) {
    const form = useForm<DeployStackBuild["contents"]>();
    const {
        control,
        watch,
        unregister,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = form;
    const stackContainers = build?.spec?.containers;
    const isLive = build?.state?.current === "live";
    const envId = watch("environment_id");

    const { data: envContainers, error } = useGetContainersQuery(
        {
            page: {
                size: 100,
                number: 1,
            },
            meta: ["instances_count", "domains"],
            include: ["environments", "images", "stacks"],
            filter: {
                environment: envId || "",
            },
        },
        { skip: !envId }
    );

    const [createStackBuildJob] = useCreateStackBuildJobMutation();
    const [trackJob] = useJobTracker();

    const [deployTriggered, setDeployTriggered] = useState(false);

    const onSubmit = async (data: DeployStackBuild["contents"]) => {
        setDeployTriggered(true);
        return trackJob(
            createStackBuildJob({
                stackId: build?.stack_id || "",
                buildId: build?.id || "",
                body: {
                    action: "deploy",
                    contents: data,
                },
            }).unwrap()
        ).catch((err) => {
            setDeployTriggered(false);
            handleSubmitError(form.setError)(err);
        });
    };

    if (error) {
        throw error;
    }

    return (
        <DialogPageBody>
            <RhfFormProvider {...form}>
                <PageContent>
                    <AccessControlledSection
                        aclResource={stack}
                        verifyFn={modifyAccessFn("stacks-builds-deploy")}
                    >
                        <Panel>
                            <PanelTitle title="Deployment Options" />
                            {deployTriggered ? (
                                <DeployingPanel
                                    isSubmitting={isSubmitting}
                                    envId={envId}
                                />
                            ) : (
                                <PanelContent className="relative">
                                    {build ? (
                                        <>
                                            {isLive ? (
                                                <>
                                                    <FormSectionHeader header="General" />
                                                    <FormSection>
                                                        <RhfFormField
                                                            name="environment"
                                                            label="Environment"
                                                            required
                                                        >
                                                            <Controller
                                                                render={({
                                                                    field: {
                                                                        ref: _ref,
                                                                        ...field
                                                                    },
                                                                }) => (
                                                                    <EnvironmentSelect
                                                                        {...field}
                                                                        placeholder="Select an Environment..."
                                                                    />
                                                                )}
                                                                rules={{
                                                                    ...required(),
                                                                }}
                                                                control={
                                                                    control
                                                                }
                                                                name="environment_id"
                                                            />
                                                        </RhfFormField>
                                                    </FormSection>
                                                    {!!envId && (
                                                        <Tab.Group>
                                                            <Tab.List className="mb-4 flex gap-2">
                                                                <OptionTab
                                                                    title="Direct"
                                                                    description="Deploy containers to environment with no set deployment version."
                                                                    icon={
                                                                        NavIcons[
                                                                            "stacks"
                                                                        ]
                                                                    }
                                                                    onClick={() =>
                                                                        unregister(
                                                                            "deployment"
                                                                        )
                                                                    }
                                                                />
                                                                <OptionTab
                                                                    title="Deployment"
                                                                    description="Deploy containers to a specific deployment version within the environment."
                                                                    icon={
                                                                        NavIcons[
                                                                            "deploy"
                                                                        ]
                                                                    }
                                                                />
                                                            </Tab.List>
                                                            <Tab.Panels>
                                                                <Tab.Panel>
                                                                    <DefaultFormSection
                                                                        stackContainers={
                                                                            stackContainers
                                                                        }
                                                                        envContainers={
                                                                            envContainers?.data
                                                                        }
                                                                    />
                                                                </Tab.Panel>
                                                                <Tab.Panel>
                                                                    <DeploymentFormSection
                                                                        stackContainers={
                                                                            stackContainers
                                                                        }
                                                                        envContainers={
                                                                            envContainers?.data
                                                                        }
                                                                    />
                                                                </Tab.Panel>
                                                            </Tab.Panels>
                                                        </Tab.Group>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="pb-4">
                                                    <InfoPanel>
                                                        Only live builds can be
                                                        deployed
                                                    </InfoPanel>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <SkeletonHeader />
                                            <FormSection>
                                                <SkeletonText size="md" />
                                                <TextInput
                                                    className={skeletonStyles}
                                                />
                                            </FormSection>
                                        </>
                                    )}

                                    <PanelFooter>
                                        <div>
                                            <RhfGlobalFormError />
                                        </div>
                                        <AccessControlOverlay
                                            aclResource={stack}
                                            verifyFn={modifyAccessFn(
                                                "stacks-builds-deploy"
                                            )}
                                        >
                                            <PushAndHoldButton
                                                icon={faRocket}
                                                flavor="primary"
                                                disabled={!isLive || !isDirty}
                                                isLoading={isSubmitting}
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Deploy
                                            </PushAndHoldButton>
                                        </AccessControlOverlay>
                                    </PanelFooter>
                                </PanelContent>
                            )}
                        </Panel>
                    </AccessControlledSection>
                </PageContent>
            </RhfFormProvider>
        </DialogPageBody>
    );
}

function DeployingPanel({
    isSubmitting,
    envId,
}: {
    isSubmitting: boolean;
    envId: string;
}) {
    return (
        <PanelContent>
            <div className="flex items-center justify-center gap-4 py-8 pt-4 text-4xl">
                <FontAwesomeIcon
                    icon={isSubmitting ? NavIcons["deploy"] : faPartyHorn}
                    className="text-cycle-blue text-4xl"
                />

                <h3>
                    {isSubmitting ? "Deploying..." : "Successfully Deployed!"}
                </h3>
            </div>
            <PanelFooter>
                {isSubmitting ? (
                    <LoaderButton
                        icon={faRocket}
                        flavor="primary"
                        isLoading={isSubmitting}
                    >
                        Deploy
                    </LoaderButton>
                ) : (
                    <ButtonLink
                        to={`environments/${envId}`}
                        flavor="primary"
                        icon={NavIcons["environments"]}
                    >
                        Continue to Environment
                    </ButtonLink>
                )}
            </PanelFooter>
        </PanelContent>
    );
}
