import { useEffect, useState } from "react";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { ResourceWithId, ResourceWithIdOrIdentifier } from "~/modules/resource";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { resourcesToResourceById } from "../resources/util";
export type HybridMultiSelectProps<T extends ResourceWithIdOrIdentifier> = {
    name: string;
    value: string[] | undefined | null;
    resources: T[];
    disabled?: boolean;
    onChange: (id: string[] | undefined | null) => void;
    placeholder?: string;
    formatDisplayValue: (v: T) => React.ReactNode;
    formatOption: (v: T) => React.ReactNode;
    creatablePlaceholder?: (val: string) => string;
    isLoading?: boolean;
};

export function HybridMultiSelect<T extends ResourceWithId>({
    name,
    value,
    resources,
    disabled,
    onChange,
    placeholder,
    formatDisplayValue,
    formatOption,
    creatablePlaceholder = (v) => `Match to identifier "${v}"`,
    isLoading,
}: HybridMultiSelectProps<T>) {
    const [resourceById, setResourceById] = useState<Record<string, T>>(
        resourcesToResourceById(resources || [])
    );
    useEffect(() => {
        setResourceById(resourcesToResourceById(resources || []));
    }, [resources]);

    return (
        <MultiSelectInput
            name={name}
            options={resources?.map((c) => c.id)}
            isCreatable
            value={value}
            formatDisplayValue={(c) => {
                if (isLoading) {
                    return (
                        <div className="flex h-full items-center">
                            <div
                                className={classNames(
                                    skeletonStyles,
                                    "h-[75%] w-[4rem]"
                                )}
                            />
                        </div>
                    );
                }
                if (!c) return "";
                const resource = resourceById[c];

                if (resource) {
                    return formatDisplayValue(resource);
                }
                return (
                    <Tooltip
                        className="flex items-center gap-1 pl-1"
                        message="identifier"
                    >
                        <FontAwesomeIcon
                            icon={faTag}
                            className="text-cycle-blue"
                        />
                        {c}
                    </Tooltip>
                );
            }}
            formatOption={(c) => {
                const resource = resourceById[c];

                if (!resource) {
                    return null;
                }

                return formatOption(resource);
            }}
            onChange={(val: string[] | null) => {
                if (!val) {
                    onChange(null);
                    return;
                }
                onChange(val);
            }}
            disabled={disabled}
            creatablePlaceholder={creatablePlaceholder}
            placeholder={placeholder}
        />
    );
}
