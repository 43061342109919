import {
    VolumeSummary,
    InstanceVolume,
    InstanceVolumeSftp,
} from "~/services/cycle";

interface LocalVolumeType {
    type: "local";
    volume: NonNullable<VolumeSummary["config"]["local"]>;
}

type VolumeType = LocalVolumeType;

export function getVolumeType(
    volume: VolumeSummary | InstanceVolume
): VolumeType | null {
    const types = ["local"] as const;

    const type = types.find((t) => volume.config[t] !== undefined);

    if (!type) {
        return null;
    }

    const typedVolume = volume.config[type];
    if (!typedVolume) {
        return null;
    }

    return {
        type: type,
        volume: typedVolume,
    };
}
export function getSftpCommand(sftp: InstanceVolumeSftp) {
    return `sftp -P ${sftp.port} ${sftp.username}@${sftp.host}`;
}

export function isVolumeDeployed(v: InstanceVolume) {
    // The ID for a volume is 00000 until it has been instantiated
    // check to see if the Id has values that are not zero -> if true -> it has been deployed
    return /[^0]/.test(v.deployed.id);
}

export function formatSFTPUrl(v: InstanceVolume) {
    let password = "";
    if (!v.sftp) {
        return "";
    }
    if (
        v.config.remote_access &&
        v.config.remote_access.password &&
        v.config.remote_access.password.algorithm === "raw"
    ) {
        password = `:${v.config.remote_access.password.data}`;
    }

    return `sftp://${v.sftp.username}${password}@${v.sftp.host}:${v.sftp.port}`;
}

export function getVolumeMaxSize(v: VolumeSummary) {
    const type = getVolumeType(v);

    if (!type) {
        return null;
    }

    switch (type.type) {
        case "local":
            return type.volume.max_size;
        default:
            return null;
    }
}
