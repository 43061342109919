import { useCallback, useEffect, useState } from "react";

export const useNavigatorOnline = () => {
    const [status, setStatus] = useState(getOnlineStatus());

    const setOnline = useCallback(() => setStatus(true), []);
    const setOffline = useCallback(() => setStatus(false), []);

    useEffect(() => {
        window.addEventListener("online", setOnline);
        window.addEventListener("offline", setOffline);

        return () => {
            window.removeEventListener("online", setOnline);
            window.removeEventListener("offline", setOffline);
        };
    }, []);

    return status;
};

const getOnlineStatus = () =>
    typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
        ? navigator.onLine
        : true;
