import { RecentActivityLog } from "~/components/logs/activity";
import { LogsLoadingFallback } from "../logs/LogsLoadingFallback";

type MemberRecentActivityProps = {
    accountId?: string;
};

export function MemberRecentActivity({ accountId }: MemberRecentActivityProps) {
    if (!accountId) {
        return <LogsLoadingFallback />;
    }
    return <RecentActivityLog accountId={accountId} />;
}
