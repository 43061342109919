import classNames from "classnames";

type HeaderTabListProps = {
    children: React.ReactNode;
    className?: string;
};

export function HeaderTabList({ className, children }: HeaderTabListProps) {
    return (
        <nav className={classNames(className, "my-2 flex gap-0 ")}>
            {children}
        </nav>
    );
}
