import { Button } from "@cycleplatform/ui/components/buttons";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faPartyHorn } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Account } from "~/services/cycle";

type BrowserNotificationsProps = {
    account: Account | undefined;
};

export function BrowserNotifications({ account }: BrowserNotificationsProps) {
    const { getPushPermission, permission } = useManageBrowserNotifications();

    return (
        <Panel>
            <PanelTitle title="Hub Preferences" />
            <PanelContent>
                <p className="pb-2">
                    Enable browser push notifications to receive important
                    alerts when not focused on the portal.
                </p>
                {permission === "granted" ? (
                    <InfoPanel>
                        <FontAwesomeIcon icon={faPartyHorn} /> You&apos;ve
                        enabled browser notifications!
                    </InfoPanel>
                ) : permission === "default" ? (
                    <Button onClick={getPushPermission}>
                        Enable Push Notifications
                    </Button>
                ) : (
                    <InfoPanel type="warning">
                        You&apos;ve blocked browser notifications. Refer to your
                        browser documentation for how to unblock them.
                    </InfoPanel>
                )}
            </PanelContent>
        </Panel>
    );
}

function useManageBrowserNotifications() {
    const [permission, setPermission] = useState(
        "Notification" in window ? Notification.permission : "denied"
    );
    const getPushPermission = () =>
        Notification.requestPermission().then((p) => {
            setPermission(p);
            if (p === "granted") {
                pushCycleNotification(
                    "Notifications Enabled",
                    "Cycle will now alert you to completed and failed jobs, and other important events."
                );
            }
        });

    return { permission, getPushPermission };
}

function pushCycleNotification(title: string, message: string) {
    return new Notification(title, { body: message });
}
