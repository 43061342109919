import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC } from "react";
import { Link, To } from "react-router-dom";

export type Breadcrumb = {
    label: string;
    /** Omit if current route */
    link?: To;
};

type BreadcrumbProps = {
    trail: Breadcrumb[];
    className?: string;
};

export const Breadcrumb: FC<BreadcrumbProps> = ({ trail, className }) => {
    return (
        <nav className={classNames("flex items-center gap-2", className)}>
            {trail.map((bc, idx) => (
                <React.Fragment key={idx}>
                    <div>
                        {bc.link ? (
                            <Link to={bc.link}>{bc.label}</Link>
                        ) : (
                            <span>{bc.label}</span>
                        )}
                    </div>
                    {idx !== trail.length - 1 ? (
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="text-cycle-blue"
                        />
                    ) : null}
                </React.Fragment>
            ))}
        </nav>
    );
};
