import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    faLockKeyhole,
    faUnlock,
    faAsterisk,
    faArrowRight,
    faCircleChevronRight,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimeFormats } from "@cycleplatform/core/util";
import { isLinkedTypeRecord } from "@cycleplatform/core/modules/dns/records";
import { format, isBefore, sub } from "date-fns";
import { Link } from "react-router-dom";
import { DeploymentTagBadge } from "~/components/environments/deployments";
import { FieldEntry } from "./fields/FieldEntry";
import { FieldName } from "./fields/FieldName";
import { FieldContent } from "./fields/FieldContent";
import {
    Container,
    useGetContainersQuery,
    useGetEnvironmentQuery,
} from "~/services/cycle";
import { DnsRecordFieldsProps } from "./DnsRecordsFields";
import { CycleErrorBoundary } from "~/components/common/errors";
import { RestrictedField } from "~/components/common/errors/RestrictedField";
import { CycleApiErrorResponse } from "~/services/helpers";
import { ContainerDeploymentBadge } from "~/components/containers/ContainerDeploymentBadge";

export function LinkedRecordFieldEntry(props: DnsRecordFieldsProps) {
    if (!isLinkedTypeRecord(props.record)) {
        return null;
    }

    if (
        "deployment" in props.record.type.linked &&
        props.record.type.linked.deployment
    ) {
        return (
            <CycleErrorBoundary
                fallbackWrapper={(_, error) => {
                    return (
                        <>
                            <FieldEntry>
                                <FieldName fieldName="Container" />
                                <FieldContent>
                                    <RestrictedField
                                        error={error as CycleApiErrorResponse}
                                        fieldLength="lg"
                                    />
                                </FieldContent>
                            </FieldEntry>
                            <FieldEntry>
                                <FieldName fieldName="Tag" />
                                <FieldContent>
                                    <RestrictedField
                                        error={error as CycleApiErrorResponse}
                                        fieldLength="sm"
                                    />
                                </FieldContent>
                            </FieldEntry>
                        </>
                    );
                }}
            >
                <DeploymentFieldEntry
                    deployment={props.record.type.linked.deployment}
                    {...props}
                />
            </CycleErrorBoundary>
        );
    }

    const container =
        "container_id" in props.record.type.linked
            ? props.recordIncludes?.containers?.[
                  props.record.type.linked.container_id || ""
              ]
            : undefined;

    return (
        <CycleErrorBoundary
            fallbackWrapper={(_, error) => {
                return (
                    <>
                        <FieldEntry>
                            <FieldName fieldName="Container" />
                            <FieldContent>
                                <RestrictedField
                                    error={error as CycleApiErrorResponse}
                                    fieldLength="lg"
                                />
                            </FieldContent>
                        </FieldEntry>
                    </>
                );
            }}
        >
            <ContainerFieldEntry container={container} {...props} />
        </CycleErrorBoundary>
    );
}

function DeploymentFieldEntry({
    deployment,
    ...props
}: {
    deployment: {
        environment_id: string;
        match: {
            container: string;
            tag?: string | null | undefined;
        };
    };
} & DnsRecordFieldsProps) {
    const { data: containers, error: containerError } = useGetContainersQuery({
        filter: {
            identifier: deployment.match.container,
            environment: deployment.environment_id,
        },
    });

    const { data: env, error: envError } = useGetEnvironmentQuery({
        environmentId: deployment.environment_id,
    });

    const matchedContainer = containers?.data?.find(
        (c) =>
            c.deployment?.version ===
            env?.data?.deployments?.tags?.[deployment?.match?.tag || ""]
    );

    if (envError) {
        throw envError;
    }
    if (containerError) {
        throw containerError;
    }

    return (
        <>
            <FieldEntry>
                <FieldName fieldName="Container" className="pr-2" />

                <FieldContent className="flex items-center">
                    {matchedContainer ? (
                        <>
                            {env ? (
                                <>
                                    <Link to={`/environments/${env?.data?.id}`}>
                                        {env?.data?.name}
                                    </Link>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="text-xs "
                                    />
                                </>
                            ) : null}

                            <Link
                                to={generateDialogLink("container", {
                                    "dialog-container-id": matchedContainer.id,
                                })}
                            >
                                {deployment.match.container}
                            </Link>
                        </>
                    ) : (
                        <>{deployment.match.container}</>
                    )}
                    <TlsMessage {...props} />
                </FieldContent>
            </FieldEntry>

            <FieldEntry>
                <FieldName fieldName="Tag" className="pr-2" />

                <FieldContent className="flex items-center">
                    <Link to={`/environments/${env?.data?.id}/deployments`}>
                        <DeploymentTagBadge tag={deployment.match.tag} />
                    </Link>
                </FieldContent>
            </FieldEntry>
        </>
    );
}

function ContainerFieldEntry({
    container,
    ...props
}: { container: Container | null | undefined } & DnsRecordFieldsProps) {
    const { data: env, error: envError } = useGetEnvironmentQuery(
        {
            environmentId: container?.environment?.id || "",
        },
        { skip: !container }
    );

    if (envError) {
        throw envError;
    }

    return (
        <>
            <FieldEntry>
                <FieldName fieldName="Container" className="pr-2" />
                <FieldContent className="flex items-center">
                    {container ? (
                        <>
                            {env ? (
                                <>
                                    <Link to={`/environments/${env?.data?.id}`}>
                                        {env?.data?.name}
                                    </Link>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="text-xs "
                                    />
                                </>
                            ) : null}

                            <Link
                                to={generateDialogLink("container", {
                                    "dialog-container-id": container.id,
                                })}
                            >
                                {container?.name}
                            </Link>
                        </>
                    ) : (
                        <span>Not Assigned</span>
                    )}
                    <TlsMessage {...props} />
                </FieldContent>
            </FieldEntry>

            {container?.deployment?.version ? (
                <FieldEntry>
                    <FieldName fieldName="Deployment" className="pr-2" />
                    <FieldContent className="flex items-center">
                        <Link
                            to={`/environments/${container.environment.id}/deployments`}
                        >
                            <ContainerDeploymentBadge container={container} />
                        </Link>
                    </FieldContent>
                </FieldEntry>
            ) : null}
        </>
    );
}

function TlsMessage({ dnsZone, record }: DnsRecordFieldsProps) {
    const isHosted = dnsZone?.hosted;
    const certificate = record?.features?.certificate;
    const certificateExpired =
        certificate &&
        isBefore(
            new Date(certificate.generated),
            sub(new Date(), { days: 90 })
        );
    if (!isLinkedTypeRecord(record)) {
        return null;
    }
    return (
        <div className="flex items-center gap-2">
            <Tooltip
                message={
                    !isHosted
                        ? "TLS Certificate will generate if this zone becomes hosted"
                        : certificate && !certificateExpired
                        ? `TLS Certificate Generated On ${format(
                              new Date(certificate.generated),
                              DateTimeFormats["standard"]
                          )}`
                        : certificateExpired
                        ? "TLS Certificate is Expired"
                        : "Generating Certificate"
                }
                disabled={!record.type.linked.features.tls.enable}
            >
                {record.type.linked.features.tls.enable ? (
                    <FontAwesomeIcon
                        icon={faLockKeyhole}
                        className={
                            !isHosted
                                ? "text-cycle-gray"
                                : certificate && !certificateExpired
                                ? "text-success"
                                : certificateExpired
                                ? "text-error"
                                : "text-cycle-blue"
                        }
                    />
                ) : (
                    <FontAwesomeIcon icon={faUnlock} />
                )}
            </Tooltip>
            {record.features?.certificate?.wildcard_child ? (
                <Tooltip message="This record utilizes a wildcard TLS certificate">
                    <FontAwesomeIcon icon={faAsterisk} />
                </Tooltip>
            ) : null}
        </div>
    );
}
