import { RhfFormProvider } from "@cycleplatform/ui/components/forms";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import {
    StyledDataTable,
    StyledTableHead,
    StyledHeaderCell,
    StyledCell,
    StyledIconHeaderCell,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { faFileLines } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    Image,
    ImageIncludes2,
    ImageSource,
    useDeleteImageMutation,
} from "~/services/cycle";
import {
    getHumanizedDuration,
    getHumanizedTime,
} from "@cycleplatform/core/util/time";
import { ImportImageButton } from "../../../routes/images/sources/$sourceId/components/ImportImageButton";
import { ResourceState } from "@cycleplatform/ui/components/resources/state";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { formatBytesString } from "@cycleplatform/core/util/bytes";
import { RhfIdCheckboxSelectAll } from "~/components/common/forms/IdSelect/RhfIdCheckboxSelectAll";
import { RhfIdCheckbox } from "~/components/common/forms/IdSelect/RhfIdCheckbox";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { TableToolTray } from "~/components/common/tooltray/TableToolTray";
import { RhfMultipleActionButton } from "~/components/common/forms/IdSelect/RhfMultipleActionButton";
import { PanelContent, PanelFooter } from "@cycleplatform/ui/components/panels";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { SkeletonTableRows } from "@cycleplatform/ui/components/loaders/skeleton";
import classNames from "classnames";
import { useIdSelectForm } from "~/components/common/forms/IdSelect/hooks";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    getImageBackendName,
    isBuildLogEnabled,
} from "@cycleplatform/core/modules/images";
import { differenceInMilliseconds } from "date-fns";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type ImagesTableProps = {
    images: Image[] | undefined;
    imageIncludes: ImageIncludes2 | undefined;
    source?: ImageSource;
    compact?: boolean;
};
export function ImagesTable({
    images,
    imageIncludes,
    source,
    compact,
}: ImagesTableProps) {
    const form = useIdSelectForm();
    const { watch } = form;
    const ids = watch("ids");
    const [deleteImage] = useDeleteImageMutation();

    const isEmpty = images && images.length === 0;

    const isDeleted = source?.state.current === "deleted";

    if (isEmpty && source) {
        return (
            <EmptyResource title="No Images" icon={NavIcons["images"]} stretch>
                <div className="w-full ">
                    <p className="pb-4 text-center">
                        {!!isDeleted
                            ? "This image source has been deleted"
                            : "Click the button below to import"}
                    </p>
                    {!isDeleted && (
                        <PanelFooter className="pr-4">
                            <ImportImageButton source={source} />
                        </PanelFooter>
                    )}
                </div>
            </EmptyResource>
        );
    }

    return (
        <RhfFormProvider {...form}>
            <PanelContent
                stretch
                className={classNames(ids.length && "rounded-b-none")}
            >
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledIconHeaderCell>
                            <RhfIdCheckboxSelectAll resources={images} />
                        </StyledIconHeaderCell>
                        <StyledHeaderCell>Name</StyledHeaderCell>
                        <StyledHeaderCell className="min-w-[6rem] ">
                            State
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-[10rem]">
                            Storage
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-[10rem]">
                            Builder
                        </StyledHeaderCell>
                        {!compact ? (
                            <StyledHeaderCell className="w-[6rem]">
                                Usage
                            </StyledHeaderCell>
                        ) : null}
                        <StyledHeaderCell className="w-40 text-center">
                            Build Log
                        </StyledHeaderCell>
                    </StyledTableHead>
                    <tbody>
                        {images ? (
                            <>
                                {images?.map((i) => {
                                    const builder =
                                        imageIncludes?.integrations?.[
                                            i.builder?.integration_id || ""
                                        ];

                                    return (
                                        <StyledTableRow key={i.id}>
                                            <StyledCell>
                                                <RhfIdCheckbox id={i.id} />
                                            </StyledCell>
                                            <StyledCell className="max-w-[18rem]">
                                                <div className="py-2 pr-4">
                                                    <Link
                                                        to={generateDialogLink(
                                                            "image",
                                                            {
                                                                "dialog-image-id":
                                                                    i.id,
                                                            }
                                                        )}
                                                    >
                                                        <div
                                                            className="w-[95%] shrink overflow-hidden text-ellipsis whitespace-nowrap"
                                                            style={{
                                                                direction:
                                                                    "rtl",
                                                            }}
                                                        >
                                                            {i.name}
                                                        </div>
                                                    </Link>
                                                    <div className="flex items-center gap-2 text-sm">
                                                        {getHumanizedTime(
                                                            i.events.created
                                                        )}
                                                        {i?.requires
                                                            .nvidia_gpu ? (
                                                            <div className="bg-cycle-gray-light dark:bg-cycle-gray rounded-md px-2 py-1 text-xs font-semibold">
                                                                NVIDIA GPU
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </StyledCell>
                                            <StyledCell>
                                                <ResourceState
                                                    state={i.state}
                                                />
                                            </StyledCell>
                                            <StyledCell>
                                                <div>
                                                    {getImageBackendName(
                                                        i as components["schemas"]["Image"]
                                                    )}
                                                </div>
                                                <div className="text-sm">
                                                    {formatBytesString(
                                                        i.size,
                                                        2
                                                    )}
                                                </div>
                                            </StyledCell>
                                            <StyledCell>
                                                <>
                                                    {builder?.name ||
                                                        "Cycle Factory"}
                                                </>

                                                {i.factory ? (
                                                    <div className="text-sm">
                                                        {getHumanizedDuration(
                                                            differenceInMilliseconds(
                                                                new Date(
                                                                    i.factory
                                                                        ?.cached ||
                                                                        ""
                                                                ),
                                                                new Date(
                                                                    i.factory
                                                                        ?.acknowledged ||
                                                                        ""
                                                                )
                                                            )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </StyledCell>
                                            {!compact ? (
                                                <StyledCell>
                                                    <>
                                                        {i.meta
                                                            ?.containers_count ||
                                                            0}{" "}
                                                        Containers
                                                    </>
                                                </StyledCell>
                                            ) : null}

                                            <StyledCell className="text-center">
                                                <BuildLogButton image={i} />
                                            </StyledCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </>
                        ) : (
                            <SkeletonTableRows columns={6} />
                        )}
                    </tbody>

                    {isEmpty ? (
                        <tbody>
                            <StyledTableRow>
                                <StyledCell colSpan={7}>
                                    <EmptyResource
                                        className="border-none"
                                        title="No Images Imported"
                                        icon={NavIcons["images"]}
                                    />
                                </StyledCell>
                            </StyledTableRow>
                        </tbody>
                    ) : null}
                </StyledDataTable>
            </PanelContent>

            <TableToolTray>
                <AccessControlOverlay
                    aclResource={source}
                    verifyFn={modifyAccessFn("images-manage")}
                >
                    <RhfMultipleActionButton
                        resourceName="images"
                        mutation={(id: string) =>
                            deleteImage({
                                imageId: id,
                            }).unwrap()
                        }
                    />
                </AccessControlOverlay>
            </TableToolTray>
        </RhfFormProvider>
    );
}

type BuildLogButtonProps = {
    image: Image;
};

function BuildLogButton({ image }: BuildLogButtonProps) {
    return (
        <ButtonLink
            disabled={
                !isBuildLogEnabled(image as components["schemas"]["Image"])
            }
            to={generateDialogLink("image", {
                "dialog-image-id": image.id,
                "dialog-tab": "build-log",
            })}
        >
            <FontAwesomeIcon icon={faFileLines} />
        </ButtonLink>
    );
}
