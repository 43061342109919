import { StackSpecContainer, Container } from "~/services/cycle";

export function sortContainers(
    envContainers: Container[] | undefined,
    stackContainers: Record<string, StackSpecContainer> | undefined,
    deployment?: string
) {
    return (
        envContainers &&
        stackContainers &&
        Object.entries(stackContainers).reduce(
            (acc, [scIdentifier, scSpec]) => {
                const a = envContainers?.find((c) => {
                    return (
                        c.identifier === scIdentifier &&
                        (deployment
                            ? c.deployment?.version === deployment
                            : !c.deployment?.version)
                    );
                });

                if (!!a) {
                    return {
                        ...acc,
                        existing: { ...acc.existing, [scIdentifier]: scSpec },
                    };
                }
                return {
                    ...acc,
                    new: { ...acc.new, [scIdentifier]: scSpec },
                };
            },
            { new: {}, existing: {} } as {
                new: Record<string, StackSpecContainer>;
                existing: Record<string, StackSpecContainer>;
            }
        )
    );
}
