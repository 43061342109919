export function immutableKeyDelete<T extends { [key: string]: any }>(
    obj: T,
    key: string | string[]
): T {
    const newKeys = Object.keys(obj).filter((k) =>
        Array.isArray(key) ? key.indexOf(k) === -1 : k !== key
    );

    const newObj: { [key: string]: any } = {};

    newKeys.forEach((k) => (newObj[k] = obj[k]));

    return newObj as T;
}

/** Grab all properties of an object T where the value of the prop is type "string" */
export type StringProps<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];
