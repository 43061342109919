import classNames from "classnames";

/**
 * FormFieldset is used as a wrapper for specific form sections.  This allows us a way to handle single sections that are disabled
 * The margin classnames are designed in such a way that the margin will "bubble" up to the top.  The last child will be
 * given an mb-0 which will allow
 */
export function FormFieldset({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <fieldset
            className={classNames(
                className,
                "mb-4 [&_*:last-child]:!mb-0",
                "group/fieldset"
            )}
        >
            {children}
        </fieldset>
    );
}
