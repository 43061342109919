import { faExternalLinkAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, LinkProps } from "react-router-dom";

type ExternalLinkProps = LinkProps;

/**
 * Standardizes an external link, to always open in a new tab, and always have the external
 * link icon next to the children (or just the icon if no children are provided)
 */
export function ExternalLink({ children, ...props }: ExternalLinkProps) {
    return (
        <Link target="_blank" referrerPolicy="no-referrer" {...props}>
            {children} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
    );
}
