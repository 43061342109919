import {
    Children,
    cloneElement,
    isValidElement,
    useContext,
    useEffect,
} from "react";
import { MultiStepDialogContext } from "../context";
import { FormStepProps } from "./MultiStepFormStep";

export function MultiStepFormSteps({
    children,
}: {
    children: React.ReactElement<FormStepProps>[];
}) {
    const { setStepNames } = useContext(MultiStepDialogContext);
    const childrenWithStepIdx = Children.map(children, (child, idx) => {
        if (isValidElement(child)) {
            return cloneElement(child, { stepIdx: idx });
        }
        return child;
    });

    const stepNames = Children.map(children, (child) => child.props.name);

    useEffect(() => {
        setStepNames(stepNames);
    }, []);

    return <>{childrenWithStepIdx}</>;
}
