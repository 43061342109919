import { PillButtons } from "@cycleplatform/ui/components/forms";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { useEffect, useState } from "react";
import { getSpecAsJSON, getSpecAsYAML } from "~/modules/jobs/utils";
import { StackSpec } from "~/services/cycle";
import prism from "prismjs";

type BuildSpecProps = {
    spec?: StackSpec | null;
    className?: string;
};

export function BuildSpec({ spec, className }: BuildSpecProps) {
    const yamlSpec = async (b?: StackSpec | null) => getSpecAsYAML(b);

    const [code, setCode] = useState<string>();
    const [mode, setMode] = useState<"json" | "yaml">("json");

    useEffect(() => {
        if (mode === "json") {
            setCode(getSpecAsJSON(spec));
        } else {
            yamlSpec(spec).then((v) => setCode(v));
        }
    }, [spec, mode]);

    useEffect(() => {
        prism.highlightAll();
    }, [code]);

    return (
        <Panel className={className}>
            <div className="flex w-full justify-between">
                <PanelTitle title="Build Spec" />
                <PillButtons
                    value={mode}
                    onChange={(v) => setMode(v as "json" | "yaml")}
                    options={[
                        {
                            value: "json",
                            label: "JSON",
                        },
                        {
                            value: "yaml",
                            label: "YAML",
                        },
                    ]}
                />
            </div>

            <pre className="h-[50rem] w-full ">
                <code className="language-javascript">{code}</code>
            </pre>
        </Panel>
    );
}
