import { Button } from "@cycleplatform/ui/components/buttons";
import { Checkbox, RhfDataSizeInput } from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { faEdit, faServer } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { VolumeStoragePoolSelect } from "~/components/containers";
import { useGetImageQuery } from "~/services/cycle";
import { CreateContainerSubmitType } from "../CreateContainerForm";

export function VolumesManager() {
    const { setValue, watch, control, register } =
        useFormContext<CreateContainerSubmitType>();
    const isStateful = watch("stateful");
    const imageId = watch("image_id");

    const { data: image } = useGetImageQuery(
        {
            imageId: imageId || "",
        },
        { skip: !imageId }
    );

    const imageVolumes = image?.data?.config?.volumes;

    useEffect(() => {
        const defaultVolumes = imageVolumes?.map((i) => ({
            read_only: false,
            local: {
                max_size: "5G",
                storage_pool: undefined,
            },
            block_device: false,
            destination: i.path || "",
            omit: false,
        }));

        setValue("volumes", defaultVolumes);
    }, [imageVolumes]);

    if (!imageId) {
        return null;
    }

    if (!imageVolumes || !imageVolumes?.length) {
        return (
            <InfoPanel>
                <span className="text-semibold">
                    There are no volumes associated with this image
                </span>
            </InfoPanel>
        );
    }

    return (
        <div>
            <InfoPanel>
                <span className="text-semibold">
                    {`Cycle has identified ${imageVolumes.length} volume associated with this image. Please configure it below.`}
                </span>
            </InfoPanel>

            {!isStateful ? (
                <InfoPanel type="alert" className="relative">
                    <div className="text-semibold py-2">
                        You selected an image with a volume but this container
                        is marked as stateless.
                    </div>
                    <Button
                        className="absolute right-2 top-2"
                        flavor="primary"
                        onClick={() => setValue("stateful", true)}
                        icon={faEdit}
                    >
                        Mark Stateful
                    </Button>
                </InfoPanel>
            ) : null}
            <div className="py-8">
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell>Path</StyledHeaderCell>
                        <StyledHeaderCell>Pool Options</StyledHeaderCell>
                        <StyledHeaderCell className="w-60">
                            Size
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-10">
                            Omit
                        </StyledHeaderCell>
                    </StyledTableHead>
                    <tbody>
                        {imageVolumes.map((v, idx) => (
                            <StyledTableRow key={v.path}>
                                <StyledCell>
                                    <div className="flex items-center gap-2">
                                        <FontAwesomeIcon
                                            icon={faServer}
                                            className="text-cycle-blue pt-1"
                                        />
                                        {v.path}
                                    </div>
                                </StyledCell>
                                <StyledCell>
                                    <div className="pr-8">
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => (
                                                <VolumeStoragePoolSelect
                                                    {...field}
                                                />
                                            )}
                                            control={control}
                                            name={`volumes.${idx}.local.storage_pool`}
                                        />
                                    </div>
                                </StyledCell>
                                <StyledCell>
                                    <div className="mr-8">
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => (
                                                <RhfDataSizeInput {...field} />
                                            )}
                                            control={control}
                                            name={`volumes.${idx}.local.max_size`}
                                        />
                                    </div>
                                </StyledCell>
                                <StyledCell>
                                    <Checkbox
                                        value={v.path}
                                        {...register(
                                            `formProps.omittedVolumes`
                                        )}
                                    />
                                </StyledCell>
                            </StyledTableRow>
                        ))}
                    </tbody>
                </StyledDataTable>
            </div>
        </div>
    );
}
