import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

export function FirewallDashboard() {
    return (
        <DialogPageBody className="w-full p-4">
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={NavIcons["firewall"]}
                title={`Load Balancer Firewall Telemetry Coming Soon`}
            />
        </DialogPageBody>
    );
}
