import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { useNavigate } from "react-router-dom";
import { clearDialogParams, isDialogOpen } from "../../helpers";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { TlsUserCertUploadForm } from "./TlsUserCertUploadForm";

export function TlsUserCertUploadDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title={`Add User TLS Certificate`}
            isOpen={isDialogOpen("tls-user-cert-upload")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <TlsUserCertUploadForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
