import {
    FormSectionHeader,
    FormSection,
    RhfFormField,
    Checkbox,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { useEffect } from "react";

export function SeccompSection() {
    const { register, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    useEffect(() => {
        // Since seccomp always comes back from the platform (as null, if not altered)
        // we need to ensure the nested value is not null. Thought this would happen with
        // defaultChecked on the input but it did not.
        if (getValues("config.runtime.seccomp.disable") === null) {
            setValue("config.runtime.seccomp.disable", false, {
                shouldDirty: false,
            });
        }
    }, []);

    return (
        <>
            <FormSectionHeader header="Seccomp" />
            <FormSection>
                <RhfFormField
                    label="Disabled"
                    name="config.runtime.seccomp.disable"
                    help=" Disabling seccomp will allow your containers
                    more flexibility to make system calls but
                    will yield a larger attack surface for
                    exploitations."
                >
                    <Checkbox {...register("config.runtime.seccomp.disable")} />
                </RhfFormField>
            </FormSection>
        </>
    );
}
