import { components } from "@cycleplatform/core/modules/api/__generated";

export function FormattedImageBuildDetails({
    builds,
}: {
    builds: components["schemas"]["BillingBuilds"];
}) {
    return Object.entries(builds)
        .filter(([k]) => !["parallel", "max_daily_builds"].includes(k))
        .map(([k, v]) => {
            let fk;
            switch (k) {
                case "parallel": {
                    fk = "Parallel Builds";
                    break;
                }
                case "cpu_cores": {
                    fk = "CPU Cores";
                    break;
                }
                case "ram_gb": {
                    fk = "GB Usable RAM";
                    break;
                }
                case "max_daily_builds": {
                    fk = "Maximum Daily Builds";
                    break;
                }
            }
            return (
                <div key={fk}>
                    <strong>{v}</strong> <span>{fk}</span>
                </div>
            );
        });
}
