import {
    TextInput,
    RhfFormField,
    FormSectionHeader,
    FormSection,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import validator from "validator";
import { useContext } from "react";
import { ContainerHybridMultiSelect } from "~/components/containers/ContainerHybridMultiSelect";
import { LoadBalancerFormValues } from "../../../../../../form";
import { LoadBalancerDialogContext } from "../../../../../../../context";

export function V1RouterMatch({
    baseRoute,
    idx,
}: {
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
}) {
    const { register, control } = useFormContext<LoadBalancerFormValues>();

    const { environment } = useContext(LoadBalancerDialogContext);

    return (
        <>
            <FormSectionHeader header="Match" />

            <FormSection>
                <InputRow>
                    <RhfFormField
                        name={`${baseRoute}.${idx}.match.domains`}
                        label="Match Domain(s)"
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <MultiSelectInput
                                    {...field}
                                    onChange={(e) => {
                                        return field.onChange(
                                            e && e.length ? e : []
                                        );
                                    }}
                                    value={field.value || []}
                                    placeholder="Select domains"
                                    isCreatable={true}
                                    formatDisplayValue={(v) => v}
                                    formatOption={(v) => (
                                        <FormattedOption label={v} />
                                    )}
                                    isCreateValueValid={(v) =>
                                        validator.isURL(v)
                                    }
                                />
                            )}
                            control={control}
                            name={`${baseRoute}.${idx}.match.domains`}
                        />
                    </RhfFormField>

                    <RhfFormField
                        name={`${baseRoute}.${idx}.match.internal_ports`}
                        label="Match Internal Port(s)"
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <MultiSelectInput
                                    {...field}
                                    onChange={(e) => {
                                        return field.onChange(
                                            e && e.length
                                                ? e.map((v) => Number(v))
                                                : []
                                        );
                                    }}
                                    value={field.value || []}
                                    placeholder="Select internal ports"
                                    isCreatable={true}
                                    formatDisplayValue={(v) => `${v}`}
                                    formatOption={(v) => (
                                        <FormattedOption label={v} />
                                    )}
                                    isCreateValueValid={(v) => !!Number(v)}
                                />
                            )}
                            control={control}
                            name={`${baseRoute}.${idx}.match.internal_ports`}
                        />
                    </RhfFormField>

                    <RhfFormField
                        name={`${baseRoute}.${idx}.match.path`}
                        label="Match Path"
                    >
                        <TextInput
                            {...register(`${baseRoute}.${idx}.match.path`)}
                        />
                    </RhfFormField>
                </InputRow>
                <InputRow>
                    <RhfFormField
                        label="Include Containers"
                        name={`${baseRoute}.${idx}.match.containers.include`}
                    >
                        <Controller
                            name={`${baseRoute}.${idx}.match.containers.include`}
                            render={({ field: { ref: _ref, ...field } }) => (
                                <ContainerHybridMultiSelect
                                    {...field}
                                    filter={{
                                        environment: environment?.id || "",
                                    }}
                                    creatablePlaceholder={(v) =>
                                        `Match to container identifier "${v}"`
                                    }
                                />
                            )}
                            control={control}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Exclude Containers"
                        name={`${baseRoute}.${idx}.match.containers.exclude`}
                    >
                        <Controller
                            name={`${baseRoute}.${idx}.match.containers.exclude`}
                            render={({ field: { ref: _ref, ...field } }) => (
                                <ContainerHybridMultiSelect
                                    {...field}
                                    filter={{
                                        environment: environment?.id || "",
                                    }}
                                    creatablePlaceholder={(v) =>
                                        `Match to container identifier "${v}"`
                                    }
                                />
                            )}
                            control={control}
                        />
                    </RhfFormField>
                </InputRow>
            </FormSection>
        </>
    );
}
