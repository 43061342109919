import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";

export function HoverSecret({
    value,
    className,
    isObscured,
}: {
    value?: string;
    className?: string;
    isObscured?: boolean;
}) {
    const [clicked, setClicked] = useState(false);

    const obscured =
        value &&
        Array(8)
            .fill(undefined)
            .map((_, idx) => (
                <FontAwesomeIcon
                    key={`${value}-${idx}`}
                    icon={faCircle}
                    className="px-[0.1rem] text-[0.5rem]"
                />
            ));
    return (
        <div className={classNames("w-[95%]  ", "group", className)}>
            {!isObscured ? (
                <div className="truncate">{value}</div>
            ) : (
                <>
                    <div
                        className={classNames(
                            "hidden  truncate group-hover:block",
                            !clicked && "hover:cursor-pointer"
                        )}
                        onClick={() => setClicked(true)}
                    >
                        {clicked ? value : "Click to reveal"}
                    </div>

                    <div className="block truncate group-hover:hidden">
                        {clicked ? value : obscured}
                    </div>
                </>
            )}
        </div>
    );
}
