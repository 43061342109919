import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextAreaInput,
} from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    UploadUserSuppliedCertificateApiArg,
    useUploadUserSuppliedCertificateMutation,
} from "~/services/cycle";
import { useNavigate } from "react-router-dom";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { clearDialogParams, getAllDialogSearchParams, shouldClose } from "../../helpers";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type FormValues = UploadUserSuppliedCertificateApiArg["body"];

export function TlsUserCertUploadForm() {
    const nav = useNavigate();
    const form = useForm<FormValues>({
        defaultValues: {
            private_key: "",
            bundle: "",
        },
        ...rhfConfig,
    });
    const params = getAllDialogSearchParams<"tls-user-cert-upload">();

    const {
        handleSubmit,
        register,
        formState: { isSubmitting, isDirty },
    } = form;

    const [uploadCert] = useUploadUserSuppliedCertificateMutation();

    const onSubmit = (data: FormValues) => {
        return uploadCert({ body: data })
            .unwrap()
            .then(
                () =>
                    nav(
                        shouldClose(params["dialog-nav"]) || "/dns/tls/user-certificates"
                    ),
                handleSubmitError(form.setError)
            );
    };
    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <RhfFormField label="Private Key" required name="private_key">
                <TextAreaInput
                    {...register("private_key", {
                        ...required(),
                        setValueAs: (v) => v.trim(),
                    })}
                    rows={10}
                />
            </RhfFormField>
            <RhfFormField label="Bundle" required name="bundle">
                <TextAreaInput
                    {...register("bundle", {
                        ...required(),
                        setValueAs: (v) => v.trim(),
                    })}
                    rows={10}
                />
            </RhfFormField>
            <DialogFooter className="justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    isLoading={isSubmitting}
                    type="button"
                    disabled={!isDirty}
                    onClick={handleSubmit(onSubmit)}
                    icon={faPlus}
                >
                    Add TLS Certificate
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
