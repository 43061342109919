import {
    Controller,
    useFieldArray,
    useFormContext,
    useWatch,
} from "react-hook-form";
import { Button } from "@cycleplatform/ui/components/buttons";
import {
    FormToggle,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    BasicSelect,
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { ReconfigureVolumeSubmit } from "./VolumesTab";
import { VolumeSummary } from "~/services/cycle";
import { AccessControlOverlay } from "~/components/common/buttons";
import { useContext } from "react";
import { ContainerDialogContext } from "../context";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

export function RemoteAccessSection({
    volume,
    idx,
}: {
    volume: VolumeSummary;
    idx: number;
}) {
    const { register, setValue, control } =
        useFormContext<ReconfigureVolumeSubmit>();
    const { environment } = useContext(ContainerDialogContext);

    const isEnabled = useWatch({
        name: `volumes.${idx}.config.remote_access.enable`,
        control,
    });

    return (
        <Panel>
            <PanelTitle
                title="Remote Access"
                className="flex justify-between pt-0"
            >
                <FormToggle
                    value={isEnabled}
                    onChange={(v) => {
                        setValue(
                            `volumes.${idx}.config.remote_access.enable`,
                            v,
                            { shouldDirty: true }
                        );
                    }}
                />
            </PanelTitle>
            <PanelContent>
                {isEnabled ? (
                    <div className="[&>*]:mb-4">
                        <div className="flex gap-4">
                            <RhfFormField
                                className="w-1/2"
                                label="Algorithm"
                                name={`volumes.${idx}.config.remote_access.password.algorithm`}
                                required
                            >
                                <Controller
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <BasicSelect
                                            {...field}
                                            options={[
                                                {
                                                    label: "Raw",
                                                    value: "raw",
                                                },
                                                {
                                                    label: "SHA512",
                                                    value: "sha512",
                                                },
                                                {
                                                    label: "MD5",
                                                    value: "md5",
                                                },
                                            ]}
                                        />
                                    )}
                                    rules={{ ...required() }}
                                    control={control}
                                    name={`volumes.${idx}.config.remote_access.password.algorithm`}
                                />
                            </RhfFormField>
                            <RhfFormField
                                className="w-1/2"
                                label="Password"
                                required
                                name={`volumes.${idx}.config.remote_access.password.data`}
                            >
                                <TextInput
                                    type="password"
                                    {...register(
                                        `volumes.${idx}.config.remote_access.password.data`,
                                        { ...required() }
                                    )}
                                />
                            </RhfFormField>
                        </div>
                        <RhfFormField
                            label="Authorization Webhook"
                            name={`volumes.${idx}.config.remote_access.webhook`}
                        >
                            <TextInput
                                {...register(
                                    `volumes.${idx}.config.remote_access.webhook`
                                )}
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="Authorized IPs"
                            name={`volumes.${idx}.config.remote_access.ips`}
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <MultiSelectInput
                                        {...field}
                                        onChange={(e) => {
                                            return field.onChange(
                                                e && e.length ? e : []
                                            );
                                        }}
                                        value={field.value || []}
                                        placeholder="Allow any IP"
                                        isCreatable={true}
                                        formatDisplayValue={(v) => v}
                                        formatOption={(v) => (
                                            <FormattedOption label={v} />
                                        )}
                                        options={
                                            volume?.config?.remote_access
                                                ?.ips || []
                                        }
                                    />
                                )}
                                control={control}
                                name={`volumes.${idx}.config.remote_access.ips`}
                            />
                        </RhfFormField>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <div className="flex max-w-xl flex-col items-center gap-4 text-center">
                            <h4>Remote Access Disabled</h4>
                            <p>
                                Cycle provides convenient SFTP access to volumes
                                with remote access enabled.
                            </p>
                            <AccessControlOverlay
                                aclResource={environment}
                                verifyFn={modifyAccessFn(
                                    "containers-volumes-manage"
                                )}
                            >
                                <Button
                                    flavor="primary"
                                    onClick={() =>
                                        setValue(
                                            `volumes.${idx}.config.remote_access.enable`,
                                            true,
                                            { shouldDirty: true }
                                        )
                                    }
                                >
                                    Enable
                                </Button>
                            </AccessControlOverlay>
                        </div>
                    </div>
                )}
            </PanelContent>
        </Panel>
    );
}
