import { useForm } from "react-hook-form";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { PageContent } from "@cycleplatform/ui/components/page";
import {
    Container,
    useCreateContainerJobMutation,
    useGetImageQuery,
} from "~/services/cycle";
import { useCallback, useContext, useMemo, useRef } from "react";
import { NetworkSection } from "./NetworkSection";
import { faEdit, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { RuntimeSection } from "./runtime/RuntimeSection";
import { DeploymentSection } from "./deploy/DeploymentSection";
import { ResourcesSection } from "./resources/ResourcesSection";
import { IntegrationsSection } from "./integrations/IntegrationsSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobTracker } from "~/modules/jobs/hooks";
import classNames from "classnames";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { $info } from "@cycleplatform/core/util/log";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { ContainerConfigFormData } from "./types";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { ServiceContainerBanner } from "~/components/containers/ServiceContainerBanner";
import { ScalingSection } from "./scaling/ScalingSection";
import { BetaBadge } from "@cycleplatform/ui/components/badges";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { AccessControlOverlay } from "~/components/common/buttons";
import { ContainerDialogContext } from "../context";
import {
    injectImageVarsIntoContainer,
    getContainerFormDefaultValues,
    getContainerSubmitBody,
    useHandleConfigSmoothScroll,
} from "./helpers";
import { containerModifyAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { VmContainerBanner } from "~/components/containers/VmContainerBanner";
import { useKeepFormCurrent } from "~/components/common/forms";
import { isStrictDirty } from "~/components/common/forms/util";

type ConfigTabProps = {
    container?: Container;
    config: string | undefined;
    focus?: string;
};

export function ConfigTab({ container, config, focus }: ConfigTabProps) {
    const ref = useRef<HTMLDivElement>(null);
    const isService = isServiceContainer(container);
    const isHypervisor = isHypervisorContainer(container);

    const { environment } = useContext(ContainerDialogContext);

    const { data: image } = useGetImageQuery(
        { imageId: container?.image.id || "" },
        {
            skip:
                container?.image.id === undefined || isService || isHypervisor,
        }
    );

    const form = useForm<ContainerConfigFormData>({
        defaultValues: container?.config
            ? getContainerFormDefaultValues(container.config)
            : {},
        ...rhfConfig,
    });
    const {
        formState: { isDirty, isSubmitting, dirtyFields },
    } = form;

    const containerWithImageVars = useMemo(
        () => injectImageVarsIntoContainer(container, image?.data),
        [container, image?.data]
    );

    useKeepFormCurrent(form, containerWithImageVars, (c) =>
        getContainerFormDefaultValues(c.config)
    );

    const [trackJob, { isTrackingJob }] = useJobTracker();
    // Used to determine whether or not to render main content.
    // A large chunk of the forms use state on first render
    // that needs to be derived from other form values. This
    // ensures it's prepared. TODO update in the future to not
    // rely on initial form values?

    const [createContainerJob] = useCreateContainerJobMutation();

    const submit = useCallback(
        (values: ContainerConfigFormData) => {
            if (!container || !isDirty) {
                return;
            }
            const submitBody = getContainerSubmitBody(values, image?.data);

            return trackJob(
                createContainerJob({
                    containerId: container.id,
                    body: {
                        action: "reconfigure",
                        contents: submitBody,
                    },
                }).unwrap()
            ).then(
                (f) => $info("complete", f),
                handleSubmitError(form.setError, { pathPrefix: "config" })
            );
        },
        [container, image?.data, isDirty]
    );

    const { network, runtime, scaling, deployment, resources, integrations } =
        useHandleConfigSmoothScroll(config, focus);

    return (
        <div className="bg-cycle-white-accent dark:bg-cycle-black-accent h-full w-full">
            <RhfFormProvider
                {...form}
                onSubmit={form.handleSubmit(submit)}
                className="h-full"
            >
                <div className="relative h-full w-full overflow-hidden">
                    <ServiceContainerBanner
                        isServiceCont={isService || false}
                    />
                    <VmContainerBanner isHypervisor={isHypervisor || false} />
                    <DialogPageBody
                        className={classNames(
                            "absolute inset-0 overflow-hidden pr-0",
                            (isService || isHypervisor) && "pt-8"
                        )}
                    >
                        <div
                            className={classNames(
                                "fixed flex h-full w-[16rem] flex-col gap-4 overflow-hidden pr-4"
                            )}
                        >
                            <ConfigNavigation
                                title="Network"
                                value="network"
                                hasError={
                                    !!form.formState.errors?.config?.network
                                }
                                onClick={network.scroll}
                                config={config}
                            />
                            <ConfigNavigation
                                title="Runtime"
                                value="runtime"
                                hasError={
                                    !!form.formState.errors?.config?.runtime
                                }
                                onClick={runtime.scroll}
                                config={config}
                            />

                            <ConfigNavigation
                                title="Deployment"
                                value="deployment"
                                hasError={
                                    !!form.formState.errors?.config?.deploy
                                }
                                onClick={deployment.scroll}
                                config={config}
                            />
                            <ConfigNavigation
                                title="Resources"
                                value="resources"
                                hasError={
                                    !!form.formState.errors?.config?.resources
                                }
                                onClick={resources.scroll}
                                config={config}
                            />
                            {container?.stateful === false ? (
                                <ConfigNavigation
                                    title="Auto-Scaling"
                                    value="scaling"
                                    isBeta
                                    hasError={
                                        !!form.formState.errors?.config?.scale
                                    }
                                    onClick={scaling.scroll}
                                    config={config}
                                />
                            ) : null}
                            <ConfigNavigation
                                title="Integrations"
                                value="integrations"
                                hasError={
                                    !!form.formState.errors?.config
                                        ?.integrations
                                }
                                onClick={integrations.scroll}
                                config={config}
                            />
                        </div>
                        <PageContent className=" ml-[15rem] h-full ">
                            <div
                                ref={ref}
                                className="h-full w-full overflow-y-auto px-4"
                            >
                                <AccessControlledSection
                                    aclResource={environment}
                                    verifyFn={modifyAccessFn(
                                        "containers-manage"
                                    )}
                                    disabled={isService || isHypervisor}
                                    className="h-full w-full "
                                >
                                    {container && (
                                        <div className="flex flex-col gap-4 pb-24">
                                            <div ref={network.ref}>
                                                <NetworkSection />
                                            </div>

                                            <div ref={runtime.ref}>
                                                <RuntimeSection
                                                    image={image?.data}
                                                />
                                            </div>

                                            <div ref={deployment.ref}>
                                                <DeploymentSection
                                                    container={container}
                                                />
                                            </div>

                                            <div ref={resources.ref}>
                                                <ResourcesSection />
                                            </div>

                                            {container?.stateful === false ? (
                                                <div ref={scaling.ref}>
                                                    <ScalingSection
                                                        container={container}
                                                    />
                                                </div>
                                            ) : null}

                                            <div ref={integrations.ref}>
                                                <IntegrationsSection
                                                    container={container}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </AccessControlledSection>
                            </div>
                        </PageContent>
                    </DialogPageBody>

                    <div className="dark:border-cycle-black-accent dark:bg-cycle-black absolute bottom-0 z-10 flex w-full items-center justify-between border-t bg-white px-2 py-4">
                        <div className="flex w-full justify-between">
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon
                                    className="text-cycle-blue"
                                    icon={faInfoCircle}
                                />
                                Updating the config will restart this container.
                            </div>
                            <div className="flex flex-1 items-center justify-end pr-4">
                                <RhfGlobalFormError />
                            </div>
                            {container ? (
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={containerModifyAccessFn(
                                        container
                                    )}
                                >
                                    <PushAndHoldButton
                                        disabled={
                                            isService ||
                                            !isStrictDirty(dirtyFields)
                                        }
                                        tooltip={
                                            container && isService
                                                ? "Service containers cannot be modified."
                                                : undefined
                                        }
                                        onClick={form.handleSubmit(submit)}
                                        type="button"
                                        isLoading={
                                            isTrackingJob || isSubmitting
                                        }
                                        flavor="primary"
                                        icon={faEdit}
                                    >
                                        Save Container
                                    </PushAndHoldButton>
                                </AccessControlOverlay>
                            ) : (
                                <SkeletonButton size="lg" />
                            )}
                        </div>
                    </div>
                </div>
            </RhfFormProvider>
        </div>
    );
}

type ConfigNavigationProps = {
    title: string;
    value: string;
    hasError: boolean;
    onClick?: () => void;
    config: string | undefined;
    isBeta?: boolean;
};

function ConfigNavigation({
    title,
    value,
    hasError,
    onClick,
    isBeta,
    config,
}: ConfigNavigationProps) {
    const isActive = config === value || (!config && value === "network");
    return (
        <Link
            key={title}
            to={generateDialogLink("container", {
                "dialog-config": value,
                "dialog-tab": "config",
            })}
            className={classNames(
                "dark:border-cycle-gray dark:bg-cycle-gray-accent  border-t-2 border-l-2 border-b-0 bg-white p-4 text-inherit",
                "!dark:hover:text-cycle-blue-accent ",
                hasError && "!border-error !text-error ",
                isActive && "!text-cycle-blue"
            )}
            // Use effect in main body will handle the routing when not
            // clicking on an already active tile
            onClick={() => {
                if (isActive) {
                    onClick?.();
                }
            }}
        >
            {title} {isBeta && <BetaBadge />}
        </Link>
    );
}
