import prism from "prismjs";
// import "prismjs/themes/prism-tomorrow.css";
import "./cycle-prism.css";
import { useEffect, useRef, useState } from "react";
import "prismjs/components/prism-json.min";
import classNames from "classnames";

type CodeBoxProps = {
    value: string;
    className?: string;
    language?: string;
};

export function CodeBox({ value, className, language = "json" }: CodeBoxProps) {
    const preRef = useRef<HTMLPreElement | null>(null);
    const { highlight, languages } = prism;
    const [children, setChildren] = useState(
        highlight(value, languages[language], language)
    );

    useEffect(() => {
        setChildren(highlight(value, languages[language], language));
    }, [value]);

    return (
        <pre
            ref={preRef}
            dangerouslySetInnerHTML={{ __html: children }}
            className={classNames(
                "bg-cycle-black w-full overflow-hidden text-ellipsis rounded-lg p-2",
                className
            )}
        />
    );
}
