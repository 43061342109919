type SkeletonFallbackProps = {
    shouldRenderFallback?: boolean;
    fallback: React.ReactNode;
    children: React.ReactNode;
};

export function SkeletonFallback({
    children,
    fallback,
    shouldRenderFallback,
}: SkeletonFallbackProps) {
    if (shouldRenderFallback) {
        return <>{fallback}</>;
    }
    return <>{children}</>;
}

type GuardedSkeletonFallbackProps<T> = {
    data: T | null | undefined;
    fallback: React.ReactNode;
    children: (data: T) => React.ReactNode;
};

export function GuardedSkeletonFallback<T>({
    children,
    fallback,
    data,
}: GuardedSkeletonFallbackProps<T>) {
    if (!data) {
        return <>{fallback}</>;
    }

    return <>{children(data)}</>;
}
