import { createContext } from "react";
import { Container, Environment, Image } from "~/services/cycle";

type ContainerDialogContext = {
    environment: Environment | undefined;
    image: Image | undefined;
};
export const ContainerDialogContext = createContext<ContainerDialogContext>({
    environment: undefined,
    image: undefined,
});
