import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { useNavigate } from "react-router-dom";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "../../helpers";
import {
    useGetAutoScaleGroupQuery,
    useGetProviderLocationsQuery,
} from "~/services/cycle";
import { EditDeployableInfraForm } from "~/components/infrastructure/form/autoScaling/deployableInfra/EditDeployableInfraForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function EditDeployableInfraDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"autoscaling-group-edit-infra">();

    const { data: group } = useGetAutoScaleGroupQuery({
        groupId: params["dialog-group-id"],
        include: ["models", "integrations", "locations"],
    });

    const model = group?.data?.infrastructure?.models?.find(
        (m) => m.model_id === params["dialog-model-id"]
    );

    const { data: allLocations } = useGetProviderLocationsQuery(
        {
            providerVendor: model?.provider || "",
            page: {
                size: 100,
                number: 1,
            },
        },
        { skip: !model?.provider }
    );

    return (
        <StyledFormDialog
            title={`Add Models - ${group?.data?.name}`}
            isOpen={isDialogOpen("autoscaling-group-edit-infra") && !!model}
            onClose={() => nav(clearDialogParams())}
        >
            {!!group && !!model && !!allLocations?.data ? (
                <DialogColumn className="w-full">
                    <EditDeployableInfraForm
                        group={group?.data}
                        groupIncludes={group?.includes}
                        model={model}
                        allLocations={allLocations?.data}
                    />
                </DialogColumn>
            ) : (
                <></>
            )}
        </StyledFormDialog>
    );
}
