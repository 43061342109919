import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RHF_GLOBAL_ERROR,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm, useWatch } from "react-hook-form";
import {
    CreateStackApiArg,
    StackRepoSource,
    useCreateStackMutation,
} from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useNavigate } from "react-router-dom";
import {
    getAllDialogSearchParams,
    shouldClose,
} from "~/components/dialogs/helpers";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
} from "~/components/common/acl";
import { GitRepoForm } from "~/components/stacks/form/GitRepoForm";
import { RawForm } from "~/components/stacks/form/RawForm";
import { StackCreateGeneralSection } from "./StackCreateGeneralSection";
import { convertToJsonObject } from "@cycleplatform/core/util";
import { gitRepoDefault, rawDefault } from "./defaults";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export type StackCreateFormType = {
    name: CreateStackApiArg["body"]["name"];
    sourceType?: "raw" | "git-repo";

    rawSource: string;
    gitSource: StackRepoSource["details"];

    forceDirty?: string;
    acl: AclForm;
    variables: CreateStackApiArg["body"]["variables"];
};

export function CreateStackForm() {
    const params = getAllDialogSearchParams<"stack-create">();
    const nav = useNavigate();
    const form = useForm<StackCreateFormType>({
        defaultValues: {
            name: "",
            rawSource: JSON.stringify(rawDefault, null, 2),
            gitSource: gitRepoDefault,
        },
        ...rhfConfig,
    });
    const {
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const sourceType = useWatch({ name: "sourceType", control });
    const [createStack] = useCreateStackMutation();

    const onSubmit = (data: StackCreateFormType) => {
        let submitBody;

        if (data.sourceType === "git-repo") {
            submitBody = {
                name: data.name,
                source: {
                    type: "git-repo",
                    details: data.gitSource,
                },
                variables: data.variables,
                ...createAclSubmitBody(data.acl, "create"),
            };
        } else {
            const details = convertToJsonObject(data.rawSource);

            if (details instanceof Error) {
                return form.setError(RHF_GLOBAL_ERROR, {
                    message: "Could not parse JSON/YAML input",
                });
            }

            submitBody = {
                name: data.name,
                source: {
                    type: "raw",
                    details,
                },
                variables: data.variables,
                ...createAclSubmitBody(data.acl, "create"),
            };
        }

        return createStack({
            body: submitBody as CreateStackApiArg["body"],
        })
            .unwrap()
            .then(
                (v) =>
                    nav(
                        shouldClose(params["dialog-nav"]) || {
                            pathname: `stacks/${v?.data?.id}`,
                            search: "",
                        }
                    ),
                handleSubmitError(form.setError, {
                    sourceOverride: { ["source.details"]: RHF_GLOBAL_ERROR },
                })
            );
    };

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <div>
                <StackCreateGeneralSection />

                {sourceType ? (
                    <>
                        {sourceType === "git-repo" ? (
                            <GitRepoForm />
                        ) : (
                            <RawForm />
                        )}
                    </>
                ) : null}
                <ResourceAclCreate resourceType="stack" />
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    type="button"
                    onClick={form.handleSubmit(onSubmit)}
                    icon={faEdit}
                    flavor="primary"
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                >
                    Create Stack
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
