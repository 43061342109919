import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faSync,
    faCheckCircle,
    faShield,
    faFileLock,
    faArrowRotateBack,
    faArrowRightToLine,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, Server } from "~/services/cycle";
import { Link } from "react-router-dom";
import { isErroredActivity } from "./common/util";

export function formatServerMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractServerName(item.component?.id || "", components);
    const link = (
        <Link to={`/infrastructure/servers/${item.component?.id}`}>{name}</Link>
    );

    switch (item.event) {
        case "infrastructure.server.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of server {link}</span>
                ) : (
                    <span>Error requesting deletion of server {link}</span>
                ),
                icon: faTrash,
            };
        case "infrastructure.server.task.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested restart of server {link}</span>
                ) : (
                    <span>Error requesting restart of server {link}</span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.services.sftp.auth":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Authenticated via SFTP to server {link}</span>
                ) : (
                    <span>
                        Detected failed authentication via SFTP to server {link}
                    </span>
                ),
                icon: faFileLock,
            };

        case "infrastructure.server.task.provision":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested provision of server {link}</span>
                ) : (
                    <span>Error requesting provision of server {link}</span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated server {link}</span>
                ) : (
                    <span>Error updating server {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "infrastructure.server.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted server {link}</span>
                ) : (
                    <span>Error deleting server {link}</span>
                ),
                icon: faTrash,
            };
        case "infrastructure.server.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Restarted server {link}</span>
                ) : (
                    <span>Error restarting server {link}</span>
                ),
                icon: faSync,
            };
        case "infrastructure.server.compute.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restart of the compute service on server{" "}
                        {link}
                    </span>
                ) : (
                    <span>
                        Error requesting restart of the compute service on
                        server {link}
                    </span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.compute.spawner.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restart of the compute spawner on server{" "}
                        {link}
                    </span>
                ) : (
                    <span>
                        Error requesting restart of the compute spawner on
                        server {link}
                    </span>
                ),
                icon: faSync,
            };
        case "infrastructure.server.provision":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Provisioned server {link}</span>
                ) : (
                    <span>Error provisioning server {link}</span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.live":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Server {link} has come online</span>
                ) : (
                    <span>Error bringing server {link} online</span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.services.sftp.lockdown":
            return {
                message: (
                    <span>
                        SFTP service on server {link} has automatically entered
                        lockdown mode due to large volume of connections
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.console":
            return {
                message: (
                    <span>
                        Detected unauthorized console access to server {link}.
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.ssh.token":
            return {
                message: (
                    <span>
                        Detected unauthorized attempt to generate SSH token for
                        access to server {link}.
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.task.features.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfiguration of features for server {link}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfiguration of features for server{" "}
                        {link}
                    </span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.features.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Reconfigured features for server {link}</span>
                ) : (
                    <span>Error reconfiguring features for server {link}</span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.services.internal-api.throttle":
            return {
                message: (
                    <span>
                        Throttled the internal API on server {link} due to
                        excessive requests
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.task.evacuation.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested evacuation of server {link}</span>
                ) : (
                    <span>Error requesting evacuation of server {link}</span>
                ),
                icon: faArrowRightToLine,
            };
        case "infrastructure.server.evacuation.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Evacuated server {link}</span>
                ) : (
                    <span>Error evacuating server {link}</span>
                ),
                icon: faArrowRightToLine,
            };
        case "infrastructure.server.task.evacuation.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested an evacuation reset on server {link}</span>
                ) : (
                    <span>
                        Error requesting evacuation reset on server {link}
                    </span>
                ),
                icon: faArrowRotateBack,
            };
        case "infrastructure.server.evacuation.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Reset evacuation on server {link}</span>
                ) : (
                    <span>Error resetting evacuating on server {link}</span>
                ),
                icon: faArrowRotateBack,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractServerName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Server).hostname;
}
