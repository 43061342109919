import { FormattedMoney } from "@cycleplatform/ui/components/money";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PromoCode } from "~/services/cycle";

type TierCreditProps = {
    promo: PromoCode | undefined;
};

export function TierCredit({ promo }: TierCreditProps) {
    const amount = promo?.credit?.amount?.mills;
    const credit = promo?.credit;
    const expires = credit?.expires;

    if (!credit) {
        return null;
    }

    return (
        <div className="rounded-md border p-4">
            <div className="flex justify-between">
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-cycle-blue"
                    />
                    <h3 className="text-lg font-semibold">Credit Applied</h3>
                </div>
                <FormattedMoney
                    value={amount || 0}
                    mills
                    className="text-success pr-4 text-2xl font-bold"
                />
            </div>
            Expires in {expires?.number} {expires?.interval}
        </div>
    );
}
