import { Container, ContainerConfig, Image } from "~/services/cycle";
import { useEffect } from "react";
import {
    sanitizeContainerConfig,
    unsanitizeContainerConfig,
} from "~/modules/containers/util/config";
import { ContainerConfigFormData } from "./types";
import { useSmoothScroll } from "@cycleplatform/core/hooks";
import { getTime } from "date-fns";

export function injectImageVarsIntoContainer(
    container: Container | undefined,
    image: Image | undefined
): (Container & { timestamp: number }) | undefined {
    if (!container) {
        return undefined;
    }
    return {
        timestamp: getTime(new Date()),
        ...container,
        config: {
            ...container?.config,
            runtime: container?.config?.runtime
                ? {
                      ...container?.config?.runtime,
                      environment_vars: {
                          ...(image?.config?.env || {}),
                          ...(container?.config?.runtime?.environment_vars ||
                              {}),
                      },
                  }
                : undefined,
        },
    };
}

export function getContainerFormDefaultValues(
    containerConfig: ContainerConfig
): ContainerConfigFormData {
    return {
        config: sanitizeContainerConfig(containerConfig as ContainerConfig),
    };
}

export function getContainerSubmitBody(
    values: ContainerConfigFormData,
    image: Image | undefined
) {
    const contents = unsanitizeContainerConfig(values.config);

    const imageVars = image?.config.env || {};

    if (values?.config?.runtime !== undefined) {
        contents.runtime = {
            ...values.config.runtime,
            environment_vars: Object.entries(
                values.config.runtime.environment_vars || {}
            ).reduce((acc, [key, value]) => {
                if (imageVars[key] === value) {
                    return { ...acc };
                }
                return { ...acc, [key]: value };
            }, {} as Record<string, string>),
        };
    }

    return contents;
}

export function useHandleConfigSmoothScroll(
    config: string | undefined,
    focus: string | undefined
) {
    const { ref: networkRef, scrollIntoView: scrollNetwork } =
        useSmoothScroll();
    const { ref: runtimeRef, scrollIntoView: scrollRuntime } =
        useSmoothScroll();
    const { ref: scalingRef, scrollIntoView: scrollScaling } =
        useSmoothScroll();
    const { ref: deploymentRef, scrollIntoView: scrollDeployment } =
        useSmoothScroll();
    const { ref: resourcesRef, scrollIntoView: scrollResources } =
        useSmoothScroll();
    const { ref: integrationsRef, scrollIntoView: scrollIntegrations } =
        useSmoothScroll();

    useEffect(() => {
        switch (config) {
            case "network":
                return scrollNetwork();
            case "runtime":
                return scrollRuntime();
            case "scaling":
                return scrollScaling();
            case "deployment":
                // skip nav to allow telemetry section focus
                if (focus !== "telemetry") {
                    return scrollDeployment();
                } else return;
            case "resources":
                return scrollResources();
            case "integrations":
                return scrollIntegrations();
            default:
                return;
        }
    }, [config]);
    return {
        network: { ref: networkRef, scroll: scrollNetwork },
        runtime: { ref: runtimeRef, scroll: scrollRuntime },
        scaling: { ref: scalingRef, scroll: scrollScaling },
        deployment: { ref: deploymentRef, scroll: scrollDeployment },
        resources: { ref: resourcesRef, scroll: scrollResources },
        integrations: { ref: integrationsRef, scroll: scrollIntegrations },
    };
}
