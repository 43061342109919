import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Grid } from "react-loading-icons";

type AlertBarProps = {
    type: "info" | "error" | "warning";
    icon?: IconProp;
    isLoading?: boolean;
    children?: React.ReactNode;
};

export function AlertBar({ type, icon, isLoading, children }: AlertBarProps) {
    return (
        <div
            role="alert"
            className={classNames(
                "text-cycle-white absolute top-0 left-0 right-0 z-30 flex select-none items-center justify-center px-4 py-1 text-center font-semibold",
                type === "info" && "bg-cycle-blue",
                type === "error" && "bg-error",
                type === "warning" && "bg-warning"
            )}
        >
            {isLoading && <Grid className="mr-2 h-4 w-4" />}{" "}
            {icon && <FontAwesomeIcon icon={icon} />} {children}
        </div>
    );
}
