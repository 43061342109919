export function formatBytes(value: number, decimals = 0) {
    if (value === 0) {
        return {
            value: 0,
            suffix: "MB",
        };
    }
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const k = 1024;
    const i = Math.floor(Math.log(value) / Math.log(k));
    const dm = decimals;

    return {
        value: parseFloat((value / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
    };
}

/** combine formatted bytes into a string */
export function formatBytesString(value: number, decimals = 0) {
    const parts = formatBytes(value, decimals);
    return `${parts.value}${parts.suffix}`;
}
