import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { HumanizedTime } from "@cycleplatform/ui/components/time";
import classNames from "classnames";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { brandColors } from "tailwindcss-config/colors";
import { LatencyChartMetric } from "./query";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { CycleChartProps } from "@cycleplatform/ui/components/recharts/props";

type LatencyChartProps = {
    className?: string;
    metric: LatencyChartMetric;
};

export function LatencyChart({ className, metric }: LatencyChartProps) {
    return (
        <div className={classNames(className, "relative h-full")}>
            <div className="absolute inset-0">
                <ResponsiveContainer>
                    <LineChart
                        {...CycleChartProps}
                        margin={{ top: 5, bottom: 5, right: 0, left: 0 }}
                        data={metric.latency.map((d) => ({
                            ...d,
                            time: new Date(d.time).getTime(),
                        }))}
                        syncId="latency"
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <YAxis
                            tickCount={2}
                            type="number"
                            hide
                            domain={[0, "auto"]}
                            allowDecimals={false}
                            tickMargin={0}
                        />
                        <XAxis
                            dataKey="time"
                            scale="time"
                            type="number"
                            domain={["auto", "auto"]}
                            hide
                        />
                        <Tooltip
                            content={(props) => {
                                if (props.payload?.length) {
                                    return (
                                        <div className="ring-cycle-blue dark:bg-cycle-black-accent rounded-lg bg-white p-2 text-xs ring-2">
                                            <div>
                                                {" "}
                                                {`${
                                                    props.payload[0]?.value ??
                                                    "?"
                                                }ms`}
                                            </div>

                                            <div>
                                                <HumanizedTime
                                                    value={
                                                        props.label as string
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            }}
                        />
                        <Line
                            {...CycleLineProps}
                            dot={true}
                            dataKey="value"
                            connectNulls
                            stroke={brandColors["cycle-blue"].DEFAULT}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
