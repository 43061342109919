import { useMemo } from "react";
import {
    ContainerLink,
    EnvironmentLink,
    ImageLink,
    StackBuildLink,
    StackLink,
} from "~/components/common/resources/ResourceLinks";
import {
    ComponentIncludes,
    PipelineRunStep,
    ResourceType,
} from "~/services/cycle";

export function PipelineRunStepMessage({
    step,
    components,
}: {
    step: PipelineRunStep;
    components?: ComponentIncludes;
}) {
    const resourceTypeIdMap = useMemo(
        () =>
            step?.components?.reduce((acc, { type, id }) => {
                return { ...acc, [type]: id };
            }, {} as Record<ResourceType, string>) ||
            ({} as Record<ResourceType, string>),
        [step]
    );

    switch (step.action) {
        case "environment.create":
            return (
                <div>
                    Create Environment{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <EnvironmentLink
                                environmentId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.start":
            return (
                <div>
                    Start Environment{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <EnvironmentLink
                                environmentId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.stop":
            return (
                <div>
                    Stop Environment{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <EnvironmentLink
                                environmentId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.delete":
            return (
                <div>
                    Delete Environment{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <EnvironmentLink
                                environmentId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.deployments.prune":
            return (
                <div>
                    Prune Environment Deployments{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                on{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.deployment.start":
            return (
                <div>
                    Start Environment Deployments{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                on{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.deployment.stop":
            return (
                <div>
                    Stop Environment Deployments{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                on{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.deployment.healthy.watch":
            return (
                <div>
                    Wait For Environment Deployment Health Check{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                on{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "environment.deployments.tag":
            return (
                <div>
                    Update Deployment Tag{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "image.create":
            return (
                <div>
                    Create Image{" "}
                    <LinkHandler id={resourceTypeIdMap["image"]}>
                        {(id) => (
                            <ImageLink imageId={id} components={components} />
                        )}
                    </LinkHandler>
                </div>
            );
        case "image.create-import":
            return (
                <div>
                    Create and Import Image{" "}
                    <LinkHandler id={resourceTypeIdMap["image"]}>
                        {(id) => (
                            <ImageLink imageId={id} components={components} />
                        )}
                    </LinkHandler>
                </div>
            );
        case "image.import":
            return (
                <div>
                    Import Image{" "}
                    <LinkHandler id={resourceTypeIdMap["image"]}>
                        {(id) => (
                            <ImageLink imageId={id} components={components} />
                        )}
                    </LinkHandler>
                </div>
            );
        case "images.prune":
            return "Prune Images";
        case "container.create":
            return (
                <div>
                    Create Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "container.reimage":
            return (
                <div>
                    Reimage Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "container.restart": {
            return (
                <div>
                    Restart Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        }
        case "container.start":
            return (
                <div>
                    Start Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "container.stop":
            return (
                <div>
                    Stop Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "container.delete":
            return (
                <div>
                    Delete Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "container.function.trigger":
            return (
                <div>
                    Trigger Function Container{" "}
                    <LinkHandler id={resourceTypeIdMap["container"]}>
                        {(id) => (
                            <ContainerLink
                                containerId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["environment"]}>
                        {(id) => (
                            <>
                                in Environment{" "}
                                <EnvironmentLink
                                    environmentId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "stack.build.create":
            return (
                <div>
                    Create Stack Build{" "}
                    <LinkHandler id={resourceTypeIdMap["stack.build"]}>
                        {(id) => (
                            <StackBuildLink
                                buildId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["stack"]}>
                        {(id) => (
                            <>
                                from Stack{" "}
                                <StackLink
                                    stackId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );

        case "stack.build.deploy":
            return (
                <div>
                    Deploy Stack Build{" "}
                    <LinkHandler id={resourceTypeIdMap["stack.build"]}>
                        {(id) => (
                            <StackBuildLink
                                buildId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["stack"]}>
                        {(id) => (
                            <>
                                in Stack{" "}
                                <StackLink
                                    stackId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "stack.build.generate":
            return (
                <div>
                    Generate Stack Build{" "}
                    <LinkHandler id={resourceTypeIdMap["stack.build"]}>
                        {(id) => (
                            <StackBuildLink
                                buildId={id}
                                components={components}
                            />
                        )}
                    </LinkHandler>{" "}
                    <LinkHandler id={resourceTypeIdMap["stack"]}>
                        {(id) => (
                            <>
                                from Stack{" "}
                                <StackLink
                                    stackId={id}
                                    components={components}
                                />
                            </>
                        )}
                    </LinkHandler>
                </div>
            );
        case "stack.prune":
            return (
                <div>
                    Prune Stack{" "}
                    <LinkHandler id={resourceTypeIdMap["stack"]}>
                        {(id) => (
                            <StackLink stackId={id} components={components} />
                        )}
                    </LinkHandler>
                </div>
            );
        case "sleep":
            return "Sleep";
        case "webhook.post":
            return "POST Request to Webhook";
        case "webhook.get":
            return "GET Request to Webhook";
    }
}

function LinkHandler({
    id,
    children,
}: {
    id?: string;
    children: (id: string) => React.ReactNode;
}) {
    if (!id) {
        return null;
    }
    return <>{children(id)}</>;
}
