import { PipelineRun, ResourceType } from "~/services/cycle";

/**
 *
 * @param run pipeline run
 * @returns an array of components used as the input for the components section of useLookupComponentsQuery
 *
 * Takes a pipeline run and generated an input for useLookupComponentQuery.
 * This is used to get all the components neccessary to generate links for pipeline run messages
 */
export function pipelineRunToComponentInput(run?: PipelineRun) {
    let components: { id: string; type: ResourceType }[] = [];

    run?.stages?.forEach((stage) => {
        stage.steps.forEach((step) => {
            step.components?.forEach((c) => components.push(c));
        });
    });

    return components as { id: string; type: ResourceType }[];
}
