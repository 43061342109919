import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import envGraphic from "@cycleplatform/ui/images/icons/gridless/environment.svg";
import { CreateEnvironmentForm } from "./CreateEnvironmentForm";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { FormField } from "@cycleplatform/ui/components/forms";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function EnvironmentCreateDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="New Environment"
            isOpen={isDialogOpen("environment-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-2/3">
                <CreateEnvironmentForm />
            </DialogColumn>
            <DialogColumn className="w-1/3">
                <CreateEnvAside />
            </DialogColumn>
        </StyledFormDialog>
    );
}

function CreateEnvAside() {
    return (
        <>
            <img className="max-w-xs " src={envGraphic} />
            <div className="mt-4 text-lg">
                <h3>Environments</h3>
                <div className="mb-4 text-sm">
                    Environments are logical groupings of containers. All
                    containers deployed inside an environment automatically have
                    a private network built between them, enabling seamless
                    communication.
                </div>
                <FormField label="learn more:">
                    <ul>
                        <li>
                            <Link
                                className="text-sm"
                                to="https://cycle.io/docs/platform/introduction-to-environments"
                            >
                                Environments Overview
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-sm"
                                to="https://cycle.io/docs/portal/creating-an-environment"
                            >
                                Creating an Environment
                            </Link>
                        </li>
                    </ul>
                </FormField>
            </div>
        </>
    );
}
