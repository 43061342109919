import { useFieldArray, useFormContext } from "react-hook-form";
import { Environment } from "~/services/cycle";
import { LoadBalancerFormValues } from "../../form";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { useSearchParams } from "react-router-dom";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../../../context";

export function RemovePortButton({
    environment,
}: {
    environment: Environment | undefined;
}) {
    const { control } = useFormContext<LoadBalancerFormValues>();
    const { service } = useContext(LoadBalancerDialogContext);
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortIdx = Number(params["dialog-controllers-port-idx"]);
    const [searchParams, setSearchParams] = useSearchParams();

    const { fields: controllers, remove } = useFieldArray({
        control,
        name: `config.details.controllers`,
    });

    const isProtectedPort = service?.base_configs?.v1.controllers
        .map((c) => c.port)
        .includes(controllers[activePortIdx]?.port);

    return (
        <AccessControlOverlay
            aclResource={environment}
            verifyFn={modifyAccessFn("environments-services-manage")}
        >
            <Button
                flavor="discard"
                icon={faTrash}
                // protected ports are FE protected from deletion
                disabled={isProtectedPort}
                onClick={() => {
                    // each controller is an entry in the controllers object.  To delete
                    // unregister the whole section
                    remove(activePortIdx || undefined);
                    // remove the active port from the url so that the auto route behavior kicks in
                    // This will bring the user to the first port in the list
                    searchParams.set("dialog-controllers-port-idx", "0");
                    setSearchParams(searchParams);
                }}
            >
                Delete Controller
            </Button>
        </AccessControlOverlay>
    );
}
