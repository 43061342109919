import { extractCycleVariables } from "@cycleplatform/core/modules/variables/util";
import { Button, CopyButton } from "@cycleplatform/ui/components/buttons";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faCheck, faCopy } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useState } from "react";
import { CodeHighlighter } from "~/components/common/forms/CodeEditor";
import { Pipeline } from "~/services/cycle";

type TriggerKeyPostInformationProps = {
    secret?: string;
    pipeline?: Pipeline;
};

export function TriggerKeyPostInformation({
    secret,
    pipeline,
}: TriggerKeyPostInformationProps) {
    const [copied, setCopied] = useState(false);
    return (
        <Panel>
            <PanelTitle title="Post Info" />
            <PanelContent>
                <CodeHighlighter
                    code={curlPost(pipeline, secret)}
                    language="bash"
                    className="mb-4 text-xs "
                />

                <PanelFooter>
                    <Button
                        onClick={(ev) => {
                            navigator.clipboard.writeText(
                                curlPost(pipeline, secret)
                            );
                            setCopied(true);
                        }}
                        flavor="primary"
                        className={classNames(copied && "bg-cycle-blue-accent")}
                        icon={!copied ? faCopy : faCheck}
                    >
                        {copied ? "copied" : "copy"}
                    </Button>
                </PanelFooter>
            </PanelContent>
        </Panel>
    );
}

function curlPost(pipeline: Pipeline | undefined, secret: string | undefined) {
    const variables = pipeline?.stages
        ? extractCycleVariables(JSON.stringify(pipeline?.stages))
        : [];

    const varString = variables?.length
        ? `, "variables": {${variables?.map((v) => `"${v}": ""`)}}`
        : "";
    const secretJson =
        secret && secret.length !== 31 ? secret : "$TRIGGER_KEY_SECRET";
    return `curl https://api.cycle.io/v1/pipelines/${
        pipeline?.id || ""
    }/trigger \\
    -H "Content-Type: application/json" \\
    -d '{ "secret": "${secretJson}" ${varString}}' \\
    -X POST`;
}
