export function handleCreditCardExpirationChange(value: string) {
    const [month = "", year = ""] = value.split("/");

    if (value === "0") {
        return {
            month: 0,
            year: -1,
        };
    }

    return {
        month: parseInt(month.substr(0, 2), 10) || 0,
        year: parseInt(year.substr(0, 2), 10) || 0,
    };
}

//

type CreditCardBlocksType = Record<CreditCardType, BlocksType>;
type CreditCardRegexType = Record<
    CreditCardExcludeGeneralType<CreditCardType>,
    RegExp
>;

type CreditCardExcludeGeneralType<T> = T extends CreditCardType.GENERAL
    ? never
    : T;

interface GetCreditCardInfoProps {
    value: string;
    strictMode?: boolean;
}
interface CreditCardInfoProps {
    type: CreditCardType;
    blocks: BlocksType;
}

type DelimiterType = string;
type BlocksType = number[];

interface StripDelimitersProps {
    value: string;
    delimiters: DelimiterType[];
}

const DefaultCreditCardDelimiter: DelimiterType = " ";

enum CreditCardType {
    UATP = "uatp",
    AMEX = "amex",
    DINERS = "diners",
    DISCOVER = "discover",
    MASTERCARD = "mastercard",
    DANKORT = "dankort",
    INSTAPAYMENT = "instapayment",
    JCB15 = "jcb15",
    JCB = "jcb",
    MAESTRO = "maestro",
    VISA = "visa",
    MIR = "mir",
    UNIONPAY = "unionpay",
    GENERAL = "general",
}

const CreditCardBlocks: CreditCardBlocksType = {
    [CreditCardType.UATP]: [4, 5, 6],
    [CreditCardType.AMEX]: [4, 6, 5],
    [CreditCardType.DINERS]: [4, 6, 4],
    [CreditCardType.DISCOVER]: [4, 4, 4, 4],
    [CreditCardType.MASTERCARD]: [4, 4, 4, 4],
    [CreditCardType.DANKORT]: [4, 4, 4, 4],
    [CreditCardType.INSTAPAYMENT]: [4, 4, 4, 4],
    [CreditCardType.JCB15]: [4, 6, 5],
    [CreditCardType.JCB]: [4, 4, 4, 4],
    [CreditCardType.MAESTRO]: [4, 4, 4, 4],
    [CreditCardType.VISA]: [4, 4, 4, 4],
    [CreditCardType.MIR]: [4, 4, 4, 4],
    [CreditCardType.UNIONPAY]: [4, 4, 4, 4],
    [CreditCardType.GENERAL]: [4, 4, 4, 4],
};

const CreditCardRegex: CreditCardRegexType = {
    // starts with 1; 15 digits, not starts with 1800 (jcb card)
    [CreditCardType.UATP]: /^(?!1800)1\d{0,14}/,

    // starts with 34/37; 15 digits
    [CreditCardType.AMEX]: /^3[47]\d{0,13}/,

    // starts with 6011/65/644-649; 16 digits
    [CreditCardType.DISCOVER]: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,

    // starts with 300-305/309 or 36/38/39; 14 digits
    [CreditCardType.DINERS]: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,

    // starts with 51-55/2221–2720; 16 digits
    [CreditCardType.MASTERCARD]:
        /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,

    // starts with 5019/4175/4571; 16 digits
    [CreditCardType.DANKORT]: /^(5019|4175|4571)\d{0,12}/,

    // starts with 637-639; 16 digits
    [CreditCardType.INSTAPAYMENT]: /^63[7-9]\d{0,13}/,

    // starts with 2131/1800; 15 digits
    [CreditCardType.JCB15]: /^(?:2131|1800)\d{0,11}/,

    // starts with 2131/1800/35; 16 digits
    [CreditCardType.JCB]: /^(?:35\d{0,2})\d{0,12}/,

    // starts with 50/56-58/6304/67; 16 digits
    [CreditCardType.MAESTRO]: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,

    // starts with 22; 16 digits
    [CreditCardType.MIR]: /^220[0-4]\d{0,12}/,

    // starts with 4; 16 digits
    [CreditCardType.VISA]: /^4\d{0,15}/,

    // starts with 62/81; 16 digits
    [CreditCardType.UNIONPAY]: /^(62|81)\d{0,14}/,
};

const stripNonNumeric = (value: string): string => value.replace(/[^\d]/g, "");

const getDelimiterRegexByDelimiter = (delimiter: string): RegExp =>
    new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g");

const stripDelimiters = ({
    value,
    delimiters,
}: StripDelimitersProps): string => {
    delimiters.forEach((current: DelimiterType) => {
        current.split("").forEach((letter) => {
            value = value.replace(getDelimiterRegexByDelimiter(letter), "");
        });
    });

    return value;
};

const getStrictBlocks = (blocks: BlocksType): BlocksType => {
    const total: number = blocks.reduce(
        (prev: number, current: number) => prev + current,
        0
    );

    return blocks.concat(19 - total);
};

const getCreditCardInfo = ({
    value,
    strictMode,
}: GetCreditCardInfoProps): CreditCardInfoProps => {
    // Some credit card can have up to 19 digits number.
    // Set strictMode to true will remove the 16 max-length restrain,
    // however, I never found any website validate card number like
    // this, hence probably you don't want to enable this option.
    for (const key of Object.keys(CreditCardRegex) as Array<
        CreditCardExcludeGeneralType<CreditCardType>
    >) {
        if (CreditCardRegex[key].test(value)) {
            const matchedBlocks: BlocksType = CreditCardBlocks[key];
            return {
                type: key,
                blocks:
                    strictMode ?? false
                        ? getStrictBlocks(matchedBlocks)
                        : matchedBlocks,
            };
        }
    }

    return {
        type: CreditCardType.GENERAL,
        blocks:
            strictMode ?? false
                ? getStrictBlocks(CreditCardBlocks.general)
                : CreditCardBlocks.general,
    };
};

interface FormatCreditCardOptions {
    delimiter?: string;
    strictMode?: boolean;
    delimiterLazyShow?: boolean;
}
const getMaxLength = (blocks: BlocksType): number =>
    blocks.reduce((previous: number, current: number) => previous + current, 0);

const headStr = (str: string, length: number): string => str.slice(0, length);

interface GetFormattedValueProps {
    value: string;
    blocks: BlocksType;
    delimiter?: DelimiterType;
    delimiters?: DelimiterType[];
    delimiterLazyShow?: boolean;
}

const getFormattedValue = ({
    value,
    blocks,
    delimiter = "",
    delimiters = [],
    delimiterLazyShow = false,
}: GetFormattedValueProps): string => {
    let result = "";
    let valueRemaining = value;
    let currentDelimiter = "";

    blocks.forEach((length: number, index: number) => {
        if (valueRemaining.length > 0) {
            const sub = valueRemaining.slice(0, length);
            const rest = valueRemaining.slice(length);

            if (delimiters.length > 0) {
                currentDelimiter =
                    delimiters[delimiterLazyShow ? index - 1 : index] ??
                    currentDelimiter;
            } else {
                currentDelimiter = delimiter;
            }

            if (delimiterLazyShow) {
                if (index > 0) {
                    result += currentDelimiter;
                }

                result += sub;
            } else {
                result += sub;

                if (sub.length === length && index < blocks.length - 1) {
                    result += currentDelimiter;
                }
            }

            // update remaining string
            valueRemaining = rest;
        }
    });

    return result;
};

export const formatCreditCard = (
    value: string,
    options?: FormatCreditCardOptions
): string => {
    const {
        delimiter = DefaultCreditCardDelimiter,
        delimiterLazyShow = false,
        strictMode = false,
    } = options ?? {};

    // strip non-numeric characters
    value = stripNonNumeric(value);

    // strip delimiters
    value = stripDelimiters({
        value,
        delimiters: [delimiter],
    });

    const { blocks }: CreditCardInfoProps = getCreditCardInfo({
        value,
        strictMode,
    });

    // max length
    const maxLength = getMaxLength(blocks);
    value = headStr(value, maxLength);

    // calculate
    value = getFormattedValue({
        value,
        blocks,
        delimiter,
        delimiterLazyShow,
    });

    return value;
};

export const getCreditCardType = (
    value: string,
    delimiter?: DelimiterType
): CreditCardType => {
    // strip non-numeric characters
    value = stripNonNumeric(value);
    // strip delimiters
    value = stripDelimiters({
        value,
        delimiters: [delimiter ?? DefaultCreditCardDelimiter],
    });

    const { type }: CreditCardInfoProps = getCreditCardInfo({ value });
    return type;
};
