export function getSubdomains(domain: string) {
    const parts = domain.split(".");

    // TLD is always the last part
    const tld = parts[parts.length - 1];

    // If the second-to-last part is greater than 3 characters, it's likely part of a compound TLD
    let baseDomain, subdomains;

    const likelyDomain = parts[parts.length - 2];

    if (!!likelyDomain && likelyDomain.length > 3) {
        // Handle cases like `example.com`, `sub.example.com`
        baseDomain = `${likelyDomain}.${tld}`;
        subdomains = parts.slice(0, parts.length - 2).join(".");
    } else {
        // Handle compound TLDs, e.g., `example.co.uk`
        baseDomain = `${parts[parts.length - 3]}.${likelyDomain}.${tld}`;
        subdomains = parts.slice(0, parts.length - 3).join(".");
    }

    return {
        subdomains: subdomains || null, // Return null if no subdomain
        baseDomain: baseDomain,
    };
}
