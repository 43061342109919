import { StackRepoSource, StackSpec } from "~/services/cycle";

export const rawDefault: StackSpec = {
    version: "1.0",
    containers: {
        containerName: {
            name: "",
            image: {
                name: "",
                origin: {
                    type: "docker-hub",
                    details: { target: "" },
                },
            },
            annotations: {},
            stateful: true,
            config: {
                network: { public: "enable", hostname: "" },
                deploy: { instances: 1 },
            },
        },
    },
};

export const gitRepoDefault: StackRepoSource["details"] = {
    url: "",
    stack_file: null,
};
