import {
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

export function DeployStartupPolicy() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <RhfFormField label="delay" name="config.deploy.startup.delay" required>
            <TextInput
                defaultValue={"0s"}
                {...register("config.deploy.startup.delay", {
                    ...required("Delay cannot be empty"),
                    validate: {
                        ...isDurationString(),
                    },
                })}
            />
        </RhfFormField>
    );
}
