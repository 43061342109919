import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { InviteMemberForm } from "./InviteMemberForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function InviteMemberDialog() {
    const navigate = useNavigate();
    return (
        <StyledFormDialog
            title="Invite Member"
            isOpen={isDialogOpen("hub-member-invite")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <InviteMemberForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
