import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";

export function LearnMore() {
    return (
        <div className="w-1/3 text-lg">
            <FormSectionHeader header="Learn More:" />
            <FormSection>
                <ul className="flex flex-col gap-2 text-sm">
                    <li>
                        <a
                            href={"https://cycle.io/docs/platform/dns-zones"}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-2 "
                        >
                            Platform Docs: DNS Zones
                            <FontAwesomeIcon
                                icon={faExternalLink}
                                className="text-xs"
                            />
                        </a>
                    </li>

                    <li>
                        <a
                            href={"https://cycle.io/docs/platform/dns-records"}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-2 "
                        >
                            Platform Docs: DNS Records
                            <FontAwesomeIcon
                                icon={faExternalLink}
                                className="text-xs"
                            />
                        </a>
                    </li>
                </ul>
            </FormSection>
        </div>
    );
}
