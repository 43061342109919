import {
    InputRow,
    RhfFormField,
    TextInput,
    isDurationString,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import {
    hasMaximumValue,
    hasMinimumValue,
} from "@cycleplatform/ui/components/forms";

export function DeployFunction() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <InputRow>
                <RhfFormField
                    label="max pool size"
                    name="config.deploy.function.max_pool_size"
                >
                    <TextInput
                        placeholder="Set Max Pool Size"
                        type="number"
                        {...register("config.deploy.function.max_pool_size", {
                            setValueAs: (v) => (v === "" ? null : Number(v)),
                            validate: {
                                ...hasMinimumValue(5),
                                ...hasMaximumValue(50),
                            },
                        })}
                    />
                </RhfFormField>
                <RhfFormField
                    label="max shard concurrency"
                    name="config.deploy.function.max_shard_concurrency"
                >
                    <TextInput
                        placeholder="Set Max Concurrency"
                        type="number"
                        {...register(
                            "config.deploy.function.max_shard_concurrency",
                            {
                                setValueAs: (v) =>
                                    v === "" ? null : Number(v),
                            }
                        )}
                    />
                </RhfFormField>
                <RhfFormField
                    label="max runtime"
                    name="config.deploy.function.max_runtime"
                >
                    <TextInput
                        placeholder="1m"
                        {...register("config.deploy.function.max_runtime", {
                            validate: {
                                ...isDurationString(),
                            },
                            setValueAs: (v) => (v === "" ? null : v),
                        })}
                    />
                </RhfFormField>{" "}
                <RhfFormField
                    label="max queue time"
                    name="config.deploy.function.max_queue_time"
                >
                    <TextInput
                        placeholder="1m"
                        {...register("config.deploy.function.max_queue_time", {
                            validate: {
                                ...isDurationString(),
                            },
                            setValueAs: (v) => (v === "" ? null : v),
                        })}
                    />
                </RhfFormField>
            </InputRow>
        </>
    );
}
