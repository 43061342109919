import { BetaBadge } from "@cycleplatform/ui/components/badges";
import {
    FormattedOption,
    SelectInput,
    SelectInputProps,
} from "@cycleplatform/ui/components/forms/select";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import {
    DeploymentStrategy,
    useGetDeploymentStrategiesQuery,
    DeploymentStrategyName,
} from "~/services/cycle";

type DeploymentStrategyWithId = DeploymentStrategy & {
    id: DeploymentStrategyName;
};

type DeploymentStrategySelectProps = Pick<
    SelectInputProps<DeploymentStrategyWithId>,
    | "name"
    | "placeholder"
    | "isOptionValid"
    | "disabled"
    | "defaultValue"
    | "hasError"
> & {
    onChange?: (strategy: DeploymentStrategyName | undefined | null) => void;
    value?: DeploymentStrategyName;
    onFetchError: (error: FetchBaseQueryError | SerializedError) => void;
    isNullable?: boolean;
};

export function DeploymentStrategySelect({
    onFetchError,
    disabled,
    isOptionValid,
    isNullable = true,
    ...props
}: DeploymentStrategySelectProps) {
    const {
        data: strategies,
        isFetching,
        error,
    } = useGetDeploymentStrategiesQuery(undefined, { refetchOnFocus: false });

    const [options, setOptions] = useState<DeploymentStrategyWithId[]>([]);

    useEffect(() => {
        if (!error) {
            return;
        }
        onFetchError(error);
    }, [error]);

    useEffect(() => {
        if (!strategies?.data) {
            return;
        }
        setOptions(
            Object.entries(strategies.data).map(([id, strategy]) => ({
                ...strategy,
                id: id as DeploymentStrategyName,
            }))
        );
    }, [strategies]);

    return (
        <SelectInput<DeploymentStrategyWithId>
            placeholder="Use default deployment strategy"
            hasError={!!error}
            {...props}
            disabled={disabled}
            isLoading={isFetching}
            isNullable={isNullable}
            filterFields={["name", "description"]}
            options={options}
            formatDisplayValue={(s) => s?.name}
            isOptionValid={isOptionValid ? isOptionValid : (s) => !s?.disabled}
            onChange={(v) => props.onChange?.(v?.id || null)}
            value={
                props.value && strategies?.data && strategies.data[props.value]
                    ? ({
                          ...strategies.data[props.value],
                          id: props.value,
                      } as DeploymentStrategyWithId)
                    : undefined
            }
            formatOption={(s) => (
                <FormattedOption label={s.name} detail={s.description}>
                    {s.name === "Function" ? <BetaBadge /> : null}
                </FormattedOption>
            )}
        />
    );
}
