import {
    RhfFormField,
    TextInput,
    startsWithHttpHttps,
    required,
    FormSection,
    FormSectionHeader,
    startsWithSlash,
    hasNoSpaces,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../util";
import { AddCredentialForm } from "./AddCredentialForm";

export function TarballForm() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();
    return (
        <div>
            <FormSectionHeader header="Tarball" />
            <FormSection>
                <div className="flex gap-4">
                    <RhfFormField
                        label="tarball url"
                        name="origin.details.targz_url"
                        required
                    >
                        <TextInput
                            {...register("origin.details.targz_url", {
                                ...required(),
                                shouldUnregister: true,
                                validate: {
                                    ...startsWithHttpHttps(),
                                    ...hasNoSpaces(),
                                },
                            })}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="context directory"
                        name="origin.details.context_dir"
                    >
                        <TextInput
                            {...register("origin.details.context_dir", {
                                shouldUnregister: true,
                            })}
                        />
                    </RhfFormField>
                </div>

                <RhfFormField
                    label="build file"
                    name="origin.details.build_file"
                >
                    <TextInput
                        placeholder="/Dockerfile"
                        {...register("origin.details.build_file", {
                            shouldUnregister: true,
                            validate: {
                                ...startsWithSlash(
                                    `Destination path must begin with a "/"`
                                ),
                                ...hasNoSpaces(),
                            },
                        })}
                    />
                </RhfFormField>
            </FormSection>
            <AddCredentialForm />
        </div>
    );
}
