import {
    DialogPageBody,
    DialogPageContent,
    DialogPageHeader,
} from "@cycleplatform/ui/components/dialog";
import { RemovePortButton } from "./form/RemovePortButton";
import { useFormContext } from "react-hook-form";
import { LoadBalancerFormValues } from "../form";
import { V1LbConfig } from "~/services/cycle";
import { V1LbFormFooter } from "./form/V1LbFormFooter";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../../context";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { OverviewSection } from "./traffic/overview/OverviewSection";
import { UrlsSection } from "./traffic/urls/UrlsSection";
import { RoutersSection } from "./traffic/routers/RoutersSection";
import classNames from "classnames";
import { V1PortForm } from "./form/v1PortForm/V1PortForm";

export function PortContent({
    idxToPortMap,
}: {
    idxToPortMap: Record<string, number>;
}) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortIdx = params["dialog-controllers-port-idx"];
    const port = idxToPortMap[activePortIdx];

    return (
        <div className="relative h-full w-full ">
            <DialogPageContent className="w-full min-w-0 !p-0 ">
                <div className="w-full overflow-y-auto pt-4">
                    <DialogPageHeader
                        key={activePortIdx}
                        title={`Port ${port}`}
                        icon={NavIcons["lbController"]}
                        tabs={[
                            {
                                name: "Overview",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "overview",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "overview",
                            },
                            {
                                name: "URLs",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection": "urls",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "urls",
                            },
                            {
                                name: "Routers",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "routers",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "routers",
                            },
                            {
                                name: "Config",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "config",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "config",
                            },
                        ]}
                    >
                        <RemovePortButton environment={environment} />
                    </DialogPageHeader>

                    {params["dialog-controllers-subsection"] === "overview" ? (
                        <OverviewSection port={port} key={`port-${port}`} />
                    ) : null}
                    {params["dialog-controllers-subsection"] === "urls" ? (
                        <UrlsSection port={port} key={`port-${port}`} />
                    ) : null}
                    {params["dialog-controllers-subsection"] === "routers" ? (
                        <RoutersSection port={port} key={`port-${port}`} />
                    ) : null}

                    <div
                        className={classNames(
                            params["dialog-controllers-subsection"] === "config"
                                ? "visible"
                                : "hidden"
                        )}
                    >
                        <ControllerConfigSection />
                    </div>
                </div>
                <V1LbFormFooter />
            </DialogPageContent>
        </div>
    );
}

function ControllerConfigSection() {
    const { getValues } = useFormContext<LoadBalancerFormValues>();
    const details = getValues("config.details") as V1LbConfig;
    const hasCap = useCapability("environments-services-manage");

    if (hasCap instanceof Error) {
        return (
            <DialogPageBody className="w-full p-4">
                <EmptyResource
                    className="flex h-60 items-center justify-center border-none"
                    icon={NavIcons["lbController"]}
                    title={`Load Balancer Controllers Restricted`}
                >
                    <p className="text-center">
                        Managing load balancer controllers requires the
                        "environment-services-manage" capability.
                    </p>
                </EmptyResource>
            </DialogPageBody>
        );
    }

    if (!details?.controllers) {
        return null;
    }

    return details.controllers.map((p, idx) => {
        return (
            <V1PortForm
                key={`controller-${idx}`}
                idx={idx}
                baseRoute={`config.details.controllers.${idx}`}
            />
        );
    });
}
