import classNames from "classnames";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { AccountMenu } from "./AccountMenu";
import { HubSwitcher } from "./HubSwitcher";
import logo from "@cycleplatform/ui/images/cycle-logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCode, faCommand } from "@fortawesome/pro-solid-svg-icons";
import { MenuContext } from "../context";
import { JobsMenu } from "./JobsMenu";
import { useKBar, VisualState } from "kbar";
import { SidebarNavLink } from "./SidebarNavLink";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { Version } from "./Version";
import { NavIcons } from "../NavIcons";
import { useGetHubsQuery } from "~/services/cycle";

export function Sidebar() {
    const context = useContext(MenuContext);
    const { query } = useKBar();
    const closeMobileMenu = useCallback(() => {
        if (context.isMobileMenuOpen) {
            context.setIsMobileMenuOpen(false);
        }
    }, [context]);

    const { data: hubs, isLoading } = useGetHubsQuery({});

    return (
        <aside
            className={classNames(
                "bg-cycle-black-accent flex h-full overflow-y-auto",
                "w-72 min-w-[18rem] flex-col"
            )}
        >
            <div className="flex h-full flex-col">
                <div className="flex justify-between px-1 py-4">
                    <img src={logo} className="mx-2 my-2 w-36" />
                    <AccountMenu />
                </div>

                <nav className="flex grow flex-col justify-between">
                    <div className="flex flex-col pl-4">
                        <div className="flex flex-col gap-4 pb-8 pr-4">
                            <div className="flex items-center justify-between">
                                <HubSwitcher />
                            </div>
                            <div className="flex items-center justify-between gap-2">
                                <button
                                    className={classNames(
                                        "flex w-5/6  flex-1 items-center justify-between  rounded transition",
                                        "hover:border-cycle-blue hover:text-white"
                                    )}
                                    onClick={() =>
                                        query.setVisualState(
                                            VisualState.animatingIn
                                        )
                                    }
                                >
                                    <div className="flex h-8 flex-1">
                                        <div
                                            className={classNames(
                                                "h-8 w-10",
                                                " bg-cycle-gray text-cycle-gray-light group-focus-within:ring-cycle-blue  flex h-full min-w-[3rem] grow items-center justify-center rounded-l-md px-2 group-focus-within:ring-2"
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div>
                                        <div className="bg-cycle-gray-accent text-cycle-white-accent/50 flex w-full items-center justify-between rounded-r-md px-4 text-start">
                                            <p>Search</p>
                                            <div className="flex items-center gap-1 text-xs">
                                                {navigator.userAgent.includes(
                                                    "Mac"
                                                ) ? (
                                                    <FontAwesomeIcon
                                                        icon={faCommand}
                                                        size="xs"
                                                    />
                                                ) : (
                                                    <span className="font-semibold">
                                                        CTRL
                                                    </span>
                                                )}
                                                +
                                                <span className="font-semibold">
                                                    K
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                                <JobsMenu />
                            </div>
                        </div>

                        {(hubs?.data && hubs?.data?.length) || isLoading ? (
                            <>
                                <SidebarNavLink
                                    to="/"
                                    title="Dashboard"
                                    icon={NavIcons["dashboard"]}
                                    end
                                    onClick={closeMobileMenu}
                                />
                                <SidebarNavLink
                                    to="/environments"
                                    title="Environments"
                                    icon={NavIcons["environments"]}
                                    onClick={closeMobileMenu}
                                />

                                <SidebarNavLink
                                    to="/images"
                                    title="Images"
                                    icon={NavIcons["images"]}
                                    onClick={closeMobileMenu}
                                    subLinks={[
                                        {
                                            to: "/images",
                                            title: "Dashboard",
                                            icon: NavIcons["altDash"],
                                            end: true,
                                        },
                                        {
                                            to: "/images/sources",
                                            title: "Sources",
                                            icon: NavIcons["imageSources"],
                                        },
                                    ]}
                                />
                                <SidebarNavLink
                                    to="/stacks"
                                    title="Stacks"
                                    icon={NavIcons["stacks"]}
                                    onClick={closeMobileMenu}
                                />

                                <SidebarNavLink
                                    to="/pipelines"
                                    title="Pipelines"
                                    icon={NavIcons["pipelines"]}
                                    onClick={closeMobileMenu}
                                />

                                <SidebarNavLink
                                    to="/infrastructure"
                                    icon={NavIcons["infrastructure"]}
                                    onClick={closeMobileMenu}
                                    title="Infrastructure"
                                    subLinks={[
                                        {
                                            to: "/infrastructure/clusters",
                                            title: "Clusters",
                                            icon: NavIcons[
                                                "infrastructureClusters"
                                            ],
                                        },
                                        {
                                            to: "/infrastructure/servers",
                                            title: "Servers",
                                            icon: NavIcons[
                                                "infrastructureServers"
                                            ],
                                        },

                                        {
                                            to: "/infrastructure/autoscaling",
                                            title: "Auto-Scaling",
                                            beta: true,
                                            icon: NavIcons[
                                                "infrastructureScaling"
                                            ],
                                        },
                                        {
                                            to: "/infrastructure/ips",
                                            title: "IPs",
                                            icon: NavIcons["infrastructureIps"],
                                        },
                                    ]}
                                />

                                <SidebarNavLink
                                    to="/dns"
                                    title="DNS"
                                    icon={NavIcons["dns"]}
                                    onClick={closeMobileMenu}
                                    subLinks={[
                                        {
                                            to: "/dns/zones",
                                            title: "Zones",
                                            icon: NavIcons["dnsZone"],
                                        },
                                        {
                                            to: "/dns/tls",
                                            title: "TLS",
                                            icon: NavIcons["dnsTls"],
                                        },
                                    ]}
                                />

                                <SidebarNavLink
                                    to="/networks"
                                    title="Networks"
                                    icon={NavIcons["networks"]}
                                    onClick={closeMobileMenu}
                                />

                                <SidebarNavLink
                                    to="/billing"
                                    title="Billing"
                                    icon={NavIcons["billing"]}
                                    onClick={closeMobileMenu}
                                    subLinks={[
                                        {
                                            to: "/billing",
                                            title: "Dashboard",
                                            icon: NavIcons["altDash"],
                                            end: true,
                                        },
                                        {
                                            to: "/billing/invoices",
                                            title: "Invoices",
                                            icon: NavIcons["billingInvoices"],
                                        },
                                        {
                                            to: "/billing/methods",
                                            title: "Methods",
                                            icon: NavIcons["billingMethods"],
                                        },
                                        {
                                            to: "/billing/active-services",
                                            title: "Active Services",
                                            icon: NavIcons[
                                                "billingActiveServices"
                                            ],
                                        },
                                        {
                                            to: "/billing/credits",
                                            title: "Credits",
                                            icon: NavIcons["billingCredits"],
                                        },
                                    ]}
                                />

                                <SidebarNavLink
                                    to="/hub"
                                    title="Hub"
                                    icon={NavIcons["hubs"]}
                                    onClick={closeMobileMenu}
                                    subLinks={[
                                        {
                                            to: "/hub",
                                            title: "Settings",
                                            icon: NavIcons["hubSettings"],
                                            end: true,
                                        },
                                        {
                                            to: "/hub/roles",
                                            title: "Roles",
                                            icon: NavIcons["hubRoles"],
                                        },
                                        {
                                            to: "/hub/members",
                                            title: "Members",
                                            icon: NavIcons["hubMembers"],
                                        },
                                        {
                                            to: "/hub/invites",
                                            title: "Invites",
                                            icon: NavIcons["hubInvites"],
                                        },
                                        {
                                            to: "/hub/integrations",
                                            title: "Integrations",
                                            icon: NavIcons["hubIntegrations"],
                                        },
                                        {
                                            to: "/hub/plans",
                                            title: "Plans",
                                            icon: NavIcons["hubPlans"],
                                        },
                                        {
                                            to: "/hub/api-keys",
                                            title: "API Keys",
                                            icon: NavIcons["hubApiKeys"],
                                        },
                                        {
                                            to: "/hub/audit-log/activity",
                                            title: "Audit Log",
                                            icon: NavIcons["hubAuditLog"],
                                        },
                                    ]}
                                />
                            </>
                        ) : (
                            <SidebarNavLink
                                to="/create-hub"
                                title="Create Hub"
                                icon={NavIcons["altDash"]}
                                end
                                onClick={closeMobileMenu}
                            />
                        )}
                    </div>

                    <div className="flex items-end justify-between px-4 py-2">
                        <div>
                            <Link
                                to="https://cycle.io/docs"
                                target="_blank"
                                rel="noopener"
                                className="inherit hover:!text-cycle-blue flex items-center gap-2 text-sm transition"
                            >
                                <FontAwesomeIcon
                                    icon={faCode}
                                    className="w-[1rem]"
                                />
                                Docs
                            </Link>
                            <Link
                                to="https://status.cycle.io"
                                target="_blank"
                                rel="noopener"
                                className="inherit hover:!text-cycle-blue flex items-center gap-2 text-sm transition"
                            >
                                <FontAwesomeIcon
                                    icon={faBell}
                                    className="w-[1rem]"
                                />
                                Status
                            </Link>
                        </div>

                        <Version />
                    </div>
                </nav>
            </div>
        </aside>
    );
}
