import { RhfFormField } from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { TagSelectInput } from "~/components/common/forms/TagSelectInput";
import { Container } from "~/services/cycle";

export function DeployContraints({ container }: { container?: Container }) {
    const { control } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <InfoPanel type="info">
                <p>
                    Constraints provide the ability to set restrictions on which
                    nodes instances of this container are able to be deployed to
                    (i.e. if you have a GPU container, it should only go on
                    nodes with a GPU).
                </p>
            </InfoPanel>

            <div className="flex gap-4">
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match One)"
                        name="config.deploy.constraints.node.tags.any"
                    >
                        {container ? (
                            <Controller
                                name="config.deploy.constraints.node.tags.any"
                                control={control}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        value={field.value || []}
                                        additionalTags={
                                            container?.config?.deploy
                                                ?.constraints?.node?.tags.any
                                        }
                                        cluster={
                                            container?.environment?.cluster
                                        }
                                    />
                                )}
                            />
                        ) : (
                            <SkeletonButton />
                        )}
                    </RhfFormField>
                </div>
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match All)"
                        name="config.deploy.constraints.node.tags.all"
                    >
                        {container ? (
                            <Controller
                                name="config.deploy.constraints.node.tags.all"
                                control={control}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        additionalTags={
                                            container?.config?.deploy
                                                ?.constraints?.node?.tags.all
                                        }
                                        value={field.value || []}
                                        cluster={
                                            container?.environment?.cluster
                                        }
                                    />
                                )}
                            />
                        ) : (
                            <SkeletonButton />
                        )}
                    </RhfFormField>
                </div>
            </div>
        </>
    );
}
