import {
    TextInput,
    RhfFormField,
    required,
    FormSectionHeader,
    FormSection,
    isDurationString,
    Checkbox,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { LoadBalancerFormValues } from "../../../../../../form";
import { V1RouterExtension } from "./V1RouterExtension";

export function V1RouterConfig({
    baseRoute,
    idx,
    mode,
}: {
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
    mode: "tcp" | "udp" | "http";
}) {
    const { register } = useFormContext<LoadBalancerFormValues>();

    return (
        <>
            <FormSectionHeader header="Config" />
            <FormSection>
                <InputRow>
                    <RhfFormField
                        name={`${baseRoute}.${idx}.config.destination_retries`}
                        label="Destination Retries"
                        required
                    >
                        <TextInput
                            type="number"
                            {...register(
                                `${baseRoute}.${idx}.config.destination_retries`,
                                {
                                    valueAsNumber: true,
                                    ...required(),
                                }
                            )}
                        />
                    </RhfFormField>

                    <RhfFormField
                        name={`${baseRoute}.${idx}.config.timeouts.destination_connection`}
                        label="Destination Connection Timeout"
                        required
                    >
                        <TextInput
                            {...register(
                                `${baseRoute}.${idx}.config.timeouts.destination_connection`,
                                {
                                    ...required(),
                                    validate: {
                                        ...isDurationString(),
                                    },
                                }
                            )}
                        />
                    </RhfFormField>
                </InputRow>
                <InputRow>
                    <DestinationPrioritizationInput
                        baseRoute={baseRoute}
                        idx={idx}
                    />
                    <RhfFormField
                        name={`${baseRoute}.${idx}.config.sticky_sessions`}
                        label="Sticky Sessions"
                        className="!w-[20rem]"
                    >
                        <Checkbox
                            {...register(
                                `${baseRoute}.${idx}.config.sticky_sessions`
                            )}
                        />
                    </RhfFormField>
                </InputRow>
                <V1RouterExtension
                    mode={mode}
                    baseRoute={baseRoute}
                    idx={idx}
                />
            </FormSection>
        </>
    );
}

function DestinationPrioritizationInput({
    baseRoute,
    idx,
}: {
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
}) {
    const { control } = useFormContext<LoadBalancerFormValues>();

    const options = [
        {
            value: "random",
            label: "Random",
        },
        {
            value: "latency",
            label: "Latency",
        },
    ];

    return (
        <RhfFormField
            name={`${baseRoute}.${idx}.config.destination_prioritization`}
            label="Destination Prioritization"
        >
            <Controller
                render={({ field: { ref: _ref, ...field } }) => (
                    <SelectInput
                        {...field}
                        placeholder="Use Platform Default"
                        isNullable
                        options={options}
                        value={options.find((o) => o.value === field.value)}
                        onChange={(v) => field.onChange(v?.value || null)}
                        formatOption={(o) => (
                            <FormattedOption label={o.label} />
                        )}
                        formatDisplayValue={(o) => {
                            return o?.label || "";
                        }}
                    />
                )}
                control={control}
                name={`${baseRoute}.${idx}.config.destination_prioritization`}
            />
        </RhfFormField>
    );
}
