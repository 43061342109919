import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";
import { useMemo } from "react";
import { GetContainersApiArg, useGetContainersQuery } from "~/services/cycle";

export function ContainerIdentifierMultiSelectInput({
    skip,
    filter,
    ...props
}: {
    filter?: GetContainersApiArg["filter"];
    name?: string;
    /** For use as an uncontrolled input */
    defaultValue?: string[];
    /** For use as a controlled input */
    value?: string[] | null;
    onChange?: (value: string[] | null) => void;
    onBlur?: () => void;
    disabled?: boolean;
    hasError?: boolean;
    placeholder?: string;
    skip?: boolean;
    isCreatable?: boolean;
}) {
    const { currentData: containers, error } = useGetContainersQuery(
        {
            sort: ["-id"],
            filter,
            page: {
                number: 1,
                size: 100,
            },
        },
        { skip }
    );

    const containerIdentifiers = useMemo(
        () => [...new Set(containers?.data?.map((c) => c.identifier))],
        [containers?.data]
    );

    if (error) {
        throw error;
    }

    return (
        <MultiSelectInput
            {...props}
            placeholder={"No identifiers listed"}
            options={containerIdentifiers}
        />
    );
}
