import { FormToggle, RhfCodeInput } from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import { V1LbConfig } from "~/services/cycle";
import { LoadBalancerFormValues } from "../../../../../form";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { Button } from "@cycleplatform/ui/components/buttons";

export function V1ExtensionSection({
    baseRoute,
}: {
    baseRoute: `config.details.controllers.${number}`;
}) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const { control, setValue, unregister } =
        useFormContext<LoadBalancerFormValues>();

    const extension = useWatch({
        control,
        name: `${baseRoute}.transport.config.extension`,
    });

    const [extensionExpanded, setExtensionExpanded] = useState(!!extension);

    const mode = useWatch({ control, name: `${baseRoute}.transport.mode` });

    useEffect(() => {
        if (extension && extension.type !== mode) {
            setExtensionExpanded(false);
            unregister(`${baseRoute}.transport.config.extension`);
        }
    }, [mode, extension]);

    return (
        <Panel>
            <PanelTitle title="Extension" className="flex justify-between">
                <FormToggle
                    value={extensionExpanded}
                    onChange={(v) => {
                        setValue("isDirty", true, {
                            shouldDirty: true,
                        });
                        setExtensionExpanded(v);

                        if (v) {
                            setValue(
                                `${baseRoute}.transport.config.extension`,
                                getDefaultExtension(mode)
                            );
                        } else {
                            unregister(
                                `${baseRoute}.transport.config.extension`
                            );
                        }
                    }}
                />
            </PanelTitle>
            <PanelContent>
                {extensionExpanded ? (
                    <>
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <RhfCodeInput {...field} />
                            )}
                            control={control}
                            name={`${baseRoute}.transport.config.extension.details`}
                        />
                    </>
                ) : (
                    <EmptyResource
                        title="Extension Disabled"
                        className="border-none !p-0"
                    >
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <Button
                                onClick={() => {
                                    setExtensionExpanded(true);
                                    setValue(
                                        `${baseRoute}.transport.config.extension`,
                                        getDefaultExtension(mode)
                                    );
                                }}
                            >
                                Enable
                            </Button>
                        </AccessControlOverlay>
                    </EmptyResource>
                )}
            </PanelContent>
        </Panel>
    );
}

function getDefaultExtension(
    mode: "tcp" | "http" | "udp"
): NonNullable<
    V1LbConfig["controllers"][number]["transport"]
>["config"]["extension"] {
    if (mode === "http") {
        return {
            type: "http",
            details: {
                connections: {
                    max_idle_conns_per_connection: null,
                },
                telemetry: {},
            },
        };
    }

    if (mode === "udp") {
        return {
            type: "udp",
            details: {
                telemetry: {},
            },
        };
    }

    return {
        type: mode,
        details: {
            connections: {},
            telemetry: {},
        },
    };
}
