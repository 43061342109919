import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faArrowRight, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { useContext, useState } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { useAppSelector } from "~/hooks";
import { getCurrentForm } from "~/modules/currentForm/slice";
import { Enable2FaFormSteps } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetHubQuery } from "~/services/cycle";

export function CompleteStep() {
    const currentForm = useAppSelector(getCurrentForm);
    const [allowContinue, setAllowContinue] = useState(false);
    const { refetch, isLoading } = useGetHubQuery({}, { skip: !allowContinue });

    const recoveryCodes = (currentForm?.steps?.[1] as Enable2FaFormSteps[1])
        .recoveryCodes;

    return (
        <>
            <div className="flex">
                <DialogColumn>
                    <h2 className="mb-4 text-xl">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success mr-2"
                        />
                        Two-Factor Authentication Enabled
                    </h2>
                    <p>
                        Two-factor authentication has been enabled for your
                        account. Please copy the recovery codes below and store
                        them somewhere safe in case you lose access to your
                        account.
                    </p>

                    <div className="mt-8 flex flex-wrap">
                        {recoveryCodes?.map((c) => (
                            <div key={c} className="w-1/2 text-center">
                                <div className="pb-2 font-semibold">{c}</div>
                            </div>
                        ))}
                    </div>
                </DialogColumn>
            </div>

            <DialogFooter>
                <LoaderButton
                    onClick={() => {
                        setAllowContinue(true);
                        setTimeout(() => {
                            refetch();
                        }, 100);
                    }}
                    isLoading={isLoading}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                >
                    Continue To Hub
                </LoaderButton>
            </DialogFooter>
        </>
    );
}
