import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { CreateApiKeyForm } from "./CreateApiKeyForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function ApiKeyCreateDialog() {
    const navigate = useNavigate();

    return (
        <StyledFormDialog
            title="New Api Key"
            isOpen={isDialogOpen("api-key-create")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <CreateApiKeyForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
