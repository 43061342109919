import {
    Checkbox,
    EnableSectionInline,
    FormField,
    isNotZero,
    required,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { useState } from "react";

export function CpuSharesInput() {
    const { getValues, setValue, register } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.resources.cpu.shares") !== undefined
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField label="Enable Shares">
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (ev.target.checked) {
                                setValue(
                                    "config.resources.cpu.shares",
                                    { limit: 1, reserve: 1 },
                                    { shouldDirty: true }
                                );
                            } else {
                                setValue(
                                    "config.resources.cpu.shares",
                                    undefined,
                                    { shouldDirty: true }
                                );
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <>
                    <RhfFormField
                        className="w-full"
                        label="Limit"
                        name="config.resources.cpu.shares.limit"
                        required
                    >
                        <TextInput
                            type="number"
                            {...register("config.resources.cpu.shares.limit", {
                                valueAsNumber: true,
                            })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        className="w-full"
                        label="Reserve"
                        name="config.resources.cpu.shares.reserve"
                        required
                    >
                        <TextInput
                            type="number"
                            {...register(
                                "config.resources.cpu.shares.reserve",
                                {
                                    valueAsNumber: true,
                                    ...required(
                                        "CPU shares reserve cannot be undefined"
                                    ),
                                    validate: {
                                        ...isNotZero(
                                            "CPU shares reserve cannot be 0"
                                        ),
                                    },
                                }
                            )}
                        />
                    </RhfFormField>
                </>
            ) : (
                <>
                    <FormField className="w-full" label="Limit">
                        <TextInput type="number" disabled />
                    </FormField>
                    <FormField className="w-full" label="Reserve">
                        <TextInput type="number" disabled />
                    </FormField>
                </>
            )}
        </EnableSectionInline>
    );
}
