import { useNavigate } from "react-router-dom";
import {
    DialogPageBody,
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { useGetHubMemberAccountQuery } from "~/services/cycle";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { MemberRecentActivity } from "~/components/account/MemberRecentActivity";
import { UserInfo } from "~/components/account/UserInfo";
import { faEdit, faUser } from "@fortawesome/pro-solid-svg-icons";
import { formatName } from "@cycleplatform/ui/components/resources/accounts/formatName";
import classNames from "classnames";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { Avatar } from "@cycleplatform/ui/components/avatar";
import { Button, ButtonLink } from "@cycleplatform/ui/components/buttons";
import { AccessControlOverlay } from "~/components/common/buttons";

export function AccountInfoDialog() {
    const params = getAllDialogSearchParams<"account-info">();
    const { data: member } = useGetHubMemberAccountQuery(
        {
            accountId: params["dialog-account-id"] || "",
            include: ["accounts", "roles"],
        },
        { skip: !params["dialog-account-id"] }
    );
    const navigate = useNavigate();
    const account =
        member?.includes?.accounts?.[params["dialog-account-id"] || ""];

    return (
        <StyledResourceDialog
            header={
                <div
                    className={classNames(
                        "flex w-full items-center  justify-between"
                    )}
                >
                    <div
                        className={classNames(
                            "flex min-w-[10rem] items-center gap-2",
                            !account?.name && skeletonStyles
                        )}
                    >
                        <Avatar email={account?.email.address} />
                        <div>
                            {account?.name ? formatName(account?.name) : ""}
                        </div>
                    </div>
                    <div>
                        <AccessControlOverlay
                            capability={"hubs-members-manage"}
                        >
                            <ButtonLink
                                to={`/hub/members/${member?.data?.id}`}
                                icon={faEdit}
                                flavor="primary"
                                className={classNames(
                                    "border-cycle-blue-accent border-r text-base"
                                )}
                            />
                        </AccessControlOverlay>
                    </div>
                </div>
            }
            open={isDialogOpen("account-info")}
            breadcrumb={[
                {
                    label: "Account",
                },
                {
                    label: account?.name ? formatName(account?.name) : "",
                },
            ]}
            onClose={() => navigate(clearDialogParams())}
        >
            <ResourceDialogTab to={null} name="Dashboard" icon={faUser}>
                <DialogPageBody>
                    <PageContent>
                        <Panel>
                            <PanelTitle title="Recent Activity" />
                            <PanelContentBoundary>
                                <MemberRecentActivity
                                    accountId={
                                        params["dialog-account-id"] || ""
                                    }
                                />
                            </PanelContentBoundary>
                        </Panel>
                    </PageContent>
                    <PageAside className="">
                        <Panel>
                            <PanelTitle title="Info" />
                            <PanelContentBoundary>
                                <UserInfo
                                    account={account}
                                    member={member?.data}
                                    memberIncludes={member?.includes}
                                />
                            </PanelContentBoundary>
                        </Panel>
                    </PageAside>
                </DialogPageBody>
            </ResourceDialogTab>
        </StyledResourceDialog>
    );
}
