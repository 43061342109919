import { useFormContext, useWatch } from "react-hook-form";
import { CodeInput } from "../CodeInput";

export function RhfCodeInput({
    onChange,
    name,
    className,
    fileType,
}: {
    onChange: (value: Record<string, unknown>) => void;
    name: string;
    className?: string;
    fileType?: "json" | "yaml";
}) {
    const form = useFormContext();

    // Use watch ensures that the component will re-render when the value changes in the bg
    // Important since the data will be deeply nested and the controller only subscribes to the field name
    // https://github.com/react-hook-form/react-hook-form/issues/7758

    const watchValue = useWatch({ name, control: form.control });

    return (
        <CodeInput
            onChange={onChange}
            name={name}
            value={watchValue}
            className={className}
            fileType={fileType}
        />
    );
}
