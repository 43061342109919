import { components } from "@cycleplatform/core/modules/api/__generated";
import { FormattedMoney } from "../../money";
import classNames from "classnames";

type ServerTitleProps = {
    providerServer: components["schemas"]["ProviderServerModel"];
    providerName: string;
    className?: string;
};

export function ServerPrice({
    providerServer,
    providerName,
    className,
}: ServerTitleProps) {
    return (
        <div className={classNames("flex w-full justify-end", className)}>
            <div>
                <span className="text-cycle-blue text-xl font-bold ">
                    ~
                    <FormattedMoney
                        value={providerServer.price.mills}
                        mills
                        className="inline-block"
                    />
                </span>
                <span className="text-xs">/mo</span>

                <div className="text-sm">{`Billed By ${providerName}`}</div>
            </div>
        </div>
    );
}
