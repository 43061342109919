import { formatDateString } from "@cycleplatform/core/util";
import {
    faChevronDown,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTerminal } from "~/components/common/terminal/useTerminal";
import {
    ContainerBackup,
    ContainerBackupLogs,
    useGetContainerBackupLogsQuery,
} from "~/services/cycle";

type BackupLogsProps = {
    backup: ContainerBackup;
};

export function BackupLogList({ backup }: BackupLogsProps) {
    const { data: logs, error } = useGetContainerBackupLogsQuery({
        backupId: backup.id,
        containerId: backup.container_id,
    });
    if (error) {
        throw error;
    }
    return (
        <ul className="flex flex-col gap-2">
            {logs?.data?.map((l) => (
                <BackupEventItem key={l.id} log={l} />
            ))}
        </ul>
    );
}

// TODO change open api spec to make BackupLogs singular.
function BackupEventItem({ log }: { log: ContainerBackupLogs }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <li
            key={log.id}
            onClick={() => setIsExpanded(!isExpanded)}
            className="dark:border-cycle-gray rounded-md border-2 px-4 py-2 "
        >
            <div className="flex items-center justify-between">
                <div>
                    <div className="text-xl capitalize">{log.type} Event</div>
                    <div>{formatDateString(log.events.created, "PPpp")}</div>
                </div>
                <FontAwesomeIcon
                    icon={isExpanded ? faChevronDown : faChevronRight}
                    className="text-cycle-blue text-lg"
                />
            </div>

            {isExpanded && <BackupTerminal log={log} />}
        </li>
    );
}

function BackupTerminal({ log }: { log: ContainerBackupLogs }) {
    const { containerRef, terminal } = useTerminal();
    useEffect(() => {
        log.log.split("\n").forEach((l) => terminal.writeln(l));
    }, []);
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="mt-4 h-full w-full"
            ref={containerRef}
        />
    );
}
