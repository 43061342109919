import { V1LbController } from "~/services/cycle";

export function generateIdxToPortMap(
    lbControllers: V1LbController[]
): Record<string, number> {
    if (!lbControllers) {
        return {};
    }

    return lbControllers.reduce((acc, cur, idx) => {
        return { ...acc, [idx.toString()]: cur.port };
    }, {});
}
