import { Controller, useFormContext } from "react-hook-form";
import { VolumeSummary } from "~/services/cycle";
import {
    Checkbox,
    RhfFormField,
    RhfDataSizeInput,
} from "@cycleplatform/ui/components/forms";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { BooleanSelect } from "@cycleplatform/ui/components/forms/select";
import { ReconfigureVolumeSubmit } from "./VolumesTab";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";

type BaseVolumeSettingsSectionProps = {
    volume?: VolumeSummary;
    idx: number;
};

export function BaseVolumeSettingsSection({
    volume,
    idx,
}: BaseVolumeSettingsSectionProps) {
    const { register, control } = useFormContext<ReconfigureVolumeSubmit>();

    return (
        <Panel key={volume?.id}>
            <PanelTitle title="config" className="flex justify-between" />
            <PanelContentBoundary>
                <div className="flex justify-between gap-8">
                    <RhfFormField
                        label="Max Size"
                        name={`volumes.${idx}.config.local.max_size`}
                        help="This will only impact future instances and migrated instances"
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <RhfDataSizeInput {...field} />
                            )}
                            control={control}
                            name={`volumes.${idx}.config.local.max_size`}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Read-Only"
                        name={`volumes.${idx}.config.read_only`}
                    >
                        <Checkbox
                            {...register(`volumes.${idx}.config.read_only`)}
                        />
                    </RhfFormField>
                    <RhfFormField
                        className="w-1/2"
                        label="Storage Pool"
                        name={`volumes.${idx}.config.local.storage_pool`}
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <BooleanSelect
                                    {...field}
                                    disabled
                                    placeholder="Use Default Storage Pool Value"
                                    options={[
                                        {
                                            label: "Enabled",
                                            value: true,
                                        },
                                        {
                                            label: "Disabled",
                                            value: false,
                                        },
                                    ]}
                                />
                            )}
                            control={control}
                            name={`volumes.${idx}.config.local.storage_pool`}
                        />
                    </RhfFormField>
                </div>
            </PanelContentBoundary>
        </Panel>
    );
}
