import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ApiKey, ComponentIncludes, Role } from "~/services/cycle";
import { Link } from "react-router-dom";
import { extractComponent, isErroredActivity } from "./common/util";

export function formatRoleMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name =
        extractComponent<Role>(item.component, components)?.name ||
        item.component?.id;

    const link = <Link to={`/hub/roles/${item.component?.id}`}>{name}</Link>;

    switch (item.event) {
        case "hub.role.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted Role {link}</span>
                ) : (
                    <span>Error deleting Role {link}</span>
                ),
                icon: faTrash,
            };
        case "hub.role.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of role {link}</span>
                ) : (
                    <span>Error requesting deletion of role {link}</span>
                ),
                icon: faTrash,
            };
        case "hub.role.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated Role {link}</span>
                ) : (
                    <span>Error updating Role {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "hub.role.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created Role {link}</span>
                ) : (
                    <span>Error creating API key</span>
                ),
                icon: faPlus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
