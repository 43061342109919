import {
    faSync,
    faHdd,
    faQuestionCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    Activity,
    ComponentIncludes,
    Container,
    Environment,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatContainerBackupMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const { container_id: containerId = "" } = item.context;
    // create container link
    const containerName = extractContainerName(containerId, components);
    const containerLink = (
        <Link
            to={generateDialogLink("container", {
                "dialog-container-id": containerId,
            })}
        >
            {containerName}
        </Link>
    );

    // create environment link
    const envName = extractEnvironmentName(
        item.context.environment_id || "",
        components
    );

    const envLink = (
        <Link to={`/environments/${item.context.environment_id}`}>
            {envName}
        </Link>
    );

    switch (item.event) {
        case "container.backup.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating backing for container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faHdd,
            };
        case "container.backup.restore":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Restored container {containerLink}in environment{" "}
                        {envLink} from backup
                    </span>
                ) : (
                    <span>
                        Error restoring container {containerLink} in environment{" "}
                        {envLink} from backup
                    </span>
                ),
                icon: faSync,
            };
        case "container.backup.task.restore":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restore of container {containerLink} in
                        environment {envLink} from backup
                    </span>
                ) : (
                    <span>
                        Error requesting restore of container {containerLink} in
                        environment {envLink} from backup
                    </span>
                ),
                icon: faSync,
            };
        case "container.backup.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faTimesCircle,
            };
        case "container.backup.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of backup for container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of backup for container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faTimesCircle,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractContainerName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Container).name;
}

function extractEnvironmentName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Environment).name;
}
