import {
    FieldErrorMessage,
    RhfFormProvider,
} from "@cycleplatform/ui/components/forms";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    useCreateServerMutation,
    useGetIntegrationQuery,
    useGetProviderLocationsQuery,
} from "~/services/cycle";
import { SelectProviderStep } from "./steps/selectProvider";
import { SelectServersStep } from "./steps/selectServers";
import { CreateServerSchema } from "./form";
import { useNavigate } from "react-router-dom";
import { shouldClose } from "../../helpers";
import { useSetServerParams } from "./helpers/hooks";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { isCycleApiError } from "~/services/helpers";

type AddServerFormProps = {
    step?: string;
    locationId?: string;
    integrationId?: string;
    navigateTo?: string;
};

export function AddServerForm({
    step,
    locationId,
    integrationId,
    navigateTo = `/infrastructure/servers`,
}: AddServerFormProps) {
    const setServerParams = useSetServerParams();

    const form = useForm<CreateServerSchema>({
        defaultValues: {
            servers: {},
        },
        ...rhfConfig,
    });

    const [createServer] = useCreateServerMutation();
    const nav = useNavigate();

    // Fetch the integration from the URL param to see if it exists.
    // If not, don't let us go to step 2
    const { data: integration, error: integrationError } =
        useGetIntegrationQuery(
            {
                integrationId: integrationId || "",
                meta: ["definition"],
            },
            {
                skip: !integrationId,
            }
        );

    const { currentData: locations, error: locationsError } =
        useGetProviderLocationsQuery(
            {
                providerVendor: integrationId || "",
                page: {
                    size: 100,
                    number: 1,
                },
            },
            {
                skip: !integrationId,
            }
        );
    const selectedLocation = locations?.data.find((l) => l.id === locationId);

    const onSubmit = (data: CreateServerSchema) => {
        if (!integrationId || !locationId) {
            return;
        }
        const transformedServers = Object.values(data.servers).map((s) => {
            return {
                integration_id: integrationId,
                model_id: s.model_id,
                location_id: locationId,
                quantity: s.quantity,
                hostnames: s.hostnames,
                advanced: s.advanced,
            };
        });

        return createServer({
            body: {
                cluster: data.cluster,
                servers: transformedServers,
            },
        })
            .unwrap()
            .then((s) => {
                nav(shouldClose(navigateTo) || navigateTo);
            }, handleSubmitError(form.setError));
    };

    useEffect(() => {
        // if provider integration or location params not set, re-route to step 0
        // if integration doesn't exist/errors out, also reset to step 0
        // TODO - check integration category for provider here.
        if (!integrationId || !locationId || integrationError) {
            setServerParams({ step: "0" });
            return;
        }
    }, [integrationId, locationId]);

    return (
        <RhfFormProvider className="h-full" {...form}>
            {!step || step === "0" ? (
                <SelectProviderStep
                    locationId={locationId}
                    integrationId={integrationId}
                    locations={locations?.data}
                />
            ) : step === "1" && integration?.data && selectedLocation ? (
                <SelectServersStep
                    integration={integration.data}
                    location={selectedLocation}
                    onSubmit={onSubmit}
                />
            ) : null}
            {locationsError ? (
                <FieldErrorMessage
                    message={
                        isCycleApiError(locationsError)
                            ? locationsError.data.error.title
                            : "Error fetching locations."
                    }
                />
            ) : null}
        </RhfFormProvider>
    );
}
