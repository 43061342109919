// Use is strict dirty since there are cases where RHF will incorrectly return "isDirty"
// Even if no fields are actually dirty
// https://github.com/react-hook-form/react-hook-form/issues/3213#issuecomment-713844639

/**
 *
 * @param dirtyFields comes from form.formState.dirtyFields in a React Hook Form context
 * @returns A boolean indicating if form is dirty
 *
 * There are cases where RHF will incorrectly return "isDirty" even if no fields are actually dirty
 *
 * This function allows us to avoid this issue
 *
 * https://github.com/react-hook-form/react-hook-form/issues/3213#issuecomment-713844639
 */
export function isStrictDirty(dirtyFields: Record<string, unknown>) {
    return Object.keys(dirtyFields).length !== 0;
}
