import {
    RhfFormProvider,
    RhfGlobalFormError,
    Checkbox,
    FormFieldLabel,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
    SchedulerEnvironmentService,
    Environment,
    CreateSchedulerServiceJobApiArg,
    useCreateSchedulerServiceJobMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import classNames from "classnames";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";

import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";

type SchedulerSettingsPanelProps = {
    info: SchedulerEnvironmentService | null | undefined;
};

function getDefaultValues(info?: SchedulerEnvironmentService | null) {
    return {
        auto_update: info?.auto_update,
        config: info?.config || null,
    };
}

export function SchedulerSettingsPanel({ info }: SchedulerSettingsPanelProps) {
    const form = useForm<CreateSchedulerServiceJobApiArg["body"]["contents"]>({
        defaultValues: getDefaultValues(info),
        ...rhfConfig,
    });
    const { environment } = useContext(ContainerDialogContext);

    const {
        handleSubmit,
        reset,
        register,
        formState: { isSubmitting, isDirty },
    } = form;
    const [formError, setFormError] = useState<string>();
    const [updateConfig] = useCreateSchedulerServiceJobMutation();

    useKeepFormCurrent(form, info, (i) => getDefaultValues(i));

    const onSubmit = async (
        data: CreateSchedulerServiceJobApiArg["body"]["contents"]
    ) => {
        setFormError(undefined);

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => reset(data))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Settings" />
                <PanelContent>
                    <div className="mb-4 flex items-center justify-between">
                        <div
                            className={classNames(
                                "flex items-center gap-2  pb-2 text-sm font-semibold "
                            )}
                        >
                            <FormFieldLabel label="Auto Update" />

                            <HelpInfoCircle
                                message={
                                    "Scheduler will update automatically as new versions are released"
                                }
                                className="py-0 "
                            />
                        </div>

                        <Checkbox {...register("auto_update")} />
                    </div>

                    <div className="mb-4 flex items-center justify-between">
                        <div
                            className={classNames(
                                "flex items-center gap-2  pb-2 text-sm font-semibold "
                            )}
                        >
                            <FormFieldLabel label="Public" />

                            <HelpInfoCircle
                                message={"Scheduler is publicly exposed."}
                                className="py-0 "
                            />
                        </div>

                        <Checkbox {...register("config.public")} />
                    </div>

                    <PanelFooter>
                        <div>
                            <div>
                                {formError && (
                                    <p className="text-error pb-2">
                                        {formError}
                                    </p>
                                )}
                                <RhfGlobalFormError className="pb-2" />
                            </div>
                            <div className="flex w-full justify-end">
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={modifyAccessFn(
                                        "environments-services-manage"
                                    )}
                                >
                                    <PushAndHoldButton
                                        flavor="primary"
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        icon={faEdit}
                                        isLoading={isSubmitting}
                                        disabled={!isDirty}
                                    >
                                        Update Scheduler
                                    </PushAndHoldButton>
                                </AccessControlOverlay>
                            </div>
                        </div>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
