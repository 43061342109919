import {
    PillButtons,
    RhfFormField,
    TextInput,
    required,
    FormSection,
    FormSectionHeader,
    hasNoSpaces,
    TextAreaInput,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { StackCreateFormType } from "~/components/dialogs/stacks/form/CreateStackForm";

export function GitRepoForm() {
    const { register } = useFormContext<StackCreateFormType>();

    return (
        <>
            <FormSectionHeader header="Git" />
            <FormSection>
                <InputRow>
                    <RhfFormField
                        label="git repo url"
                        name="gitSource.url"
                        required
                    >
                        <TextInput
                            placeholder="https://github.com/repo.git"
                            {...register("gitSource.url", {
                                ...required(),
                                validate: { ...hasNoSpaces() },
                            })}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="git repo branch"
                        name="gitSource.branch"
                    >
                        <TextInput
                            {...register("gitSource.branch", {
                                shouldUnregister: true,
                                setValueAs: (s) => (s.length ? s : undefined),
                            })}
                        />
                    </RhfFormField>
                </InputRow>

                <RefFields />

                <RhfFormField
                    label="stack file"
                    name="gitSource.stack_file"
                    help="Specify which file in the repo contains the stack spec. By default, it is `cycle.json` in the root of the repo."
                >
                    <TextInput
                        placeholder="cycle.json"
                        {...register("gitSource.stack_file", {
                            setValueAs: (v) => (v === "" ? null : v),
                            validate: { ...hasNoSpaces() },
                        })}
                    />
                </RhfFormField>
            </FormSection>
            <GitRepoAuthSection />
        </>
    );
}
type RefType = "hash" | "tag" | "none";

function RefFields() {
    const { control, register, setValue } =
        useFormContext<StackCreateFormType>();

    const sourceType = useWatch({ name: "sourceType", control });
    const gitSource = useWatch({ name: "gitSource", control });
    const gitRepoSource = sourceType === "git-repo";

    const [refType, setRefType] = useState<string>(
        gitRepoSource ? gitSource?.ref?.type || "none" : "none"
    );

    const handleRefTypeChange = (rt: RefType) => {
        setRefType(rt);

        if (rt === "none") {
            setValue("gitSource.ref", undefined, {
                shouldDirty: true,
            });
        } else {
            setValue(
                "gitSource.ref",
                {
                    type: rt,
                    value: "",
                },
                { shouldDirty: true }
            );
        }
    };

    return (
        <div className="flex gap-4">
            <RhfFormField
                label="ref"
                name="gitSource.ref.type"
                className="pb-4"
            >
                <BasicSelect
                    placeholder="Select a Ref Type..."
                    value={refType}
                    onChange={(v) => handleRefTypeChange(v as RefType)}
                    options={[
                        {
                            value: "none",
                            label: "None",
                        },
                        {
                            value: "branch",
                            label: "Branch",
                        },
                        {
                            value: "hash",
                            label: "Hash",
                        },
                        {
                            value: "tag",
                            label: "Tag",
                        },
                    ]}
                />
            </RhfFormField>

            <RhfFormField
                label="ref value"
                className="w-3/5"
                name="gitSource.ref.value"
                required={refType !== "none"}
            >
                {refType !== "none" ? (
                    <TextInput
                        placeholder="Ref Value"
                        {...register("gitSource.ref.value", {
                            ...required(),
                            shouldUnregister: true,
                        })}
                    />
                ) : (
                    <TextInput placeholder="Ref Value" disabled value="" />
                )}
            </RhfFormField>
        </div>
    );
}
type AuthType = "http" | "ssh" | "none";

function GitRepoAuthSection() {
    const { control, register, setValue, unregister } =
        useFormContext<StackCreateFormType>();

    const sourceType = useWatch({ name: "sourceType", control });
    const gitSource = useWatch({ name: "gitSource", control });
    const gitRepoSource = sourceType === "git-repo";

    const [authType, setAuthType] = useState<"http" | "ssh" | "none">(
        gitRepoSource ? gitSource?.auth?.type || "none" : "none"
    );

    const handleAuthTypeChange = (t: AuthType) => {
        setAuthType(t);
        if (t === "none") {
            setValue("gitSource.auth.credentials.username", "", {
                shouldDirty: true,
            });
            unregister("gitSource.auth", { keepDirty: true });
        } else if (t === "http") {
            setValue(
                "gitSource.auth",
                {
                    type: "http",
                    credentials: {
                        username: "",
                        password: "",
                    },
                },
                { shouldDirty: true }
            );
        } else if (t === "ssh") {
            setValue(
                "gitSource.auth",
                {
                    type: "ssh",
                    credentials: {
                        username: "",
                        passphrase: "",
                        private_key: "",
                    },
                },
                { shouldDirty: true }
            );
        }
    };

    return (
        <>
            <FormSectionHeader header="Auth">
                <PillButtons
                    value={authType}
                    onChange={(v) => handleAuthTypeChange(v as AuthType)}
                    options={[
                        {
                            value: "http",
                            label: "HTTP",
                        },
                        {
                            value: "none",
                            label: "None",
                        },
                        {
                            value: "ssh",
                            label: "SSH",
                        },
                    ]}
                />
            </FormSectionHeader>
            {authType === "http" ? (
                <>
                    <InputRow>
                        <RhfFormField
                            label="username"
                            name="gitSource.auth.credentials.username"
                            required
                        >
                            <TextInput
                                {...register(
                                    "gitSource.auth.credentials.username",
                                    { ...required() }
                                )}
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="token"
                            name="gitSource.auth.credentials.password"
                            required
                        >
                            <TextInput
                                type="password"
                                {...register(
                                    "gitSource.auth.credentials.password",
                                    { ...required() }
                                )}
                            />
                        </RhfFormField>
                    </InputRow>
                </>
            ) : authType === "ssh" ? (
                <>
                    <InputRow>
                        <RhfFormField
                            label="username"
                            className="w-1/2"
                            name="gitSource.auth.credentials.username"
                            required
                        >
                            <TextInput
                                {...register(
                                    "gitSource.auth.credentials.username",
                                    { ...required() }
                                )}
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="passphrase"
                            className="w-1/2"
                            name="gitSource.auth.credentials.passphrase"
                        >
                            <TextInput
                                type="passphrase"
                                {...register(
                                    "gitSource.auth.credentials.passphrase"
                                )}
                            />
                        </RhfFormField>
                    </InputRow>
                    <RhfFormField
                        label="private key"
                        name="gitSource.auth.credentials.private_key"
                        required
                    >
                        <TextAreaInput
                            rows={3}
                            {...register(
                                "gitSource.auth.credentials.private_key",
                                { ...required() }
                            )}
                        />
                    </RhfFormField>
                </>
            ) : null}
        </>
    );
}
