import { isDialogOpen } from "~/components/dialogs/helpers";
import { MultiStepFormDialog } from "../../MultiStepFormDialog/MultiStepFormDialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { SetOrigin } from "./form/step1/SetOrigin";
import { SetHosted } from "./form/step2/SetHosted";
import { Configure } from "./form/step3/CompleteSetup";
import { MultiStepFormSteps } from "../../MultiStepFormDialog/components/MultStepFormSteps";
import { MultiStepFormStep } from "../../MultiStepFormDialog/components/MultiStepFormStep";
import { Review } from "./form/step4/Review";

export function DnsZoneDialog() {
    return (
        <MultiStepFormDialog
            title="New DNS Zone"
            isOpen={isDialogOpen("dns-zone-create")}
            icon={NavIcons["dnsZone"]}
        >
            <MultiStepFormSteps>
                <MultiStepFormStep name="Define Origin">
                    <SetOrigin />
                </MultiStepFormStep>
                <MultiStepFormStep name="Zone Type">
                    <SetHosted />
                </MultiStepFormStep>
                <MultiStepFormStep name="Configure">
                    <Configure />
                </MultiStepFormStep>
                <MultiStepFormStep name="Review">
                    <Review />
                </MultiStepFormStep>
            </MultiStepFormSteps>
        </MultiStepFormDialog>
    );
}
