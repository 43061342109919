import { useForm } from "react-hook-form";
import {
    CreateContainerApiArg,
    useCreateContainerMutation,
    useGetEnvironmentQuery,
} from "~/services/cycle";
import { General } from "./components/General";
import { ImageSection } from "./components/ImageSection";
import { Network } from "./components/Network";
import {
    RHF_GLOBAL_ERROR,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    generateDialogLink,
    getAllDialogSearchParams,
    shouldClose,
} from "../../helpers";
import { Deployments } from "./components/Deployments";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export type CreateContainerSubmitType = CreateContainerApiArg["body"] & {
    formProps: {
        sourceId: string;
        omittedVolumes: string[];
    };
};

export function CreateContainerForm({
    environmentId,
}: {
    environmentId: string | undefined;
}) {
    const params = getAllDialogSearchParams<"container-create">();
    const form = useForm<CreateContainerSubmitType>({
        defaultValues: {
            name: "",
            environment_id: environmentId,
            annotations: undefined,
            stateful: false,
            config: {
                network: {
                    public: "disable",
                    ports: [],
                    hostname: "",
                },
                deploy: {
                    instances: 1,
                    constraints: {
                        node: {
                            tags: {
                                any: [],
                                all: [],
                            },
                        },
                    },
                },
                integrations: {},
            },
            image_id: "",
            volumes: [],
            formProps: {
                omittedVolumes: [],
                sourceId: undefined,
            },
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createContainer] = useCreateContainerMutation();
    const nav = useNavigate();

    const { data: environment } = useGetEnvironmentQuery(
        { environmentId: environmentId || "" },
        { skip: !environmentId }
    );

    const onSubmit = async (data: CreateContainerSubmitType) => {
        if (!isDirty) {
            return;
        }
        // Removes Volumes marked as "omit" in omittedVolumes arr
        const volumesPreProcess = data.volumes?.filter((v) => {
            return !data.formProps.omittedVolumes.includes(v.destination);
        });

        return createContainer({
            body: {
                name: data.name,
                environment_id: data.environment_id,
                image_id: data.image_id,
                stateful: data.stateful,
                config: data.config,
                volumes: volumesPreProcess,
                annotations: data.annotations,
                deployment: data.deployment,
            },
        })
            .unwrap()
            .then(
                (c) =>
                    nav(
                        shouldClose(params["dialog-nav"]) ||
                            generateDialogLink("container", {
                                "dialog-container-id": c?.data?.id || "",
                            })
                    ),
                handleSubmitError(form.setError, {
                    sourceOverride: { deployment: "deployment.version" },
                })
            );
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <General environmentId={environmentId} />
                <ImageSection />
                <Network />
                <Deployments environmentId={environmentId} />
            </div>
            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <AccessControlOverlay
                    aclResource={environment?.data}
                    verifyFn={modifyAccessFn("containers-manage")}
                >
                    <LoaderButton
                        flavor="primary"
                        type="submit"
                        icon={faPlus}
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                    >
                        Deploy Container
                    </LoaderButton>
                </AccessControlOverlay>
            </DialogFooter>
        </RhfFormProvider>
    );
}
