import React from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { DialogBody, DialogContent, DialogHeader } from "./components";

type StyledFormDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
    /**
     * Whether or not to render a transparent overlay.
     *
     * @default true
     */
    hasBackdrop?: boolean;
    title?: React.ReactNode;
    description?: React.ReactNode;
    className?: string;
    icon?: IconDefinition;
    children: React.ReactNode;
};

export function StyledFormDialog({
    isOpen,
    onClose = () => null,
    title,
    className,
    children,
    icon,
}: StyledFormDialogProps) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className={classNames("relative z-50 ", className)}
        >
            <DialogBody onClose={onClose}>
                <DialogHeader icon={icon} title={title} />
                <DialogContent>
                    <div className="flex h-full">{children}</div>
                </DialogContent>
            </DialogBody>
        </Dialog>
    );
}
