import {
    Checkbox,
    EnableSectionInline,
    FormField,
    RhfDataSizeInput,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { useState } from "react";

export function RamReserveInput() {
    const { control, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.resources.ram.reserve") !== undefined
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField label="Enable Reserve">
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (!ev.target.checked) {
                                setValue(
                                    "config.resources.ram.reserve",
                                    undefined,
                                    { shouldDirty: true }
                                );
                            } else {
                                setValue("config.resources.ram.reserve", "1G", {
                                    shouldDirty: true,
                                });
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <RhfFormField
                    label="Reserve"
                    name="config.resources.ram.reserve"
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <RhfDataSizeInput {...field} />
                        )}
                        control={control}
                        name={"config.resources.ram.reserve"}
                    />
                </RhfFormField>
            ) : (
                <FormField label="Reserve">
                    <TextInput disabled />
                </FormField>
            )}
        </EnableSectionInline>
    );
}
