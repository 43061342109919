import {
    faChevronDown,
    faCircle,
    faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { ActivitySecurity } from "~/services/cycle";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";

type SecurityProps = {
    security: ActivitySecurity;
};

export function Security({ security }: SecurityProps) {
    const { refs, floatingStyles } = useFloating({
        placement: "bottom-end",
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    return (
        <div
            className={classNames("rounded-lg px-2 text-sm", {
                "border-warning-accent [&_svg]:text-warning-accent border":
                    security.risk === "medium",
                "border-warning [&_svg]:text-warning  border":
                    security.risk === "high",
                "border-error [&_svg]:text-error border":
                    security.risk === "critical",
                "border-cycle-blue [&_svg]:text-cycle-blue border":
                    security.risk === "info" ||
                    security.risk === "low" ||
                    security.event === "notice",
            })}
        >
            <Popover className="relative">
                <Popover.Button className="flex w-full items-center justify-between gap-2 py-1">
                    <FontAwesomeIcon icon={faWarning} className="ml-1" />{" "}
                    <div ref={refs.setReference}>Security Event</div>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Popover.Button>
                <Popover.Panel
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="dark:bg-cycle-gray dark:text-cycle-white animate-fade-in-fast a bg-cycle-white z-10 min-w-[25rem] rounded-lg stroke-1 p-4 shadow-lg"
                >
                    <p>
                        Cycle has detected a potential security threat{" "}
                        {security.event === "prevention" &&
                            "and has taken action to protect you"}
                        .
                    </p>
                    <div className="mt-2 grid grid-cols-2 gap-3 capitalize">
                        <div>
                            <span className="font-bold">Action: </span>{" "}
                            {security.event.replace("-", " ")}
                        </div>
                        <div className="flex items-center gap-1">
                            <span className="font-bold">Risk Level: </span>{" "}
                            <FontAwesomeIcon icon={faCircle} />{" "}
                            {security.risk.replace("-", " ")}
                        </div>
                        <div>
                            <span className="font-bold">Attack Surface: </span>{" "}
                            {security.surface.replace("-", " ")}
                        </div>
                        <div>
                            <span className="font-bold">Attack Type: </span>{" "}
                            {security.attack.replace("-", " ")}
                        </div>
                    </div>
                </Popover.Panel>
            </Popover>
        </div>
    );
}
