import {
    Checkbox,
    EnableSectionInline,
    FormField,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { useState } from "react";
import { Image } from "~/services/cycle";

export function OverrideWorkdirInput({ image }: { image?: Image }) {
    const { register, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.runtime.workdir") !== undefined
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField className="!w-1/4" label="Override">
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (!ev.target.checked) {
                                setValue("config.runtime.workdir", undefined, {
                                    shouldDirty: true,
                                });
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <>
                    <RhfFormField
                        label="workdir"
                        name="config.runtime.workdir"
                        required
                    >
                        <TextInput
                            placeholder={image?.config.workdir}
                            {...register("config.runtime.workdir", {
                                ...required(),
                            })}
                        />
                    </RhfFormField>
                </>
            ) : (
                <>
                    <FormField label="workdir">
                        <TextInput
                            placeholder={image?.config.workdir}
                            disabled
                        />
                    </FormField>
                </>
            )}
        </EnableSectionInline>
    );
}
