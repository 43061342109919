import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    required,
    RhfFormField,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
    cycleApi,
    useCreateImageJobMutation,
    useCreateImageMutation,
    useGetImageSourcesQuery,
} from "~/services/cycle";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useJobTracker } from "~/modules/jobs/hooks";
import { $info } from "@cycleplatform/core/util/log";
import { useAppDispatch } from "~/hooks";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { ImageSourceSelect } from "~/components/images/sources/ImageSourceSelect";
import { ImageSelect } from "~/components/images/ImageSelect";
import { CreateContainerSubmitType } from "../CreateContainerForm";
import { handleSubmitError } from "~/components/forms/util";

export const ChooseFromImageSource = () => {
    const dispatch = useAppDispatch();
    const {
        control,
        watch,
        setValue,
        setError,
        formState: { errors },
    } = useFormContext<CreateContainerSubmitType>();
    const [trackJob, { isTrackingJob }] = useJobTracker();

    const { data: sources, error } = useGetImageSourcesQuery({
        sort: ["-id"],
        meta: ["images_count"],
        page: {
            size: 100,
            number: 1,
        },
    });
    if (error) {
        throw error;
    }

    const sourceId = watch("formProps.sourceId");

    useEffect(() => {
        setValue("image_id", "");
    }, [sourceId]);

    // We do not have a notification when the image is successfully imported
    // This allows us to manually trigger an image cache invalidation when the
    // job is complete
    useEffect(() => {
        if (isTrackingJob === false) {
            dispatch(
                cycleApi.util.invalidateTags([
                    {
                        type: "Image",
                        id: "LIST",
                    },
                ])
            );
        }
    }, [isTrackingJob]);

    const [createImage] = useCreateImageMutation();
    const [importImage] = useCreateImageJobMutation();

    const onImportImage = () => {
        createImage({
            body: {
                source_id: sourceId || "",
            },
        })
            .unwrap()
            .then((i) => {
                trackJob(
                    importImage({
                        imageId: i?.data?.id || "",
                        body: {
                            action: "import",
                        },
                    }).unwrap()
                );
                setValue("image_id", i?.data?.id || "");
            })
            .then((d) => {
                $info("Imported", d);
            }, handleSubmitError(setError));
    };

    if (error) {
        throw error;
    }

    const isBucketSource = sourceId
        ? sources?.data?.find((s) => s.id === sourceId)?.type === "bucket"
        : null;

    return (
        <div>
            <RhfFormField
                className="w-1/2"
                label="Source"
                name="formProps.source_id"
                required
            >
                <Controller
                    rules={{ ...required() }}
                    control={control}
                    name="formProps.sourceId"
                    render={({ field: { ref: _ref, ...field } }) => (
                        <label>
                            <ImageSourceSelect
                                value={sourceId}
                                onChange={(id, source) => {
                                    field.onChange(id || "");
                                }}
                            />
                        </label>
                    )}
                />
            </RhfFormField>

            <RhfGlobalFormError />

            <div className="flex items-end gap-2">
                <RhfFormField
                    className="!mb-0 "
                    label="Image"
                    name="image_id"
                    required
                >
                    <Controller
                        control={control}
                        name="image_id"
                        rules={{ ...required() }}
                        render={({ field: { ref: _ref, ...field } }) => (
                            <ImageSelect
                                {...field}
                                filterFields={["id", "name"]}
                                skip={!sourceId}
                                disabled={!sourceId}
                                filter={{
                                    source_type: "direct",
                                    source_id: sourceId,
                                }}
                            />
                        )}
                    />
                </RhfFormField>

                {isBucketSource ? (
                    <Tooltip message="Bucket sources cannot be imported directly">
                        <Button type="button" disabled>
                            Import Image
                        </Button>
                    </Tooltip>
                ) : (
                    <LoaderButton
                        isLoading={isTrackingJob}
                        type="button"
                        flavor="primary"
                        disabled={!sourceId}
                        onClick={() => onImportImage()}
                        icon={faPlus}
                    >
                        Import Image
                    </LoaderButton>
                )}
            </div>
        </div>
    );
};
