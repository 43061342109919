import { Button } from "@cycleplatform/ui/components/buttons";
import { Image } from "~/services/cycle";
import { CommandOptions } from "./CommandOptions";
import { EnvironmentVariables } from "./environmentVariables";
import { SysctlSection } from "./SysctlSection";
import { RLimitSection } from "./RLimitSection";
import { RootFsSection } from "./RootFsSection.tsx";
import { SeccompSection } from "./SeccompSection";
import { HostSection } from "./HostSection";
import { PrivilegesSection } from "./PrivilegesSection";
import { DevicesOptions } from "./DevicesOptions";
import {
    ToggleFormSection,
    TogglePanelContent,
} from "@cycleplatform/react-hook-form-components/components";
import { useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { ContainerConfigFormData } from "../types";

type RuntimeSectionProps = {
    image?: Image;
};

export function RuntimeSection({ image }: RuntimeSectionProps) {
    const { setValue } = useFormContext();

    const initializeEnvironmentVariables = useCallback(() => {
        setValue("config.runtime.environment_vars", image?.config?.env || {});
    }, [image?.config?.env, setValue]);

    return (
        <TogglePanelContent
            title="Runtime"
            field="config.runtime"
            onToggle={(v) => {
                if (v) {
                    initializeEnvironmentVariables();
                }
            }}
            disabledFallback={(toggle) => (
                <div className="flex justify-center">
                    <div className="flex max-w-xl flex-col items-center gap-4 text-center">
                        <h4>Runtime Settings Disabled</h4>
                        <p>
                            Enable runtime settings to configure environment
                            variables, container command args, and advanced
                            container process privileges (seccomp, rootfs, etc.)
                        </p>
                        <Button flavor="primary" onClick={() => toggle()}>
                            Enable
                        </Button>
                    </div>
                </div>
            )}
        >
            {() => (
                <>
                    <EnvironmentVariables image={image} />
                    <ToggleFormSection<ContainerConfigFormData>
                        header="SYSCTL"
                        field="config.runtime.sysctl"
                    >
                        {() => <SysctlSection />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="RLIMITS"
                        field="config.runtime.rlimits"
                    >
                        {() => <RLimitSection />}
                    </ToggleFormSection>
                    <CommandOptions image={image} />
                    <DevicesOptions />

                    <RootFsSection />
                    <SeccompSection />
                    <HostSection />
                    <PrivilegesSection />
                </>
            )}
        </TogglePanelContent>
    );
}
