import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import Fuse from "fuse.js";
import {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
    KeyboardEvent as ReactKeyboardEvent,
    MouseEvent,
} from "react";
import { useDebounce } from "../../../hooks";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    size,
    useFloating,
    useInteractions,
    useRole,
} from "@floating-ui/react";

export type SelectInputProps<T> = {
    /** If set, will render a hidden input in the form with the value */
    name?: string;

    options?: T[];

    /** For use as an uncontrolled input */
    defaultValue?: T;

    /** For use as a controlled input */
    value?: T;

    onChange?: (value: T | null) => void;
    disabled?: boolean;
    isNullable?: boolean;
    hasError?: boolean;
    placeholder?: string;

    // async

    /**
     * If provided, will be called (with debounce) when the user enters a new search query.
     * The result will be merged with the provided options once the promise is resolved.
     */
    fetchOptions?: (search: string) => Promise<T[]>;
    /** An override to show the loading spinner if fetching data manually. */
    isLoading?: boolean;

    /** Formats the value shown in the input based on the option. Must be a string (as it's an input element.) */
    formatDisplayValue?: (option: T) => string;
    /** Formats the option in the option list. Can be any valid react component. */
    formatOption?: (option: T, disabled?: boolean) => React.ReactNode;

    /**
     * Any fields that should be considered when filtering
     */
    filterFields?: Array<keyof T>;

    /**
     * Provide this function to group options into categories.
     * The key is the category name (displayed as a separator), and the value
     * are the options in that group
     */
    groupOptions?: (options: T[]) => Record<string, T[]>;

    // creatable

    /** If true, the user can add new values to options[] */
    isCreatable?: boolean;
    /** A function that takes the string value from the input, and converts it into the new option */
    formatCreatableValue?: (v: string) => T;
    /** If provided, the placeholder that will be rendered on the create option */
    creatablePlaceholder?: (v: string) => string;
    /** If provided, will validate the option, and disable if not valid. */
    isOptionValid?: (v: T) => boolean;
    /** If provided, will validate the intended create value on keypress, and disable if not valid. */
    isCreateValueValid?: (v: string) => boolean;
    className?: string;
    optionsClassName?: string;
    /** If provided, will perform a function on query string change */
    onSearchQueryChanged?: (v: string) => void;
    /** Customizes "empty" options state message */
    emptyMessage?: string;
};

export function SelectInput<T>({
    name,
    options: initialOptions,
    defaultValue,
    value,
    onChange,
    disabled,
    isNullable,
    hasError: externalError,
    placeholder = "None",
    fetchOptions,
    isLoading: userIsLoading,
    formatDisplayValue = (v: T) => `${v}`,
    formatOption = (v: T) => `${v}`,
    filterFields,
    groupOptions = (opts) => ({ "": opts }),
    isCreatable,
    formatCreatableValue = (v) => v as T,
    creatablePlaceholder = (v) => `Create "${v}"`,
    isOptionValid = () => true,
    isCreateValueValid,
    className,
    optionsClassName,
    onSearchQueryChanged,
    emptyMessage,
}: SelectInputProps<T>) {
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce(query, 500);
    const [isLoading, setIsLoading] = useState(userIsLoading);
    const [options, setOptions] = useState(initialOptions || []);
    const [inputFocused, setInputFocused] = useState(false);
    const inputEl = useRef<HTMLInputElement>(null);
    const [fuse] = useState<Fuse<T>>(
        new Fuse(
            options,
            filterFields
                ? {
                      keys: filterFields as string[],
                  }
                : undefined
        )
    );

    const [internalError, setInternalError] = useState(false);
    const hasError = externalError || internalError;
    // Callback to fetch async options
    const handleFetchOptions = useCallback(
        async (query: string) => {
            if (!fetchOptions) {
                return [];
            }
            setIsLoading(true);
            const fetchedOpts = await fetchOptions(query);
            setIsLoading(false);

            return fetchedOpts;
        },
        [fetchOptions, setOptions, initialOptions, setIsLoading]
    );

    // Handle manual isLoading
    useEffect(() => {
        setIsLoading(userIsLoading);
    }, [userIsLoading]);

    // Handle query changed side effect
    useEffect(() => {
        onSearchQueryChanged?.(debouncedQuery);
    }, [debouncedQuery]);

    // Merge options with async results
    useEffect(() => {
        handleFetchOptions(debouncedQuery).then((asyncOpts) => {
            const io = initialOptions || [];
            const newOpts = [...io, ...asyncOpts];
            setOptions(newOpts);
            fuse.setCollection(newOpts);
        });
    }, [initialOptions, debouncedQuery]);

    // Update groupings when necessary - query change, options change, or group function changes
    const filterOptions = useCallback(
        (query: string) => {
            if (!query) {
                return groupOptions(options);
            }

            const filteredOptions = fuse.search(query).map((q) => q.item);
            return groupOptions(filteredOptions);
        },
        [options, groupOptions, fuse, debouncedQuery]
    );

    useEffect(() => {
        if (value === undefined && inputEl.current) {
            inputEl.current.value = "";
        }
    }, [value]);

    const { refs, context, floatingStyles } = useFloating({
        open: true,
        placement: "bottom-start",
        strategy: "fixed",
        middleware: [
            offset(10),
            shift(),
            flip(),
            size({
                apply({ elements }) {
                    // Do things with the data, e.g.
                    Object.assign(elements.floating.style, {
                        maxWidth: `${
                            elements.reference.getBoundingClientRect().width
                        }px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const role = useRole(context, { role: "menu" });
    const { getReferenceProps, getFloatingProps } = useInteractions([role]);

    const forceDefocus = () => {
        setInputFocused(false);
        setTimeout(() => {
            inputEl?.current?.blur();
        }, 100);
    };

    return (
        <Combobox
            name={name}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            nullable={isNullable as true}
            aria-invalid={hasError ? "true" : "false"}
        >
            {() => (
                <div
                    ref={refs.setReference}
                    {...getReferenceProps()}
                    className="relative"
                >
                    <Combobox.Input
                        ref={inputEl}
                        autoComplete={"off"}
                        onFocus={() => setInputFocused(true)}
                        spellCheck="false"
                        className={classNames(
                            className,
                            " flex min-h-[40px] w-full flex-wrap items-center bg-white text-base",
                            "disabled:bg-cycle-gray/10 disabled:cursor-not-allowed  ",
                            "focus-within:ring-cycle-blue focus-within:border-transparent focus-within:ring-2",
                            "text-cycle-gray peer rounded-md border border-inherit outline-none",
                            // dark
                            "dark:bg-cycle-gray-accent dark:text-cycle-white-accent dark:border-none",
                            // disabled dark
                            "dark:disabled:bg-black dark:disabled:text-opacity-50",
                            "placeholder-cycle-gray/70 dark:placeholder-cycle-white/50",
                            { "focus-within:ring-cycle-blue": !hasError },
                            {
                                "!ring-error border-transparent ring-2":
                                    !!hasError,
                            }
                        )}
                        onChange={(ev) => {
                            if (!inputFocused) {
                                setInputFocused(true);
                            }

                            setQuery(ev.target.value);
                        }}
                        onBlur={() => {
                            // We need to ensure that the onClick to select triggers before the blur
                            // If onBlur triggers first, the modal will close before val registers
                            setTimeout(() => {
                                setInputFocused(false);
                                setQuery("");
                            }, 100);
                        }}
                        onKeyDown={(ev: ReactKeyboardEvent) => {
                            if (ev.key === "Enter") {
                                if (!isCreatable) {
                                    if (
                                        // If filtered options is empty
                                        Object.values(
                                            filterOptions(query)
                                        ).flat().length
                                    ) {
                                        setInternalError(false);
                                    } else {
                                        onChange?.(null);
                                        setInternalError(true);
                                    }

                                    // have to blur so that onChange can trigger before input defo
                                    if (inputEl.current) {
                                        inputEl.current.blur();
                                    }

                                    return;
                                }
                                setInputFocused(false);
                                onChange?.(query as T);
                            }
                        }}
                        displayValue={formatDisplayValue}
                        placeholder={placeholder}
                    />
                    {isNullable && value ? (
                        <button
                            type="button"
                            className="absolute inset-y-0 right-6 flex items-center pr-2"
                            onClick={() => {
                                onChange?.(null);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={classNames("text-error h-4 w-4")}
                                aria-hidden="true"
                            />
                        </button>
                    ) : null}
                    <Combobox.Button
                        // disabled={disabled}
                        className="absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                        <FontAwesomeIcon
                            icon={isLoading ? faSpinner : faChevronDown}
                            spin={isLoading}
                            className={classNames(
                                isLoading
                                    ? "text-cycle-blue"
                                    : "text-cycle-gray",
                                "h-4 w-4"
                            )}
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                    <Transition
                        show={inputFocused}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Combobox.Options
                            static
                            className={classNames(
                                optionsClassName,
                                "z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                                "dark:bg-cycle-gray-accent "
                            )}
                            ref={refs.setFloating}
                            style={{ ...floatingStyles }}
                            {...getFloatingProps()}
                        >
                            <>
                                {isCreatable && query.length > 0 ? (
                                    <Combobox.Option
                                        // onClick does not properly register press and hold. Using mousedown ensures all
                                        // mouse actions are properly registered even if click and drag etc
                                        // solves an issue where clicks are not properly registering
                                        onMouseDown={(
                                            e: MouseEvent<HTMLLIElement>
                                        ) => {
                                            e.preventDefault();
                                            onChange?.(
                                                formatCreatableValue(query) as T
                                            );
                                            if (
                                                isCreateValueValid &&
                                                isCreateValueValid(query)
                                            ) {
                                                setInternalError(false);
                                            }
                                            if (inputFocused) {
                                                forceDefocus();
                                            }
                                        }}
                                        disabled={
                                            isCreateValueValid
                                                ? !isCreateValueValid(query)
                                                : false
                                        }
                                        className={({ active }) =>
                                            `relative min-h-[40px] cursor-default select-none py-2 px-4  text-base tracking-normal ${
                                                active
                                                    ? "bg-cycle-blue text-cycle-white"
                                                    : "text-cycle-gray-accent dark:text-cycle-gray-light"
                                            }`
                                        }
                                        value={formatCreatableValue(query)}
                                    >
                                        <div>{creatablePlaceholder(query)}</div>
                                    </Combobox.Option>
                                ) : null}

                                {!isCreatable &&
                                Object.values(filterOptions(query)).flat()
                                    ?.length === 0 ? (
                                    <Combobox.Option
                                        disabled
                                        value={null}
                                        className={() =>
                                            `text-cycle-gray-accent dark:text-cycle-gray-light relative min-h-[40px] cursor-default select-none py-2 px-4 text-base tracking-normal`
                                        }
                                    >
                                        {emptyMessage || "No options..."}
                                    </Combobox.Option>
                                ) : null}

                                {Object.entries(filterOptions(query)).map(
                                    ([group, options], idx) => {
                                        return (
                                            <Fragment key={`${group}-${idx}`}>
                                                {group !== "" && (
                                                    <div className="bg-cycle-gray-light dark:bg-cycle-black-accent p-2">
                                                        {group}
                                                    </div>
                                                )}
                                                {options.map((o) => (
                                                    <Combobox.Option
                                                        // onClick does not properly register press and hold. Using mousedown ensures all
                                                        // mouse actions are properly registered even if click and drag etc
                                                        // solves an issue where clicks are not properly registering
                                                        onMouseDown={(
                                                            e: MouseEvent<HTMLLIElement>
                                                        ) => {
                                                            e.preventDefault();
                                                            onChange?.(o);
                                                            setInternalError(
                                                                false
                                                            );

                                                            if (
                                                                inputEl.current
                                                            ) {
                                                                inputEl.current.value =
                                                                    formatDisplayValue(
                                                                        o
                                                                    );
                                                            }

                                                            if (inputFocused) {
                                                                forceDefocus();
                                                            }
                                                        }}
                                                        disabled={
                                                            isOptionValid
                                                                ? !isOptionValid(
                                                                      o
                                                                  )
                                                                : false
                                                        }
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative z-20 min-h-[40px] cursor-default  select-none   pl-4 pr-4 text-base tracking-normal ${
                                                                active
                                                                    ? "bg-cycle-blue text-cycle-white"
                                                                    : "text-cycle-gray-accent dark:text-cycle-white"
                                                            } ${
                                                                isOptionValid &&
                                                                !isOptionValid(
                                                                    o
                                                                )
                                                                    ? "bg-cycle-gray-light dark:bg-cycle-black-accent dark:text-cycle-gray-light/50"
                                                                    : ""
                                                            }`
                                                        }
                                                        key={JSON.stringify(o)}
                                                        value={o}
                                                    >
                                                        <span>
                                                            {formatOption(
                                                                o,
                                                                !isOptionValid?.(
                                                                    o
                                                                )
                                                            )}
                                                        </span>
                                                    </Combobox.Option>
                                                ))}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </>
                        </Combobox.Options>
                    </Transition>
                </div>
            )}
        </Combobox>
    );
}
