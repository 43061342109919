import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type EmptyResourceListItemProps = {
    resourceName: string;
    icon: IconDefinition;
};

/**
 * An individual resource item that goes in the 'ResourceList' component.
 */
export function EmptyResourceListItem({
    resourceName,
    icon,
}: EmptyResourceListItemProps) {
    return (
        <li
            className={classNames(
                "flex min-h-[4.5rem] w-full w-full rounded-lg rounded-r-none",
                "bg-cycle-white-accent dark:bg-cycle-black-accent"
            )}
        >
            <div className="inherit flex flex-1 items-center">
                <div className="inherit flex-1 p-4">
                    <div className={classNames("flex flex-1 items-center")}>
                        <FontAwesomeIcon
                            icon={icon}
                            className="text-cycle-blue pr-4"
                        />
                        <div className="capitalize">{`No ${resourceName}`}</div>
                    </div>
                </div>
            </div>
        </li>
    );
}
