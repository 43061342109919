import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import {
    Capability,
    CreateRoleApiArg,
    Role,
    useCreateRoleMutation,
    useGetHubMembershipQuery,
    useGetRolesQuery,
} from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useForm } from "react-hook-form";
import {
    FormField,
    FormSection,
    FormSectionHeader,
    InputRow,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    RhfSliderInput,
    TextInput,
    isIdentifier,
    required,
} from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { CapabilitiesFormSection } from "~/components/capabilities/CapabilitiesFormSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import classNames from "classnames";
import { NavIcons } from "~/components/layout/NavIcons";
import { Fragment, useEffect, useState } from "react";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
export function RoleCreateDialog() {
    const navigate = useNavigate();

    return (
        <StyledFormDialog
            title={"Create New Role"}
            isOpen={isDialogOpen("role-create")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <CreateRoleForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}

function CreateRoleForm() {
    const form = useForm<CreateRoleApiArg["body"]>({
        defaultValues: {
            rank: 0,
        },
        ...rhfConfig,
    });
    const { data: self, error } = useGetHubMembershipQuery({
        include: ["roles"],
    });

    const { data: roles, error: rolesError } = useGetRolesQuery({
        sort: ["-rank"],
    });

    const viewerRole = self?.includes?.roles?.[self?.data?.role_id || ""];

    const [warningMessage, setWarningMessage] = useState<React.ReactNode>(null);
    const [appliedTemplate, setAppliedTemplate] = useState("custom");

    const {
        handleSubmit,
        register,
        setValue,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createRole] = useCreateRoleMutation();
    const nav = useNavigate();

    const onSubmit = (data: CreateRoleApiArg["body"]) => {
        return createRole({
            body: data,
        })
            .unwrap()
            .then(() => nav(clearDialogParams()))
            .catch(handleSubmitError(form.setError));
    };

    useEffect(() => {
        const role = roles?.data?.find((r) => r.id === appliedTemplate);

        const filteredSpecific: Capability[] = [];
        const excludedCaps: Capability[] = [];

        role?.capabilities?.specific?.forEach((c) => {
            if (
                viewerRole?.capabilities.specific.includes(c) ||
                viewerRole?.capabilities?.all ||
                viewerRole?.root
            ) {
                filteredSpecific.push(c);
            } else {
                excludedCaps.push(c);
            }
        });

        setValue(
            "capabilities",
            role
                ? { ...role.capabilities, specific: filteredSpecific }
                : {
                      all: false,
                      specific: [],
                  },
            { shouldDirty: true }
        );

        if (excludedCaps.length) {
            setWarningMessage(
                <InfoPanel type="warning">
                    Applied template from role "
                    <p className="inline font-semibold">{role?.name}</p>" but
                    excluded capabilities{" "}
                    {excludedCaps.map((c, idx) => (
                        <Fragment key={c}>
                            {idx > 0 && ", "}
                            <p className="inline font-semibold">{c}</p>
                        </Fragment>
                    ))}{" "}
                    since you do not have access with your current role.
                </InfoPanel>
            );
        } else {
            setWarningMessage(null);
        }
    }, [appliedTemplate, roles?.data]);

    if (error) {
        throw error;
    }
    if (rolesError) {
        throw rolesError;
    }
    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <FormSectionHeader header="General" />
                <FormSection>
                    <RhfFormField label="name" name="name" required>
                        <TextInput {...register("name", { ...required() })} />
                    </RhfFormField>
                    <InputRow>
                        <RhfFormField
                            label="identifier"
                            name="identifier"
                            required
                        >
                            <TextInput
                                {...register("identifier", {
                                    ...required(),
                                    ...isIdentifier(),
                                })}
                            />
                        </RhfFormField>
                    </InputRow>

                    <FormField label="apply template">
                        <div className="grid grid-cols-3 gap-4">
                            {roles?.data?.map((r) => {
                                const isPrivilegedViewer =
                                    viewerRole?.root ||
                                    viewerRole?.capabilities?.all;

                                return (
                                    <button
                                        type="button"
                                        key={r.id}
                                        className={classNames(
                                            getOptionButtonClassName(
                                                appliedTemplate === r.id
                                            ),
                                            "disabled:bg-cycle-gray-light flex items-center justify-center gap-4 p-4 disabled:cursor-not-allowed disabled:ring-0 "
                                        )}
                                        onClick={() => setAppliedTemplate(r.id)}
                                        disabled={
                                            (r.root || r.capabilities.all) &&
                                            !isPrivilegedViewer
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={NavIcons["hubRoles"]}
                                            className="text-cycle-blue"
                                        />
                                        {r.name}
                                    </button>
                                );
                            })}
                        </div>
                    </FormField>
                </FormSection>

                {viewerRole?.rank ? (
                    <>
                        <FormSectionHeader
                            header="Rank"
                            help="A higher rank indicates a higher position in the organization"
                        />
                        <FormSection>
                            <RhfSliderInput
                                min={0}
                                max={viewerRole?.rank - 1}
                                numTicks={viewerRole?.rank - 1}
                                {...register("rank", {
                                    valueAsNumber: true,
                                })}
                            />
                        </FormSection>
                    </>
                ) : null}

                {warningMessage}

                <FormSectionHeader header="Capabilities" />
                {/* FormSection creates issues with margin, so using the classnames inline */}
                <div
                    className={classNames(
                        "form-section mb-4 border-l-4 py-2 pl-6",
                        "mb-4"
                    )}
                >
                    <CapabilitiesFormSection />
                </div>
            </div>

            <DialogFooter className="items-center justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>
                <PushAndHoldButton
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                    icon={faEdit}
                    onClick={handleSubmit(onSubmit)}
                >
                    Create Role
                </PushAndHoldButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
