import { useSearchParams } from "react-router-dom";

/**
 * Convenience method for constructing the page object used by the API.
 * Uses a url param ("page" by default) to store the page state.
 */
export function usePagination(size = 20, urlParam = "page") {
    const [searchParams] = useSearchParams();
    const page = searchParams.get(urlParam);
    let pageNum = page ? parseInt(page) : 1;

    if (isNaN(pageNum)) {
        pageNum = 1;
    }

    return { number: pageNum, size, urlParam };
}
