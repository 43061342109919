import { faSpinner, faTasks } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "~/hooks";
import {
    selectErrorCounter,
    selectRunningJobIds,
    selectSuccessCounter,
} from "~/modules/jobs/slice";
import {
    autoUpdate,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
} from "@floating-ui/react";
import { Job, useGetJobsQuery } from "~/services/cycle";
import { CreatorAvatar } from "@cycleplatform/ui/components/resources/creator/CreatorAvatar";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { extractCreatorDetails } from "@cycleplatform/core/modules/creators";
import { CreatorLink } from "~/components/creator/CreatorLink";
import { HumanizedTime } from "@cycleplatform/ui/components/time";
import { ResourceStateFull } from "@cycleplatform/ui/components/resources/state";
import {
    faCircle,
    faCircleCheck,
    faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
    getActiveStep,
    getActiveTask,
    getJobError,
    getJobPercentComplete,
} from "~/modules/jobs/utils";
import { Meter } from "@cycleplatform/ui/components/progress";
import { selectActiveHubId } from "~/modules/hubs/slice";
import { selectAppliedTheme } from "~/modules/settings";

export function JobsMenu() {
    const hubId = useAppSelector(selectActiveHubId);
    const theme = useAppSelector(selectAppliedTheme);

    const [jobCounts, setJobCounts] = useState<{
        success: number;
        error: number;
    }>({ success: 0, error: 0 });

    const [fullOpacity, setFullOpacity] = useState(true);
    const [ringColor, setRingColor] = useState("ring-cycle-blue");

    const runningJobIds = useAppSelector(selectRunningJobIds);
    const successCounter = useAppSelector(selectSuccessCounter);
    const errorCounter = useAppSelector(selectErrorCounter);

    // Controls temporary ring color changes on job success/error
    useEffect(() => {
        setJobCounts({ success: successCounter, error: errorCounter });
        if (errorCounter > jobCounts.error) {
            // we have an errored job.
            setRingColor("ring-error");
            setTimeout(() => {
                setRingColor("ring-cycle-blue");
            }, 1500);
            return;
        }

        if (successCounter > jobCounts.success) {
            // we have a success
            setRingColor("ring-success");
            setTimeout(() => {
                setRingColor("ring-cycle-blue");
            }, 1500);
            return;
        }
    }, [runningJobIds, successCounter, errorCounter, setJobCounts]);

    // Ring opacity in tailwind is controlled with a variable that's compiled
    // into the color and box shadow property. As far as I'm aware there's no way
    // to animate 'just' css variables with an animation, so we adjust it based on
    // class here.
    useEffect(() => {
        let i: NodeJS.Timer | undefined;
        if (runningJobIds.length > 0) {
            i = setInterval(() => {
                setFullOpacity((o) => !o);
            }, 500);
        } else {
            setFullOpacity(true);
            clearInterval(i);
        }

        return () => clearInterval(i);
    }, [runningJobIds.length]);

    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        placement: "right",
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpen,
        strategy: "fixed",
    });
    const hover = useHover(context);
    const focus = useFocus(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
    ]);

    const { data: recentJobs } = useGetJobsQuery(
        {
            page: {
                number: 1,
                size: 2,
            },
            sort: ["-id"],
            include: ["creators"],
        },
        { skip: !hubId }
    );

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                <NavLink to="/jobs">
                    <div
                        className={classNames(
                            fullOpacity
                                ? "ring-opacity-100"
                                : "ring-opacity-20",
                            ringColor,
                            // error && "!ring-error",
                            "bg-cycle-black text-cycle-white flex h-6 w-6 items-center justify-center rounded-full text-sm ring-2 transition duration-700"
                        )}
                    >
                        <span>
                            {runningJobIds.length > 0 ? (
                                runningJobIds.length
                            ) : (
                                <FontAwesomeIcon
                                    className="text-cycle-blue"
                                    icon={faTasks}
                                />
                            )}
                        </span>
                    </div>
                </NavLink>
            </div>
            {isOpen && recentJobs?.data?.length ? (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                    className="bg-cycle-gray-accent animate-fade-in-fast !text-cycle-gray-light z-40 my-8 ml-6 w-96 rounded-lg px-4 py-2 "
                >
                    <h2 className="!text-cycle-gray-light mt-2 text-lg">
                        Recent Jobs
                    </h2>

                    {recentJobs.data.map((j) => {
                        const details = extractCreatorDetails(
                            j.creator,
                            recentJobs.includes
                                ?.creators as components["schemas"]["CreatorInclude"]
                        );
                        const jobPercentComplete = getJobPercentComplete(j);
                        const task = getActiveTask(j);
                        const step = getActiveStep(task);
                        const error = getJobError(j);

                        return (
                            <div
                                key={j.id}
                                className="bg-cycle-black my-4 rounded-lg p-2"
                            >
                                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                                    <JobStateIcon state={j.state} /> {j.caption}
                                </div>
                                <Meter
                                    className="my-2 !h-2 !rounded-2xl"
                                    percent={jobPercentComplete}
                                    fillClassName={classNames(
                                        error && "bg-error"
                                    )}
                                />

                                {error && (
                                    <div className="text-error my-2 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-semibold">
                                        {error}
                                    </div>
                                )}

                                <div className="flex items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                                    <span>
                                        Created{" "}
                                        <HumanizedTime
                                            value={j.events.queued}
                                        />{" "}
                                        by{" "}
                                    </span>
                                    {recentJobs.includes?.creators ? (
                                        <>
                                            <CreatorAvatar
                                                className="scale-75"
                                                creator={j.creator}
                                                creatorInclude={
                                                    recentJobs.includes
                                                        .creators as components["schemas"]["CreatorInclude"]
                                                }
                                                theme={theme}
                                            />
                                            {details ? (
                                                <CreatorLink
                                                    creatorType={details}
                                                />
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
}

function JobStateIcon({ state }: { state: Job["state"] }) {
    if (state.error) {
        return (
            <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-error"
            />
        );
    }

    switch (state.current) {
        case "running":
            return (
                <FontAwesomeIcon
                    icon={faSpinner}
                    spinPulse
                    className="text-cycle-blue text-xs"
                />
            );
        case "completed":
            return (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-success text-xs"
                />
            );
        default:
            return (
                <FontAwesomeIcon
                    icon={faCircle}
                    className="text-cycle-blue text-xs"
                />
            );
    }
}
