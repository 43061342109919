import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../../util";
import {
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";

export function WebhookAuthFields() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();

    return (
        <div className="flex gap-4">
            <RhfFormField
                required
                help="This webhook will do a GET request to the specified URL, and expects back a base-64 encoded string that when decoded, is in the format 'username:token'"
                label="URL"
                name="origin.details.auth.details.url"
            >
                <TextInput
                    {...register("origin.details.auth.details.url", {
                        ...required(),
                        shouldUnregister: true,
                    })}
                />
            </RhfFormField>
        </div>
    );
}
