import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    CreateNetworkApiArg,
    Network,
    useCreateNetworkJobMutation,
    useCreateNetworkMutation,
    useUpdateNetworkMutation,
} from "~/services/cycle";
import { useForm, useWatch } from "react-hook-form";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    atLeastOneArrayEntry,
    maxLength,
    required,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import {
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import {
    clearDialogParams,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import { ClusterSelect } from "~/components/infrastructure/clusters/ClusterSelect";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
    getAclDefaultValues,
} from "~/components/common/acl";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type NetworkFormProps = {
    network?: Network;
};

type NetworkFormType = Omit<CreateNetworkApiArg["body"], "acl"> & {
    acl: AclForm;
};

export function NetworkForm({ network }: NetworkFormProps) {
    const params = getAllDialogSearchParams<"sdn-network-create">();
    const isEdit = !!network;

    const envIds = network?.environments?.map((e) => e.id);

    const form = useForm<NetworkFormType>({
        defaultValues: {
            name: network?.name || "",
            identifier: network?.identifier || "",
            environments: envIds || [],
            cluster: network?.cluster || "",
            acl: getAclDefaultValues(undefined),
        },
        ...rhfConfig,
    });

    const {
        register,
        control,
        watch,
        setValue,
        formState: { isDirty, isSubmitting },
    } = form;

    const cluster = watch("cluster");
    const nav = useNavigate();

    const [updateNetwork] = useUpdateNetworkMutation();
    const [createNetworkJob] = useCreateNetworkJobMutation();
    const [createNetwork] = useCreateNetworkMutation();

    const name = useWatch({ name: "name", control });

    const onSubmit = (data: NetworkFormType) => {
        if (!isDirty) {
            return;
        }
        if (isEdit) {
            updateNetwork({
                networkId: network?.id || "",
                body: {
                    name: data.name,
                },
            })
                .unwrap()
                .catch(handleSubmitError(form.setError));

            if (data.environments !== envIds)
                return createNetworkJob({
                    networkId: network?.id || "",
                    reconfigureSdnNetwork: {
                        action: "reconfigure",
                        contents: {
                            environment_ids: data.environments,
                        },
                    },
                })
                    .unwrap()
                    .then(
                        () => form.reset((formValues) => ({ ...formValues })),
                        handleSubmitError(form.setError)
                    );
            return;
        }

        const submitBody = {
            name: data.name,
            identifier: data.identifier,
            cluster: data.cluster,
            environments: data.environments,
            ...createAclSubmitBody(data.acl, "create"),
        };

        return createNetwork({
            body: submitBody,
        })
            .unwrap()
            .then((v) => {
                if (params["dialog-nav"] === "x") {
                    nav(clearDialogParams());
                    return;
                }
                nav({ pathname: `networks/${v?.data?.id}` });
            }, handleSubmitError(form.setError));
    };

    useEffect(() => {
        form.reset({
            name: network?.name || "",
            identifier: network?.identifier || "",
            environments: envIds || [],
            cluster: network?.cluster || "",
        });
    }, [network]);

    const [isIdentifierEdited, setIsIdentifierEdited] = useState(false);

    return (
        <RhfFormProvider
            {...form}
            onSubmit={
                !isEdit
                    ? form.handleSubmit(onSubmit)
                    : (e) => e.preventDefault()
            }
        >
            <div>
                <FormSectionHeader header="General" />
                <FormSection>
                    <RhfFormField required name="name" label="name">
                        <TextInput
                            value={name}
                            onChange={(e) => {
                                setValue("name", e.target.value, {
                                    shouldDirty: true,
                                });
                                if (!isEdit && !isIdentifierEdited) {
                                    setValue(
                                        "identifier",
                                        slugify(e.target.value, {
                                            lower: true,
                                            strict: true,
                                        }).slice(0, 6)
                                    );
                                }
                            }}
                        />
                    </RhfFormField>

                    <RhfFormField
                        name="identifier"
                        label="identifier"
                        required
                        help="Identifier is limited to 6 characters."
                    >
                        <TextInput
                            {...register("identifier", {
                                ...required(),
                                ...maxLength(6),
                                onBlur: (ev) => {
                                    setValue(
                                        "identifier",
                                        slugify(ev.target.value, {
                                            lower: true,
                                            strict: true,
                                        }).slice(0, 6)
                                    );
                                    setIsIdentifierEdited(true);
                                },
                                setValueAs: (v) =>
                                    slugify(v, {
                                        lower: true,
                                        strict: true,
                                    }),
                            })}
                            disabled={isEdit}
                        />
                    </RhfFormField>
                </FormSection>

                <FormSectionHeader header="Scope" />
                <FormSection>
                    {/* {!isEdit ? ( */}
                    <RhfFormField name="cluster" label="cluster" required>
                        <Controller
                            rules={{ ...required() }}
                            control={control}
                            name="cluster"
                            defaultValue=""
                            render={({ field: { ref: _ref, ...field } }) => (
                                <ClusterSelect {...field} />
                            )}
                        />
                    </RhfFormField>

                    <RhfFormField
                        name="environments"
                        label="environments"
                        required
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <EnvironmentSelect
                                    {...field}
                                    disabled={cluster === ""}
                                    filterResult={(envs) =>
                                        envs.filter(
                                            (env) => env.cluster === cluster
                                        )
                                    }
                                    multiple
                                    placeholder="Select Environment(s)"
                                />
                            )}
                            rules={{
                                validate: {
                                    ...atLeastOneArrayEntry(
                                        "Must select at least one environment"
                                    ),
                                },
                            }}
                            control={control}
                            name="environments"
                        />
                    </RhfFormField>
                </FormSection>

                {!isEdit ? <ResourceAclCreate resourceType="network" /> : null}
            </div>

            {isEdit ? (
                <PanelFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <AccessControlOverlay
                        aclResource={network}
                        verifyFn={modifyAccessFn("sdn-networks-manage")}
                    >
                        <PushAndHoldButton
                            flavor="primary"
                            icon={faEdit}
                            type="button"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={!isDirty}
                            isLoading={isSubmitting}
                        >
                            Update Network
                        </PushAndHoldButton>
                    </AccessControlOverlay>
                </PanelFooter>
            ) : (
                <DialogFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        flavor="primary"
                        icon={faEdit}
                        type="button"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        Create Network
                    </LoaderButton>
                </DialogFooter>
            )}
        </RhfFormProvider>
    );
}
