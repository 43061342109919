import {
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useCallback, useState } from "react";
import { decrypt } from "./util";
import { useFormContext, useWatch } from "react-hook-form";
import { ScopedVariableFormSubmitType } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/pro-solid-svg-icons";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";

type DecryptFormType = {
    secret_key: string;
    error?: string;
};

export function ScopedVariableDecryptForm() {
    const { setValue, control } =
        useFormContext<ScopedVariableFormSubmitType>();

    const source = useWatch({ name: "source", control });
    const value = useWatch({ name: "source.details.value", control });

    const [decryptForm, setDecryptForm] = useState<DecryptFormType>({
        secret_key: "",
        error: undefined,
    });

    const handleDecrypt = useCallback(() => {
        if (source.type === "raw") {
            const decryptedSource = decrypt(value, decryptForm.secret_key);

            if (decryptedSource) {
                setValue("source.details.value", decryptedSource, {
                    shouldDirty: true,
                });
                setValue("source.details.secret", {}, { shouldDirty: true });
            } else {
                setDecryptForm({
                    secret_key: "",
                    error: "Unable to decrypt with provided code",
                });
            }
        }
    }, [source.type, value, decryptForm.secret_key]);

    if (source.type !== "raw") {
        return null;
    }

    return (
        <>
            <div className="w-full">
                <div className="flex items-center gap-4 pb-4 text-lg">
                    <FontAwesomeIcon icon={faUnlock} /> Decrypt Value
                </div>
                {source.details.secret?.hint ? (
                    <div className="flex items-center gap-1">
                        Hint{" "}
                        <div>
                            <HelpInfoCircle
                                message={source.details.secret?.hint}
                            />
                        </div>
                    </div>
                ) : null}
                <div className="flex w-full items-center gap-4">
                    <div className="grow">
                        <FormField label="Current Key" className="w-full">
                            <TextInput
                                autoComplete="off"
                                spellCheck={false}
                                type="password"
                                className="w-full border-gray-200"
                                value={decryptForm.secret_key}
                                onChange={(e) =>
                                    setDecryptForm({
                                        secret_key: e.target.value,
                                    })
                                }
                            />
                        </FormField>
                    </div>
                    <div className="mt-4">
                        <Button
                            flavor="primary"
                            type="button"
                            onClick={() => handleDecrypt()}
                            disabled={decryptForm.secret_key === ""}
                        >
                            Decrypt
                        </Button>
                    </div>
                </div>
                {decryptForm.error ? (
                    <p className="text-error">{decryptForm.error}</p>
                ) : null}
            </div>
        </>
    );
}
