import { useFormContext } from "react-hook-form";
import {
    ServerInfo,
    ServerPrice,
    ServerTitle,
} from "@cycleplatform/ui/components/resources";
import classNames from "classnames";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { DeployableInfraFormType } from "../form";
import { Integration, ProviderServerModel } from "~/services/cycle";

export function ModelTile({
    s,
    provider,
}: {
    s: ProviderServerModel;
    provider?: Integration;
}) {
    const { watch, setValue } = useFormContext<DeployableInfraFormType>();

    const selectedModel = watch("model");

    return (
        <li key={s.id}>
            <button
                key={s.id}
                type="button"
                onClick={() => setValue("model", s)}
                className="w-full"
            >
                <ModelContent
                    className={classNames(
                        getOptionButtonClassName(
                            selectedModel && selectedModel.id === s.id
                        )
                    )}
                    model={s}
                    providerName={provider?.name || provider?.vendor || ""}
                />
            </button>
        </li>
    );
}

export function ModelContent({
    providerName,
    model,
    className,
}: {
    providerName: string;
    model: ProviderServerModel;
    className?: string;
}) {
    return (
        <div className={classNames("flex items-center p-4", className)}>
            <div className="grid flex-1 grid-cols-4 items-center gap-8 ">
                <ServerTitle providerServer={model} />

                <ServerInfo providerServer={model} />

                <ServerPrice
                    providerServer={model}
                    providerName={providerName}
                />
            </div>
        </div>
    );
}
