import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext, useMemo } from "react";
import { formatLoadBalancerInstanceName } from "@cycleplatform/core/modules/environments/loadbalancer/format";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import {
    DateTimeFormats,
    formatBytes,
    formatDateString,
} from "@cycleplatform/core/util";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { getAbbreviatedNumberString } from "@cycleplatform/core/util/number";
import {
    Instance,
    InstanceIncludes,
    useGenerateAggregatedMetricsQuery,
    useGetInstancesQuery,
} from "~/services/cycle";
import { LbTrafficQueryTelem, getLbTrafficQuery } from "./query";
import {
    pieCenterTextProps,
    pieProps,
} from "@cycleplatform/ui/components/recharts/pie/props";
import { CyclePieTooltip } from "@cycleplatform/ui/components/recharts/pie/CyclePieTooltip";
import { LoadBalancerDialogContext } from "../../../../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { ThemedColors, useGetThemedChartColors } from "~/util/charts/hooks";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export function OverviewSection({ port }: { port: number }) {
    const { environment, lbContainer } = useContext(LoadBalancerDialogContext);
    const { data: metrics, isLoading } = useGenerateAggregatedMetricsQuery(
        getLbTrafficQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const telemetry = metrics?.data as LbTrafficQueryTelem | undefined;
    const instanceMetrics = telemetry?.[0]?.instances;

    const { data: instances, isLoading: instancesLoading } =
        useGetInstancesQuery(
            {
                containerId: lbContainer?.id || "",
                include: ["locations"],
            },
            { skip: !lbContainer?.id, pollingInterval: AGGREGATE_POLLING_MS }
        );

    // Reduce lookups for instance pairing with telemetry
    const instancesByInstanceId = useMemo(
        () =>
            instances?.data.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {} as Record<string, Instance>) || {},
        [instances]
    );

    return (
        <Panel>
            <div className="relative w-full">
                <PanelTitle
                    title="Load Balancers"
                    className="flex items-center justify-between"
                >
                    <div className="text-sm">
                        Last Updated:{" "}
                        {formatDateString(
                            telemetry?.[0]?.time || "",
                            DateTimeFormats["standard"]
                        )}
                    </div>
                </PanelTitle>
            </div>
            <PanelContent stretch>
                {isLoading || instancesLoading || !telemetry ? (
                    <SkeletonTable />
                ) : (
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledHeaderCell className="w-[40%]">
                                Instance
                            </StyledHeaderCell>

                            <StyledHeaderCell className="min-w-[10rem]">
                                Data Transfer
                            </StyledHeaderCell>

                            <StyledHeaderCell className="w-1/8 text-center">
                                Connections
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-1/8 text-center">
                                Requests
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-[10rem] text-center">
                                Disconnects
                            </StyledHeaderCell>
                        </StyledTableHead>
                        <tbody>
                            {instanceMetrics ? (
                                instanceMetrics.map((metric) => (
                                    <InstanceRow
                                        instanceMetric={metric}
                                        instance={
                                            instancesByInstanceId[
                                                metric.instance_id
                                            ]
                                        }
                                        instanceIncludes={instances?.includes}
                                    />
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            )}
                        </tbody>
                    </StyledDataTable>
                )}
            </PanelContent>
        </Panel>
    );
}

function InstanceRow({
    instanceMetric,
    instanceIncludes,
    instance,
}: {
    instance?: Instance;
    instanceIncludes?: InstanceIncludes;
    instanceMetric: LbTrafficQueryTelem[number]["instances"][number];
}) {
    const metricsMap = useMemo(() => {
        return instanceMetric?.metrics?.reduce((acc, cur) => {
            if (!cur.metric || !cur.metric.includes("lb.controller")) {
                return { ...acc };
            }

            return {
                ...acc,
                [cur.metric.split("lb.controller.")[1] || ""]: cur?.value,
            };
        }, {} as Record<string, number>);
    }, [instanceMetric]);

    const location = instanceIncludes?.locations?.[instance?.location_id || ""];

    return (
        <StyledTableRow key={instanceMetric.instance_id}>
            {" "}
            <StyledCell>
                <div>
                    <Link
                        to={generateDialogLink("container", {
                            "dialog-container-id": instance?.container_id,
                            "dialog-instance-id": instance?.id,
                            "dialog-tab": "instances",
                        })}
                    >
                        {formatLoadBalancerInstanceName(
                            instance?.id || "",
                            instance?.provider?.vendor || "",
                            location?.name || ""
                        )}
                    </Link>
                </div>
            </StyledCell>
            <StyledCell>
                {" "}
                <div className="text-sm">
                    {`${
                        formatBytes(metricsMap.received_kb || 0 * 1024).value
                    } ${
                        formatBytes(metricsMap.received_kb || 0 * 1024).suffix
                    } Received`}
                </div>
                <div className="text-sm">
                    {`${
                        formatBytes(metricsMap.transmitted_kb || 0 * 1024).value
                    } ${
                        formatBytes(metricsMap.transmitted_kb || 0 * 1024)
                            .suffix
                    }  Transmitted`}
                </div>
            </StyledCell>
            <StyledCell className="text-center">
                {getAbbreviatedNumberString(metricsMap.connections)}
            </StyledCell>
            <StyledCell className="text-center">
                {getAbbreviatedNumberString(metricsMap.requests)}
            </StyledCell>
            <StyledCell className="flex justify-center">
                <div className="m-2 flex h-[4rem] w-[4rem]">
                    <DisconnectsChart
                        instanceMetric={instanceMetric}
                        instanceId={instance?.id}
                    />
                </div>
            </StyledCell>
        </StyledTableRow>
    );
}

function DisconnectsChart({
    instanceMetric,
    instanceId,
}: {
    instanceMetric: LbTrafficQueryTelem[number]["instances"][number];
    instanceId?: string;
}) {
    const { colors } = useGetThemedChartColors();

    const chartData = instanceMetric?.metrics.reduce((acc, cur) => {
        if (
            !cur.metric ||
            !cur.metric.includes("disconnects.") ||
            cur.metric.includes("total")
        ) {
            return [...acc];
        }

        const metricName = cur.metric.split("disconnects.")?.[1] || "?";

        return [
            ...acc,
            {
                name: metricName,
                value: cur.value,
                color: getColor(metricName, colors),
            },
        ] as {
            name: string;
            value: number;
            color: string;
        }[];
    }, [] as { name: string; value: number; color: string }[]);

    const total = instanceMetric.metrics.find((m) =>
        m.metric.includes("disconnects.total")
    )?.value;

    if (!chartData) {
        return null;
    }
    return (
        <div className="flex w-full">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart margin={{ top: 1, bottom: 1 }}>
                    <Pie {...pieProps} data={chartData} dataKey="value">
                        {chartData.map((entry, idx) => {
                            return (
                                <>
                                    <Cell
                                        className="cursor-pointer"
                                        key={`cell-${instanceId}-${idx}`}
                                        fill={entry.color}
                                        stroke="rgba(0,0,0,0)"
                                    />
                                </>
                            );
                        })}
                    </Pie>

                    <text {...pieCenterTextProps}>
                        {total ? (
                            <>{getAbbreviatedNumberString(total)}</>
                        ) : null}
                    </text>
                    <Tooltip
                        reverseDirection={{ x: true, y: true }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        offset={-5}
                        content={(props) => (
                            <CyclePieTooltip {...props} dataLabel="name" />
                        )}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

function getColor(entry: string, colors: ThemedColors) {
    switch (entry) {
        case "no_error":
            return colors["green"].DEFAULT;
        case "destination_unavailable":
            return colors["red"].DEFAULT;
        case "request_invalid":
            return colors["red"].light;
        case "connection_invalid":
            return colors["orange"].DEFAULT;
        case "router_nomatch":
            return colors["orange"].light;
        case "unknown_error":
            return colors["red"].dark;
        case "timeout_idle":
            return colors["gray"].DEFAULT;
        case "timeout_init":
            return colors["yellow"].DEFAULT;
        default:
            return colors["orange"].light;
    }
}
