import { useContext } from "react";
import { InstanceTab } from "~/components/dialogs/containers/container/instances/InstanceTab";
import { Container } from "~/services/cycle";
import { LoadBalancerDialogContext } from "../context";

type InstancesTabProps = {
    container: Container | undefined;
    containerId: string;
    instanceId: string;
};

export function InstancesTab({ ...props }: InstancesTabProps) {
    const { environment } = useContext(LoadBalancerDialogContext);
    return (
        <InstanceTab
            {...props}
            parent="environment-lb-manage"
            environment={environment}
        />
    );
}
