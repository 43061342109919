import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { PLAN_TILE_CLASSNAME } from "@cycleplatform/ui/components/resources";
import { useFormContext } from "react-hook-form";
import { UpdateEnvironmentApiArg } from "~/services/cycle";
import classNames from "classnames";
import {
    MonitoringTierDurationDetails,
    MonitoringTierFeatures,
} from "~/components/environments/monitoring/FormattedDetails";
import { capitalizeEachWord } from "@cycleplatform/core/util";
import { FormattedMoney } from "@cycleplatform/ui/components/money";
import { Tier } from "./UpdateMonitoringTier";

export function MonitoringTierButton({
    tier,
    selectedTier,
}: {
    tier: Tier;
    selectedTier: string;
}) {
    const form = useFormContext<UpdateEnvironmentApiArg["body"]>();
    const { setValue } = form;

    return (
        <button
            type="button"
            className={classNames(
                getOptionButtonClassName(selectedTier === tier.identifier),
                "p-4",
                PLAN_TILE_CLASSNAME
            )}
            onClick={() =>
                setValue("monitoring.tier", tier.identifier, {
                    shouldDirty: true,
                })
            }
        >
            <div
                className={classNames(
                    "@container",
                    "w-full",
                    "flex h-full flex-col"
                )}
            >
                <h2
                    className={classNames(
                        "@[22rem]:text-2xl @[16rem]:text-xl text-large",
                        "flex items-center justify-between pb-2 font-semibold"
                    )}
                >
                    {capitalizeEachWord(tier.identifier)}
                </h2>

                <div className="relative py-4">
                    <div className="flex items-center">
                        <FormattedMoney
                            value={tier.cost_mills}
                            mills
                            className={classNames(
                                "pr-4 text-base",
                                "!text-2xl font-bold"
                            )}
                        />
                        /month
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <MonitoringTierFeatures tier={tier} />
                    <MonitoringTierDurationDetails tier={tier} />
                </div>
            </div>
        </button>
    );
}
