import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export type LbUrlQueryTelem = {
    cache_hits: number;
    cache_ms_avg: number | null;
    forward_hits: number;
    forward_ms_avg: number | null;
    host: string;
    method: string;
    path: string;
    proxy_hits: number;
    proxy_ms_avg: number | null;
    redirect_hits: number;
    redirect_ms_avg: number | null;
    time: string;
    responses: LbUrlResponse[];
}[];

export type LbUrlResponse = { response: string; value: number };

export function getLbUrlsQuery(
    environmentId: string | undefined,
    port: number | undefined
) {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subDays(new Date(), 1), {
                    roundingMethod: "floor",
                })
            ),
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "lb.controller.requests.url.responses",
                        "lb.controller.requests.url.handlers.forward.hits",
                        "lb.controller.requests.url.handlers.proxy.hits",
                        "lb.controller.requests.url.handlers.redirect.hits",
                        "lb.controller.requests.url.handlers.cache.hits",
                        "lb.controller.requests.url.handlers.forward.timing_ms",
                        "lb.controller.requests.url.handlers.proxy.timing_ms",
                        "lb.controller.requests.url.handlers.redirect.timing_ms",
                        "lb.controller.requests.url.handlers.cache.timing_ms",
                    ],
                },
                "labels.environment:id": environmentId,
                "labels.port": `${port}`,
            },
            pipeline: [
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $group: {
                        _id: {
                            host: "$labels.request:host",
                            path: "$labels.request:path",
                            method: "$labels.request:method",
                            response: "$labels.response",
                            metric: "$metadata.metric",
                        },
                        time: {
                            $last: "$time",
                        },
                        responses: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.responses",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        forward_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.forward.hits",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        proxy_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.proxy.hits",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        redirect_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.redirect.hits",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        cache_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.cache.hits",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        forward_ms: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.forward.timing_ms",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        proxy_ms: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.proxy.timing_ms",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        redirect_ms: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.redirect.timing_ms",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                        cache_ms: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.requests.url.handlers.cache.timing_ms",
                                        ],
                                    },
                                    then: {
                                        $arrayElemAt: [
                                            {
                                                $arrayElemAt: ["$points", 0],
                                            },
                                            1,
                                        ],
                                    },
                                    else: null,
                                },
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            host: "$_id.host",
                            path: "$_id.path",
                            method: "$_id.method",
                        },
                        host: {
                            $first: "$_id.host",
                        },
                        path: {
                            $first: "$_id.path",
                        },
                        method: {
                            $first: "$_id.method",
                        },
                        time: {
                            $last: "$time",
                        },
                        responses: {
                            $push: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.responses",
                                        ],
                                    },
                                    then: {
                                        response: "$_id.response",
                                        value: "$responses",
                                    },
                                    else: null,
                                },
                            },
                        },
                        forward_hits: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.forward.hits",
                                        ],
                                    },
                                    then: "$forward_hits",
                                    else: 0,
                                },
                            },
                        },
                        proxy_hits: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.proxy.hits",
                                        ],
                                    },
                                    then: "$proxy_hits",
                                    else: 0,
                                },
                            },
                        },
                        redirect_hits: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.redirect.hits",
                                        ],
                                    },
                                    then: "$redirect_hits",
                                    else: 0,
                                },
                            },
                        },
                        cache_hits: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.cache.hits",
                                        ],
                                    },
                                    then: "$cache_hits",
                                    else: 0,
                                },
                            },
                        },
                        forward_ms: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.forward.timing_ms",
                                        ],
                                    },
                                    then: "$forward_ms",
                                    else: null,
                                },
                            },
                        },
                        proxy_ms: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.proxy.timing_ms",
                                        ],
                                    },
                                    then: "$proxy_ms_avg",
                                    else: null,
                                },
                            },
                        },
                        redirect_ms: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.redirect.timing_ms",
                                        ],
                                    },
                                    then: "$redirect_ms_avg",
                                    else: null,
                                },
                            },
                        },
                        cache_ms: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$_id.metric",
                                            "lb.controller.requests.url.handlers.cache.timing_ms",
                                        ],
                                    },
                                    then: "$cache_ms_avg",
                                    else: null,
                                },
                            },
                        },
                    },
                },
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $addFields: {
                        responses: {
                            $filter: {
                                input: "$responses",
                                as: "response",
                                cond: {
                                    $ne: ["$$response", null],
                                },
                            },
                        },
                    },
                },
                {
                    $project: {
                        _id: 0,
                        host: "$host",
                        path: "$path",
                        method: "$method",
                        responses: "$responses",
                        forward_hits: "$forward_hits",
                        proxy_hits: "$proxy_hits",
                        redirect_hits: "$redirect_hits",
                        cache_hits: "$cache_hits",
                        time: "$time",
                        forward_ms_avg: {
                            $cond: {
                                if: {
                                    $gt: ["$forward_hits", 0],
                                },
                                then: {
                                    $divide: ["$forward_ms", "$forward_hits"],
                                },
                                else: null,
                            },
                        },
                        proxy_ms_avg: {
                            $cond: {
                                if: {
                                    $gt: ["$proxy_hits", 0],
                                },
                                then: {
                                    $divide: ["$proxy_ms", "$proxy_hits"],
                                },
                                else: null,
                            },
                        },
                        redirect_ms_avg: {
                            $cond: {
                                if: {
                                    $gt: ["$redirect_hits", 0],
                                },
                                then: {
                                    $divide: ["$redirect_ms", "$redirect_hits"],
                                },
                                else: null,
                            },
                        },
                        cache_ms_avg: {
                            $cond: {
                                if: {
                                    $gt: ["$cache_hits", 0],
                                },
                                then: {
                                    $divide: ["$cache_ms", "$cache_hits"],
                                },
                                else: null,
                            },
                        },
                    },
                },
            ],
        },
    };
}
