import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faBadgeCheck,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, DnsZone } from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatDnsZoneMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractZoneName(item.component?.id || "", components);
    const link = <Link to={`/dns/zones/${item.component?.id}`}>{name}</Link>;
    switch (item.event) {
        case "dns.zone.task.verify":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested verification of DNS zone {link}</span>
                ) : (
                    <span>
                        Error requesting verification of DNS zone {link}{" "}
                    </span>
                ),
                icon: faBadgeCheck,
            };
        case "dns.zone.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of DNS zone {link}</span>
                ) : (
                    <span>Error requesting deletion of DNS zone {link}</span>
                ),
                icon: faTrash,
            };
        case "dns.zone.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated DNS zone {link}</span>
                ) : (
                    <span>Error updating DNS zone {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "dns.zone.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created DNS zone {link}</span>
                ) : (
                    <span>Error creating DNS zone</span>
                ),
                icon: faPlus,
            };
        case "dns.zone.verify":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Verified DNS zone {link}</span>
                ) : (
                    <span>Error verifying DNS zone {link} </span>
                ),
                icon: faCheckCircle,
            };

        case "dns.zone.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted DNS zone {link}</span>
                ) : (
                    <span>Error deleting DNS zone {link} </span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractZoneName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as DnsZone).origin;
}
