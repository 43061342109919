import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

type EmptyActionPanelProps = {
    title: string;
    className?: string;
    iconClassName?: string;
    details?: React.ReactNode;
    icon: string | IconDefinition;
    children?: React.ReactNode;
};

export function EmptyActionPanel({
    title,
    details,
    className,
    icon,
    iconClassName,
    children,
}: EmptyActionPanelProps) {
    return (
        <section
            className={classNames(
                className,
                "flex w-full min-w-0 bg-transparent",
                "dark:bg-cycle-black-accent",
                "flex-col overflow-y-auto p-4"
            )}
        >
            <div className="mt-12 flex flex-col items-center justify-center">
                <div className="flex justify-center text-center">
                    <h1 className="text-4xl font-extrabold">{title}</h1>
                </div>

                <p className="max-w-[50%] text-center text-xl">{details}</p>

                <EmptyActionIcon icon={icon} className={iconClassName} />

                {children}
            </div>
        </section>
    );
}

export function EmptyActionIcon({
    icon,
    className,
}: {
    icon: string | IconDefinition;
    className?: string;
}) {
    if (typeof icon === "string") {
        return <img src={icon} className="my-10 w-96 max-w-xl" />;
    }
    return (
        <FontAwesomeIcon
            icon={icon}
            className={classNames(
                "text-cycle-blue  min-h-48  my-10  ",
                className
            )}
        />
    );
}
