import { RhfFormField, required } from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useAppSelector } from "~/hooks";
import {
    Integration,
    ProviderLocation,
    ProviderServerModel,
} from "~/services/cycle";
import { ScheduledServerTile } from "./ScheduledServerTile";
import { CreateServerSchema } from "../../../form";
import { IntegrationLogo } from "@cycleplatform/ui/components/resources";
import { selectAppliedTheme } from "~/modules/settings";
import { ClusterSelect } from "~/components/infrastructure/clusters/ClusterSelect";

type OrderSummaryProps = {
    providerServers?: ProviderServerModel[];
    location: ProviderLocation;
    integration: Integration;
};

export function OrderSummary({
    providerServers,
    location,
    integration,
}: OrderSummaryProps) {
    const theme = useAppSelector(selectAppliedTheme);
    const { control } = useFormContext<CreateServerSchema>();
    const servers = useWatch({
        name: "servers",
        control,
    });

    return (
        <Panel className="pb-4">
            <PanelTitle title="Order Summary" />
            <PanelContent>
                <div>
                    <div className="flex flex-col items-center">
                        <IntegrationLogo
                            vendor={integration.vendor}
                            theme={theme}
                            className="mb-4"
                        />
                        <div className="pb-4 text-center font-bold">
                            <span>{integration.name}</span>{" "}
                            <span>{location?.name || "Unknown Location"}</span>
                        </div>
                    </div>

                    {servers && Object.keys(servers).length ? (
                        <>
                            <div className="mb-4 border-b">
                                <RhfFormField
                                    label="cluster"
                                    name="cluster"
                                    required
                                >
                                    <Controller
                                        render={({
                                            field: { ref: _ref, ...field },
                                        }) => <ClusterSelect {...field} />}
                                        rules={{ ...required() }}
                                        control={control}
                                        name="cluster"
                                    />
                                </RhfFormField>
                            </div>
                            <div className="flex flex-col gap-2">
                                {Object.values(servers)?.map((s) => {
                                    const feats =
                                        integration.meta?.definition
                                            ?.features || [];

                                    const extendedConfig =
                                        integration.meta?.definition
                                            ?.extended_configuration?.options ||
                                        [];

                                    return (
                                        <ScheduledServerTile
                                            key={s.model_id}
                                            serverModels={providerServers}
                                            server={s}
                                            advFeatures={feats}
                                            extendedConfig={extendedConfig}
                                            location={
                                                location?.provider?.location
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="px-4 text-center">
                            {`Begin by selecting servers from the left.`}
                        </div>
                    )}
                </div>
            </PanelContent>
        </Panel>
    );
}
