import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { SkeletonFallback } from "@cycleplatform/ui/components/loaders/skeleton";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import {
    Container,
    Instance,
    Ip,
    useGetContainersQuery,
    useGetEnvironmentSummaryQuery,
    useGetInstancesQuery,
} from "~/services/cycle";
import { doesLbHaveIps } from "@cycleplatform/core/modules/environments/loadbalancer";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

type LoadBalancerInstancesProps = {
    loadbalancer?: Container;
    isLoading?: boolean;
};
export function LoadBalancerInstances({
    loadbalancer,
    isLoading,
}: LoadBalancerInstancesProps) {
    const lbId = loadbalancer?.id;
    const lbNoIps = !doesLbHaveIps(loadbalancer);
    const { currentData: lbInstances, error } = useGetInstancesQuery(
        {
            containerId: lbId || "",
            page: {
                number: 1,
                size: 20,
            },
        },
        {
            skip: !loadbalancer || lbNoIps,
        }
    );

    if (error) {
        throw error;
    }

    return (
        <>
            {loadbalancer ? (
                <LoadBalancerWarning loadBalancer={loadbalancer} />
            ) : isLoading ? (
                <div className={classNames(skeletonStyles, "h-[56px]")} />
            ) : (
                <EmptyResource
                    title="No Load Balancer Online "
                    icon={NavIcons["environmentLoadBalancer"]}
                    className="border-none"
                />
            )}
            <ul>
                <SkeletonFallback
                    shouldRenderFallback={false}
                    fallback={Array.from(Array(2).keys()).map((id) => (
                        <LoadBalancerInstanceItem
                            i={undefined}
                            ips={undefined}
                            key={id}
                        />
                    ))}
                >
                    {lbInstances?.data ? (
                        <>
                            {lbInstances?.data?.map((i) => (
                                <LoadBalancerInstanceItem
                                    key={i.id}
                                    i={i}
                                    ips={
                                        loadbalancer?.meta?.ips?.filter(
                                            (ip) =>
                                                ip.assignment?.instance_id ===
                                                i.id
                                        ) || []
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {loadbalancer?.meta?.ips?.map((ip) => {
                                return (
                                    <LoadBalancerInstanceItem
                                        key={ip.id}
                                        ips={[ip]}
                                    />
                                );
                            })}
                        </>
                    )}
                </SkeletonFallback>
            </ul>
        </>
    );
}

function LoadBalancerInstanceItem({
    i,
    ips,
}: {
    i?: Instance | undefined;
    ips: Ip[] | undefined;
}) {
    return (
        <li
            className={classNames(
                " px-1 text-sm tracking-wider [&>:first-child]:mb-2",
                ips === undefined && skeletonStyles
            )}
        >
            <FormField
                label={i ? `${i.provider.vendor} - ${i.provider.location}` : ""}
            >
                <LoadBalancerIps ips={ips} />
            </FormField>
        </li>
    );
}

function LoadBalancerWarning({ loadBalancer }: { loadBalancer?: Container }) {
    const lbOffline = loadBalancer?.state?.current !== "running";
    const lbNoIps = !doesLbHaveIps(loadBalancer);

    const { data: containers } = useGetContainersQuery(
        {
            filter: { environment: loadBalancer?.environment?.id || "" },
        },
        { skip: !loadBalancer?.environment?.id }
    );

    const { currentData: summary } = useGetEnvironmentSummaryQuery(
        {
            environmentId: loadBalancer?.environment?.id || "",
        },
        { skip: !loadBalancer?.environment?.id }
    );

    const vpnEnabled = summary?.data?.services.vpn?.enable;

    const hasRunningPublicNetworkContainers = containers?.data?.filter(
        (c) =>
            c.config.network.public === "enable" &&
            c.state.current === "running"
    )?.length;

    if (
        (lbOffline || lbNoIps) &&
        !hasRunningPublicNetworkContainers &&
        !vpnEnabled
    ) {
        return (
            <InfoPanel type="info">
                The load balancer in this environment is offline. However, no
                containers are currently running with public network access
                enabled.
            </InfoPanel>
        );
    }

    if ((hasRunningPublicNetworkContainers || vpnEnabled) && lbOffline) {
        switch (loadBalancer?.state.current) {
            case "starting":
                return (
                    <InfoPanel type="info" className="mb-4">
                        The load balancer is starting. Once it is online
                        containers with networking set to &apos;public&apos;
                        will be accessible.
                    </InfoPanel>
                );
            default:
                return (
                    <InfoPanel type="warning" className="mb-4">
                        The load balancer is not currently online. Containers
                        exposed to the public internet will be inaccessible
                        until an instance comes online.
                    </InfoPanel>
                );
        }
    }

    if ((hasRunningPublicNetworkContainers || vpnEnabled) && lbNoIps) {
        return (
            <InfoPanel type="warning">
                The load balancer in this environment has no available ips.
                Containers exposed to the public internet will be inaccessible
                until an instance comes online.
            </InfoPanel>
        );
    }

    return null;
}
function LoadBalancerIps({ ips }: { ips: Ip[] | undefined }) {
    return (
        <ul>
            <SkeletonFallback
                shouldRenderFallback={ips === undefined}
                fallback={Array.from(Array(2).keys()).map((_, idx) => (
                    <li
                        key={idx}
                        className={classNames("my-2 mb-4  tracking-wider")}
                    >
                        <CopyInput />
                    </li>
                ))}
            >
                {ips?.map((ip) => (
                    <li key={ip.id} className="my-2 mb-4 tracking-wider">
                        <CopyInput value={ip.cidr} prefix={ip.kind} />
                    </li>
                ))}
            </SkeletonFallback>
        </ul>
    );
}
