import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../../util";
import {
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";

export function UserAuthFields() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();

    return (
        <div className="flex gap-4">
            <RhfFormField
                required
                label="username"
                name="origin.details.auth.details.username"
            >
                <TextInput
                    {...register("origin.details.auth.details.username", {
                        ...required(),
                        shouldUnregister: true,
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="token"
                name="origin.details.auth.details.token"
                required
            >
                <TextInput
                    {...register("origin.details.auth.details.token", {
                        ...required(),
                        shouldUnregister: true,
                    })}
                    type="password"
                />
            </RhfFormField>
        </div>
    );
}
