import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { useAppSelector } from "~/hooks";
import { IntegrationLogo } from "@cycleplatform/ui/components/resources";
import { DeployableInfraFormType } from "../form";
import { Integration } from "~/services/cycle";
import { selectAppliedTheme } from "~/modules/settings";

export function ProviderSection({
    provider,
    providers,
}: {
    provider?: Integration;
    providers?: Integration[];
}) {
    const { setValue } = useFormContext<DeployableInfraFormType>();
    const theme = useAppSelector(selectAppliedTheme);

    return (
        <div className="grid grid-cols-4 gap-4 pb-8">
            {providers?.map((p) => {
                return (
                    <button
                        key={p.id}
                        type="button"
                        onClick={() => setValue("provider", p)}
                        className={classNames(
                            getOptionButtonClassName(
                                provider && provider.id === p.id
                            )
                        )}
                    >
                        <div className="flex items-center justify-center p-2 ">
                            <div className="h-10">
                                <IntegrationLogo
                                    vendor={p.vendor}
                                    theme={theme}
                                />
                            </div>
                        </div>
                    </button>
                );
            })}
        </div>
    );
}
