import {
    RhfFormProvider,
    RhfGlobalFormError,
    Checkbox,
    FormFieldLabel,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
    VpnEnvironmentService,
    Environment,
    useCreateVpnServiceJobMutation,
    VpnReconfigureTask,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import classNames from "classnames";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type VpnSettingsSectionProps = {
    info: VpnEnvironmentService | null | undefined;
    environment: Environment | undefined;
};

function getDefaultValues(info?: VpnEnvironmentService | null) {
    return {
        enable: info?.enable,
        container_id: info?.container_id,
        auto_update: info?.auto_update,
        config: info?.config,
    };
}

export function VpnSettingsSection({
    info,
    environment,
}: VpnSettingsSectionProps) {
    const form = useForm<VpnReconfigureTask["contents"]>({
        defaultValues: getDefaultValues(info),
        ...rhfConfig,
    });

    const {
        handleSubmit,
        reset,
        register,
        formState: { isSubmitting, isDirty },
    } = form;
    const [formError, setFormError] = useState<string>();

    const [updateConfig] = useCreateVpnServiceJobMutation();

    useKeepFormCurrent(form, info, (i) => getDefaultValues(i));

    const onSubmit = async (data: VpnReconfigureTask["contents"]) => {
        setFormError(undefined);

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => reset(data))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Settings" />
                <PanelContent>
                    <div className="w-full">
                        <div className="flex items-center justify-between pb-4">
                            <FormFieldLabel
                                label="Auto Update"
                                help="VPN will update automatically as new versions are released"
                            />

                            <Checkbox {...register("auto_update")} />
                        </div>
                        <PanelFooter>
                            <div>
                                <div>
                                    {formError && (
                                        <p className="text-error pb-2">
                                            {formError}
                                        </p>
                                    )}
                                    <RhfGlobalFormError className="pb-2" />
                                </div>
                                <div className="flex w-full justify-end">
                                    <AccessControlOverlay
                                        aclResource={environment}
                                        verifyFn={modifyAccessFn(
                                            "environments-services-manage"
                                        )}
                                    >
                                        <PushAndHoldButton
                                            flavor="primary"
                                            type="button"
                                            onClick={handleSubmit(onSubmit)}
                                            icon={faEdit}
                                            isLoading={isSubmitting}
                                            disabled={!isDirty}
                                        >
                                            Update VPN
                                        </PushAndHoldButton>
                                    </AccessControlOverlay>
                                </div>
                            </div>
                        </PanelFooter>
                    </div>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
