import { $warn } from "@cycleplatform/core/util/log";
import zonefile from "dns-zonefile";
import { CreateDnsZoneRecordApiArg } from "~/services/cycle";

/**
 * Accepts a BIND zone file and converts it into Cycle zone records
 */
export function parseZoneFileToRecords(
    file: string
): CreateDnsZoneRecordApiArg["body"][] {
    const parsed = zonefile.parse(file);
    if (Object.keys(parsed).length === 0) {
        return [];
    }

    const newRecords: CreateDnsZoneRecordApiArg["body"][] = [];

    if (parsed.a) {
        parsed.a.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    a: {
                        ip: r.ip,
                    },
                },
            });
        });
    }

    if (parsed.aaaa) {
        parsed.aaaa.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    aaaa: {
                        ip: r.ip,
                    },
                },
            });
        });
    }

    if (parsed.caa) {
        parsed.caa.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    caa: {
                        tag: r.tag,
                        value: r.value,
                    },
                },
            });
        });
    }

    if (parsed.cname) {
        parsed.cname.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    cname: {
                        domain: r.alias,
                    },
                },
            });
        });
    }

    if (parsed.ds) {
        $warn("DS records not yet supported on Cycle");
    }

    if (parsed.mx) {
        parsed.mx.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    mx: {
                        priority: r.preference,
                        domain: r.host,
                    },
                },
            });
        });
    }

    if (parsed.ns) {
        parsed.ns.forEach((r) => {
            if (r.host === "@") {
                $warn("refusing to import root (@) NS records");
                return;
            }
            newRecords.push({
                name: r.name,
                type: {
                    ns: {
                        domain: r.host,
                    },
                },
            });
        });
    }

    if (parsed.soa) {
        $warn("refusing to import SOA records");
    }

    if (parsed.spf) {
        parsed.spf.forEach((r) => {
            // convert SPF entries to TXT
            newRecords.push({
                name: r.name,
                type: {
                    txt: {
                        value: r.data,
                    },
                },
            });
        });
    }

    if (parsed.srv) {
        parsed.srv.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    srv: {
                        priority: r.priority,
                        domain: r.target,
                        port: r.port,
                        weight: r.weight,
                    },
                },
            });
        });
    }

    if (parsed.txt) {
        parsed.txt.forEach((r) => {
            newRecords.push({
                name: r.name,
                type: {
                    txt: {
                        value: r.txt,
                    },
                },
            });
        });
    }

    return newRecords;
}
