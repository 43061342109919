import { createContext } from "react";
import {
    Container,
    Environment,
    GetLoadBalancerServiceApiResponse,
} from "~/services/cycle";

type LoadBalancerDialogContext = {
    environment: Environment | undefined;
    lbContainer: Container | undefined;
    service: GetLoadBalancerServiceApiResponse["data"] | undefined;
};
export const LoadBalancerDialogContext =
    createContext<LoadBalancerDialogContext>({
        environment: undefined,
        lbContainer: undefined,
        service: undefined,
    });
