import { useState } from "react";
import { EventsChart } from "./chart";
import { EventsContext } from "./context";
import { EventsList } from "./list";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";

export function EventChartList({
    criteria,
    skip,
}: {
    criteria: { [key: string]: unknown };
    skip?: boolean;
}) {
    const [focusDate, setFocusDate] = useState<string>();

    return (
        <EventsContext.Provider
            value={{
                setFocusDate,
                focusDate,
            }}
        >
            <EventsChart criteria={criteria} skip={skip} className="mb-4" />
            <EventsList criteria={criteria} skip={skip} />
        </EventsContext.Provider>
    );
}

export function EventChartListLoading() {
    return (
        <div>
            <div className={classNames(skeletonStyles, "h-60")} />
            <div>
                <div className={classNames(skeletonStyles, "h-[6rem]")} />
                <div className={classNames(skeletonStyles, "h-[6rem]")} />
                <div className={classNames(skeletonStyles, "h-[6rem]")} />
            </div>
        </div>
    );
}
