import { CreatorType } from "@cycleplatform/core/modules/creators";
import { DateTimeFormats, formatDateString } from "@cycleplatform/core/util";
import { SkeletonText } from "@cycleplatform/ui/components/loaders/skeleton";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    ApiKey,
    Environment,
    AutoScaleGroup,
    BillingInvoice,
    BillingMethod,
    Cluster,
    ComponentIncludes,
    Container,
    DnsRecord,
    DnsZone,
    Image,
    ImageSource,
    InfrastructureIpPool,
    Instance,
    Integration,
    Network,
    Pipeline,
    PipelineRun,
    Role,
    ScopedVariable,
    Server,
    Stack,
    StackBuild,
    PublicAccount,
    VirtualMachine,
} from "~/services/cycle";

export function ContainerInstanceLink({
    instanceId,
    components,
}: {
    instanceId?: string;
    components?: ComponentIncludes;
}) {
    if (!instanceId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const instance = components?.[instanceId] as Instance | undefined;

    if (!instance) {
        return <>{instanceId}</>;
    }

    return (
        <Link
            to={generateDialogLink("container", {
                "dialog-container-id": instance?.container_id,
                "dialog-instance-id": instance?.id,
                "dialog-tab": "instances",
            })}
        >
            {instance.hostname}
        </Link>
    );
}
export function EnvironmentServiceLink({
    containerId,
    components,
}: {
    containerId?: string;
    components?: ComponentIncludes;
}) {
    if (!containerId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const container = components?.[containerId] as Container | undefined;

    if (!container) {
        return <>{containerId}</>;
    }

    const link = () => {
        switch (container.image.service) {
            case "loadbalancer":
                return generateDialogLink("environment-lb-manage", {
                    "dialog-env": container.environment.id,
                });
            case "discovery":
                return generateDialogLink("environment-discovery-manage", {
                    "dialog-env": container.environment.id,
                });
            case "scheduler":
                return generateDialogLink("environment-scheduler-manage", {
                    "dialog-env": container.environment.id,
                });
            case "vpn":
                return generateDialogLink("environment-vpn-manage", {
                    "dialog-env": container.environment.id,
                });
        }
        return generateDialogLink("container", {
            "dialog-container-id": containerId,
        });
    };

    return <Link to={link()}>{container.name.split("Environment")[1]}</Link>;
}
export function ContainerLink({
    containerId,
    components,
}: {
    containerId?: string;
    components?: ComponentIncludes;
}) {
    if (!containerId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const container = components?.[containerId] as Container | undefined;

    return (
        <Link
            to={generateDialogLink("container", {
                "dialog-container-id": containerId,
            })}
        >
            {container?.name || containerId}
        </Link>
    );
}

export function UserLink({
    userId,
    userType,
    components,
}: {
    userId?: string;
    userType?: CreatorType["type"];
    components?: ComponentIncludes;
}) {
    if (!userType || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }

    switch (userType) {
        case "account":
            if (!userId) {
                return (
                    <SkeletonText
                        size="md"
                        className="!my-0 inline-block h-4"
                    />
                );
            }
            const account = components?.[userId] as PublicAccount | undefined;

            return (
                <>
                    account{" "}
                    <Link
                        to={generateDialogLink("account-info", {
                            "dialog-account-id": userId,
                        })}
                    >
                        {account
                            ? `${account.name.first} ${account.name.last}`
                            : userId}
                    </Link>
                </>
            );
        case "environment":
            if (!userId) {
                return (
                    <SkeletonText
                        size="md"
                        className="!my-0 inline-block h-4"
                    />
                );
            }
            const environment = components?.[userId] as Environment | undefined;
            return (
                <>
                    environment{" "}
                    <Link to={`/environments/${userId}`}>
                        {environment?.name || userId}
                    </Link>
                </>
            );

        case "api-key":
            if (!userId) {
                return (
                    <SkeletonText
                        size="md"
                        className="!my-0 inline-block h-4"
                    />
                );
            }
            const key = components?.[userId] as ApiKey | undefined;
            return (
                <>
                    api key{" "}
                    <Link to={`/hub/api-keys/${userId}`}>
                        {key?.name || userId}
                    </Link>
                </>
            );
        case "platform":
            return <>Cycle Platform</>;
        case "platform-pipeline":
            if (!userId) {
                return (
                    <SkeletonText
                        size="md"
                        className="!my-0 inline-block h-4"
                    />
                );
            }
            const pipeline = components?.[userId] as Pipeline | undefined;

            return (
                <>
                    pipeline{" "}
                    <Link to={`/pipelines/${userId}`}>
                        {pipeline?.name || userId}
                    </Link>
                </>
            );
        default:
            return (
                <>
                    {userType} {userId}
                </>
            );
    }
}

export function ImageSourceLink({
    sourceId,
    components,
}: {
    sourceId?: string;
    components?: ComponentIncludes;
}) {
    if (!sourceId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const imageSource = components?.[sourceId] as ImageSource | undefined;

    return (
        <Link to={`/images/sources/${sourceId}`}>
            {imageSource?.name || sourceId}
        </Link>
    );
}

export function ImageLink({
    imageId,
    components,
}: {
    imageId?: string;
    components?: ComponentIncludes | null;
}) {
    if (!imageId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const image = components?.[imageId] as Image | undefined;

    return (
        <Link
            to={generateDialogLink("image", {
                "dialog-image-id": imageId,
            })}
        >
            {image?.name || imageId}
        </Link>
    );
}

export function ApiKeyLink({
    apiKeyId,
    components,
}: {
    apiKeyId?: string;
    components?: ComponentIncludes;
}) {
    if (!apiKeyId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const apiKey = components?.[apiKeyId] as ApiKey | undefined;

    return (
        <Link to={`/hub/api-keys/${apiKeyId}`}>{apiKey?.name || apiKeyId}</Link>
    );
}

export function RoleLink({
    roleId,
    components,
}: {
    roleId?: string;
    components?: ComponentIncludes;
}) {
    if (!roleId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const role = components?.[roleId] as Role | undefined;

    return <Link to={`/hub/roles/${roleId}`}>{role?.name || roleId}</Link>;
}

export function NetworkLink({
    networkId,
    components,
}: {
    networkId?: string;
    components?: ComponentIncludes;
}) {
    if (!networkId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const network = components?.[networkId] as Network | undefined;

    return (
        <Link to={`/networks/${networkId}`}>{network?.name || networkId}</Link>
    );
}

export function DnsZoneLink({
    dnsZoneId,
    components,
}: {
    dnsZoneId?: string;
    components?: ComponentIncludes;
}) {
    if (!dnsZoneId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const dnsZone = components?.[dnsZoneId] as DnsZone | undefined;

    return (
        <Link to={`/networks/${dnsZoneId}`}>
            {dnsZone?.origin || dnsZoneId}
        </Link>
    );
}
export function DnsRecordLink({
    recordId,
    components,
}: {
    recordId?: string;
    components?: ComponentIncludes;
}) {
    if (!recordId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const dnsRecord = components?.[recordId] as DnsRecord | undefined;

    if (!dnsRecord) {
        return <>{recordId}</>;
    }
    return (
        <Link
            to={generateDialogLink("dns-record", {
                "dialog-zone-id": dnsRecord.zone_id,
                "dialog-record-id": dnsRecord.id,
            })}
        >
            {dnsRecord.name}
        </Link>
    );
}
export function PipelineLink({
    pipelineId,
    components,
}: {
    pipelineId?: string;
    components?: ComponentIncludes;
}) {
    if (!pipelineId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const pipeline = components?.[pipelineId] as Pipeline | undefined;

    return (
        <Link to={`/pipelines/${pipelineId}`}>
            {pipeline?.name || pipelineId}
        </Link>
    );
}
export function PiplineRunLink({
    runId,
    components,
}: {
    runId?: string;
    components?: ComponentIncludes;
}) {
    if (!runId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const pipelineRun = components?.[runId] as PipelineRun | undefined;

    if (!pipelineRun) {
        return <>{runId}</>;
    }
    return (
        <Link
            to={generateDialogLink("pipeline-run", {
                "dialog-pipeline-run-id": pipelineRun.id,
                "dialog-pipeline-id": pipelineRun.pipeline_id,
            })}
        >
            {formatDateString(pipelineRun.events.started)}
        </Link>
    );
}

export function StackLink({
    stackId,
    components,
}: {
    stackId?: string;
    components?: ComponentIncludes;
}) {
    if (!stackId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const stack = components?.[stackId] as Stack | undefined;

    return <Link to={`/stacks/${stackId}`}>{stack?.name || stackId}</Link>;
}
export function StackBuildLink({
    buildId,
    components,
}: {
    buildId?: string;
    components?: ComponentIncludes;
}) {
    if (!buildId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const stackBuild = components?.[buildId] as StackBuild | undefined;

    if (!stackBuild) {
        return <>{buildId}</>;
    }
    return (
        <Link
            to={generateDialogLink("stack-build", {
                "dialog-stack-id": stackBuild.stack_id,
                "dialog-build-id": stackBuild.id,
            })}
        >
            {formatDateString(stackBuild.events.created)}
        </Link>
    );
}
export function ProviderLink({
    providerId,
    components,
}: {
    providerId?: string;
    components?: ComponentIncludes;
}) {
    if (!providerId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const provider = components?.[providerId] as Integration | undefined;

    if (!provider) {
        return <>{providerId}</>;
    }
    return (
        <Link to={`/hub/integrations/${provider.identifier}`}>
            {provider.name}
        </Link>
    );
}
export function AutoscaleGroupLink({
    groupId,
    components,
}: {
    groupId?: string;
    components?: ComponentIncludes;
}) {
    if (!groupId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const group = components?.[groupId] as AutoScaleGroup | undefined;

    return (
        <Link to={`/infrastructure/autoscaling/${groupId}`}>
            {group?.name || groupId}
        </Link>
    );
}
export function ServerLink({
    serverId,
    components,
}: {
    serverId?: string;
    components?: ComponentIncludes;
}) {
    if (!serverId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const server = components?.[serverId] as Server | undefined;

    return (
        <Link to={`/infrastructure/servers/${serverId}`}>
            {server?.hostname || serverId}
        </Link>
    );
}

export function ClusterLink({
    clusterId,
    components,
}: {
    clusterId?: string;
    components?: ComponentIncludes;
}) {
    if (!clusterId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const cluster = components?.[clusterId] as Cluster | undefined;

    return (
        <Link to={`/infrastructure/clusters/${clusterId}`}>
            {cluster?.identifier || clusterId}
        </Link>
    );
}
export function IpsPoolLink({
    poolId,
    components,
}: {
    poolId?: string;
    components?: ComponentIncludes;
}) {
    if (!poolId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const pool = components?.[poolId] as InfrastructureIpPool | undefined;

    return (
        <Link to={`/infrastructure/ips/${poolId}`}>
            {pool?.block?.cidr || poolId}
        </Link>
    );
}

export function InvoiceLink({
    invoiceId,
    components,
}: {
    invoiceId?: string;
    components?: ComponentIncludes;
}) {
    if (!invoiceId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const invoice = components?.[invoiceId] as BillingInvoice | undefined;

    return (
        <Link to={`/billing/invoices/${invoiceId}`}>
            {invoice ? (
                <>
                    {formatDateString(
                        invoice.events.created,
                        DateTimeFormats["standard"]
                    )}
                </>
            ) : (
                invoiceId
            )}
        </Link>
    );
}
export function BillingMethodLink({
    methodId,
    components,
}: {
    methodId?: string;
    components?: ComponentIncludes;
}) {
    if (!methodId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const method = components?.[methodId] as BillingMethod | undefined;

    return (
        <Link to={`/billing/methods/${methodId}`}>
            {method?.name || methodId}
        </Link>
    );
}
export function EnvironmentLink({
    environmentId,
    components,
}: {
    environmentId?: string;
    components?: ComponentIncludes;
}) {
    if (!environmentId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const environment = components?.[environmentId] as Environment | undefined;

    return (
        <Link to={`/environments/${environmentId}`}>
            {environment?.name || environmentId}
        </Link>
    );
}
export function EnvScopedVariableLink({
    scopedVarId,
    components,
}: {
    scopedVarId?: string;
    components?: ComponentIncludes;
}) {
    if (!scopedVarId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const scopedVariable = components?.[scopedVarId] as
        | ScopedVariable
        | undefined;

    if (!scopedVariable) {
        return <>{scopedVarId}</>;
    }
    return (
        <Link
            to={generateDialogLink("environment-scopedvar-edit", {
                "dialog-var": scopedVariable.id,
                "dialog-env": scopedVariable.environment_id,
            })}
        >
            {scopedVariable.identifier}
        </Link>
    );
}

export function VirtualMachineLink({
    vmId,
    components,
}: {
    vmId?: string;
    components?: ComponentIncludes;
}) {
    if (!vmId || !components) {
        return <SkeletonText size="md" className="!my-0 inline-block" />;
    }
    const vm = components?.[vmId] as VirtualMachine | undefined;

    return (
        <Link
            to={generateDialogLink("virtual-machine", {
                "dialog-id": vmId,
            })}
        >
            {vm?.name || vmId}
        </Link>
    );
}
