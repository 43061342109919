import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export function getLbDestinationsQuery(
    environmentId: string | undefined,
    port: string | undefined
) {
    const portCriteria = port ? { "labels.port": `${port}` } : {};

    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subDays(new Date(), 1), {
                    roundingMethod: "floor",
                })
            ),
            "range-end": formatRFC3339(roundToNearestMinutes(new Date())),
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "lb.controller.router.destinations.connections.invalid",
                        "lb.controller.router.destinations.connections.success",
                        "lb.controller.router.destinations.connections.unavailable",
                    ],
                },
                "labels.environment:id": environmentId,
                ...portCriteria,
            },
            pipeline: [
                {
                    $project: {
                        time: "$time",
                        metadata: "$metadata",
                        controller: "$labels.controller",
                        instance: "$labels.instance:id",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                        interval: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "minute",
                                binSize: 15,
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$interval",
                            },
                            hour: {
                                $hour: "$interval",
                            },
                            minute: {
                                $minute: "$interval",
                            },
                            instance: "$instance",
                            controller: "$controller",
                        },
                        success: {
                            $avg: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.success",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        unavailable: {
                            $avg: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.unavailable",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        invalid: {
                            $avg: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.invalid",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        last_time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: "$_id.day",
                            hour: "$_id.hour",
                            minute: "$_id.minute",
                        },
                        success: {
                            $sum: "$success",
                        },
                        unavailable: {
                            $sum: "$unavailable",
                        },
                        invalid: {
                            $sum: "$invalid",
                        },
                        time: {
                            $last: {
                                $dateTrunc: {
                                    date: "$last_time",
                                    unit: "minute",
                                    binSize: 15,
                                },
                            },
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: 15,
                            unit: "minute",
                            bounds: [
                                formatRFC3339(
                                    roundToNearestMinutes(
                                        subDays(new Date(), 1),
                                        {
                                            nearestTo: 15,
                                            roundingMethod: "floor",
                                        }
                                    )
                                ),
                                formatRFC3339(
                                    roundToNearestMinutes(new Date(), {
                                        nearestTo: 15,
                                        roundingMethod: "floor",
                                    })
                                ),
                            ],
                        },
                    },
                },
                {
                    $addFields: {
                        success: { $ifNull: ["$success", 0] },
                        unavailable: { $ifNull: ["$unavailable", 0] },
                        invalid: { $ifNull: ["$invalid", 0] },
                    },
                },

                {
                    $project: {
                        _id: 0,
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
            ],
        },
    };
}
