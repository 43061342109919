import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Badge } from "@cycleplatform/ui/components/badges";
import { faScrewdriverWrench } from "@fortawesome/pro-duotone-svg-icons";

type ServiceContainerBadgeProps = {
    className?: string;
};

export function ServiceContainerBadge({
    className,
}: ServiceContainerBadgeProps) {
    return (
        <div>
            <Badge
                className={classNames(
                    className,
                    "!bg-cycle-gray !h-8",
                    "dark:bg-cycle-gray gap-2 whitespace-nowrap px-2 !text-xs leading-4 text-white"
                )}
            >
                <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    className="text-xs"
                />
                <span className="pt-0.5 !text-xs">service</span>
            </Badge>
        </div>
    );
}
