import { Container, Environment } from "~/services/cycle";
import { CycleErrorBoundary } from "~/components/common/errors";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { Link } from "react-router-dom";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { generateDialogLink } from "../../helpers";
import { ContainerStateControls } from "~/components/containers/controls";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";

type ServiceContainerPanelProps = {
    container: Container | undefined;
    environment: Environment | undefined;
};

export function ServiceContainerPanel({
    container,
    environment,
}: ServiceContainerPanelProps) {
    return (
        <Panel>
            <PanelTitle title="Container" />
            <PanelContent>
                <CycleErrorBoundary>
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-8">
                                <Link
                                    className={!container ? skeletonStyles : ""}
                                    to={generateDialogLink("container", {
                                        "dialog-container-id": container?.id,
                                    })}
                                >
                                    {container?.id}
                                </Link>

                                <ResourceStateBadge state={container?.state} />
                            </div>

                            <div className="h-10 w-10">
                                <ResourceStatePieChart
                                    data={preprocessCountsData(
                                        container?.meta?.instances_count
                                    )}
                                />
                            </div>

                            <ContainerStateControls
                                container={container}
                                hide={["delete"]}
                                environment={environment}
                            />
                        </div>
                    </div>
                </CycleErrorBoundary>
            </PanelContent>
        </Panel>
    );
}
