import yaml from "js-yaml";
/**
 *
 * @param inputString Is a JSON or YAML string
 * @returns a YAML string or an Error
 *
 * Function to convert input string (JSON or YAML) to a formatted YAML string
 */
export function convertToYamlString(inputString: string): string | Error {
    try {
        // Attempt to parse JSON first; if it fails, parse as YAML
        let data;
        try {
            data = JSON.parse(inputString);
        } catch (error) {
            data = yaml.load(inputString);
        }

        // Convert the data to a YAML string
        return yaml.dump(data, {
            indent: 2,
            noRefs: true,
        });
    } catch (error) {
        return new Error("Failed to convert to YAML string");
    }
}

/**
 *
 * @param inputString Is a JSON or YAML string
 * @returns a formatted JSON string or an Error
 *
 * Function to convert input string (JSON or YAML) to a formatted JSON string
 */
export function convertToJsonString(inputString: string): string | Error {
    try {
        // Attempt to parse JSON first; if it fails, parse as YAML
        let data;
        try {
            data = JSON.parse(inputString);
        } catch (error) {
            data = yaml.load(inputString);
        }

        return JSON.stringify(data, null, 2);
    } catch (error) {
        return new Error("Failed to convert to JSON string");
    }
}

/**
 *
 * @param inputString Is a JSON or YAML string
 * @returns a JSON object or an Error
 *
 * Function to convert input string (JSON or YAML) to a formatted JSON object
 */
export function convertToJsonObject(
    inputString: string
): Record<string, unknown> | Error {
    try {
        let data;
        try {
            data = JSON.parse(inputString);
        } catch (error) {
            data = yaml.load(inputString);
        }

        return data;
    } catch (error) {
        return new Error("Failed to convert to JSON object");
    }
}
