import { formatBytes, formatBytesString, pushUnique } from "../../../util";
import { components } from "../../api/__generated";

type Server = components["schemas"]["Server"];

export function extractServerNodeStats(server: Server | null) {
    if (!server?.meta || !server?.meta.node) {
        return null;
    }

    return server.meta.node.stats;
}

export function isServerOffline(server: Server) {
    if (!server.meta || !server.meta.node) {
        return null;
    }

    return !server.meta.node.online;
}

export function isServerHealthy(server: Server) {
    const stats = extractServerNodeStats(server);
    if (!stats) {
        return null;
    }

    const load = stats.load.avg15m;
    const coreCount = stats.cpu.cores;
    if (coreCount === undefined) {
        return null;
    }

    return coreCount > load;
}

/**
 * Uses a generic function extending Server in order to be compatible with RTK types in portal
 * Neccesary because the RTK compiler differs in how Record<string, any> functions are handled
 * https://github.com/drwpow/openapi-typescript/issues/1070
 */
export function getServersByProvider<T extends Server>(
    servers: T[]
): Record<string, T[]> {
    return servers.reduce((serversByProvider, server) => {
        if (!serversByProvider[server.provider.vendor]) {
            serversByProvider[server.provider.vendor] = [];
        }

        serversByProvider[server.provider.vendor].push(server);
        return serversByProvider;
    }, {} as Record<string, T[]>);
}

export function getServerStateCounts(servers: Server[]) {
    return servers?.reduce((countsByState, server) => {
        if (!countsByState[server.state.current]) {
            countsByState[server.state.current] = 0;
        }

        countsByState[server.state.current] += 1;
        return countsByState;
    }, {} as Record<string, number>);
}

export function formatServerRamUsage(
    ram?: components["schemas"]["ServerStatsRam"]
) {
    if (!ram) {
        return "";
    }
    const used = formatBytesString((ram.total - ram.available) * 1000);
    const total = formatBytesString(ram.total * 1000);
    return `${used} / ${total}`;
}

export function calcUsedRamPercent(
    ram?: components["schemas"]["ServerStatsRam"]
) {
    if (!ram) {
        return 0;
    }
    return ram ? ((ram.total - ram.available) / ram?.total) * 100 : 0;
}

export function formatUsedStorageUsage(
    storage?: components["schemas"]["ServerStatsStorage"]
) {
    if (!storage) {
        return "";
    }
    return `${formatBytesString(
        getUsedStorageBytes(storage)
    )} / ${formatBytesString(getTotalServerStorageBytes(storage))} used`;
}

export function calcUsedStoragePercent(
    storage?: components["schemas"]["ServerStatsStorage"]
) {
    if (!storage) {
        return 0;
    }
    return (
        (getUsedStorageBytes(storage) / getTotalServerStorageBytes(storage)) *
        100
    );
}
export function getUsedStorageBytes(
    storage: components["schemas"]["ServerStatsStorage"]
) {
    const stats = extractBaseVolumeInfo(storage);
    if (stats === null) {
        return 0;
    }

    return (stats.total - stats.free) * 1024;
}

export function getTotalServerStorageBytes(
    storage: components["schemas"]["ServerStatsStorage"]
) {
    const baseVolume = extractBaseVolumeInfo(storage);
    if (baseVolume === null) {
        return 0;
    }
    return baseVolume.total * 1024;
}

export function extractBaseVolumeInfo(
    storage: components["schemas"]["ServerStatsStorage"]
) {
    for (const key in storage.mounts) {
        // eslint-disable-next-line no-prototype-builtins
        if (storage.mounts.hasOwnProperty(key)) {
            const element = storage.mounts[key];
            if (element?.mountpoint && element.mountpoint.includes("data")) {
                return element;
            }
        }
    }
    return null;
}

export function formatModel(
    modelId: string,
    modelIncludes: components["schemas"]["ServerModelIncludes"]
) {
    return (modelIncludes && modelIncludes[modelId]?.name) || "Unknown";
}

export function formatUptime(
    uptime?: components["schemas"]["ServerStatsUptime"]
) {
    const seconds = uptime ? uptime.seconds || 0 : 0;
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const min = Math.floor(((seconds % 86400) % 3600) / 60);
    return `${days} day${(days !== 1 && "s") || ""}, ${hours} hour${
        (hours !== 1 && "s") || ""
    }, ${min} minute${(min !== 1 && "s") || ""}`;
}

export function formatClusterCpuShares(cpu: {
    cores: number;
    shares: {
        allocated: number;
        total: number;
    };
    share_ratio?: number;
}) {
    return `${cpu.shares.allocated}/ ${cpu.shares.total} Shares`;
}

export function formatCpuDetails(
    modelId: string,
    modelIncludes: components["schemas"]["ServerModelIncludes"],
    stats?: components["schemas"]["NodeMetaStats"]
) {
    if (!stats?.cpu?.processors) {
        return null;
    }
    const model = modelIncludes[modelId];
    // Returns an array with all CPU Details as strings
    return [
        stats.cpu.processors[0].model || null,
        `${stats.cpu.cores} cores`,
        model?.specs?.cpu?.threads
            ? `${model.specs.cpu.threads} threads`
            : null,
        model?.specs?.cpu?.shared ? "Shared" : null,
    ];
}

export function formatClusterRamUsage(ram: {
    total_mb: number;
    allocated_mb: number;
    used_mb: number;
    provisioned_mb: number;
}) {
    const used = formatBytes(ram.used_mb * 1000);
    const total = formatBytes(ram.total_mb * 1000);
    return `${used.value}${used.suffix} / ${total.value}${total.suffix}`;
}

export function formatClusterRamAllocation(ram: {
    total_mb: number;
    allocated_mb: number;
    used_mb: number;
    provisioned_mb: number;
}) {
    const allocated = formatBytes(ram.allocated_mb * 1000);
    const total = formatBytes(ram.total_mb * 1000);
    return `${allocated.value}${allocated.suffix} / ${total.value}${total.suffix}`;
}

export function getServerCategories(
    servers: components["schemas"]["ProviderServerModel"][]
) {
    return servers.reduce<string[]>((acc, cur) => {
        return pushUnique(acc, cur.provider.category);
    }, []);
}

export function getServerLocationName(
    serverIncludes?: components["schemas"]["ServerIncludes"],
    locationId?: string
) {
    if (!serverIncludes || !locationId) {
        return "";
    }

    return serverIncludes?.locations?.[locationId]?.name || "";
}
