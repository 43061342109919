import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import {
    Account,
    useGetHubsQuery,
    useDeleteAccountMutation,
} from "~/services/cycle";
import { Activity } from "./components/Activity";
import { Profile } from "./components/Profile";
import { BrowserNotifications } from "./components/BrowserNotifications";
import { PendingInvitesList } from "~/components/hubs/PendingInvitesList";
import { useNavigate } from "react-router-dom";
import { DeleteResource } from "~/components/common/resources/DeleteResource";

type DashboardSectionProps = {
    account?: Account;
};

export function DashboardSection({ account }: DashboardSectionProps) {
    const [deleteAccount] = useDeleteAccountMutation();
    const nav = useNavigate();

    const { data: hubs } = useGetHubsQuery({});

    const ownedHubsExist = !!hubs?.data?.filter(
        (h) => h.creator.id === account?.id
    ).length;

    return (
        <DialogPageBody>
            <PageContent>
                <Profile account={account} />
                <BrowserNotifications account={account} />
            </PageContent>
            <PageAside>
                <PendingInvitesList />
                <Activity account={account} />
                <DeleteResource
                    // For accounts, they may be outside the context of a hub
                    // when this dialog is shown, and we don't need to rely on acls for deleting our own account.
                    accessControl={undefined}
                    disabled={ownedHubsExist}
                    warningPanel={
                        ownedHubsExist
                            ? "You must delete all hubs your are the owner of before you can delete your account"
                            : undefined
                    }
                    resourceType="account"
                    resourceName={account?.email?.address}
                    onSubmit={async () => {
                        if (!account) {
                            return;
                        }
                        return deleteAccount()
                            .unwrap()
                            .then(() => {
                                nav("/logout");
                            });
                    }}
                />
            </PageAside>
        </DialogPageBody>
    );
}
