import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { ToolTrayContent } from "./ToolTrayContent";
import { IdSelectType } from "../forms/IdSelect/hooks";

type ToolTrayProps = {
    children: React.ReactNode;
};

export function ToolTray({ children }: ToolTrayProps) {
    const { watch, resetField } = useFormContext<IdSelectType>();

    const ids = watch("ids");

    useEffect(() => {
        const keyHandler = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                resetField("ids");
            }
        };
        window.addEventListener("keydown", keyHandler);
        return () => {
            window.removeEventListener("keydown", keyHandler);
        };
    }, []);

    return (
        <Transition
            show={ids.length > 0}
            enterTo="translate-x-0"
            enterFrom="translate-x-full"
            leave="translate-x-full"
            unmount={false}
            className="absolute left-0 right-0 bottom-1 w-full transition-transform duration-150"
        >
            <div
                className={classNames(
                    "bg-cycle-white rounded-l-lg px-4 pb-4 pt-4 shadow-lg",
                    "dark:bg-cycle-black"
                )}
            >
                <ToolTrayContent ids={ids}>{children}</ToolTrayContent>
            </div>
        </Transition>
    );
}
1;
