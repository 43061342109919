import { ComponentIncludes } from "~/services/cycle";

import { GenericEvent } from "../util";
import {
    ClusterLink,
    ContainerInstanceLink,
    ContainerLink,
    EnvironmentLink,
    EnvironmentServiceLink,
    ImageLink,
    ServerLink,
    UserLink,
    VirtualMachineLink,
} from "../../../common/resources/ResourceLinks";
import { CreatorType } from "@cycleplatform/core/modules/creators";
type EventMessageProps = {
    event: GenericEvent;
    components?: ComponentIncludes;
};

export function EventMessage({ event, components }: EventMessageProps) {
    switch (event.event) {
        case "api.security_violation":
            return <div>{event.text}</div>;
        case "console.ssh.login":
            return (
                <p>
                    Successful SSH login on container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    {event.user_id ? (
                        <>
                            from{" "}
                            <UserLink
                                userId={event.user_id as string}
                                userType={
                                    event.user_type as CreatorType["type"]
                                }
                                components={components}
                            />
                        </>
                    ) : null}
                    .
                </p>
            );
        case "console.sos.login":
            return (
                <p>
                    Successful login to serial-over-SSH on virtual machine
                    <VirtualMachineLink
                        vmId={event.component.id}
                        components={components}
                    />{" "}
                    {event.user_id ? (
                        <>
                            from{" "}
                            <UserLink
                                userId={event.user_id as string}
                                userType={
                                    event.user_type as CreatorType["type"]
                                }
                                components={components}
                            />
                        </>
                    ) : null}
                    .
                </p>
            );
        case "console.sos.login.failed":
            return (
                <p>
                    Failed attempt to log in to serial-over-SSH on virtual
                    machine{" "}
                    <VirtualMachineLink
                        vmId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.volume.extend.failed":
            return (
                <p>
                    Failed to extend volume for instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    in container{" "}
                    <ContainerLink
                        containerId={event?.labels?.["container:id"]}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "console.ssh.login.failed":
            return (
                <p>
                    Failed SSH login on container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.backup.completed":
            return (
                <p>
                    Completed backup of instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.labels?.["instance:id"]}
                        components={components}
                    />
                    .
                </p>
            );
        case "container.instance.backup.failed":
            return (
                <p>
                    Failed while creating a backup of instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.labels?.["instance:id"]}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.delete.failed":
            return (
                <p>
                    Failed to delete container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.error":
            return (
                <p>
                    Error on instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.function.max_runtime":
            return (
                <p>
                    Function instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    reached maximum runtime after{" "}
                    {event.labels?.["max-runtime"]}. Instance is stopping.
                </p>
            );
        case "container.instance.healthcheck.failed":
            return (
                <p>
                    Healthcheck failed on instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "container.instance.healthcheck.recovered":
            return (
                <p>
                    Instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    recovered from failed healthcheck.
                </p>
            );
        case "container.instance.healthcheck.restarted":
            return (
                <p>
                    Instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    restarted due to failed healthcheck.
                </p>
            );
        case "container.instance.migration.failed":
            return (
                <p>
                    Failed to migrate container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.migration.completed":
            return (
                <p>
                    Migration of instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    completed.
                </p>
            );

        case "container.instance.network.interfaces.create.failed":
            return (
                <p>
                    Failed to create network interface for instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.restart.failed":
            return (
                <p>
                    Failed to restart container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.restart.max_restarts":
            return (
                <p>
                    Maximum number of restarts limit reached on instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    after {event?.labels?.["restart-attempts"]} attempts.
                </p>
            );
        case "container.instance.start.failed":
            return (
                <p>
                    Failed to start container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instance.start.privileged":
            return (
                <p>
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />{" "}
                    started as a privileged instance.
                </p>
            );
        case "container.instance.stop.failed":
            return (
                <p>
                    Failed to stop container instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.instances.autoscale.down":
            return (
                <p>
                    Automatically scaled container{" "}
                    <ContainerLink
                        containerId={event.component.id}
                        components={components}
                    />{" "}
                    down by{" "}
                    <strong>{event.labels?.["purged-instances"]}</strong>{" "}
                    instances.
                </p>
            );
        case "container.instances.autoscale.up":
            return (
                <p>
                    Automatically scaled container{" "}
                    <ContainerLink
                        containerId={event.component.id}
                        components={components}
                    />{" "}
                    by <strong>{event.labels?.["new-instances"]}</strong> new{" "}
                    instances.
                </p>
            );
        case "container.reconfigured.privileged":
            return (
                <p>
                    Container has been changed to{" "}
                    <ContainerLink
                        containerId={event.component.id}
                        components={components}
                    />{" "}
                    "privileged".
                </p>
            );
        case "container.volumes.base.create.failed":
            return (
                <p>
                    Failed to create base volume for instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "container.volumes.create.failed":
            return (
                <p>
                    Failed to create volume for instance{" "}
                    <ContainerInstanceLink
                        instanceId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "environment.service.auto_update":
            return (
                <p>
                    Automatic update of the
                    <EnvironmentServiceLink
                        containerId={event.labels?.["container:id"]}
                        components={components}
                    />{" "}
                    service on environment{" "}
                    <EnvironmentLink
                        environmentId={event.labels?.["environment:id"]}
                        components={components}
                    />{" "}
                    from version [
                    <strong>{event.labels?.["version:current"]}</strong>] to [
                    <strong>{event.labels?.["version:new"]}</strong>]. Service
                    is restarting.
                </p>
            );
        case "environment.service.lb.ips.sync.failed":
            return (
                <p>
                    Failed to sync IPs for
                    <EnvironmentServiceLink
                        containerId={event.labels?.["container:id"]}
                        components={components}
                    />{" "}
                    service on environment{" "}
                    <EnvironmentLink
                        environmentId={event.labels?.["environment:id"]}
                        components={components}
                    />
                    .
                </p>
            );
        case "environment.service.vpn.login.failed":
            return (
                <p>
                    Failed VPN login on environment{" "}
                    <EnvironmentLink
                        environmentId={event.labels?.["environment:id"]}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.cluster.resources.ram.full":
            return (
                <p>
                    Less that 10% of total ram is available for cluster{" "}
                    <ClusterLink
                        clusterId={event.cluster}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.compute.full_restart":
            return (
                <p>
                    Compute service on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    was fully restarted.
                </p>
            );
        case "infrastructure.server.compute.sharedfs.mounts.mount":
            return (
                <p>
                    Successfully mounted file system on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.compute.sharedfs.mounts.mount.failed":
            return (
                <p>
                    Failed to mount file system on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "infrastructure.server.compute.soft_restart":
            return (
                <p>
                    Compute service soft restarted (zero downtime) on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.compute.volumes.base.reconfigured":
            return (
                <p>
                    Increased base volume on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    to {event.labels?.["size_gb"]}GB.
                </p>
            );
        case "infrastructure.server.image.download.failed":
            return (
                <p>
                    Failed to download container image{" "}
                    <ImageLink imageId={event.labels?.["image:id"]} /> on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "infrastructure.server.internal_api.throttled":
            return (
                <p>
                    Throttling Internal API - too many requests on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.manifest.sync.failed":
            return (
                <p>
                    Unable to sync Cycle manifest to server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    after 5 failed attempts.
                </p>
            );

        case "infrastructure.server.mesh.connect.failed":
            return (
                <p>
                    Unable to connect to server mesh for server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.neighbor.reachable":
            const d = event.labels?.["outage:duration"].split(".");
            // time is to the 10th decimal point.
            // RoundedDuration is the integer value and the last character of the string, which decribes the unit of time measure ex: "s"
            const roundedDuration = `${d?.[0]}${d?.[1].slice(-1)}`;
            return (
                <p>
                    Server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    has reestablished communication with neighbor{" "}
                    <ServerLink
                        serverId={event.labels?.["neighbor:server_id"]}
                        components={components}
                    />{" "}
                    after {roundedDuration}.
                </p>
            );
        case "infrastructure.server.neighbor.rebuild":
            return (
                <p>
                    Configuration between servers{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    and{" "}
                    <ServerLink
                        serverId={event.labels?.["neighbor:server_id"]}
                        components={components}
                    />{" "}
                    changed significantly, rebuilding network.
                </p>
            );
        case "infrastructure.server.neighbor.unreachable":
            return (
                <p>
                    Server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    unable to reach neighbor{" "}
                    <ServerLink
                        serverId={event.labels?.["neighbor:server_id"]}
                        components={components}
                    />{" "}
                    after {event.labels?.["failed-pings"]} failed pings.
                </p>
            );

        case "infrastructure.server.neighbor.upgraded":
            return (
                <p>
                    Connection between server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    and{" "}
                    <ServerLink
                        serverId={event.labels?.["neighbor:server_id"]}
                        components={components}
                    />{" "}
                    has been upgraded automatically to use private networking.
                </p>
            );
        case "infrastructure.server.resources.load.high":
            return (
                <p>
                    Server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    has experienced a 5 minute load of{" "}
                    {event.labels?.["load:5m"]}, which is higher than expected
                    for the number of CPU cores.
                </p>
            );
        case "infrastructure.server.resources.ram.full":
            return (
                <p>
                    There is less than 10% available RAM remaining on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.resources.storage.cycle_pool.full":
            return (
                <p>
                    There is less than 10% of total storage available on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.resources.storage.volumes.base.full":
            return (
                <p>
                    There is less than 10% of total base volume storage
                    available on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.sftp.login":
            return (
                <p>
                    Failed SFTP login on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    by user <strong>{event.labels?.["user:username"]}</strong>.
                </p>
            );
        case "infrastructure.server.sftp.login.failed":
            return (
                <p>
                    SFTP login failed on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    . <div className="text-error text-sm">{event.text}</div>
                </p>
            );
        case "infrastructure.server.sftp.lockdown":
            return (
                <p>
                    SFTP service has been locked down on server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    due to too many failed logins.
                </p>
            );

        case "infrastructure.server.evacuation.completed":
            return (
                <p>
                    Instances from server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />{" "}
                    evacuated to{" "}
                    <ServerLink
                        serverId={event.labels?.["destination:server_id"]}
                        components={components}
                    />
                    .
                </p>
            );
        case "infrastructure.server.evacuation.failed":
            return (
                <p>
                    Failed to evacuate instances from server{" "}
                    <ServerLink
                        serverId={event.component.id}
                        components={components}
                    />
                    .
                </p>
            );
        default:
            return <div>{event.event}</div>;
    }
}
