import { Cluster, useGetClustersQuery } from "~/services/cycle";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { ResourceComboBox } from "~/components/common/forms";
import { useGetMemberRole } from "~/modules/hubs/permissions/useGetMemberRole";
import {
    modifyAccessFn,
    verifyAccess,
} from "@cycleplatform/core/modules/acls/util";
import { useCallback } from "react";

type ClusterSelectProps = {
    value: string | null;
    onChange: (
        clusterIdentifier: string | undefined,
        cluster: Cluster | undefined
    ) => void;
    sideEffect?: () => void;
    disabled?: boolean;
    skip?: boolean;
};

export function ClusterSelect({
    value,
    onChange,
    sideEffect,
    disabled,
    skip,
}: ClusterSelectProps) {
    const {
        currentData: clusters,
        error: clustersError,
        isLoading: clusterIsLoading,
    } = useGetClustersQuery(
        {
            page: {
                number: 1,
                // TODO - infinite scroll
                size: 100,
            },
        },
        { skip }
    );

    const {
        role,
        error: roleError,
        isLoading: roleIsLoading,
    } = useGetMemberRole();

    if (clustersError) {
        throw clustersError;
    }
    if (roleError) {
        throw roleError;
    }

    const verifyFn = useCallback(modifyAccessFn("clusters-view"), []);

    return (
        <ResourceComboBox
            disabled={disabled}
            placeholder="Select a Cluster"
            filterFields={["id", "identifier"]}
            value={value}
            isOptionValid={(c) => {
                if (!role) {
                    return true;
                }
                const accessErr = verifyAccess({
                    aclResource: c,
                    cluster: c,
                    role,
                    verifyFn,
                });
                return !!!accessErr;
            }}
            resources={clusters?.data || []}
            formatDisplayValue={(i) => {
                if (!i) return "";

                return i.identifier;
            }}
            isLoading={roleIsLoading || clusterIsLoading}
            formatOption={(c) => <FormattedOption label={c.identifier} />}
            onChange={(_, cluster) => {
                onChange(cluster?.identifier || "", cluster);
                sideEffect?.();
            }}
        />
    );
}
