import { components } from "@cycleplatform/core/modules/api/__generated";
import classNames from "classnames";
import { skeletonStyles } from "../../loaders/skeleton/skeletonStyle";
import { ResourceStateIcon } from "./ResourceStateIcon";
import { Tooltip } from "../../tooltip";
import { getHumanizedTime } from "@cycleplatform/core/util";

export type ResourceStateFull = components["schemas"]["State"] & {
    current: string;
    error?: {
        message?: string;
    };
    health?: components["schemas"]["InstanceState"]["health"];
};

type ResourceStateProps = {
    state?: ResourceStateFull;
    className?: string;
};

export function ResourceState({ state, className }: ResourceStateProps) {
    return (
        <div
            className={classNames(
                className,
                !state && skeletonStyles,
                "flex items-center gap-2"
            )}
        >
            <Tooltip message={getStateTooltip(state)} disabled={!state?.error}>
                <ResourceStateIcon
                    state={state || { current: "new", changed: "" }}
                />{" "}
            </Tooltip>
            <span className="text-base">{state?.current}</span>
        </div>
    );
}

export function getStateTooltip(
    state?: ResourceStateFull,
    defaultMsg?: string
) {
    if (!state) {
        return "";
    }
    if (state.error?.message) {
        return `${state.error.message}`;
    }

    return defaultMsg || `Updated ${getHumanizedTime(state.changed)}`;
}
