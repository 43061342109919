import { RestrictedTooltip } from "@cycleplatform/ui/components/acl/RestrictedTooltip";
import { CycleApiErrorResponse } from "~/services/helpers";

type RestrictedFieldProps = {
    error: CycleApiErrorResponse;
    fieldLength?: "sm" | "md" | "lg" | "xl";
    children?: React.ReactNode;
};

function getTooltipMessage(error: CycleApiErrorResponse) {
    return `${error?.data?.error?.code}: ${error?.data?.error?.title}`;
}

export function RestrictedField({
    children,
    fieldLength,
    error,
}: RestrictedFieldProps) {
    return (
        <RestrictedTooltip tooltip={getTooltipMessage(error)}>
            {children ? (
                children
            ) : fieldLength ? (
                <div>{fieldLengthMap[fieldLength]}</div>
            ) : (
                <div>restricted</div>
            )}
        </RestrictedTooltip>
    );
}

const fieldLengthMap = {
    sm: "000",
    md: "00000000",
    lg: "0000000000000",
    xl: "0000000000000000000000.",
};
