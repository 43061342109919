import {
    faArrowCircleUp,
    faFileDownload,
    faPlus,
    faQuestionCircle,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, Pipeline } from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";

export const formatPipelineMessage = (
    item: Activity,
    components: ComponentIncludes
) => {
    const pipec = extractComponent<Pipeline>(item.component, components);

    const pipe = (
        <Link
            to={{
                pathname: `/pipelines/${pipec?.id}`,
            }}
        >
            {pipec?.name || item.component?.id}
        </Link>
    );

    switch (item.event) {
        case "pipeline.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created new pipeline {pipe}</span>
                ) : (
                    <span>Error creating new pipeline {pipe}</span>
                ),
                icon: faPlus,
            };
        case "pipeline.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated pipeline {pipe}</span>
                ) : (
                    <span>Error updating pipeline {pipe}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "pipeline.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of pipeline {pipe}</span>
                ) : (
                    <span>Error requesting deletion of pipeline {pipe}</span>
                ),
                icon: faTrash,
            };
        case "pipeline.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted pipeline {pipe}</span>
                ) : (
                    <span>Error deleting pipeline {pipe}</span>
                ),
                icon: faTrash,
            };
        case "pipeline.task.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested trigger of pipeline {pipe}</span>
                ) : (
                    <span>Error requesting trigger of pipeline {pipe}</span>
                ),
                icon: faFileDownload,
            };
        case "pipeline.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Triggered pipeline {pipe}</span>
                ) : (
                    <span>Error triggering pipeline {pipe}</span>
                ),
                icon: faFileDownload,
            };
        default:
            return {
                message: item.event,
            };
    }
};
