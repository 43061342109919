import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { Stack, StackSpec } from "~/services/cycle";
import { BuildSpec } from "~/components/stacks/stack-builds/BuildSpec";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function RawStackSourceDialog({ stack }: { stack?: Stack }) {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="Stack Source"
            isOpen={isDialogOpen("raw-stack-source")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full !pb-0">
                {stack?.source.details ? (
                    <BuildSpec spec={stack.source.details as StackSpec} />
                ) : null}
            </DialogColumn>
        </StyledFormDialog>
    );
}
