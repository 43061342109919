import { components } from "@cycleplatform/core/modules/api/__generated";
import { ResourceStateBadge } from "../resources/state";
import { getInstanceState } from "@cycleplatform/core/modules/containers/instances";
import { getServerPurgeTime } from "@cycleplatform/core/modules/containers/instances";
import { FormattedPurgeTimer } from "../resources";

type InstanceStateBadgeProps = {
    instance: components["schemas"]["Instance"];
};

export function InstanceStateBadge({ instance }: InstanceStateBadgeProps) {
    const purgeTime = getServerPurgeTime(instance);
    return (
        <ResourceStateBadge
            state={getInstanceState(instance)}
            tooltip={
                purgeTime ? <FormattedPurgeTimer purgeTime={purgeTime} /> : null
            }
        />
    );
}
