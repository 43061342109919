import { Badge } from "@cycleplatform/ui/components/badges";
import { faEarthAmerica, faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "@cycleplatform/core/modules/api/__generated";
import classNames from "classnames";

export function ContainerPublicNetworkBadge({
    c,
}: {
    c: components["schemas"]["Container"];
}) {
    const style = getStyle(c.config.network.public);
    return (
        <div>
            <Badge className="dark:bg-cycle-gray gap-2 whitespace-nowrap leading-4">
                <FontAwesomeIcon
                    icon={faEarthAmerica}
                    className={classNames(style.className, "text-xs")}
                />
                <span className="text-xs">{style.message}</span>
            </Badge>
        </div>
    );
}

function getStyle(publicNetwork: "enable" | "disable" | "egress-only") {
    switch (publicNetwork) {
        case "enable":
            return {
                message: "public",
                className: "text-success",
            };
        case "egress-only":
            return {
                message: "egress only",
                className: "text-warning",
            };
        default:
        case "disable":
            return {
                message: "private",
                className: "text-cycle-gray-light/50",
            };
    }
}
