import {
    formatRFC3339,
    roundToNearestHours,
    roundToNearestMinutes,
    subHours,
} from "date-fns";
import { InstanceGranularity } from "../../types";

export function buildTrafficByInstanceQuery({
    criteria,
    granularity,
    instanceIds,
    range,
    packets,
    scope,
}: {
    criteria: Record<string, string>;
    granularity: InstanceGranularity;
    instanceIds: string[] | undefined;
    range: number;
    packets: "rx" | "tx";
    scope?: "private" | "public";
}) {
    const bounds = { start: "", end: "" };

    switch (granularity.unit) {
        case "minute":
            bounds.start = formatRFC3339(
                roundToNearestMinutes(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestMinutes(new Date()));
            break;
        case "hour":
            bounds.start = formatRFC3339(
                roundToNearestHours(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestHours(new Date()));
            break;
    }

    const idFilter = generateInstanceIdFilter(instanceIds);

    const packetsFilter = generatePacketsFilter(packets);
    const interfaceScope = generateScopeFilter(scope);

    return {
        filter: {
            "range-start": bounds.start,
            "range-end": bounds.end,
        },
        body: {
            criteria: {
                ...packetsFilter,
                ...criteria,
                ...idFilter,
                ...interfaceScope,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },

                {
                    $project: {
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                binSize: granularity.size,
                                unit: granularity.unit,
                            },
                        },
                        labels: "$labels",
                        metadata: "$metadata",
                        instance: "$metadata.component._id",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                    },
                },

                {
                    $group: {
                        _id: {
                            instance: "$instance",
                            time: "$time",
                        },
                        traffic: {
                            $max: "$point_value",
                        },
                    },
                },
                {
                    $sort: {
                        "_id.time": 1,
                    },
                },
                {
                    $group: {
                        _id: "$_id.instance",
                        data: {
                            $push: {
                                time: "$_id.time",
                                traffic: "$traffic",
                            },
                        },
                    },
                },
                {
                    $project: {
                        instance: "$_id",
                        diffs: {
                            $map: {
                                input: { $range: [1, { $size: "$data" }] },
                                as: "i",
                                in: {
                                    time: {
                                        $arrayElemAt: ["$data.time", "$$i"],
                                    },
                                    diff: {
                                        $let: {
                                            vars: {
                                                current: {
                                                    $arrayElemAt: [
                                                        "$data.traffic",
                                                        "$$i",
                                                    ],
                                                },
                                                previous: {
                                                    $arrayElemAt: [
                                                        "$data.traffic",
                                                        {
                                                            $subtract: [
                                                                "$$i",
                                                                1,
                                                            ],
                                                        },
                                                    ],
                                                },
                                            },
                                            in: {
                                                $cond: {
                                                    if: {
                                                        $gte: [
                                                            {
                                                                $subtract: [
                                                                    "$$current",
                                                                    "$$previous",
                                                                ],
                                                            },
                                                            0,
                                                        ],
                                                    },
                                                    then: {
                                                        $multiply: [
                                                            {
                                                                $subtract: [
                                                                    "$$current",
                                                                    "$$previous",
                                                                ],
                                                            },
                                                            1024,
                                                        ],
                                                    },
                                                    else: null,
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    $unwind: "$diffs",
                },
                {
                    $group: {
                        _id: "$diffs.time",
                        time: { $first: "$diffs.time" },
                        instances: {
                            $push: {
                                k: { $toString: "$instance" },
                                v: "$diffs.diff",
                            },
                        },
                    },
                },
                {
                    $project: {
                        time: 1,
                        _id: 0,
                        newRoot: {
                            $mergeObjects: [
                                { $arrayToObject: "$instances" },
                                { time: "$time" },
                            ],
                        },
                    },
                },
                {
                    $replaceRoot: { newRoot: "$newRoot" },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: granularity.size,
                            unit: granularity.unit,
                            bounds: [bounds.start, bounds.end],
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
            ],
        },
    };
}

export function buildFindTopTrafficInstancesQuery({
    criteria,
    granularity,
    limit,
    range,
    packets,
    scope,
}: {
    criteria: Record<string, string>;
    granularity: InstanceGranularity;
    limit: number;
    range: number;
    packets: "rx" | "tx";
    scope?: "private" | "public";
}) {
    const bounds = { start: "", end: "" };

    switch (granularity.unit) {
        case "minute":
            bounds.start = formatRFC3339(
                roundToNearestMinutes(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestMinutes(new Date()));
            break;
        case "hour":
            bounds.start = formatRFC3339(
                roundToNearestHours(subHours(new Date(), range), {
                    nearestTo: granularity.size,
                    roundingMethod: "floor",
                })
            );
            bounds.end = formatRFC3339(roundToNearestHours(new Date()));
            break;
    }

    const packetsFilter = generatePacketsFilter(packets);
    const interfaceScope = generateScopeFilter(scope);

    return {
        filter: {
            "range-start": bounds.start,
            "range-end": bounds.end,
        },
        body: {
            criteria: {
                ...criteria,
                ...packetsFilter,
                ...interfaceScope,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                binSize: granularity.size,
                                unit: `${granularity.unit}`,
                            },
                        },
                        instance: "$metadata.component._id",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: granularity.size,
                            unit: granularity.unit,
                            bounds: [bounds.start, bounds.end],
                        },
                        partitionByFields: ["instance"], // Densify separately for each instance
                    },
                },
                {
                    $fill: {
                        output: {
                            point_value: { value: 0 }, // Fill missing values with zero
                        },
                    },
                },
                {
                    $group: {
                        _id: "$instance",
                        avgUsage: { $avg: "$point_value" },
                    },
                },
                {
                    $sort: {
                        avgUsage: -1,
                    },
                },
                {
                    $limit: limit,
                },
                {
                    $group: {
                        _id: null,
                        instanceIds: { $push: "$_id" },
                    },
                },
                {
                    $project: {
                        _id: 0,
                        instanceIds: 1,
                    },
                },
            ],
        },
    };
}

function generateInstanceIdFilter(instanceIds: string[] | undefined) {
    return instanceIds
        ? { "metadata.component._id": { $in: instanceIds } }
        : {};
}

function generatePacketsFilter(packets: "tx" | "rx") {
    return packets === "tx"
        ? {
              "metadata.metric": "container.instance.resources.network.tx_kb",
          }
        : {
              "metadata.metric": "container.instance.resources.network.rx_kb",
          };
}

function generateScopeFilter(scope: "public" | "private" | undefined) {
    if (!scope) {
        return {};
    }
    return scope === "private"
        ? {
              "labels.interface_scope": "private",
          }
        : {
              "labels.interface_scope": "public",
          };
}
