import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { Link } from "react-router-dom";
import { SkeletonTableRows } from "@cycleplatform/ui/components/loaders/skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { RamUsageByInstanceDataPoint } from "./query";
import { formatBytesString } from "@cycleplatform/core/util";
import { InstanceChartComponents } from "../../helpers";
import { useMemo } from "react";

export function RamUsageByInstanceTable({
    data,
    instanceColorMap,
    instanceLabelMap,
    components,
    include = [],
    instanceIds,
}: {
    data: RamUsageByInstanceDataPoint[];
    instanceColorMap: Record<string, string>;
    instanceLabelMap: Record<string, string>;
    components: InstanceChartComponents;
    instanceIds: string[];
    include?: ("servers" | "environments")[];
}) {
    if (!data.length) {
        return null;
    }
    const { time, ...lastPoint } = data[data.length - 1];

    const sortedLastPointIds = useMemo(
        () =>
            Object.entries(lastPoint)
                .sort(([, aVal], [, bVal]) => (bVal || 0) - (aVal || 0))
                .map((d) => d[0]),
        [lastPoint]
    );

    const ids = [...new Set([...sortedLastPointIds, ...instanceIds])];

    if (ids.length === 0) {
        return null;
    }

    return (
        <StyledDataTable>
            <StyledTableHead>
                <StyledHeaderCell className="w-[4rem]"></StyledHeaderCell>
                <StyledHeaderCell>Instance</StyledHeaderCell>
                {include.includes("servers") ? (
                    <StyledHeaderCell>Server</StyledHeaderCell>
                ) : null}
                {include.includes("environments") ? (
                    <StyledHeaderCell>Environment</StyledHeaderCell>
                ) : null}
                <StyledHeaderCell className="w-[12rem] text-center">
                    Current Usage
                </StyledHeaderCell>
            </StyledTableHead>
            <tbody>
                {ids.length ? (
                    ids.map((instanceId, idx) => {
                        const instance = components.instances[instanceId];
                        const server =
                            components.servers[instance?.server_id || ""];
                        const environment =
                            components.environments[
                                instance?.environment?.id || ""
                            ];
                        return (
                            <StyledTableRow key={`ram-row-${idx}`}>
                                <StyledCell className="max-w-[2rem] truncate">
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        color={instanceColorMap[instanceId]}
                                    />
                                </StyledCell>
                                <StyledCell className="max-w-[7rem] truncate">
                                    {instance ? (
                                        <Link
                                            to={generateDialogLink(
                                                "container",
                                                {
                                                    "dialog-container-id":
                                                        instance?.container_id,
                                                    "dialog-instance-id":
                                                        instanceId,
                                                    "dialog-tab": "instances",
                                                }
                                            )}
                                        >
                                            {
                                                instanceLabelMap[
                                                    instanceId
                                                ]?.split(" - ")?.[0]
                                            }
                                        </Link>
                                    ) : null}
                                </StyledCell>
                                {include.includes("servers") && server ? (
                                    <StyledCell className="max-w-[5rem] truncate">
                                        <Link
                                            to={`/infrastructure/servers/${server?.id}`}
                                        >
                                            {server?.hostname}
                                        </Link>
                                    </StyledCell>
                                ) : null}
                                {include.includes("environments") &&
                                environment ? (
                                    <StyledCell className="max-w-[5rem] truncate">
                                        <Link
                                            to={`/environments/${environment?.id}`}
                                        >
                                            {environment?.name}
                                        </Link>
                                    </StyledCell>
                                ) : null}
                                <StyledCell className="text-center">
                                    {typeof lastPoint[instanceId] ===
                                    "number" ? (
                                        <>
                                            {formatBytesString(
                                                lastPoint[instanceId] || 0
                                            )}
                                        </>
                                    ) : null}
                                </StyledCell>
                            </StyledTableRow>
                        );
                    })
                ) : (
                    <SkeletonTableRows columns={3} />
                )}
            </tbody>
        </StyledDataTable>
    );
}
