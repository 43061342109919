import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    DialogPageBody,
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    generateDialogLink,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    Image,
    ImageSource,
    Stack,
    StackBuild,
    useGetImageBuildLogQuery,
    useGetImageQuery,
    useGetImageSourceQuery,
    useLookupStackBuildQuery,
    useGetStackQuery,
    useDeleteImageMutation,
} from "~/services/cycle";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons";
import {
    PageAside,
    PageBody,
    PageContent,
} from "@cycleplatform/ui/components/page";
import { ContainerUsage } from "~/components/dialogs/images/viewImage/components/ContainerUsage";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    CopyInput,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { UpdateImage } from "./components/UpdateImage";
import { ImageSummary } from "./components/ImageSummary";
import { EnvironmentVariables } from "./components/EnvironmentVariables";
import { VolumesSummary } from "./components/VolumesSummary";
import { ImageSourceSummary } from "./components/ImageSourceSummary";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { CycleErrorBoundary } from "~/components/common/errors";
import { isCycleApiError } from "~/services/helpers";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { formatDateString } from "@cycleplatform/core/util/time";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { ReadOnlyBadge } from "@cycleplatform/ui/components/badges";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { ImageDialogContext } from "./context";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ImageBuildLog } from "~/components/images/ImageBuildLog";

export function ViewImageDialog() {
    const params = getAllDialogSearchParams<"image">();

    const { data: image, error: imageError } = useGetImageQuery(
        {
            imageId: params["dialog-image-id"] || "",
            include: ["stacks", "stack_builds"],
            meta: ["containers_count"],
        },
        { skip: !params["dialog-image-id"] }
    );

    const sourceIsStackBuild = image?.data?.source?.type === "stack-build";

    const { data: source, error: sourceError } = useGetImageSourceQuery(
        {
            sourceId: image?.data?.source?.details?.id || "",
        },
        {
            skip: !image?.data?.source?.details?.id || sourceIsStackBuild,
        }
    );

    // Don't fail if we don't have the ability to view stacks
    const stacksViewCapabilityError = useCapability("stacks-view");

    const { data: stackBuild, error: stackBuildError } =
        useLookupStackBuildQuery(
            {
                buildId: image?.data?.source?.details?.id || "",
            },
            {
                skip:
                    !image?.data?.source?.details?.id ||
                    !sourceIsStackBuild ||
                    !!stacksViewCapabilityError,
            }
        );

    const { data: stack, error: stackError } = useGetStackQuery(
        {
            stackId:
                image?.data?.source?.type === "stack-build"
                    ? image?.data?.source?.details?.stack_id || ""
                    : "",
        },
        {
            skip:
                !image?.data?.source?.details?.id ||
                !sourceIsStackBuild ||
                !!stacksViewCapabilityError,
        }
    );

    const [deleteImage] = useDeleteImageMutation();
    const nav = useNavigate();

    const accessErr = useVerifyAccess(
        image?.data,
        modifyAccessFn("images-manage")
    );

    if (sourceError) {
        throw sourceError;
    }

    if (stackError) {
        throw stackError;
    }

    if (stackBuildError) {
        throw stackBuildError;
    }

    if (imageError) {
        throw imageError;
    }

    return (
        <ImageDialogContext.Provider value={{}}>
            <StyledResourceDialog
                open={isDialogOpen("image")}
                onClose={() => nav(clearDialogParams())}
                header={
                    <div className="flex items-center gap-4">
                        <h1>{image?.data?.name}</h1>
                        <ResourceStateBadge
                            className="!bg-cycle-gray !h-8"
                            state={image?.data?.state}
                        />
                        {!!accessErr ? (
                            <ReadOnlyBadge
                                className="!bg-cycle-gray !h-8"
                                state={image?.data?.state}
                            />
                        ) : null}
                    </div>
                }
                breadcrumb={getImageBreadcrumb(
                    image?.data,
                    stack?.data,
                    stackBuild?.data,
                    source?.data
                )}
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={faChartLine}
                >
                    <SectionDisabledControl
                        disabled={image?.data.state.current === "deleted"}
                        heightFull
                    >
                        <DialogPageBody>
                            <PageContent>
                                <UpdateImage
                                    image={image?.data}
                                    source={source?.data}
                                />
                                <ImageSummary image={image?.data} />
                                <EnvironmentVariables image={image?.data} />
                                <VolumesSummary image={image?.data} />
                            </PageContent>
                            <PageAside className="w-1/2">
                                {!sourceIsStackBuild && (
                                    <ImageSourceSummary
                                        image={image?.data}
                                        source={source?.data}
                                    />
                                )}
                                <ContainerUsage
                                    image={image?.data}
                                    source={source?.data}
                                />

                                <Panel>
                                    <PanelTitle title="Image Id" />
                                    <PanelContent>
                                        <CopyInput value={image?.data?.id} />
                                    </PanelContent>
                                </Panel>
                                <DeleteResource
                                    accessControl={{
                                        aclResource: source?.data,
                                        verifyFn:
                                            modifyAccessFn("images-manage"),
                                    }}
                                    resourceType="image"
                                    resourceName={image?.data?.name}
                                    onSubmit={async () => {
                                        return deleteImage({
                                            imageId: image?.data?.id || "",
                                        }).unwrap();
                                    }}
                                />
                            </PageAside>
                        </DialogPageBody>
                    </SectionDisabledControl>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="build-log"
                    name="Build Log"
                    icon={faChartLine}
                >
                    <CycleErrorBoundary>
                        <DialogPageBody>
                            <ImageBuildLog imageId={image?.data?.id} />
                        </DialogPageBody>
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </ImageDialogContext.Provider>
    );
}

function getImageBreadcrumb(
    image?: Image,
    stack?: Stack,
    stackBuild?: StackBuild,
    source?: ImageSource
) {
    if (!image?.source?.details) {
        return undefined;
    }

    if ("stack_id" in image.source.details) {
        const buildIdentifier = stackBuild
            ? formatDateString(
                  stackBuild.events.created,
                  "MMM dd yyyy hh:mm aaa"
              )
            : "Stack Build";

        return [
            {
                label: "Stacks",
                link: "stacks",
            },
            {
                label: stack?.name || "",
                link: `stacks/${image?.source?.details?.stack_id}`,
            },
            {
                label: "Builds",
                link: `stacks/${image?.source?.details?.stack_id}/builds`,
            },
            {
                label: buildIdentifier,
            },
            {
                label: "Images",
                link: generateDialogLink("stack-build", {
                    "dialog-build-id": image?.source?.details?.id,
                    "dialog-stack-id": image?.source?.details?.stack_id,
                    "dialog-tab": "images",
                }),
            },
            {
                label: image?.name || "",
            },
        ];
    }
    return [
        {
            label: "Image Sources",
        },
        {
            label: source?.name || "",
            link: `images/sources/${source?.id}`,
        },
        {
            label: "Images",
            link: `images/sources/${source?.id}`,
        },
        {
            label: image?.name || "",
        },
    ];
}
