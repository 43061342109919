import {
    DateFormats,
    DateTimeFormats,
    formatDateString,
    getHumanizedTime,
} from "@cycleplatform/core/util/time";
import { isAfter, subWeeks } from "date-fns";
import { useEffect, useState } from "react";
import { Tooltip } from "../tooltip";
import classNames from "classnames";

type HumanizedTimeProps = {
    value: string | undefined;
    placeholder?: string;
    refreshIntervalSeconds?: number;
    formatStr?: string;
    granularity?: "ms" | "s" | "m" | "h" | "d";
    significantFigures?: number;
    humanizeUpTo?: Date;
    className?: string;
};

export function HumanizedTime({
    value,
    placeholder = "Unknown",
    refreshIntervalSeconds = 60,
    granularity = "s",
    significantFigures,
    humanizeUpTo = subWeeks(new Date(), 1),
    formatStr,
    className,
}: HumanizedTimeProps) {
    const [displayValue, setDisplayValue] = useState<string>(
        formatDateString(value || "", formatStr || DateFormats["standardShort"])
    );

    useEffect(() => {
        // always humanize on client side
        let interval: NodeJS.Timer | undefined = undefined;

        if (!value || isAfter(humanizeUpTo, new Date(value))) {
            clearInterval(interval);
            return;
        }

        const humanize = () => {
            setDisplayValue(
                getHumanizedTime(value, {
                    formatStr,
                    granularity,
                    significantFigures,
                })
            );
        };

        humanize();

        // automatic update
        interval = setInterval(humanize, refreshIntervalSeconds * 1000);

        return () => {
            clearInterval(interval as any);
        };
    }, [value]);

    if (!value) {
        return <span>{placeholder}</span>;
    }

    return (
        <Tooltip
            message={formatDateString(
                value,
                formatStr || DateTimeFormats["standard"]
            )}
            className={classNames("inline-block", className)}
        >
            <time suppressHydrationWarning dateTime={value}>
                {displayValue}
            </time>
        </Tooltip>
    );
}
