import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    Checkbox,
    FormFieldLabel,
    isDurationString,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    DiscoveryEnvironmentService,
    Environment,
    CreateDiscoveryServiceJobApiArg,
    useCreateDiscoveryServiceJobMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import classNames from "classnames";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import { MultiSelectInput } from "@cycleplatform/ui/components/forms/select";

type DiscoveryConfigPanelProps = {
    info: DiscoveryEnvironmentService | null | undefined;
    environment: Environment | undefined;
};

function getDefaultValues(info?: DiscoveryEnvironmentService | null) {
    return {
        high_availability: info?.high_availability,
        auto_update: info?.auto_update,
        config: info?.config,
    };
}

export function DiscoveryConfigPanel({
    info,
    environment,
}: DiscoveryConfigPanelProps) {
    const form = useForm<CreateDiscoveryServiceJobApiArg["body"]["contents"]>({
        defaultValues: getDefaultValues(info),
        ...rhfConfig,
    });

    const {
        handleSubmit,
        reset,
        register,
        control,
        formState: { isSubmitting, isDirty },
    } = form;
    const [formError, setFormError] = useState<string>();
    const [updateConfig] = useCreateDiscoveryServiceJobMutation();

    useKeepFormCurrent(form, info, (i) => getDefaultValues(i));

    const onSubmit = async (
        data: CreateDiscoveryServiceJobApiArg["body"]["contents"]
    ) => {
        setFormError(undefined);

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => reset(data))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Settings" />
                <PanelContent>
                    <div className="w-full">
                        <RhfFormField
                            label="High Availablity"
                            name="high_availability"
                            className="relative"
                        >
                            <div className="absolute inset-0 flex justify-end">
                                <Checkbox {...register("high_availability")} />
                            </div>
                        </RhfFormField>

                        <div className="flex items-center justify-between pb-4">
                            <div
                                className={classNames(
                                    "flex items-center  gap-2 pb-2 text-sm font-semibold"
                                )}
                            >
                                <FormFieldLabel label="Auto Update" />
                                <HelpInfoCircle
                                    message={
                                        "Discovery will update automatically as new versions are released"
                                    }
                                    className="py-0 "
                                />
                            </div>

                            <Checkbox {...register("auto_update")} />
                        </div>

                        <div className="pb-4">
                            <div
                                className={classNames(
                                    "flex items-center  gap-2 pb-2 text-sm font-semibold"
                                )}
                            >
                                <FormFieldLabel label="Empty Set Delay" />
                                <HelpInfoCircle
                                    message={
                                        "Discovery will delay the return of an empty result for DNS lookups. This can be enabled to support older DNS clients that wait for both an IPv4 and IPv6 result during a lookup."
                                    }
                                    className="py-0 "
                                />
                            </div>

                            <TextInput
                                placeholder="0ms"
                                {...register("config.empty_set_delay", {
                                    validate: {
                                        ...isDurationString(),
                                    },
                                    setValueAs: (v) => (v === "" ? null : v),
                                })}
                            />
                        </div>

                        <div className="pb-4">
                            <div
                                className={classNames(
                                    "flex items-center  gap-2 pb-2 text-sm font-semibold"
                                )}
                            >
                                <FormFieldLabel label="Custom Resolvers" />
                                <HelpInfoCircle
                                    message={
                                        "DNS servers can be either IPs or domains"
                                    }
                                    className="py-0 "
                                />
                            </div>

                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <MultiSelectInput {...field} isCreatable />
                                )}
                                control={control}
                                name="config.custom_resolvers"
                            />
                        </div>

                        <PanelFooter>
                            <div>
                                <div>
                                    {formError && (
                                        <p className="text-error pb-2">
                                            {formError}
                                        </p>
                                    )}
                                    <RhfGlobalFormError className="pb-2" />
                                </div>
                                <div className="flex w-full justify-end">
                                    <AccessControlOverlay
                                        aclResource={environment}
                                        verifyFn={modifyAccessFn(
                                            "environments-services-manage"
                                        )}
                                    >
                                        <PushAndHoldButton
                                            flavor="primary"
                                            type="button"
                                            onClick={handleSubmit(onSubmit)}
                                            icon={faEdit}
                                            isLoading={isSubmitting}
                                            disabled={!isDirty}
                                        >
                                            Update Discovery
                                        </PushAndHoldButton>
                                    </AccessControlOverlay>
                                </div>
                            </div>
                        </PanelFooter>
                    </div>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
