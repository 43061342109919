import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { ScopedVariableFormSubmitType } from "..";
import { useFormContext, useWatch } from "react-hook-form";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useState } from "react";
import { Button } from "@cycleplatform/ui/components/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";

export function ScopedVariablesUrl() {
    const { register, control, setValue, getValues } =
        useFormContext<ScopedVariableFormSubmitType>();
    const source = useWatch({ name: "source", control });

    if (source.type !== "url") {
        return null;
    }
    return (
        <>
            <FormSectionHeader header="URL" />
            <FormSection>
                <div className="flex gap-4">
                    <RhfFormField
                        label="URL Source"
                        name="source.details.url"
                        required
                    >
                        <TextInput
                            spellCheck={false}
                            className="w-full border-gray-200"
                            {...register("source.details.url", {
                                ...required(),
                            })}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="Auth Token Url"
                        name="source.details.auth_token_url"
                    >
                        <TextInput
                            spellCheck={false}
                            className="w-full border-gray-200"
                            {...register("source.details.auth_token_url", {
                                setValueAs: (v) => (v === "" ? null : v),
                            })}
                        />
                    </RhfFormField>
                </div>

                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell className="w-80">
                            Header
                        </StyledHeaderCell>
                        <StyledHeaderCell>Value</StyledHeaderCell>
                        <StyledHeaderCell className="w-20"></StyledHeaderCell>
                    </StyledTableHead>

                    <tbody>
                        {source.details.headers
                            ? Object.entries(source.details.headers).map(
                                  ([header, value]) => (
                                      <StyledTableRow key={header}>
                                          <StyledCell>{header}</StyledCell>
                                          <StyledCell>
                                              <TextInput
                                                  {...register(
                                                      `source.details.headers.${header}`
                                                  )}
                                              />
                                          </StyledCell>
                                          <StyledCell className="text-end">
                                              <Button
                                                  flavor="discard"
                                                  onClick={() => {
                                                      const { ...newObject } =
                                                          getValues(
                                                              "source.details.headers"
                                                          );
                                                      delete newObject[header];
                                                      setValue(
                                                          "source.details.headers",
                                                          newObject,
                                                          { shouldDirty: true }
                                                      );
                                                  }}
                                              >
                                                  <FontAwesomeIcon
                                                      icon={faTrash}
                                                  />
                                              </Button>
                                          </StyledCell>
                                      </StyledTableRow>
                                  )
                              )
                            : null}

                        <NewHeaderRow
                            onAdd={(v) =>
                                setValue(
                                    `source.details.headers.${v.header}`,
                                    v.value,
                                    { shouldDirty: true }
                                )
                            }
                        />
                    </tbody>
                </StyledDataTable>
            </FormSection>
        </>
    );
}

function NewHeaderRow({
    onAdd,
}: {
    onAdd: (v: { header: string; value: string }) => void;
}) {
    const [newEnvVar, setNewEnvVar] = useState({
        header: "",
        value: "",
    });

    return (
        <StyledTableRow className="border-t">
            <StyledCell className="pr-2 !pl-0">
                <TextInput
                    placeholder="HEADER"
                    value={newEnvVar.header}
                    onChange={(ev) =>
                        setNewEnvVar({
                            ...newEnvVar,
                            header: ev.target.value,
                        })
                    }
                />
            </StyledCell>
            <StyledCell className="pr-4">
                <TextInput
                    placeholder="VALUE"
                    value={newEnvVar.value}
                    onChange={(ev) =>
                        setNewEnvVar({
                            ...newEnvVar,
                            value: ev.target.value,
                        })
                    }
                />
            </StyledCell>
            <StyledCell className="text-end">
                <Button
                    type="button"
                    className="whitespace-nowrap"
                    disabled={!newEnvVar.header || !newEnvVar.value}
                    onClick={() => {
                        onAdd({
                            ...newEnvVar,
                            header: newEnvVar.header,
                        });
                        setNewEnvVar({
                            header: "",
                            value: "",
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </StyledCell>
        </StyledTableRow>
    );
}
