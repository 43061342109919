import classNames from "classnames";
import { PageBody } from "../page";

type DialogPageBodyProps = {
    children: React.ReactNode;
    className?: string;
};

export function DialogPageBody({ children, className }: DialogPageBodyProps) {
    return (
        <PageBody
            className={classNames(
                className,
                "dark:bg-cycle-black-accent h-full overflow-y-auto p-4"
            )}
        >
            {children}
        </PageBody>
    );
}
