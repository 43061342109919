import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useSetHubPlanParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback(
        ({
            step,
            tier,
            promo,
        }: {
            step?: string;
            tier?: string;
            promo?: string | null;
        }) => {
            const nsp = new URLSearchParams(searchParams);

            if (step) {
                nsp.set("dialog-step", step);
            }
            if (tier) {
                nsp.set("dialog-tier-id", tier);
            }
            if (promo) {
                nsp.set("dialog-promo-code", promo);
            }
            if (promo === null) {
                nsp.delete("dialog-promo-code");
            }
            setSearchParams(nsp);
        },
        [searchParams, setSearchParams]
    );
};
