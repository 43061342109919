import { components } from "@cycleplatform/core/modules/api/__generated";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Container, useLookupComponentsQuery } from "~/services/cycle";
import classNames from "classnames";

export function HumanizedRouterDescription({
    router,
}: {
    router: components["schemas"]["V1LbConfigRouter"];
}) {
    const ports = router.match.internal_ports;
    const domains = router.match.domains;
    const path = router.match.path;

    const containersInclude = router.match.containers?.include || [];
    const containersExclude = router.match.containers?.exclude || [];

    const input: { type: "container"; id: string }[] = [
        ...containersInclude,
        ...containersExclude,
    ]
        .filter((c) => {
            return !!c.match(new RegExp("^[a-f0-9]{24}$"));
        })
        .map((c) => ({ type: "container", id: c }));

    const { data: components, isLoading } = useLookupComponentsQuery({
        body: {
            components: input,
        },
    });

    const containers = components?.data as
        | Record<string, Container>
        | undefined;

    const PortsString = () => {
        if (!ports || !ports.length) {
            return <>All ingress traffic</>;
        }

        return (
            <>
                All ingress traffic to containers listening on port
                {ports.length > 1 ? "s" : ""}{" "}
                <strong>{ports.join(", ")}</strong>
            </>
        );
    };

    const DomainsString = () => {
        if (!domains || !domains.length) {
            return <></>;
        }

        return (
            <>
                , for <strong>{domains.join(", ")}</strong>
            </>
        );
    };

    const PathString = () => {
        if (!path || path === "") {
            return <></>;
        }
        return (
            <>
                , matching path <strong>{path}</strong>
            </>
        );
    };

    const ContainersString = () => {
        if (!containersInclude?.length && !containersExclude?.length) {
            return <></>;
        }

        const includedContainers = containersInclude.map(
            (c) => containers?.[c]?.name || c
        );

        const excludedContainers = containersExclude.map(
            (c) => containers?.[c]?.name || c
        );

        const IncludeString = () => {
            if (isLoading) {
                return (
                    <span>
                        {includedContainers?.map((_) => (
                            <div
                                className={classNames(
                                    skeletonStyles,
                                    "mr-1 mt-1 inline-block h-[1rem] w-[4rem]"
                                )}
                            />
                        ))}
                    </span>
                );
            }
            return <strong>{includedContainers?.join(", ")}</strong>;
        };

        const ExcludeString = () => {
            if (isLoading) {
                return (
                    <span>
                        {excludedContainers?.map((_) => (
                            <div
                                className={classNames(
                                    skeletonStyles,
                                    "mr-1 mt-1 inline-block h-[1rem] w-[4rem]"
                                )}
                            />
                        ))}
                    </span>
                );
            }
            return <strong>{excludedContainers?.join(", ")}</strong>;
        };

        return (
            <>
                {!!includedContainers?.length && (
                    <>
                        {`, including container${
                            includedContainers.length > 1 ? "s" : ""
                        }`}{" "}
                        <IncludeString />
                    </>
                )}
                {!!excludedContainers?.length && (
                    <>
                        {`, excluding container${
                            excludedContainers.length > 1 ? "s" : ""
                        }`}{" "}
                        <ExcludeString />
                    </>
                )}
            </>
        );
    };

    return (
        <span>
            <PortsString />
            <DomainsString />
            <PathString />
            <ContainersString />
        </span>
    );
}
