import { Activity, ComponentIncludes } from "~/services/cycle";

export function extractComponent<T extends ComponentIncludes[string]>(
    component: Activity["component"],
    components: ComponentIncludes
): T | undefined {
    if (!component) {
        return undefined;
    }
    return components?.[component.id] as T;
}

export function isErroredActivity(a: Activity) {
    return a.error !== null;
}
