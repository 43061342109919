import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { useNavigate } from "react-router-dom";
import graphic from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";
import { FormField } from "@cycleplatform/ui/components/forms";
import { clearDialogParams, isDialogOpen } from "../../helpers";
import { CreateGroupForm } from "~/components/infrastructure/form/autoScaling/CreateGroupForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function AutoScalingGroupCreateDialog() {
    const nav = useNavigate();

    return (
        <StyledFormDialog
            title="Create Auto-Scaling Group"
            isOpen={isDialogOpen("autoscaling-group-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-2/3">
                <CreateGroupForm />
            </DialogColumn>
            <DialogColumn className="w-1/3">
                <img className="max-w-xs" src={graphic} />
                <div className="mt-4 text-lg">
                    <h3>Add an Auto-Scaling Group</h3>
                    <div className="pb-4 text-sm">
                        An auto-scaling group is a pre-configured list of
                        servers that can be deployed during an auto-scale event
                        issued by a container. Containers may opt-in to an
                        auto-scale group so that in the event more resources are
                        needed, they can automatically scale up and down
                        infrastructure to support increased workloads.
                    </div>

                    <FormField label="learn more:">
                        <ul>
                            <li>
                                <a
                                    className="text-cycle-blue text-sm"
                                    href="https://cycle.io/docs/platform/scaling-and-auto-scaling"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div>
                                        Documentation: Auto-Scaling Groups
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </FormField>
                </div>
            </DialogColumn>
        </StyledFormDialog>
    );
}
