import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { RuntimeVariablesSection } from "./runtimeVariables/RuntimeVariablesSection";
import { PipelineStagesSection } from "./stages/PipelineStagesSection";
import { useContext } from "react";
import { PipelineRunDialogContext } from "../context";

export function PipelineRunDashboard() {
    const { run } = useContext(PipelineRunDialogContext);

    return (
        <DialogPageBody>
            <PageContent>
                <PipelineStagesSection />
            </PageContent>
            {run && Object.keys(run?.variables || {}).length ? (
                <PageAside className="!w-1/3">
                    <RuntimeVariablesSection />
                </PageAside>
            ) : null}
        </DialogPageBody>
    );
}
