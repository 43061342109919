import { components } from "@cycleplatform/core/modules/api/__generated";
import classNames from "classnames";

type ServerInfoProps = {
    providerServer: components["schemas"]["ProviderServerModel"];
    className?: string;
};

export function ServerInfo({ providerServer, className }: ServerInfoProps) {
    return (
        <div className={classNames("flex w-full  text-sm", className)}>
            <div className="flex flex-col">
                <div>
                    <strong>
                        {providerServer.specs.memory.size_gb}
                        GB
                    </strong>{" "}
                    RAM
                </div>

                <div>
                    <div>
                        {providerServer.specs.cpu.threads ? (
                            <div>
                                <strong>
                                    {providerServer.specs.cpu.count}x
                                </strong>{" "}
                                {providerServer.specs.cpu.type} (
                                {providerServer.specs.cpu.threads} vCPUs)
                            </div>
                        ) : providerServer.specs.cpu.cores ? (
                            <div>
                                <strong>
                                    {providerServer.specs.cpu.count}x
                                </strong>{" "}
                                {providerServer.specs.cpu.type} (
                                {providerServer.specs.cpu.cores} cores)
                            </div>
                        ) : (
                            <div>
                                <strong>
                                    {providerServer.specs.cpu.count}x
                                </strong>{" "}
                                {providerServer.specs.cpu.type}
                            </div>
                        )}
                    </div>
                </div>

                <div className="cursor-pointer ">
                    {providerServer.specs.storage.map((v, i) => {
                        return (
                            <div className="flex gap-2" key={v.type + i}>
                                <div>
                                    {v.count || v.size_gb ? (
                                        <strong>
                                            {v.count ? `${v.count}x` : ""}{" "}
                                            {v.size_gb}
                                            GB
                                        </strong>
                                    ) : null}{" "}
                                    {v.type}
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div>
                    {providerServer.specs.network.map((v, i) => (
                        <div key={v.throughput + i}>
                            {v.scope ? (
                                <>
                                    <strong>{v.scope}</strong> - {v.type} (
                                    {v.throughput})
                                </>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
