import {
    FormSectionHeader,
    FormSection,
    RhfFormField,
    Checkbox,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";

export function RootFsSection() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <FormSectionHeader header="RootFS" />
            <FormSection>
                <RhfFormField
                    label="Read-Only"
                    name="config.runtime.rootfs.readonly"
                    help=" Enabling this option will set the containers
                    filesystem to readonly. Volumes associated
                    with the container will not be affected by
                    this."
                >
                    <Checkbox {...register("config.runtime.rootfs.readonly")} />
                </RhfFormField>
            </FormSection>
        </>
    );
}
