import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import {
    Environment,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import { LookupDataPoint, buildRecentLookupsQuery } from "./query";
import {
    Cell,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import {
    pieProps,
    pieCenterTextProps,
} from "@cycleplatform/ui/components/recharts/pie";
import { getAbbreviatedNumberString } from "@cycleplatform/core/util/number";
import { CyclePieTooltip } from "@cycleplatform/ui/components/recharts/pie/CyclePieTooltip";
import { brandColors } from "tailwindcss-config/colors";
import { ExternalLink } from "~/components/common/links";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type RecentLookupsTableProps = {
    environment: Environment | undefined;
};

export function RecentLookupsTable({ environment }: RecentLookupsTableProps) {
    const { data: lookups, error } = useGenerateAggregatedMetricsQuery(
        buildRecentLookupsQuery(environment?.id || ""),
        { skip: !environment, pollingInterval: AGGREGATE_POLLING_MS }
    );

    if (error) {
        throw error;
    }

    const points = lookups?.data as LookupDataPoint[] | undefined;

    if (points?.length === 0) {
        return (
            <div className="p-4 text-center">
                <h3 className="mb-4 font-bold">No Recent Lookups</h3>
                <p>
                    When a container instance does a DNS lookup, it will show up
                    here.
                </p>
            </div>
        );
    }

    return (
        <StyledDataTable>
            <StyledTableHead>
                <StyledHeaderCell className="w-6/12">Domain</StyledHeaderCell>
                <StyledHeaderCell className="w-2/12 text-center">
                    Question
                </StyledHeaderCell>
                <StyledHeaderCell className="w-4/12 text-center"></StyledHeaderCell>
            </StyledTableHead>
            <tbody>
                {points?.map((p) => (
                    <LookupRow key={`${p.domain}-${p.question}`} point={p} />
                ))}
            </tbody>
        </StyledDataTable>
    );
}

function LookupRow({ point }: { point: LookupDataPoint }) {
    const data = [
        {
            name: "cached",
            value: point.cached,
            color: brandColors["cycle-blue"].DEFAULT,
        },
        {
            name: "forwarded",
            value: point.forwarded,
            color: brandColors["success"].DEFAULT,
        },
        {
            name: "not_found",
            value: point.not_found,
            color: brandColors["error"].DEFAULT,
        },
        {
            name: "throttled_hits",
            value: point.throttled_hits,
            color: brandColors["warning"].DEFAULT,
        },
    ];
    return (
        <StyledTableRow key={point.domain}>
            <StyledCell className="max-w-[15rem]">
                <Tooltip message={point.domain} className="truncate">
                    <ExternalLink to={`//${point.domain}`}>
                        {point.domain}
                    </ExternalLink>
                </Tooltip>
            </StyledCell>
            <StyledCell className="h-16 text-center">
                {point.question}
            </StyledCell>
            <StyledCell className="h-16 min-w-[10rem] text-center">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart margin={{ top: 1, bottom: 1 }}>
                        <Pie {...pieProps} data={data} dataKey="value">
                            {data.map((entry, index) => {
                                return (
                                    <Cell
                                        className="cursor-pointer"
                                        key={`cell-${index}`}
                                        stroke="rgba(0,0,0,0)"
                                        fill={entry.color}
                                    />
                                );
                            })}
                        </Pie>
                        <text {...pieCenterTextProps}>
                            {getAbbreviatedNumberString(point.total_lookups)}
                        </text>

                        <RechartsTooltip
                            reverseDirection={{ x: true, y: true }}
                            allowEscapeViewBox={{ x: true, y: true }}
                            offset={-5}
                            content={(props) => (
                                <CyclePieTooltip
                                    {...props}
                                    dataLabel="name"
                                    dataValue="value"
                                />
                            )}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </StyledCell>
        </StyledTableRow>
    );
}
