import { getHumanizedDuration } from "@cycleplatform/core/util";
import { differenceInMilliseconds } from "date-fns";
import { PipelineRunStep } from "~/services/cycle";

export function PipelineStepDuration({ step }: { step: PipelineRunStep }) {
    const diff = differenceInMilliseconds(
        new Date(step.events.finished),
        new Date(step.events.started)
    );

    if (diff <= 0) {
        return null;
    }

    return <div className="text-sm">{getHumanizedDuration(diff, "ms")}</div>;
}
