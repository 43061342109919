import { Controller, useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import {
    RhfDataSizeInput,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";

export function RamThreshold({ thresholdIdx }: { thresholdIdx: number }) {
    const { control } = useFormContext<ContainerConfigFormData>();

    return (
        <div>
            <RhfFormField label="usage" name="usage">
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <RhfDataSizeInput {...field} />
                    )}
                    control={control}
                    name={`config.scale.thresholds.${thresholdIdx}.details.usage`}
                />
            </RhfFormField>
        </div>
    );
}
