import classNames from "classnames";
import { FC } from "react";
import { Badge } from "../../badges/Badge";
import { skeletonStyles } from "../../loaders/skeleton/skeletonStyle";
import { ResourceStateIcon, StateIconEntry } from "./ResourceStateIcon";
import { Tooltip } from "../../tooltip";
import { ResourceStateFull, getStateTooltip } from "./ResourceState";

type ResourceStateBadgeProps = {
    /** State of the resource. If ommitted, a placeholder will render. */
    state?: ResourceStateFull;
    tooltip?: React.ReactNode;
    className?: string;
    // Override state icons for specific current states - must provide full StateIconEntry
    stateIconOverride?: Record<string, StateIconEntry>;
};

export const ResourceStateBadge: FC<ResourceStateBadgeProps> = ({
    state,
    stateIconOverride,
    tooltip,
    className,
}) => {
    return (
        <Tooltip message={tooltip || getStateTooltip(state)}>
            <Badge
                className={classNames(
                    className,
                    !state && skeletonStyles,
                    "dark:bg-cycle-gray gap-2 whitespace-nowrap leading-4"
                )}
            >
                <ResourceStateIcon
                    stateIconOverride={stateIconOverride}
                    state={state || { current: "new", changed: "" }}
                />
                <span className="text-xs">{state?.current}</span>
            </Badge>
        </Tooltip>
    );
};
