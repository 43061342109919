import React from "react";
import {
    faQuestionCircle,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity } from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatBillingOrderMessage(item: Activity) {
    switch (item.event) {
        case "billing.order.task.confirm":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested confirmation of order #{item.component?.id}
                    </span>
                ) : (
                    <span>
                        Error requesting confirmation of order #
                        {item.component?.id}
                    </span>
                ),
                icon: faCheckCircle,
            };
        case "billing.order.confirm":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Confirmed order #{item.component?.id}</span>
                ) : (
                    <span>Error confirming order #{item.component?.id}</span>
                ),
                icon: faCheckCircle,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
