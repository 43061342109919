import { Duration, MonitoringTierDetails } from "~/services/cycle";
import { getHumanizedDuration, parseDuration } from "@cycleplatform/core/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";

export function FormattedDetails({ tier }: { tier: MonitoringTierDetails }) {
    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-1 ">
            <MonitoringTierFeatures tier={tier} />
            <MonitoringTierDurationDetails tier={tier} />
        </div>
    );
}

export function MonitoringTierFeatures({
    tier,
}: {
    tier: MonitoringTierDetails;
}) {
    return (
        <div>
            <FeatureSupportedDetail
                isFeatureSupported={tier.logs.analysis}
                label="Log Analysis"
            />
            <FeatureSupportedDetail
                isFeatureSupported={tier.logs.aggregation}
                label="Log Aggregation"
            />
            <FeatureSupportedDetail
                isFeatureSupported={tier.features.public_ping_monitor}
                label="Public Ping Monitor"
            />
            <FeatureSupportedDetail
                isFeatureSupported={tier.logs.custom}
                label="Custom Logs"
            />
            <FeatureSupportedDetail
                isFeatureSupported={tier.events.custom}
                label="Custom Events"
            />
            <FeatureSupportedDetail
                isFeatureSupported={tier.metrics.custom}
                label="Custom Metrics"
            />
        </div>
    );
}

export function MonitoringTierDurationDetails({
    tier,
}: {
    tier: MonitoringTierDetails;
}) {
    return (
        <div>
            <FeatureDurationDetail
                duration={tier.metrics.service_granularity}
                label="Service Granularity"
            />
            <FeatureDurationDetail
                duration={tier.metrics.container_telemetry_granularity}
                label="Container Granularity"
            />
            <FeatureDurationDetail
                duration={tier.metrics.retention_period}
                label="Metrics Retention"
            />
            <FeatureDurationDetail
                duration={tier.metrics.downsample_period}
                label="Downsample Period"
            />
            <FeatureDurationDetail
                duration={tier.events.retention_period}
                label="Events Retention"
            />
        </div>
    );
}

export function FeatureSupportedDetail({
    isFeatureSupported,
    label,
}: {
    isFeatureSupported: boolean;
    label: string;
}) {
    return (
        <div className="flex items-center gap-1">
            <div className="w-[3rem]">
                <IsFeatureSupportedIcon
                    isFeatureSupported={isFeatureSupported}
                />
            </div>
            {label}
        </div>
    );
}

export function FeatureDurationDetail({
    duration,
    label,
}: {
    duration: Duration;
    label: string;
}) {
    const parsedDuration = parseDuration(duration);

    if (typeof parsedDuration === "number") {
        return (
            <div className="flex w-full items-center gap-1 ">
                <div className="w-[3rem]">
                    <strong>{`${getHumanizedDuration(
                        parsedDuration
                    )} `}</strong>
                </div>
                <p>{label}</p>
            </div>
        );
    }
    return null;
}

export function IsFeatureSupportedIcon({
    isFeatureSupported,
}: {
    isFeatureSupported: boolean;
}) {
    if (isFeatureSupported) {
        return (
            <FontAwesomeIcon icon={faCheckCircle} className="text-cycle-blue" />
        );
    } else {
        return (
            <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-cycle-gray group-hover/option:text-cycle-gray-dark"
            />
        );
    }
}
