/*
 * Return the given number as a formatted string.  The default format is a plain
 * integer with thousands-separator commas.  The optional parameters facilitate
 * other formats:
 *   - decimals = the number of decimals places to round to and show
 *   - valueIfNaN = the value to show for non-numeric input
 *   - style
 *     - '%': multiplies by 100 and appends a percent symbol
 *     - '$': prepends a dollar sign
 *   - useOrderSuffix = whether to use suffixes like k for 1,000, etc.
 *   - orderSuffixes = the list of suffixes to use
 *   - minOrder and maxOrder allow the order to be constrained.  Examples:
 *     - minOrder = 1 means the k suffix should be used for numbers < 1,000
 *     - maxOrder = 1 means the k suffix should be used for numbers >= 1,000,000
 */
export function formatNumber(
    number: number,
    {
        decimals = 0,
        valueIfNaN = "",
        style = "",
        useOrderSuffix = false,
        orderSuffixes = ["", "k", "M", "B", "T"],
        minOrder = 0,
        maxOrder = Infinity,
    } = {}
) {
    let x = number;

    if (isNaN(x)) return valueIfNaN;

    if (style === "%") x *= 100.0;

    let order;
    if (!isFinite(x) || !useOrderSuffix) order = 0;
    else if (minOrder === maxOrder) order = minOrder;
    else {
        const unboundedOrder = Math.floor(Math.log10(Math.abs(x)) / 3);
        order = Math.max(
            0,
            minOrder,
            Math.min(unboundedOrder, maxOrder, orderSuffixes.length - 1)
        );
    }

    const orderSuffix = orderSuffixes[order];
    if (order !== 0) x /= Math.pow(10, order * 3);

    return (
        (style === "$" ? "$" : "") +
        x.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }) +
        orderSuffix +
        (style === "%" ? "%" : "")
    );
}
/**
 * Accepts a number and returns an abbreviated humanized string
 * @param val is any number
 * @returns A humanized version of the number.  1,100 becomes 1.1k. 1,100,000 becomes 1.1m.
 */
export function getAbbreviatedNumberString(val: number) {
    if (val / 1_000_000 > 1) {
        return `${round(val / 1_000_000, 1)}m`;
    }

    if (val / 1000 > 1) {
        return `${round(val / 1_000, 1)}k`;
    }

    return val.toString();
}

export function round(value: number, precision: number) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function getOrdinalNum(n: number) {
    return (
        n +
        (n > 0
            ? ["th", "st", "nd", "rd"][
                  (n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10
              ]
            : "")
    );
}
