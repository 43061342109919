import {
    RhfFormField,
    TextInput,
    hasNoSpaces,
    isUrl,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";

export function WebhooksIntegration() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <div className="flex flex-col gap-2">
            <RhfFormField
                label="Start"
                name="config.integrations.webhooks.events.start"
            >
                <TextInput
                    {...register("config.integrations.webhooks.events.start", {
                        validate: {
                            ...isUrl("Webhook must be a URL"),
                            ...hasNoSpaces(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="Stop"
                name="config.integrations.webhooks.events.stop"
            >
                <TextInput
                    {...register("config.integrations.webhooks.events.stop", {
                        validate: {
                            ...isUrl("Webhook must be a URL"),
                            ...hasNoSpaces(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="Deploy"
                name="config.integrations.webhooks.events.deploy"
            >
                <TextInput
                    {...register("config.integrations.webhooks.events.deploy", {
                        validate: {
                            ...isUrl("Webhook must be a URL"),
                            ...hasNoSpaces(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="Config"
                name="config.integrations.webhooks.config"
            >
                <TextInput
                    {...register("config.integrations.webhooks.config", {
                        validate: {
                            ...isUrl("Webhook must be a URL"),
                            ...hasNoSpaces(),
                        },
                    })}
                />
            </RhfFormField>
        </div>
    );
}
