/**
 *
 * @param length represents the number of characters the random string should have
 * @returns A random alphanumeric string of the provided length
 */
export function generateRandomString(length: number): string {
    return Array.from({ length }, () =>
        Math.random().toString(36).charAt(2)
    ).join("");
}
