import * as Sentry from "@sentry/browser";
import { isFetchBaseQueryError } from "~/services/helpers";

export const sentryLog = (err: unknown) => {
    if (
        err &&
        isFetchBaseQueryError(err) &&
        typeof err?.status === "number" &&
        err.status >= 500
    ) {
        return Sentry.captureException(err);
    }
};
