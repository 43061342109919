import { Container, LogLine, useAggregateLogsMutation } from "~/services/cycle";
import {
    LoaderButton,
    PopoverButtonContext,
} from "@cycleplatform/ui/components/buttons";
import {
    FormProvider,
    useForm,
    useFormContext,
    useWatch,
} from "react-hook-form";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { faGear, faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { Popover } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";
import classNames from "classnames";
import ReactDatePicker from "react-datepicker";
import { subHours } from "date-fns";
import { convertLogsSearchToQuery, SearchLogsForm } from "./helpers";
import { AccessControlOverlay } from "~/components/common/buttons";

export function FetchLogsForm({
    container,
    setLogs,
}: {
    container?: Container;
    setLogs: (logs: LogLine[]) => void;
}) {
    const form = useForm<SearchLogsForm>({
        defaultValues: {
            limit: 1000,
            isRegex: false,
            dateRange: {
                start: subHours(new Date(), 72),
                end: new Date(),
            },
            search: "",
        },
    });

    const { register } = form;
    const dispatch = useAppDispatch();
    const [aggregateLogs, { isLoading }] = useAggregateLogsMutation();

    const onSubmit = (data: SearchLogsForm) => {
        return aggregateLogs({
            body: convertLogsSearchToQuery({ container, form: data }),
        })
            .unwrap()
            .then((res) => {
                if (res.data === null) {
                    setLogs?.([]);
                } else {
                    setLogs?.(res.data);
                }
            })
            .catch((err) => {
                dispatch(
                    pushNotification({
                        title: `Error generating logs for container ${container?.name}`,
                        message: `${err?.data?.error?.title}`,
                        type: "error",
                        icon: "containers",
                    })
                );
            });
    };

    return (
        <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-1/2 font-normal normal-case"
        >
            <FormProvider {...form}>
                <div className={classNames("group/logs-search")}>
                    <div
                        className={classNames(
                            "relative flex w-full gap-0 rounded-md",
                            "group-focus-within/logs-search:ring-cycle-blue group-focus-within/logs-search:border-transparent group-focus-within/logs-search:ring-2"
                        )}
                    >
                        <TextInput
                            className={classNames(" rounded-r-none")}
                            placeholder="Enter a search term (optional)"
                            {...register("search")}
                        />
                        <AccessControlOverlay
                            capability={"monitor-view"}
                            className="rounded-l-none"
                        >
                            <LoaderButton
                                isLoading={isLoading}
                                type="submit"
                                flavor="primary"
                                className={classNames(
                                    "h-[40px] rounded-l-none"
                                )}
                                icon={faMagnifyingGlass}
                            >
                                Search
                            </LoaderButton>
                        </AccessControlOverlay>
                        <AdvancedConfigOptions />
                    </div>
                </div>
            </FormProvider>
        </form>
    );
}

function AdvancedConfigOptions() {
    const { register, setValue, control } = useFormContext<SearchLogsForm>();

    const { refs, floatingStyles } = useFloating({
        placement: "bottom-end",
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    // https://github.com/Hacker0x01/react-datepicker/issues/3834#issuecomment-1451662259
    const DatePicker =
        (ReactDatePicker as unknown as { default: typeof ReactDatePicker })
            .default ?? ReactDatePicker;

    const startDate = useWatch({ name: "dateRange.start", control });
    const endDate = useWatch({ name: "dateRange.end", control });

    return (
        <div className={classNames("absolute right-32 top-0 flex ")}>
            <Popover className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button
                            ref={refs.setReference}
                            className="h-full"
                        >
                            <button
                                className={classNames(
                                    "text-cycle-blue-accent z-50 !h-[40px] rounded-none"
                                )}
                            >
                                <FontAwesomeIcon icon={faGear} />
                            </button>
                        </Popover.Button>
                        <PopoverButtonContext.Provider
                            value={{
                                isOpen: open,
                                close,
                            }}
                        >
                            <Popover.Panel
                                ref={refs.setFloating}
                                style={floatingStyles}
                                className={classNames(
                                    "dark:bg-cycle-gray dark:text-cycle-white animate-fade-in-fast a bg-cycle-white z-10 min-w-[25rem] rounded-lg stroke-1 p-4 shadow-lg"
                                )}
                            >
                                <FormField
                                    label="Date Range"
                                    help="Max range is 72 hours"
                                >
                                    <div className="flex justify-between gap-0">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) =>
                                                setValue(
                                                    "dateRange.start",
                                                    date
                                                )
                                            }
                                            name="startTime"
                                            placeholderText="Start"
                                            className="border-cycle-gray/20 rounded-md"
                                        />
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) =>
                                                setValue("dateRange.end", date)
                                            }
                                            name="endTime"
                                            placeholderText="End"
                                            className="border-cycle-gray/20 rounded-md"
                                        />
                                    </div>
                                </FormField>
                                <div className="flex gap-4">
                                    <RhfFormField label="limit" name="limit">
                                        <TextInput
                                            type="number"
                                            {...register("limit", {
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="RegEx"
                                        name="isRegex"
                                        className="!w-[8rem]"
                                    >
                                        <Checkbox {...register("isRegex")} />
                                    </RhfFormField>{" "}
                                </div>
                            </Popover.Panel>
                        </PopoverButtonContext.Provider>
                    </>
                )}
            </Popover>
        </div>
    );
}
