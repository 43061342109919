import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { ConfigurationRecords } from "~/components/dialogs/dns/zone-create/ConfigurationRecords";
import { DialogFormStepTypes } from "~/components/dialogs/dns/zone-create/types";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";

export function IsSubdomain() {
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();
    return (
        <>
            <FormSectionHeader header="Configure NS Records" />
            <FormSection>
                <p className="text-base">{`Before you're able to use your domain with Cycle, you'll need to login to your DNS provider and create the following NS records for this subdomain:`}</p>

                <ConfigurationRecords
                    isHosted={true}
                    isAuthoritative={true}
                    origin={values[0].origin}
                />
            </FormSection>
        </>
    );
}
