import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    CreateClusterApiArg,
    useCreateClusterMutation,
} from "~/services/cycle";
import { useNavigate } from "react-router-dom";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { clearDialogParams } from "../../helpers";
import {
    AclForm,
    ResourceAclCreate,
    createAclSubmitBody,
    getAclDefaultValues,
} from "~/components/common/acl";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type CreateClusterFormType = Omit<CreateClusterApiArg["body"], "acl"> & {
    acl: AclForm;
};
export function CreateClusterForm() {
    const nav = useNavigate();
    const form = useForm<CreateClusterFormType>({
        defaultValues: {
            acl: getAclDefaultValues(undefined),
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = form;

    const [createCluster] = useCreateClusterMutation();

    const onSubmit = (data: CreateClusterFormType) => {
        const submitBody = {
            ...data,
            ...createAclSubmitBody(data.acl, "create"),
        };
        return createCluster({ body: submitBody })
            .unwrap()
            .then(() => nav(clearDialogParams()), handleSubmitError(form.setError));
    };
    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <RhfIdentifierInput name="identifier" label="identifier" required />
            <ResourceAclCreate resourceType="cluster" />

            <DialogFooter className="justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    isLoading={isSubmitting}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    icon={faPlus}
                >
                    Create Cluster
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
