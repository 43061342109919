import { $error, $info, $trace, $warn } from "@cycleplatform/core/util/log";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
    selectTrackedJobs,
    stateIntentToTrackJob,
    TrackedJob,
    trackJob as trackJobAction,
} from "./slice";

/**
 * A hook to be used when it's necessary for the component to be updated as a job
 * progresses. Useful for showing loading states that persist for the entirety of a job,
 * or for progress meters as a job completes its individual steps.
 */
export function useJobTracker(jobMatch?: {
    resourceId: string;
    header: string;
}) {
    const dispatch = useAppDispatch();
    const trackedJobs = useAppSelector(selectTrackedJobs);
    const [jobId, setJobId] = useState<string>();
    const [trackingState, setTrackingState] = useState<{
        tracking: boolean;
        resourceId?: string;
    }>({ tracking: false, resourceId: undefined });

    const promise = useRef<{
        res: (v: TrackedJob) => void;
        rej: (err: unknown) => void;
    }>();

    const removeTracker = useCallback(() => {
        promise.current = undefined;
        if (trackingState) {
            setTrackingState({ tracking: false });
        }
        if (!jobId) {
            return;
        }
        setJobId(undefined);

        $trace(`removing job tracker ${jobId} `);
    }, [promise.current, dispatch, jobId]);

    useEffect(() => {
        if (!jobMatch || !!promise.current) {
            return;
        }

        const match = Object.entries(trackedJobs)?.find(([id, j]) => {
            return (
                j.header === jobMatch.header &&
                j.resourceId === jobMatch.resourceId
            );
        });

        if (jobMatch) {
            if (!!match) {
                trackJob(
                    Promise.resolve({
                        data: {
                            job: {
                                id: match[0],
                            },
                        },
                    })
                );
            }
        }
    }, [jobMatch?.resourceId, trackedJobs]);

    useEffect(() => {
        if (!jobId) {
            return;
        }
        const tracked = trackedJobs[jobId];

        if (!tracked) {
            removeTracker();
            return;
        }

        if (tracked.error) {
            promise.current?.rej(new Error(tracked.error));
            $error(`tracked job ${jobId} failed - ${tracked.error}`);
            removeTracker();
            return;
        }
        if (tracked.state === "completed") {
            $info(`tracked job ${jobId} was completed`);
            promise.current?.res(tracked);
            removeTracker();
            return;
        }
    }, [jobId, trackedJobs]);

    /**
     * Initiates a job tracker
     * @param taskPromise A promise that may contain a task descriptor object from
     * creating a job
     * @returns a promise that will resolve when the job is complete, or reject when the job fails.
     * A job is failed if its state contains an error, or TODO its state is expired
     */

    type TaskPromiseType = Promise<{ data?: { job?: { id: string } } }>;
    const trackJob = async (
        taskPromise: TaskPromiseType,
        options?: {
            shouldTrackPercent?: boolean;
        }
    ): Promise<TrackedJob> => {
        // Begin 'tracking' from the moment we try to create the job
        // (useful for loading)
        setTrackingState({ tracking: true, resourceId: jobMatch?.resourceId });
        dispatch(stateIntentToTrackJob());

        try {
            const task = await taskPromise;

            if (!task?.data) {
                $warn("Attempting to track job, but no data was received!");
                removeTracker();
                return Promise.reject(task);
            }

            if (!task?.data?.job?.id) {
                return Promise.reject();
            }

            dispatch(
                trackJobAction({
                    jobId: task.data.job.id,
                    shouldTrackPercent: options?.shouldTrackPercent,
                    resourceId: jobMatch?.resourceId,
                    header: jobMatch?.header,
                })
            );

            setJobId(task.data.job.id);

            const trackerPromise = new Promise((res, rej) => {
                promise.current = { res, rej };
            });

            return trackerPromise as Promise<TrackedJob>;
        } catch (e) {
            // likely 422 error submitting job
            $warn("failed creating job");
            setTrackingState({ tracking: false });
            return Promise.reject(e);
        }
    };

    return [
        trackJob,
        {
            isTrackingJob: jobMatch?.resourceId
                ? trackingState.tracking &&
                  trackingState.resourceId === jobMatch?.resourceId
                : trackingState.tracking,
        },
    ] as const;
}
