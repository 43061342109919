import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import {
    RhfFormField,
    RhfSliderInput,
} from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";

export function CpuThreshold({ thresholdIdx }: { thresholdIdx: number }) {
    const { register, watch } = useFormContext<ContainerConfigFormData>();
    const cpuLimits = watch(`config.resources.cpu`);
    const isPrereqMet =
        cpuLimits?.shares !== undefined || cpuLimits?.cpus !== undefined;
    return (
        <div>
            {!isPrereqMet ? (
                <InfoPanel className="!mb-4" type="warning">
                    CPU thresholds are only available on containers with CPU
                    resource limits set.
                </InfoPanel>
            ) : null}
            <RhfFormField label="utilization %" name="utilization">
                <RhfSliderInput
                    min={50}
                    unit="%"
                    numTicks={5}
                    {...register(
                        `config.scale.thresholds.${thresholdIdx}.details.utilization`,
                        { valueAsNumber: true }
                    )}
                />
            </RhfFormField>
        </div>
    );
}
