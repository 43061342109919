import { Button } from "@cycleplatform/ui/components/buttons";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import classNames from "classnames";
import {
    Integration,
    ProviderLocation,
    useGetIntegrationsQuery,
    useGetProviderLocationsQuery,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IntegrationLogo } from "@cycleplatform/ui/components/resources";
import { useAppSelector } from "~/hooks";
import { pushUnique } from "@cycleplatform/core/util";
import { CategoryFilter } from "../../ui/CategoryFilter";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import graphic from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";
import { ResourceComboBox } from "~/components/common/forms";
import {
    FormField,
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { NavIcons } from "~/components/layout/NavIcons";
import { useSetServerParams } from "../../helpers/hooks";
import { useState } from "react";
import { selectAppliedTheme } from "~/modules/settings";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

type SelectProviderStepProps = {
    locationId?: string;
    integrationId?: string;
    locations?: ProviderLocation[];
};

export function SelectProviderStep({
    locationId,
    locations,
    integrationId,
}: SelectProviderStepProps) {
    const { data: integrations, error } = useGetIntegrationsQuery({
        filter: {
            category: "infrastructure-provider",
        },
    });

    if (error) {
        throw error;
    }

    const categories = getLocationCategories(locations || []);
    const [category, setCategory] = useState<string | null>("all");
    const filteredProviderLocations = locations?.filter((l) =>
        category === "all" ? true : l.geographic?.region === category
    );

    const sortedProviderLocations = filteredProviderLocations?.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const setServerParams = useSetServerParams();

    return (
        <div className="flex h-full gap-8">
            <DialogColumn className="flex h-full w-2/3 flex-col justify-between">
                <div>
                    <FormSectionHeader header="Provider" />
                    <FormSection>
                        <div className="grid grid-cols-4 gap-4 pb-8">
                            {integrations?.data.length
                                ? [...integrations.data]
                                      .sort((a) =>
                                          a.vendor === "abstraction" ? 1 : 0
                                      )
                                      .map((i) => (
                                          <ProviderButton
                                              key={i.id}
                                              integration={i}
                                              isSelected={
                                                  i.id === integrationId
                                              }
                                          />
                                      ))
                                : Array.from({
                                      length: 2,
                                  }).map((_, idx) => (
                                      <div
                                          key={idx}
                                          className={classNames(
                                              getOptionButtonClassName(),
                                              skeletonStyles,
                                              "!outline-none !ring-0"
                                          )}
                                      >
                                          <div className="h-14" />
                                      </div>
                                  ))}
                        </div>
                    </FormSection>

                    <FormSectionHeader header="Location" />
                    <FormSection>
                        <FormField required label="location">
                            <div className="flex gap-2">
                                <CategoryFilter
                                    active={category}
                                    setCategory={setCategory}
                                    categories={categories}
                                    additionalOnClick={() =>
                                        setServerParams({ locationId: "" })
                                    }
                                />
                            </div>

                            <ResourceComboBox
                                onChange={(v) =>
                                    setServerParams({ locationId: v || "" })
                                }
                                value={locationId}
                                filterFields={["id", "name"]}
                                resources={sortedProviderLocations || []}
                                placeholder="Select a Location..."
                                disabled={!integrationId}
                                formatDisplayValue={(l) => {
                                    if (!l) {
                                        return "";
                                    }
                                    return l.name;
                                }}
                                formatOption={(l) => (
                                    <FormattedOption label={l.name}>
                                        <div className="flex">
                                            {l.features.supported.map((f) => (
                                                <div
                                                    key={f}
                                                    className={classNames(
                                                        "rounded-md px-2 py-1 text-sm",
                                                        "bg-cycle-gray-light text-cycle-black",
                                                        "dark:bg-cycle-black-accent dark:text-cycle-white"
                                                    )}
                                                >
                                                    {f}
                                                </div>
                                            ))}
                                        </div>
                                    </FormattedOption>
                                )}
                            />
                        </FormField>
                    </FormSection>
                </div>

                <DialogFooter>
                    <Button
                        type="button"
                        onClick={() => setServerParams({ step: "1" })}
                        disabled={!locationId}
                    >
                        Next
                    </Button>
                </DialogFooter>
            </DialogColumn>
            <DialogColumn className="w-1/3 flex-col items-center ">
                <img className="max-w-[16rem]" src={graphic} />
                <div className="mt-4 text-lg">
                    <h3>Select a Provider</h3>
                    <div className="text-sm">
                        Choose which configured provider and provider location
                        to deploy infrastructure to.
                    </div>
                </div>
            </DialogColumn>
        </div>
    );
}

function ProviderButton({
    integration,
    isSelected,
}: {
    integration: Integration;
    isSelected: boolean;
}) {
    const setParams = useSetServerParams();
    const theme = useAppSelector(selectAppliedTheme);

    return (
        <button
            key={integration.id}
            type="button"
            onClick={() =>
                setParams({ integrationId: integration.id, locationId: null })
            }
            className={classNames(getOptionButtonClassName(isSelected))}
        >
            <div className="flex items-center justify-center p-2 ">
                {integration.vendor !== "abstraction" ? (
                    <div className="h-10">
                        <IntegrationLogo
                            vendor={integration.vendor}
                            theme={theme}
                        />
                    </div>
                ) : (
                    <div className="flex h-10 items-center">
                        <FontAwesomeIcon
                            icon={NavIcons["infrastructureProviders"]}
                            className="pr-2 "
                        />
                        {integration.name}
                    </div>
                )}
            </div>
        </button>
    );
}

export const getLocationCategories = (locations: ProviderLocation[]) => {
    return locations
        .reduce<string[]>((acc, cur) => {
            if (cur.geographic?.region) {
                return pushUnique(acc, cur.geographic?.region);
            }

            return [];
        }, [])
        .sort();
};
