import { capitalizeEachWord } from "@cycleplatform/core/util";
import { GenericEvent } from "../util";

export function EventTitle({ event }: { event: GenericEvent }) {
    switch (event.event) {
        case "api.security_violation":
            return "API Security Violation";
        case "console.ssh.login":
            return "SSH Login Detected";
        case "console.ssh.login.failed":
            return "SSH Login Failed";
        case "console.sos.login":
            return "Login to Serial-Over-SSH Detected";
        case "console.sos.login.failed":
            return "Login to Serial-Over-SSH Failed";
        case "container.instance.backup.completed":
            return "Successful Backup";
        case "container.instance.backup.failed":
            return "Backup Failed";
        case "container.instance.delete.failed":
            return "Instance Delete Failed";
        case "container.instance.error":
            return "Instance Failed with Error";
        case "container.instance.function.max_runtime":
            return "Function Instance Reached Max Runtime";
        case "container.instance.healthcheck.failed":
            return "Instance Failed Healthcheck";
        case "container.instance.healthcheck.recovered":
            return "Instance Recovered After Failed Healthcheck";
        case "container.instance.healthcheck.restarted":
            return "Instance Failed Healthcheck - Restarted";
        case "container.instance.migration.failed":
            return "Instance Migration Failed";
        case "container.instance.migration.completed":
            return "Instance Migration Completed";
        case "container.instance.network.interfaces.create.failed":
            return "Failed to Create Network Interface";
        case "container.instance.restart.failed":
            return "Instance Restart Failed";
        case "container.instance.restart.max_restarts":
            return "Instance Reached Max Restarts";
        case "container.instance.start.failed":
            return "Instance Start Failed";
        case "container.instance.start.privileged":
            return "Privileged Instance Started";
        case "container.instance.stop.failed":
            return "Instance Failed to Stop";
        case "container.instances.autoscale.down":
            return "Container Auto-Scaled Down";
        case "container.instances.autoscale.up":
            return "Container Auto-Scaled Up";
        case "container.reconfigured.privileged":
            return "Privileged Container Reconfigured";
        case "container.instance.volume.extend.failed":
            return "Failed to Extend Volume";
        case "container.volumes.base.create.failed":
            return "Failed to Create Base Volume";
        case "container.volumes.create.failed":
            return "Failed to Create Volume";
        case "environment.service.auto_update":
            return "Service Auto-Updated";
        case "environment.service.lb.ips.sync.failed":
            return "Load Balancer Failed to Sync IPs";
        case "environment.service.vpn.login.failed":
            return "Failed VPN Login";
        case "infrastructure.cluster.resources.ram.full":
            return "Cluster RAM is Full";
        case "infrastructure.server.compute.full_restart":
            return "Server Compute Restarted";
        case "infrastructure.server.compute.sharedfs.mounts.mount":
            return "Mounted Shared File System";
        case "infrastructure.server.compute.sharedfs.mounts.mount.failed":
            return "Error Mounting Shared File System";
        case "infrastructure.server.compute.soft_restart":
            return "Server Compute Soft Restarted";
        case "infrastructure.server.compute.volumes.base.reconfigured":
            return "Server Base Volume Reconfigured";
        case "infrastructure.server.image.download.failed":
            return "Image Download Failed";
        case "infrastructure.server.internal_api.throttled":
            return "Server API Throttled";
        case "infrastructure.server.manifest.sync.failed":
            return "Server Sync Failed";
        case "infrastructure.server.mesh.connect.failed":
            return "Server Mesh Connection Failed";
        case "infrastructure.server.neighbor.reachable":
            return "Server Neighbor Connection Recovered";
        case "infrastructure.server.neighbor.rebuild":
            return "Server Neighbor Connection Rebuilding";
        case "infrastructure.server.neighbor.unreachable":
            return "Server Neighbor Unreachable";
        case "infrastructure.server.neighbor.upgraded":
            return "Server Neighbor Connection Upgraded";
        case "infrastructure.server.resources.load.high":
            return "High Server Load";
        case "infrastructure.server.resources.ram.full":
            return "Server RAM Full";
        case "infrastructure.server.resources.storage.cycle_pool.full":
            return "Server Storage Pool Full";
        case "infrastructure.server.resources.storage.volumes.base.full":
            return "Server Base Volume Full";
        case "infrastructure.server.sftp.login":
            return "SFTP Login Detected";
        case "infrastructure.server.sftp.login.failed":
            return "SFTP Login Failed";
        case "infrastructure.server.sftp.lockdown":
            return "SFTP Lockdown";
        case "infrastructure.server.evacuation.completed":
            return "Server Successfully Evacuated";
        case "infrastructure.server.evacuation.failed":
            return "Server Failed to Evacuate";
        default:
            return `${capitalizeEachWord(event.text || "")}`;
    }
}
