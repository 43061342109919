import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import {
    Environment,
    GetLoadBalancerServiceApiResponse,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    LineChart,
    Line,
} from "recharts";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { buildLbTrafficQuery } from "./query";
import {
    DataPoint,
    generateCumulativeDiff,
} from "@cycleplatform/ui/components/recharts/util";
import { formatBytesString } from "@cycleplatform/core/util";
import { useEffect, useMemo, useState } from "react";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { isUsingCycleNativeLb } from "@cycleplatform/core/modules/environments/loadbalancer";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { faLineChart } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerTrafficReportChartProps = {
    className?: string;
    environment?: Environment | undefined;
    service: GetLoadBalancerServiceApiResponse["data"] | undefined;
    port: string | undefined;
};

export function LoadBalancerTrafficReportChart({
    className,
    environment,
    service,
    port,
}: LoadBalancerTrafficReportChartProps) {
    const { colors } = useGetThemedChartColors();
    const isNativeLb = service ? isUsingCycleNativeLb(service) : undefined;
    const isLbDisabled = environment?.services?.loadbalancer?.enable === false;

    const {
        data: telemetry,
        isLoading,
        isFetching,
        error,
    } = useGenerateAggregatedMetricsQuery(
        buildLbTrafficQuery(environment?.id, port),
        {
            skip: !environment?.id || !isNativeLb || isLbDisabled,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const [prevPort, setPrevPort] = useState<string | undefined>();
    const [isFetchingNewPort, setFetchingNewPort] = useState(false);

    useEffect(() => {
        if (prevPort === port && !isFetchingNewPort) {
            return;
        }
        setFetchingNewPort(isFetching);
        setPrevPort(port);
    }, [isFetching, port, prevPort]);

    const diffs = useMemo(
        () =>
            generateCumulativeDiff(telemetry?.data as DataPoint[] | undefined),
        [telemetry?.data]
    );

    if (error) {
        throw error;
    }

    if (isNativeLb === false) {
        return (
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={faLineChart}
                title="Not Using Native Load Balancer"
            >
                <p className="text-center">
                    Metrics are only available when using the Cycle Native Load
                    Balancer (v1). The type of load balancer for this
                    environment can be selected in the{" "}
                    <Link
                        to={generateDialogLink("environment-lb-manage", {
                            "dialog-env": environment?.id,
                        })}
                    >
                        load balancer management panel.
                    </Link>
                </p>
            </EmptyResource>
        );
    }

    if (isLbDisabled) {
        return (
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={faLineChart}
                title="Load Balancer Disabled"
            >
                <p className="text-center">
                    The load balancer is disabled for this environment. Once it
                    is enabled, Cycle will begin gathering metrics that will be
                    displayed here.
                </p>
                <ButtonLink
                    to={generateDialogLink("environment-lb-manage", {
                        "dialog-env": environment?.id,
                    })}
                >
                    Enable Load Balancer
                </ButtonLink>
            </EmptyResource>
        );
    }

    return (
        <div
            className={classNames(
                className,
                "h-full",
                (isLoading || isFetchingNewPort) && skeletonStyles
            )}
        >
            {telemetry?.data && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        {...CycleChartProps}
                        data={diffs}
                        syncId="lb-telem"
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            scale="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            tick={(props) => (
                                <FormattedYTick
                                    {...props}
                                    formatValue={(v: number) =>
                                        formatBytesString(v * 1024, 1)
                                    }
                                />
                            )}
                        />
                        <Tooltip
                            content={(props) => (
                                <CycleLineTooltip
                                    {...props}
                                    formatValue={(v) =>
                                        formatBytesString(v * 1000, 1)
                                    }
                                    labelMap={{
                                        received_kb: "received",
                                        transmitted_kb: "transmitted",
                                    }}
                                />
                            )}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="received_kb"
                            stroke={colors["blue"].DEFAULT}
                        />
                        <Line
                            {...CycleLineProps}
                            dataKey="transmitted_kb"
                            stroke={colors["green"].DEFAULT}
                        />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
