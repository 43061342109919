import {
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";

export function LogsLoadingFallback({ length = 8 }: { length?: number }) {
    return (
        <>
            {Array(length)
                .fill(undefined)
                .map((_, idx) => (
                    <div className="flex gap-8 p-4" key={`logs-loading-${idx}`}>
                        <div
                            className={classNames(
                                "flex !w-1/12 min-w-[5rem] justify-center",
                                skeletonStyles
                            )}
                        />
                        <div className="w-11/12">
                            <SkeletonHeader />
                            <SkeletonText />
                        </div>
                    </div>
                ))}
        </>
    );
}
