import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useGetStackBuildQuery, useGetStackQuery } from "~/services/cycle";
import { StackBuildImages } from "~/components/dialogs/stackBuilds/components/StackBuildImages";
import { StackBuildDashboard } from "~/components/dialogs/stackBuilds/components/StackBuildDashboard";
import { formatDateString } from "@cycleplatform/core/util/time";
import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "../helpers";
import { StackBuildDeploy } from "./components/stackBuildDeploy";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { ReadOnlyBadge } from "@cycleplatform/ui/components/badges";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type StackBuildDialogProps = {
    className?: string;
};

export function StackBuildDialog({ className }: StackBuildDialogProps) {
    const params = getAllDialogSearchParams<"stack-build">();
    const nav = useNavigate();

    const buildId = params["dialog-build-id"];
    const stackId = params["dialog-stack-id"];

    const { data: stack, error: stackError } = useGetStackQuery({
        stackId: stackId || "",
    });

    const { data: stackBuild, error: stackBuildError } = useGetStackBuildQuery({
        stackId: stackId || "",
        buildId: buildId || "",
    });

    const accessErr = useVerifyAccess(
        stack?.data,
        modifyAccessFn("stacks-builds-manage")
    );

    if (stackError) {
        throw stackError;
    }

    if (stackBuildError) {
        throw stackBuildError;
    }

    const buildIdentifier = stackBuild?.data
        ? formatDateString(
              stackBuild.data.events.created,
              "MMM dd yyyy hh:mm aaa"
          )
        : "Stack Build";

    return (
        <StyledResourceDialog
            open={isDialogOpen("stack-build") && !!buildId && !!stackId}
            onClose={() => nav(clearDialogParams())}
            className={classNames("relative z-50", className)}
            breadcrumb={[
                {
                    label: "Stacks",
                    link: "stacks",
                },
                {
                    label: stack?.data?.name || "",
                    link: `stacks/${stackId}`,
                },
                {
                    label: "Builds",
                    link: `stacks/${stackId}/builds`,
                },
                {
                    label: buildIdentifier,
                },
            ]}
            header={
                <div className="flex items-center gap-4">
                    <h1>{buildIdentifier}</h1>
                    {stackBuild?.data && (
                        <>
                            <ResourceStateBadge
                                className="!bg-cycle-gray !h-8"
                                state={stackBuild.data.state}
                            />
                        </>
                    )}
                    {!!accessErr ? (
                        <ReadOnlyBadge
                            className="!bg-cycle-gray !h-8"
                            state={stackBuild?.data?.state}
                        />
                    ) : null}
                </div>
            }
        >
            <ResourceDialogTab
                to={null}
                name="Dashboard"
                icon={NavIcons["altDash"]}
            >
                <StackBuildDashboard
                    stack={stack?.data}
                    build={stackBuild?.data}
                />
            </ResourceDialogTab>

            <ResourceDialogTab
                to="images"
                name="Images"
                icon={NavIcons["images"]}
            >
                <SectionDisabledControl
                    disabled={stackBuild?.data.state.current === "deleted"}
                    heightFull
                >
                    <StackBuildImages build={stackBuild?.data} />
                </SectionDisabledControl>
            </ResourceDialogTab>

            <ResourceDialogTab
                to="deploy"
                name="Deploy"
                icon={NavIcons["deploy"]}
            >
                <SectionDisabledControl
                    disabled={stackBuild?.data.state.current === "deleted"}
                    heightFull
                >
                    <StackBuildDeploy
                        stack={stack?.data}
                        build={stackBuild?.data}
                    />
                </SectionDisabledControl>
            </ResourceDialogTab>
        </StyledResourceDialog>
    );
}
