import { useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import { LoadBalancerFormValues } from "../../../../../form";
import { V1LbConfig, V1LbController } from "~/services/cycle";
import { Panel, PanelContent } from "@cycleplatform/ui/components/panels";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faEdit, faSliders } from "@fortawesome/pro-solid-svg-icons";
import { getActualTransportConfig } from "@cycleplatform/core/modules/environments/loadbalancer/v1";

export function V1ManagePlatformDefault({
    baseRoute,
}: {
    baseRoute: `config.details.controllers.${number}`;
}) {
    const { service } = useContext(LoadBalancerDialogContext);

    const { control, setValue, unregister } =
        useFormContext<LoadBalancerFormValues>();

    const controller = useWatch({
        control,
        name: `${baseRoute}`,
    });
    const transport = useWatch({
        control,
        name: `${baseRoute}.transport`,
    });

    const details = useWatch({
        control,
        name: "config.details",
    }) as V1LbConfig | null;

    return (
        <Panel>
            <PanelContent>
                <div className="flex w-full items-center justify-between">
                    <div>
                        {transport
                            ? "Using Custom Config"
                            : "Using Platform Defaults"}
                    </div>
                    {transport ? (
                        <Button
                            flavor="primary"
                            onClick={() => {
                                unregister(`${baseRoute}.transport`);
                                setValue("isDirty", true, {
                                    shouldDirty: true,
                                });
                            }}
                            icon={faSliders}
                        >
                            Use Platform Defaults
                        </Button>
                    ) : (
                        <Button
                            flavor="primary"
                            onClick={() => {
                                const baseConfig = service?.base_configs
                                    ?.v1 as V1LbConfig;

                                const currentConfig = details;
                                if (!baseConfig || !currentConfig) {
                                    return;
                                }

                                const transport = getActualTransportConfig(
                                    controller.port,
                                    baseConfig,
                                    currentConfig
                                );

                                setValue(
                                    `${baseRoute}.transport`,
                                    // https://github.com/oazapfts/oazapfts/issues/612
                                    // Need this merged in for correct typing from codegen
                                    transport as V1LbController["transport"],
                                    {
                                        shouldDirty: true,
                                    }
                                );
                            }}
                            icon={faEdit}
                        >
                            Customize
                        </Button>
                    )}
                </div>
            </PanelContent>
        </Panel>
    );
}
