import {
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    RHF_GLOBAL_ERROR,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    AutoScaleGroup,
    AutoScaleGroupIncludes,
    ProviderLocation,
    UpdateAutoScaleGroupApiArg,
    useUpdateAutoScaleGroupMutation,
} from "~/services/cycle";
import { PageBody } from "@cycleplatform/ui/components/page";
import { useNavigate } from "react-router-dom";
import { LocationSection } from "./components/LocationSection";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { DeployableInfraFormType } from "./form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type EditDeployableInfraFormProps = {
    group?: AutoScaleGroup;
    groupIncludes?: AutoScaleGroupIncludes;
    model: {
        provider: string;
        model_id: string;
        priority: number;
        locations: {
            id: string;
            availability_zones: string[];
        }[];
    };
    allLocations: ProviderLocation[];
};

function getDefaultValues({
    groupIncludes,
    model,
    allLocations,
}: EditDeployableInfraFormProps) {
    return {
        provider: groupIncludes?.integrations?.[model.provider],
        model: groupIncludes?.models?.[model?.model_id],
        priority: model.priority,
        locations: model.locations.map((l) =>
            allLocations.find((al) => al.id === l.id)
        ),
        zones: model.locations.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.id]: cur.availability_zones,
            }),
            {} as Record<string, string[]>
        ),
        step: 0,
    };
}

export function EditDeployableInfraForm({
    group,
    groupIncludes,
    model,
    allLocations,
}: EditDeployableInfraFormProps) {
    const form = useForm<DeployableInfraFormType>({
        defaultValues: getDefaultValues({ model, allLocations, groupIncludes }),
        ...rhfConfig,
    });
    const {
        handleSubmit,
        watch,
        formState: { isSubmitting, isDirty },
    } = form;

    const [updateAutoscaleGroup, { isLoading }] =
        useUpdateAutoScaleGroupMutation();
    const nav = useNavigate();

    const onDelete = () => {
        const mIdx = group?.infrastructure.models.findIndex(
            (m) => m.model_id === model.model_id
        );

        if (mIdx === undefined || !group) {
            return;
        }

        return updateAutoscaleGroup({
            groupId: group.id,
            body: {
                ...group,
                infrastructure: {
                    models: group.infrastructure.models.filter(
                        (_, idx) => idx !== mIdx
                    ),
                },
            } as UpdateAutoScaleGroupApiArg["body"],
        })
            .unwrap()
            .then(
                () => nav(clearDialogParams()),
                handleSubmitError(form.setError, {
                    sourceOverride: { infrastructure: RHF_GLOBAL_ERROR },
                })
            );
    };

    const onSubmit = (data: DeployableInfraFormType) => {
        if (!model || !group) {
            return;
        }

        const keyedModels = group?.infrastructure.models.reduce(
            (acc, cur) => ({ ...acc, [cur.model_id]: cur }),
            {} as Record<string, EditDeployableInfraFormProps["model"]>
        );

        const updatedModel = {
            ...model,
            priority: data.priority || 1,
            locations:
                data?.locations?.map((l) => ({
                    id: l.id,
                    availability_zones: data.zones?.[l.id] || [],
                })) || [],
        };

        return updateAutoscaleGroup({
            groupId: group?.id || "",
            body: {
                ...group,
                infrastructure: {
                    ...group.infrastructure,
                    models: Object.values({
                        ...keyedModels,
                        [updatedModel.model_id]: updatedModel,
                    }),
                },
            } as UpdateAutoScaleGroupApiArg["body"],
        })
            .unwrap()
            .then(
                () => nav(clearDialogParams()),
                handleSubmitError(form.setError, {
                    sourceOverride: { infrastructure: RHF_GLOBAL_ERROR },
                })
            );
    };

    const selectedModel = watch("model");

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <PageBody>
                <div className="h-full w-full ">
                    <LocationSection
                        model={selectedModel}
                        allLocations={allLocations}
                    />
                </div>
            </PageBody>

            <DialogFooter className="justify-between">
                <PushAndHoldButton
                    flavor="discard"
                    isLoading={isLoading}
                    type="button"
                    onClick={() => onDelete()}
                    icon={faTimes}
                >
                    Delete Model
                </PushAndHoldButton>
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    isLoading={isSubmitting}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    icon={faPlus}
                    disabled={!isDirty}
                >
                    Update Model
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
