import { DeployStackBuild } from "~/services/cycle";
import { useFormContext } from "react-hook-form";
import {
    Checkbox,
    FormSectionHeader,
    FormSection,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";

export function ScopedVariablesSection() {
    const { register } = useFormContext<DeployStackBuild["contents"]>();
    return (
        <>
            <FormSectionHeader header="Scoped Variables" />
            <FormSection>
                <div className="flex w-1/3 gap-4">
                    <RhfFormField
                        label="Add New"
                        name="update.scoped_variables.add_new"
                        help="Add any new scoped variables defined in the stack."
                    >
                        <Checkbox
                            {...register("update.scoped_variables.add_new")}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="Replace Existing"
                        name="update.scoped_variables.replace_existing"
                        help="Replace all scoped variables with those defined in the stack."
                    >
                        <Checkbox
                            {...register(
                                "update.scoped_variables.replace_existing"
                            )}
                        />
                    </RhfFormField>
                </div>
            </FormSection>
        </>
    );
}
