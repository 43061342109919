import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export type LookupDataPoint = {
    time: string;
    cache_hits: number;
    lookups: number;
};

export function buildDiscoveryLookupsQuery(environmentId: string | undefined) {
    const intervalMinutes = 15;
    const start = formatRFC3339(
        roundToNearestMinutes(subDays(new Date(), 1), {
            nearestTo: intervalMinutes,
            roundingMethod: "floor",
        })
    );
    const end = formatRFC3339(roundToNearestMinutes(new Date()));

    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "discovery.resolutions.lookups",
                        "discovery.resolutions.cache_hits",
                        "discovery.resolutions.throttled_hits",
                        "discovery.resolutions.not_founds",
                    ],
                },
                "labels.environment:id": environmentId,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        metadata: "$metadata",
                        labels: "$labels",
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "minute",
                                binSize: intervalMinutes,
                            },
                        },
                        point: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                        },
                        time: {
                            $last: "$time",
                        },
                        cached: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.cache_hits",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        not_found: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.not_founds",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        total_lookups: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.lookups",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        throttled_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.throttled_hits",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                    },
                },
                {
                    $addFields: {
                        lookups: {
                            $subtract: [
                                "$total_lookups",
                                {
                                    $add: [
                                        "$not_found",
                                        "$cached",
                                        "$throttled_hits",
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: intervalMinutes,
                            unit: "minute",
                            bounds: [start, end],
                        },
                    },
                },

                {
                    $project: {
                        _id: 0,
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
            ],
        },
    };
}
