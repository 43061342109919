import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { Account } from "~/services/cycle";
import { Memberships } from "./components/Memberships";
import { NotificationSettings } from "./components/NotificationSettings";
import { PendingInvitesList } from "~/components/hubs/PendingInvitesList";

type HubsSectionProps = {
    account?: Account;
};

export function HubsSection({ account }: HubsSectionProps) {
    return (
        <DialogPageBody>
            <PageContent>
                <Memberships account={account} />
            </PageContent>
            <PageAside>
                <NotificationSettings account={account} />
                <PendingInvitesList />
            </PageAside>
        </DialogPageBody>
    );
}
