import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export function getLbControllersQuery(environmentId: string | undefined) {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subDays(new Date(), 1), {
                    roundingMethod: "floor",
                })
            ),
            "range-end": formatRFC3339(
                roundToNearestMinutes(new Date(), {
                    roundingMethod: "floor",
                })
            ),
        },

        // Random assortment of controller metrics to narrow down the query time
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "lb.controller.router.destinations.connections.transmitted_kb",
                        "lb.controllers.router.destinations.requests.total",
                        "lb.controller.requests",
                        "lb.controller.requests.url.responses",
                    ],
                },
                "labels.environment:id": environmentId,
            },
            pipeline: [
                {
                    $group: {
                        _id: null,
                        controllers: {
                            $addToSet: "$labels.port",
                        },
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
            ],
        },
    };
}
