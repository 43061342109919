import { useNavigate } from "react-router-dom";
import { StyledResourceDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    useGetPipelineQuery,
    useGetPipelineRunQuery,
    useLookupComponentsQuery,
} from "~/services/cycle";
import { useMemo } from "react";
import { formatDateString } from "@cycleplatform/core/util";
import { PipelineRunDashboard } from "./dashboard/PipelineRunDashboard";
import { pipelineRunToComponentInput } from "./dashboard/stages/helpers";
import { PipelineRunDialogContext } from "./context";

export function PipelineRunDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"pipeline-run">();

    const { data: pipeline, error: pipelineError } = useGetPipelineQuery(
        {
            pipelineId: params?.["dialog-pipeline-id"],
        },
        { skip: !params?.["dialog-pipeline-id"] }
    );

    const { data: run, error: runError } = useGetPipelineRunQuery(
        {
            pipelineId: params?.["dialog-pipeline-id"],
            runId: params?.["dialog-pipeline-run-id"],
        },
        {
            skip:
                !params?.["dialog-pipeline-id"] ||
                !params?.["dialog-pipeline-run-id"],
        }
    );

    const compInput = useMemo(
        () => pipelineRunToComponentInput(run?.data),
        [run?.data]
    );

    const { data: components, error: componentsError } =
        useLookupComponentsQuery(
            {
                body: {
                    components: compInput,
                },
            },
            { skip: !compInput || compInput.length === 0 }
        );

    if (componentsError) {
        throw componentsError;
    }

    if (pipelineError) {
        throw pipelineError;
    }

    if (runError) {
        throw runError;
    }

    return (
        <PipelineRunDialogContext.Provider
            value={{ run: run?.data, components: components?.data }}
        >
            <StyledResourceDialog
                header={
                    <div className="flex items-center gap-4">
                        {`Pipeline [${pipeline?.data?.name || ""}] - ${
                            run?.data?.events?.queued
                                ? formatDateString(
                                      run.data.events.queued,
                                      "PPpp"
                                  )
                                : run?.data?.id || ""
                        }`}
                    </div>
                }
                open={isDialogOpen("pipeline-run")}
                onClose={() => nav(clearDialogParams())}
                breadcrumb={[
                    {
                        label: "Pipeline",
                    },
                    {
                        label: pipeline?.data?.name || "",
                        link: `/pipelines/${pipeline?.data?.id}`,
                    },
                    {
                        label: "Runs",
                    },
                    {
                        label: run?.data?.events?.queued
                            ? formatDateString(run.data.events.queued, "PPpp")
                            : run?.data?.id || "",
                    },
                ]}
                hideSidebar
            >
                <PipelineRunDashboard />
            </StyledResourceDialog>
        </PipelineRunDialogContext.Provider>
    );
}
