import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { InstanceTab } from "~/components/dialogs/containers/container/instances/InstanceTab";
import { Container } from "~/services/cycle";

type InstancesTabProps = {
    container: Container | undefined;
    containerId: string;
    instanceId: string;
};

export function InstancesTab({ ...props }: InstancesTabProps) {
    const { environment } = useContext(ContainerDialogContext);
    return (
        <InstanceTab
            {...props}
            parent="environment-vpn-manage"
            environment={environment}
        />
    );
}
