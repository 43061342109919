type ConfigSectionTitleProps = {
    title: string;
    children?: React.ReactNode;
};

export function ConfigSubSectionTitle({
    children,
    title,
}: ConfigSectionTitleProps) {
    return (
        <div className="flex w-full justify-center pb-4 font-bold ">
            <h4 className="text-sm uppercase">{title}</h4>
            {children}
        </div>
    );
}
