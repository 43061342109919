import { FC } from "react";
import type { components } from "@cycleplatform/core/modules/api/__generated";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-solid-svg-icons";

type Capability = components["schemas"]["Capability"];

type PermissionDeniedProps = {
    className?: string;
    title?: string;
    details?: string;
    capability?: Capability[] | Capability;
    resourceName?: string;
};

export const PermissionDenied: FC<PermissionDeniedProps> = ({
    className,
    title,
    details,
    capability,
    resourceName,
}) => {
    return (
        <div
            className={classNames(
                className,
                "@container flex h-1/2 w-full flex-col items-center justify-center gap-8 p-4"
            )}
        >
            <FontAwesomeIcon
                className="text-cycle-gray @2xl:text-9xl @lg:block hidden text-5xl"
                icon={faBan}
            />

            <div className="flex w-full flex-col items-center justify-center gap-2">
                <h2 className="@lg:text-2xl @4xl:text-4xl text-md flex items-center">
                    <FontAwesomeIcon
                        className="text-cycle-gray @lg:hidden mr-2 block"
                        icon={faBan}
                    />
                    {title || "Access Denied"}{" "}
                    {resourceName && resourceName !== "resource"
                        ? `- ${resourceName}`
                        : ""}
                </h2>

                <DetailsString details={details} capability={capability} />

                <span>
                    If this seems like a mistake, please reach out to the Hub
                    owner to verify your Role.
                </span>
            </div>
        </div>
    );
};

function DetailsString({
    details,
    capability,
}: {
    details?: string;
    capability?: Capability[] | Capability;
}) {
    const hasCapability = Array.isArray(capability)
        ? capability.length > 0
        : !!capability;

    if (!details && !hasCapability) {
        return null;
    }

    const highlightSpecificCapability = (details: string): JSX.Element => {
        const pattern = /'(.*?)'/g;
        const parts = (
            details?.charAt(0).toUpperCase() + details?.slice(1)
        ).split(pattern);

        return (
            <div>
                {parts.map((part, index) =>
                    index % 2 === 1 ? (
                        <span key={index} className="text-warning">
                            {part}
                        </span>
                    ) : (
                        part
                    )
                )}
            </div>
        );
    };

    // If handling an ACTUAL API error -> details will be defined.  Always use that if we have it

    // If pre-processing the capability prior to making API call, we will have a capability we can use
    // to generate a detail message
    return (
        <span className="@lg:text-base @4xl:text-xl text-center text-sm ">
            {details ? (
                <>{highlightSpecificCapability(details)}</>
            ) : hasCapability ? (
                <p className="text-cycle-gray dark:text-cycle-gray-light @4xl:text-base text-xs">
                    This section requires the following capabilities:{" "}
                    <span className=" text-warning">
                        {typeof capability === "string"
                            ? capability
                            : capability?.join(", ")}
                    </span>
                </p>
            ) : null}
        </span>
    );
}
