import {
    RhfEnableDisableFormSection,
    RhfCodeInput,
} from "@cycleplatform/ui/components/forms";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { LoadBalancerFormValues } from "../../../../../../form";
import { useEffect } from "react";

type V1RouterExtensionProps = {
    mode: "tcp" | "http" | "udp";
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
};

export function V1RouterExtension({
    mode,
    baseRoute,
    idx,
}: V1RouterExtensionProps) {
    const { control, setValue, unregister } =
        useFormContext<LoadBalancerFormValues>();

    const extensionSection = useWatch({
        control,
        name: `${baseRoute}.${idx}.config.extension`,
    });

    // TCP does not support extension so the whole section needs to be unregistered
    useEffect(() => {
        if (mode === "tcp") {
            setValue(`${baseRoute}.${idx}.config.extension`, null, {
                shouldDirty: true,
            });
        }
    }, [mode]);

    if (mode !== "http") {
        return null;
    }

    return (
        <div className="pb-4">
            <RhfEnableDisableFormSection
                header={
                    <div className="flex items-center gap-2">
                        <p>Extension</p>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-cycle-blue text-base"
                        />
                        <Link
                            className="text-sm"
                            to={
                                "https://cycle.io/docs/portal/configuring-v1#routers"
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            Router Extension Details
                        </Link>
                    </div>
                }
                isExpanded={!!extensionSection}
                onToggle={(v: boolean) => {
                    setValue("isDirty", true, { shouldDirty: true });

                    if (v) {
                        setValue(
                            `${baseRoute}.${idx}.config.extension.details`,
                            {
                                redirect: null,
                                forward: null,
                                proxy: null,
                            }
                        );
                        setValue(
                            `${baseRoute}.${idx}.config.extension.type`,
                            mode
                        );
                    } else {
                        unregister(`${baseRoute}.${idx}.config.extension`);
                    }
                }}
            >
                {(isExpanded) => (
                    <>
                        {isExpanded && (
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => <RhfCodeInput {...field} />}
                                control={control}
                                name={`${baseRoute}.${idx}.config.extension.details`}
                            />
                        )}
                    </>
                )}
            </RhfEnableDisableFormSection>
        </div>
    );
}
