import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { Container, GetLoadBalancerServiceApiResponse } from "~/services/cycle";
import { CycleErrorBoundary } from "~/components/common/errors";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { LoadBalancerInstances } from "~/components/environments/services/lb/LoadBalancerInstances";
import { LbConfigurationPanel } from "./LbConfigurationPanel";
import { ServiceContainerPanel } from "../../ServiceContainerPanel";
import { useContext } from "react";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { LoadBalancerDialogContext } from "../context";
import { LoadBalancerTelemetrySection } from "./loadBalancerTelemetry/LoadBalancerTelemetrySection";

type DashboardTabProps = {
    container: Container | undefined;
    lbInfo: GetLoadBalancerServiceApiResponse["data"] | undefined;
};

export function DashboardTab({ container, lbInfo }: DashboardTabProps) {
    const { environment } = useContext(LoadBalancerDialogContext);

    return (
        <div className="h-full">
            <DialogPageBody>
                <PageContent>
                    <LoadBalancerTelemetrySection />
                </PageContent>
                <PageAside>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                        heightFull
                    >
                        <Panel>
                            <PanelTitle title="IPs" />
                            <PanelContent>
                                <CycleErrorBoundary>
                                    <LoadBalancerInstances
                                        loadbalancer={container}
                                    />
                                </CycleErrorBoundary>
                            </PanelContent>
                        </Panel>
                        <LbConfigurationPanel
                            lbInfo={lbInfo}
                            environment={environment}
                        />
                        <ServiceContainerPanel
                            container={container}
                            environment={environment}
                        />
                    </AccessControlledSection>
                </PageAside>
            </DialogPageBody>
        </div>
    );
}
