import { GenericEvent, eventsToComponentInput } from "../util";
import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
    faCheckCircle,
    faExclamationCircle,
    faFileCircleQuestion,
    faInfoCircle,
    faLightEmergencyOn,
    faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { eventTypeColorMap } from "~/components/security/events/color";

export function EventAlertIcon({
    event,
    size,
}: {
    event: GenericEvent;
    size?: SizeProp;
}) {
    let icon: IconDefinition;

    const { colors } = useGetThemedChartColors();

    const eventColors = eventTypeColorMap(colors);

    switch (event.type) {
        case "success":
            icon = faCheckCircle;
            break;
        case "error":
            icon = faExclamationCircle;
            break;
        case "warning":
            icon = faWarning;
            break;
        case "audit":
            icon = faFileCircleQuestion;
            break;
        case "alert":
            icon = faLightEmergencyOn;
            break;
        case "notice":
        case "info":
        default:
            icon = faInfoCircle;
            break;
    }

    const color = eventColors[event?.type];

    return (
        <FontAwesomeIcon icon={icon} color={color as "success"} size={size} />
    );
}
