import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    faDashboard,
    faGearComplexCode,
} from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { useGetContainerQuery, useGetEnvironmentQuery } from "~/services/cycle";
import { DashboardTab } from "./dashboard";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { ContainerStateControls } from "~/components/containers/controls";
import { ServiceContainerBadge } from "~/components/containers/ServiceContainerBadge";
import { CycleErrorBoundary } from "~/components/common/errors";
import { NavIcons } from "~/components/layout/NavIcons";
import { InstancesTab } from "./instances/InstancesTab";
import { ConfigTab } from "./config/ConfigTab";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";

export function ManageDiscoveryDialog() {
    const params = getAllDialogSearchParams<"environment-discovery-manage">();
    const isOpen = isDialogOpen("environment-discovery-manage");
    const navigate = useNavigate();

    const { currentData: environment, error } = useGetEnvironmentQuery(
        {
            environmentId: params["dialog-env"],
        },
        { skip: !params["dialog-env"] }
    );

    const { data: discoveryContainer, error: containerError } =
        useGetContainerQuery(
            {
                containerId:
                    environment?.data?.services?.discovery?.container_id || "",
                meta: ["ips", "instances_count"],
                include: ["environments"],
            },
            {
                skip: !environment?.data?.services?.discovery?.container_id,
            }
        );

    if (error) {
        throw error;
    }
    if (containerError) {
        throw containerError;
    }

    return (
        <ContainerDialogContext.Provider
            value={{ environment: environment?.data, image: undefined }}
        >
            <StyledResourceDialog
                open={isOpen}
                onClose={() => navigate(clearDialogParams())}
                header={
                    <div className="flex w-full items-center justify-between">
                        <div className="flex h-full items-center gap-4">
                            <h1>
                                {environment?.data?.name ? (
                                    <>{environment?.data?.name}: Discovery</>
                                ) : (
                                    <SkeletonHeader size="lg" />
                                )}
                            </h1>
                            <ResourceStateBadge
                                className="!bg-cycle-gray !h-8"
                                state={discoveryContainer?.data?.state}
                            />
                            <ServiceContainerBadge />

                            <div className="h-10 w-10">
                                <ResourceStatePieChart
                                    data={preprocessCountsData(
                                        discoveryContainer?.data?.meta
                                            ?.instances_count
                                    )}
                                    centerTextClassName="text-cycle-white"
                                />
                            </div>
                        </div>
                        <ContainerStateControls
                            container={discoveryContainer?.data}
                            environment={environment?.data}
                            hide={["delete"]}
                        />
                    </div>
                }
                breadcrumb={[
                    {
                        label: "Environments",
                        link: "environments",
                    },
                    {
                        label: environment?.data ? environment.data.name : "",
                        link: `environments/${environment?.data?.id}`,
                    },
                    {
                        label: "Services",
                    },
                    {
                        label: "Discovery",
                    },
                ]}
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={faDashboard}
                >
                    <CycleErrorBoundary>
                        <DashboardTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="config"
                    name="Config"
                    icon={faGearComplexCode}
                >
                    <CycleErrorBoundary>
                        <ConfigTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="instances"
                    name="Instances"
                    icon={NavIcons["instances"]}
                >
                    <CycleErrorBoundary>
                        <InstancesTab
                            container={discoveryContainer?.data}
                            containerId={
                                environment?.data?.services.discovery
                                    ?.container_id || ""
                            }
                            instanceId={params["dialog-instance-id"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </ContainerDialogContext.Provider>
    );
}
