import { Button } from "@cycleplatform/ui/components/buttons";
import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";

export function IsInvalidConfig() {
    const { navigateToStep } = useContext(MultiStepDialogContext);
    return (
        <>
            <FormSectionHeader header="Invalid Config" />
            <FormSection>
                <InfoPanel type="warning">
                    Based on the answer above, you intend to manage the root
                    domain with Cycle. To configure this, set the root domain as
                    the origin.
                </InfoPanel>
                <Button
                    icon={faArrowLeft}
                    className="mt-4 text-base"
                    onClick={() => navigateToStep(0)}
                >
                    Return to Origin
                </Button>
            </FormSection>
        </>
    );
}
