import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { PublicNetworkSelect } from "~/components/containers/PublicNetworkSelect";
import { CreateContainerSubmitType } from "../CreateContainerForm";
import { PortSelect } from "~/components/containers/PortSelect";
import { InfoPanel } from "@cycleplatform/ui/components/panels";

export function Network() {
    const { register, control, watch } =
        useFormContext<CreateContainerSubmitType>();

    const publicNetwork = watch("config.network.public");
    const ports = watch("config.network.ports");

    return (
        <>
            <FormSectionHeader header="Network" />
            <FormSection>
                <RhfFormField
                    label="public network"
                    name="config.network.public"
                    required
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <PublicNetworkSelect {...field} />
                        )}
                        rules={{ ...required() }}
                        control={control}
                        name="config.network.public"
                    />
                </RhfFormField>

                <RhfFormField
                    label="hostname"
                    name="config.network.hostname"
                    required
                >
                    <TextInput
                        {...register("config.network.hostname", {
                            ...required(),
                        })}
                    />
                </RhfFormField>

                {publicNetwork === "enable" ? (
                    <>
                        <RhfFormField label="ports" name="config.network.ports">
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => {
                                    return <PortSelect {...field} />;
                                }}
                                control={control}
                                name="config.network.ports"
                            />
                        </RhfFormField>

                        {!ports?.length ? (
                            <InfoPanel
                                type="warning"
                                className="mb-4"
                            >{`Your container is configured for public internet,
                            but the image doesn't specify any ports. This will
                            prevent you from being able to connect to the
                            container over public internet. Either set public
                            network to "disabled", or specify port mappings
                            below to ensure you're able to connect (you can
                            adjust this later in the config, too).`}</InfoPanel>
                        ) : null}
                    </>
                ) : null}
            </FormSection>
        </>
    );
}
