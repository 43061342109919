import classNames from "classnames";

export function FormSection({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        // TODO - form-section class added temporarily to prevent double nesting until
        // we refactor
        <div
            className={classNames(
                "form-section mb-4 border-l-4 py-2 pl-6",
                "[&_.form-section]:-ml-6 [&_.form-section]:!mb-0 [&_.form-section]:!border-l-0 [&_.form-section]:!pt-0 ",
                "mb-4 ",

                className
            )}
        >
            {children}
        </div>
    );
}
