import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { faChartLine } from "@fortawesome/pro-duotone-svg-icons";

import {
    DialogPageBody,
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import {
    useCreatePipelineTriggerKeyMutation,
    useGetPipelineQuery,
    useGetPipelineTriggerKeyQuery,
    useDeletePipelineTriggerKeyMutation,
} from "~/services/cycle";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { TriggerKeySettings } from "./components/TriggerKeySettings";
import { TriggerKeyPostInformation } from "./components/TriggerKeyPostInformation";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "../../helpers";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type TriggerKeyDialogProps = {
    className?: string;
};

export function TriggerKeyDialog({ className }: TriggerKeyDialogProps) {
    const params = getAllDialogSearchParams<"pipeline-trigger-key">();
    const nav = useNavigate();

    const pipelineId = params["dialog-pipeline-id"];
    const keyId = params["dialog-key-id"];

    const { data: pipeline } = useGetPipelineQuery({
        pipelineId,
    });

    const { data: triggerKey } = useGetPipelineTriggerKeyQuery({
        pipelineId,
        triggerKeyId: keyId,
    });

    // If a new trigger key is present here, we need to show the 'real' key
    const [, { data: newTriggerKey }] = useCreatePipelineTriggerKeyMutation({
        fixedCacheKey: `trigger-key-${pipelineId}`,
    });

    const [deleteTriggerKey] = useDeletePipelineTriggerKeyMutation();
    return (
        <StyledResourceDialog
            open={
                isDialogOpen("pipeline-trigger-key") && !!pipelineId && !!keyId
            }
            onClose={() => nav(clearDialogParams())}
            className={classNames("relative z-50", className)}
            breadcrumb={[
                {
                    label: "Pipelines",
                    link: "pipelines",
                },
                {
                    label: pipeline?.data?.name || "Loading...",
                    link: `pipelines/${pipeline?.data?.id || ""}`,
                },
                {
                    label: "Trigger Keys",
                    link: `pipelines/${pipeline?.data?.id}/trigger-keys`,
                },
                {
                    label: triggerKey?.data?.name || "",
                },
            ]}
            header={
                <div className="flex items-center gap-4">
                    <h1>{triggerKey?.data?.name} </h1>
                    <ResourceStateBadge
                        className="!bg-cycle-gray !h-8"
                        state={triggerKey?.data?.state}
                    />
                </div>
            }
        >
            <ResourceDialogTab to={null} name="Dashboard" icon={faChartLine}>
                <DialogPageBody>
                    <PageContent>
                        <TriggerKeySettings
                            pipelineId={pipelineId}
                            pipeline={pipeline?.data}
                            triggerKey={triggerKey?.data}
                            fullTriggerKey={newTriggerKey?.data?.secret}
                        />
                        <TriggerKeyPostInformation
                            pipeline={pipeline?.data}
                            secret={newTriggerKey?.data?.secret}
                        />
                    </PageContent>
                    <PageAside>
                        <DeleteResource
                            accessControl={{
                                aclResource: pipeline?.data,
                                verifyFn: modifyAccessFn("pipelines-manage"),
                            }}
                            resourceType="trigger key"
                            resourceName={triggerKey?.data?.name}
                            onSubmit={() => {
                                return deleteTriggerKey({
                                    pipelineId: pipeline?.data?.id || "",
                                    triggerKeyId: triggerKey?.data?.id || "",
                                })
                                    .unwrap()
                                    .then(() => nav(clearDialogParams()));
                            }}
                        />
                    </PageAside>
                </DialogPageBody>
            </ResourceDialogTab>
        </StyledResourceDialog>
    );
}
