import { PanelContent } from "../../panels";
import classNames from "classnames";
import logoDarkMode from "../../../images/cycle-gear-dark-bg.png";
import logoLightMode from "../../../images/cycle-gear-black.svg";

type LoadingPanelProps = {
    panelClassName?: string;
    className?: string;
    theme?: "light" | "dark";
};

/**
 * A panel used when a data is in a loading state.
 */
export function LoadingPanel({
    panelClassName,
    className,
    theme,
}: LoadingPanelProps) {
    const loadingIcon = (
        <img
            className={classNames(
                "h-80 w-80 animate-pulse rounded-full",
                className
            )}
            src={theme === "dark" ? logoDarkMode : logoLightMode}
            alt="platform"
        />
    );

    return (
        <div
            className={classNames(
                "flex h-full w-full items-center justify-center",
                panelClassName
            )}
        >
            <div className={"flex justify-center p-4"}>{loadingIcon}</div>
        </div>
    );
}
