import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { formatBytesString } from "@cycleplatform/core/util/bytes";
import {
    ContainerBackup,
    ContainerBackupIncludes,
    useGetContainerBackupQuery,
} from "~/services/cycle";
import { BackupLogList } from "./BackupLogs";
import { BackupRestoreDialog } from "./BackupRestoreDialog";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { DialogPageHeader } from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { formatDateString } from "@cycleplatform/core/util";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";

type BackupContentProps = {
    backup?: ContainerBackup;
    backupIncludes?: ContainerBackupIncludes;
    backupId?: string;
    containerId?: string;
};

export function BackupContent({
    backup: bk,
    backupIncludes,
    containerId,
    backupId,
}: BackupContentProps) {
    // This gives us flexibility to fetch a backup that is specified in the URL,
    // but wasn't present on the sidebar list (because it's probably on another page).
    // With this setup, we fetch the backup ONLY IF it wasn't passed in from the navigation.
    const { data: fetchedBackup, error } = useGetContainerBackupQuery(
        {
            backupId: backupId || "",
            containerId: containerId || "",
            include: ["integrations"],
        },
        { skip: !!bk || !backupId || !containerId }
    );
    if (error) {
        throw error;
    }

    const backup = fetchedBackup?.data || bk;
    const includes = fetchedBackup?.includes || backupIncludes;
    const integration =
        includes?.integrations?.[backup?.target?.integration_id || ""];

    return (
        <>
            <DialogPageHeader
                title={
                    backup && formatDateString(backup.events.created, "PPpp")
                }
                state={backup?.state}
                icon={NavIcons["backups"]}
            >
                {" "}
                <div className="flex gap-2">
                    {backup ? (
                        <BackupRestoreDialog backup={backup} />
                    ) : (
                        <SkeletonButton className="!h-6 !w-6" />
                    )}
                </div>
            </DialogPageHeader>
            <div className="flex flex-col gap-4">
                <Panel className="w-full ">
                    <PanelTitle className="flex" title={"Target"} />
                    <PanelContentBoundary className="w-full flex-1">
                        <div className="grid grid-cols-2 gap-4">
                            <FormField label="ID">
                                {backup ? (
                                    <CopyInput value={backup.id} />
                                ) : (
                                    <SkeletonButton />
                                )}
                            </FormField>
                            <FormField label="Destination">
                                {backup ? (
                                    <CopyInput
                                        value={
                                            integration?.name ||
                                            integration?.identifier ||
                                            backup.target.integration_id
                                        }
                                    />
                                ) : (
                                    <SkeletonButton />
                                )}
                            </FormField>
                            <FormField label="path">
                                {backup ? (
                                    <CopyInput value={backup.target.path} />
                                ) : (
                                    <SkeletonButton />
                                )}
                            </FormField>
                            <FormField label="size">
                                {backup ? (
                                    <CopyInput
                                        value={formatBytesString(
                                            backup.target.size
                                        )}
                                    />
                                ) : (
                                    <SkeletonButton />
                                )}
                            </FormField>
                        </div>
                    </PanelContentBoundary>
                </Panel>
                <Panel>
                    <PanelTitle title="Logs" />
                    <PanelContent>
                        {backup && <BackupLogList backup={backup} />}
                    </PanelContent>
                </Panel>
            </div>
        </>
    );
}
