import { StateIconMap } from "@cycleplatform/ui/components/resources/state";
import { useMemo } from "react";
import { brandColors } from "tailwindcss-config/colors";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";

export type ThemedColors = {
    red: {
        DEFAULT: string;
        light: string;
        dark: string;
    };
    orange: {
        DEFAULT: string;
        light: string;
        dark: string;
    };
    yellow: {
        DEFAULT: string;
    };
    green: {
        DEFAULT: string;
        dark: string;
        light: string;
    };
    blue: {
        DEFAULT: string;
        dark: string;
        light: string;
    };
    purple: {
        DEFAULT: string;
    };
    gray: {
        DEFAULT: string;
        dark: string;
        light: string;
        extraLight: string;
    };
    black: {
        DEFAULT: string;
    };
};
export function useGetThemedChartColors(): { colors: ThemedColors } {
    const theme = useAppSelector(selectAppliedTheme);

    const isDark = theme === "dark";

    const colors = useMemo(
        () => ({
            red: {
                DEFAULT: !isDark ? brandColors["error"].DEFAULT : "#ff0000",
                light: "#f55d5d",
                dark: !isDark ? "#910000" : "#ff371c",
            },
            orange: {
                DEFAULT: "#ff6200",
                light: !isDark ? "#ff8e47" : "#ff8e47",
                dark: !isDark ? "#e04e00" : "#c76102",
            },

            yellow: {
                DEFAULT: !isDark ? "#e6de02" : brandColors["warning"].accent,
            },
            green: {
                DEFAULT: brandColors["success"].DEFAULT,
                dark: "#0d8f29",
                light: "#48f76b",
            },
            blue: {
                DEFAULT: brandColors["cycle-blue"].DEFAULT,
                dark: brandColors["cycle-blue"].accent,
                light: !isDark ? "#5ac7e6" : "#75e1ff",
            },
            purple: {
                DEFAULT: brandColors["cycle-purple"].DEFAULT,
            },
            gray: {
                DEFAULT: !isDark
                    ? brandColors["cycle-gray"].DEFAULT
                    : "#58585e",
                light: !isDark ? "#82828c" : "#a1a1ab",
                extraLight: !isDark ? "#c3c3c3" : "#474747",
                dark: !isDark ? "#68686b" : "#c0c0c4",
            },
            black: {
                DEFAULT: !isDark
                    ? brandColors["cycle-black"].DEFAULT
                    : "#dbdbdb",
            },
        }),
        [isDark]
    );

    return {
        colors,
    };
}

export function useGetStateColorMap() {
    const theme = useAppSelector(selectAppliedTheme);

    const isDark = theme === "dark";

    const stateColorMap = {
        red: brandColors["error"].DEFAULT,
        orange: brandColors["warning"].DEFAULT,
        green: brandColors["success"].DEFAULT,
        blue: brandColors["cycle-blue"].DEFAULT,
        purple: brandColors["cycle-purple"].DEFAULT,
        gray: isDark ? "#3b3b40" : "#8b8b8b",
    };

    const colors = useMemo(
        () =>
            Object.entries(StateIconMap).reduce((acc, [state, value]) => {
                return { ...acc, [state]: stateColorMap[value.color] };
            }, {} as Record<string, string>),
        [theme]
    );
    return {
        colors,
    };
}
