import {
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { matchAutofillFromImage } from "~/modules/hubs/util";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { ConfigSubSectionTitle } from "../../common/ConfigSubSectionTitle";
import { Container, useGetImageQuery } from "~/services/cycle";
import { Button } from "@cycleplatform/ui/components/buttons";
import { ContainerConfigFormData } from "../types";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { IntegrationSelect } from "~/components/integrations";

type BackupsIntegrationProps = {
    container: Container;
};

export function BackupsIntegration({ container }: BackupsIntegrationProps) {
    const { register, control, setValue } =
        useFormContext<ContainerConfigFormData>();

    const { data: image, error } = useGetImageQuery(
        { imageId: container.image.id || "" },
        {
            skip:
                container.image.id === undefined ||
                isServiceContainer(container) ||
                isHypervisorContainer(container),
        }
    );

    const hub = useAppSelector(selectActiveHub);

    const autofill = image?.data ? matchAutofillFromImage(image.data) : null;

    const fillCommand = useCallback(() => {
        if (!autofill) {
            return;
        }
        setValue(
            "config.integrations.backups.backup.command",
            autofill.commands.backupCommand
        );
        setValue(
            "config.integrations.backups.restore.command",
            autofill.commands.restoreCommand
        );
    }, [autofill?.type]);

    if (error) {
        throw error;
    }

    return (
        <div className="flex flex-col">
            <RhfFormField
                label="Destination"
                name="config.integrations.backups.integration_id"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <IntegrationSelect
                            featureExtends={"backups"}
                            onChange={(v) => {
                                field.onChange(v || null);
                            }}
                            value={field.value}
                            filter={{
                                category: "object-storage",
                            }}
                            placeholder="Select a Backup Provider"
                            isNullable={false}
                        />
                    )}
                    control={control}
                    name="config.integrations.backups.integration_id"
                />
            </RhfFormField>

            {autofill && (
                <div className="dark:border-cycle-gray-accent my-4 flex flex-col items-center justify-between rounded-md border-2 p-4 px-4">
                    <ConfigSubSectionTitle title="Database Image Detected" />
                    <p>
                        It looks like you&apos;re using a{" "}
                        <strong>{autofill.type} image</strong>. Would you like
                        to use the {autofill.type} backup/restore commands?
                    </p>
                    <Button className="mt-4" onClick={fillCommand}>
                        Use {autofill.type} commands
                    </Button>
                </div>
            )}

            <div className="mt-4 flex flex-col gap-2">
                <ConfigSubSectionTitle title="Backup" />

                <RhfFormField
                    label="Command"
                    name="config.integrations.backups.backup.command"
                    required
                >
                    <TextInput
                        {...register(
                            "config.integrations.backups.backup.command",
                            {
                                ...required("A backup command is required"),
                            }
                        )}
                    />
                </RhfFormField>
                <RhfFormField
                    label="Timeout"
                    name="config.integrations.backups.backup.timeout"
                    required
                >
                    <TextInput
                        {...register(
                            "config.integrations.backups.backup.timeout",
                            {
                                ...required("Timeout cannot be empty"),
                                validate: {
                                    ...isDurationString(),
                                },
                            }
                        )}
                    />
                </RhfFormField>
                <RhfFormField
                    label="Cron String"
                    name="config.integrations.backups.backup.cron_string"
                    required
                >
                    <TextInput
                        {...register(
                            "config.integrations.backups.backup.cron_string",
                            {
                                ...required("Cron string is required"),
                            }
                        )}
                    />
                </RhfFormField>
            </div>
            <div className="mt-4 flex flex-col gap-2">
                <ConfigSubSectionTitle title="Restore" />
                <RhfFormField
                    label="Command"
                    name="config.integrations.backups.restore.command"
                    required
                >
                    <TextInput
                        {...register(
                            "config.integrations.backups.restore.command",
                            {
                                ...required(),
                            }
                        )}
                    />
                </RhfFormField>
                <RhfFormField
                    label="Timeout"
                    name="config.integrations.backups.restore.timeout"
                    required
                >
                    <TextInput
                        {...register(
                            "config.integrations.backups.restore.timeout",
                            {
                                ...required("Timeout cannot be empty"),
                                validate: {
                                    ...isDurationString(),
                                },
                            }
                        )}
                    />
                </RhfFormField>
            </div>
        </div>
    );
}
