import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { Children, cloneElement, useEffect, useState } from "react";
import { FormFieldLabel } from "../FormFieldLabel";

type RhfFormFieldProps = {
    name: string;
    label?: string;
    children: React.ReactElement;
    required?: boolean;
    className?: string;
    help?: string;
    labelClassName?: string;
    tooltip?: React.ReactNode;
    hideError?: boolean;
};

export function RhfFormField({
    label,
    name,
    required,
    children,
    className,
    help,
    tooltip,
    labelClassName,
    hideError,
}: RhfFormFieldProps) {
    const {
        formState: { errors },
    } = useFormContext();

    // When using trigger to manually validate, this can cause the error message to display for a very brief moment in time
    // By debouncing, we can ensure that only "real" (and long lasting) errors are transmitted to the client
    // This ensures visual consistency by filtering out transient errors
    const [debouncedErrors, setDebouncedErrors] = useState(errors);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedErrors(errors);
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [errors]);

    return (
        <div
            className={classNames(
                className,
                label ? "mb-4 mt-2" : "py-2",
                "relative   w-full min-w-0",
                "[&>.checkbox-container]:h-[40px] [&>.checkbox-container]:pl-4"
            )}
        >
            {label ? (
                <label className="inline" htmlFor={name}>
                    <div
                        className={classNames(
                            "flex items-center text-sm font-semibold ",
                            label && "pb-1"
                        )}
                    >
                        <FormFieldLabel
                            className={classNames("pb-2", labelClassName)}
                            label={label}
                            required={required}
                            help={help}
                            tooltip={tooltip}
                        />
                    </div>
                </label>
            ) : null}

            {Children.map(children, (child) =>
                cloneElement(child, {
                    id: name,
                    error: !!errors[name]?.message,
                })
            )}
            {!hideError ? (
                <div className="absolute w-full">
                    <ErrorMessage
                        errors={debouncedErrors}
                        name={name}
                        render={({ message }) => (
                            <FieldErrorMessage
                                className="relative text-end "
                                message={message}
                            />
                        )}
                    />
                </div>
            ) : null}
        </div>
    );
}
