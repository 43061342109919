import {
    FormSection,
    FormSectionHeader,
    required,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ScopedVariableFormSubmitType } from "../ScopedVariableForm";
import { useEffect } from "react";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";

export function ScopedVariablesGeneral() {
    const { register, control, setValue } =
        useFormContext<ScopedVariableFormSubmitType>();

    const source = useWatch({ name: "source", control });

    useEffect(() => {
        if (source?.type === "url") {
            setValue("source.details", {
                url: source?.details?.url || "",
                headers: source?.details?.headers || {},
                auth_token_url: source?.details?.auth_token_url || null,
            });
        } else {
            setValue("source.details", {
                value: source?.details?.value || "",
                blob: source?.details?.blob || false,
            });
        }
    }, [source?.type]);

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <div className="flex w-full gap-4">
                    <div className="w-1/2">
                        <RhfIdentifierInput
                            name={`identifier`}
                            label="Identifier"
                            required
                        />
                    </div>
                    <div className="w-1/2">
                        <RhfFormField
                            label="Source Type"
                            name="source.type"
                            required
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <BasicSelect
                                        {...field}
                                        options={[
                                            { value: "raw", label: "RAW" },
                                            { value: "url", label: "URL" },
                                        ]}
                                    />
                                )}
                                rules={{ ...required() }}
                                control={control}
                                name="source.type"
                            />
                        </RhfFormField>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
