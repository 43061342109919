import { components } from "../api/__generated";

export function isServiceContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service !== null;
}

export function isHypervisorContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.extension?.identifier === "virtual-machine-v1";
}
