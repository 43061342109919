import {
    TextInput,
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { CreateImageSourceApiArg } from "~/services/cycle";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageSourceCreateEditSubmitType } from "../../util";

export const AddCredentialForm = () => {
    const { watch, setValue } =
        useFormContext<ImageSourceCreateEditSubmitType>();
    const creds = watch("origin.details.credentials");

    return (
        <>
            <FormSectionHeader header="Registry Credentials" />
            <FormSection>
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell className="w-1/3">
                            URL
                        </StyledHeaderCell>
                        <StyledHeaderCell>Username</StyledHeaderCell>
                        <StyledHeaderCell>Token</StyledHeaderCell>
                        <StyledHeaderCell className="w-10"></StyledHeaderCell>
                    </StyledTableHead>
                    <tbody>
                        {creds?.map((c, i) => (
                            <StyledTableRow key={c?.url}>
                                <StyledCell>{c.url}</StyledCell>
                                <StyledCell>{c.username}</StyledCell>
                                <StyledCell>{c.token}</StyledCell>
                                <StyledCell>
                                    <Button
                                        flavor="discard"
                                        onClick={() => {
                                            const tmpCreds = creds;
                                            tmpCreds.splice(i, 1);
                                            setValue(
                                                "origin.details.credentials",
                                                tmpCreds,
                                                { shouldDirty: true }
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </StyledCell>
                            </StyledTableRow>
                        ))}

                        <AddRegistryRow />
                    </tbody>
                </StyledDataTable>
            </FormSection>
        </>
    );
};
function AddRegistryRow() {
    const { setValue, watch } =
        useFormContext<CreateImageSourceApiArg["body"]>();
    const creds = watch("origin.details.credentials");

    const [newRegistry, setNewRegistry] = useState({
        url: "",
        username: "",
        token: "",
    });

    return (
        <StyledTableRow>
            <td className="pr-2">
                <TextInput
                    placeholder="Registry URL"
                    value={newRegistry.url}
                    onChange={(ev) =>
                        setNewRegistry({
                            ...newRegistry,
                            url: ev.target.value,
                        })
                    }
                />
            </td>
            <StyledCell className="pr-2">
                <TextInput
                    placeholder="Username"
                    value={newRegistry.username}
                    onChange={(ev) =>
                        setNewRegistry({
                            ...newRegistry,
                            username: ev.target.value,
                        })
                    }
                />
            </StyledCell>
            <StyledCell className="pr-2">
                <TextInput
                    placeholder="Token"
                    value={newRegistry.token}
                    onChange={(ev) =>
                        setNewRegistry({
                            ...newRegistry,
                            token: ev.target.value,
                        })
                    }
                />
            </StyledCell>
            <StyledCell className="text-end">
                <Button
                    type="button"
                    flavor="primary"
                    disabled={
                        !newRegistry.token ||
                        !newRegistry.url ||
                        !newRegistry.username
                    }
                    onClick={() => {
                        setValue(
                            "origin.details.credentials",
                            creds ? [...creds, newRegistry] : [newRegistry],
                            { shouldDirty: true }
                        );
                        setNewRegistry({
                            url: "",
                            username: "",
                            token: "",
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </StyledCell>
        </StyledTableRow>
    );
}
