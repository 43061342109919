import {
    RhfFormField,
    Checkbox,
    TextInput,
    FormSectionHeader,
    FormSection,
    EnableSectionInline,
    required,
    FormField,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";

export function DevicesOptions() {
    const { register, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        !!getValues("config.runtime.devices.shm_size")
    );

    return (
        <>
            <FormSectionHeader header="Devices" />
            <FormSection>
                <EnableSectionInline
                    enableNode={
                        <RhfFormField label="Override" name="overrides.workdir">
                            <Checkbox
                                checked={isCustomized}
                                onChange={(ev) => {
                                    setIsCustomized(ev.target.checked);

                                    if (!ev.target.checked) {
                                        setValue(
                                            "config.runtime.devices.shm_size",
                                            null,
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                    }
                                }}
                            />
                        </RhfFormField>
                    }
                >
                    {isCustomized ? (
                        <RhfFormField
                            label="shared host memory size"
                            name="config.runtime.devices.shm_size"
                            required
                        >
                            <TextInput
                                {...register(
                                    "config.runtime.devices.shm_size",
                                    {
                                        ...required(),
                                    }
                                )}
                            />
                        </RhfFormField>
                    ) : (
                        <FormField label="shared host memory size">
                            <TextInput placeholder={"64M"} disabled />
                        </FormField>
                    )}
                </EnableSectionInline>
            </FormSection>
        </>
    );
}
