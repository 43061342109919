import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faBroom,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, Stack } from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatStackMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name = extractStackName(item.component?.id || "", components);
    const link = <Link to={`/stacks/${item.component?.id}`}>{name}</Link>;
    switch (item.event) {
        case "stack.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of stack {link}</span>
                ) : (
                    <span>Error requesting deletion of stack {link} </span>
                ),
                icon: faTrash,
            };
        case "stack.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted stack {link}</span>
                ) : (
                    <span>Error deleting stack {link} </span>
                ),
                icon: faTrash,
            };
        case "stack.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated stack {link}</span>
                ) : (
                    <span>Error updating stack {link}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "stack.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created stack {link}</span>
                ) : (
                    <span>Error creating stack</span>
                ),
                icon: faPlus,
            };
        case "stack.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested prune of all builds of stack {link}</span>
                ) : (
                    <span>
                        Error requesting prune of all builds builds of stack{" "}
                        {link}
                    </span>
                ),
                icon: faBroom,
            };
        case "stack.task.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested prune of all builds of stack {link}</span>
                ) : (
                    <span>
                        Error requesting prune of all builds builds of stack{" "}
                        {link}
                    </span>
                ),
                icon: faBroom,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractStackName(id: string, components: ComponentIncludes) {
    if (!components) {
        return id;
    }

    const c = components[id] as Stack;
    return c.name;
}
