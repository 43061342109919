import { components } from "@cycleplatform/core/modules/api/__generated";
import { getTargetNetworkInterface } from "@cycleplatform/core/modules/containers/instances";
import {
    getCpuUsageAsPercent,
    getEquivalentCpuCoresForInstance,
} from "@cycleplatform/core/modules/containers/telemetry";
import { parseDuration } from "@cycleplatform/core/util";
import {
    differenceInSeconds,
    formatRFC3339,
    roundToNearestMinutes,
    sub,
} from "date-fns";
import {
    ContainerResources,
    DeploymentStrategyName,
    InstanceTelemetryResourceSnapshot,
} from "~/services/cycle";

export type TelemetryMode = "stream" | "report";

const DEFAULT_RETENTION = 6 * 60 * 60 * 1000; // in MS;
// 1400 datapoints is the maxiumum we will get from a report
// This max ensures that we do not end up with over 1500 data points when streaming as well
export const MAX_STREAM_DATAPOINTS = 1400;
/**
 * Array of report telemetry options, options will be disabled if time in
 * seconds is greater than retention time
 * @param retention From container config; if undefined, default retention === 6 hours
 * @note value must be converted to seconds to compare against retention
 */
export const reportSelectOptions = (retention: string | undefined) => {
    const parsedDuration = retention
        ? parseDuration(retention)
        : DEFAULT_RETENTION;

    const comparisonTime =
        typeof parsedDuration === "number" ? parsedDuration : DEFAULT_RETENTION;

    function getRangeStart(value: number) {
        return formatRFC3339(
            sub(
                roundToNearestMinutes(new Date(), {
                    nearestTo: 5,
                    roundingMethod: "ceil",
                }),
                { hours: value }
            )
        );
    }
    return [
        {
            label: "Last Hour",
            value: getRangeStart(1),
            // the report will display one hour at minimum, even if the retention is < one hour.
            disabled: false,
        },
        {
            label: "Last 6 Hours",
            value: getRangeStart(6),
            disabled: 6 * 60 * 60 * 1000 > comparisonTime,
        },
        {
            label: "Last 12 Hours",
            value: getRangeStart(12),
            disabled: 12 * 60 * 60 * 1000 > comparisonTime,
        },
        {
            label: "Last Day",
            value: getRangeStart(24),
            disabled: 25 * 60 * 60 * 1000 > comparisonTime,
        },
        {
            label: "Last Three Days",
            value: getRangeStart(72),
            disabled: 72 * 60 * 60 * 1000 > comparisonTime,
        },
    ];
};

export type TelemetryOptions = {
    mode: TelemetryMode;
    option?: { label: string; value: string; disabled: boolean };
};

export interface CpuTelemetryData {
    time: string;
    usage: number | null;
}

export interface RamTelemetryData {
    usage: { x: Date; y: number | null };
    kernel: { x: Date; y: number | null };
    kernelTcp: { x: Date; y: number | null };
}

export interface NetworkTelmetryData {
    time: string;
    rx: number | null;
    tx: number | null;
}

export function snapshotToCpuData(
    snapshot: InstanceTelemetryResourceSnapshot,
    prevSnapshot: InstanceTelemetryResourceSnapshot | null,
    cpuConfig: ContainerResources["cpu"] | undefined,
    deploymentStrategy: DeploymentStrategyName | undefined
): CpuTelemetryData {
    const x = new Date(snapshot.time);

    const hostCores = snapshot.cpu.num_cores;

    function safeCalcUsage(cur?: number, prev?: number) {
        if (!cur || !prev || !prevSnapshot || !hostCores) {
            return null;
        }

        return getCpuUsageAsPercent({
            currentTotal: cur,
            prevTotal: prev,
            secondsDiff: differenceInSeconds(x, new Date(prevSnapshot.time)),
            cores: getEquivalentCpuCoresForInstance(
                cpuConfig,
                hostCores,
                deploymentStrategy
            ),
        });
    }

    return {
        time: snapshot.time,
        usage: safeCalcUsage(
            snapshot.cpu.usage.total,
            prevSnapshot?.cpu.usage.total
        ),
    };
}

export const snapshotToNetworkData = (
    mode: "public" | "private",
    snapshot: InstanceTelemetryResourceSnapshot,
    prevSnapshot: InstanceTelemetryResourceSnapshot | null
): NetworkTelmetryData => {
    const intf = getTargetNetworkInterface(mode, snapshot.network.interfaces);
    const prevIntf = prevSnapshot
        ? getTargetNetworkInterface(mode, prevSnapshot.network.interfaces)
        : null;

    const rxy =
        intf && prevIntf && intf.rx_bytes - prevIntf.rx_bytes >= 0
            ? intf.rx_bytes - prevIntf.rx_bytes
            : null;
    const txy =
        intf && prevIntf && intf.tx_bytes - prevIntf.tx_bytes >= 0
            ? intf.tx_bytes - prevIntf.tx_bytes
            : null;

    return {
        rx: rxy,
        tx: txy,
        time: snapshot.time,
    };
};
