import { useContext, useEffect } from "react";
import {
    FilterContext,
    FilterGroupContext,
    FilterOption,
} from "./FilterContext";
import classNames from "classnames";

type FilterValueProps = {
    identifier?: string;
    name: string;
    value: string;
    children: React.ReactNode;
    /**
     * isDefault can ONLY be used in a "required" group
     */
    isDefault?: boolean;
};

export function FilterValue({
    name,
    children,
    identifier,
    value,
    isDefault,
}: FilterValueProps) {
    const {
        appendFilterValue,
        deleteFilterValue,
        replaceFilterValue,
        filters,
        setFilter,
        initializeDefaults,
    } = useContext(FilterContext);

    const { group, groupName, exclusive, required, onClose } =
        useContext(FilterGroupContext);

    if (!identifier && !group) {
        throw new Error(
            "Filter must have an identifier or be part of a FilterGroup"
        );
    }

    if (identifier && group) {
        throw new Error(
            "Filter canont have both an identifier and be part of a FilterGroup"
        );
    }

    const key = identifier || group || "";
    const filterOption: FilterOption = filters[key];

    const isActive =
        filterOption && filterOption.value.some((v) => v.value === value);

    useEffect(() => {
        if (!filterOption && isDefault && required) {
            initializeDefaults(key, {
                name: groupName,
                value: [
                    {
                        name,
                        value,
                    },
                ],
            });
        }
    }, [filters]);

    const onClick = () => {
        if (!filterOption) {
            setFilter(key, {
                name: groupName,
                value: [
                    {
                        name,
                        value,
                    },
                ],
            });
        } else {
            if (isActive) {
                if (filterOption.value.length === 1 && required) {
                    return;
                }
                deleteFilterValue(key, value);
            } else {
                if (exclusive) {
                    replaceFilterValue(key, {
                        name,
                        value,
                    });
                } else {
                    appendFilterValue(key, {
                        name,
                        value,
                    });
                }
            }
        }
        if (exclusive) {
            onClose?.(false);
        }
    };

    return (
        <button
            onClick={onClick}
            className={classNames(
                "dark:bg-cycle-black-accent flex-1 rounded-md border border-b bg-white p-2 text-center transition",
                isActive &&
                    "text-cycle-blue  bg-cycle-blue/10 dark:bg-cycle-blue-accent/30 font-bold"
            )}
        >
            {children}
        </button>
    );
}
