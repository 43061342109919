import { Tooltip } from "../tooltip";

type RestrictedTooltipProps = {
    children: React.ReactNode;
    tooltip?: string;
};

export function RestrictedTooltip({
    tooltip = "You do not have access to view this resource.",
    children,
}: RestrictedTooltipProps) {
    return (
        <Tooltip message={tooltip}>
            <div className="rounded-md px-2 blur-sm">{children}</div>
        </Tooltip>
    );
}
