import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    Checkbox,
    FormFieldLabel,
    FormSectionHeader,
    FormSection,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Environment,
    GetLoadBalancerServiceApiResponse,
    CreateLoadBalancerServiceJobApiArg,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faChevronDown, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { useKeepFormCurrent } from "~/components/common/forms";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { useCapability } from "~/modules/hubs/permissions/useCapability";

type LbInstancePanelProps = {
    lbInfo?: GetLoadBalancerServiceApiResponse["data"];
    environment: Environment | undefined;
};

function getDefaultValues(lbInfo?: GetLoadBalancerServiceApiResponse["data"]) {
    return {
        high_availability: lbInfo?.service?.high_availability,
        auto_update: lbInfo?.service?.auto_update,
        config: lbInfo?.service?.config || lbInfo?.default_config,
    };
}

export function LbConfigurationPanel({
    lbInfo,
    environment,
}: LbInstancePanelProps) {
    const form = useForm<
        CreateLoadBalancerServiceJobApiArg["body"]["contents"]
    >({
        defaultValues: getDefaultValues(lbInfo),
        ...rhfConfig,
    });

    const {
        handleSubmit,
        control,
        reset,
        register,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty },
    } = form;

    const [formError, setFormError] = useState<string>();
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();
    const [isAdvancedExpanded, setIsAdvancedExpanded] = useState(
        getValues("config.bind_host") === true
    );

    const onSubmit = async (
        data: CreateLoadBalancerServiceJobApiArg["body"]["contents"]
    ) => {
        setFormError(undefined);

        if (!data?.config?.ipv4 && !data?.config?.ipv6) {
            setFormError("Must have at least one public IP");
            return;
        }

        return updateConfig({
            environmentId: environment?.id || "",
            body: {
                action: "reconfigure",
                contents: data,
            },
        })
            .unwrap()
            .then(() => reset(data))
            .catch(handleSubmitError(form.setError));
    };

    const type = watch("config.type");
    const bindHost = watch("config.bind_host");

    // When changing the type, set details to null so that users
    // are on the platform default config unless they choose to
    // make a change
    useEffect(() => {
        setValue("config.details", null);
    }, [type]);

    useEffect(() => {
        if (isAdvancedExpanded) {
            return;
        }
        setIsAdvancedExpanded(bindHost === true);
    }, [bindHost]);

    useKeepFormCurrent(form, lbInfo, (i) => getDefaultValues(i));

    const loadBalancerDisabled =
        environment?.services?.loadbalancer?.enable === false;

    const hasManageCap = useCapability("environments-services-manage");

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Settings" />
                <PanelContent>
                    <div className="w-full">
                        <RhfFormField label="Type" name="config.type">
                            {hasManageCap instanceof Error ? (
                                <TextInput
                                    disabled
                                    value={lbInfo?.current_type}
                                />
                            ) : (
                                <Controller
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <BasicSelect
                                            {...field}
                                            className={
                                                !lbInfo && !loadBalancerDisabled
                                                    ? skeletonStyles
                                                    : ""
                                            }
                                            disabled={loadBalancerDisabled}
                                            options={[
                                                {
                                                    label: `Platform Default ${
                                                        lbInfo
                                                            ? `(${lbInfo?.default_type})`
                                                            : ""
                                                    }`,
                                                    value: "default",
                                                },
                                                {
                                                    value: "v1",
                                                    label: "V1 (Beta)",
                                                },
                                                {
                                                    value: "haproxy",
                                                    label: "HAProxy",
                                                },
                                            ]}
                                            value={field.value || undefined}
                                        />
                                    )}
                                    control={control}
                                    name="config.type"
                                />
                            )}
                        </RhfFormField>

                        <RhfFormField
                            label="Public IPV4"
                            name="config.ipv4"
                            className="relative"
                        >
                            <div className="absolute inset-0 flex justify-end">
                                <Checkbox
                                    {...register("config.ipv4")}
                                    disabled={loadBalancerDisabled}
                                />
                            </div>
                        </RhfFormField>

                        <RhfFormField
                            label="Public IPV6"
                            name="config.ipv6"
                            className="relative"
                        >
                            <div className="absolute inset-0 flex justify-end">
                                <Checkbox
                                    {...register("config.ipv6")}
                                    disabled={loadBalancerDisabled}
                                />
                            </div>
                        </RhfFormField>

                        <RhfFormField
                            label="High Availablity"
                            name="high_availability"
                            className="relative"
                        >
                            <div className="absolute inset-0 flex justify-end">
                                <Checkbox
                                    {...register("high_availability")}
                                    disabled={loadBalancerDisabled}
                                />
                            </div>
                        </RhfFormField>

                        {/* TODO: Change this to be a reusable component of some kind */}
                        <div className="flex items-center justify-between pb-4">
                            <div
                                className={classNames(
                                    "flex items-center pb-2 text-sm font-semibold "
                                )}
                            >
                                <FormFieldLabel label="Auto Update" />

                                <HelpInfoCircle
                                    message={
                                        "Load Balancer will update automatically as new versions are released"
                                    }
                                    className="ml-2 py-0"
                                />
                            </div>

                            <Checkbox
                                {...register("auto_update")}
                                disabled={loadBalancerDisabled}
                            />
                        </div>

                        <FormSectionHeader header="Advanced">
                            <div className="flex grow items-center justify-end pl-4">
                                <div className="flex items-center gap-8">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setIsAdvancedExpanded(
                                                !isAdvancedExpanded
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className={classNames(
                                                !isAdvancedExpanded
                                                    ? "-rotate-90"
                                                    : ""
                                            )}
                                        />
                                    </button>
                                </div>
                            </div>
                        </FormSectionHeader>
                        <FormSection
                            className={classNames(
                                isAdvancedExpanded ? "block" : "hidden"
                            )}
                        >
                            <RhfFormField
                                label="Bind Host IP"
                                name="config.bind_host"
                                className="flex justify-between"
                                help="Bind to the host IP. Reduces cost and latency, but limits to 1 environment on the host."
                            >
                                <div className="flex items-center">
                                    <Checkbox
                                        {...register("config.bind_host")}
                                        disabled={loadBalancerDisabled}
                                    />
                                </div>
                            </RhfFormField>
                        </FormSection>

                        <PanelFooter>
                            <div>
                                <div>
                                    {formError && (
                                        <p className="text-error pb-2">
                                            {formError}
                                        </p>
                                    )}
                                    <RhfGlobalFormError className="pb-2" />
                                </div>
                                <div className="flex w-full justify-end">
                                    <AccessControlOverlay
                                        aclResource={environment}
                                        verifyFn={modifyAccessFn(
                                            "environments-services-manage"
                                        )}
                                    >
                                        <PushAndHoldButton
                                            flavor="primary"
                                            type="button"
                                            onClick={handleSubmit(onSubmit)}
                                            icon={faEdit}
                                            isLoading={isSubmitting}
                                            disabled={!isDirty && !formError}
                                        >
                                            Update Load Balancer
                                        </PushAndHoldButton>
                                    </AccessControlOverlay>
                                </div>
                            </div>
                        </PanelFooter>
                    </div>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
