import {
    Checkbox,
    FormField,
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextAreaInput,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import {
    faBinaryLock,
    faLock,
    faUnlock,
} from "@fortawesome/pro-solid-svg-icons";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { useFormContext, useWatch } from "react-hook-form";
import { ScopedVariableFormSubmitType } from "..";
import { ScopedVariableEncryptForm } from "./encryption/ScopedVariableEncryptForm";
import { ScopedVariableDecryptForm } from "./encryption/ScopedVariableDecryptForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import classNames from "classnames";

export function ScopedVariablesRaw() {
    const { register, control, setValue, unregister } =
        useFormContext<ScopedVariableFormSubmitType>();

    const source = useWatch({ name: "source", control });
    const iv = useWatch({ name: "source.details.secret.iv", control });
    const blob = useWatch({ name: "source.details.blob", control });

    const [protectedValue, setProtectedValue] = useState(!!iv);

    useEffect(() => {
        setProtectedValue(!!iv);
    }, [iv]);

    useEffect(() => {
        if (blob) {
            setValue("access.env_variable", undefined, { shouldDirty: true });
        }
    }, [blob]);
    if (source.type !== "raw") {
        return null;
    }

    return (
        <>
            <FormSectionHeader header="RAW" />
            <FormSection>
                <div className="flex gap-4">
                    <div className="w-full">
                        <RhfFormField
                            label="Value"
                            name="source.details.value"
                            required
                        >
                            {source.details.blob ? (
                                <>
                                    <TextAreaInput
                                        rows={3}
                                        spellCheck={false}
                                        {...register("source.details.value", {
                                            ...required(),
                                        })}
                                    />
                                    {source.details.secret?.iv ? (
                                        <FontAwesomeIcon icon={faLock} />
                                    ) : null}
                                </>
                            ) : (
                                <div className="mr-4 flex items-center">
                                    <TextInput
                                        spellCheck={false}
                                        {...register("source.details.value", {
                                            ...required(),
                                        })}
                                        className={classNames(
                                            protectedValue && "rounded-r-none"
                                        )}
                                        disabled={protectedValue}
                                    />
                                    {protectedValue ? (
                                        <Tooltip message="Encrypted.  Click to edit.">
                                            <Button
                                                onClick={() => {
                                                    setProtectedValue(false);
                                                    setValue(
                                                        "source.details.secret",
                                                        {},
                                                        { shouldDirty: true }
                                                    );
                                                }}
                                                className="rounded-l-none"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faBinaryLock}
                                                />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                </div>
                            )}
                        </RhfFormField>
                    </div>
                    <FormField label="blob" className="!w-40">
                        <Checkbox {...register("source.details.blob")} />
                    </FormField>
                    <FormField label="secret" className="!w-40">
                        <Checkbox
                            checked={!!source.details.secret}
                            onChange={(v) => {
                                if (!v.target.checked) {
                                    unregister("source.details.secret");
                                    setValue(
                                        "dirtyField",
                                        "source.details.secret",
                                        { shouldDirty: true }
                                    );
                                } else {
                                    setValue(
                                        "source.details.secret",
                                        {},
                                        { shouldDirty: true }
                                    );
                                }
                            }}
                        />
                    </FormField>
                    <div className="flex justify-between">
                        <div className="flex pt-6 ">
                            <div>
                                <PositionedMenu
                                    className="w-[20rem]"
                                    render={() => <ScopedVariableEncryptForm />}
                                    placement="bottom"
                                >
                                    <Button
                                        icon={faBinaryLock}
                                        disabled={!source.details.secret}
                                        className=" rounded-r-none !border-r-0"
                                    >
                                        Encrypt
                                    </Button>
                                </PositionedMenu>
                            </div>
                            <div>
                                <PositionedMenu
                                    className="w-80"
                                    render={() => <ScopedVariableDecryptForm />}
                                    placement="bottom"
                                >
                                    <Button
                                        icon={faUnlock}
                                        disabled={!source.details.secret}
                                        className="!border-l-none flex items-center rounded-l-none"
                                    >
                                        Decrypt
                                    </Button>
                                </PositionedMenu>
                            </div>
                        </div>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
