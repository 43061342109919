import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    ChangePasswordApiArg,
    useChangePasswordMutation,
} from "~/services/cycle";

type UpdatePasswordSubmitType = ChangePasswordApiArg["body"] & {
    confirmNew: string;
};

export function UpdatePassword() {
    const form = useForm<UpdatePasswordSubmitType>({
        ...rhfConfig,
    });

    const {
        register,
        watch,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updatePassword] = useChangePasswordMutation();
    const onSubmit = (data: UpdatePasswordSubmitType) => {
        if (!isDirty) {
            return;
        }
        return updatePassword({
            body: {
                current: data.current,
                new: data.new,
            },
        })
            .unwrap()
            .then(
                () =>
                    form.reset({
                        current: "",
                        new: "",
                        confirmNew: "",
                    }),
                handleSubmitError(form.setError)
            );
    };

    return (
        <Panel>
            <PanelTitle title="Update Password" />
            <RhfFormProvider {...form}>
                <PanelContent>
                    <RhfFormField label="Current Password" name="current">
                        <TextInput
                            type="password"
                            {...register("current", { ...required() })}
                        />
                    </RhfFormField>
                    <RhfFormField label="New Password" name="new">
                        <TextInput
                            type="password"
                            autoComplete="off"
                            {...register("new", { ...required() })}
                        />
                    </RhfFormField>
                    <RhfFormField label="Confirm Password" name="confirmNew">
                        <TextInput
                            autoComplete="off"
                            type="password"
                            {...register("confirmNew", {
                                ...required(),
                                validate: (val: string) => {
                                    if (watch("new") !== val) {
                                        return "Passwords must match";
                                    }
                                },
                            })}
                        />
                    </RhfFormField>

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <PushAndHoldButton
                            icon={faEdit}
                            type="button"
                            onClick={form.handleSubmit(onSubmit)}
                            flavor="primary"
                            disabled={!isDirty}
                            isLoading={isSubmitting}
                        >
                            Change Password
                        </PushAndHoldButton>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
