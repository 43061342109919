import { createContext } from "react";

type MenuContext = {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen: (open: boolean) => void;
};

export const MenuContext = createContext<MenuContext>({
    isMobileMenuOpen: false,
    setIsMobileMenuOpen: () => null,
});
