/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";
import {
    FieldPathByValue,
    PathValue,
    FieldValues,
    useFormContext,
    Control,
    useFieldArray,
    UseFieldArrayReturn,
    useWatch,
    UseFormRegister,
} from "react-hook-form";

type ObjectFieldName<TFieldValues extends FieldValues> = FieldPathByValue<
    TFieldValues,
    Record<string, any> | undefined | null
>;

type RecordToArray<T extends Record<string, any>> = Array<{
    key: string;
    value: T[keyof T];
}>;

type ControlFieldValues<T> = T extends Control<infer R> ? R : never;

type DynamicObjectFieldProps<
    TControl extends Control<any> = Control<any>,
    TFieldValues extends ControlFieldValues<TControl> = ControlFieldValues<TControl>,
    TName extends ObjectFieldName<TFieldValues> = ObjectFieldName<TFieldValues>
> = {
    control: TControl;
    field: TName;
    unregisterOnEmpty?: boolean;
    children: (
        fields: UseFieldArrayReturn<
            {
                __map: RecordToArray<
                    NonNullable<PathValue<TFieldValues, TName>>
                >;
            },
            "__map"
        > & {
            fieldKey: "__map";
            register: UseFormRegister<
                TFieldValues & {
                    __map: RecordToArray<
                        NonNullable<PathValue<TFieldValues, TName>>
                    >;
                }
            >;
        }
    ) => React.ReactNode;
};

/**
 * A component for handling dynamic map form types as if they were field arrays.
 * **WARNING**
 *
 * This function makes use of `any` to get around the fact that we 'invent' a hidden
 * field on the passed form. The field is transparent to the consumer and is not exposed
 * to them, so it is relatively safe as long as things are properly tested here
 * if any changes are made.
 *
 * **WARNING**
 */
export function DynamicObjectField<
    T extends FieldValues,
    K extends ObjectFieldName<T>
>({
    field,
    control,
    children,
}: DynamicObjectFieldProps<Control<T>, ControlFieldValues<Control<T>>, K>) {
    const { setValue, register } = useFormContext<T>();

    const externalValues = useWatch({ name: field, control });

    const isInternalChange = useRef(false);

    const arrValues = useWatch({ name: `__map-${field}` as any, control });

    const r = useFieldArray({
        control: control,
        name: `__map-${field}` as any,
    });

    useEffect(() => {
        // Only trigger the sync use effect if a change is detected that was
        // not a direct response to interaction with the DynamicObjectField
        if (isInternalChange.current === true) {
            isInternalChange.current = false;
            return;
        }

        const fields = externalValues;
        const a = Object.entries(fields || {}).map(([key, value]) => ({
            key,
            value,
        }));
        setValue(`__map-${field}` as any, a as any, { shouldDirty: false });
    }, [externalValues]);

    useEffect(() => {
        if (!arrValues) {
            return;
        }

        const map = arrValues.reduce(
            (acc: PathValue<T, K>, cur: { key: string; value: any }) => {
                acc[cur.key] = cur.value;
                return acc;
            },
            {} as PathValue<T, K>
        );

        isInternalChange.current = true;

        // Only set dirty if map is not empty. Otherwise creates "false positive" isDirty response on init.
        // In the case of removing an entry and ending up with an empty object, isDirty is still triggered
        // due to the use of the "remove" fieldArray function that is registered to the form.
        setValue(field, map, { shouldDirty: arrValues.length });
    }, [arrValues]);

    return (
        <div>
            {children({
                ...(r as any),
                fieldKey: `__map-${field}` as "__map",
                register: register as unknown as UseFormRegister<
                    T & {
                        __map: RecordToArray<NonNullable<PathValue<T, K>>>;
                    }
                >,
            })}
        </div>
    );
}
