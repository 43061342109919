import { $info } from "@cycleplatform/core/util/log";
import { faChartNetwork } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

type IntegrationLogoProps = {
    vendor: string;
    theme?: "dark" | "light" | "auto";
    className?: string;
};

export function IntegrationLogo({
    vendor,
    theme = "dark",
    className,
}: IntegrationLogoProps) {
    const imageRef = useRef<HTMLImageElement>(null);
    const [shouldUseFallback, setShouldUseFallback] = useState<boolean>();

    useEffect(() => {
        setShouldUseFallback(undefined);
    }, [vendor]);

    if (shouldUseFallback === true) {
        return (
            <FontAwesomeIcon
                icon={faChartNetwork}
                className={classNames(
                    className,
                    "text-cycle-blue h-10 !max-w-[2rem]"
                )}
            />
        );
    }

    return (
        <img
            onLoad={() => {
                $info(`${vendor} image loaded`);
                setShouldUseFallback(false);
            }}
            onError={() => {
                $info(`${vendor} fallback loaded`);
                setShouldUseFallback(true);
            }}
            ref={imageRef}
            className={classNames(className, "h-10")}
            alt={vendor}
            src={`https://static.cycle.io/portal/integrations/${vendor}${
                theme === "light" ? "-light-bg" : ""
            }.svg`}
        />
    );
}
