import { useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";

export type IdSelectType = {
    ids: string[];
};

export function useIdSelectForm() {
    const form = useForm<IdSelectType>({
        defaultValues: {
            ids: [],
        },
        ...rhfConfig,
    });

    return form;
}
