import {
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
} from "@cycleplatform/ui/components/tables";

import { SkeletonHeader } from "./SkeletonHeader";
import { SkeletonTableRows } from "./SkeletonTableRows";

type SkeletonTableProps = {
    rows?: number;
    columns?: number;
};

export function SkeletonTable({ rows = 2, columns = 5 }: SkeletonTableProps) {
    return (
        <StyledDataTable>
            <StyledTableHead>
                {new Array(columns).fill(0).map((r, idx) => (
                    <StyledHeaderCell key={`head-${idx}`}>
                        <SkeletonHeader size="sm" />
                    </StyledHeaderCell>
                ))}
            </StyledTableHead>
            <tbody>
                <SkeletonTableRows rows={rows} columns={columns} />
            </tbody>
        </StyledDataTable>
    );
}
