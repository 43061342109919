import {
    TextAreaInput,
    TextAreaInputProps,
} from "@cycleplatform/ui/components/forms";
import { forwardRef } from "react";

type PublicKeyInputProps = TextAreaInputProps;

export const PublicKeyInput = forwardRef<
    HTMLTextAreaElement,
    PublicKeyInputProps
>(function PublicKeyInput(props: PublicKeyInputProps, ref) {
    return (
        <TextAreaInput
            placeholder="Begins with 'ssh-rsa', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384', 'ecdsa-sha2-nistp521', 'ssh-ed25519', 'sk-ecdsa-sha2-nistp256@openssh.com', or 'sk-ssh-ed25519@openssh.com'."
            rows={5}
            {...props}
            ref={ref}
        />
    );
});
