import {
    FormSection,
    RhfFormField,
    TextInput,
    isTaggedImage,
    required,
    FormSectionHeader,
    RhfEnableDisableFormSection,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DockerImageSelect } from "~/components/images/DockerImageSelect";
import { ImageSourceCreateEditSubmitType } from "../util";
import { DockerHubOrigin } from "~/services/cycle";

export function DockerHubForm() {
    const { control } = useFormContext<ImageSourceCreateEditSubmitType>();
    return (
        <>
            <FormSectionHeader header="Target" />
            <FormSection>
                <div className="flex gap-4">
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <DockerImageSelect {...field} />
                        )}
                        rules={{
                            ...required(),
                            validate: {
                                ...isTaggedImage(),
                            },
                        }}
                        control={control}
                        name={"origin.details.target"}
                    />
                </div>
                <AuthSection />
            </FormSection>
        </>
    );
}

function AuthSection() {
    const { register, unregister, setValue, watch } =
        useFormContext<ImageSourceCreateEditSubmitType>();

    const currentDetails = watch("origin.details");
    const currentToken = watch("origin.details.token");
    const [auth, setAuth] = useState(!!currentToken);

    const handleAuthChange = (b: boolean) => {
        setAuth(b);

        setValue(
            "origin.details",
            {
                ...currentDetails,
                username: "",
                token: "",
            } as DockerHubOrigin["details"],
            { shouldDirty: true }
        );

        if (!b) {
            unregister("origin.details.username", { keepDirty: true });
            unregister("origin.details.token", { keepDirty: true });
        }
    };

    return (
        <RhfEnableDisableFormSection
            header="Auth"
            isExpanded={auth}
            onToggle={(b) => handleAuthChange(b)}
        >
            {(isExpanded) => (
                <>
                    {isExpanded && (
                        <div className="flex gap-4">
                            <RhfFormField
                                label="username"
                                name="origin.details.username"
                            >
                                <TextInput
                                    {...register("origin.details.username", {
                                        shouldUnregister: true,
                                    })}
                                />
                            </RhfFormField>
                            <RhfFormField
                                label="token"
                                name="origin.details.token"
                            >
                                <TextInput
                                    {...register("origin.details.token", {
                                        shouldUnregister: true,
                                    })}
                                    type="password"
                                />
                            </RhfFormField>
                        </div>
                    )}
                </>
            )}
        </RhfEnableDisableFormSection>
    );
}
