import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CyclePieTooltip = ({
    active,
    payload,
    formatValue = (v: number) => `${v}`,
    /**
     *   if the label value used for the data is not "label" specify the key corresponding to the label as "dataLabel"
     */
    dataLabel = "label",
    dataValue = "value",
}: {
    formatValue?: (v: number) => string;
    dataLabel?: string;
    dataValue?: string;
    [key: string]: unknown;
}) => {
    if (active && payload && Array.isArray(payload) && payload.length) {
        const data = payload[0].payload.payload;

        return (
            <div className="whitespace-nowrap rounded-md border bg-white p-2 text-xs ">
                <div className="flex items-center gap-2 text-xs">
                    <FontAwesomeIcon
                        icon={faCircle}
                        className="w-3"
                        color={data?.color}
                    />
                    {data[dataLabel]} [
                    {formatValue(data?.[dataValue] as number)}]
                </div>
            </div>
        );
    }
    return null;
};
