import { selectActiveHub } from "~/modules/hubs/slice";
import { Container, useGetIntegrationsQuery } from "~/services/cycle";
import { BackupsIntegration } from "./BackupsIntegration";
import { FilesIntegration } from "./FilesIntegration";
import { LetsEncryptIntegration } from "./letsEncrypt/LetsEncryptIntegration";
import { WebhooksIntegration } from "./WebhooksIntegration";
import { useAppSelector } from "~/hooks";
import { Link } from "react-router-dom";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { SharedDirectoriesIntegration } from "./SharedDirectoriesIntegration";
import { ToggleFormSection } from "@cycleplatform/react-hook-form-components/components";
import { ContainerConfigFormData } from "../types";

type IntegrationsSectionProps = {
    container?: Container;
};

export function IntegrationsSection({ container }: IntegrationsSectionProps) {
    if (!container) {
        return null;
    }

    const hub = useAppSelector(selectActiveHub);
    const isServiceCont = isServiceContainer(container);
    const isHypervisor = isHypervisorContainer(container);

    const { data: integrations } = useGetIntegrationsQuery({
        filter: {
            category: "object-storage",
        },
    });

    const hasBackupSupport =
        integrations?.data && integrations?.data.length > 0;

    return (
        <Panel>
            <PanelTitle title="Integrations" className="flex justify-start " />
            <PanelContent>
                <SectionDisabledControl
                    disabled={isServiceCont || isHypervisor}
                >
                    <ToggleFormSection<ContainerConfigFormData>
                        header="Files"
                        field="config.integrations.files"
                    >
                        {() => <FilesIntegration />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Shared File System"
                        field="config.integrations.shared_file_systems"
                    >
                        {() => <SharedDirectoriesIntegration />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Let's Encrypt"
                        field="config.integrations.lets_encrypt"
                    >
                        {() => <LetsEncryptIntegration />}
                    </ToggleFormSection>

                    <ToggleFormSection<ContainerConfigFormData>
                        header="Webhooks"
                        field="config.integrations.webhooks"
                    >
                        {() => <WebhooksIntegration />}
                    </ToggleFormSection>

                    {hub && container ? (
                        <div className="relative">
                            <SectionDisabledControl
                                disabled={
                                    !container.stateful || !hasBackupSupport
                                }
                            >
                                {!container.stateful || !hasBackupSupport ? (
                                    <div
                                        className="absolute -top-0 -right-2 -left-2 -bottom-0 z-20 flex justify-center rounded-md bg-black/30"
                                        aria-hidden="true"
                                    >
                                        <InfoPanel
                                            className="bg-cycle-white dark:text-cycle-white dark:bg-black"
                                            type="info"
                                        >
                                            <>
                                                {!container.stateful ? (
                                                    "Backups are only available for stateful containers."
                                                ) : !hasBackupSupport ? (
                                                    <span>
                                                        No backup providers
                                                        configured. Enable one
                                                        in the{" "}
                                                        <Link to="/hub/integrations">
                                                            hub integration
                                                            settings.
                                                        </Link>
                                                    </span>
                                                ) : undefined}
                                            </>
                                        </InfoPanel>
                                    </div>
                                ) : null}

                                <ToggleFormSection<ContainerConfigFormData>
                                    header="Backups"
                                    field="config.integrations.backups"
                                >
                                    {() => (
                                        <BackupsIntegration
                                            container={container}
                                        />
                                    )}
                                </ToggleFormSection>
                            </SectionDisabledControl>
                        </div>
                    ) : null}
                </SectionDisabledControl>
            </PanelContent>
        </Panel>
    );
}
