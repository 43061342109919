import { Checkbox } from "@cycleplatform/ui/components/forms";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { IdSelectType } from "./hooks";

type RhfIdCheckboxProps = {
    id: string;
    disabled?: boolean;
    className?: string;
};

export function RhfIdCheckbox({ id, disabled, className }: RhfIdCheckboxProps) {
    const { register } = useFormContext<IdSelectType>();
    return (
        <Checkbox
            {...register("ids")}
            value={id}
            disabled={disabled}
            className={classNames(className)}
        />
    );
}
