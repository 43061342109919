import { FormToggle } from "@cycleplatform/ui/components/forms";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    FieldPath,
    FieldPathValue,
    FieldValues,
    useFormContext,
    useWatch,
} from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";

type TogglePanelContentProps<TFieldValues extends FieldValues> = {
    field: FieldPath<TFieldValues>;
    toggleDisabled?: boolean;

    children: () => React.ReactNode;
    title: React.ReactNode;
    defaultOpen?: boolean;
    onToggle?: (v: boolean) => void;
    help?: string;
    toggleTooltip?: string;
    /** Optional custom check to decide whether this section is active or not */
    initialIsActive?: (value: unknown) => boolean;
    /** Optional function to call to unregister, otherwise calls form unregister  */
    unregisterFn?: (field: string) => void;
    disabledFallback: (toggle: () => void) => React.ReactNode;
};

/**
 * Includes everything needed to enable/disable a full Form Panel Section (example "Resources" in ContainerConfig)
 * Use when you want to be able to enable/disable an entire Paneled Section of the form.
 *
 * Requires a disabled Fallback where messaging is provided, as well as "toggle" functionality
 * provided to the fallback for enabling the section.
 */
export function TogglePanelContent<TFieldValues extends FieldValues>({
    field,
    children,
    title,
    help,
    onToggle,
    initialIsActive = (value) => !!value,
    unregisterFn,
    toggleDisabled,
    toggleTooltip,
    disabledFallback,
}: TogglePanelContentProps<TFieldValues>) {
    const { getValues, setValue, unregister, control } = useFormContext();
    const fieldVal = useWatch({ control, name: field });

    const [isEnabled, setIsEnabled] = useState(initialIsActive(fieldVal));

    const [prevState, setPrevState] =
        useState<FieldPathValue<TFieldValues, typeof field>>();

    const toggle = useCallback(() => {
        setValue("_isDirty", true, { shouldDirty: true });

        if (isEnabled) {
            setPrevState(getValues(field));
            setIsEnabled(false);
            onToggle?.(false);
            if (unregisterFn) {
                unregisterFn(field);
            } else {
                unregister(field, undefined);
            }
            return;
        }

        setIsEnabled(true);

        if (prevState) {
            setValue(field, prevState);
        }
        onToggle?.(true);
    }, [isEnabled, setIsEnabled, onToggle]);

    useEffect(() => {
        const isActive = initialIsActive(fieldVal);

        if (isActive !== isEnabled) {
            setIsEnabled(isActive);
        }
    }, [fieldVal]);

    return (
        <Panel>
            <PanelTitle
                title={title}
                help={help}
                className="flex justify-between"
            >
                <Tooltip message={toggleTooltip} disabled={!toggleTooltip}>
                    <FormToggle
                        disabled={toggleDisabled}
                        value={isEnabled}
                        onChange={() => {
                            toggle();
                        }}
                    />
                </Tooltip>
            </PanelTitle>

            {isEnabled ? (
                <ExpandedContentWrapper>{children()}</ExpandedContentWrapper>
            ) : (
                <PanelContent>{disabledFallback(toggle)}</PanelContent>
            )}
        </Panel>
    );
}

function ExpandedContentWrapper({ children }: { children: React.ReactNode }) {
    if (children === <></>) {
        return null;
    }
    return <PanelContent>{children}</PanelContent>;
}
