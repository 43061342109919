import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { ResourceComboBox } from "./ResourceComboBox";
import { required } from "@cycleplatform/ui/components/forms";
import { ResourceWithId } from "~/modules/resource";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { getCountriesData } from "@cycleplatform/core/util";

type RhfCountrySelectProps = {
    path: string;
    disabled?: boolean;
    isRequired?: boolean;
};

export function RhfCountrySelect({
    path,
    disabled,
    isRequired,
}: RhfCountrySelectProps) {
    const { control, watch } = useFormContext();

    watch(path);

    const [countries, setCountries] = useState<
        (ResourceWithId & { label: string })[]
    >([]);

    useEffect(() => {
        const getData = async () => {
            setCountries(await getCountriesData());
        };
        getData();
    }, []);
    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => (
                <ResourceComboBox
                    {...field}
                    disabled={disabled}
                    resources={countries}
                    placeholder="Select a Country"
                    formatDisplayValue={(v) => {
                        return v?.label || "";
                    }}
                    formatOption={(v) => <FormattedOption label={v.label} />}
                    filterFields={["label"]}
                />
            )}
            rules={isRequired ? { ...required() } : {}}
            control={control}
            name={path}
        />
    );
}
