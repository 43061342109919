import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { useContext, useEffect, useMemo, useState } from "react";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";

import { processAggregateCpuInstanceData } from "~/components/instances/telemetry/charts/cpu/helpers";
import { CpuUsageByInstanceChart } from "~/components/instances/telemetry/charts/cpu/CpuUsageByInstanceChart";
import { CpuUsageByInstanceTable } from "~/components/instances/telemetry/charts/cpu/CpuUsageByInstanceTable";
import {
    AggregateCpuDataPoint,
    buildCpuUsageByInstanceQuery,
    buildFindTopCpuUsageInstancesQuery,
} from "~/components/instances/telemetry/charts/cpu/query";
import { getInstanceIdsFromAggregateData } from "~/components/instances/telemetry/helpers";
import { InstanceChartTelemetryContext } from "../context";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export function CpuSection() {
    const {
        filtering: { granularity, instanceLimit, range, isBuffering },
        instanceColorMap,
        instanceLabelMap,
        components,
        registerIds,
        criteria,
        tableInclude,
    } = useContext(InstanceChartTelemetryContext);

    const {
        currentData: tId,
        isLoading: tIsLoading,
        error: tError,
    } = useGenerateAggregatedMetricsQuery(
        buildFindTopCpuUsageInstancesQuery({
            criteria: criteria || {},
            granularity,
            limit: instanceLimit,
            range,
        }),
        {
            skip: !criteria || isBuffering,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const topIds = tId?.data?.[0]?.instanceIds as string[] | undefined;

    const {
        data: cpuUsage,
        error,
        isLoading,
    } = useGenerateAggregatedMetricsQuery(
        buildCpuUsageByInstanceQuery({
            criteria: criteria || {},
            granularity,
            instanceIds: topIds,
            range,
        }),
        {
            skip: !criteria || !topIds || isBuffering,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const data = useMemo(
        () =>
            processAggregateCpuInstanceData(
                cpuUsage?.data as AggregateCpuDataPoint[] | undefined,
                granularity
            ),
        [cpuUsage?.data]
    );

    const { instanceIds } = getInstanceIdsFromAggregateData(data);

    useEffect(() => {
        if (!topIds) {
            return;
        }
        registerIds(topIds || []);
    }, [topIds]);

    if (error) {
        throw error;
    }
    if (tError) {
        throw error;
    }

    const renderLoading = isLoading || tIsLoading || isBuffering;

    return (
        <Panel>
            <PanelTitle title={`CPU Usage`} />
            <PanelContent stretch className="border-none">
                <div
                    className={classNames(
                        "m-4 h-60",
                        renderLoading && skeletonStyles
                    )}
                >
                    {data && (
                        <CpuUsageByInstanceChart
                            data={data}
                            instanceColorMap={instanceColorMap}
                            instanceLabelMap={instanceLabelMap}
                            instanceIds={instanceIds}
                        />
                    )}
                </div>

                {!renderLoading && data ? (
                    <CpuUsageByInstanceTable
                        data={data}
                        instanceColorMap={instanceColorMap}
                        instanceLabelMap={instanceLabelMap}
                        components={components}
                        include={tableInclude}
                        instanceIds={instanceIds}
                    />
                ) : (
                    <SkeletonTable />
                )}
            </PanelContent>
        </Panel>
    );
}
