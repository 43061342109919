import {
    Checkbox,
    EnableSectionInline,
    FormField,
    RhfFormField,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    MultiSelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerRuntimeNamespaces } from "~/modules/containers/schemas";
import { ContainerConfigFormData } from "../types";
import { useMemo, useState } from "react";
import classNames from "classnames";

// https://linear.app/cycleplatform/issue/ENG-1609/add-customize-checkbox-for-namespaces-and-kernel-capabilities
// user namespace should never be added, mount & cgroup should never be removed.
export function NamespacesInput() {
    const { control, getValues, setValue } =
        useFormContext<ContainerConfigFormData>();

    const [isCustomized, setIsCustomized] = useState(
        getValues("config.runtime.namespaces") !== undefined
    );

    const defaultNamespaces = useMemo(
        () => [
            // Required
            "cgroup" as const,
            "mount" as const,
            // Optional
            "ipc" as const,
            "network" as const,
            "pid" as const,
            "uts" as const,
        ],
        []
    );

    return (
        <EnableSectionInline
            enableNode={
                <FormField
                    help="Enable custom namespaces"
                    className="!w-1/4"
                    label="Customize"
                >
                    <Checkbox
                        checked={isCustomized}
                        onChange={(ev) => {
                            setIsCustomized(ev.target.checked);
                            if (ev.target.checked) {
                                setValue(
                                    "config.runtime.namespaces",
                                    defaultNamespaces,
                                    { shouldDirty: true }
                                );
                            } else {
                                setValue(
                                    "config.runtime.namespaces",
                                    undefined,
                                    { shouldDirty: true }
                                );
                            }
                        }}
                    />
                </FormField>
            }
        >
            {isCustomized ? (
                <RhfFormField
                    label="Namespaces"
                    name={"config.runtime.namespaces"}
                    className={classNames("w-full")}
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => {
                            const value = field.value
                                ? [
                                      ...new Set([
                                          "mount",
                                          "cgroup",
                                          ...field.value,
                                      ]),
                                  ]
                                : defaultNamespaces;
                            return (
                                <MultiSelectInput
                                    {...field}
                                    placeholder="No namespaces enabled..."
                                    isOptionValid={(o) =>
                                        !["user", "cgroup", "mount"].includes(o)
                                    }
                                    value={value}
                                    options={ContainerRuntimeNamespaces.options}
                                    formatOption={(o) => (
                                        <FormattedOption label={o} />
                                    )}
                                    formatDisplayValue={(o) => o}
                                />
                            );
                        }}
                        control={control}
                        shouldUnregister
                        name={"config.runtime.namespaces"}
                    />
                </RhfFormField>
            ) : (
                <FormField label="Namespaces" className={classNames("w-full")}>
                    <MultiSelectInput
                        disabled={true}
                        value={defaultNamespaces}
                        formatDisplayValue={(o) => o}
                    />
                </FormField>
            )}
        </EnableSectionInline>
    );
}
