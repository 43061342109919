import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    ExpandableFormSection,
    FieldErrorMessage,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import {
    BillingOrder,
    CreateOrderApiArg,
    PromoCode,
    useCreateOrderMutation,
} from "~/services/cycle";
import { useSetHubPlanParams } from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft,
    faCheck,
    faPlus,
    faTag,
} from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { getAllDialogSearchParams } from "../../../../../helpers";
import { Badge } from "@cycleplatform/ui/components/badges";
import { PromoInfo } from "./OrderConfirmationStep";
import { TierCredit } from "./TierCredit";
import { handleSubmitError } from "~/components/forms/util";

export function AddPromoCode({
    promoInfo,
    setPromoInfo,
}: {
    promoInfo: PromoInfo | undefined;
    setPromoInfo: (info: PromoInfo) => void;
}) {
    const form = useFormContext<CreateOrderApiArg["body"]>();
    const { control, setValue } = form;

    const promoParam =
        getAllDialogSearchParams<"change-hub-plan">()["dialog-promo-code"];

    const promoCode = useWatch({ name: "promo_code", control });
    const tierPlanId = useWatch({ name: "tier_plan_id", control });

    const [err, setError] = useState<string>();
    const [success, setSuccess] = useState(false);

    const [createOrder, { isLoading }] = useCreateOrderMutation();
    const setHubPlanParams = useSetHubPlanParams();

    const addPromo = () => {
        if (!promoCode || !tierPlanId) {
            return;
        }

        return createOrder({
            include: ["promo_codes"],
            body: {
                promo_code: promoCode,
                tier_plan_id: tierPlanId,
            },
        })
            .unwrap()
            .then(
                (o) => {
                    setSuccess(true);
                    setError(undefined);
                    setHubPlanParams({ promo: promoCode });
                    setPromoInfo({
                        order: o.data,
                        promo: o.includes?.promo_codes?.[
                            o.data.promo_code_id || ""
                        ],
                    });
                },
                (err) => {
                    handleSubmitError(form.setError)(err);
                    setHubPlanParams({ promo: null });
                }
            );
    };

    useEffect(() => {
        if (promoCode) {
            addPromo();
        }
    }, []);

    return (
        <ExpandableFormSection
            contentClassName="!pr-0"
            defaultOpen={!!promoParam}
            header={
                <div className="flex items-center gap-4">
                    <FontAwesomeIcon icon={faTag} className="text-cycle-blue" />{" "}
                    Apply Promo Code
                    {success ? (
                        <Badge className="bg-cycle-blue-accent flex gap-2 text-sm ">
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="text-success"
                            />
                            {promoCode}
                        </Badge>
                    ) : null}
                </div>
            }
        >
            {() => (
                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-4 ">
                        <RhfFormField label="Promo Code" name="promo_code">
                            <TextInput
                                disabled={success}
                                {...form.register("promo_code", {
                                    ...required(),
                                    setValueAs: (v) => (v ? v : undefined),
                                })}
                            />
                        </RhfFormField>
                        <div className="pt-4">
                            {!success ? (
                                <LoaderButton
                                    type="button"
                                    icon={faPlus}
                                    onClick={() => {
                                        addPromo();
                                    }}
                                    isLoading={isLoading}
                                >
                                    Apply Code
                                </LoaderButton>
                            ) : (
                                <Button
                                    icon={faArrowCircleLeft}
                                    onClick={() => {
                                        setValue("promo_code", "");
                                        setSuccess(false);
                                        setHubPlanParams({
                                            promo: null,
                                        });
                                    }}
                                >
                                    Clear Promo Code
                                </Button>
                            )}
                        </div>
                    </div>
                    {err ? <FieldErrorMessage message={err} /> : null}

                    <TierCredit promo={promoInfo?.promo} />
                </div>
            )}
        </ExpandableFormSection>
    );
}
