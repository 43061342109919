import classNames from "classnames";

export function EnableSectionInline({
    enableNode,
    className,
    enableClassName,
    children,
}: {
    enableNode: React.ReactNode;
    className?: string;
    enableClassName?: string;
    children: React.ReactNode;
}) {
    return (
        <div className={classNames("mb-2 flex w-full", className)}>
            <div
                className={classNames(
                    enableClassName,
                    "min-w-[10rem] max-w-[27rem]"
                )}
            >
                {enableNode}
            </div>
            <div className="w-full gap-4 ">{children}</div>
        </div>
    );
}
