import {
    isATypeRecord,
    isAaaaTypeRecord,
    isAliasTypeRecord,
    isCaaTypeRecord,
    isCnameTypeRecord,
    isLinkedTypeRecord,
    isMxTypeRecord,
    isNsTypeRecord,
    isSrvTypeRecord,
    isTxtTypeRecord,
} from "@cycleplatform/core/modules/dns/records";
import { FieldEntry } from "./fields/FieldEntry";
import { FieldName } from "./fields/FieldName";
import { FieldContent } from "./fields/FieldContent";
import { LinkedRecordFieldEntry } from "./LinkedRecordFieldEntry";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { DnsZone, RecordIncludes } from "~/services/cycle";
export type DnsRecordFieldsProps = {
    // This does better type inference than redux's type.
    record: components["schemas"]["Record"];
    recordIncludes?: RecordIncludes;
    dnsZone?: DnsZone;
};

export function DnsRecordFields({
    record,
    recordIncludes,
    dnsZone,
}: DnsRecordFieldsProps) {
    if (isATypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="IP" />
                <FieldContent>{record.type.a.ip}</FieldContent>
            </FieldEntry>
        );
    } else if (isAaaaTypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="IP" />{" "}
                <FieldContent>{record.type.aaaa.ip}</FieldContent>
            </FieldEntry>
        );
    } else if (isCnameTypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="Domain" />
                <FieldContent>{record.type.cname.domain}</FieldContent>
            </FieldEntry>
        );
    } else if (isNsTypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="Domain" />
                <FieldContent>{record.type.ns.domain}</FieldContent>
            </FieldEntry>
        );
    } else if (isMxTypeRecord(record)) {
        return (
            <>
                <FieldEntry>
                    <FieldName fieldName="Domain" />
                    <FieldContent>{record.type.mx.domain}</FieldContent>
                </FieldEntry>
                <FieldEntry>
                    <FieldName fieldName="Priority" />
                    <FieldContent>{record.type.mx.priority}</FieldContent>
                </FieldEntry>
            </>
        );
    } else if (isTxtTypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="Value" />
                <FieldContent>{record.type.txt.value}</FieldContent>
            </FieldEntry>
        );
    } else if (isAliasTypeRecord(record)) {
        return (
            <FieldEntry>
                <FieldName fieldName="Domain" />
                <FieldContent>{record.type.alias.domain}</FieldContent>
            </FieldEntry>
        );
    } else if (isSrvTypeRecord(record)) {
        return (
            <>
                <FieldEntry>
                    <FieldName fieldName="Domain" />
                    <FieldContent>{record.type.srv.domain}</FieldContent>
                </FieldEntry>
                <FieldEntry>
                    <FieldName fieldName="Weight" />
                    <FieldContent>{record.type.srv.weight}</FieldContent>
                </FieldEntry>
                <FieldEntry>
                    <FieldName fieldName="Priority" />
                    <FieldContent>{record.type.srv.priority}</FieldContent>
                </FieldEntry>
                <FieldEntry>
                    <FieldName fieldName="Port" />
                    <FieldContent>{record.type.srv.port}</FieldContent>
                </FieldEntry>
            </>
        );
    } else if (isCaaTypeRecord(record)) {
        return (
            <>
                <FieldEntry>
                    <FieldName fieldName="Tag" />
                    <FieldContent>{record.type.caa.tag}</FieldContent>
                </FieldEntry>
                <FieldEntry>
                    <FieldName fieldName="Value" />
                    <FieldContent>{record.type.caa.value}</FieldContent>
                </FieldEntry>
            </>
        );
    } else if (isLinkedTypeRecord(record)) {
        return (
            <LinkedRecordFieldEntry
                dnsZone={dnsZone}
                record={record}
                recordIncludes={recordIncludes}
            />
        );
    }

    return null;
}
