import {
    RhfFormField,
    TextInput,
    isDurationString,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

export function DeployUpdatePolicy() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <RhfFormField
                label="stagger"
                name="config.deploy.update.stagger"
                help="When set, Cycle will pick a random time from 0 - this duration, and stagger the instances so they all start at different times"
            >
                <TextInput
                    defaultValue={"3s"}
                    {...register("config.deploy.update.stagger", {
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
        </>
    );
}
