import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export type EventPoint = {
    time: string;
    [priority: string]: number | string;
};

export function buildEventsQuery(criteria: { [key: string]: unknown }) {
    const start = formatRFC3339(
        roundToNearestMinutes(subDays(new Date(), 1), {
            roundingMethod: "floor",
            nearestTo: 30,
        })
    );
    const end = formatRFC3339(roundToNearestMinutes(new Date()));
    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria,
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $addFields: {
                        truncatedTime: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "minute",
                                binSize: 30,
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            time: "$truncatedTime",
                            type: "$metadata.type",
                        },
                        total: {
                            $sum: 1,
                        },
                        time: {
                            $last: "$truncatedTime",
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                        },
                        time: {
                            $last: "$time",
                        },
                        events: {
                            $push: {
                                k: "$_id.type",
                                v: "$total",
                            },
                        },
                    },
                },
                {
                    $project: {
                        time: 1,
                        events: {
                            $arrayToObject: "$events",
                        },
                    },
                },
                {
                    $replaceRoot: {
                        newRoot: {
                            $mergeObjects: ["$events", { time: "$time" }],
                        },
                    },
                },
                {
                    $densify: {
                        field: "time",
                        range: {
                            step: 30,
                            unit: "minute",
                            bounds: [start, end],
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
            ],
        },
    };
}
