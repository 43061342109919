import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    CreateHubInviteApiArg,
    HubMembership,
    useCreateHubInviteMutation,
} from "~/services/cycle";
import { Controller, useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    isEmail,
    required,
    FormSectionHeader,
    FormSection,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { useKeepFormCurrent } from "~/components/common/forms";
import {
    getAllDialogSearchParams,
    shouldClose,
} from "~/components/dialogs/helpers";
import { useNavigate } from "react-router-dom";
import { handleSubmitError } from "~/components/forms/util";
import { RolesSelect } from "~/components/roles/RolesSelect";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

function getDefaultValues(member?: HubMembership) {
    return {
        memberId: member?.id || "",
        role_id: member?.role_id,
    };
}

export function InviteMemberForm({
    invite,
    readOnly,
}: {
    invite?: HubMembership;
    readOnly?: boolean;
}) {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"hub-member-invite">();

    const form = useForm<CreateHubInviteApiArg["body"]>({
        defaultValues: getDefaultValues(invite),
        ...rhfConfig,
    });
    useKeepFormCurrent(form, invite, (m) => getDefaultValues(m));

    const {
        register,
        control,
        watch,
        formState: { isDirty, isSubmitting, errors },
    } = form;

    const [inviteMemberForm] = useCreateHubInviteMutation();

    const onSubmit = (data: CreateHubInviteApiArg["body"]) => {
        if (!isDirty) {
            return;
        }

        return inviteMemberForm({
            body: data,
        })
            .unwrap()
            .then(
                (m) =>
                    nav(
                        shouldClose(params["dialog-nav"]) ||
                            `/hub/invites/${m?.data?.id}`
                    ),
                handleSubmitError(form.setError)
            );
    };

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <SectionDisabledControl disabled={readOnly}>
                <FormSectionHeader header="General" />
                <FormSection>
                    {!readOnly ? (
                        <RhfFormField label="email" name="recipient" required>
                            <TextInput
                                autoComplete="off"
                                {...register("recipient", {
                                    ...required(),
                                    validate: {
                                        ...isEmail(),
                                    },
                                })}
                            />
                        </RhfFormField>
                    ) : null}

                    <RhfFormField label="role" name="role" required>
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <RolesSelect {...field} />
                            )}
                            rules={{ ...required() }}
                            control={control}
                            name="role_id"
                        />
                    </RhfFormField>
                </FormSection>
            </SectionDisabledControl>

            <DialogFooter className="items-center justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>

                <LoaderButton
                    flavor="primary"
                    icon={faMessage}
                    onClick={form.handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                    disabled={!isDirty || Object.keys(errors).length > 0}
                >
                    Invite
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
