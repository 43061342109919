import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    CreateInstancesApiArg,
    Instance,
    Server,
    useCreateInstancesMutation,
    useGetInstancesQuery,
    useDeleteInstanceMutation,
    useGetInstanceQuery,
    Capability,
    Environment,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { InstanceContent } from "./InstanceContent";
import {
    faArrowUpRightDots,
    faCubesStacked,
} from "@fortawesome/pro-duotone-svg-icons";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import {
    SectionDisabledControl,
    FormSection,
    FormSectionHeader,
    RhfFormField,
    RhfFormProvider,
    RhfSliderInput,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    DialogResourceList,
    ResourceNavHeader,
} from "~/components/layout/resources";
import { RhfMultipleActionButton } from "~/components/common/forms/IdSelect/RhfMultipleActionButton";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { useIdSelectForm } from "~/components/common/forms/IdSelect/hooks";
import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import {
    EmptyActionPanel,
    PanelFooter,
} from "@cycleplatform/ui/components/panels";
import { Controller, useForm } from "react-hook-form";
import { UsableServerSelect } from "~/components/infrastructure/UsableServerSelect";
import { DialogResourceListItem } from "~/components/layout/resources/dialogResources/DialogResourceListItem";
import { ToolTray } from "~/components/common/tooltray/ToolTray";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { ResourceStateIcon } from "@cycleplatform/ui/components/resources/state";
import {
    getInstanceState,
    getServerPurgeTime,
} from "@cycleplatform/core/modules/containers/instances";
import { handleSubmitError } from "~/components/forms/util";
import { formatDateString } from "@cycleplatform/core/util";
import { FormattedPurgeTimer } from "@cycleplatform/ui/components/resources";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import { isHypervisorContainer } from "@cycleplatform/core/modules/containers/util";
import { VmContainerBanner } from "~/components/containers/VmContainerBanner";
export type InstanceTabParent =
    | "container"
    | "environment-lb-manage"
    | "environment-discovery-manage"
    | "environment-scheduler-manage"
    | "environment-vpn-manage";

type InstanceTabProps = {
    container: Container | undefined;
    environment: Environment | undefined;
    containerId: string;
    instanceId: string;
    parent?: InstanceTabParent;
};

export function InstanceTab({
    container,
    environment,
    containerId,
    instanceId,
    parent = "container",
}: InstanceTabProps) {
    const {
        data: instances,
        isLoading,
        error,
    } = useGetInstancesQuery(
        {
            containerId,
            include: ["servers"],
            page: {
                size: 100,
                number: 1,
            },
        },
        { skip: !containerId }
    );
    const instanceInList = instances?.data?.some((i) => i.id === instanceId);

    const { data: instance, error: instanceError } = useGetInstanceQuery(
        {
            containerId,
            instanceId,
            include: ["servers"],
        },
        { skip: !instanceId || !containerId }
    );

    const navigate = useNavigate();

    const selectedInstance =
        instances?.data?.find((i) => i.id === instanceId) || instance?.data;

    const form = useIdSelectForm();

    const [deleteInstance] = useDeleteInstanceMutation();
    const isManualStrategy =
        container?.config?.deploy?.strategy === "manual" ||
        container?.creator?.type === "environment";

    // If there is no selected instance, navigate to the first instance in the array
    useEffect(() => {
        if (instanceId || !instances?.data?.[0]) {
            return;
        }

        navigate(
            generateDialogLink(parent, {
                "dialog-instance-id": instances?.data?.[0].id || "",
                "dialog-tab": "instances",
            }),
            { replace: true }
        );
    }, [instanceId, instances]);

    const mergedInstanceList = instanceInList
        ? instances?.data
        : [...(instances?.data || []), instance?.data];

    const mergedInstanceIncludes = {
        ...instances?.includes,
        servers: {
            ...instances?.includes?.servers,
            ...instance?.includes?.servers,
        },
    };
    const isHypervisor = isHypervisorContainer(container);

    if (error) {
        throw error;
    }
    if (instanceError) {
        throw instanceError;
    }

    const requiredCapability: Capability = !!container?.image?.service
        ? "environments-services-manage"
        : "containers-manage";

    return (
        <DialogPageBody
            className={classNames("relative px-0 pb-0", isHypervisor && "pt-8")}
        >
            <VmContainerBanner isHypervisor={isHypervisor || false} />
            <PageAside className="relative h-full !w-auto border-r bg-white dark:bg-black">
                <RhfFormProvider {...form} className="h-full">
                    <DialogResourceList>
                        <ResourceNavHeader>
                            <div className="flex ">
                                <TextInput
                                    placeholder="Search Instances"
                                    prefix={<FontAwesomeIcon icon={faSearch} />}
                                />
                            </div>
                        </ResourceNavHeader>
                        {!isLoading ? (
                            mergedInstanceList?.map((i) => {
                                if (!i) {
                                    return;
                                }
                                return (
                                    <InstanceItem
                                        key={i.id}
                                        instance={i}
                                        isActive={instanceId === i.id}
                                        server={
                                            mergedInstanceIncludes?.servers?.[
                                                i.server_id
                                            ]
                                        }
                                        container={container}
                                        parent={parent}
                                    />
                                );
                            })
                        ) : (
                            <>
                                {Array.from({
                                    length:
                                        container?.meta?.instances_count
                                            ?.total || 3,
                                }).map((_, idx) => (
                                    <DialogResourceListItem
                                        key={idx}
                                        isLoading={true}
                                        to=""
                                    />
                                ))}
                            </>
                        )}
                    </DialogResourceList>

                    {container && isManualStrategy ? (
                        <div
                            className={classNames(
                                "bg-cycle-white absolute bottom-0 left-0 right-0 flex w-full justify-between gap-2 p-4 shadow-lg",
                                "dark:bg-cycle-black"
                            )}
                        >
                            <PositionedMenu
                                className="w-[32rem]"
                                render={(_, setIsOpen) => (
                                    <DeployNewInstanceForm
                                        container={container}
                                        setIsOpen={setIsOpen}
                                    />
                                )}
                            >
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={modifyAccessFn(
                                        requiredCapability
                                    )}
                                >
                                    <Button className="w-full" icon={faPlus}>
                                        New
                                    </Button>
                                </AccessControlOverlay>
                            </PositionedMenu>
                            <AccessControlOverlay
                                aclResource={environment}
                                verifyFn={modifyAccessFn(requiredCapability)}
                            >
                                <RhfMultipleActionButton
                                    resourceName="instances"
                                    mutation={(id: string) =>
                                        deleteInstance({
                                            containerId: container?.id || "",
                                            instanceId: id,
                                        }).unwrap()
                                    }
                                />
                            </AccessControlOverlay>
                        </div>
                    ) : (
                        <ToolTray>
                            <RhfMultipleActionButton
                                resourceName="instances"
                                mutation={(id: string) =>
                                    deleteInstance({
                                        containerId: container?.id || "",
                                        instanceId: id,
                                    }).unwrap()
                                }
                            />
                        </ToolTray>
                    )}
                </RhfFormProvider>
            </PageAside>

            <PageContent className="h-full w-full min-w-0 overflow-y-auto">
                {!isLoading && !instances?.data?.length && !instance ? (
                    <EmptyActionPanel
                        title="No Instances"
                        details="This container has no instances deployed."
                        icon={faCubesStacked}
                    />
                ) : null}
                {container && selectedInstance ? (
                    <SectionDisabledControl
                        className="min-w-0"
                        disabled={container.state.current === "deleted"}
                        heightFull
                    >
                        <InstanceContent
                            instance={selectedInstance}
                            container={container}
                            environment={environment}
                            server={
                                instances?.includes?.servers?.[
                                    selectedInstance.server_id
                                ]
                            }
                            parent={parent}
                        />
                    </SectionDisabledControl>
                ) : null}
            </PageContent>
        </DialogPageBody>
    );
}

type InstanceItemProps = {
    instance: Instance;
    isActive?: boolean;
    server?: Server;
    container?: Container;
    parent?: InstanceTabParent;
};

function InstanceItem({
    instance,
    container,
    isActive,
    server,
    parent = "container",
}: InstanceItemProps) {
    const purgeTime = getServerPurgeTime(instance);
    const isHypervisor = isHypervisorContainer(container);

    return (
        <DialogResourceListItem
            checkboxId={instance.id}
            checkboxDisabled={container?.lock || isHypervisor}
            checkboxDisabledTooltip="Container is locked and instances cannot be deleted"
            isLoading={!instance}
            to={generateDialogLink(parent, {
                "dialog-instance-id": instance.id,
                "dialog-tab": "instances",
            })}
            state={getInstanceState(
                instance as components["schemas"]["Instance"]
            )}
            stateTooltip={
                purgeTime ? <FormattedPurgeTimer purgeTime={purgeTime} /> : null
            }
            isActive={isActive || false}
        >
            <div className={classNames("flex flex-col text-inherit ")}>
                <div
                    className={classNames(
                        "flex gap-2 overflow-hidden text-ellipsis whitespace-nowrap",
                        "2xl:w-[13rem]",
                        "w-[9rem]"
                    )}
                >
                    {instance.hostname} <InstanceBadges instance={instance} />
                </div>
                <div
                    className={classNames(
                        "overflow-hidden text-ellipsis whitespace-nowrap text-sm",
                        "2xl:w-[13rem]",
                        "w-[9rem]"
                    )}
                >
                    <span>{server?.hostname}</span>
                </div>
            </div>
        </DialogResourceListItem>
    );
}

function InstanceBadges({ instance }: { instance: Instance }) {
    return (
        <>
            {instance.state.health?.healthy === false && (
                <Tooltip
                    message={`unhealthy since ${formatDateString(
                        instance.state.health.updated,
                        "PPpp"
                    )}`}
                >
                    <ResourceStateIcon
                        state={{ current: "unhealthy", changed: "" }}
                    />
                </Tooltip>
            )}
            {instance.autoscale && (
                <Tooltip
                    message={`Auto-scaled instance (Minimum TTL ${formatDateString(
                        instance.autoscale.min_ttl,
                        "PPpp"
                    )})`}
                >
                    <FontAwesomeIcon
                        icon={faArrowUpRightDots}
                        className="text-cycle-blue"
                    />
                </Tooltip>
            )}
        </>
    );
}

function DeployNewInstanceForm({
    container,
    setIsOpen,
}: {
    container: Container;
    setIsOpen?: (open: boolean) => void;
}) {
    const service = container.image.service;

    const form = useForm<CreateInstancesApiArg>({
        defaultValues: {
            containerId: container.id,
            body: [
                {
                    new_instances: 1,
                    server_id: "",
                },
            ],
        },
    });
    const {
        register,
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    const [createInstances] = useCreateInstancesMutation();

    const onSubmit = (data: CreateInstancesApiArg) => {
        if (!isDirty) {
            return;
        }
        return createInstances(data)
            .unwrap()
            .then(() => setIsOpen?.(false), handleSubmitError(form.setError));
    };
    return (
        <div>
            <RhfFormProvider {...form}>
                <div className="p-2">
                    <div className="pb-4 text-lg">
                        <FontAwesomeIcon icon={faPlus} className="pr-2" />
                        Manually Deploy New Instance
                    </div>
                    <p className="pb-4">
                        This container is set to manual deploy mode. Instances
                        must be managed by the user.
                    </p>

                    <FormSectionHeader header="New Instances" />
                    <FormSection>
                        <div className="px-4">
                            <RhfSliderInput
                                min={0}
                                max={service ? 2 : 25}
                                numTicks={service ? 2 : 5}
                                {...register(`body.${0}.new_instances`, {
                                    valueAsNumber: true,
                                })}
                            />
                        </div>
                    </FormSection>

                    <FormSectionHeader header="Destination" />
                    <FormSection>
                        <RhfFormField
                            label="server"
                            name={`body.${0}.server_id`}
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <UsableServerSelect
                                        {...field}
                                        containerId={container.id}
                                        filterFields={["id", "hostname"]}
                                    />
                                )}
                                control={control}
                                name={`body.${0}.server_id`}
                            />
                        </RhfFormField>
                    </FormSection>
                </div>

                <PanelFooter>
                    <PushAndHoldButton
                        icon={faPlus}
                        onClick={form.handleSubmit(onSubmit)}
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                    >
                        Add Instances
                    </PushAndHoldButton>
                </PanelFooter>
            </RhfFormProvider>
        </div>
    );
}
