import { useEffect, useState } from "react";
import { FormSectionHeader } from "./FormSectionHeader";
import { FormSection } from "./FormSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Tooltip } from "../tooltip";

type ExpandableSectionProps = {
    children: (isExpanded?: boolean) => React.ReactNode;
    header?: React.ReactNode;
    defaultOpen?: boolean;
    logo?: string;
    onToggle?: (v: boolean) => void;
    help?: string;
    toggleDisabled?: boolean;
    toggleTooltip?: string;
    tooltip?: React.ReactNode;
    contentClassName?: string;
};

export function ExpandableFormSection({
    children,
    header = "Advanced",
    defaultOpen,
    logo,
    help,
    toggleTooltip,
    tooltip,
    contentClassName,
}: ExpandableSectionProps) {
    const [isExpanded, setIsExpanded] = useState(defaultOpen || false);

    useEffect(() => {
        setIsExpanded(defaultOpen || false);
    }, [defaultOpen]);

    return (
        <>
            <FormSectionHeader header={header} help={help} tooltip={tooltip}>
                <div className="flex grow items-center justify-end pl-4">
                    <div className="flex items-center gap-8">
                        {logo && <img src={logo} className="mx-2 w-40 py-2" />}
                        <Tooltip
                            message={toggleTooltip}
                            disabled={!toggleTooltip}
                        >
                            <button
                                type="button"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className={classNames(
                                        !isExpanded ? "-rotate-90" : ""
                                    )}
                                />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </FormSectionHeader>

            {isExpanded && (
                <ExpandedContentWrapper contentClassName={contentClassName}>
                    {children(isExpanded)}
                </ExpandedContentWrapper>
            )}
        </>
    );
}

function ExpandedContentWrapper({
    children,
    contentClassName,
}: {
    children: React.ReactNode;
    contentClassName?: string;
}) {
    if (children === <></>) {
        return null;
    }
    return (
        <FormSection className={classNames("p-4", contentClassName)}>
            {children}
        </FormSection>
    );
}
