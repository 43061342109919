import { Checkbox } from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Capability, Role, UpdateRoleApiArg } from "~/services/cycle";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { PanelContent, PanelTitle } from "@cycleplatform/ui/components/panels";

export function CapabilitiesSection({
    section,
    capabilities,
    viewerRole,
}: {
    section: string;
    capabilities: {
        identifier: Capability;
        label: string;
    }[];
    viewerRole: Role | undefined;
}) {
    const sectionCaps = useMemo(
        () =>
            capabilities
                .map((c) => c.identifier)
                .filter(
                    (c) =>
                        viewerRole?.capabilities?.specific?.includes(c) ||
                        viewerRole?.capabilities?.all
                ),
        [viewerRole, capabilities]
    );

    const { control, setValue } = useFormContext<UpdateRoleApiArg["body"]>();
    const currentCaps = useWatch({ name: "capabilities", control });
    const enabled = useHandleToggleState(currentCaps?.specific, sectionCaps);

    return (
        <div className="flex flex-col">
            <div className="!h-[37.5px]">
                <PanelTitle title={section} className="flex justify-between" />
            </div>
            <PanelContent className="h-full">
                {capabilities.map((c) => (
                    <CapabilityItem info={c.label} name={c.identifier} />
                ))}
            </PanelContent>
        </div>
    );
}

type CapabilityItemProps = {
    name: Capability;
    info: string;
};

export function CapabilityItem({ name, info, ...props }: CapabilityItemProps) {
    const { register } = useFormContext<UpdateRoleApiArg["body"]>();
    const capabilityError = name === null ? undefined : useCapability(name);

    return (
        <div className="py-1">
            <Checkbox
                tooltip={
                    !!capabilityError
                        ? "Cannot update a capability you do not have."
                        : undefined
                }
                info={`${info}`}
                disabled={!!capabilityError}
                value={name}
                {...register("capabilities.specific")}
            />
        </div>
    );
}

function getUpdatedCapsOnToggle(
    c: Capability[] | undefined,
    sectionCaps: Capability[],
    toggled: boolean
) {
    const currentCaps = c || [];

    if (toggled) {
        return [...currentCaps, ...sectionCaps];
    } else {
        return currentCaps.filter((c) => {
            return !sectionCaps.includes(c);
        });
    }
}

function useHandleToggleState(
    currentCaps: Capability[] | undefined,
    sectionCaps: Capability[]
) {
    const { roleId } = useParams<{ roleId: string }>();
    const [enabled, setEnabled] = useState(
        toggleDefaultValue(currentCaps, sectionCaps)
    );
    useEffect(() => {
        if (!currentCaps) {
            return;
        }
        setEnabled(toggleDefaultValue(currentCaps, sectionCaps));
    }, [currentCaps, roleId]);

    return enabled;
}

function toggleDefaultValue(
    currentCaps: Capability[] | undefined,
    sectionCaps: Capability[]
) {
    return currentCaps?.some((c) => sectionCaps.includes(c));
}
