export async function getCountriesData() {
    const { default: _, ...countries } = await import(
        "@cycleplatform/core/util/countries.json"
    );
    return Object.entries(countries)
        .sort(([idA, labelA], [idB, labelB]) => {
            if (idA === "US") return -1; // US should be at the top
            if (idB === "US") return 1; // Any other country should come after US
            return labelA.localeCompare(labelB); // Sort alphabetically by country name
        })
        .map(([id, label]) => ({
            label,
            id,
        }));
}
