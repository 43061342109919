import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { BillingMethodForm } from "~/components/settings/forms/BillingMethodForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function BillingMethodCreateDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="New Payment Method"
            isOpen={isDialogOpen("billing-method-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <BillingMethodForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
