import { BasicSelect } from "@cycleplatform/ui/components/forms/select";

type PublicNetworkSelectProps = {
    value?: string;
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value?: string | null) => void;
    resourceName?: string;
};

export function PublicNetworkSelect({
    value,
    onChange,
    resourceName = "Container",
    ...props
}: PublicNetworkSelectProps) {
    return (
        <BasicSelect
            {...props}
            value={value}
            onChange={onChange}
            options={[
                {
                    value: "disable",
                    label: "Disable",
                    detail: `${resourceName} will only be allowed to communicate with other containers/VMs in its environment.`,
                },
                {
                    value: "egress-only",
                    label: "Egress Only",
                    detail: `${resourceName} will be able to connect to the internet, but inbound public connections are blocked.`,
                },
                {
                    value: "enable",
                    label: "Enable",
                    detail: `${resourceName} will be able to initiate and receive public network connections`,
                },
            ]}
        />
    );
}
