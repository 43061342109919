import React from "react";
import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faFileMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, Hub } from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";

export function formatHubMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const name =
        extractComponent<Hub>(item.component, components)?.name ||
        item.component?.id;
    const hub = <Link to="/hub">{name}</Link>;

    switch (item.event) {
        case "hub.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested hub {hub} be deleted</span>
                ) : (
                    <span>Error requesting deletion of hub {hub} </span>
                ),
                icon: faTrash,
            };
        case "hub.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated hub {hub}</span>
                ) : (
                    <span>Error updating hub {hub}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "hub.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created hub {hub}</span>
                ) : (
                    <span>Error creating hub</span>
                ),
                icon: faPlus,
            };
        case "hub.images.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Pruned images associated with hub {hub}</span>
                ) : (
                    <span>Error pruning images associated wtih hub {hub}</span>
                ),
                icon: faFileMinus,
            };
        case "hub.task.images.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested images associated with hub {hub} be pruned
                    </span>
                ) : (
                    <span>
                        Error requesting images associated wtih hub {hub} be
                        pruned
                    </span>
                ),
                icon: faFileMinus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
