import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";

type VolumeStoragePoolProps = {
    name: string;
    value?: boolean;
    disabled?: boolean;
    onBlur?: () => void;
    onChange: (value: boolean | undefined) => void;
};

export function VolumeStoragePoolSelect({
    onChange,
    value,
    ...props
}: VolumeStoragePoolProps) {
    type keys = keyof typeof options;

    const options = {
        default: {
            value: undefined,
            label: "Default",
            detail: "Platform will decide what drives to use",
        },
        enable: {
            value: true,
            label: "Enabled",
            detail: "Use drives if availiable ",
        },
        disable: {
            value: false,
            label: "Disabled",
            detail: "Do not use drives, even if they exist",
        },
    };

    return (
        <SelectInput
            {...props}
            options={Object.keys(options) as keys[]}
            onChange={(v) => {
                onChange(options[v as keys].value);
            }}
            value={
                Object.entries(options).find((o) => o[1].value === value)?.[0]
            }
            formatDisplayValue={(o) => options[o as keys]?.label}
            formatOption={(o) => (
                <FormattedOption
                    label={options[o as keys].label}
                    detail={options[o as keys].detail}
                />
            )}
        />
    );
}
