import { useEffect, useState } from "react";
import { useGetImageBuildLogQuery } from "~/services/cycle";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { isCycleApiError } from "~/services/helpers";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import prism from "prismjs";
import classNames from "classnames";

type ImageBuildLogProps = {
    imageId?: string;
    title?: React.ReactNode;
    codeDimensionsClassName?: string;
};

export function ImageBuildLog({
    imageId,
    title,
    codeDimensionsClassName,
}: ImageBuildLogProps) {
    const [noBuildLog, setNoBuildLog] = useState(false);
    const { currentData: log, error: logError } = useGetImageBuildLogQuery(
        {
            imageId: imageId || "",
        },
        {
            skip: !imageId,
        }
    );

    useEffect(() => {
        prism.highlightAll();
    }, [log?.data?.log]);

    useEffect(() => {
        if (!logError) {
            return;
        }
        if (logError && isCycleApiError(logError) && logError.status === 404) {
            setNoBuildLog(true);
        } else {
            throw new Error();
        }
    }, [logError]);

    if (noBuildLog) {
        return (
            <Panel>
                <EmptyResource title="No Build Log" />
            </Panel>
        );
    }
    return (
        <div className="w-full ">
            <PanelTitle title={title || "Build Log"} />
            <div className="py-2">
                <pre
                    className={classNames(
                        "language-bash  !m-0 rounded-lg p-4 !text-sm",
                        codeDimensionsClassName || "h-full max-w-[80rem]"
                    )}
                >
                    <code>{log?.data?.log}</code>
                </pre>
            </div>
        </div>
    );
}
