import classNames from "classnames";
import { StyledHeaderCell } from "./StyledHeaderCell";

type StyledIconHeaderCellProps = {
    className?: string;
    children?: React.ReactNode;
};

export function StyledIconHeaderCell({
    children,
    className,
}: StyledIconHeaderCellProps) {
    return (
        <StyledHeaderCell className={classNames(className, "w-20")}>
            {children}
        </StyledHeaderCell>
    );
}
