import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { faChartLine } from "@fortawesome/pro-duotone-svg-icons";
import { useGetAccountQuery } from "~/services/cycle";
import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "../helpers";
import { DashboardSection } from "./components/DashboardSection";
import { SecuritySection } from "./components/SecuritySection";
import { HubsSection } from "./components/HubsSection";
import { formatName } from "@cycleplatform/ui/components/resources/accounts/formatName";
import { NavIcons } from "~/components/layout/NavIcons";

type AccountDialogProps = {
    className?: string;
};

export function AccountDialog({ className }: AccountDialogProps) {
    const { data: account, error } = useGetAccountQuery();
    const navigate = useNavigate();

    if (error) {
        throw error;
    }

    return (
        <StyledResourceDialog
            open={isDialogOpen("account")}
            onClose={() => navigate(clearDialogParams())}
            className={classNames("relative z-50", className)}
            breadcrumb={[
                {
                    label: "Account Settings",
                    link: "",
                },
                {
                    label: formatName(account?.data?.name),
                    link: "",
                },
            ]}
            header={
                <div className="flex items-center">
                    <h1>{formatName(account?.data?.name)}</h1>
                </div>
            }
        >
            <ResourceDialogTab
                to={null}
                name="Dashboard"
                icon={NavIcons["altDash"]}
            >
                <DashboardSection account={account?.data} />
            </ResourceDialogTab>

            <ResourceDialogTab to="security" name="Security" icon={faChartLine}>
                <SecuritySection account={account?.data} />
            </ResourceDialogTab>

            <ResourceDialogTab to="hubs" name="Hubs" icon={NavIcons["hubs"]}>
                <HubsSection account={account?.data} />
            </ResourceDialogTab>
        </StyledResourceDialog>
    );
}
