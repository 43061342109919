import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGetScopedVariableQuery } from "~/services/cycle";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    ScopedVariableForm,
    ScopedVariableFormSubmitType,
} from "./ScopedVariableForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export const ScopedVariableFormDialogContext =
    createContext<ScopedVariableFormSubmitType | null>(null);

export function ScopedVariableFormDialog() {
    const navigate = useNavigate();

    const { ["dialog-env"]: envId, ["dialog-var"]: variableId } =
        getAllDialogSearchParams<"environment-scopedvar-edit">();

    const { data: scopedVariable, error } = useGetScopedVariableQuery(
        {
            scopedVariableId: variableId,
            environmentId: envId,
        },
        { skip: !variableId }
    );

    if (error) {
        throw error;
    }

    const onClose = () => navigate(clearDialogParams());

    return (
        <StyledFormDialog
            title={`${variableId ? "Edit" : "Create"} Scoped Variable ${
                scopedVariable?.data
                    ? ` - ${scopedVariable.data.identifier}`
                    : ""
            }`}
            isOpen={isDialogOpen("environment-scopedvar-edit")}
            onClose={onClose}
        >
            <DialogColumn className="w-full">
                <ScopedVariableForm
                    environmentId={envId}
                    closeDialog={onClose}
                    scopedVariable={scopedVariable?.data}
                />
            </DialogColumn>
        </StyledFormDialog>
    );
}
