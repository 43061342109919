import { InfoPanel } from "@cycleplatform/ui/components/panels";
import {
    CreateOrderApiArg,
    TierPlan,
    useGetBillingServicesQuery,
} from "~/services/cycle";
import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import classNames from "classnames";
import { useWatch, useFormContext } from "react-hook-form";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useMemo } from "react";
import { useSetHubPlanParams } from "../../../hooks";
import { CategorySection } from "./components/CategorySection";
import { PlanTierButton } from "./components/PlanTierButton";
import { EnterpriseContent } from "./components/EnterpriseContent";
import { TierCategoryNavigation } from "./components/CategoryNavigation";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { PLAN_TILE_CLASSNAME } from "@cycleplatform/ui/components/resources";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import {
    getTiersByCategory,
    tierCategories,
} from "@cycleplatform/core/modules/tiers/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type SelectTierStepProps = {
    tiers: TierPlan[] | undefined;
};

export function SelectTierStep({ tiers }: SelectTierStepProps) {
    const params = getAllDialogSearchParams<"change-hub-plan">();
    const form = useFormContext<CreateOrderApiArg["body"]>();

    const { control } = form;

    const { data: activeServices, error } = useGetBillingServicesQuery({});

    const activeTierPrice = useMemo(
        () =>
            activeServices?.data?.find((as) => !!as.item.tier)?.item.tier?.price
                .mills || 0,
        [activeServices?.data]
    );

    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id || null;
    const selectedTierId = useWatch({ name: "tier_plan_id", control });

    const tiersByCategory = getTiersByCategory(tiers, currentTierId);

    /** May be missing when a new hub is created while active services are being configured. */
    const hasHubBillingTier = !!hub?.billing?.plans.tier_id;
    const setHubPlanParams = useSetHubPlanParams();

    if (error) {
        throw error;
    }

    return (
        <div className="h-full pb-16">
            <TierCategoryNavigation
                tiers={tiers}
                currentTierId={currentTierId}
                hasCustomTiers={tiersByCategory.custom.length !== 0}
            />

            {params["dialog-tier-tab"] ? (
                <div className="flex w-full flex-col gap-4 p-4">
                    {tiers && !hasHubBillingTier ? (
                        <InfoPanel>
                            Cycle is configuring the billing for this hub. This
                            form will be available shortly.
                        </InfoPanel>
                    ) : null}

                    {/* Non Commercial */}
                    <CategorySection tab={tierCategories.NON_COMMERCIAL}>
                        {tiersByCategory?.nonCommercial?.map((t) => (
                            <PlanTierButton
                                tier={t}
                                selectedTierId={selectedTierId}
                                currentTierId={currentTierId}
                                activeTierPrice={activeTierPrice}
                            />
                        ))}
                    </CategorySection>

                    {/* Teams */}
                    <CategorySection tab={tierCategories.TEAMS}>
                        {tiersByCategory?.teams?.map((t) => (
                            <PlanTierButton
                                tier={t}
                                selectedTierId={selectedTierId}
                                currentTierId={currentTierId}
                                activeTierPrice={activeTierPrice}
                            />
                        ))}
                    </CategorySection>

                    {/* Enterprise */}
                    <CategorySection tab={tierCategories.ENTERPRISE}>
                        <div
                            className={classNames(
                                "pointer-events-none p-4",
                                PLAN_TILE_CLASSNAME,
                                getOptionButtonClassName(false)
                            )}
                        >
                            <EnterpriseContent />
                        </div>
                    </CategorySection>

                    {/* Custom */}
                    <CategorySection tab={tierCategories.CUSTOM}>
                        {tiersByCategory?.custom?.map((t) => (
                            <PlanTierButton
                                tier={t}
                                selectedTierId={selectedTierId}
                                currentTierId={currentTierId}
                                activeTierPrice={activeTierPrice}
                            />
                        ))}
                    </CategorySection>
                </div>
            ) : null}

            <DialogFooter className="items-center ">
                <RhfGlobalFormError />
                <Button
                    disabled={
                        !selectedTierId || currentTierId === selectedTierId
                    }
                    onClick={() =>
                        setHubPlanParams({ step: "1", tier: selectedTierId })
                    }
                >
                    Next
                </Button>
            </DialogFooter>
        </div>
    );
}
