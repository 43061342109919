import {
    Panel,
    PanelContent,
    PanelDetail,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import {
    Stack,
    StackBuild,
    useDeleteStackBuildMutation,
} from "~/services/cycle";
import { BuildSpec } from "~/components/stacks/stack-builds/BuildSpec";
import { CopyInput } from "@cycleplatform/ui/components/forms";
import { formatDateString } from "@cycleplatform/core/util/time";
import { BuildImportProgressMeter } from "~/components/stacks/BuildImportProgressMeter";
import { RawStackSourceDialog } from "~/routes/stacks/$stackId/dialog/RawStackSourceDialog";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type StackBuildDashboardProps = {
    build?: StackBuild;
    stack?: Stack;
};

export function StackBuildDashboard({
    stack,
    build,
}: StackBuildDashboardProps) {
    const [deleteStackBuild] = useDeleteStackBuildMutation();

    return (
        <DialogPageBody>
            <PageContent className="w-1/2">
                <BuildSpec spec={build?.spec} />
            </PageContent>
            <PageAside>
                <Panel>
                    <PanelTitle title="Build Import Progress" />
                    <PanelContent>
                        <BuildImportProgressMeter build={build} />
                    </PanelContent>
                </Panel>
                <Panel>
                    <PanelTitle title="Build Summary" />
                    <PanelContent>
                        <PanelDetail label="build id" block>
                            <CopyInput value={build?.id} />
                        </PanelDetail>
                        <PanelDetail label="created">
                            {build?.events?.created && (
                                <>{formatDateString(build.events.created)}</>
                            )}
                        </PanelDetail>
                    </PanelContent>
                </Panel>

                <DeleteResource
                    accessControl={{
                        aclResource: stack,
                        verifyFn: modifyAccessFn("stacks-builds-manage"),
                    }}
                    resourceType="build"
                    resourceName={build?.id}
                    onSubmit={async () => {
                        return deleteStackBuild({
                            stackId: stack?.id || "",
                            buildId: build?.id || "",
                        }).unwrap();
                    }}
                />
            </PageAside>

            <RawStackSourceDialog stack={stack} />
        </DialogPageBody>
    );
}
