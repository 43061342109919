import { Container, VolumeSummary } from "~/services/cycle";
import { RemoteAccessSection } from "./RemoteAccessSection";
import { BaseVolumeSettingsSection } from "./BaseVolumeSettingsSection";
import classNames from "classnames";
import { DialogPageHeader } from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { Badge } from "@cycleplatform/ui/components/badges";
import { faCube } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isHypervisorContainer } from "@cycleplatform/core/modules/containers/util";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";

export function ActiveVolumePanel({
    volume,
    isActive,
    idx,
    container,
}: {
    volume: VolumeSummary;
    isActive: boolean;
    idx: number;
    container?: Container;
}) {
    const isHypervisor = isHypervisorContainer(container);
    return (
        <SectionDisabledControl disabled={isHypervisor}>
            <div className={classNames(isActive ? "block" : "hidden", "py-4")}>
                <DialogPageHeader
                    title={volume?.config.destination}
                    icon={NavIcons["volumes"]}
                    badge={
                        volume.config.block_device ? (
                            <Badge className="flex items-center gap-2">
                                <FontAwesomeIcon
                                    icon={faCube}
                                    className="text-cycle-blue text-xs"
                                />
                                <div className=" !text-xs">block-device</div>
                            </Badge>
                        ) : undefined
                    }
                />
                <AccessControlledSection
                    capability="containers-volumes-manage"
                    className="flex flex-col gap-4"
                >
                    <BaseVolumeSettingsSection volume={volume} idx={idx} />
                    <RemoteAccessSection volume={volume} idx={idx} />
                </AccessControlledSection>
            </div>
        </SectionDisabledControl>
    );
}
