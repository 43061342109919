import {
    faQuestionCircle,
    faPlus,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { Link, createSearchParams } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    Environment,
    ScopedVariable,
} from "~/services/cycle";
import { extractComponent, isErroredActivity } from "./common/util";

export function formatScopedVariableMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const sv = extractComponent<ScopedVariable>(item.component, components);
    const envc = extractComponent<Environment>(
        { id: item.context.environment_id || "", type: "environment" },
        components
    );

    const env = (
        <Link
            to={{
                pathname: `/environments/${item.context.environment_id}/scoped-variables`,
            }}
        >
            {envc?.name}
        </Link>
    );

    const scopedVar = (
        <Link
            to={{
                pathname: `/environments/${item.context.environment_id}/scoped-variables`,
                search: createSearchParams({
                    dialog: "scoped-var-edit",
                    var: item.component?.id || "",
                }).toString(),
            }}
        >
            {sv?.identifier}
        </Link>
    );

    switch (item.event) {
        case "environment.scoped-variable.create": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created new scoped variable {scopedVar} for environment
                        {env}
                    </span>
                ) : (
                    <span>
                        Error creating new scoped variable {scopedVar} for
                        environment
                        {env}
                    </span>
                ),
                icon: faPlus,
            };
        }

        case "environment.scoped-variable.update": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated scoped variable {scopedVar} in environment {env}
                    </span>
                ) : (
                    <span>
                        Error updating scoped variable {scopedVar} in
                        environment
                        {env}
                    </span>
                ),
                icon: faEdit,
            };
        }

        case "environment.scoped-variable.delete": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted scoped variable {scopedVar} for environment{" "}
                        {env}
                    </span>
                ) : (
                    <span>
                        Error deleting scoped variable {scopedVar} for
                        environment
                        {env}
                    </span>
                ),
                icon: faPlus,
            };
        }
        case "environment.scoped-variable.task.delete": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of scoped variable {scopedVar} in
                        environment {env}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of scoped variable {scopedVar}{" "}
                        in environment {env}
                    </span>
                ),
                icon: faPlus,
            };
        }
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
