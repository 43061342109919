import { useNavigate, useSearchParams } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import graphic from "@cycleplatform/ui/images/icons/gridless/container-orchestration.svg";
import { CreateImageSourceForm } from "./CreateImageSourceForm";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function ImageSourceCreateDialog() {
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="New Image Source"
            isOpen={isDialogOpen("image-source-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-2/3">
                <CreateImageSourceForm />
            </DialogColumn>
            <DialogColumn className="w-1/3">
                <img className="max-w-xs" src={graphic} />
                <div className="mt-4 text-lg">
                    <h3>Image Sources</h3>
                    <div className="text-sm">
                        By creating a source and giving Cycle access to a
                        specific image, developers can easily reimage containers
                        without constantly managing credentials.
                    </div>
                </div>
            </DialogColumn>
        </StyledFormDialog>
    );
}
