import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useRef } from "react";

/**
 *
 * @params are generated in the useDraggableFieldArray hook and passed into the DraggableWrapper
 * @returns A JSX object that provides a drag handle and enables drag and drop reordering for
 * React Hook Form fieldArrays
 */
export function DraggableWrapper({
    id,
    drop,
    dragStart,
    dragEnter,
    dragOverItem,
    dragItem,
    children,
    fieldArrayId,
    activeFieldArrayId,
}: {
    id: string;
    drop: () => void;
    dragStart: (e: React.DragEvent<HTMLDivElement>) => void;
    dragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
    /**
     * Drag Over Item is the fieldArray ID of the item being dragged over
     */
    dragOverItem: string | null;
    /**
     * Drag Item is the fieldArray ID of the item being dragged
     */
    dragItem: string | null;
    /**
     *  Array key is defined during draggableFieldArray instantiation - prevents bleed between separate fieldArrays
     */
    fieldArrayId: string;
    /**
     *  Active Array key the fieldArrayId of the currently dragged item
     */
    activeFieldArrayId: string | null;
    children: React.ReactNode;
}) {
    const isDraggedOver =
        dragOverItem === id &&
        dragOverItem !== dragItem &&
        fieldArrayId === activeFieldArrayId;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        if (wrapperRef.current) {
            const { width, height } =
                wrapperRef.current.getBoundingClientRect();

            e.dataTransfer.setDragImage(wrapperRef.current, width, height / 2);
        }
        dragStart(e);
    };

    return (
        <div
            ref={wrapperRef}
            className={classNames(
                "flex w-full rounded-md",
                isDraggedOver && "ring-cycle-blue ring"
            )}
        >
            {children}
            <div
                className="bg-cycle-gray-light dark:bg-cycle-gray-accent ml-4 flex cursor-grab flex-col justify-center rounded-md p-4"
                onDragStart={handleDragStart}
                draggable
                onDragEnter={(e) => dragEnter(e)}
                onDragEnd={() => drop()}
                onDragOver={(e) => e.preventDefault()}
                id={id}
            >
                <FontAwesomeIcon icon={faBars} />
            </div>
        </div>
    );
}
