import { ScaleThresholdMetric } from "~/services/cycle";
import { ScalingTile } from "../ScalingTile";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { RamThreshold } from "./RamThreshold";
import { CpuThreshold } from "./CpuThreshold";
import { NetworkConnectionsThreshold } from "./NetworkConnnectionsThreshold";
import { NetworkRequestsThreshold } from "./NetworkRequestsThreshold";
import { NetworkThroughputThreshold } from "./NetworkThroughputThreshold";
import { ThresholdLabel } from "../util";

export function ThresholdForm({
    t,
    remove,
    idx,
    thresholdIdx,
}: {
    t: ScaleThresholdMetric;
    remove: (idx: number) => void;
    idx: number;
    thresholdIdx: number;
}) {
    return (
        <ScalingTile>
            <div>
                <div className="flex w-full items-center justify-between gap-4">
                    <h3 className="text-xl font-semibold">
                        {ThresholdLabel[t.type]} Threshold
                    </h3>

                    <div>
                        <Button
                            flavor="discard"
                            className="border-none"
                            icon={faTrash}
                            onClick={() => remove(idx)}
                        />
                    </div>
                </div>
                <ThresholdDetails type={t.type} thresholdIdx={thresholdIdx} />
            </div>
        </ScalingTile>
    );
}

function ThresholdDetails({
    type,
    thresholdIdx,
}: {
    type: ScaleThresholdMetric["type"];
    thresholdIdx: number;
}) {
    switch (type) {
        case "cpu":
            return <CpuThreshold thresholdIdx={thresholdIdx} />;
        case "ram":
            return <RamThreshold thresholdIdx={thresholdIdx} />;
        case "network-connections":
            return <NetworkConnectionsThreshold thresholdIdx={thresholdIdx} />;
        case "network-requests":
            return <NetworkRequestsThreshold thresholdIdx={thresholdIdx} />;
        case "network-throughput":
            return <NetworkThroughputThreshold thresholdIdx={thresholdIdx} />;
        default:
            return null;
    }
}
