import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError } from "~/components/forms/util";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    faExclamationTriangle,
    faLock,
    faUnlock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { VerificationCodeInput } from "~/components/auth/VerificationCodeInput";
import {
    Account,
    DisableTwoFactorAuthApiArg,
    useDisableTwoFactorAuthMutation,
    useGetTwoFactorAuthSetupMutation,
    useEnableTwoFactorAuthMutation,
    EnableTwoFactorAuthApiArg,
} from "~/services/cycle";

type TwoFactorAuthProps = {
    account?: Account;
};

export function TwoFactorAuth({ account }: TwoFactorAuthProps) {
    const twoFaEnabled = account?.two_factor_auth?.verified;
    const [formVisible, setFormVisible] = useState(false);
    const [getTwoFaSetup, { data: twoFa }] = useGetTwoFactorAuthSetupMutation({
        fixedCacheKey: "twofa",
    });

    const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

    return (
        <Panel>
            <PanelTitle title="Two-Factor Auth" />
            <PanelContent>
                <div className="flex items-center justify-between">
                    <div>
                        <FontAwesomeIcon
                            icon={twoFaEnabled ? faLock : faUnlock}
                            className={classNames(
                                "pr-2",
                                { "text-success": twoFaEnabled },
                                { "text-error": !twoFaEnabled }
                            )}
                        />
                        {"Two-Factor authentication is currently "}
                        <span
                            className={classNames(
                                "font-bold",
                                { "text-success": twoFaEnabled },
                                { "text-error": !twoFaEnabled }
                            )}
                        >
                            {twoFaEnabled ? "Enabled" : "Disabled"}
                        </span>
                    </div>

                    <div className="h-10">
                        <Button
                            onClick={() => {
                                if (!twoFaEnabled && !twoFa) {
                                    getTwoFaSetup();
                                }
                                setFormVisible(!formVisible);
                            }}
                            className={classNames({ hidden: formVisible })}
                        >
                            {twoFaEnabled
                                ? "Remove Auth"
                                : "Add Two-Factor Auth"}
                        </Button>
                    </div>
                </div>

                {recoveryCodes?.length && twoFaEnabled ? (
                    <InfoPanel type="success" className="mt-4 !p-4 " omitIcon>
                        <h3 className="pb-2 text-lg">
                            Two-Factor Authentication Successfully Configured!
                        </h3>
                        <p className="pb-4 text-sm">
                            {`Save the following codes in case you lose your device or
                    need to recover access. Store them somewhere safe, they
                    won't be shown again.`}
                        </p>
                        <div className="flex flex-wrap">
                            {recoveryCodes?.map((c) => (
                                <div key={c} className="w-1/2 text-center">
                                    <div className="pb-2 font-semibold">
                                        {c}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </InfoPanel>
                ) : null}

                {formVisible ? (
                    <>
                        {twoFaEnabled ? (
                            <Disable2faForm setFormVisible={setFormVisible} />
                        ) : (
                            <Enable2FaForm
                                setFormVisible={setFormVisible}
                                setRecoveryCodes={setRecoveryCodes}
                            />
                        )}
                    </>
                ) : null}
            </PanelContent>
        </Panel>
    );
}

type TwoFaFormProps = {
    setFormVisible: (visibile: boolean) => void;
    setRecoveryCodes?: (codes: string[]) => void;
};

function Disable2faForm({ setFormVisible }: TwoFaFormProps) {
    const form = useForm<DisableTwoFactorAuthApiArg["body"]>();

    const {
        setValue,
        formState: { isDirty, isSubmitting },
    } = form;

    const [disable2Fa] = useDisableTwoFactorAuthMutation();

    const onSubmit = (data: DisableTwoFactorAuthApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return disable2Fa({
            body: data,
        })
            .unwrap()
            .then(
                () => setFormVisible(false),
                handleSubmitError(form.setError)
            );
    };

    return (
        <div>
            <RhfFormProvider {...form}>
                <div className="flex items-center gap-4 pb-4">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-error inline"
                        size="2x"
                    />
                    <p>
                        Disabling two-factor auth will reduce the security of
                        your account. Please confirm by entering the two-factor
                        token from your app into the box below.
                    </p>
                </div>
                <RhfFormField label="token" name="token">
                    <VerificationCodeInput
                        onChange={(v) =>
                            setValue("token", v, { shouldDirty: true })
                        }
                    />
                </RhfFormField>

                <PanelFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <PushAndHoldButton
                        icon={faLock}
                        type="button"
                        onClick={form.handleSubmit(onSubmit)}
                        flavor="discard"
                        disabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        Disable
                    </PushAndHoldButton>
                </PanelFooter>
            </RhfFormProvider>
        </div>
    );
}

function Enable2FaForm({ setFormVisible, setRecoveryCodes }: TwoFaFormProps) {
    const form = useForm<EnableTwoFactorAuthApiArg["body"]>();
    const [, { data: twoFaSetup, reset }] = useGetTwoFactorAuthSetupMutation({
        fixedCacheKey: "twofa",
    });

    const [setup2Fa] = useEnableTwoFactorAuthMutation();

    const {
        setValue,
        formState: { isDirty, isSubmitting },
    } = form;

    const onSubmit = (data: EnableTwoFactorAuthApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return setup2Fa({
            body: data,
        })
            .unwrap()
            .then((v) => {
                setRecoveryCodes?.(v.data?.recovery_codes || []);
                reset();
                setFormVisible(false);
            }, handleSubmitError(form.setError));
    };

    return (
        <div>
            <RhfFormProvider {...form}>
                <div className="flex items-center gap-4 pb-4">
                    <p>
                        Using a two-factor app such as Authy , scan the QR Code
                        on the left, and enter the six-digit numeric token into
                        the box below to enable two-factor authentication.
                    </p>
                </div>
                <div className="flex items-center gap-8 pb-4">
                    <div className="flex w-1/3 flex-col items-center">
                        {twoFaSetup ? (
                            <>
                                <img
                                    className="w-40"
                                    src={`data:image/png;base64,${twoFaSetup?.data?.qr}`}
                                />
                                <div className="text-xs">
                                    {twoFaSetup?.data?.secret}
                                </div>
                            </>
                        ) : null}
                    </div>

                    <RhfFormField label="token" name="token">
                        <VerificationCodeInput
                            onChange={(v) =>
                                setValue("token", v, { shouldDirty: true })
                            }
                        />
                    </RhfFormField>
                </div>

                <PanelFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <PushAndHoldButton
                        icon={faLock}
                        type="button"
                        onClick={form.handleSubmit(onSubmit)}
                        flavor="primary"
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                    >
                        Enable
                    </PushAndHoldButton>
                </PanelFooter>
            </RhfFormProvider>
        </div>
    );
}
