import { Badge } from "@cycleplatform/ui/components/badges";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PipelineRunStep } from "~/services/cycle";

export function PipelineStepErrorIcon({ step }: { step: PipelineRunStep }) {
    if (!step?.error) {
        return null;
    }

    return (
        <div>
            <Badge className="text-xs">
                <FontAwesomeIcon icon={faCircle} className="text-error pr-2" />{" "}
                Error
            </Badge>
        </div>
    );
}
