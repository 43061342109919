import { useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";

const useCountdown = (targetDate: Date) => {
    const [countdown, setCountdown] = useState(
        intervalToDuration({ start: new Date(), end: targetDate })
    );
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(
                intervalToDuration({ start: new Date(), end: targetDate })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return countdown;
};

export { useCountdown };
