import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import {
    Environment,
    useGenerateAggregatedMetricsQuery,
} from "~/services/cycle";
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    BarChart,
    Bar,
} from "recharts";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import { getLbResponsesQuery } from "./query";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { useEffect, useState } from "react";
import { ApiResponseColorMap } from "@cycleplatform/ui/components/charts/colors";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { faLineChart } from "@fortawesome/pro-solid-svg-icons";
import { formatNumber } from "@cycleplatform/core/util/number";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type LoadBalancerRouterResponseChartProps = {
    className?: string;
    environment?: Environment;
    port: string | undefined;
};

export type RouterDataType = {
    time: string;
    [code: string]: number | string;
};

const supportedPorts = ["80", "443", "8000"];

export function LoadBalancerRouterResponseChart({
    className,
    environment,
    port,
}: LoadBalancerRouterResponseChartProps) {
    const {
        data: telemetry,
        isLoading,
        isFetching,
    } = useGenerateAggregatedMetricsQuery(
        getLbResponsesQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );
    const [bars, setBars] = useState<string[]>([]);
    const routerData = telemetry?.data as RouterDataType[];

    const [prevPort, setPrevPort] = useState<string | undefined>();
    const [isFetchingNewPort, setFetchingNewPort] = useState(false);

    useEffect(() => {
        if (!routerData) {
            return;
        }

        const bs: string[] = [];

        routerData.forEach((d) => {
            const { time, ...priorities } = d;
            bs.push(...Object.keys(priorities));
        });

        setBars([...new Set(bs)]);
    }, [routerData]);

    useEffect(() => {
        if (prevPort === port && !isFetchingNewPort) {
            return;
        }
        setFetchingNewPort(isFetching);
        setPrevPort(port);
    }, [isFetching, port, prevPort]);

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    if (port && !supportedPorts.includes(port)) {
        return (
            <EmptyResource
                className="flex h-full items-center justify-center"
                icon={faLineChart}
                title="Router responses are not supported on this port"
            >
                <p className="text-center">
                    Router response metrics are only available on port{" "}
                    <strong>80</strong>, <strong>443</strong>, and{" "}
                    <strong>8000</strong>.
                </p>
            </EmptyResource>
        );
    }

    return (
        <div
            className={classNames(
                className,
                "h-full",
                (isLoading || isFetchingNewPort) && skeletonStyles
            )}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    {...CycleChartProps}
                    // Slice the first point so that the chart times align with all the others
                    // The other charts use generateCumulativeDiff which does not have a diff until there are 2 points
                    data={routerData?.slice(1)}
                    syncId={"lb-telem"}
                >
                    <CartesianGrid {...CycleGridProps} />
                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        tick={(props) => (
                            <FormattedYTick
                                {...props}
                                formatValue={(v: number) => `${v}`}
                            />
                        )}
                    />
                    <Tooltip
                        content={(props) => (
                            <CycleBarTooltip
                                {...props}
                                formatValue={(v: number) =>
                                    `${formatNumber(v, { decimals: 0 })}`
                                }
                            />
                        )}
                    />
                    {[...bars].map((b) => {
                        const resCode = b.split(":")[1] || "";
                        return (
                            <Bar
                                {...CycleBarProps}
                                key={b}
                                stackId="priority"
                                dataKey={b}
                                fill={ApiResponseColorMap[resCode]}
                            />
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
