import {
    FormSection,
    RhfFormField,
    TextInput,
    isTaggedImage,
    isUrl,
    required,
    FormSectionHeader,
    RhfEnableDisableFormSection,
    isNotHttpHttps,
    hasNoSpaces,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../util";
import { DockerRegistryOrigin } from "~/services/cycle";

export function DockerRegistryForm() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();
    return (
        <>
            <FormSectionHeader header="Target" />
            <FormSection>
                <div className="flex gap-4">
                    <RhfFormField
                        label="image name"
                        name="origin.details.target"
                        required
                    >
                        <TextInput
                            {...register("origin.details.target", {
                                shouldUnregister: true,
                                ...required("Image target cannot be empty"),
                                validate: {
                                    ...isTaggedImage(),
                                },
                            })}
                            placeholder="image:tag"
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="registry url"
                        name="origin.details.url"
                        required
                    >
                        <TextInput
                            {...register("origin.details.url", {
                                shouldUnregister: true,
                                ...required(),
                                validate: {
                                    ...isUrl(),
                                    ...isNotHttpHttps(
                                        "Registry URL must not include http:// or https://"
                                    ),
                                    ...hasNoSpaces(),
                                },
                            })}
                            placeholder="registry.com"
                        />
                    </RhfFormField>
                </div>
                <AuthSection />
            </FormSection>
        </>
    );
}

function AuthSection() {
    const { register, unregister, setValue, watch } =
        useFormContext<ImageSourceCreateEditSubmitType>();
    const currentDetails = watch("origin.details");
    const currentUsername = watch("origin.details.username");
    const currentPassword = watch("origin.details.password");
    const currentToken = watch("origin.details.token");
    const [auth, setAuth] = useState(!!currentUsername);

    const handleAuthChange = (b: boolean) => {
        setAuth(b);

        setValue(
            "origin.details",
            {
                ...currentDetails,
                username: "",
                token: "",
                password: "",
            } as DockerRegistryOrigin["details"],
            { shouldDirty: true }
        );

        if (!b) {
            unregister("origin.details.username", { keepDirty: true });
            unregister("origin.details.token", { keepDirty: true });
            unregister("origin.details.password", { keepDirty: true });
        }
    };

    return (
        <RhfEnableDisableFormSection
            header="Auth"
            isExpanded={auth}
            onToggle={(b) => handleAuthChange(b)}
        >
            {(isExpanded) => (
                <>
                    {isExpanded && (
                        <div className="mb-4 w-full">
                            <RhfFormField
                                label="username"
                                name="origin.details.username"
                            >
                                <TextInput
                                    {...register("origin.details.username", {
                                        shouldUnregister: true,
                                    })}
                                />
                            </RhfFormField>
                            <div className="flex gap-4">
                                <RhfFormField
                                    label="password"
                                    className="w-1/2"
                                    name="origin.details.password"
                                >
                                    <TextInput
                                        type="password"
                                        disabled={currentToken !== ""}
                                        {...register(
                                            "origin.details.password",
                                            { shouldUnregister: true }
                                        )}
                                    />
                                </RhfFormField>

                                <RhfFormField
                                    label="token"
                                    className="w-1/2"
                                    name="origin.details.token"
                                >
                                    <TextInput
                                        type="password"
                                        disabled={currentPassword !== ""}
                                        {...register("origin.details.token", {
                                            shouldUnregister: true,
                                        })}
                                    />
                                </RhfFormField>
                            </div>
                        </div>
                    )}
                </>
            )}
        </RhfEnableDisableFormSection>
    );
}
