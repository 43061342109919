import { useNavigate, useParams } from "react-router-dom";
import { FormField } from "@cycleplatform/ui/components/forms";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { CreateContainerForm } from "./CreateContainerForm";
import { faContainerStorage } from "@fortawesome/pro-duotone-svg-icons";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

type RouteParams = {
    environmentId: string;
};

export function ContainerCreateDialog() {
    const navigate = useNavigate();
    const params = useParams<RouteParams>();

    const environmentId = window.location.pathname.includes("environment")
        ? params.environmentId
        : undefined;

    return (
        <StyledFormDialog
            title="New Container"
            isOpen={isDialogOpen("container-create")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-2/3">
                <CreateContainerForm environmentId={environmentId} />
            </DialogColumn>

            <DialogColumn className="w-1/3">
                <div className="w-full text-center">
                    <FontAwesomeIcon
                        icon={faContainerStorage}
                        className="text-cycle-blue my-10 h-20 text-center"
                    />
                </div>
                <div className="text-lg">
                    <h3 className="mb-2">Container Deployment</h3>
                    <div className="mb-4 text-sm">
                        Use this form to get up and running with a single
                        container deployment. Config can be further modified
                        after creation.
                    </div>

                    <FormField label="learn more:">
                        <ul>
                            <li>
                                <a
                                    className="text-cycle-blue text-sm"
                                    href="https://cycle.io/docs/portal/container-deployment-configuration"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div>
                                        Documentation: Deploying a Container
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </FormField>
                </div>
            </DialogColumn>
        </StyledFormDialog>
    );
}
