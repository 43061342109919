import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { Link } from "react-router-dom";
import { ImageBuildLog } from "~/components/images/ImageBuildLog";
import { PipelineRunStep, useGetImagesQuery } from "~/services/cycle";
import { generateDialogLink } from "~/components/dialogs/helpers";

export function StackBuildGenerateDetails({ step }: { step: PipelineRunStep }) {
    const buildId = step?.components?.find((c) => c.type === "stack.build")?.id;

    const { data: images, error } = useGetImagesQuery(
        {
            filter: {
                source_type: "stack-build",
                source_id: buildId || "",
            },
            include: ["stack_builds", "stacks"],
            meta: ["containers_count"],

            sort: ["-id"],
        },
        { skip: !buildId }
    );

    if (error) {
        throw error;
    }

    return (
        <>
            {images?.data?.map((i) => (
                <ImageBuildLog
                    imageId={i.id}
                    codeDimensionsClassName="max-h-[25rem] max-w-[55rem]"
                    title={
                        <div className="flex items-center gap-2 !normal-case">
                            <Link
                                to={generateDialogLink("image", {
                                    "dialog-image-id": i.id,
                                })}
                            >
                                {i?.name}
                            </Link>
                            <ResourceStateBadge state={i.state} />
                        </div>
                    }
                />
            ))}
        </>
    );
}
