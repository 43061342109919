import {
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCodeBranch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useInteractions,
    arrow,
    FloatingArrow,
} from "@floating-ui/react";
import { useRegisterSW } from "virtual:pwa-register/react";
import { $trace, $error } from "@cycleplatform/core/util/log";

export function Version() {
    const [dismissed, setDismissed] = useState(false);

    const {
        updateServiceWorker,
        needRefresh: [needRefresh],
    } = useRegisterSW({
        onRegisteredSW: (_, r) => {
            setInterval(async () => {
                $trace("checking for service worker update");
                try {
                    await r?.update();
                } catch (e) {
                    $error("error while checking for service worker update", e);
                }
            }, 2 * 60_000);
        },
        onRegisterError(error) {
            $error("unable to register service worker", error);
        },
    });

    const arrowRef = useRef(null);

    const { x, y, strategy, refs, context } = useFloating({
        open: needRefresh && !dismissed,
        placement: "top",
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <div ref={refs.setReference} {...getReferenceProps()}>
            <a
                href="https://cycle.io/changelog/"
                className="inherit hover:!text-cycle-blue flex items-center gap-2 text-sm transition"
            >
                <FontAwesomeIcon icon={faCodeBranch} />
                {import.meta.env.VERSION}
            </a>
            {needRefresh && !dismissed ? (
                <div
                    ref={refs.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    }}
                    className="bg-cycle-gray-accent animate-fade-in dark:border-cycle-gray-accent z-10 flex w-60 flex-wrap justify-center rounded-lg border p-4"
                    {...getFloatingProps()}
                >
                    <FloatingArrow
                        className="fill-cycle-gray-accent shadow-lg"
                        ref={arrowRef}
                        context={context}
                    />
                    <button
                        onClick={() => {
                            setDismissed(true);
                        }}
                        className="hover:text-cycle-blue absolute right-2 top-1 transition-colors"
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            // Ensure sw refresh isn't stuck when this is clicked
                            // by removing the flag from local storage
                            localStorage.removeItem("swNeedRefresh");
                            updateServiceWorker();
                        }}
                    >
                        <div className="flex items-center gap-2 text-sm">
                            <FontAwesomeIcon
                                className="text-cycle-blue"
                                icon={faInfoCircle}
                            />{" "}
                            New Version Available
                        </div>
                        <p className="text-xs">
                            Click here to get the latest updates.
                        </p>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
