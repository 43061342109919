import { components } from "@cycleplatform/core/modules/api/__generated";

/**
 * This function is dedigned to do two things
 * 1. It takes counts and formats into ResourceStatePieChartData format
 * 2.  If "fakes" data when counts is 0
 */
export type ResourceStatePieChartData = {
    id: string;
    label: string;
    value: number;
}[];

export function preprocessCountsData(
    counts:
        | Omit<components["schemas"]["StateCountSummary"], "available">
        | undefined
) {
    return Object.entries(counts?.state || {}).map(([state, count]) => ({
        id: state,
        label: state,
        value: count,
    })) as ResourceStatePieChartData;
}
