import { components } from "@cycleplatform/core/modules/api/__generated";
import {
    extractCreatorDetails,
    getCreatorName,
} from "@cycleplatform/core/modules/creators";
import classNames from "classnames";
import { Avatar } from "../../avatar";
import logoDarkMode from "../../../images/cycle-gear-dark-bg.png";
import logoLightMode from "../../../images/cycle-gear-black.svg";
import { Tooltip } from "../../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/pro-duotone-svg-icons";

type CreatorAvatarProps = {
    creator: components["schemas"]["CreatorScope"];
    creatorInclude: components["schemas"]["CreatorInclude"];
    className?: string;
    theme: "light" | "dark";
};

export function PlatformAvatar({
    className,
    theme,
}: {
    className?: string;
    theme: "dark" | "light";
}) {
    return (
        <img
            className={classNames(
                "h-12 w-12 rounded-full transition-all",
                className
            )}
            src={theme === "dark" ? logoDarkMode : logoLightMode}
            alt="platform"
        />
    );
}

export function CreatorAvatar({
    creator,
    creatorInclude,
    className,
    theme = "dark",
}: CreatorAvatarProps) {
    const details = extractCreatorDetails(creator, creatorInclude);

    if (details?.type === "platform") {
        return (
            <Tooltip message="Platform">
                <PlatformAvatar theme={theme} />
            </Tooltip>
        );
    }

    switch (details?.type) {
        case "account":
        case "employee":
        case "visitor":
            return (
                <Tooltip message={getCreatorName(details) || ""}>
                    <Avatar
                        className={classNames(className)}
                        email={details.creator?.email.address || ""}
                    />
                </Tooltip>
            );
        case "api-key":
            return (
                <Tooltip message={getCreatorName(details)}>
                    <div
                        className={classNames(
                            className,
                            "bg-cycle-gray-light dark:bg-cycle-gray text-cycle-blue flex h-7 w-7 items-center justify-center rounded-full transition-all"
                        )}
                    >
                        <FontAwesomeIcon icon={faKey} />
                    </div>
                </Tooltip>
            );
        case "environment":
            return (
                <Tooltip message={getCreatorName(details)}>
                    <PlatformAvatar theme={theme} />
                </Tooltip>
            );
        case "platform-pipeline":
            return (
                <Tooltip message={getCreatorName(details)}>
                    <PlatformAvatar theme={theme} />
                </Tooltip>
            );
    }

    return (
        <Tooltip message={"unknown type"}>
            <PlatformAvatar theme={theme} />
        </Tooltip>
    );
}
