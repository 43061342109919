import { ContainerConfig } from "~/services/cycle";

/** Replace dots in keys used in react-hook-form where necessary */
export const DOT_DELIMITER = "#!#";

/**
 * Sanitizes fields in a container config that aren't compatible with react-hook-form.
 * Namely, any field containing a 'dot' in the key will break react-hook-form syntax.
 * Here, we replace any place where we may have a dot that would break the system.
 */
export function sanitizeContainerConfig(
    config: ContainerConfig
): ContainerConfig {
    const clone: ContainerConfig = JSON.parse(JSON.stringify(config));

    if (clone.runtime?.sysctl) {
        Object.entries(clone.runtime.sysctl).forEach(([field, val]) => {
            if (!clone.runtime?.sysctl?.[field]) {
                return;
            }
            delete clone.runtime.sysctl[field];
            clone.runtime.sysctl[field.replace(".", DOT_DELIMITER)] = val;
        });
    }

    return clone;
}

/**
 * Undoes sanitizeContainerConfig and preps the config for submitting.
 */
export function unsanitizeContainerConfig(
    config: ContainerConfig
): ContainerConfig {
    const clone: ContainerConfig = JSON.parse(JSON.stringify(config));

    if (clone.runtime?.sysctl) {
        Object.entries(clone.runtime.sysctl).forEach(([field, val]) => {
            if (!clone.runtime?.sysctl?.[field]) {
                return;
            }
            delete clone.runtime.sysctl[field];
            clone.runtime.sysctl[field.replace(DOT_DELIMITER, ".")] = val;
        });
    }

    return clone;
}
