import {
    useCreateHubMutation,
    useCreateOrderJobMutation,
    useCreateOrderMutation,
} from "~/services/cycle";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "~/hooks";
import { cycleApi } from "~/services/cycle";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { setActiveHub } from "~/modules/hubs/slice";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError } from "~/components/forms/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type HubCreateFormType = {
    name: string;
};

export function HubCreateForm() {
    const form = useForm<HubCreateFormType>({});
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createHub] = useCreateHubMutation();
    const [createOrder] = useCreateOrderMutation();
    const [createOrderJob] = useCreateOrderJobMutation();
    const dispatch = useAppDispatch();

    const nav = useNavigate();

    const onHubCreate = (data: HubCreateFormType) => {
        if (!isDirty) {
            return;
        }
        return createHub({
            body: {
                name: data.name,
            },
        })
            .unwrap()
            .then((hub) => {
                dispatch(setActiveHub({ hubId: hub?.data?.id }));
            })
            .then(() =>
                createOrder({
                    body: {},
                }).unwrap()
            )
            .then((order) =>
                createOrderJob({
                    orderId: order?.data?.id || "",
                    body: {
                        action: "confirm",
                    },
                }).unwrap()
            )
            .then(() => {
                dispatch(
                    cycleApi.util.invalidateTags([
                        "Hub",
                        "Hub.Resource",
                        "Hub.Membership",
                        "Hub.Integration",
                        "Hub.Provider",
                        "Hub.Invite",
                        "Hub.Role",
                        "Hub.Notifications",
                        "PersonalAccount",
                        "PublicAccount",
                        "SearchIndex",
                    ])
                );
                nav({ pathname: "/", search: "" });
            })
            .catch(handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onHubCreate)}>
            <RhfFormField label="hub name" name="name" required>
                <TextInput {...register("name", { ...required() })} />
            </RhfFormField>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onHubCreate)}
                    icon={faPlus}
                    flavor="primary"
                    disabled={!isDirty}
                >
                    Create
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
