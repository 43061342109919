import { useCountdown } from "../../../../hooks";

type FormattedPurgeTimerProps = {
    purgeTime: string;
    onlyTime?: boolean;
};

export function FormattedPurgeTimer({
    purgeTime,
    onlyTime,
}: FormattedPurgeTimerProps) {
    const { hours: h, minutes: m } = useCountdown(new Date(purgeTime));
    const hours = h || 0;
    const minutes = m || 0;

    const currentTime = `${hours ? `${hours}h` : ""} ${
        minutes ? `${minutes}m` : ""
    }`;

    if (onlyTime) {
        return <>{currentTime}</>;
    }

    return <div>{`Pending Purge (${currentTime})`}</div>;
}
