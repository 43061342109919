import classNames from "classnames";

type MeterProps = {
    percent: number;
    text?: string;
    className?: string;
    fillClassName?: string;
    textClassName?: string;
};

export function Meter({
    percent,
    text,
    textClassName,
    className,
    fillClassName,
}: MeterProps) {
    return (
        <div
            className={classNames(
                "bg-cycle-gray-light dark:bg-cycle-gray-accent dark:text-cycle-white relative h-8 overflow-hidden rounded-2xl ",
                className
            )}
        >
            <div
                style={{ right: `${100 - percent}%` }}
                className={classNames(
                    "bg-cycle-blue absolute inset-0 rounded-2xl transition-all",
                    fillClassName
                )}
            />
            <div
                className={classNames(
                    textClassName,
                    "relative flex h-full w-full items-center justify-center text-sm font-semibold leading-4"
                )}
            >
                {text}
            </div>
        </div>
    );
}
