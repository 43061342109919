import classNames from "classnames";

/**
 * InputRow is used as a wrapper for specific form rows.
 * The margin classnames are designed in such a way that the margin will "bubble" up to the top, and collapse on the last item
 * This works because the PanelContent is wrapped in a m-4 so the final margin will collapse with the PanelContent margin.
 * By removing the mb of the children, we avoid double margin in nested structures
 */
export function InputRow({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames(className, "mb-4 [&_*]:!mb-0", "flex gap-4")}
        >
            {children}
        </div>
    );
}
