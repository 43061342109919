import { Container, LogLine } from "~/services/cycle";
import { PageContent } from "@cycleplatform/ui/components/page";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { useState } from "react";
import { LogsTable } from "./components/LogsTable";
import { FetchLogsForm } from "./components/FetchLogsForm";

type LogsTabProps = {
    container?: Container;
};

export function LogsTab({ container }: LogsTabProps) {
    const [logs, setLogs] = useState<LogLine[] | undefined>();

    return (
        <DialogPageBody className="relative">
            <PageContent>
                <Panel>
                    <PanelTitle title="Logs" className="flex justify-between">
                        <FetchLogsForm
                            container={container}
                            setLogs={setLogs}
                        />
                    </PanelTitle>

                    <LogsTable container={container} logs={logs} />
                </Panel>
            </PageContent>
        </DialogPageBody>
    );
}
