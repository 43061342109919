import {
    InvalidState,
    NotFound,
    PermissionDenied,
    ResourceError,
    WrongHub,
} from "@cycleplatform/ui/components/resources/panels";
import { isCycleApiError } from "~/services/helpers";
import * as Sentry from "@sentry/browser";
import { Button } from "@cycleplatform/ui/components/buttons";
import { Capability } from "~/services/cycle";
import { FallbackProps } from "react-error-boundary";

type CycleFallbackProps = Omit<FallbackProps, "error"> & {
    error: unknown;
    resourceName?: string;
    capability?: Capability | Capability[];
};

export function ErrorFallback({
    error,
    resetErrorBoundary,
    resourceName,
    capability,
}: CycleFallbackProps) {
    if (isCycleApiError(error)) {
        switch (error.status) {
            case 403:
                // TODO segment log in the future
                switch (error.data.error.code) {
                    case "403.wrong-hub":
                    case "403.mismatch":
                        return (
                            <div className="w-full p-4">
                                <WrongHub
                                    what={
                                        resourceName || getResourceNameFromUrl()
                                    }
                                />
                            </div>
                        );
                    case "403.invalid-state":
                        return (
                            <div className="w-full p-4">
                                <InvalidState detail={error.data.error.title} />
                            </div>
                        );
                    default:
                        let fcl: Capability[] = [];
                        if (Array.isArray(capability)) {
                            fcl = capability;
                        } else if (capability) {
                            fcl.push(capability);
                        }

                        if (error.data.error.extra?.capability) {
                            fcl.push(error.data.error.extra.capability);
                        }

                        return (
                            <div className="w-full rounded p-4">
                                <PermissionDenied
                                    details={error.data.error.title}
                                />
                            </div>
                        );
                }
            case 404:
                return (
                    <div className="w-full p-4">
                        <NotFound
                            what={resourceName || getResourceNameFromUrl()}
                        />
                    </div>
                );
            case 500:
            default:
                Sentry.captureException(JSON.stringify(error.data.error));
                return (
                    <div className="flex w-full flex-col items-center p-4">
                        <ResourceError
                            details={
                                error.data.error.detail ||
                                error.data.error.title ||
                                ""
                            }
                        />
                        <Button flavor="primary" onClick={resetErrorBoundary}>
                            Try Again
                        </Button>
                    </div>
                );
        }
    }

    Sentry.captureException(error);
    return (
        <div className="flex w-full flex-col items-center p-4">
            <ResourceError details="We've encountered a problem. Our engineers have been notified" />
            <Button flavor="primary" onClick={resetErrorBoundary}>
                Try Again
            </Button>
        </div>
    );
}

// Unfortunately there's not an easier/better way to
function getResourceNameFromUrl() {
    const url = window.location.pathname;
    const isDialog = window.location.href.includes("dialog=");

    if (isDialog) {
        return "resource";
    }

    if (url.includes("environments")) {
        return "environment";
    }

    if (url.includes("images")) {
        return "image";
    }

    if (url.includes("stacks")) {
        return "stack";
    }

    if (url.includes("pipelines")) {
        return "pipeline";
    }

    if (url.includes("images")) {
        return "image";
    }

    return undefined;
}
