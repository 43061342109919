import { formatBytesString } from "@cycleplatform/core/util/bytes";
import { RhfFormProvider } from "@cycleplatform/ui/components/forms";
import classNames from "classnames";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ContainerBackup,
    Container,
    useGetContainerBackupsQuery,
    useDeleteContainerBackupMutation,
} from "~/services/cycle";
import { BackupContent } from "./BackupContent";
import { PageAside, PageBody } from "@cycleplatform/ui/components/page";
import { useIdSelectForm } from "~/components/common/forms/IdSelect/hooks";
import { ToolTray } from "~/components/common/tooltray/ToolTray";
import { RhfMultipleActionButton } from "~/components/common/forms/IdSelect/RhfMultipleActionButton";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    DialogResourceList,
    DialogResourceListItem,
} from "~/components/layout/resources";
import { EmptyResourceListItem } from "~/components/layout/resources/EmptyResourceListItem";
import { DialogPageContent } from "@cycleplatform/ui/components/dialog";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { formatDateString } from "@cycleplatform/core/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { ContainerDialogContext } from "../context";
import { useWatch } from "react-hook-form";
import { RhfIdCheckboxSelectAll } from "~/components/common/forms/IdSelect/RhfIdCheckboxSelectAll";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { EmptyActionPanel } from "@cycleplatform/ui/components/panels";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import {
    isHypervisorContainer,
    isServiceContainer,
} from "@cycleplatform/core/modules/containers/util";
type BackupsTabProps = {
    container?: Container;
    backupId: string | undefined;
};

export function BackupsTab({ container, backupId }: BackupsTabProps) {
    const { environment } = useContext(ContainerDialogContext);
    const {
        data: backups,
        isLoading,
        error,
    } = useGetContainerBackupsQuery(
        {
            containerId: container?.id || "",
            sort: ["-id"],
            include: ["integrations"],
        },
        { skip: !container?.id }
    );

    const [deleteBackup] = useDeleteContainerBackupMutation();
    const navigate = useNavigate();

    // If there is no selected backup, navigate to the first instance in the array
    useEffect(() => {
        if (backupId || !backups?.data?.[0]) {
            return;
        }

        navigate(
            generateDialogLink("container", {
                "dialog-backup-id": backups?.data?.[0].id || "",
                "dialog-tab": "backups",
            }),
            { replace: true }
        );
    }, [backupId, backups?.data]);

    const form = useIdSelectForm();
    const selectedIds = useWatch({ name: "ids", control: form.control });
    const selectedBackup = backups?.data?.find((b) => b.id === backupId);
    const isHypervisor = isHypervisorContainer(container);
    const isService = isServiceContainer(container);

    if (error) {
        throw error;
    }

    if (isHypervisor) {
        return (
            <EmptyActionPanel
                title={"Backups are not available for hypervisor containers"}
                icon={NavIcons["backups"]}
            />
        );
    }

    if (isService) {
        return (
            <EmptyActionPanel
                title={"Backups are not available for service containers"}
                icon={NavIcons["backups"]}
            />
        );
    }

    if (container && !container?.stateful) {
        return (
            <EmptyActionPanel
                title={"Backups are only available for stateful containers."}
                icon={NavIcons["backups"]}
            />
        );
    }

    if (
        container &&
        !container.config.integrations?.backups &&
        backups?.data &&
        !backups.data.length
    ) {
        return (
            <>
                <EmptyActionPanel
                    title={"Backups are not enabled for this container."}
                    icon={NavIcons["backups"]}
                >
                    <AccessControlOverlay
                        aclResource={environment}
                        verifyFn={modifyAccessFn("containers-manage")}
                    >
                        <ButtonLink
                            to={generateDialogLink("container", {
                                "dialog-tab": "config",
                                "dialog-config": "integrations",
                            })}
                            icon={faAdd}
                        >
                            Enable Backups
                        </ButtonLink>
                    </AccessControlOverlay>
                </EmptyActionPanel>
            </>
        );
    }

    return (
        <PageBody className="h-full overflow-y-hidden">
            <PageAside className="relative h-full !w-auto border-r bg-white dark:bg-black">
                <RhfFormProvider {...form} className="h-full ">
                    <DialogResourceList
                        className={classNames(selectedIds.length && "pb-16")}
                    >
                        {!container || isLoading ? (
                            <>
                                {Array.from({ length: 3 }).map((_, idx) => (
                                    <DialogResourceListItem
                                        key={idx}
                                        isLoading={true}
                                        to=""
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                {backups?.data && backups?.data?.length ? (
                                    <>
                                        <div className="flex items-center gap-4 p-4">
                                            <RhfIdCheckboxSelectAll
                                                resources={backups?.data}
                                                className="ml-1"
                                            />

                                            <p className="text-base">
                                                {" "}
                                                {`- Select All`}
                                            </p>
                                        </div>

                                        {backups?.data?.map((b) => (
                                            <BackupItem
                                                key={b.id}
                                                backup={b}
                                                isActive={backupId === b.id}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <EmptyResourceListItem
                                        resourceName="Backups"
                                        icon={NavIcons["backups"]}
                                    />
                                )}
                            </>
                        )}
                    </DialogResourceList>

                    <ToolTray>
                        <RhfMultipleActionButton
                            resourceName="backups"
                            mutation={(id: string) =>
                                deleteBackup({
                                    containerId: container?.id || "",
                                    backupId: id,
                                }).unwrap()
                            }
                        />
                    </ToolTray>
                </RhfFormProvider>
            </PageAside>

            <DialogPageContent>
                {backupId && container ? (
                    <div className="flex w-full flex-1 flex-col gap-4 ">
                        <AccessControlledSection
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "containers-backups-manage"
                            )}
                        >
                            <BackupContent
                                key={backupId}
                                backupId={backupId}
                                containerId={container.id}
                                backup={selectedBackup}
                                backupIncludes={backups?.includes}
                            />
                        </AccessControlledSection>
                    </div>
                ) : null}

                {backups?.data?.length === 0 ? (
                    <EmptyActionPanel
                        title={"No Backups available for this container"}
                        icon={NavIcons["backups"]}
                    >
                        <ButtonLink
                            to={generateDialogLink("container", {
                                "dialog-tab": "config",
                                "dialog-config": "integrations",
                            })}
                            icon={faAdd}
                        >
                            View Backups Settings
                        </ButtonLink>
                    </EmptyActionPanel>
                ) : null}
            </DialogPageContent>
        </PageBody>
    );
}

function BackupItem({
    backup,
    isActive,
}: {
    backup: ContainerBackup;
    isActive: boolean;
}) {
    return (
        <AccessControlledSection capability={"containers-backups-manage"}>
            <DialogResourceListItem
                checkboxId={backup.id}
                isLoading={!backup}
                to={generateDialogLink("container", {
                    "dialog-backup-id": backup.id,
                    "dialog-tab": "backups",
                })}
                state={backup.state}
                isActive={isActive || false}
            >
                <div className={classNames("flex  flex-col text-inherit")}>
                    <div className="w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap">
                        {formatDateString(backup.events.created, "Pp")}
                    </div>

                    <span className="text-sm">
                        {formatBytesString(backup.target.size)}
                    </span>

                    {backup.state.error && (
                        <div className="text-wrap w-[8rem] whitespace-normal break-all">
                            <div className="text-error text-xs">
                                {backup.state.error.message}
                            </div>
                        </div>
                    )}
                </div>
            </DialogResourceListItem>
        </AccessControlledSection>
    );
}
