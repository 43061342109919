import { BooleanSelect } from "@cycleplatform/ui/components/forms/select";

type StatefulOption = {
    id: string;
    detail: string;
};

type StatefulSelectProps = {
    name: string;
    value?: boolean;
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value: boolean | null) => void;
};

export function StatefulSelect({
    value,
    onChange,
    ...props
}: StatefulSelectProps) {
    return (
        <BooleanSelect
            {...props}
            value={value}
            onChange={onChange}
            options={[
                {
                    value: false,
                    label: "Stateless",
                    detail: "Stateless containers are treated as ephemeral instances that can be scaled up and down without worrying about data loss.",
                },
                {
                    value: true,
                    label: "Stateful",
                    detail: "Stateful containers are treated as high priority, static instances that cannot be auto-scaled. Useful for databases or containers that care about data between restarts and need to be scaled manually.",
                },
            ]}
        />
    );
}
