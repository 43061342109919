import React, { ComponentPropsWithoutRef, FC } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import anOldHope from "react-syntax-highlighter/dist/esm/styles/hljs/an-old-hope";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import classNames from "classnames";

type CodeHighlighterProps = {
    language: any;
    code: any;
    style?: React.CSSProperties;
    highlighterStyle?: React.CSSProperties;
    className?: string;
};

export function CodeHighlighter({
    code,
    language,
    style,
    highlighterStyle,
    className,
}: CodeHighlighterProps) {
    SyntaxHighlighter.registerLanguage("json", json);

    return (
        <div
            className={classNames(
                className,
                "[&>pre]:!bg-cycle-black leading-6 [&>pre]:rounded-lg"
            )}
        >
            <SyntaxHighlighter
                wrapLongLines
                language={language}
                style={anOldHope}
                customStyle={highlighterStyle}
            >
                {code}
            </SyntaxHighlighter>
        </div>
    );
}
