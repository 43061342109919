import {
    Checkbox,
    RhfFormField,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import { NamespacesInput } from "./NamespacesInput";
import { KernelCapsInput } from "./KernelCapsInput";

export function PrivilegesSection() {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <>
            <FormSectionHeader header="Privileges" />
            <FormSection>
                <NamespacesInput />
                <KernelCapsInput />
                <RhfFormField
                    label="Fully Privileged [Not Recommended]"
                    name="config.runtime.privileged"
                    help="Selecting this option will give this
                    container full permissions on the
                    server. This is not recommended and
                    increases the likelihood of your server
                    being compromised."
                >
                    <Checkbox {...register("config.runtime.privileged")} />
                </RhfFormField>
            </FormSection>
        </>
    );
}
