import {
    useFloating,
    offset,
    shift,
    size,
    autoUpdate,
    useRole,
    useInteractions,
} from "@floating-ui/react";
import {
    faCheck,
    faChevronDown,
    faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useCallback, useState } from "react";

type ListboxProps<T> = {
    className?: string;
    name?: string;
    options: T[];
    defaultValue?: T;
    value?: T | null;
    onChange?: (value: T | null) => void;
    disabled?: boolean;
    placeholder?: string;
    error?: boolean;
    isNullable?: boolean;
    /** If provided, will validate the option, and disable if not valid. */
    isOptionValid?: (option: T) => boolean;

    /** Formats the value shown in the input based on the option. If none is provided, the option will be cast to a string. */
    formatDisplayValue?: (option?: T) => string;
    /** Formats the option in the option list. Can be any valid react component. */
    formatOption?: (
        option: T,
        selected?: boolean,
        active?: boolean,
        disabled?: boolean
    ) => React.ReactNode;

    /**
     * Provide this function to group options into categories.
     * The key is the category name (displayed as a separator), and the value
     * are the options in that group
     */
    groupOptions?: (options: T[]) => Record<string, T[]>;
};

export function Listbox<T>({
    value,
    name,
    onChange,
    defaultValue,
    className,
    disabled,
    placeholder,
    error,
    options,
    formatDisplayValue = (option?: T) => `${option}`,
    groupOptions = (options: T[]) => ({ "": options }),
    isOptionValid = (option: T) => true,
    formatOption = (option: T) => `${option}`,
    isNullable,
}: ListboxProps<T>) {
    const [selected, setSelected] = useState<T>();
    const { refs, context, floatingStyles } = useFloating({
        placement: "bottom-start",
        strategy: "fixed",
        middleware: [
            offset(10),
            shift(),
            size({
                apply({ elements }) {
                    // clamp to max width of the select
                    Object.assign(elements.floating.style, {
                        maxWidth: `${
                            elements.reference.getBoundingClientRect().width
                        }px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const role = useRole(context, { role: "menu" });
    const { getReferenceProps, getFloatingProps } = useInteractions([role]);

    const onChangeHandler = useCallback(
        (value: T) => {
            setSelected(value);
            onChange?.(value);
        },
        [setSelected, onChange]
    );

    return (
        <HeadlessListbox
            name={name}
            value={value}
            onChange={onChangeHandler}
            defaultValue={defaultValue}
            disabled={disabled}
        >
            <HeadlessListbox.Button
                ref={refs.setReference}
                {...getReferenceProps()}
                placeholder={placeholder}
                className={classNames(
                    className,
                    "relative flex min-h-[40px] w-full cursor-default items-center rounded-lg border border-inherit bg-white text-left text-base tracking-normal",
                    "focus-visible:border-cycle-blue focus-visible:ring-cycle-blue focus:outline-none focus-visible:ring-2",
                    "dark:bg-cycle-gray-accent dark:text-cycle-white-accent dark:border-none",
                    "disabled:bg-cycle-gray/10 disabled:cursor-not-allowed disabled:dark:bg-black disabled:dark:text-opacity-50",
                    { "ring-error border-transparent ring-2": error }
                )}
            >
                {({ value }) => (
                    <>
                        <span className="block truncate pl-4">
                            {formatDisplayValue(value || selected) || (
                                <span className="text-cycle-gray/70 dark:text-cycle-white/50">
                                    {placeholder}
                                </span>
                            )}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                        {isNullable && value ? (
                            <button
                                className="absolute inset-y-0 right-6 flex items-center pr-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onChange?.(null);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={classNames("text-error h-4 w-4")}
                                    aria-hidden="true"
                                />
                            </button>
                        ) : null}
                    </>
                )}
            </HeadlessListbox.Button>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <HeadlessListbox.Options
                    className={classNames(
                        "z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5",
                        "focus-visible:ring-cycle-blue focus:outline-none focus-visible:ring-2",
                        "dark:bg-cycle-gray-accent"
                    )}
                    ref={refs.setFloating}
                    style={{ ...floatingStyles }}
                    {...getFloatingProps()}
                >
                    {Object.entries(groupOptions(options)).map(
                        ([group, options], idx) => (
                            <Fragment key={`${group}-${idx}`}>
                                {group !== "" && (
                                    <div className="bg-cycle-gray-light dark:bg-cycle-black-accent p-2">
                                        {group}
                                    </div>
                                )}
                                {options.map((o, idx) => (
                                    <HeadlessListbox.Option
                                        key={idx}
                                        value={o}
                                        disabled={!isOptionValid(o)}
                                        className={({
                                            active,
                                            selected,
                                            disabled,
                                        }) =>
                                            classNames(
                                                "relative min-h-[40px] cursor-pointer select-none pl-6 text-base tracking-normal",
                                                active
                                                    ? "bg-cycle-blue text-cycle-white"
                                                    : "text-cycle-gray-accent dark:text-cycle-white",
                                                disabled &&
                                                    "bg-cycle-gray-light dark:bg-cycle-black-accent dark:text-cycle-gray-light/50"
                                            )
                                        }
                                    >
                                        {({ selected, active, disabled }) => (
                                            <>
                                                {selected && (
                                                    <div className="absolute inset-y-1/3 left-2 flex items-center">
                                                        <FontAwesomeIcon
                                                            className={classNames(
                                                                "text-cycle-blue",
                                                                {
                                                                    "text-cycle-white":
                                                                        active,
                                                                }
                                                            )}
                                                            icon={faCheck}
                                                        />
                                                    </div>
                                                )}
                                                {formatOption(
                                                    o,
                                                    selected,
                                                    disabled
                                                )}
                                            </>
                                        )}
                                    </HeadlessListbox.Option>
                                ))}
                            </Fragment>
                        )
                    )}
                </HeadlessListbox.Options>
            </Transition>
        </HeadlessListbox>
    );
}
