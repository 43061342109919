import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useState } from "react";
import { AccessControlOverlay } from "~/components/common/buttons";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { useCreateVpnServiceJobMutation } from "~/services/cycle";

export function VpnControlsSection() {
    const { environment } = useContext(ContainerDialogContext);

    // HandleLoading is used to lock the restart buttons for a set amount of time
    // We cannot track the job because the job completes immediately
    const [isLoading, setIsLoading] = useState(false);
    const [createVpnJob] = useCreateVpnServiceJobMutation();

    const handleLoading = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 5_000);
    };
    return (
        <Panel>
            <PanelTitle title="Controls" />
            <PanelContent>
                <div className="flex justify-start">
                    <AccessControlOverlay
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                    >
                        <PushAndHoldButton
                            flavor="primary"
                            icon={faRepeat}
                            onClick={() => {
                                handleLoading();
                                createVpnJob({
                                    environmentId: environment?.id || "",
                                    body: {
                                        action: "reset",
                                    },
                                });
                            }}
                            disabled={!environment?.id}
                            isLoading={isLoading}
                        >
                            Reset Keys and Configs
                        </PushAndHoldButton>
                    </AccessControlOverlay>
                </div>
            </PanelContent>
        </Panel>
    );
}
