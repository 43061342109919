/** Converts an amount of money from dollars to mills (1/1000 of a dollar) */
export function dollarsToMills(dollars: number): number {
    return dollars * 1000;
}

/** Converts an amount of money from mills (1/1000 of a dollar) to dollars */
export function millsToDollars(mills: number): number {
    return mills / 1000;
}

export function formatMoney({
    value,
    mills = false,
    formatOpts = {},
}: {
    value: number;
    mills?: boolean;
    formatOpts?: Intl.NumberFormatOptions;
}) {
    let v = value;
    if (mills) {
        v /= 1000;
    }

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        ...formatOpts,
    });
    return formatter.format(v);
}
