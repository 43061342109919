import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { UpdateMonitoringTierForm } from "./form/UpdateMonitoringTier";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function EnvironmentMonitoringDialog() {
    const params = getAllDialogSearchParams<"environment-monitoring">();
    const nav = useNavigate();
    return (
        <StyledFormDialog
            title="Update Monitoring Tier"
            isOpen={isDialogOpen("environment-monitoring")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="!w-full">
                <UpdateMonitoringTierForm envId={params["dialog-env-id"]} />
            </DialogColumn>
        </StyledFormDialog>
    );
}
