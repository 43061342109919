import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";

type CategoryFilterProps = {
    active: string | null;
    setCategory: (c: string | null) => void;
    categories: string[];
    loading?: boolean;
    additionalOnClick?: () => void;
};

export function CategoryFilter({
    active,
    setCategory,
    categories,
    loading,
    additionalOnClick,
}: CategoryFilterProps) {
    return (
        <div className="flex gap-2 pb-2">
            {!loading ? (
                <>
                    <FilterButton
                        active={active}
                        filter="all"
                        setCategory={setCategory}
                    />
                    {categories.map((c) => (
                        <FilterButton
                            key={c}
                            active={active}
                            filter={c}
                            setCategory={setCategory}
                            additionalOnClick={additionalOnClick}
                        />
                    ))}
                </>
            ) : (
                <>
                    {Array.from({
                        length: 2,
                    }).map((_, idx) => (
                        <div
                            className={classNames(skeletonStyles, "h-8 w-20")}
                            key={idx}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

type FilterButtonProps = {
    active: string | null;
    filter: string;
    setCategory: (c: string | null) => void;
    additionalOnClick?: () => void;
};

function FilterButton({
    active,
    filter,
    setCategory,
    additionalOnClick,
}: FilterButtonProps) {
    // const { setValue } = useFormContext<CreateServerSchema>();
    return (
        <button
            type="button"
            className={classNames(
                "dark:bg-cycle-gray-accent rounded-md bg-white px-4 py-1 dark:text-white",
                active === filter ? "!bg-cycle-blue text-cycle-white" : "border"
            )}
            onClick={() => {
                setCategory(filter);
                additionalOnClick?.();
            }}
        >
            {filter
                .split("-")
                .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")}
        </button>
    );
}
