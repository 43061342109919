import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

type FormSectionHeaderProps = {
    header: React.ReactNode;
    help?: string;
    children?: React.ReactNode;
    className?: string;
    tooltip?: React.ReactNode;
    onClick?: () => void;
};

export function FormSectionHeader({
    header,
    help,
    children,
    className,
    tooltip,
    onClick,
}: FormSectionHeaderProps) {
    const h = <h3 className={classNames(className, " text-lg")}>{header}</h3>;
    return (
        <div className="relative flex w-full flex-1 items-center justify-between pb-4">
            <div className="flex h-8 w-full items-center gap-2">
                {onClick ? (
                    <button type="button" onClick={onClick}>
                        {h}
                    </button>
                ) : (
                    h
                )}

                {help && (
                    <Tooltip message={help}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-cycle-blue text-xs"
                        />
                    </Tooltip>
                )}
                {tooltip ? <div>{tooltip}</div> : null}
            </div>
            {children}
        </div>
    );
}
