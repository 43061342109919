import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { NavIcons } from "~/components/layout/NavIcons";
import { Account, useGetAccountLoginsQuery } from "~/services/cycle";

type EmployeeAccessLogProps = {
    account?: Account;
};

export function EmployeeAccessLog({ account }: EmployeeAccessLogProps) {
    const { data: logins, error } = useGetAccountLoginsQuery({
        sort: ["-id"],
        filter: {
            type: "employees",
        },
        page: {
            number: 1,
            size: 10,
        },
    });

    if (error) {
        throw error;
    }

    return (
        <Panel>
            <PanelTitle title="Employee Access Log" />

            {logins?.data && logins?.data.length > 0 ? (
                <PanelContent>
                    {logins?.data?.map((l) => (
                        <div
                            key={l.id}
                            className={classNames(
                                " border-l-4 p-2",
                                { "border-error": !l.success },
                                { "border-success": l.success }
                            )}
                        >
                            {`${
                                l.success ? "Successful" : "Failed"
                            } login from IP ${l.account.ip}`}
                            <div className="flex items-center gap-2 text-sm">
                                <FontAwesomeIcon icon={faClock} />
                                {formatDateString(
                                    l.time,
                                    DateFormats["standardShort"]
                                )}
                            </div>
                        </div>
                    ))}
                </PanelContent>
            ) : (
                <EmptyResource
                    title="No Recent Activity"
                    icon={NavIcons["hubAuditLog"]}
                >
                    <p className="text-xs">
                        {!account?.allow_support_login
                            ? "Employee Access Disabled"
                            : ""}
                    </p>
                </EmptyResource>
            )}
        </Panel>
    );
}
