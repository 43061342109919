import { useMemo } from "react";
import { ApiResponseColorMap } from "@cycleplatform/ui/components/charts/colors";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { getAbbreviatedNumberString } from "@cycleplatform/core/util/number";
import {
    pieCenterTextProps,
    pieProps,
} from "@cycleplatform/ui/components/recharts/pie/props";
import { LbUrlResponse } from "./query";
import { CyclePieTooltip } from "@cycleplatform/ui/components/recharts/pie/CyclePieTooltip";

export function ResponsesPieChart({
    responses,
}: {
    responses: LbUrlResponse[];
}) {
    const chartData = useMemo(
        () =>
            responses.reduce((acc, cur) => {
                const resCode = cur.response.split(":")[1] || "";

                return [
                    ...acc,
                    {
                        response: resCode,
                        value: cur.value,
                        color: ApiResponseColorMap[resCode],
                    },
                ] as { response: string; value: number; color: string }[];
            }, [] as { response: string; value: number; color: string }[]),
        [responses]
    );

    const total = useMemo(
        () => responses.reduce((acc, cur) => acc + cur.value, 0),
        [responses]
    );

    return (
        <div className="flex w-full">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart margin={{ top: 1, bottom: 1 }}>
                    <Pie {...pieProps} data={chartData} dataKey="value">
                        {chartData.map((entry, index) => {
                            return (
                                <>
                                    <Cell
                                        className="cursor-pointer"
                                        key={`cell-${entry.response}`}
                                        fill={entry.color}
                                        stroke="rgba(0,0,0,0)"
                                    />
                                </>
                            );
                        })}
                    </Pie>
                    <text {...pieCenterTextProps}>
                        {total ? (
                            <>{getAbbreviatedNumberString(total)}</>
                        ) : null}
                    </text>

                    <Tooltip
                        reverseDirection={{ x: true, y: true }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        offset={-5}
                        content={(props) => (
                            <CyclePieTooltip {...props} dataLabel="response" />
                        )}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}
