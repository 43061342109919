import classNames from "classnames";

type DataLabelProps = {
    label: string;
    className?: string;
};

export function DataLabel({ label, className }: DataLabelProps) {
    return (
        <span className={classNames(className, "font-bold uppercase")}>
            {label}
        </span>
    );
}
