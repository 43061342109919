import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useContext } from "react";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { LbUrlQueryTelem, getLbUrlsQuery } from "./query";
import { ResponsesPieChart } from "./ResponsesPieChart";
import { HandlersPieChart } from "./HandlersPieChart";
import { LoadBalancerDialogContext } from "../../../../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export function UrlsSection({ port }: { port: number }) {
    const { environment } = useContext(LoadBalancerDialogContext);

    const { data: t, isLoading } = useGenerateAggregatedMetricsQuery(
        getLbUrlsQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const telemetry = t?.data as LbUrlQueryTelem | undefined;

    return (
        <Panel className="w-full">
            <PanelTitle
                className="flex items-center justify-between"
                title="Destinations"
            >
                {telemetry?.[0] && (
                    <div className="text-sm">Showing Last 24 Hours</div>
                )}
            </PanelTitle>
            <PanelContent stretch>
                {!telemetry || isLoading ? (
                    <SkeletonTable />
                ) : (
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledHeaderCell className="max-w-[20rem]">
                                Host
                            </StyledHeaderCell>
                            <StyledHeaderCell className="min-w-[6rem] text-center">
                                Method
                            </StyledHeaderCell>

                            <StyledHeaderCell className="w-[8rem] text-center">
                                Responses
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-[8rem] text-center">
                                Handler
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-[14rem] text-center">
                                Average Time
                            </StyledHeaderCell>
                        </StyledTableHead>

                        <tbody>
                            {telemetry?.length ? (
                                telemetry.map((metric) => (
                                    <DestinationRow
                                        destinationMetric={metric}
                                    />
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            )}
                        </tbody>
                    </StyledDataTable>
                )}
            </PanelContent>
        </Panel>
    );
}

function DestinationRow({
    destinationMetric,
}: {
    destinationMetric: LbUrlQueryTelem[number];
}) {
    return (
        <StyledTableRow key={destinationMetric.host}>
            <StyledCell className="max-w-[20rem]">
                <div className="max-w-[95%] truncate">
                    {destinationMetric.path}
                </div>
                <div className="text-sm">{destinationMetric.host}</div>
            </StyledCell>
            <StyledCell className="text-center">
                {destinationMetric.method}
            </StyledCell>
            <StyledCell>
                <div className="m-2 flex h-[4rem] w-[8rem] text-center">
                    <ResponsesPieChart
                        responses={destinationMetric.responses}
                    />
                </div>
            </StyledCell>

            <StyledCell>
                <div className="m-2 flex h-[4rem] w-[8rem] text-center">
                    <HandlersPieChart destinationMetric={destinationMetric} />
                </div>
            </StyledCell>

            <StyledCell className="text-center">
                {!!destinationMetric?.proxy_ms_avg && (
                    <div className="text-sm">
                        {`Proxy: ${Math.floor(
                            destinationMetric?.proxy_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.cache_ms_avg && (
                    <div className="text-sm">
                        {`Cache: ${Math.floor(
                            destinationMetric?.cache_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.forward_ms_avg && (
                    <div className="text-sm">
                        {`Forward: ${Math.floor(
                            destinationMetric?.forward_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.redirect_ms_avg && (
                    <div className="text-sm">
                        {`Redirect: ${Math.floor(
                            destinationMetric?.redirect_ms_avg
                        )} ms`}
                    </div>
                )}
            </StyledCell>
        </StyledTableRow>
    );
}
