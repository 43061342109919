import { components } from "../../api/__generated";

export function findAvailableIntegration(
    availableIntegrations:
        | Record<
              string,
              components["schemas"]["IntegrationDefinition"][] | null
          >
        | undefined,
    vendor: string | undefined
) {
    if (!vendor) {
        return undefined;
    }
    return Object.values(availableIntegrations || {})
        .flat()
        .find((i) => i?.vendor === vendor);
}

export function getIntegrationStatus(
    integrations?: components["schemas"]["Integration"][]
) {
    if (!integrations || integrations.length === 0) {
        return "not-configured";
    }
    if (integrations.filter((i) => !!i.state.error)?.length) {
        return "misconfigured";
    } else {
        return "configured";
    }
}
