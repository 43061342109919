import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useForm, useWatch } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/currentForm/slice";
import { OptionButton } from "@cycleplatform/ui/components/options";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { HostedStepType } from "../../types";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

export function SetHosted({ stepIdx = 1 }: { stepIdx?: number }) {
    const { handleNext, handleBack } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector(getCurrentForm);

    const form = useForm<HostedStepType>({
        defaultValues: currentForm?.steps?.[stepIdx] || {},
        ...rhfConfig,
    });

    const {
        handleSubmit,
        control,
        setValue,
        formState: { isSubmitting, isDirty },
    } = form;

    const dispatch = useAppDispatch();

    const hosted = useWatch({ name: "hosted", control });

    const onSubmit = async (data: HostedStepType) => {
        if (!isDirty && !currentForm?.steps?.[stepIdx]) {
            return;
        }
        dispatch(addStepData({ data, idx: stepIdx }));

        handleNext?.();
    };

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <DialogColumn>
                <div className="flex gap-16">
                    <div className="flex gap-2 py-4">
                        <OptionButton
                            onClick={() =>
                                setValue("hosted", true, {
                                    shouldDirty: true,
                                })
                            }
                            title="Hosted Zone"
                            description={
                                <ul className="flex list-disc flex-col gap-2 pt-2 pl-4">
                                    <li>
                                        Domains that will be fully managed by
                                        Cycle.
                                    </li>
                                    <li>
                                        These domains must have their
                                        authoritative nameservers configured to
                                        Cycle's nameservers.
                                    </li>
                                    <li>
                                        These domains must be verified to ensure
                                        proper ownership.
                                    </li>
                                </ul>
                            }
                            isActive={hosted === true}
                        />

                        <OptionButton
                            onClick={() =>
                                setValue("hosted", false, {
                                    shouldDirty: true,
                                })
                            }
                            title="Non-Hosted Zone"
                            description={
                                <ul className="flex list-disc flex-col gap-2 pt-2 pl-4">
                                    <li>
                                        These domains do not require
                                        verification and maintain their
                                        authoritative nameservers
                                    </li>
                                    <li>
                                        Non-hosted zones require A/AAAA records
                                        at the host to point to your Cycle load
                                        balancer IPs.
                                    </li>
                                </ul>
                            }
                            isActive={hosted === false}
                        />
                    </div>
                </div>
            </DialogColumn>

            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Origin
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                    disabled={!isDirty && !currentForm?.steps?.[stepIdx]}
                >
                    Configure
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
