import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { Environment } from "~/services/cycle";
import { VpnManageUsersForm } from "./VpnManageUsersForm";
import { VpnAccessControlSection } from "./VpnAccessControlSection";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type AuthTabProps = {};

export function AuthTab({}: AuthTabProps) {
    const { environment } = useContext(ContainerDialogContext);
    const accessErr = useVerifyAccess(
        environment,
        modifyAccessFn("environments-services-manage")
    );

    return (
        <DialogPageBody>
            <PageContent>
                <SectionDisabledControl
                    disabled={!!accessErr}
                    className="w-full"
                >
                    <VpnManageUsersForm environment={environment} />
                </SectionDisabledControl>
            </PageContent>
            <PageAside>
                <SectionDisabledControl
                    disabled={!!accessErr}
                    className="w-full"
                >
                    <VpnAccessControlSection environment={environment} />
                </SectionDisabledControl>
            </PageAside>
        </DialogPageBody>
    );
}
