import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { WebglAddon } from "xterm-addon-webgl";
import "xterm/css/xterm.css";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export function useTerminal() {
    const containerRef = useRef<HTMLDivElement>(null);
    const fitAddon = useRef(new FitAddon());
    const webglAddon = useRef(new WebglAddon());
    const [terminal] = useState(
        new Terminal({ convertEol: true, scrollback: 9999999 })
    );
    const resize = useCallback(() => {
        fitAddon.current.fit();
    }, [fitAddon]);

    useLayoutEffect(() => {
        if (containerRef.current) {
            terminal.loadAddon(fitAddon.current);
            terminal.loadAddon(webglAddon.current);
            terminal.open(containerRef.current);
            window.addEventListener("resize", resize);
            resize();
        }
        return () => {
            terminal.dispose();
            window.removeEventListener("resize", resize);
        };
    }, []);

    return { containerRef, terminal, resize };
}
