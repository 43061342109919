import { Image } from "~/services/cycle";
import {
    CopyInput,
    FormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";

type ImageSummaryProps = {
    image?: Image;
};
export function ImageSummary({ image }: ImageSummaryProps) {
    return (
        <Panel>
            <PanelTitle title="Image Summary" />
            <PanelContent>
                <div className="flex gap-4">
                    <div className="w-1/4">
                        <FormField label="entrypoint">
                            <TextInput
                                value={
                                    image?.config?.entrypoint?.join(" ") ||
                                    "Not Specified"
                                }
                                disabled={!image?.config?.entrypoint.length}
                            />
                        </FormField>
                    </div>

                    <FormField label="command" className="w-1/4">
                        <CopyInput
                            value={
                                image?.config?.command?.join(" ") || "Unknown"
                            }
                        />
                    </FormField>
                </div>

                <FormField label="ports">
                    <div className="bg-cycle-gray/10 min-h-10 flex h-10 gap-2 rounded-md p-2">
                        {image?.config?.ports?.map((p) => {
                            return (
                                <div
                                    className="bg-cycle-gray/20 h-6 rounded-md px-2 text-sm"
                                    key={p.host}
                                >{`${p.host}:${p.container}/${p.type}`}</div>
                            );
                        })}
                    </div>
                </FormField>

                <div className="flex w-full gap-4">
                    <FormField label="workdir">
                        <CopyInput value={image?.config?.workdir} />
                    </FormField>

                    <FormField label="signal stop">
                        <CopyInput value={image?.config?.signal_stop} />
                    </FormField>
                </div>
            </PanelContent>
        </Panel>
    );
}
