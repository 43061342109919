import {
    Container,
    ContainerState,
    DnsRecord,
    HaProxyConfigSet,
    LoadBalancerConfig,
} from "~/services/cycle";
import { isFunctionContainer } from "@cycleplatform/core/modules/containers/config";
import { getActualTransportConfig } from "@cycleplatform/core/modules/environments/loadbalancer/v1";

export type ContainerPublicNetworkAnalysisStatus =
    | "ok"
    | "error"
    | "warning"
    | "disabled";

export type NetworkAnalysis = {
    mode: "public" | "egress" | "disabled";
    exposedPorts: ReturnType<typeof getAnnotatedContainerPorts>;
    errors: NetworkAnalysisError[];
};

export type NetworkAnalysisError = {
    type: ContainerPublicNetworkAnalysisStatus;
    code: NetworkAnalysisErrorCode;
    details: string;
    resolution?: string;
};

// <affected>.<category>.<issue>
export type NetworkAnalysisErrorCode =
    /** container is not in running state */
    | "container.state.offline"
    | "container.state.deleted"
    | "container.type.service-container"
    /** The container in question is a load balancer */
    | "container.type.is-loadbalancer"
    /** container has no exposed ports */
    | "container.ports.none"
    /** container is listening on https port, but no TLS linked record pointed at it */
    | "container.ports.no-tls-domain"

    /** load balancer is offline */
    | "lb.state.offline"
    /** no instances of load balancer */
    | "lb.instances.none"
    /** LB version is HAProxy, which won't work with the current configuration */
    | "lb.version.haproxy"

    /** container is listening for TLS traffic that is likely being decoded at the load balancer */
    | "container-ingress.ports.tls-mismatch"
    | "container-ingress.dns.discovery-offline"

    /** container is configured for http connections but has no LINKED record pointed at it */
    | "lb-ingress.dns.missing-record";

function analyzeContainerStatus(container: Container): NetworkAnalysisError[] {
    const errors: NetworkAnalysisError[] = [];

    if (container.image.service) {
        errors.push({
            type: "disabled",
            code: "container.type.service-container",
            details:
                "This is a service container who's status is managed by Cycle.",
        });

        if (container.image.service === "loadbalancer") {
            errors.push({
                type: "disabled",
                code: "container.type.is-loadbalancer",
                details:
                    "This is an environment load balancer managed by Cycle.",
            });
        }
        return errors;
    }

    if (container.state.current === "deleted") {
        errors.push({
            type: "disabled",
            code: "container.state.deleted",
            details: "Container has been deleted.",
        });
        return errors;
    }

    if (
        container.config.network.public !== "disable" &&
        container.state.current !== "running" &&
        // function containers won't be running most of the time,
        // but will spin up instances as needed.
        !isFunctionContainer(container)
    ) {
        errors.push({
            type: "error",
            code: "container.state.offline",
            details: "Container is offline.",
            resolution: "Start at least one instance of this container.",
        });
    }

    // TODO health check

    return errors;
}

function analyzeLoadBalancerStatus(
    container: Container,
    lb: Container | undefined,
    lbconfig: LoadBalancerConfig | undefined
): NetworkAnalysisError[] {
    const errors: NetworkAnalysisError[] = [];

    if (!lb) {
        errors.push({
            type: "error",
            code: "lb.instances.none",
            details:
                "A load balancer has not been configured or is not enabled for this environment.",
            resolution:
                "Start this environment or manually start the load balancer.",
        });
    } else {
        if (lb.state.current !== "running") {
            errors.push({
                type: "error",
                code: "lb.state.offline",
                details: "Load balancer is offline.",
                resolution:
                    "Either click 'start all' on the environment, or start the load balancer directly.",
            });
        }

        if (lb.instances === 0) {
            errors.push({
                type: "error",
                code: "lb.instances.none",
                details: "No load balancer instances created.",
                resolution: "On the load balancer instances tab, click 'add'.",
            });
        }
    }

    if (isFunctionContainer(container) && lbconfig?.type === "haproxy") {
        errors.push({
            type: "error",
            code: "lb.version.haproxy",
            details:
                "Function containers are only accessible over the native load balancer.",
            resolution:
                "On the load balancer manage page, select 'V1' as the type under 'settings'.",
        });
    }

    // TODO health check

    return errors;
}

function analyzePortConfiguration(
    container: Container,
    lbConfig: LoadBalancerConfig | undefined
): NetworkAnalysisError[] {
    if (container.config.network.public !== "enable") {
        // port configuration doesn't matter in this situation
        return [];
    }

    const errors: NetworkAnalysisError[] = [];

    if (
        !container.config.network.ports ||
        container.config.network.ports.length === 0
    ) {
        errors.push({
            type: "error",
            code: "container.ports.none",
            details:
                "Container network is set to public, but has no open ports.",
            resolution:
                "Open ports on the container in the container network config.",
        });
    }

    const ports = getAnnotatedContainerPorts(container, lbConfig);
    const domains = getContainerLinkedRecords(container);

    // if we have matching ports:
    // - is there a non-tls record & non-tls port pair?
    // - is there a tls record & tls enabled port pair?
    // - is there a tcp port pair?

    // if ports exist, and no domains, and none of our matching ports are 'tcp' or 'udp' (since they don't need a record to work)
    if (
        !domains.length &&
        ports.length &&
        !ports.some((p) => p.mode === "tcp" || p.mode === "udp")
    ) {
        errors.push({
            type: "error",
            code: "lb-ingress.dns.missing-record",
            details:
                "No LINKED record points to this container. No ingress traffic will be permitted.",
            resolution:
                "Configure at least one LINKED record pointed at this container.",
        });
    }

    const hasTlsDomain = domains.some((d) => d.tls);

    if (ports.some((p) => p.mode === "http" && p.tls) && !hasTlsDomain) {
        errors.push({
            type: "warning",
            code: "container.ports.no-tls-domain",
            details:
                "Container is listening for TLS-encrypted HTTP traffic, but no domains support TLS",
            resolution:
                "Configure at least one TLS-enabled LINKED record for this container.",
        });
    }

    if (
        domains.length &&
        hasTlsDomain &&
        ports.some(
            (p) => p.lbIngress === 443 && p.containerIngress === 443 && p.tls
        )
    ) {
        errors.push({
            type: "warning",
            code: "container-ingress.ports.tls-mismatch",
            details:
                "The container is listening on port 443:443, but the load balancer is configured to do TLS termination on this port.",
            resolution:
                "Update port configuration on the container to be 443:80.",
        });
    }

    return errors;
}

/**
 * Analyzes the network connection from the internet <---> container to
 * identify any issues someone may have trying to connect over the public internet.
 * @param container the container to test
 * @param lb the load balancer container that sits in front of the container
 * @param lbconfig the config for the load balancer that sits in front of the container
 * @returns a detailed analysis of the network connection
 */
export function analyzeContainerNetworkStatus(
    container: Container,
    lb: Container | undefined,
    lbconfig: LoadBalancerConfig | undefined
): NetworkAnalysis {
    const containerNetworkStatus = container.config.network.public;

    return {
        mode:
            containerNetworkStatus === "enable"
                ? "public"
                : containerNetworkStatus === "egress-only"
                ? "egress"
                : "disabled",
        exposedPorts: getAnnotatedContainerPorts(container, lbconfig),
        errors: [
            ...analyzePortConfiguration(container, lbconfig),
            ...analyzeContainerStatus(container),
            ...analyzeLoadBalancerStatus(container, lb, lbconfig),
        ],
    };
}

type AnnotatedContainerPort = {
    lbIngress: number;
    containerIngress: number;
    mode: "tcp" | "http" | "udp";
    tls: boolean;
    disabled: boolean;
    autoRedirect: boolean;
};

/**
 * Retrieves an array of ports (with configurations) of any port that is open allowing traffic from LB <-> Container
 */
function getAnnotatedContainerPorts(
    container: Container,
    lbConfig: LoadBalancerConfig | undefined
): AnnotatedContainerPort[] {
    const containerPorts =
        container.config.network.ports
            ?.map((p) => {
                try {
                    const parts = p.split(":");
                    const lbIngress = parseInt(parts[0]);
                    const containerIngress = parts[1]
                        ? parseInt(parts[1])
                        : lbIngress;
                    return { lbIngress, containerIngress };
                } catch {
                    return null;
                }
            })
            .filter(
                (p): p is { lbIngress: number; containerIngress: number } => !!p
            ) || [];

    return containerPorts
        .map(({ lbIngress, containerIngress }) => {
            switch (lbConfig?.type) {
                case "v1": {
                    // it's important to note we're dealing with either a custom config
                    // OR a base config for the defaults, and it's expected that the caller
                    // passes in some valid config for this load balancer. therefore we can reuse
                    // it as the 'base' as it's expected that the absolute base config at a minimum
                    // will be present.
                    const transport = getActualTransportConfig(
                        lbIngress,
                        lbConfig.details,
                        lbConfig.details
                    );

                    return {
                        lbIngress,
                        containerIngress,
                        mode: transport.mode,
                        tls: transport.config.ingress.tls?.enable || false,
                        disabled: transport.disable,
                        autoRedirect: transport.routers.some((r) => {
                            if (r.config.extension?.type !== "http") {
                                return;
                            }
                            return r.config.extension?.details.redirect
                                ?.auto_https_redirect;
                        }),
                    };
                }
                case "haproxy": {
                    const match =
                        Object.entries(lbConfig.details?.ports || {}).find(
                            (p) => p[0] === `${lbIngress}`
                        )?.[1] || lbConfig.details?.default;

                    if (!match) {
                        return null;
                    }

                    return {
                        lbIngress,
                        containerIngress,
                        mode: match.frontend.mode as string,
                        tls: lbIngress === 443,
                        disabled: false,
                        autoRedirect: lbIngress === 443,
                    };
                }
            }
        })
        .filter((p): p is AnnotatedContainerPort => !!p);
}

function getContainerLinkedRecords(container: Container) {
    const domains = container.meta?.domains?.filter(
        (
            d
        ): d is typeof container.meta.domains[number] & { record: DnsRecord } =>
            !!d.record
    );

    if (!domains || domains.length === 0) {
        return [];
    }

    return domains.map((d) => ({
        fqdn: d.fqdn,
        tls: !!d.record.features?.certificate?.id,
    }));
}

// TESTS

if (import.meta.vitest) {
    const { it, expect, describe } = import.meta.vitest;

    const buildContainerResource = (
        state: ContainerState["current"],
        network: Container["config"]["network"]["public"],
        ports?: string[],
        domains?: { fqdn: string; tls: boolean }[],
        service?: Container["image"]["service"]
    ): Container => {
        return {
            state: {
                current: state,
            },
            config: {
                network: {
                    public: network,
                    ports,
                },
            },
            image: {
                service: service || undefined,
            },
            meta: domains
                ? {
                      domains: domains.map((d) => ({
                          fqdn: d.fqdn,
                          record: {
                              features: d.tls
                                  ? {
                                        certificate: {
                                            id: "record-id",
                                        },
                                    }
                                  : undefined,
                          },
                      })),
                  }
                : undefined,
        } as Container;
    };

    const buildLbContainer = (
        state: ContainerState["current"],
        instances: number = 1
    ): Container => {
        return {
            state: {
                current: state,
            },
            instances,
        } as Container;
    };

    const buildLbV1Config = (
        controllers: {
            port: number;
            tls: boolean;
            tcp?: boolean;
            redirect?: boolean;
        }[]
    ): LoadBalancerConfig => {
        return {
            type: "v1",
            ipv4: true,
            ipv6: true,
            details: {
                controllers: controllers.map((c) => ({
                    port: c.port,
                    identifier: `port-${c.port}`,
                    transport: {
                        mode: c.tcp ? "tcp" : "http",
                        routers: c.redirect
                            ? [
                                  {
                                      config: {
                                          extension: {
                                              details: {
                                                  redirect: {
                                                      auto_https_redirect: true,
                                                  },
                                              },
                                          },
                                      },
                                  },
                              ]
                            : [],
                        config: {
                            ingress: {
                                tls: {
                                    enable: c.tls,
                                },
                            },
                        },
                    },
                })),
                controller_template: {
                    identifier: "template",
                    port: 0,
                    transport: {
                        disable: false,
                        mode: "tcp",
                        config: {
                            performance: false,
                            verbosity: "low",
                            ingress: {},
                        },
                        routers: [
                            {
                                config: {
                                    tls: null,
                                },
                            },
                        ],
                    },
                },
            },
        } as unknown as LoadBalancerConfig;
    };

    const buildLbHaProxyConfig = (
        controllers: { port: number; tls: boolean; tcp?: boolean }[]
    ): LoadBalancerConfig => {
        return {
            ipv4: true,
            ipv6: true,
            type: "haproxy",
            details: {
                default: {},
                ports: controllers.reduce((acc, cur) => {
                    acc[`${cur.port}`] = {
                        frontend: {
                            mode: cur.tcp ? "tcp" : "http",
                        },
                    } as HaProxyConfigSet;
                    return acc;
                }, {} as Record<string, HaProxyConfigSet>),
            },
        } as LoadBalancerConfig;
    };

    describe("analyze container public network", () => {
        it("checks container happy path", () => {
            // test happy path
            let errors = analyzeContainerStatus(
                buildContainerResource("running", "enable", ["80:80", "443:80"])
            );
            expect(errors).toHaveLength(0);
        });
        it("checks for container being offline", () => {
            // test `container.state.offline`
            let errors = analyzeContainerStatus(
                buildContainerResource("stopped", "enable", ["80:80", "443:80"])
            );
            expect(errors).toEqual([
                expect.objectContaining({
                    type: "error",
                    code: "container.state.offline",
                }),
            ]);
            // function containers shouldn't be 'offline'
            errors = analyzeContainerStatus(
                buildContainerResource("function", "enable", [
                    "80:80",
                    "443:80",
                ])
            );
            expect(errors).toHaveLength(0);
        });
        it("checks if container is deleted", () => {
            // test `container.state.deleted`
            let errors = analyzeContainerStatus(
                buildContainerResource("deleted", "enable", ["80:80", "443:80"])
            );
            expect(errors).toEqual([
                expect.objectContaining({
                    type: "disabled",
                    code: "container.state.deleted",
                }),
            ]);
        });
        it("checks if container is a service container", () => {
            // test `container.type.service-container` and `container.type.is-loadbalancer`
            let errors = analyzeContainerStatus(
                buildContainerResource(
                    "running",
                    "enable",
                    ["80:80", "443:80"],
                    undefined,
                    "discovery"
                )
            );
            expect(errors).toEqual([
                expect.objectContaining({
                    type: "disabled",
                    code: "container.type.service-container",
                }),
            ]);

            errors = analyzeContainerStatus(
                buildContainerResource(
                    "running",
                    "enable",
                    ["80:80", "443:80"],
                    undefined,
                    "loadbalancer"
                )
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                    code: "container.type.service-container",
                })
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                    code: "container.type.is-loadbalancer",
                })
            );
        });
        // LB
        it("checks for lb happy path", () => {
            let errors = analyzeLoadBalancerStatus(
                buildContainerResource("running", "enable", [
                    "80:80",
                    "443:80",
                ]),
                buildLbContainer("running"),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toHaveLength(0);
        });
        it("checks lb state", () => {
            let errors = analyzeLoadBalancerStatus(
                buildContainerResource("running", "enable", [
                    "80:80",
                    "443:80",
                ]),
                buildLbContainer("stopped", 0),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "error",
                    code: "lb.state.offline",
                })
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "error",
                    code: "lb.instances.none",
                })
            );
        });
        it("checks haproxy lb won't work with function containers", () => {
            let errors = analyzeLoadBalancerStatus(
                buildContainerResource("function", "enable", [
                    "80:80",
                    "443:80",
                ]),
                buildLbContainer("running"),
                buildLbHaProxyConfig([{ port: 443, tls: true }])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "error",
                    code: "lb.version.haproxy",
                })
            );
        });
        it("checks ports happy path", () => {
            let errors = analyzePortConfiguration(
                buildContainerResource(
                    "running",
                    "enable",
                    ["80:80", "443:80"],
                    [{ fqdn: "test.com", tls: true }]
                ),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toHaveLength(0);
        });
        it("checks for missing container ports", () => {
            let errors = analyzePortConfiguration(
                buildContainerResource("function", "enable", []),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "error",
                    code: "container.ports.none",
                })
            );
        });
        it("checks for missing LINKED record", () => {
            let errors = analyzePortConfiguration(
                buildContainerResource("running", "enable", ["443:80"]),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "error",
                    code: "lb-ingress.dns.missing-record",
                })
            );
            // doesn't care about record if egress
            errors = analyzePortConfiguration(
                buildContainerResource("running", "egress-only", ["443:80"]),
                buildLbV1Config([{ port: 443, tls: true }])
            );
            expect(errors).toHaveLength(0);
            // doesn't care about record if at least one port is !http
            errors = analyzePortConfiguration(
                buildContainerResource("running", "enable", ["666"]),
                buildLbV1Config([{ port: 666, tls: true, tcp: true }])
            );
            expect(errors).toHaveLength(0);
        });
        it("checks for container listening on https port, but no TLS LINKED record pointed at it", () => {
            let errors = analyzePortConfiguration(
                buildContainerResource(
                    "running",
                    "enable",
                    ["443:80"],
                    [{ fqdn: "test.com", tls: false }]
                ),
                buildLbV1Config([
                    { port: 443, tls: true },
                    { port: 80, tls: false },
                ])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "warning",
                    code: "container.ports.no-tls-domain",
                })
            );
        });
        it("checks for LINKED record with TLS, but container is mapping 443:443 instead of 443:80", () => {
            let errors = analyzePortConfiguration(
                buildContainerResource(
                    "running",
                    "enable",
                    ["443:443", "80:80"],
                    [{ fqdn: "test.com", tls: true }]
                ),
                buildLbV1Config([
                    { port: 443, tls: true },
                    { port: 80, tls: false, redirect: true },
                ])
            );
            expect(errors).toContainEqual(
                expect.objectContaining({
                    type: "warning",
                    code: "container-ingress.ports.tls-mismatch",
                })
            );
        });
        it("checks it uses the default lb config if no matching port config on lb", () => {
            const container = buildContainerResource("running", "enable", [
                "6000:8000",
            ]);
            // TODO - also test haproxy default
            const lb = buildLbV1Config([]);
            let errors = analyzePortConfiguration(container, lb);
            const ports = getAnnotatedContainerPorts(container, lb);

            expect(errors).toHaveLength(0);

            expect(ports).toContainEqual(
                expect.objectContaining({
                    lbIngress: 6000,
                    containerIngress: 8000,
                    mode: "tcp",
                    tls: false,
                    disabled: false,
                    autoRedirect: false,
                })
            );
        });
    });
}
